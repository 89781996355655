import {
  FETCH_UNIT_REQUEST,
  FETCH_UNIT_SUCCESS,
  SAVE_UNIT_REQUEST,
  SAVE_UNIT_SUCCESS,
  DELETE_UNIT_REQUEST,
  DELETE_UNIT_SUCCESS,
  SET_REDIRECT_URL,
  CLEAR_UNIT,
  SAVE_UNIT_FAIL,
  } from "@constants/UHEActionTypes";


/**
 * @description Get unit acttion
 * @param {number} id 
 * @returns {Number}
 */
export const getUnit = (id) => {
  return {
    type: FETCH_UNIT_REQUEST,
    id,
  };
};

/**
 * @description Get unit success
 * @param {Object} payload
 * @returns {Object}
 */
export const fetchUnitSuccess = (payload) => {
  return {
    type: FETCH_UNIT_SUCCESS,
    payload,
  };
};

/**
 * @description Save unit action
 * @param {Object} data
 * @returns {Object}
 */
export const saveUnit = (data) => {
  return {
    type: SAVE_UNIT_REQUEST,
    payload: { ...data },
  };
};

/**
 * Fetch action when save request fail
 * @returns {boolean} after catch error
 */
export const saveUnitFail = () => {
  return {
    type: SAVE_UNIT_FAIL,
  };
};

/**
 * @description Save unit success
 * @param {Object} payload
 * @returns {Object}
 */
export const saveUnitSuccess = (payload) => {
  return {
    type: SAVE_UNIT_SUCCESS,
    payload: { ...payload },
  };
};

/**
 * @description Set redirect url action
 * @param {Object} payload 
 * @returns {Object}
 */
export const setRedirectUrl = (payload) => {
  return {
    type: SET_REDIRECT_URL,
    payload,
  };
};


/**
 * @description Delete unit action
 * @param {Object} data 
 * @returns {Promise}
 */
export const deleteUnit = (data) => {
  return {
    type: DELETE_UNIT_REQUEST,
    payload: data,
  };
};

/**
 * @description Delete unit success
 * @returns {Promise}
 */
export const deleteUnitSuccess = () => {
  return {
    type: DELETE_UNIT_SUCCESS,
  };
};

export const clearUnit = () => {
  return {
    type: CLEAR_UNIT,
  };
};
