import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from 'util/IntlMessages';
import {
  Card, Col, Input, Row,
} from 'antd';

/**
 * @description Render Perfect Serve card for edit/add
 * @param {object} customer
 * @param {function} onChangeHandler
 * @param disabled
 * @param {{ customer: boolean }} readyMap Container for state of loading elements
 * @param {boolean} isNew Switch which tells if the component is new
 * @returns {object}
 */
const PerfectServe = ({
  customer, onChangeHandler, disabled, readyMap, isNew,
}) => {
  return (
    <Card
      className="gx-card customer-edit-perf-serve-card"
      title={<IntlMessages id="configuration.customer.perfect_serve" />}
      loading={!isNew && !readyMap.customer}
    >
      <Row className="baseLineAlignedItems" gutter={16}>
        <Col lg={8} md={8} sm={24} xs={24}>
          <div><IntlMessages id="configuration.customer.post_url_protocol" /></div>
        </Col>
        <Col lg={16} md={16} sm={24} xs={24}>
          <Input
            autoComplete="off"
            id="perfectServeUrl"
            name="name"
            type="text"
            defaultValue={customer.perfectserve_url}
            value={customer.perfectserve_url}
            onChange={(event) => onChangeHandler(event, 'perfectserve_url')}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Card>
  );
};

PerfectServe.defaultProps = {
  isNew: true,
};

PerfectServe.propTypes = {
  customer: PropTypes.object.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  readyMap: PropTypes.shape({ customer: PropTypes.bool }).isRequired,
  isNew: PropTypes.bool,
};

export default PerfectServe;
