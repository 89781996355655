import {
  PROGRAMS_LISTING_REQUEST,
  PROGRAMS_LISTING_SUCCESS,
  DELETE_PROGRAM_REQUEST,
  DELETE_PROGRAM_SUCCESS,
  SAVE_PROGRAM,
  SAVE_PROGRAM_SUCCESS,
  GET_PROGRAM_REQUEST,
  GET_PROGRAM_SUCCESS,
  UPDATE_PROGRAM_REQUEST,
  UPDATE_PROGRAM_SUCCESS,
  PROGRAM_GRANTS_REQUEST,
  PROGRAM_GRANTS_SUCCESS,
  UPDATE_PROGRAM_GRANTS_REQUEST,
  UPDATE_PROGRAM_GRANTS_SUCCESS,
  UPLOAD_PROGRAM_DOCUMENT_REQUEST,
  UPLOAD_PROGRAM_DOCUMENT_SUCCESS,
  CLEAR_PROGRAM_STATE,
} from '@constants/UHEActionTypes';

/**
 * Handles UPLOAD_PROGRAM_DOCUMENT_REQUEST action
 * @param {Object} file file
 * @returns {Object} object
 */
export const uploadProgramDocument = (file) => ({
  type: UPLOAD_PROGRAM_DOCUMENT_REQUEST,
  payload: {
    file,
  },
});

/**
 * Handles UPLOAD_PROGRAM_DOCUMENT_SUCCESS action
 * @returns {Object} object
 */
export const uploadProgramDocumentSuccess = () => ({
  type: UPLOAD_PROGRAM_DOCUMENT_SUCCESS,
});

/**
 * getProgramsListing Action Creator
 * @param {number} page Page Number
 * @param {string} sorting Sorting String
 * @param {string} filter Filter String
 * @returns {object} Action Type, Page Number, Sorting String, Filter String
 */
export const getProgramsListing = (page, sorting, filter) => ({
  type: PROGRAMS_LISTING_REQUEST,
  page,
  sorting,
  filter,
});

/**
 * getProgramsListingSuccess Action Creator
 * @param {object} payload Listing Data
 * @returns {object} Action Type, Listing Data
 */
export const getProgramsListingSuccess = (payload) => ({
  type: PROGRAMS_LISTING_SUCCESS,
  payload,
});

/**
 * deleteProgram Action Creator
 * @param {Object} payload Program ID
 * @return {object} Action Type, Payload ID
 */
export const deleteProgram = (payload) => ({
  type: DELETE_PROGRAM_REQUEST,
  payload,
});

/**
 * deleteProgram Action Creator
 * @param {Object} payload Program ID
 * @return {object} Action Type, Payload ID
 */
export const deleteProgramSuccess = (payload) => ({
  type: DELETE_PROGRAM_SUCCESS,
  payload,
});

/**
* Handle SAVE_PROGRAM Action
 * @param {Object} data payload
 * @returns {Object} data
 */
export const saveProgram = (data) => ({
  type: SAVE_PROGRAM,
  payload: { ...data },
});

/**
 * Handle SAVE_PROGRAM_SUCCESS Action
 * @param {Object} payload data
 * @returns {Object} data
 */
export const saveProgramSuccess = (payload) => ({
  type: SAVE_PROGRAM_SUCCESS,
  payload: { ...payload },
});

/**
 * getProgram Action Creator
 * @param {string} id Program ID
 * @returns {object} Type, Payload
 */
export const getProgram = (id) => ({
  type: GET_PROGRAM_REQUEST,
  id,
});

/**
 * getProgramSuccess Action Creator
 * @param {object} payload Program Data
 * @returns {object} Type, Payload
 */
export const getProgramSuccess = (payload) => ({
  type: GET_PROGRAM_SUCCESS,
  payload,
});

/**
 * updateProgram Action Creator
 * @param {string} payload Program ID
 * @returns {object} Action Type, Program ID
 */
export const updateProgram = (payload) => ({
  type: UPDATE_PROGRAM_REQUEST,
  payload,
});

/**
 * updateProgramSuccess Action Creator
 * @param {object} payload Program Data
 * @returns {object} Action Type, Program Data
 */
export const updateProgramSuccess = (payload) => ({
  type: UPDATE_PROGRAM_SUCCESS,
  payload,
});

/**
 * getProgramGrants Action Creator
 * @param {string} id Program ID
 * @returns {object} Action Type, Program ID
 */
export const getProgramGrants = (id) => ({
  type: PROGRAM_GRANTS_REQUEST,
  id,
});

/**
 * getProgramGrantsSuccess Action Creator
 * @param {object} payload Program Grants
 * @returns {object} Action Type, Program Grants
 */
export const getProgramGrantsSuccess = (payload) => ({
  type: PROGRAM_GRANTS_SUCCESS,
  payload,
});

/**
 * updateProgramGrants Action Creator
 * @param {object} payload Action Type, Program ID, Program Grants Body
 * @returns {object} Action Type, Program ID, Program Grants Body
 */
export const updateProgramGrants = (payload) => ({
  type: UPDATE_PROGRAM_GRANTS_REQUEST,
  payload,
});

/**
 * updateProgramGrantsSuccess Action Creator
 * @param {object} payload Action Type, Program Grants
 * @returns {object} Action Type, Program Grants
 */
export const updateProgramGrantsSuccess = (payload) => ({
  type: UPDATE_PROGRAM_GRANTS_SUCCESS,
  payload,
});

/**
 * clearProgramState Action Creator
 * @returns {object} Action Type
 */
export const clearProgramState = () => ({
  type: CLEAR_PROGRAM_STATE,
});
