import React from 'react';
import { injectIntl } from 'react-intl';
import {
  PROCESSING,
  SUCCESS,
  FAIL,
} from '@constants/UHESettings';

/**
 * Command status jsx
 * @param {object} content cell
 * @param {intl} object with strings
 * @param {String} statusFigure string
 * @returns {JSX} Status cell
 */
const CommandStatusCell = ({ content, intl, statusFigure }) => {
  const { status } = content;
  statusFigure = 'icon-rhombus';
  if (status === SUCCESS) {
    statusFigure = 'green-status-command-icon';
  } else if (status === PROCESSING) {
    statusFigure = 'yellow-status-command-icon';
  } else if (status === FAIL) {
    statusFigure = 'red-status-command-icon';
  }
  return (
    <div className="uhe-table-td" title={intl.formatMessage({ id: `uhe.status.${status}` })}>
      <span className="status">
        <i className={`icon ${statusFigure} `} />
      </span>
    </div>
  );
};

export default injectIntl(CommandStatusCell);
