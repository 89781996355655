import { MONITORING_STANDARDS_FETCH_SUCCESS, MONITORING_UHE_FETCH_DATA } from '@constants/UHEActionTypes';
import { tableContent } from 'util/UheHelper';

const INIT_STATE = {
  loading: true,
  table: {
    list: [],
    page: {},
  },
};

/**
 * Standards Reducers
 * @param  {Object} state Current state
 * @param  {String} action object
 * @returns {Object} state
 */
export default (state = INIT_STATE, action) => {
  let currentTable = {};
  let newState = {};
  switch (action.type) {
    case MONITORING_STANDARDS_FETCH_SUCCESS:
      currentTable = tableContent(action.data, state.table);
      newState = { table: currentTable, loading: false };
      return { ...state, ...newState };

    case MONITORING_UHE_FETCH_DATA:
      return { ...state, loading: true };

    default:
      return state;
  }
};
