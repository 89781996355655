import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import IntlMessages from 'util/IntlMessages';
import {
  Pie, PieChart, Sector, Label,
} from 'recharts';
import { Card, Empty } from 'antd';
import PropTypes from 'prop-types';

import { onFetchPieChartData } from '@uhe_actions/monitoring/UHEActions';
import { statusIcon } from '../../util/UheHelper';

const RADIAN = Math.PI / 180;
let _locale;

/**
 * @param  {number} cx
 * @param  {number} cy
 * @param  {number} midAngle
 * @param  {number} innerRadius
 * @param  {number} outerRadius
 * @param  {number} value
 * @param  {string} fill
 * @return {ReactElement}
 */
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, value, fill,
}) => (
  <g>
    <text position="center" fill={fill}>
      {value}
    </text>
  </g>
);

/**
 * @param  {number} cx
 * @param  {number} cy
 * @param  {number} innerRadius
 * @param  {number} outerRadius
 * @param  {string} fill
 * @param  {string} name
 * @param  {number} startAngle
 * @param  {number} endAngle
 * @return {ReactElement}
 */
const renderActiveShape = ({
  cx, cy, innerRadius, outerRadius, fill, name, startAngle, endAngle,
}) => (
  <g>
    <text x={cx} y={cy - 10} dy={8} textAnchor="middle" fill={fill}>{name}</text>
    <text x={cx} y={cy + 10} dy={8} textAnchor="middle" fill={fill}>
      <IntlMessages id="uhe.pie.clickFilter" />
    </text>
    <Sector
      cx={cx}
      cy={cy}
      innerRadius={innerRadius}
      outerRadius={outerRadius}
      startAngle={startAngle}
      endAngle={endAngle}
      fill={fill}
    />
    <Sector
      cx={cx}
      cy={cy}
      startAngle={startAngle}
      endAngle={endAngle}
      innerRadius={outerRadius + 1}
      outerRadius={outerRadius + 5}
      fill={fill}
    />
  </g>
);

/**
 *
 */
class UheChart extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const { filter } = nextProps;

    const pieHolder = document.getElementById('pie-chart-holder');
    const newState = { pieWidth: 400 };

    if (pieHolder) {
      newState.pieWidth = pieHolder.clientWidth - 50;
    }

    if (JSON.stringify(filter) === JSON.stringify(prevState.filter)) {
      return newState;
    }

    nextProps.onFetchPieChartData(filter);
    newState.filter = filter;

    return newState;
  }

  constructor(props) {
    super(props);

    this.state = {
      activeIndex: null,
    };

    this.history = this.props.history;
    this.qParams = new URLSearchParams(this.history.location.search);
    this.lastQueryString = this.history.location.search;
  }

  componentDidUpdate() {
    if (this.lastQueryString != this.history.location.search) {
      this.lastQueryString = this.history.location.search;
      this.hasAnimationEnded = false;
    }
  }

  /**
   * Adapt data returned by the server
   * @param  {Array<Object>} data data
   * @param {number} allDevices the sum of devices
   * @return {Object} obj
   */
  dataAdapter(data, allDevices) {
    const { intl } = this.props;
    let inAlarmChart = 0;
    let inCallData = 0;
    let onlineChart = 0;
    let notConfChart = 0;
    const pieData = [];

    data.forEach((value) => {
      if (value.inAlarm) {
        inAlarmChart += value.count;
      } else if (value.online && value.activated && !value.in_a_call && !value.not_configured) {
        onlineChart += value.count;
      } else if (value.in_a_call) {
        inCallData += value.count;
      } else if (value.not_configured) {
        notConfChart += value.count;
      }
    });
    const onlineTotalCount = onlineChart + inCallData;

    pieData.push(
      [
        {
          key: 'total', name: intl.formatMessage({ id: 'uhe.pie.totalDevices' }), value: allDevices, fill: '#0092D7', stroke: 'none',
        },
      ],
      [
        {
          key: 'healthy', name: intl.formatMessage({ id: 'uhe.pie.healthy' }), value: onlineChart, fill: '#44b449', stroke: 'none',
        },
        {
          key: 'inCall', name: intl.formatMessage({ id: 'uhe.pie.inCall' }), value: inCallData, fill: '#EE66A4', stroke: 'none',
        },
        {
          key: 'healthy', value: allDevices - onlineTotalCount, fill: '#004877', stroke: 'none',
        },
      ],
      [
        {
          key: 'not_configured', name: intl.formatMessage({ id: 'uhe.pie.not_configured' }), value: notConfChart, fill: '#FABD2C', stroke: 'none',
        },
        {
          key: 'not_configured', value: allDevices - notConfChart, fill: '#004877', stroke: 'none',
        },
      ],
      [
        {
          key: 'unhealthy', name: intl.formatMessage({ id: 'uhe.pie.inAlarm' }), value: inAlarmChart, fill: '#FE0000', stroke: 'none',
        },
        {
          key: 'unhealthy', value: allDevices - inAlarmChart, fill: '#004877', stroke: 'none',
        },
      ],

    );
    return {
      pieData,
      onlineTotalCount,
    };
  }

  renderTitle = (value, name) => (
    <div className="gx-d-flex gx-align-items-center">
      {name
        && statusIcon(value)}
      <span className="margin-left-8">
        {name}
      </span>

    </div>
  )

  /**
   *
   */
  render() {
    const {
      loading, data, onPieClicked, selectedPie,
    } = this.props;
    _locale = this.props.intl.locale;
    let allDevices = 0;
    data.forEach((value) => {
      allDevices += value.count;
    });
    const {
      pieData, onlineTotalCount,
    } = this.dataAdapter(data, allDevices);

    const pieCharts = pieData.map((value) => (
      <Card
        loading={loading}
        className={selectedPie === value[0]?.key
          ? 'selected-pie pie-chart-card gx-card '
          : 'pie-chart-card gx-card '}
        title={this.renderTitle(value[0]?.key, value[0]?.name)}
      >
        <PieChart className="pie-chart-wrap" width={260} height={130}>

          <Pie
            startAngle={90}
            endAngle={-270}
            labelLine={false}
            data={value}
            innerRadius={37}
            outerRadius={47}
            onClick={onPieClicked}
            fill="#8884d8"
            dataKey="value"
          >
            <Label value={value[0].key === 'healthy' ? onlineTotalCount : value[0]?.value} position="center" fill="#FFFFFF" />
          </Pie>
        </PieChart>
      </Card>

    ));

    return (
      <>
        <div>
          {!!allDevices > 0 && (
          <div className="pie-wrap">
            {pieCharts}
            <br />
          </div>
          )}
          {!allDevices > 0 && <Empty className="no-pie-data" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </div>

      </>
    );
  }
}

UheChart.propTyps = {
  onPieClicked: PropTypes.func.isRequired,
  selectedPie: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ UhePieChart = {} }) => {
  const { loading = true, data = [] } = UhePieChart;

  return { loading, data };
};

export default connect(mapStateToProps, {
  onFetchPieChartData,
})(injectIntl(withRouter(UheChart)));
