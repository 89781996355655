// ListingsTopFilterSaga
import {
  all, call, fork, put, takeEvery, select,
} from 'redux-saga/effects';
import { fetchDataSuccess, fetchPieChartDataSuccess, fetchGmapDataSuccess } from '@actions/uhe/monitoring/UHEActions';
import { standardsFetchDataSuccess } from '@actions/uhe/monitoring/StandardsActions';
import { MONITORING_UHE_FETCH_DATA, MONITORING_UHE_FETCH_PIE_CHART_DATA, MONITORING_UHE_FETCH_GMAP_DATA } from '@constants/UHEActionTypes';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { MONITORING_DEFAULT_SORTING_QUERY_STRING } from '@constants/UHESettings';
import { fetchError } from '@actions/Common';
import RestManager from '@util/RestManager';
import { hideSystemMessages } from '@util/UheRoleChecker';

/**
 * doFetchData
 * @param  {number} page page
 * @param  {string} sorting sorting
 * @param  {string} filter filter
 * @param  {string} dashboardType dashboardType
 * @return {Object} response data
 */
const doFetchData = async (page, sorting, filter, dashboardType) => {
  const filterQueryString = filter && filter.length ? `&${filter.join('&')}` : '';
  const sortingQueryString = sorting && sorting.length ? `&sort=${sorting.join('&sort=')}` : MONITORING_DEFAULT_SORTING_QUERY_STRING;
  let endpoint;
  let sbaps_monitoring;

  switch (dashboardType) {
    case 'standards':
      endpoint = `${ENDPOINTS.monitoring.UHETable}?projection=standard&`;
      sbaps_monitoring = 0;
      break;
    case 'monitoring-standards':
      endpoint = `${ENDPOINTS.monitoring.UHETable}?projection=standard&`;
      sbaps_monitoring = 1;
      break;
    default:
      endpoint = `${ENDPOINTS.monitoring.UHETable}?`;
      sbaps_monitoring = 0;
  }

  return await RestManager.request(`${endpoint}page=${page || 0}${sortingQueryString}${filterQueryString}&sbaps_monitoring_enabled=${sbaps_monitoring}`);
};

/**
 * doFetchPieChartData
 * @param  {array} filter filter
 * @return {Object} response data
 */
const doFetchPieChartData = async (filter) => {
  const updFilter = filter.filter((element) => !element.includes('status') && !element.includes('in_a_call'));
  const filterQueryString = updFilter && updFilter.length ? `?${updFilter.join('&')}` : '';
  return await RestManager.request(`${ENDPOINTS.monitoring.UHEPieChart}${filterQueryString}`);
};

/**
 * doFetchGmapData
 * @param  {array} filter params
 * @return {Object} response data
 */
const doFetchGmapData = async (filter) => {
  const filterQueryString = filter && filter.length ? `&${filter.join('&')}` : '';
  return await RestManager.request(`${ENDPOINTS.monitoring.UHEGoogleMap}&page=0&size=1000000${filterQueryString}`);
};

/**
 * fetchTableData
 * @param {string} type type
 * @param  {number} page page
 * @param  {string} sorting sorting
 * @param  {string} filter filter
 * @param  {string} dashboardType dashboardType
 * @returns {void}
 */
function* fetchTableData({
  type, page, sorting, filter, dashboardType,
}) {
  try {
    const fetchedData = yield call(
      doFetchData,
      page,
      sorting,
      filter,
      dashboardType,
    );
    switch (dashboardType) {
      case 'standards':
        yield put(standardsFetchDataSuccess(fetchedData));
        break;
      case 'monitoring-standards':
        yield put(standardsFetchDataSuccess(fetchedData));
        break;
      default:
        yield put(fetchDataSuccess(fetchedData));
        break;
    }
  } catch (error) {
    const roles = (state) => state.ConfigurationUsers.ownUser;
    const loggedUser = yield select(roles);
    const hideErrorMessage = hideSystemMessages(loggedUser.isUserAdmin);
    if (!hideErrorMessage) {
      yield put(fetchError(error));
    }
  }
}

/**
 * fetchPieChartData
 * @param {string} type type
 * @param  {string} filter filter
 * @returns {void}
 */
function* fetchPieChartData({ type, filter }) {
  try {
    const fetchedData = yield call(doFetchPieChartData, filter);
    yield put(fetchPieChartDataSuccess(fetchedData));
  } catch (error) {
    const roles = (state) => state.ConfigurationUsers.ownUser;
    const loggedUser = yield select(roles);
    const hideErrorMessage = hideSystemMessages(loggedUser.isUserAdmin);
    if (!hideErrorMessage) {
      yield put(fetchError(error));
    }
  }
}

/**
 * fetchGmapData
 * @param {string} type type
 * @param  {string} filter filter
 * @returns {void}
 */
function* fetchGmapData({ filter }) {
  try {
    const fetchedData = yield call(doFetchGmapData, filter);
    yield put(fetchGmapDataSuccess(fetchedData));
  } catch (error) {
    const roles = (state) => state.ConfigurationUsers.ownUser;
    const loggedUser = yield select(roles);
    const hideErrorMessage = hideSystemMessages(loggedUser.isUserAdmin);
    if (!hideErrorMessage) {
      yield put(fetchError(error));
    }
  }
}

/**
 * fetchDataProxy
 * @param  {string} data data
 * @returns {void}
 */
function* fetchDataProxy(data) {
  switch (data.type) {
    case MONITORING_UHE_FETCH_PIE_CHART_DATA:
      yield call(fetchPieChartData, data);
      break;
    case MONITORING_UHE_FETCH_GMAP_DATA:
      yield call(fetchGmapData, data);
      break;
    default:
      yield call(fetchTableData, data);
  }
}

/**
 * Sagas Watcher
 * @returns {void}
 */
export function* fetchData() {
  yield takeEvery([MONITORING_UHE_FETCH_DATA,
    MONITORING_UHE_FETCH_PIE_CHART_DATA,
    MONITORING_UHE_FETCH_GMAP_DATA],
  fetchDataProxy);
}

/**
 * Performs Non-Blocking Call
 * @returns {void}
 */
export default function* rootSaga() {
  yield all([fork(fetchData)]);
}
