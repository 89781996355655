import {
  MACHINE_INFO_REQUEST, MACHINE_INFO_SUCCESS, SEND_ADVANCED_DEV_COMMAND,
} from '@constants/UHEActionTypes';

/**
 * getMachineInfo Action Creator
 * @param {number} page Page Number
 * @param {string} sorting Sorting String
 * @param {string} filter Filter String
 * @returns {object} Payload
 */
export const getMachineInfo = (page, sorting, filter) => ({
  type: MACHINE_INFO_REQUEST,
  page,
  sorting,
  filter,
});

/**
 * getMachineInfoSuccess Action Creator
 * @param {object} payload Machine Info Data
 * @returns {object} Payload
 */
export const getMachineInfoSuccess = (payload) => ({
  type: MACHINE_INFO_SUCCESS,
  payload,
});

/**
 * patientDeviceCommand Action Creator
 * @param {object} payload Request Body
 * @returns {object} Data
 */
export const sendAdvancedDevCommand = (payload) => ({
  type: SEND_ADVANCED_DEV_COMMAND,
  payload,
});
