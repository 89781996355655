import {
  SSO_PROVIDERS_FETCH_DATA,
  SSO_PROVIDERS_FETCH_DATA_SUCCESS,
  SAVE_SSO_PROVIDER,
  SAVE_SSO_PROVIDER_SUCCESS,
  SAVE_SSO_PROVIDER_ID,
  SAVE_SSO_PROVIDER_ID_SUCCESS,
  DELETE_SSO_PROVIDER_REQUEST,
  DELETE_SSO_PROVIDER_REQUEST_SUCCESS,
  SAML_PROVIDERS_FETCH_DATA,
  SAML_PROVIDERS_FETCH_DATA_SUCCESS,
  SAVE_SAML_PROVIDER,
  SAVE_SAML_PROVIDER_SUCCESS,
  DOMAIN_FETCH_DATA,
  DOMAIN_FETCH_DATA_SUCCESS,
  SAVE_DOMAIN_DATA,
  SAVE_DOMAIN_DATA_SUCCESS,
  DELETE_DOMAIN_REQUEST,
  DELETE_DOMAIN_REQUEST_SUCCESS,
  CLEAR_DOMAIN_STATE,
} from '@constants/UHEActionTypes';

const INIT_STATE = {
  table: {
    content: [],
    page: {},
  },
  loading: false,
  selectedSsoProvider: {},
  selectedSamlProvider: {},
  selectedDomain: [],
};

/**
 * @description SSO Providers reducer
 * @param  {Object} state
 * @param  {string} action
 * @return {Object}
 */
export default (state = INIT_STATE, action) => {
  let newState;

  switch (action.type) {
    case SSO_PROVIDERS_FETCH_DATA_SUCCESS:
      newState = { table: action.data, loading: false };
      return { ...state, ...newState };

    case SSO_PROVIDERS_FETCH_DATA:
      newState = { loading: false, saved: true };
      return { ...state, ...newState };

    case SAVE_SSO_PROVIDER: {
      return { ...state, loading: true };
    }

    case SAVE_SSO_PROVIDER_SUCCESS: {
      newState = { loading: false, savedSsoProviderId: action.payload.id };
      return { ...state, ...newState };
    }

    case SAVE_SSO_PROVIDER_ID: {
      const selectedSsoProvider = { ...state.selectedSsoProvider, ...action.payload };
      newState = { selectedSsoProvider, loading: true };
      return { ...state, ...newState };
    }

    case SAVE_SSO_PROVIDER_ID_SUCCESS: {
      const selectedSsoProvider = { ...state.selectedSsoProvider, ...action.payload };
      newState = { selectedSsoProvider, loading: false };
      return { ...state, ...newState };
    }

    case DELETE_SSO_PROVIDER_REQUEST: {
      return { ...state, loading: true };
    }

    case DELETE_SSO_PROVIDER_REQUEST_SUCCESS: {
      const selectedSsoProvider = { ...INIT_STATE.selectedSsoProvider };
      newState = { selectedSsoProvider };
      return { ...state, ...newState, loading: false };
    }
    case SAML_PROVIDERS_FETCH_DATA: {
      const selectedSamlProvider = { ...state.selectedSamlProvider, ...action.payload };
      newState = { selectedSamlProvider, loading: true };
      return { ...state, ...newState };
    }
    case SAML_PROVIDERS_FETCH_DATA_SUCCESS: {
      const selectedSamlProvider = { ...state.selectedSamlProvider, ...action.payload };
      newState = { selectedSamlProvider, loading: false };
      return { ...state, ...newState };
    }
    case SAVE_SAML_PROVIDER: {
      return { ...state, loading: true };
    }
    case SAVE_SAML_PROVIDER_SUCCESS: {
      newState = { loading: false, savedSamlProviderId: action.payload };
      return { ...state, ...newState };
    }
    case DOMAIN_FETCH_DATA:
      newState = { selectedDomain: action.data, loading: true };
      return { ...state, ...newState };
    case DOMAIN_FETCH_DATA_SUCCESS: {
      return { ...state, selectedDomain: action.payload.data, loading: false };
    }
    case SAVE_DOMAIN_DATA: {
      return { ...state, loading: true };
    }
    case SAVE_DOMAIN_DATA_SUCCESS: {
      const domainData = { ...action.payload };
      const domainNewState = { domainData };
      return { ...state, ...domainNewState, loading: false };
    }
    case DELETE_DOMAIN_REQUEST: {
      return { ...state, loading: true };
    }
    case DELETE_DOMAIN_REQUEST_SUCCESS: {
      const selectedDomain = { ...INIT_STATE.selectedDomain };
      newState = { selectedDomain };
      return { ...state, ...newState, loading: false };
    }
    case CLEAR_DOMAIN_STATE: {
      return INIT_STATE;
    }
    default:
      return state;
  }
};
