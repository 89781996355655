import {
  MACHINE_INFO_REQUEST,
  MACHINE_INFO_SUCCESS,
} from '@constants/UHEActionTypes';
import { tableContent } from 'util/UheHelper';

const INIT_STATE = {
  table: {
    list: [],
    page: {},
  },
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MACHINE_INFO_REQUEST: {
      return { ...state, loading: true };
    }

    case MACHINE_INFO_SUCCESS: {
      const currentTable = tableContent(action.payload, state.table);
      const newState = { table: currentTable, loading: false };
      return { ...state, ...newState };
    }

    default:
      return state;
  }
};
