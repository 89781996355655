// Listings Top Filter Actions
export const LISTINGS_TOP_FILTER_GET_OPTIONS = 'LISTINGS_TOP_FILTER_GET_OPTIONS';
export const LISTINGS_TOP_FILTER_FETCH_SUCCESS = 'LISTINGS_TOP_FILTER_FETCH_SUCCESS';
export const RESET_TOP_FILTERS = 'RESET_TOP_FILTERS';

// Should Reset Pagination Flag
export const SHOULD_RESET_PAGINATION = 'SHOULD_RESET_PAGINATION';
export const SHOULD_RESET_PAGINATION_SUCCESS = 'SHOULD_RESET_PAGINATION_SUCCESS';

// Monitoring -> UHE Page Actions
export const MONITORING_UHE_FETCH_DATA = 'MONITORING_UHE_FETCH_DATA';
export const MONITORING_UHE_FETCH_SUCCESS = 'MONITORING_UHE_FETCH_SUCCESS';
export const MONITORING_UHE_FETCH_PIE_CHART_DATA = 'MONITORING_UHE_FETCH_PIE_CHART_DATA';
export const MONITORING_UHE_FETCH_PIE_CHART_SUCCESS = 'MONITORING_UHE_FETCH_PIE_CHART_SUCCESS';
export const MONITORING_UHE_FETCH_GMAP_DATA = 'MONITORING_UHE_FETCH_GMAP_DATA';
export const MONITORING_UHE_FETCH_GMAP_SUCCESS = 'MONITORING_UHE_FETCH_GMAP_SUCCESS';

// Configuration -> Organizations Page Actions
export const CONFIGURATION_ORGANIZATIONS_FETCH_DATA = 'CONFIGURATION_ORGANIZATIONS_FETCH_DATA';
export const CONFIGURATION_ORGANIZATIONS_FETCH_SUCCESS = 'CONFIGURATION_ORGANIZATIONS_FETCH_SUCCESS';
export const FETCH_ORGANIZATION_REQUEST = 'FETCH_ORGANIZATION_REQUEST';
export const SAVE_ORGANIZATION_REQUEST = 'SAVE_ORGANIZATION_REQUEST';
export const DELETE_ORGANIZATION_REQUEST = 'DELETE_ORGANIZATION_REQUEST';
export const FETCH_ORGANIZATION_SUCCESS = 'FETCH_ORGANIZATION_SUCCESS';
export const SAVE_ORGANIZATION_SUCCESS = 'SAVE_ORGANIZATION_SUCCESS';
export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS';
// add const for organization edit and add
export const FETCH_DATA_SUCCESS_CUSTOMERS = 'FETCH_DATA_SUCCESS_CUSTOMERS';
export const FETCH_DATA_CUSTOMERS = 'FETCH_DATA_CUSTOMERS';
export const FETCH_DATA_SUCCESS_FACILITY = 'FETCH_DATA_SUCCESS_FACILITY';
export const FETCH_DATA_FACILITY = 'FETCH_DATA_FACILITY';

export const FETCH_ORGANIZATION_BRANDING_REQUEST = 'FETCH_ORGANIZATION_BRANDING_REQUEST';
export const FETCH_ORGANIZATION_BRANDING_SUCCESS = 'FETCH_ORGANIZATION_BRANDING_SUCCESS';

// Configuration -> Facilities Page Actions
export const CONFIGURATION_FACILITIES_FETCH_DATA = 'CONFIGURATION_FACILITIES_FETCH_DATA';
export const CONFIGURATION_FACILITIES_FETCH_SUCCESS = 'CONFIGURATION_FACILITIES_FETCH_SUCCESS';
export const DELETE_FACILITY_REQUEST = 'DELETE_FACILITY_REQUEST';
export const DELETE_FACILITY_SUCCESS = 'DELETE_FACILITY_SUCCESS';

// Configuration -> Customers page Actions
export const CONFIGURATION_CUSTOMERS_FETCH_DATA = 'CONFIGURATION_CUSTOMERS_FETCH_DATA';
export const CONFIGURATION_CUSTOMERS_FETCH_SUCCESS = 'CONFIGURATION_CUSTOMERS_FETCH_SUCCESS';

// Configuration -> Units page Actions
export const CONFIGURATION_UNITS_FETCH_DATA = 'CONFIGURATION_UNITS_FETCH_DATA';
export const CONFIGURATION_UNITS_FETCH_SUCCESS = 'CONFIGURATION_UNITS_FETCH_SUCCESS';
export const DELETE_UNITS_REQUEST = 'DELETE_UNITS_REQUEST';
export const DELETE_UNITS_SUCCESS = 'DELETE_UNITS_SUCCESS';

// Monitoring -> Standards Page Actions
export const MONITORING_STANDARDS_FETCH_SUCCESS = 'MONITORING_STANDARDS_FETCH_SUCCESS';

// Unit action types
export const FETCH_UNIT_REQUEST = 'FETCH_UNIT_REQUEST';
export const FETCH_UNIT_SUCCESS = 'FETCH_UNIT_SUCCESS';
export const FETCH_UNIT_FAILURE = 'FETCH_UNIT_FAILURE';
export const SAVE_UNIT_REQUEST = 'SAVE_UNIT_REQUEST';
export const SAVE_UNIT_SUCCESS = 'SAVE_UNIT_SUCCESS';
export const SAVE_UNIT_FAILURE = 'SAVE_UNIT_FAILURE';
export const DELETE_UNIT_REQUEST = 'DELETE_UNIT_REQUEST';
export const DELETE_UNIT_SUCCESS = 'DELETE_UNIT_SUCCESS';
export const DELETE_UNIT_FAILURE = 'DELETE_UNIT_FAILURE';
export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';
export const CLEAR_UNIT = 'CLEAR_UNIT';
export const SAVE_UNIT_FAIL = 'SAVE_UNIT_FAIL';

// Facilities
export const FETCH_FACILITIES_REQUEST = 'FETCH_FACILITIES_REQUEST';
export const FETCH_FACILITIES_SUCCESS = 'FETCH_FACILITIES_SUCCESS';
export const FETCH_FACILITIES_FAILURE = 'FETCH_FACILITIES_FAILURE';
export const FETCH_FACILITY_REQUEST = 'FETCH_FACILITY_REQUEST';
export const FETCH_FACILITY_SUCCESS = 'FETCH_FACILITY_SUCCESS';
export const CLEAR_FACILITY = 'CLEAR_FACILITY';
export const SAVE_FACILITY_REQUEST = 'SAVE_FACILITY_REQUEST';
export const SAVE_FACILITY_SUCCESS = 'SAVE_FACILITY_SUCCESS';
export const CLEAR_FACILITY_STATE = 'CLEAR_FACILITY_STATE';
export const SAVE_FACILITY_FAIL = 'SAVE_FACILITY_FAIL';

// Customer
export const FETCH_CUSTOMER_REQUEST = 'FETCH_CUSTOMER_REQUEST';
export const SAVE_CUSTOMER_REQUEST = 'SAVE_CUSTOMER_REQUEST';
export const DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST';
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
export const SAVE_CUSTOMER_SUCCESS = 'SAVE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const CLEAN_UP_SELECTED_CUSTOMER = 'CLEAN_UP_SELECTED_CUSTOMER';
export const FETCH_BRANDING_REQUEST = 'FETCH_BRANDING_REQUEST';
export const FETCH_BRANDING_SUCCESS = 'FETCH_BRANDING_SUCCESS';
export const CLEAR_CUSTOMER_LOADING = 'CLEAR_CUSTOMER_LOADING';
export const FETCH_INDEMAND_INTERPRETER = 'FETCH_INDEMAND_INTERPRETER';
export const FETCH_INDEMAND_INTERPRETER_SUCCESS = 'FETCH_INDEMAND_INTERPRETER_SUCCESS';
export const FETCH_STRATUS_INTERPRETER = 'FETCH_STRATUS_INTERPRETER';
export const FETCH_STRATUS_INTERPRETER_SUCCESS = 'FETCH_STRATUS_INTERPRETER_SUCCESS';
export const DELETE_STRATUS_INTEPRETER_REQUEST = 'DELETE_STRATUS_INTEPRETER_REQUEST';
export const DELETE_STRATUS_INTEPRETER_SUCCESS = 'DELETE_STRATUS_INTEPRETER_SUCCESS';
export const UPLOAD_INTERPRETER_REQUEST = 'UPLOAD_INTERPRETER_REQUEST';
export const UPLOAD_INTERPRETER_SUCCESS = 'UPLOAD_INTERPRETER_SUCCESS';
export const SAVE_STRATUS_INTERPRETER_REQUEST = 'SAVE_STRATUS_INTERPRETER_REQUEST';
export const SAVE_STRATUS_INTERPRETER_SUCCESS = 'SAVE_STRATUS_INTERPRETER_SUCCESS';
export const CONFIGURATION_CUSTOMERS_FETCH_DEVICES = 'CONFIGURATION_CUSTOMERS_FETCH_DEVICES';
export const CONFIGURATION_CUSTOMERS_FETCH_DEVICES_SUCCESS = 'CONFIGURATION_CUSTOMERS_FETCH_DEVICES_SUCCESS';
export const BULK_ACTION_REQUEST = 'BULK_ACTION_REQUEST';
export const BULK_ACTION_REQUEST_SUCCESS = 'BULK_ACTION_REQUEST_SUCCESS';
export const PENDING_ACTION_DATA = 'PENDING_ACTION_DATA';
export const PENDING_ACTION_DATA_SUCCESS = 'PENDING_ACTION_DATA_SUCCESS';
export const DELETE_PENDING_ACTION_REQUEST = 'DELETE_PENDING_ACTION_REQUEST';
export const DELETE_PENDING_ACTION_SUCCESS = 'DELETE_PENDING_ACTION_SUCCESS';
export const CLEAR_CUSTOMER_STATE = 'CLEAR_CUSTOMER_STATE';
export const FETCH_INTERPRETER_OPTIONS_REQUEST = 'FETCH_INTERPRETER_OPTIONS_REQUEST';
export const FETCH_INTERPRETER_OPTIONS_SUCCESS = 'FETCH_INTERPRETER_OPTIONS_SUCCESS';
export const FETCH_INTERPRETER_REQUEST = 'FETCH_INTERPRETER_REQUEST';
export const FETCH_INTERPRETER_SUCCESS = 'FETCH_INTERPRETER_SUCCESS';
export const UPLOAD_JAMF_DATA_REQUEST = 'UPLOAD_JAMF_DATA_REQUEST';
export const UPLOAD_JAMF_DATA_REQUEST_SUCCESS = 'UPLOAD_JAMF_DATA_REQUEST_SUCCESS';
export const NETWORK_CERTIFICATE_REPORT_REQUEST = 'NETWORK_CERTIFICATE_REPORT_REQUEST';
export const NETWORK_CERTIFICATE_REPORT_SUCCESS = 'NETWORK_CERTIFICATE_REPORT_SUCCESS';
export const FETCH_CSV_REPORT_REQUEST = 'FETCH_CSV_REPORT_REQUEST';
export const MOVE_DEVICE_REQUEST = 'MOVE_DEVICE_REQUEST';
export const MOVE_DEVICE_SUCCESS = 'MOVE_DEVICE_SUCCESS';
export const PENDING_DEVICE_LIST = 'PENDING_DEVICE_LIST';
export const PENDING_DEVICE_LIST_SUCCESS = 'PENDING_DEVICE_LIST_SUCCESS';
export const DELETE_PENDING_COMMAND_REQUEST = 'DELETE_PENDING_COMMAND_REQUEST';
export const DELETE_PENDING_COMMAND_SUCCESS = 'DELETE_PENDING_COMMAND_SUCCESS';

// Organizations
export const FETCH_ORGANIZATIONS_REQUEST = 'FETCH_ORGANIZATIONS_REQUEST';
export const FETCH_ORGANIZATIONS_SUCCESS = 'FETCH_ORGANIZATIONS_SUCCESS';
export const FETCH_ORGANIZATIONS_FAILURE = 'FETCH_ORGANIZATIONS_FAILURE';
export const CLEAR_ORGANIZATIONS_LOADING = 'CLEAR_ORGANIZATIONS_LOADING';

// Beds/Carts
export const FETCH_BED_CART_REQUEST = 'FETCH_BED_CART_REQUEST';
export const FETCH_BED_CART_SUCCESS = 'FETCH_BED_CART_SUCCESS';
export const DELETE_UHE_BED_CART_REQUEST = 'DELETE_UHE_BED_CART_REQUEST';
export const DELETE_UHE_BED_CART_SUCCESS = 'DELETE_UHE_BED_CART_SUCCESS';
export const DELETE_NON_UHE_BED_CART_REQUEST = 'DELETE_NON_UHE_BED_CART_REQUEST';
export const DELETE_NON_UHE_BED_CART_SUCCESS = 'DELETE_NON_UHE_UNIT_SUCCESS';
export const FETCH_DEVICE_TYPES_REQUEST = 'FETCH_DEVICE_TYPES_REQUEST';
export const FETCH_DEVICE_TYPES_SUCCESS = 'FETCH_DEVICE_TYPES_SUCCESS';
export const FETCH_DEVICE_QR_CODE_REQUEST = 'FETCH_DEVICE_QR_CODE_REQUEST';
export const FETCH_DEVICE_QR_CODE_SUCCESS = 'FETCH_DEVICE_QR_CODE_SUCCESS';
export const FETCH_DEVICE_ADDITIONAL_QR_CODE_REQUEST = 'FETCH_DEVICE_ADDITIONAL_QR_CODE_REQUEST';
export const FETCH_DEVICE_ADDITIONAL_QR_CODE_SUCCESS = 'FETCH_DEVICE_ADDITIONAL_QR_CODE_SUCCESS';
export const CLEAR_BED_CART = 'CLEAR_BED_CART';
export const SAVE_UHE_BEDCART_REQUEST = 'SAVE_UHE_BEDCART_REQUEST';
export const SAVE_UHE_BEDCART_SUCCESS = 'SAVE_UHE_BEDCART_SUCCESS';
export const ADD_BEDCART_REQUEST = 'ADD_BEDCART_REQUEST';
export const ADD_BEDCART_SUCCESS = 'ADD_BEDCART_SUCCESS';
export const FETCH_ACKNOWLEDGED_REQUEST = 'FETCH_ACKNOWLEDGED_REQUEST';
export const FETCH_ACKNOWLEDGED_SUCCESS = 'FETCH_ACKNOWLEDGED_SUCCESS';
export const FETCH_ACKNOWLEDGED_NOTE_RREQUEST = 'FETCH_ACKNOWLEDGED_NOTE_REQUEST';
export const FETCH_ACKNOWLEDGED_NOTE_SUCCESS = 'FETCH_ACKNOWLEDGED_NOTE_SUCCESS';
export const FETCH_ACKNOWLEDGED_FALSE_REQUEST = 'FETCH_ACKNOWLEDGED_FALSE_REQUEST';
export const FETCH_ACKNOWLEDGED_FALSE_SUCCESS = 'FETCH_ACKNOWLEDGED_FALSE_SUCCESS';
export const FETCH_DEVICE_ACTION = 'FETCH_DEVICE_ACTION';
export const UPLOAD_BEDCART_PICTURE_REQUEST = 'UPLOAD_BEDCART_PICTURE_REQUEST';
export const UPLOAD_BEDCART_PICTURE_SUCCESS = 'UPLOAD_BEDCART_PICTURE_SUCCESS';
export const FETCH_BEDCART_IMAGE_REQUEST = 'FETCH_BEDCART_IMAGE_REQUEST';
export const FETCH_BEDCART_IMAGE_SUCCESS = 'FETCH_BEDCART_IMAGE_SUCCESS';
export const FETCH_CARE_DEVICE_MODES = 'FETCH_CARE_DEVICE_MODES';
export const FETCH_CARE_DEVICE_MODES_SUCCESS = 'FETCH_CARE_DEVICE_MODES_SUCCESS';
export const FETCH_CALL_ACTION = 'FETCH_CALL_ACTION';
export const FETCH_CALL_ACTION_SUCCESS = 'FETCH_CALL_ACTION_SUCCESS';
export const DELETE_ROOM_PICTURE = 'DELETE_ROOM_PICTURE';
export const DELETE_ROOM_PICTURE_SUCCESS = 'DELETE_ROOM_PICTURE_SUCCESS';
export const SAVE_BEDCART_FAIL = 'SAVE_BEDCART_FAIL';
export const VERIFY_CONNECTIVITY = 'VERIFY_CONNECTIVITY';
export const VERIFY_CONNECTIVITY_SUCCESS = 'VERIFY_CONNECTIVITY_SUCCESS';
export const FETCH_ACKNOWLEDGED_EMAIL = 'FETCH_AKNOWLEDGED_EMAIL';
export const FETCH_ACKNOWLEDGED_EMAIL_SUCCESS = 'FETCH_AKNOWLEDGED_EMAIL_SUCCESS';
export const FETCH_COMMANDS_STATUS = 'FETCH_COMMANDS_STATUS';
export const FETCH_COMMANDS_STATUS_SUCCESS = 'FETCH_COMMANDS_STATUS_SUCCESS';

// Users
export const CONFIGURATION_USERS_FETCH_DATA = 'CONFIGURATION_USERS_FETCH_DATA';
export const CONFIGURATION_USERS_FETCH_SUCCESS = 'CONFIGURATION_USERS_FETCH_SUCCESS';
export const DELETE_USERS_REQUEST = 'DELETE_USERS_REQUEST';
export const DELETE_USERS_SUCCESS = 'DELETE_USERS_SUCCESS';
export const CLEAR_USER = 'CLEAR_USER';
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const SAVE_USER_REQUEST = 'SAVE_USER_REQUEST';
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS';
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const CONFIGURATION_USERS_FETCH_ACCESS_DATA = 'CONFIGURATION_USERS_FETCH_ACCESS_DATA';
export const CONFIGURATION_USERS_FETCH_ACCESS_SUCCESS = 'CONFIGURATION_USERS_FETCH_ACCESS_SUCCESS';
export const SAVE_PERMISSIONS_REQUEST = 'SAVE_PERMISSIONS_REQUEST';
export const SAVE_PERMISSIONS_SUCCESS = 'SAVE_PERMISSIONS_SUCCESS';
export const FETCH_IMPERSONATE_TOKEN = 'FETCH_IMPERSONATE_TOKEN';
export const FETCH_IMPERSONATE_TOKEN_SUCCESS = 'FETCH_IMPERSONATE_TOKEN_SUCCESS';
export const CLEAR_LOADING = 'CLEAR_LOADING';
export const FETCH_OWN_PROFILE_REQUEST = 'FETCH_OWN_PROFILE_REQUEST';
export const FETCH_OWN_PROFILE_SUCCESS = 'FETCH_OWN_PROFILE_SUCCESS';
export const ACTIVATE_USER_REQUEST = 'ACTIVATE_USER_REQUEST';
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';
export const UPLOAD_USER_DOCUMENT_REQUEST = 'UPLOAD_USER_DOCUMENT_REQUEST';
export const UPLOAD_USER_DOCUMENT_SUCCESS = 'UPLOAD_USER_DOCUMENT_SUCCESS';
export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD';
export const RESET_USER_PASSWORD_SUCCESS = 'RESET_USER_PASSWORD_SUCCESS';
export const CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD';
export const CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD_SUCCESS';
export const FETCH_USER_GRANT_REQUEST = 'FETCH_USER_GRANT_REQUEST';
export const FETCH_USER_GRANT_REQUEST_SUCCESS = 'FETCH_USER_GRANT_REQUEST_SUCCESS';
export const SAVE_USERS_GRANTS_REQUEST = 'SAVE_USERS_GRANTS_REQUEST';
export const SAVE_USERS_GRANTS_REQUEST_SUCCESS = 'SAVE_USERS_GRANTS_REQUEST_SUCCESS';
export const FETCH_UPLOAD_LOGS_REQUEST = 'FETCH_UPLOAD_LOGS_REQUEST';
export const FETCH_UPLOAD_LOGS_SUCCESS = 'FETCH_UPLOAD_LOGS_SUCCESS';
export const FETCH_DETAILED_REPORT_REQUEST = 'FETCH_DETAILED_REPORT_REQUEST';
export const FETCH_USER_PREFERENCES_REQUEST = 'FETCH_USER_PREFERENCES_REQUEST';
export const FETCH_USER_PREFERENCES_SUCCESS = 'FETCH_USER_PREFERENCES_SUCCESS';
export const SAVE_USER_PREFERENCES_REQUEST = 'SAVE_USER_PREFERENCES_REQUEST';
export const SAVE_USER_PREFERENCES_SUCCESS = 'SAVE_USER_PREFERENCES_SUCCESS';

// Mobile Patients
export const CONFIGURATION_MOBILE_PATIENTS_DATA = 'CONFIGURATION_MOBILE_PATIENTS_DATA';
export const CONFIGURATION_MOBILE_PATIENTS_DATA_SUCCESS = 'CONFIGURATION_MOBILE_PATIENTS_DATA_SUCCESS';
export const UPLOAD_MOBILE_PATIENT_DOCUMENT_REQUEST = 'UPLOAD_MOBILE_PATIENT_DOCUMENT_REQUEST';
export const UPLOAD_MOBILE_PATIENT_DOCUMENT_SUCCESS = 'UPLOAD_MOBILE_PATIENT_DOCUMENT_SUCCESS';
export const DELETE_MOBILE_PATIENT_REQUEST = 'DELETE_MOBILE_PATIENT_REQUEST';
export const DELETE_MOBILE_PATIENT_REQUEST_SUCCESS = 'DELETE_MOBILE_PATIENT_REQUEST_SUCCESS';
export const SAVE_MOBILE_PATIENT = 'SAVE_MOBILE_PATIENT';
export const SAVE_MOBILE_PATIENT_SUCCESS = 'SAVE_MOBILE_PATIENT_SUCCESS';
export const FETCH_MOBILE_PATIENT = 'FETCH_MOBILE_PATIENT';
export const FETCH_MOBILE_PATIENT_SUCCESS = 'FETCH_MOBILE_PATIENT_SUCCESS';
export const SAVE_MOBILE_PATIENT_ID = 'SAVE_MOBILE_PATIENT_ID';
export const SAVE_MOBILE_PATIENT_ID_SUCCESS = 'SAVE_MOBILE_PATIENT_ID_SUCCESS';
export const MOBILE_PATIENT_ACCESS_DATA = 'MOBILE_PATIENT_ACCESS_DATA';
export const MOBILE_PATIENT_ACCESS_DATA_SUCCESS = 'MOBILE_PATIENT_ACCESS_DATA_SUCCESS';
export const SAVE_MOBILE_PATIENT_FAIL = 'SAVE_MOBILE_PATIENT_FAIL';
export const FETCH_MDM_TOKEN = 'FETCH_MDM_TOKEN';
export const FETCH_MDM_TOKEN_SUCCESS = 'FETCH_MDM_TOKEN_SUCCESS';
export const CLEAR_MOBILE_USER = 'CLEAR_MOBILE_USER';

// nonUheUnits
export const UPLOAD_SBAPS_DOCUMENT_REQUEST = 'UPLOAD_SBAPS_DOCUMENT_REQUEST';
export const UPLOAD_SBAPS_DOCUMENT_SUCCESS = 'UPLOAD_SBAPS_DOCUMENT_SUCCESS';

// System --> Settings --> System
export const SYSTEM_SETTINGS_SYSTEM_FETCH_DATA = 'SYSTEM_SETTINGS_SYSTEM_FETCH_DATA';
export const SYSTEM_SETTINGS_SYSTEM_FETCH_SUCCESS = 'SYSTEM_SETTINGS_SYSTEM_FETCH_SUCCESS';
export const SAVE_SYSTEM_SETTINGS_SYSTEM_REQUEST = 'SAVE_SYSTEM_SETTINGS_SYSTEM_REQUEST';
export const SAVE_SYSTEM_SETTINGS_SYSTEM_SUCCESS = 'SAVE_SYSTEM_SETTINGS_SYSTEM_SUCCESS';
export const CLEAR_SYSTEM_SETTINGS = 'CLEAR_SYSTEM_SETTINGS';
export const SEND_TEST_EMAIL = 'SEND_TEST_EMAIL';
export const SEND_TEST_EMAIL_SUCCESS = 'SEND_TEST_EMAIL_SUCCESS';
export const FETCH_LICENSING_REQUEST = 'FETCH_LICENSING_REQUEST';
export const FETCH_LICENSING_SUCCESS = 'FETCH_LICENSING_SUCCESS';
export const SEND_LICENSING_KEY_REQUEST = 'SEND_LICENSING_KEY_REQUEST';
export const SEND_LICENSING_KEY_SUCCESS = 'SEND_LICENSING_KEY_SUCCESS';
export const FETCH_APPLICATION_VERSION_REQUEST = 'FETCH_APPLICATION_VERSION_REQUEST';
export const FETCH_APPLICATION_VERSION_SUCCESS = 'FETCH_APPLICATION_VERSION_SUCCESS';
export const FETCH_TWILIO_DATA = 'FETCH_TWILIO_DATA';
export const FETCH_TWILIO_DATA_SUCCESS = 'FETCH_TWILIO_DATA_SUCCESS';
export const DELETE_TWILIO_REQUEST = 'DELETE_TWILIO_REQUEST';
export const DELETE_TWILIO_SUCCESS = 'DELETE_TWILIO_SUCCESS';
export const SAVE_TWILIO = 'SAVE_TWILIO';
export const SAVE_TWILIO_SUCCESS = 'SAVE_TWILIO_SUCCESS';
export const GET_TWILIO_REQUEST = 'GET_TWILIO_REQUEST';
export const GET_TWILIO_SUCCESS = 'GET_TWILIO_SUCCESS';
export const UPDATE_TWILIO_REQUEST = 'UPDATE_TWILIO_REQUEST';
export const UPDATE_TWILIO_SUCCESS = 'UPDATE_TWILIO_SUCCESS';

// iObserver
export const SYSTEM_IOBSERVER_FETCH_DATA = 'SYSTEM_IOBSERVER_FETCH_DATA';
export const SYSTEM_IOBSERVER_FETCH_SUCCESS = 'SYSTEM_IOBSERVER_FETCH_SUCCESS';
export const SAVE_IOBSERVER_SETTINGS = 'SAVE_IOBSERVER_SETTINGS';
export const SAVE_IOBSERVER_SETTINGS_SUCCESS = 'SAVE_IOBSERVER_SETTINGS_SUCCESS';
export const DEFAULT_TURN_SERVERS = 'DEFAULT_TURN_SERVERS';
export const DEFAULT_TURN_SERVERS_SUCCESS = 'DEFAULT_TURN_SERVERS_SUCCESS';
export const CUSTOMER_TURN_SERVER = 'CUSTOMER_TURN_SERVER';
export const CUSTOMER_TURN_SERVER_SUCCESS = 'CUSTOMER_TURN_SERVER_SUCCESS';
export const SAVE_DEFAULT_TURN_SERVER = 'SAVE_DEFAULT_TURN_SERVER';
export const SAVE_DEFAULT_TURN_SERVER_SUCCESS = 'SAVE_DEFAULT_TURN_SERVER_SUCCESS';
export const DELETE_DEFAULT_TURN_SERVER_REQUEST = 'DELETE_DEFAULT_TURN_SERVER_REQUEST';
export const DELETE_DEFAULT_TURN_SERVER_REQUEST_SUCCESS = 'DELETE_DEFAULT_TURN_SERVER_REQUEST_SUCCESS';
export const SAVE_CUSTOMER_TURN_SERVER = 'SAVE_CUSTOMER_TURN_SERVER';
export const SAVE_CUSTOMER_TURN_SERVER_SUCCESS = 'SAVE_CUSTOMER_TURN_SERVER_SUCCESS';
export const DELETE_CUSTOMER_TURN_SERVER_REQUEST = 'DELETE_CUSTOMER_TURN_SERVER_REQUEST';
export const DELETE_CUSTOMER_TURN_SERVER_SUCCESS = 'DELETE_CUSTOMER_TURN_SERVER_SUCCESS';
export const CUSTOMER_TURN_SERVER_BY_ID = 'CUSTOMER_TURN_SERVER_BY_ID';
export const CUSTOMER_TURN_SERVER_BY_ID_SUCCESS = 'CUSTOMER_TURN_SERVER_BY_ID_SUCCESS';
export const CLEAR_IOBSERVER_STATE = 'CLEAR_IOBSERVER_STATE';

// Developer
export const SYSTEM_DEVELOPER_FETCH_DATA = 'SYSTEM_DEVELOPER_FETCH_DATA';
export const SYSTEM_DEVELOPER_FETCH_DATA_SUCCESS = 'SYSTEM_DEVELOPER_FETCH_DATA_SUCCESS';

// Notifications
export const NOTIFICATIONS_REQUEST = 'NOTIFICATIONS_REQUEST';
export const NOTIFICATIONS_REQUEST_SUCCESS = 'NOTIFICATIONS_REQUEST_SUCCESS';
export const GLOBAL_NOTIFICATIONS_REQUEST = 'GLOBAL_NOTIFICATIONS_REQUEST';
export const GLOBAL_NOTIFICATIONS_REQUEST_SUCCESS = 'GLOBAL_NOTIFICATIONS_REQUEST_SUCCESS';
export const UPDATE_GLOBAL_NOTIFICATIONS_REQUEST = 'UPDATE_GLOBAL_NOTIFICATIONS_REQUEST';
export const UPDATE_GLOBAL_NOTIFICATIONS_SUCCESS = 'UPDATE_GLOBAL_NOTIFICATIONS_SUCCES';
export const CUSTOMER_NOTIFICATIONS_REQUEST = 'CUSTOMER_NOTIFICATIONS_REQUEST';
export const CUSTOMER_NOTIFICATIONS_SUCCESS = 'CUSTOMER_NOTIFICATIONS_SUCCESS';
export const UPDATE_CUSTOMER_NOTIFICATIONS_REQUEST = 'UPDATE_CUSTOMER_NOTIFICATIONS_REQUEST';
export const UPDATE_CUSTOMER_NOTIFICATIONS_SUCCESS = 'UPDATE_CUSTOMER_NOTIFICATIONS_SUCCESS';

// System --> Settings --> Support
export const CREATE_SUPPORT_VIDEO_REQUEST = 'CREATE_SUPPORT_VIDEO_REQUEST';
export const CREATE_SUPPORT_VIDEO_SUCCESS = 'CREATE_SUPPORT_VIDEO_SUCCESS';
export const DELETE_SUPPORT_VIDEO_REQUEST = 'DELETE_SUPPORT_VIDEO_REQUEST';
export const DELETE_SUPPORT_VIDEO_SUCCESS = 'DELETE_SUPPORT_VIDEO_SUCCESS';
export const SUPPORT_DOCUMENT_FETCH_DATA = 'SUPPORT_DOCUMENT_FETCH_DATA';
export const SUPPORT_DOCUMENT_FETCH_DATA_SUCCESS = 'SUPPORT_DOCUMENT_FETCH_DATA_SUCCESS';
export const CREATE_SUPPORT_DOCUMENT_REQUEST = 'CREATE_SUPPORT_DOCUMENT_REQUEST';
export const CREATE_SUPPORT_DOCUMENT_SUCCESS = 'CREATE_SUPPORT_DOCUMENT_SUCCESS';
export const SUPPORT_SECTION_FETCH_DATA = 'SUPPORT_SECTION_FETCH_DATA';
export const SUPPORT_SECTION_FETCH_SUCCESS = 'SUPPORT_SECTION_FETCH_SUCCESS';
export const DOWNLOAD_PDF_REQUEST = 'DOWNLOAD_PDF_REQUEST';
export const DOWNLOAD_PDF_SUCCESS = 'DOWNLOAD_PDF_SUCCESS';
export const DELETE_SUPPORT_DOCUMENT_REQUEST = 'DELETE_SUPPORT_DOCUMENT_REQUEST';
export const DELETE_SUPPORT_DOCUMENT_SUCCESS = 'DELETE_SUPPORT_DOCUMENT_SUCCESS';
export const CLEAR_SUPPORT_STATE = 'CLEAR_SUPPORT_STATE';
export const CREATE_SUPPORT_SECTION_REQUEST = 'CREATE_SUPPORT_SECTION_REQUEST';
export const CREATE_SUPPORT_SECTION_SUCCESS = 'CREATE_SUPPORT_SECTION_SUCCESS';
export const DELETE_SUPPORT_SECTION_REQUEST = 'DELETE_SUPPORT_SECTION_REQUEST';
export const DELETE_SUPPORT_SECTION_SUCCESS = 'DELETE_SUPPORT_SECTION_SUCCESS';
export const SUPPORT_DOCUMENT_FETCH_DATA_SEARCH = 'SUPPORT_DOCUMENT_FETCH_DATA_SEARCH';
export const SUPPORT_DOCUMENT_FETCH_DATA_SEARCH_SUCCESS = 'SUPPORT_DOCUMENT_FETCH_DATA_SEARCH_SUCCESS';

// Sign In Summary Reports
export const FETCH_SIGNIN_REPORTS_REQUEST = 'FETCH_SIGNIN_REPORTS_REQUEST';
export const FETCH_SIGNIN_REPORTS_SUCCESSS = 'FETCH_SIGNIN_REPORTS_SUCCESSS';

// VMR Report
export const FETCH_VMR_REPORT = 'FETCH_VMR_REPORT';
export const FETCH_VMR_REPORT_SUCCESS = 'FETCH_VMR_REPORT_SUCCESS';

// SSO Providers
export const SSO_PROVIDERS_FETCH_DATA = 'SSO_PROVIDERS_FETCH_DATA';
export const SSO_PROVIDERS_FETCH_DATA_SUCCESS = 'SSO_PROVIDERS_FETCH_DATA_SUCCESS';
export const SAVE_SSO_PROVIDER = 'SAVE_SSO_PROVIDER';
export const SAVE_SSO_PROVIDER_SUCCESS = 'SAVE_SSO_PROVIDER_SUCCESS';
export const SAVE_SSO_PROVIDER_ID = 'SAVE_SSO_PROVIDER_ID';
export const SAVE_SSO_PROVIDER_ID_SUCCESS = 'SAVE_SSO_PROVIDER_ID_SUCCESS';
export const DELETE_SSO_PROVIDER_REQUEST = 'DELETE_SSO_PROVIDER_REQUEST';
export const DELETE_SSO_PROVIDER_REQUEST_SUCCESS = 'DELETE_SSO_PROVIDER_REQUEST_SUCCESS';
export const SAVE_SAML_PROVIDER = 'SAVE_SAML_PROVIDER';
export const SAVE_SAML_PROVIDER_SUCCESS = 'SAVE_SAML_PROVIDER_SUCCESS';

// Saml Providers
export const SAML_PROVIDERS_FETCH_DATA = 'SAML_PROVIDERS_FETCH_DATA';
export const SAML_PROVIDERS_FETCH_DATA_SUCCESS = 'SAML_PROVIDERS_FETCH_DATA_SUCCESS';

// Domains
export const DOMAIN_FETCH_DATA = 'DOMAIN_FETCH_DATA';
export const DOMAIN_FETCH_DATA_SUCCESS = 'DOMAIN_FETCH_DATA_SUCCESS';
export const SAVE_DOMAIN_DATA = 'SAVE_DOMAIN_DATA';
export const SAVE_DOMAIN_DATA_SUCCESS = 'SAVE_DOMAIN_DATA_SUCCESS';
export const SAVE_DOMAIN_ID = 'SAVE_DOMAIN_ID';
export const SAVE_DOMAIN_ID_SUCCESS = 'SAVE_DOMAIN_ID_SUCCESS';
export const DELETE_DOMAIN_REQUEST = 'DELETE_DOMAIN_REQUEST';
export const DELETE_DOMAIN_REQUEST_SUCCESS = 'DELETE_DOMAIN_REQUEST_SUCCESS';
export const CLEAR_DOMAIN_STATE = 'CLEAR_DOMAIN_STATE';

// Change own password
export const FETCH_PASSWORD_DATA = 'FETCH_PASSWORD_DATA';
export const FETCH_PASSWORD_DATA_SUCCESS = 'FETCH_PASSWORD_DATA_SUCCESS';

// Programs
export const PROGRAMS_LISTING_REQUEST = 'PROGRAMS_LISTING_REQUEST';
export const PROGRAMS_LISTING_SUCCESS = 'PROGRAMS_LISTING_SUCCESS';
export const DELETE_PROGRAM_REQUEST = 'DELETE_PROGRAM_REQUEST';
export const DELETE_PROGRAM_SUCCESS = 'DELETE_PROGRAM_SUCCESS';
export const SAVE_PROGRAM = 'SAVE_PROGRAM';
export const SAVE_PROGRAM_SUCCESS = 'SAVE_PROGRAM_SUCCESS';
export const GET_PROGRAM_REQUEST = 'GET_PROGRAM_REQUEST';
export const GET_PROGRAM_SUCCESS = 'GET_PROGRAM_SUCCESS';
export const UPDATE_PROGRAM_REQUEST = 'UPDATE_PROGRAM_REQUEST';
export const UPDATE_PROGRAM_SUCCESS = 'UPDATE_PROGRAM_SUCCESS';
export const PROGRAM_GRANTS_REQUEST = 'PROGRAM_GRANTS_REQUEST';
export const PROGRAM_GRANTS_SUCCESS = 'PROGRAM_GRANTS_SUCCESS';
export const CLEAR_PROGRAM_STATE = 'CLEAR_PROGRAM_STATE';
export const UPDATE_PROGRAM_GRANTS_REQUEST = 'UPDATE_PROGRAM_GRANTS_REQUEST';
export const UPDATE_PROGRAM_GRANTS_SUCCESS = 'UPDATE_PROGRAM_GRANTS_SUCCESS';
export const EXCLUDED_FACILITIES_REQUEST = 'EXCLUDED_FACILITIES_REQUEST';
export const EXCLUDED_FACILITIES_SUCCESS = 'EXCLUDED_FACILITIES_SUCCESS';
export const UPLOAD_PROGRAM_DOCUMENT_REQUEST = 'UPLOAD_PROGRAM_DOCUMENT_REQUEST';
export const UPLOAD_PROGRAM_DOCUMENT_SUCCESS = 'UPLOAD_PROGRAM_DOCUMENT_SUCCESS';

// User Programs
export const USER_PROGRAMS_LISTING_REQUEST = 'USER_PROGRAMS_LISTING_REQUEST';
export const USER_PROGRAMS_LISTING_SUCCESS = 'USER_PROGRAMS_LISTING_SUCCESS';
export const USER_PROGRAMS_LISTING_RESET = 'USER_PROGRAMS_LISTING_RESET';

// Advanced Dev
export const MACHINE_INFO_REQUEST = 'MACHINE_INFO_REQUEST';
export const MACHINE_INFO_SUCCESS = 'MACHINE_INFO_SUCCESS';
export const SEND_ADVANCED_DEV_COMMAND = 'SEND_ADVANCED_DEV_COMMAND';

// Announcements
export const GET_ANNOUNCEMENTS_LIST_REQUEST = 'GET_ANNOUNCEMENTS_LIST_REQUEST';
export const GET_ANNOUNCEMENTS_LIST_SUCCESS = 'GET_ANNOUNCEMENTS_LIST_SUCCESS';
export const DELETE_ANNOUNCEMENT_REQUEST = 'DELETE_ANNOUNCEMENT_REQUEST';
export const DELETE_ANNOUNCEMENT_SUCCESS = 'DELETE_ANNOUNCEMENT_SUCCESS';
export const CREATE_ANNOUNCEMENT_REQUEST = 'CREATE_ANNOUNCEMENT_REQUEST';
export const UPDATE_ANNOUNCEMENT_REQUEST = 'UPDATE_ANNOUNCEMENT_REQUEST';
export const UPDATE_ANNOUNCEMENT_SUCCESS = 'UPDATE_ANNOUNCEMENT_SUCCESS';
