import {
  CREATE_SUPPORT_VIDEO_REQUEST,
  CREATE_SUPPORT_VIDEO_SUCCESS,
  DELETE_SUPPORT_VIDEO_REQUEST,
  DELETE_SUPPORT_VIDEO_SUCCESS,
  CREATE_SUPPORT_DOCUMENT_REQUEST,
  CREATE_SUPPORT_DOCUMENT_SUCCESS,
  SUPPORT_DOCUMENT_FETCH_DATA,
  SUPPORT_DOCUMENT_FETCH_DATA_SUCCESS,
  DELETE_SUPPORT_DOCUMENT_REQUEST,
  DELETE_SUPPORT_DOCUMENT_SUCCESS,
  CREATE_SUPPORT_SECTION_REQUEST,
  CREATE_SUPPORT_SECTION_SUCCESS,
  SUPPORT_SECTION_FETCH_DATA,
  SUPPORT_SECTION_FETCH_SUCCESS,
  DELETE_SUPPORT_SECTION_REQUEST,
  DELETE_SUPPORT_SECTION_SUCCESS,
  DOWNLOAD_PDF_REQUEST,
  DOWNLOAD_PDF_SUCCESS,
  CLEAR_SUPPORT_STATE,
  SUPPORT_DOCUMENT_FETCH_DATA_SEARCH_SUCCESS,
} from '@constants/UHEActionTypes';

const INIT_STATE = {
  documentData: {
    list: [],
    page: {},
  },
  sectionData: {
    list: [],
    page: {},
  },
  searchDocumentData: {
    list: [],
    page: {},
  },
  loading: false,
};

/**
 * Adds newly uploaded document to existing list and increments section documents count
 * @param {object} state Previous state
 * @param {string} id Id of the new document
 * @param {string} name Name of the document
 * @param {string} filename Filename of the document
 * @param {string} sectionId Section of the document
 * @returns {object} New state
 */
function createSupportDocumentSuccess(state, id, name, filename, sectionId) {
  const uploadedDocument = {
    id,
    name,
    document: filename,
    section: {
      id: sectionId,
      name: state.sectionData.list.find((section) => section.id === sectionId).name,
    },
  };
  return {
    ...state,
    loading: false,
    documentData: {
      ...state.documentData,
      list: [
        ...state.documentData.list,
        uploadedDocument,
      ],
    },
    sectionData: {
      ...state.sectionData,
      list: state.sectionData.list
        .map((section) => (section.id === sectionId
          ? { ...section, documents: section.documents + 1 }
          : section)),
    },
  };
}

/**
 * Removes the deleted document from the list and decrements section documents count
 * @param {object} state Previous state
 * @param {string} id Deleted document id
 * @returns {object} New state
 */
function deleteSupportDocumentSuccess(state, id) {
  const deletedDocument = state.documentData.list.find((document) => document.id !== id);
  return {
    ...state,
    loading: false,
    documentData: {
      ...state.documentData,
      list: state.documentData.list.filter((document) => document.id !== id),
    },
    sectionData: {
      ...state.sectionData,
      list: state.sectionData.list
        .map((document) => (document.id === deletedDocument.section.id
          ? { ...document, documents: document.documents - 1 }
          : document)),
    },
  };
}

/**
 * Support reducer
 * @param  {Object} state current state
 * @param  {{type: string, payload: {}}} action action
 * @return {Object} updated state
 */
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_SUPPORT_VIDEO_REQUEST:
      return { ...state, loading: true };

    case CREATE_SUPPORT_VIDEO_SUCCESS:
      return { ...state, loading: false, savedVideoId: action.payload.id };

    case DELETE_SUPPORT_VIDEO_REQUEST:
      return { ...state, loading: true };

    case DELETE_SUPPORT_VIDEO_SUCCESS:
      return { ...state, loading: false };

    case SUPPORT_DOCUMENT_FETCH_DATA_SUCCESS:
      return {
        ...state,
        documentData: {
          ...state.documentData,
          list: action.data.list,
          page: action.data.page,
        },
        loading: false,
      };
    case SUPPORT_DOCUMENT_FETCH_DATA_SEARCH_SUCCESS:
      return {
        ...state,
        searchDocumentData: {
          ...state.searchDocumentData,
          list: action.data.list,
          page: action.data.page,
        },
        loading: false,
      };

    case SUPPORT_DOCUMENT_FETCH_DATA:
      return { ...state, loading: true, saved: false };

    case SUPPORT_SECTION_FETCH_SUCCESS:
      return { ...state, sectionData: action.data, loading: false };

    case SUPPORT_SECTION_FETCH_DATA:
      return { ...state, loading: true, saved: false };

    case DOWNLOAD_PDF_REQUEST:
      return { ...state, saved: false };

    case DOWNLOAD_PDF_SUCCESS:
      return { ...state, sectionData: action.data };

    case CREATE_SUPPORT_DOCUMENT_REQUEST:
      return { ...state, loading: true, saved: false };

    case CREATE_SUPPORT_DOCUMENT_SUCCESS:
      return createSupportDocumentSuccess(
        state,
        action.payload.uploadedDocumentId,
        action.payload.name,
        action.payload.filename,
        action.payload.sectionId,
      );

    case DELETE_SUPPORT_DOCUMENT_REQUEST:
      return { ...state, loading: true };

    case DELETE_SUPPORT_DOCUMENT_SUCCESS:
      return deleteSupportDocumentSuccess(state, action.payload.id);

    case CREATE_SUPPORT_SECTION_REQUEST:
      return { ...state, loading: true };

    case CREATE_SUPPORT_SECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        sectionData: state.sectionData.list
          .map((section) => (section.id === action.payload.id ? action.payload : section)),
      };

    case DELETE_SUPPORT_SECTION_REQUEST:
      return { ...state, loading: true };

    case DELETE_SUPPORT_SECTION_SUCCESS:
      return { ...state, loading: false };

    case CLEAR_SUPPORT_STATE:
      return { ...INIT_STATE };
    default:
      return state;
  }
};
