import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Table, Collapse, Select, Checkbox, Dropdown, Button, Pagination,
} from 'antd';
import {
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import Countdown from 'react-countdown';
import lodash from 'lodash';
import RestManager from '@util/RestManager';
import { BASE_URL, ENDPOINTS } from '@constants/UHEEndpoints';

import IntlMessages from 'util/IntlMessages';
import ListingsTableInputFilter from '@filters/ListingsTableInputFilter';
import MapClustering from '@components/map/googlemap/mapClustering';

import OrganizationCell from '@components/tables/cells/OrganizationCell';
import CustomerCell from '@components/tables/cells/CustomerCell';
import FacilityCell from '@components/tables/cells/FacilityCell';
import UnitCell from '@components/tables/cells/UnitCell';
import VersionCell from '@components/tables/cells/VersionCell';
import InCallCell from '@components/tables/cells/InCallCell';
import IsLiveCell from '@components/tables/cells/IsLiveCell';
import StatusCell from '@components/tables/cells/StatusCell';
import LastEventCell from '@components/tables/cells/LastEventCell';
import UheUnitCell from '@components/tables/cells/UheUnitCell';
import GridViewCard from '@components/uhe/GridViewCard';
import { onFetchOwnPofile, saveUserPreferences, getUserPreferences } from '@uhe_actions/configuration/users/UsersActions';

import { onFetchData, onFetchPieChartData, onFetchGmapData } from '@uhe_actions/monitoring/UHEActions';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import { systemOnFetchData } from '@uhe_actions/system/SystemActions';
import {
  LISTING_TABLES_PAGE_SIZE,
  TOP_FILTER_PREFIX,
  TABLE_FILTER_PREFIX,
  MAP_FILTER_PREFIX,
  NAV_STYLE_FIXED,
} from '@constants/UHESettings';
import {
  COUNTDOWN_TIMER_VALUE,
} from '@constants/SystemConstants';
import { getCurrentSort, statusIcon } from '@util/UheHelper';
import { fetchBedCart } from '@uhe_actions/configuration/bedsCarts/bedsCartsActions';
import { fetchCustomer } from '@uhe_actions/configuration/customers/CustomersActions';
import ApiUserPreferences from '@containers/uhe/Configuration/ManageUsers/Models/ApiUserPreferences';
import { ReactComponent as ColumnViewIcon } from '@assets/images/column_view.svg';
import { ReactComponent as GridViewIcon } from '@assets/images/grid_view.svg';
import { ReactComponent as ColumnCustomizeIcon } from '@assets/images/column_customize.svg';
import chart from '@assets/images/chart.svg';
import chart_on from '@assets/images/chart_on.svg';
import UheChart from '@routes/Uhe/UheChart';
import SidePanelCard from '@routes/Uhe/SidePanelCard';

const { Panel } = Collapse;

/**
   * Show caller name in In Call column
   * @param  {Boolean} inCall true/false
   * @param  {String} clinicianName caller name
   * @return {void}
   */
const showInCallName = (inCall, clinicianName) => {
  if (inCall === 1 && clinicianName) {
    const trimmedUser = clinicianName.trim();
    if (trimmedUser) {
      return clinicianName;
    }
    return (
      <div>
        <i className="icon icon-radiobutton inCall-icon" />
      </div>
    );
  }
};

/**
 * Renders UHE page
 */
class Uhe extends React.Component {
  /**
   * Adapt data returned from the server
   * @param  {Array<Object>} data to adapt
   * @param  {Array<Object>} profile user profile
   * @return {Array<Object>} adapted data
   */
  static dataAdapter(data = []) {
    const adaptedData = [];

    data.forEach((value, index) => {
      adaptedData.push({
        key: index,
        organization: {
          id: value.organization_id,
          name: value.organization_name,
        },
        customer: {
          id: value.customer_id,
          organizationId: value.organization_id,
          name: value.customer_name,
        },
        facility: {
          id: value.facility_id,
          organizationId: value.organization_id,
          customerId: value.customer_id,
          name: value.facility_name,
        },
        unit: {
          id: value.unit_id,
          organizationId: value.organization_id,
          customerId: value.customer_id,
          facilityId: value.facility_id,
          name: value.unit_name,
        },
        bedCart: {
          id: value.device_id,
          name: value.cart_name,
          customerId: value.customer_id,
        },
        deviceId: value.device_id,
        type: value.type,
        version: value.version,
        inCall: showInCallName(value.in_a_call, value.named_clinician_in_call),
        isLive: value.is_live === 0
          ? (
            <div className="table-popover">
              <p>
                <IntlMessages id="common.mode" />
                :
                {' '}
                {value.device_config_mode}
              </p>
              <p>
                <IntlMessages id="configuration.bedsCarts.deviceConfig.caregilityMonitor" />
                :
                {' '}
                {value.is_live ? 'ON' : 'OFF'}
              </p>
              <p>
                <IntlMessages id="configuration.bedsCarts.deviceConfig.customerNotification" />
                :
                {' '}
                {value.customer_auto_notification ? 'ON' : 'OFF'}
              </p>
            </div>
          )
          : '',
        lastEvent: value.last_event,
        status: {
          status: value.status,
          statusColor: value.status_color,
        },
        acknowledgedBy: <div title={value.acknowledged_by}>
          {value.acknowledged_by && (value.acknowledged_notes || value.acknowledged_by)}
        </div>,
      });
    });
    return adaptedData;
  }

  dropDownOptions = [
    {
      key: 'total', label: <IntlMessages id="uhe.pie.totalDevices" />, value: 'total',
    },
    {
      key: 'healthy', label: <IntlMessages id="uhe.pie.healthy" />, value: 'healthy',
    },
    {
      key: 'not_configured', label: <IntlMessages id="uhe.pie.not_configured" />, value: 'not_configured',
    },
    {
      key: 'unhealthy', label: <IntlMessages id="uhe.pie.inAlarm" />, value: 'unhealthy',
    },
  ]

  /**
   * Uhe Constructor
   * @param {object} props Props
   */
  constructor(props) {
    super(props);
    this.onPageChange = this.onPageChange.bind(this);
    this.date = Date.now();
    const { intl, history } = this.props;

    this.topFilterMap = {
      [`${TOP_FILTER_PREFIX}organization`]: 'organization_id',
      [`${TOP_FILTER_PREFIX}customer`]: 'customer_id',
      [`${TOP_FILTER_PREFIX}facility`]: 'facility_id',
      [`${TOP_FILTER_PREFIX}unit`]: 'unit_id',
    };

    this.tableFilterMap = {
      organization: 'organization_name',
      customer: 'customer_name',
      facility: 'facility_name',
      unit: 'unit_name',
      bedCart: 'cart_name',
      deviceId: 'device_id',
      status: 'status',
      type: 'type',
      version: 'version',
      inCall: 'in_a_call',
      lastEvent: 'last_event',
      isLive: 'is_live',
      acknowledgedBy: 'acknowledged_by',
    };

    this.numberInputs = {
      deviceId: 'number',
    };

    this.locationFilterMap = {
      longitude_min: 'longitude_min',
      latitude_min: 'latitude_min',
      longitude_max: 'longitude_max',
      latitude_max: 'latitude_max',
    };

    this.data = [];
    this.columns = [];
    this.tableKeys = Object.keys(this.tableFilterMap);
    this.filterTypes = {
      status: {
        type: 'dropdown',
        options: [
          { value: 'not_configured', label: intl.formatMessage({ id: 'uhe.table.filter.status.not_configured' }) },
          { value: 'healthy', label: intl.formatMessage({ id: 'uhe.table.filter.status.online' }) },
          { value: 'unhealthy', label: intl.formatMessage({ id: 'uhe.table.filter.status.unhealthy' }) },
        ],
      },
      inCall: {
        type: 'dropdown',
        options: [
          { value: '1', label: intl.formatMessage({ id: 'common.yes' }) },
          { value: '0', label: intl.formatMessage({ id: 'common.no' }) },
        ],
      },
      isLive: {
        type: 'dropdown',
        options: [
          { value: '1', label: intl.formatMessage({ id: 'common.yes' }) },
          { value: '0', label: intl.formatMessage({ id: 'common.no' }) },
        ],
      },
      lastEvent: {
        type: 'dropdown',
        options: [
          { value: '1', label: 'to 1 minute' },
          { value: '30', label: 'to 30 minutes' },
          { value: '60', label: 'to 1 hour' },
          { value: '360', label: 'to 6 hours' },
          { value: '720', label: 'to 12 hours' },
          { value: '1440', label: 'to 1 day' },
          { value: '10080', label: 'to 1 week' },
          { value: '44640', label: 'to 1 month' },
          { value: '525600', label: 'to 1 year' },
          { value: '5256000', label: 'to 10 years' },
        ],
      },
    };

    this.tableKeys.forEach((value, index) => {
      const filter = this.filterTypes[value] || {};
      this.columns.push({
        title: (cellData) => (
          <ListingsTableInputFilter
            filterType={filter.type}
            filterOptions={filter.options}
            clearFilters={this.clearFilters}
            cellData={cellData}
            title={`uhe.table.${value}`}
            dataKey={value}
            triggerCharsNum={value === 'deviceId' || value === 'version' ? 0 : undefined}
          />
        ),
        sorter: (value === 'actions') ? false : { multiple: index },
        align: index > 3 ? 'center' : 'left',
        dataIndex: value,
        render: (content) => this.cellRenderer(content, value),
      });
    });

    this.topFilters = [
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byOrganization',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'organization',
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byCustomer',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'customer',
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byFacility',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'facility',
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byUnit',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'unit',
      },
    ];

    this.history = history;
    this.qParams = new URLSearchParams(this.history.location.search);

    this.props.setSubtitle('sidebar.APS');
    this.props.systemOnFetchData();

    this.onPageChange(1);
    this.state = {
      showCounter: true,
      showHideText: intl.formatMessage({ id: 'common.show' }),
      isCollapsed: true,
      isVisible: false,
      columnsUnchecked: {},
      showSidePanel: false,
      selectedRoom: {},
      isGridView: false,
      isColumnView: false,
      selectedPie: 'total',
      showMore: 2,
      selectedId: null,
    };

    this.apsMonitoring = this.history.location.pathname;
    this.tableFooterRef = React.createRef();
  }

  /**
   * Update local state
   * @returns {void}
   */
  componentDidMount() {
    const { userPreferences } = this.props;
    this.tableFooterRef.current.parentNode.parentNode.addEventListener('scroll', this.handleScroll, true);
    const savedPreferences = userPreferences.preferences.find((pref) => Object.keys(pref).find((screen) => screen === '/uhe-units'));
    if (savedPreferences && savedPreferences['/uhe-units']) {
      this.setState({ columnsUnchecked: savedPreferences['/uhe-units'] });
      this.setState({ isCollapsed: savedPreferences['/uhe-units'].isCollapsed });
      this.setState({ isGridView: savedPreferences['/uhe-units'].isGridView });
      this.setState({ isColumnView: savedPreferences['/uhe-units'].isColumnView });
    }

    try {
      const selectedPie = localStorage.getItem('selectedPie');
      if (selectedPie !== null) {
        this.setState({ selectedPie });
        this.onPieClicked(selectedPie, {
          key: selectedPie,
          value: selectedPie,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
 * Updates the component on location change
 * @param {Object} prevProps data
 * @returns {void}
 */
  componentDidUpdate(prevProps) {
    const { location, profile } = this.props;
    const { isGridView, selectedPie } = this.state;
    const scrollableTable = document.getElementsByClassName('scrollable-table')[0];
    const tableResponsive = document.getElementsByClassName('gx-table-responsive')[0];
    if (location.search !== prevProps.location.search) {
      this.qParams = new URLSearchParams(location.search);
      this.onPageChange(1);
    }
    const currStatus = this.qParams.get(`${TABLE_FILTER_PREFIX}status`);
    if (currStatus && currStatus !== this.state.selectedPie) {
      this.setState({
        selectedPie: currStatus,
      });
    }
    const currSelectedPie = localStorage.getItem('selectedPie');
    if (currSelectedPie !== null && currSelectedPie !== selectedPie) {
      this.setState({
        selectedPie: currSelectedPie,
      });
      this.onPieClicked(currSelectedPie, {
        key: currSelectedPie,
        value: currSelectedPie,
      });
    }

    if (this.tableFooterRef.current) {
      this.tableFooterRef.current.parentNode.parentNode.addEventListener('scroll', this.handleScroll, true);
    }

    if (!isGridView && this.tableFooterRef.current) {
      this.tableFooterRef.current.parentNode.style.transform = 'translateX(0)';
      scrollableTable.scrollTop = 0;
      tableResponsive.scrollLeft = 0;
    }
  }

  /**
   * Remove Event Listener
   * @returns {void}
   */
  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    if (this.tableFooterRef.current) {
      document.getElementsByClassName('gx-table-responsive')[0].removeEventListener('scroll', this.handleScroll);
    }
  }

  /**
   * Updates the page
   * @param  {number} page page
   * @return {void}
   */
  onPageChange(page) {
    this.currentPage = page - 1;
    const currSort = this.qParams.getAll('sort') || [];
    const filter = [];

    lodash.forOwn(this.topFilterMap, (value, key) => {
      const filterParam = this.qParams.get(key);
      if (filterParam) {
        filter.push(`${value}=${filterParam}`);
      }
    });

    lodash.forOwn(this.tableFilterMap, (value, key) => {
      const filterParam = this.qParams.get(`${TABLE_FILTER_PREFIX}${key}`);

      if (filterParam) {
        if (this.filterTypes[key] && this.filterTypes[key].type === 'dropdown') {
          filter.push(`${value}=${encodeURIComponent(filterParam)}`);
        } else if (this.numberInputs[key]) {
          filter.push(`${value}=${encodeURIComponent(Number(filterParam))}`);
        } else {
          filter.push(`${value}~=${encodeURIComponent(`%${filterParam}%`)}`);
        }
      }
    });

    const sort = getCurrentSort(currSort, this.tableFilterMap);

    lodash.forOwn(this.locationFilterMap, (value, key) => {
      const filterParam = this.qParams.get(`${MAP_FILTER_PREFIX}${key}`);

      if (filterParam) {
        filter.push(`${value}=${encodeURIComponent(filterParam)}`);
      }
    });

    this.sort = sort;
    this.filter = filter;

    this.setState({ showMore: page + 1 });
    this.props.onFetchData(page - 1, sort, filter);
  }

  /**
   * Clears the filters for piechart/dropdown
   * @returns {void}
   */
     clearFilters = () => {
       this.setState({
         selectedPie: 'total',
       });
       this.qParams.delete(`${TABLE_FILTER_PREFIX}inCall`);
       this.qParams.delete(`${TABLE_FILTER_PREFIX}status`);

       this.history.push({ search: this.qParams.toString() });
       localStorage.setItem('selectedPie', 'total');
     }

     /**
   * Handle click on Pie Chart/dropdown
   * @param  {Object} data data
   * @param  {Object} option option
   * @return {void}
   */
   onPieClicked = (data, option) => {
     if (data.key === 'total' || option.key === 'total') {
       this.clearFilters();
     } else {
       this.setState({
         selectedPie: data.key === 'inCall' ? 'healthy' : data.key || option.key,
       });

       this.qParams.delete(`${TABLE_FILTER_PREFIX}status`);
       this.qParams.delete(`${TABLE_FILTER_PREFIX}inCall`);
       this.history.push({ search: this.qParams.toString() });

       if (data.key === 'inCall') {
         this.qParams.set(`${TABLE_FILTER_PREFIX}inCall`, '1');
         localStorage.setItem('selectedPie', 'healthy');
       } else {
         this.qParams.set(`${TABLE_FILTER_PREFIX}status`, data.key || option.key);
         localStorage.setItem('selectedPie', data.key || option.key);
       }
       this.history.push({ search: this.qParams.toString() });
     }
   }

  /**
   * Get endpoint url for data export
   * @returns {string} string
   */
  getCsvUrl = () => {
    const token = RestManager.getToken();

    const filterQueryString = this.filter
      && this.filter.length ? `&${this.filter.join('&')}` : '';
    const sortingQueryString = this.sorting
      && this.sorting.length ? `&sort=${this.sorting.join('&sort=')}` : '';
    const tokenParam = `${filterQueryString || sortingQueryString ? `&token=${token}` : `token=${token}`}`;
    return `${BASE_URL}${ENDPOINTS.monitoring.UHETable}/csv?${filterQueryString}${sortingQueryString}${tokenParam}`;
  }

  /**
   * Call component's actions when finish count end
   * @return {void}
   */
  onFinishCount = () => {
    const { onFetchPieChartData, onFetchGmapData } = this.props;
    onFetchPieChartData(this.filter);
    onFetchGmapData(this.filter);
    this.onPageChange(this.currentPage + 1);
    this.setState({ showCounter: false }, () => {
      this.timeout = setTimeout(() => {
        this.date = Date.now();
        this.setState({ showCounter: true });
        this.timeout = null;
      }, 200);
    });
  }

  /**
   * Change header title when collapse
   * @param {Array} event unique key of panel
   * @returns {String} changed string
   */
    onColapseChange = () => {
      const { saveUserPreferences } = this.props;
      const { columnsUnchecked } = this.state;
      this.setState((prevState) => {
        const userPreferences = new ApiUserPreferences({ screen: this.apsMonitoring, preferences: { ...columnsUnchecked, isCollapsed: !prevState.isCollapsed } });
        saveUserPreferences(userPreferences);
        return { isCollapsed: !prevState.isCollapsed };
      });
    };

   /**
   * Opens the side panel and fetches the information
   * @param {Object} row the selectedrow
   * @returns {void}
   */
   onClickRow = (row) => {
     const { id, customerId } = row;
     const { fetchBedCart, fetchCustomer } = this.props;

     fetchBedCart({ id });
     fetchCustomer({ id: customerId });
     this.setState({
       showSidePanel: true,
       selectedRoom: row,
     });
   }

   /**
   * Closes the side panel
   * @returns {void}
   */
   onClickClosePanel = () => {
     this.setState({
       showSidePanel: false,
     });
   }

   /**
   * Use different cell type depending on the column
   * @param  {Object} content content
   * @param  {string} key key
   * @return {ReactElement|Object} object
   */
   cellRenderer(content, key) {
     const { latestVersion, location } = this.props;
     let cell;
     switch (key) {
       case 'organization':
         cell = <OrganizationCell content={content} />;
         break;
       case 'customer':
         cell = <CustomerCell content={content} />;
         break;
       case 'facility':
         cell = <FacilityCell content={content} />;
         break;
       case 'unit':
         cell = <UnitCell content={content} location={location} />;
         break;
       case 'bedCart':
         cell = <UheUnitCell content={content} />;
         break;
       case 'version':
         cell = <VersionCell content={parseFloat(content) || null} latestVersion={latestVersion} />;
         break;
       case 'inCall':
         cell = <InCallCell content={content} />;
         break;
       case 'isLive':
         cell = <IsLiveCell content={content} />;
         break;
       case 'lastEvent':
         cell = <LastEventCell content={content} />;
         break;
       case 'status':
         cell = <StatusCell content={content} />;
         break;

       default:
         cell = content;
     }

     return cell;
   }

   /**
   * This Method is Passed to the GridViewCard Component - Based on the ID Sets ClassName
   * @param {number} id Device ID
   * @returns {void}
   */
   toggleActiveClass = (id) => {
     this.setState({
       selectedId: id,
     });
   }

   /**
   * Save User preferences
   * @returns {Object} data
   */
   savePreferences() {
     const { columnsUnchecked } = this.state;
     const { saveUserPreferences } = this.props;
     const userPreferences = new ApiUserPreferences({ screen: this.apsMonitoring, preferences: columnsUnchecked });
     saveUserPreferences(userPreferences);
   }

   /**
   * If it's true and show column
   * @param {Object} event of checkbox
   * @param {string} name of column
   * @returns {Object} data
   */
   onColumnOptionSelect = (event, name) => {
     const { columnsUnchecked } = this.state;
     const { saveUserPreferences } = this.props;
     Object.keys(columnsUnchecked).forEach((value) => {
       if (name === 'all') {
         if (!event.target.checked) {
           columnsUnchecked[value] = false;
           this.setState({ columnsUnchecked });
         } else {
           columnsUnchecked[value] = true;
           this.setState({ columnsUnchecked });
         }
       } else {
         this.setState({ columnsUnchecked: { ...columnsUnchecked, [name]: event.target.checked } }, function () {
           this.savePreferences();
         });
       }
     });
   }

   /**
   * Get checked columns in new array
   * @returns {Array} checked column options
   */
   getColumns() {
     const { columnsUnchecked } = this.state;
     const result = [];

     this.columns.forEach((value, index) => {
       if ((!columnsUnchecked.hasOwnProperty(value.dataIndex) || columnsUnchecked[value.dataIndex]) && (!columnsUnchecked.hasOwnProperty('all') || columnsUnchecked.all)) {
         result.push(value);
       }
     });

     return result;
   }

  /**
   * Customize button click event
   * @returns {Boolean} isvisible
   */
  onCustomizeColumnButtonClicked = () => {
    const { isVisible } = this.state;
    this.setState({ isVisible: true });
    if (isVisible) {
      this.setState({ isVisible: false });
    }
  }

  /**
   * Map customize columns
   * @param {Array} columns Columns
   * @return {JSX} JSX Element
   */
  columnsMapper = (columns) => {
    const { columnsUnchecked } = this.state;
    const { intl } = this.props;

    const mappedColumns = columns.map((value, index) => {
      const check = Object.keys(columnsUnchecked).find((key) => {
        let column;
        if (key === value) {
          column = columnsUnchecked[key];
        }

        return column;
      });

      return (
        <Col>
          <Checkbox
            defaultChecked="true"
            className="column-item"
            checked={check}
            onChange={(e) => this.onColumnOptionSelect(e, value, index)}
          >
            {intl.formatMessage({ id: `uhe.table.${value}` })}
          </Checkbox>
        </Col>
      );
    });

    return mappedColumns;
  }

  /**
   * Render the list of columns options
   * @returns {JSX} element
   */
  renderMenuItems = () => {
    const columnNames = Object.keys(this.tableFilterMap);
    const middleIndex = Math.ceil(columnNames.length / 2);
    const firstHalf = columnNames.splice(0, middleIndex);
    firstHalf.unshift('all');
    const secondHalf = columnNames.splice(-middleIndex);

    const mappedColumnNames = this.columnsMapper(firstHalf);
    const secondMappedColumns = this.columnsMapper(secondHalf);
    return (
      <Row
        multiple
        gutter={16}
        className="wrapper-customize-clumns"
      >
        {mappedColumnNames}
        {secondMappedColumns}
      </Row>
    );
  }

  /**
  * Handles horizontal scroll in table
  * @param {event} event table
  * @returns {void}
  */
  handleScroll = (event) => {
    const { isGridView } = this.state;
    const currentScroll = event.target.scrollLeft;
    if (this.tableFooterRef.current && !isGridView) {
      this.tableFooterRef.current.parentNode.style.transform = `translateX(${currentScroll}px)`;
    }
  };

  /*
* Adapts Data from the BE into Grid View Cards
   * @returns {JSX|null} Grid View Card or Null
   */
gridViewDataAdapter = () => {
  const {
    data, loading, sideMenu, latestVersion,
  } = this.props;
  const { selectedId } = this.state;

  const card = data.map((item) => {
    const bedCart = {
      id: item.device_id,
      name: item.cart_name,
      customerId: item.customer_id,
    };
    return (
      <Col
        key={item.device_id}
        className="grid-view-wrapper"
        xxl={sideMenu === NAV_STYLE_FIXED ? 4 : 3}
        xl={sideMenu === NAV_STYLE_FIXED ? 6 : 4}
        lg={5}
        sm={5}
        xs={24}
        onClick={() => { this.onClickRow(bedCart); }}
      >
        <GridViewCard
          room={item.cart_name}
          deviceType={item.type}
          deviceId={item.device_id}
          version={parseFloat(item.version) || null}
          customerId={item.customer_id}
          status={item.status}
          latestVersion={latestVersion}
          loading={loading}
          wifiLevel={item?.wifi?.level}
          batteryLevel={item?.battery?.level}
          selectedId={selectedId}
          toggleActiveClass={this.toggleActiveClass}
        />
      </Col>
    );
  });

  return (
    <Row
      gutter={16}
      className="grid-view"
    >
      {card}
    </Row>
  ) || null;
}

/**
 * Handles Changes in the Grid View Button
 * @returns {void}
 */
 toggleGridView = () => {
   const { saveUserPreferences } = this.props;
   const { columnsUnchecked } = this.state;
   this.setState((prevState) => {
     const userPreferences = new ApiUserPreferences({ screen: this.apsMonitoring, preferences: { ...columnsUnchecked, isGridView: true, isColumnView: false } });
     saveUserPreferences(userPreferences);
     return {
       isGridView: !prevState.isGridView,
       isColumnView: false,
       showSidePanel: false,
     };
   });
 }

 /**
 * Handles Column View
  * @returns {void}
 */
 columnViewHandler = () => {
   const { saveUserPreferences } = this.props;
   const { columnsUnchecked } = this.state;
   this.setState((prevState) => {
     const userPreferences = new ApiUserPreferences({ screen: this.apsMonitoring, preferences: { ...columnsUnchecked, isGridView: false, isColumnView: true } });
     saveUserPreferences(userPreferences);
     return {
       isColumnView: true,
       isGridView: false,
       showSidePanel: false,
     };
   });
 }

 /**
  * Renders Uhe Component
  * @returns {JSX.Element} Uhe Component
  */
 render() {
   const {
     selectedBedCart, sidePanellLoading, customerTimeZone,
     pagination, intl, data, profile, loading,
   } = this.props;
   const {
     showCounter, isCollapsed, showSidePanel, selectedRoom, isGridView, showMore, isColumnView, selectedPie, isVisible,
   } = this.state;
   pagination.onChange = this.onPageChange;
   const chartColor = isCollapsed ? 'unSelectedChart' : 'selectedChart';
   const showHideText = (
     <div className={`${chartColor} chart-text-container`}>
       <h1 className="selectedChart chart-title">Monitoring</h1>
       <span className="show-hide-chart-label">
         <img alt="chart" className="chart-icon" src={isCollapsed ? chart : chart_on} />
         {intl.formatMessage({ id: 'common.showHideChart' })}
         <DownOutlined className="down-outlined" style={!isCollapsed ? { transform: 'rotate(180deg)', transition: 'transform 0.3s' } : { transition: 'transform 0.3s' }} />
       </span>
     </div>
   );
   const tableColumns = this.getColumns();
   return (
     <div className="dashboard uhe-units-wrapper">
       <Collapse expandIconPosition="right" onChange={this.onColapseChange} activeKey={isCollapsed ? undefined : ['1']}>
         <Panel className="gmap-chart-panel" header={showHideText} key="1" showArrow={false}>
           <Row className="chart-map-section" gutter={16}>
             <Col className="chart-col">
               <UheChart
                 filter={this.filter}
                 onPieClicked={this.onPieClicked}
                 selectedPie={selectedPie}
                 clearFilters={this.clearFilters}
               />
             </Col>
             <Col className="map-col">
               <MapClustering filter={this.filter} showResetButton />
             </Col>
           </Row>
         </Panel>
       </Collapse>
       <div>
         <div className="gx-d-flex gx-justify-content-between gx-align-items-center table-info">
           <div className="pie-drop-down gx-d-flex gx-align-items-center">
             <div className="dropdown-title gx-d-flex gx-align-items-center">
               <IntlMessages id="uhe.pie.show" />
               {statusIcon(selectedPie)}
             </div>
             <Select
               className="dropdown-select total-devices-select"
               onSelect={this.onPieClicked}
               defaultValue="Total Devices"
               value={selectedPie}
               options={this.dropDownOptions}
             />
           </div>
           <div className="gx-d-flex gx-align-items-center table-view-info">
             <div className="gx-d-flex gx-align-items-center">
               <p className="gx-mr-3">
                 <IntlMessages id="uhe.table.autoRefresh" />
                 {showCounter
                  && (
                    <Countdown
                      onComplete={this.onFinishCount}
                      date={this.date + COUNTDOWN_TIMER_VALUE}
                      renderer={({ seconds }) => (
                        <span className="gx-ml-1">
                          {seconds}
                        </span>
                      )}
                    />
                  )}
               </p>
               <p className="gx-mr-3 matching-results-label">
                 <IntlMessages id="uhe.table.matchingResults" />
                 <span>{pagination.total}</span>
               </p>
             </div>
             <div className="gx-d-flex gx-align-items-center">
               <Dropdown
                 overlay={this.renderMenuItems}
                 trigger={['click']}
                 id="devices"
                 className="drop-down"
               >
                 <Button
                   className="table-view-btn"
                   onClick={this.onCustomizeColumnButtonClicked}
                   icon={<ColumnCustomizeIcon className="table-view-icon" />}
                 >
                   <IntlMessages id="uhe.customizeColumns" />
                   {isVisible ? <UpOutlined className="customize-column-arrow" /> : <DownOutlined className="customize-column-arrow" />}
                 </Button>
               </Dropdown>
               <Button
                 className={`table-view-btn ${!isGridView ? 'grid-view-btn-selected' : ''}`}
                 icon={<ColumnViewIcon className="table-view-icon" />}
                 onClick={this.columnViewHandler}
               >
                 <IntlMessages id="uhe.columnView" />
               </Button>
               <Button
                 className={`table-view-btn ${isGridView ? 'grid-view-btn-selected' : ''}`}
                 icon={<GridViewIcon className="table-view-icon" />}
                 onClick={this.toggleGridView}
               >
                 <IntlMessages id="uhe.gridView" />
               </Button>
             </div>
           </div>
         </div>
         {
           !tableColumns.length && (
           <div className="monitoring-aps-no-selected-column">
             {' '}
             <IntlMessages id="uhe.noColumnSelected" />
           </div>
           )
         }
         <div className="monitoring-aps-with-sidepanel">

           <div className={`uhe-table monitoring-aps-table  ${showSidePanel ? 'open-sidepanel' : ''} uhe-table`}>
             {
               tableColumns.length
                 ? (
                   <div className={!isGridView ? 'scrollable-table' : ''}>
                     <Table
                       scrollToFirstRowOnChange
                       bordered
                       className={`gx-table-responsive custom-scrollbar ${isGridView ? 'grid-view-table custom-scrollbar-hidden' : ''} ${showSidePanel ? 'smaller-table' : ''}`}
                       columns={this.getColumns()}
                       dataSource={isGridView ? [] : Uhe.dataAdapter(data, profile)}
                       onChange={this.handleTableChange}
                       pagination={false}
                       {...(isGridView ? { scroll: { x: 0 } } : {})}
                       footer={() => (
                         <div className="table-footer" ref={this.tableFooterRef}>
                           {isGridView
                          && this.gridViewDataAdapter()}
                           <Button type="text" onClick={() => this.onPageChange(showMore)}>
                             <IntlMessages id="common.showMore" />
                             <DownOutlined />
                           </Button>
                         </div>
                       )}
                       loading={loading}
                       onRow={(record) => ({
                         onClick: (event) => { this.onClickRow(record.bedCart); },
                       })}
                       rowClassName={
                      (record) => ((
                        `${showSidePanel && record.deviceId === selectedRoom.id ? 'selected-row' : ''} ${record.status.statusColor === 'grey' ? 'not-configured' : ''}`
                      ))
                    }
                     />
                   </div>
                 ) : null
             }
           </div>

           <SidePanelCard
             selectedBedCart={selectedBedCart}
             loading={sidePanellLoading}
             onClickClose={this.onClickClosePanel}
             selectedRoom={selectedRoom}
             timeZone={customerTimeZone}
             showSidePanel={showSidePanel}
           />
         </div>

       </div>
     </div>
   );
 }
}

Uhe.defaultProps = {
  loading: true,
  pagination: {
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  },
  sideMenu: '',
};

Uhe.propTypes = {
  data: PropTypes.shape().isRequired,
  loading: PropTypes.bool,
  pagination: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  intl: PropTypes.object,
  latestVersion: PropTypes.number,
  onFetchData: PropTypes.func,
  setSubtitle: PropTypes.func,
  profile: PropTypes.shape().isRequired,
  selectedBedCart: PropTypes.shape(),
  sidePanellLoading: PropTypes.bool,
  customerTimeZone: PropTypes.string,
  fetchBedCart: PropTypes.func,
  fetchCustomer: PropTypes.func,
  sideMenu: PropTypes.string,
  userPreferences: PropTypes.shape(),
};

/**
 * Maps Global State to Component's Props
 * @returns {Object} data
 */
const mapStateToProps = ({
  MonitoringUHE,
  subtitle,
  SystemSettingsSystem,
  ConfigurationUsers,
  bedsCarts,
  ConfigurationCustomers,
  settings,
}) => {
  const { list = [], page = {} } = MonitoringUHE.table || {};
  const { loading } = MonitoringUHE;
  const pagination = {
    total: page.totalElements || 0,
    current: page.number + 1 || 0,
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  };

  const { production_version = {} } = SystemSettingsSystem.data;
  const latestVersion = parseFloat(production_version.value) || null;

  return {
    data: list,
    pagination,
    loading,
    subtitle,
    latestVersion,
    profile: ConfigurationUsers.ownUser,
    userPreferences: ConfigurationUsers.userPreferences,
    selectedBedCart: bedsCarts.selectedBedCart,
    customerTimeZone: ConfigurationCustomers.selectedCustomer.time_zone,
    sidePanellLoading: bedsCarts.loading,
    sideMenu: settings.navStyle,
  };
};

/**
 * Returns Object Which Dispatch Actions to the Store
 * @param {function} dispatch data
 * @returns {Object} data
 */
const mapDispatchToProps = (dispatch) => ({
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  onFetchData: (page, sort, filter) => dispatch(onFetchData(page, sort, filter)),
  systemOnFetchData: (page) => dispatch(systemOnFetchData(page)),
  onFetchOwnPofile: () => dispatch(onFetchOwnPofile()),
  onFetchPieChartData: (filter) => dispatch(onFetchPieChartData(filter)),
  onFetchGmapData: (filter) => dispatch(onFetchGmapData(filter)),
  fetchBedCart: (id) => dispatch(fetchBedCart(id)),
  fetchCustomer: (id) => dispatch(fetchCustomer(id)),
  saveUserPreferences: (data) => dispatch(saveUserPreferences(data)),
  getUserPreferences: (data) => dispatch(getUserPreferences(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(Uhe)));
