/**
 * @type {Readonly<{
    organization: number,
    customer: number,
    facility: number,
    unit: number,
    device: number
   }>}
 */
export const LevelType = Object.freeze({
  organization: 1,
  customer: 2,
  facility: 3,
  unit: 4,
  device: 5,
});

export const getLevelName = (level) => Object.entries(LevelType)
  .find(([, value]) => value === level)
  ?.shift();

export const getIndexFromLevel = (level) => Object.entries(LevelType)
  .map(([k, v], i) => [k, v, i])
  .find(([, value]) => value === level)
  ?.pop();

export const LevelChain = Object.freeze(Object.keys(LevelType));

const $toggleChildrenPermissions = (permissionName, el = {}, toggle, breakInheritance = false) => {
  Object.values(el).forEach((child) => {
    const { permissions: { [permissionName]: permission } } = child;
    permission.granted = toggle;
    if (!breakInheritance) {
      permission.inherited = toggle;
    }
    $toggleChildrenPermissions(permissionName, child.children, toggle, breakInheritance);
  });
};

// eslint-disable-next-line max-len
const getToLevel = (dataSource, keychain) => function getToLevel0(level, children = dataSource, depth = 1) {
  if (!level) {
    return {};
  }

  if (level !== depth) {
    return getToLevel0(
      level,
      children[keychain[depth - 1]]?.children,
      depth + 1,
    );
  }

  return children || {};
};

const togglePermission = (permissionName, data, globalGrantsData) => {
  globalGrantsData[permissionName].granted = false;
  const { permissions: { [permissionName]: permission } } = data;
  const oldGrant = permission.granted;
  permission.granted = !oldGrant;
  permission.inherited = false;
  $toggleChildrenPermissions(permissionName, data.children, !oldGrant);
};

const toggleGlobalGrant = (permissionName, data, globalGrantsData) => {
  const newValue = !globalGrantsData[permissionName].granted;
  globalGrantsData[permissionName].granted = newValue;
  Object.values(data).forEach((element) => {
    const { [permissionName]: permission } = element.permissions;
    permission.granted = newValue;
    permission.inherited = newValue;
    $toggleChildrenPermissions(permissionName, element.children, newValue);
  });
};

const areLowerLevelsSet = (permissionName, children) => {
  if (!children) {
    return false;
  }

  // eslint-disable-next-line max-len,no-restricted-syntax
  for (const { permissions: { [permissionName]: { granted, artificial, inherited } }, children: children0 } of Object.values(children)) {
    if (granted && !artificial && !inherited) {
      return true;
    }

    if (areLowerLevelsSet(permissionName, children0)) {
      return true;
    }
  }

  return false;
};

// eslint-disable-next-line max-len
const revokeWithParentsGrants = (element, roleName, dataSource, keychain, globalGrantsData) => function revokeWithParentsGrants0(children = dataSource, depth = 1) {
  globalGrantsData[roleName].granted = false;
  if (depth === element.discriminator) {
    Object.values(getToLevel(dataSource, keychain)(element.discriminator))
      .forEach((sibling) => {
        sibling.permissions[roleName].inherited = false;
        sibling.permissions[roleName].granted = true;
      });
    const { permissions: { [roleName]: permission } } = element;
    permission.granted = false;
    $toggleChildrenPermissions(roleName, element.children, false);
    return;
  }

  Object.values(children).forEach((sibling) => {
    sibling.permissions[roleName].inherited = false;
  });
  const currentElement = children[keychain[depth - 1]];
  const { permissions: { [roleName]: permission } } = currentElement;
  permission.granted = false;
  revokeWithParentsGrants0(currentElement.children, depth + 1);
};

export default class ReactivePermissionData {
  constructor(obj = null) {
    /**
     * @type {number}
     */
    this.id = undefined;
    /**
     * @type {string}
     */
    this.name = undefined;
    /**
     * @type {Object.<string, ReactivePermission>}
     */
    this.permissions = undefined;
    /**
     * @type {Object.<string, ReactivePermissionData>}
     */
    this.children = undefined;
    /**
     * @description Use LevelType when checking or setting
     * @type {number}
     */
    this.discriminator = undefined;
    /**
     * @description "DEV" or "USR"
     * @type {number}
     */
    this.type = undefined;

    if (obj) {
      Object.assign(this, obj);
    }
  }
}

// Private //
// ReactivePermissionData.$toggleChildrenPermissions = $toggleChildrenPermissions;

// Public //
ReactivePermissionData.levelType = LevelType;
ReactivePermissionData.levelChain = LevelChain;
ReactivePermissionData.getIndexFromLevel = getIndexFromLevel;
ReactivePermissionData.getLevelName = getLevelName;
ReactivePermissionData.getToLevel = getToLevel;
ReactivePermissionData.togglePermission = togglePermission;
ReactivePermissionData.toggleGlobalGrant = toggleGlobalGrant;
ReactivePermissionData.areLowerLevelsSet = areLowerLevelsSet;
ReactivePermissionData.revokeWithParentsGrants = revokeWithParentsGrants;
