import {
  SYSTEM_IOBSERVER_FETCH_SUCCESS,
  SYSTEM_IOBSERVER_FETCH_DATA,
  SAVE_IOBSERVER_SETTINGS,
  SAVE_IOBSERVER_SETTINGS_SUCCESS,
  DEFAULT_TURN_SERVERS,
  DEFAULT_TURN_SERVERS_SUCCESS,
  CUSTOMER_TURN_SERVER,
  CUSTOMER_TURN_SERVER_SUCCESS,
  SAVE_DEFAULT_TURN_SERVER,
  SAVE_DEFAULT_TURN_SERVER_SUCCESS,
  DELETE_DEFAULT_TURN_SERVER_REQUEST,
  DELETE_DEFAULT_TURN_SERVER_REQUEST_SUCCESS,
  SAVE_CUSTOMER_TURN_SERVER,
  SAVE_CUSTOMER_TURN_SERVER_SUCCESS,
  DELETE_CUSTOMER_TURN_SERVER_REQUEST,
  DELETE_CUSTOMER_TURN_SERVER_SUCCESS,
  CLEAR_IOBSERVER_STATE,
} from "@constants/UHEActionTypes";

const INIT_STATE = {
  loading: true,
  data: {},
  defaultServer: [],
  customerServer: [],
};

/**
  * @description System reducer
  * @param  {Object} state
  * @param  {string} action
  * @return {Object}
  */
 export default (state = INIT_STATE, action) => {
  switch (action.type) {
  
    case SYSTEM_IOBSERVER_FETCH_DATA:
      return Object.assign({}, state, { loading: true });
      break;
    case SYSTEM_IOBSERVER_FETCH_SUCCESS:
      const newState = { 'data': action.data, loading: false }
      return Object.assign({}, state, newState);
      break;
    case SAVE_IOBSERVER_SETTINGS: {
      return { ...state, loading: true }
    }
    case SAVE_IOBSERVER_SETTINGS_SUCCESS: {
      const data = { ...action.payload };
      const newState = { data };
      return { ...state, ...newState, loading: false };
    }
    case DEFAULT_TURN_SERVERS_SUCCESS:
      const newDefaultServerState = { 'defaultServer': action.data, loading: false }
      return Object.assign({}, state, newDefaultServerState);
      break;
    case DEFAULT_TURN_SERVERS:
      return Object.assign({}, state, { loading: true });
      break;
    case SAVE_DEFAULT_TURN_SERVER: {
      return { ...state, loading: true }
    }
    case SAVE_DEFAULT_TURN_SERVER_SUCCESS:
      const data = { ...action.payload };
      const defaultNewState = { data };
      return { ...state, ...defaultNewState, loading: false };
    case DELETE_DEFAULT_TURN_SERVER_REQUEST: {
      return { ...state, loading: true }
    }
    case DELETE_DEFAULT_TURN_SERVER_REQUEST_SUCCESS: {
      const defaultServer = { ...INIT_STATE.defaultServer };
      const newState = { defaultServer };
      return { ...state, ...newState, loading: false };
      }
    case CUSTOMER_TURN_SERVER:
      return Object.assign({}, state, {loading: true});
      break;
    case CUSTOMER_TURN_SERVER_SUCCESS:
      const newCustomerServerState = { 'customerServer': action.data, loading: false }
      return Object.assign({}, state, newCustomerServerState);
      break;
    case SAVE_CUSTOMER_TURN_SERVER: {
      return { ...state, loading: true }
    }
    case SAVE_CUSTOMER_TURN_SERVER_SUCCESS:
      const customerTurnData = { ...action.payload };
      const customerNewState = { customerTurnData };
      return { ...state, ...customerNewState, loading: false };
    case DELETE_CUSTOMER_TURN_SERVER_REQUEST: {
      return { ...state, loading: true }
    }
    case DELETE_CUSTOMER_TURN_SERVER_SUCCESS: {
      const customerServer = { ...INIT_STATE.customerServer };
      const newState = { customerServer };
      return { ...state, ...newState, loading: false };
      }  
    case CLEAR_IOBSERVER_STATE: {
      return INIT_STATE;
    }
    default:
      return state;
  }
}
