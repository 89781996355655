import {
  FETCH_UNIT_SUCCESS,
  SAVE_UNIT_SUCCESS,
  DELETE_UNIT_SUCCESS,
  SET_REDIRECT_URL,
  CLEAR_UNIT,
  SAVE_UNIT_FAIL,
  SAVE_UNIT_REQUEST,
} from '@constants/UHEActionTypes';

const INIT_STATE = {
  selectedUnit: {
    facility: { id: null, name: '' },
    name: '',
  },
  units: [],
  redirectUrl: '',
  loading: false,
};

/**
 * @param  {Object} state
 * @param  {string} action
 * @return {Object}
 */
export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case FETCH_UNIT_SUCCESS: {
      const selectedUnit = { ...action.payload };
      const newState = { selectedUnit };
      return { ...state, ...newState };
    }
    case SAVE_UNIT_REQUEST: {
      return { ...state, loading: true };
    }
    case SAVE_UNIT_FAIL: {
      return { ...state, loading: false };
    }
    case SAVE_UNIT_SUCCESS: {
      const selectedUnit = { ...action.payload };
      const newState = { selectedUnit };
      return { ...state, ...newState, loading: false };
    }
    case DELETE_UNIT_SUCCESS: {
      const selectedUnit = { ...INIT_STATE.selectedUnit };
      const newState = { selectedUnit };
      return { ...state, ...newState };
    }
    case SET_REDIRECT_URL: {
      const redirectUrl = action.payload.url;
      const newState = { redirectUrl };
      return { ...state, ...newState };
    }
    case CLEAR_UNIT: {
      return { ...INIT_STATE };
    }

    default:
      return state;
  }
};
