import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

import RestManager from '@util/RestManager';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { fetchError, showMessage } from '@actions/Common';
import {
  PROGRAMS_LISTING_REQUEST,
  SAVE_PROGRAM,
  DELETE_PROGRAM_REQUEST,
  GET_PROGRAM_REQUEST,
  UPDATE_PROGRAM_REQUEST,
  PROGRAM_GRANTS_REQUEST,
  UPDATE_PROGRAM_GRANTS_REQUEST,
  UPLOAD_PROGRAM_DOCUMENT_REQUEST,
} from '@constants/UHEActionTypes';
import {
  getProgramsListing,
  getProgramsListingSuccess,
  saveProgramSuccess,
  deleteProgramSuccess,
  getProgramSuccess,
  updateProgramSuccess,
  getProgram,
  getProgramGrantsSuccess,
  updateProgramGrantsSuccess,
  uploadProgramDocumentSuccess,
} from '@actions/uhe/configuration/programs/ProgramsActions';
import { fetchUploadLogsRequest } from '@actions/uhe/configuration/users/UsersActions';

/**
 * Programs Listing Request
 * @param {number} page Page Number
 * @param {string} sorting Sorting String
 * @param {string} filter Filter String
 * @returns {object} Response
 */
const programsListRequest = async (page, sorting, filter) => {
  const filterQueryString = filter?.length ? `&${filter.join('&')}` : '';
  const sortingQueryString = sorting?.length ? `&sort=${sorting.join('&sort=')}` : '';
  const request = await RestManager.request(`${ENDPOINTS.programs.endpoint}?page=${page || 0}${sortingQueryString}${filterQueryString}`);

  return request;
};

/**
 * Program Delete Request
 * @param {string} id Program ID
 * @returns {object} Response
 */
const deleteProgramRequest = async (id) => {
  const request = await RestManager.requestWithoutQueryParams(`${ENDPOINTS.programs.endpoint}/${id}`, 'DELETE');

  return request;
};

/**
 * Get Program by ID Request
 * @param {string} id Program ID
 * @returns {object} Response
 */
const getProgramRequest = async (id) => {
  const request = await RestManager.request(`${ENDPOINTS.programs.endpoint}/${id}`);

  return request;
};

/**
 * Program Update Request
 * @param {string} id Program ID
 * @param {object} body Request Body
 * @returns {object} Response
 */
const updateProgramRequest = async (id, body) => {
  const request = await RestManager.requestWithoutQueryParams(`${ENDPOINTS.programs.endpoint}/${id}`, 'POST', body);

  return request;
};

/**
 * Program Grants Update Request
 * @param {*} id Program ID
 * @param {*} body Request Body
 * @returns {object} Response
 */
const updateProgramGrantsRequest = async (id, body) => {
  const request = await RestManager.requestWithoutQueryParams(`${ENDPOINTS.programs.endpoint}/${id}/grants`, 'POST', body);

  return request;
};

/**
 * Get Program Grants Request
 * @param {string} id Program ID
 * @returns {object} Response
 */
const programGrantsRequest = async (id) => {
  const request = await RestManager.request(`${ENDPOINTS.programs.endpoint}/${id}/grants`);

  return request;
};

/**
 * updateProgramGrantsSaga Saga Worker
 * @param {object} data Payload Data
 * @returns {void}
 */
function* updateProgramGrantsSaga(data) {
  try {
    const response = yield call(updateProgramGrantsRequest, data.payload.id, data.payload.body);

    if (response) {
      yield put(updateProgramGrantsSuccess(response));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * programGrantsSaga Saga Worker
 * @param {string} id Program ID
 * @returns {void}
 */
function* programGrantsSaga({ id }) {
  try {
    const response = yield call(programGrantsRequest, id);

    if (response) {
      yield put(getProgramGrantsSuccess(response));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * updateProgramSaga Saga Worker
 * @param {object} data Payload Data
 * @returns {void}
 */
function* updateProgramSaga(data) {
  try {
    const response = yield call(updateProgramRequest, data.payload.id, data.payload.body);

    if (response) {
      yield put(updateProgramSuccess(response));
      yield put(showMessage('programUpdated'));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * getProgramSaga Saga Worker
 * @param {object} payload Payload Data
 * @returns {void}
 */
function* getProgramSaga(payload) {
  try {
    const response = yield call(getProgramRequest, payload.id);

    if (response) {
      yield put(getProgramSuccess(response));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * deleteProgramSaga Saga Worker
 * @param {string} id Program ID
 * @param {number} page Page Number
 * @param {string} sort Sorting String
 * @param {string} filter Filter String
 * @returns {void}
 */
function* deleteProgramSaga({
  payload: {
    id, page, sort, filter,
  },
}) {
  try {
    const response = yield call(deleteProgramRequest, id);

    if (response) {
      yield put(deleteProgramSuccess(id));
      yield put(getProgramsListing(page, sort, filter));
      yield put(showMessage('delete_program_success'));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * getProgramsList Saga Worker
 * @param {number} page Page Number
 * @param {string} sorting Sorting String
 * @param {string} filter Filter String
 * @returns {void}
 */
function* getProgramsList({ page, sorting, filter }) {
  try {
    const response = yield call(programsListRequest, page, sorting, filter);
    yield put(getProgramsListingSuccess(response));
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Request for add new program
 * @param  {Object} bodyData for new program
 * @return {Object} data
 */
const addNewProgram = async (bodyData) => {
  const res = await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.programs.endpoint}`,
    'POST',
    bodyData,
  );
  return res;
};

/**
 * Save program
 * @param {Object} data payload
 * @returns {void}
 */
function* saveProgramData(data) {
  try {
    const response = yield call(addNewProgram, data.payload.program);

    if (response) {
      yield put(saveProgramSuccess(response));
      yield put(showMessage('programCreated'));
      yield put(updateProgramGrantsSuccess({ body: response, id: response.id }));
      yield put(getProgram(response.id));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Upload user document request
 * @param {Object} file file
 * @returns {Object} response
 */
const uploadProgramDocumentRequest = async (file) => await RestManager.formDataRequest(
  `${ENDPOINTS.programs.uploadProgram}`,
  file,
);

/**
 * Handles Response and Request for uploading CSV file
 * @param {Object} payload payload
 * @returns {void}
 */
function* onUploadProgramDocument(payload) {
  try {
    const uploadProgramDocument = yield call(
      uploadProgramDocumentRequest,
      payload.payload.file,
    );

    if (uploadProgramDocument) {
      yield put(uploadProgramDocumentSuccess());
      yield put(fetchUploadLogsRequest());
      yield put(showMessage('save_success'));
    }
  } catch (error) {
    yield put(fetchError(error));
    yield put(fetchUploadLogsRequest());
  }
}

/**
 * Actions Watcher
 * @returns {void}
 */
export function* actionsWatcher() {
  yield takeEvery(PROGRAMS_LISTING_REQUEST, getProgramsList);
  yield takeEvery(DELETE_PROGRAM_REQUEST, deleteProgramSaga);
  yield takeEvery(SAVE_PROGRAM, saveProgramData);
  yield takeEvery(GET_PROGRAM_REQUEST, getProgramSaga);
  yield takeEvery(UPDATE_PROGRAM_REQUEST, updateProgramSaga);
  yield takeEvery(PROGRAM_GRANTS_REQUEST, programGrantsSaga);
  yield takeEvery(UPDATE_PROGRAM_GRANTS_REQUEST, updateProgramGrantsSaga);
  yield takeEvery(UPLOAD_PROGRAM_DOCUMENT_REQUEST, onUploadProgramDocument);
}

/**
 * Root Saga
 * @returns {void}
 */
export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
