import {
  FETCH_SIGNIN_REPORTS_REQUEST,
  FETCH_SIGNIN_REPORTS_SUCCESSS,
} from '@constants/UHEActionTypes';

export const fetchSignInReports = (page, sorting, filter) => {
  return {
    type: FETCH_SIGNIN_REPORTS_REQUEST,
    page,
    sorting,
    filter,
  };
};

export const fetchSignInReportsSuccess = (data) => {
  return {
    type: FETCH_SIGNIN_REPORTS_SUCCESSS,
    data,
  };
};