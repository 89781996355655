import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  iobserverFetchDataSuccess,
  saveIobserverSuccess,
  defaultTurnServerSuccess,
  customerTurnServerSuccess,
  saveDefaultTurnServerSuccess,
  saveCustomerTurnServerSuccess,
} from '@actions/uhe/system/iObserverActions';
import {
  SYSTEM_IOBSERVER_FETCH_DATA,
  SAVE_IOBSERVER_SETTINGS,
  DEFAULT_TURN_SERVERS,
  CUSTOMER_TURN_SERVER,
  SAVE_DEFAULT_TURN_SERVER,
  SAVE_CUSTOMER_TURN_SERVER,
  DELETE_DEFAULT_TURN_SERVER_REQUEST,
  DELETE_CUSTOMER_TURN_SERVER_REQUEST,
} from '@constants/UHEActionTypes';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { fetchError, showMessage } from '@actions/Common';
import RestManager from '@util/RestManager';


/**
 * @description Request data for controller settings
 * @param  {Array} // Array of objects
 */
const doFetchData = async () => {
  return await RestManager.request(`${ENDPOINTS.iObserver.controllerSettings}`);
};

/**
 * @description Controller settings
 * @param {number} page
 * @param filter {Array}
 */
function* fetchIobserverData({ page }) {
  try {
    const fetchedData = yield call(doFetchData, page);
    yield put(iobserverFetchDataSuccess(fetchedData));
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Save request for Controller Settings
 * @param  {object} bodyData
 * @return {Object}
 */
const save = async (bodyData) => {
  return await RestManager.requestWithoutQueryParams(`${ENDPOINTS.iObserver.saveIobserver}`, 'POST', bodyData);
};

/**
 * @description Save Constroller settings data
 * @param {string} data - Data for saving
 */
function* saveIobserver(data) {
  try {
    const system = yield call(save, data.payload);
    if (system) {
      yield put(saveIobserverSuccess(system));
      yield put(showMessage('save_success'));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Save request for Default Turn Servers
 * @param  {object} bodyData
 * @return {Object}
 */
const saveDefault = async (bodyData) => {
  return await RestManager.requestWithoutQueryParams(`${ENDPOINTS.iObserver.saveDefaultTurnServer}`, 'POST', bodyData);
};

/**
 * @description Save Default Turn Servers
 * @param {string} data - Data for saving
 */
function* saveDefaultTurnServer(data) {
  try {
    const system = yield call(saveDefault, data.payload);
    if (system) {
      yield put(saveDefaultTurnServerSuccess(system));
      const reason = data.payload.reason;
      if(reason === "delete") {
        yield put(showMessage('delete_success'));
      } else {
        yield put(showMessage('save_success'));
      }
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Delete request
 * @param {string} id
 * @return  {Promise}
 */
const deleteDefaultTurnServer = async () => {
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.iObserver.deleteDefaultTurnServer}`,
    'POST'
  );
};


/**
 * @description Delete customer by given id
 * @param {string} data
 * @return {void}
 */
function* delDefaultTurnServer({ id }) {
  try {
    const deletedCustomer = yield call(deleteDefaultTurnServer, id);
    if (deletedCustomer && deletedCustomer.status >= 200 && deletedCustomer.status < 300) {
      yield call(defaultTurnServersData);
      yield put(showMessage('delete_success'));
    } else {
      console.error("DELETE ERROR: ", deletedCustomer);
      yield put(fetchError(deletedCustomer));
    }
    console.log(deletedCustomer);
  } catch (error) {
    console.error("DELETE ERROR: ", error);
    yield put(fetchError(error));
  }
}

/**
 * @description Default turn server request
 */
const defaultTurnServers = async () => {
  return await RestManager.request(`${ENDPOINTS.iObserver.defaultTurnServer}`);
};

/**
 * @description Default Turn Server
 * @param {number} page
 * @param filter {Array}
 */
function* defaultTurnServersData({ page }) {
  try {
    const fetchedData = yield call(defaultTurnServers, page);
    yield put(defaultTurnServerSuccess(fetchedData));
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Request for Customer Turn Servers
 * @param  {object} bodyData
 * @return {Object}
 */
const customerTurnServers = async () => {
  return await RestManager.request(`${ENDPOINTS.iObserver.customerTurnServer}`);
};

/**
 * @description Customer Turn Servers
 * @param {number} id
 * @param filter {Array}
 */
function* customerTurnServersData({ id }) {
  try {
    let fetchedData;
    if (id) {
      fetchedData = yield call(CustomerById, id);
    } else {
      fetchedData = yield call(customerTurnServers);
    }

    if (fetchedData) {
      yield put(customerTurnServerSuccess(fetchedData));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Sends GET Request
 * @param {string} id
 * @returns {Array}
 */
const CustomerById = async (id) => {
  return await RestManager.request(`${ENDPOINTS.iObserver.saveCustomerTurnServer}/${id}`);
};

/**
 * @description Save request for Default Turn Servers
 * @param  {object} bodyData
 * @return {Object}
 */
const saveCustomerTurn = async (bodyData, id) => {
  return await RestManager.requestWithoutQueryParams(`${ENDPOINTS.iObserver.saveCustomerTurnServer}/${id}`, 'POST', bodyData);
};

/**
 * @description Save customer turn server
 * @param {Object} data
 */
function* saveCustomerTurnServer(data) {
  try {
    const customer = yield call(saveCustomerTurn, data.payload.body, data.payload.id);
    if (customer) {
      yield put(saveCustomerTurnServerSuccess(customer));
      const reason = data.payload.reason;
      if(reason === "delete") {
        yield put(showMessage('delete_success'));
      } else {
        yield put(showMessage('save_success'));
      }
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Save request for Default Turn Servers
 * @param  {object} bodyData
 * @return {Object}
 */
const deleteCustomerTurn = async (bodyData, id) => {
  return await RestManager.requestWithoutQueryParams(`${ENDPOINTS.iObserver.deleteCustomerTurnServer}/${id}`, 'POST', bodyData);
};

/**
 * @description Delete customer by given id
 * @param {string} data
 * @return {void}
 */
function* deleteCustomerTurnServer({ id }) {
  try {
    const deletedCustomer = yield call(deleteCustomerTurn, id);
    if (deletedCustomer && deletedCustomer.status >= 200 && deletedCustomer.status < 300) {
      yield call(customerTurnServersData);
      yield put(showMessage('delete_success'));
    } else {
      console.error("DELETE ERROR: ", deletedCustomer);
      yield put(fetchError(deletedCustomer));
    }
  } catch (error) {
    console.error("DELETE ERROR: ", error);
    yield put(fetchError(error));
  }
}


export function* actionsWatcher() {
  yield takeEvery(SYSTEM_IOBSERVER_FETCH_DATA, fetchIobserverData);
  yield takeEvery(SAVE_IOBSERVER_SETTINGS, saveIobserver);
  yield takeEvery(DEFAULT_TURN_SERVERS, defaultTurnServersData);
  yield takeEvery(CUSTOMER_TURN_SERVER, customerTurnServersData);
  yield takeEvery(SAVE_DEFAULT_TURN_SERVER, saveDefaultTurnServer);
  yield takeEvery(SAVE_CUSTOMER_TURN_SERVER, saveCustomerTurnServer);
  yield takeEvery(DELETE_DEFAULT_TURN_SERVER_REQUEST, delDefaultTurnServer);
  yield takeEvery(DELETE_CUSTOMER_TURN_SERVER_REQUEST, deleteCustomerTurnServer);
}

export default function* rootSaga() {
  yield all(
    [
      fork(actionsWatcher),
    ]
  );
}