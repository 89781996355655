import React from 'react';
import { injectIntl } from 'react-intl';

const MINS_PER_YEAR = 24 * 365 * 60;
const MINS_PER_MONTH = 24 * 30 * 60;
const MINS_PER_DAY = 24 * 60;
const MINS_PER_HOUR = 60;

const LastEventCell = ({ content: minutes, intl }) => {
  let time = '';
  if (minutes === null) {
    time = intl.formatMessage({ id: 'common.null' });
  } else if (minutes < 1) {
    time = intl.formatMessage({ id: 'uhe.lastEvent.lessThanMinute' });
  } else {
    const years = Math.floor(minutes / MINS_PER_YEAR);
    const months = Math.floor(minutes / MINS_PER_MONTH);
    const days = Math.floor(minutes / MINS_PER_DAY);
    const hours = Math.floor(minutes / MINS_PER_HOUR);
    if (years < 1) {
      if (months < 1) {
        if (days < 1) {
          time = hours < 1
            ? intl.formatMessage({ id: 'uhe.lastEvent.minutes' }, { count: minutes })
            : intl.formatMessage({ id: 'uhe.lastEvent.hours' }, { count: hours });
        } else {
          time = intl.formatMessage({ id: 'uhe.lastEvent.days' }, { count: days });
        }
      } else {
        time = intl.formatMessage({ id: 'uhe.lastEvent.months' }, { count: months });
      }
    } else {
      time = intl.formatMessage({ id: 'uhe.lastEvent.years' }, { count: years });
    }
  }

  return (
    <div className="uhe-table-td">
      <span className="maint-mode">
        {time}
      </span>
    </div>
  );
};

export default injectIntl(LastEventCell);
