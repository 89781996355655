import React from 'react';
import { injectIntl } from 'react-intl';

import devices_in_alarm from '@assets/images/show_devices_in_alarm.svg';
import devices_not_configured from '@assets/images/show_devices_not_configured.svg';
import devices_online from '@assets/images/show_devices_online.svg';

const StatusCell = ({ content, intl, statusFigure }) => {
  const { status } = content;
  statusFigure = devices_not_configured;
  if(status === "healthy"){
    statusFigure = devices_online;
  }else if (status === "unhealthy"){
    statusFigure = devices_in_alarm;
  }
  return (
    <div className="uhe-table-td" title={intl.formatMessage({ id: `uhe.status.${status}` })} >
      <span className="status">
        <img className="status-icon" alt="icon" src={statusFigure} />
      </span>
    </div>
  );
};

export default injectIntl(StatusCell);
