import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import {
  Card,
  Input,
  Cascader,
  Form,
  Row,
  Popover,
  Checkbox,
} from 'antd';
import { SearchOutlined, DownOutlined } from '@ant-design/icons';

import SimpleMap from '@components/map/googlemap/simple/Components/SimpleMap';
import EditHeader from '@components/uhe/configuration/EditHeader';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import * as facilityActions from '@uhe_actions/configuration/facilities/FacilitiesActions';
import { onGetOptions } from '@uhe_actions/filters/ListingsTopFilterActions';
import { withLastLocation } from 'react-router-last-location';
import {
  goBackTo,
  cascaderSearchFilter,
  removeSearchIcon,
} from '@util/UheHelper';
import { shouldFacilityBeEditable, shouldDisableCallerID, shouldDisableVerifiedCheckbox } from '@util/UheRoleChecker';
import PropTypes from 'prop-types';

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

/**
 * Facility add and edit page view.
 */
class ManageFacility extends React.Component {
  formRef = React.createRef();

  /**
   * ManageFacility Constructor
   * @param {object} props Facilities Props
   */
  constructor(props) {
    super(props);

    this.isNew = this.props.location.pathname.indexOf('/new') !== -1;
    this.intl = this.props.intl;
    this.state = {
      facility: {
        caller_id: null,
        organization: { id: null, name: '' },
        customer: { id: null, name: '' },
        id: null,
        name: '',
        status: null,
        country: '',
        state: '',
        city: '' !== null,
        zip: '',
        address1: '',
        geo: { lat: 38.3789586, lon: -81.7665882 },
      },
      hasTypedLocation: false,
      hasChosenLocation: false,
      focusCascader: {
        organization: false,
        customer: false,
      },
    };

    this.renderHeadLine = this.renderHeadLine.bind(this);
    this.saveFacility = this.saveFacility.bind(this);
    this.onChangeHandlerName = this.onChangeHandlerName.bind(this);
    this.onChangeHandlerAddress = this.onChangeHandlerAddress.bind(this);
    this.handlePlaceChange = this.handlePlaceChange.bind(this);
    this.onChangeCustomerHandler = this.onChangeCustomerHandler.bind(this);

    if (this.isNew) {
      if (this.props.subtitle.langId !== 'facilities.new_title') {
        this.props.onGetCustomerOptions();
        this.props.onGetOrganizationOptions();
        this.props.setSubtitle('facilities.new_title');
      }
    } else if (this.props.subtitle.langId !== 'facilities.edit_title') {
      this.props.setSubtitle('facilities.edit_title');
    }
  }

  /**
   * Update local state
   * @returns {void}
   */
  componentDidMount() {
    const { actions, match } = this.props;
    actions.clearFacilityState();
    const urlId = match.params.id;
    if (urlId) {
      actions.fetchFacility({ id: urlId });
    }
  }

  /**
   * Component Did Update
   * @param {Object} prevProps previous props
   * @param {object} prevState  previous state
   * @returns {void} void
   */
  componentDidUpdate(prevProps, prevState) {
    this.isNew = this.props.location.pathname.indexOf('/new') !== -1;
    const { savedFacilityId, facility, error } = this.props;

    if (this.isNew && savedFacilityId) {
      this.props.history.push(`/configuration/facilities/edit/${savedFacilityId}`);
      return;
    }

    if (!this.isNew && (prevProps.facility.id !== this.props.facility.id) || (prevProps.facility.saved !== this.props.facility.saved)) {
      const newFacility = { ...this.props.facility };

      this.setState({
        facility: { ...prevState.facility, ...newFacility },
      });
    }

    if (prevProps.error !== error) {
      // handle system error
      if (error.code === 404 && !this.isNew) {
        this.props.history.push('/configuration/facilities');
      }
    }

    if (prevProps.facility.name !== facility.name) {
      this.formRef.current.setFieldsValue({
        name: facility.name,
      });
    }
  }

  /**
   * Handle Value Changes in the Text Input
   * @param {object} event Logged User Information
   * @return {void}
   */
  onChangeHandlerName(event) {
    if (event && event.persist && event.preventDefault) {
      event.persist();
      event.preventDefault();
    }
    const newFacility = { ...this.state.facility, name: event.target.value };
    this.setState({ facility: newFacility });
  }

  /**
   * Handle change of callerID input value
   * @param {object} event event data
   * @return {void}
   */
  onChangeCallerIDHandler = (event) => {
    if (event && event.persist && event.preventDefault) {
      event.persist();
      event.preventDefault();
    }
    const newFacility = { ...this.state.facility, caller_id: event.target.value };
    this.setState({ facility: newFacility });
  }

  /**
   * Save Changes on Save Button Click
   * @param {object} event Logged User Information
   * @returns {void} is Specific User Role true|false
   */
  saveFacility(event) {
    event.persist();
    event.preventDefault();

    this.formRef.current
      .validateFields(['name', 'organization', 'customer', 'searchBox', 'callerId', 'verified'])
      .then((values) => {
        const { actions } = this.props;
        const { facility } = this.state;
        actions.saveFacility(facility);
        this.setState({
          hasTypedLocation: false,
          hasChosenLocation: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /**
   * Handle Map Position and Updates State
   * @param {Object} geo geo
   * @param {string} zip zip
   * @param {string} country country
   * @param {string} city city
   * @param {string} state state
   * @param {string} address1 address
   * @returns {void}
   */
  handlePlaceChange(geo, zip, country, city, state, address1) {
    this.setState({
      facility: {
        ...this.state.facility,
        geo,
        zip,
        country,
        city,
        state,
        address1,
      },
      hasChosenLocation: true,
    });
    this.formRef.current.setFieldsValue({
      searchBox: address1,
    });
  }

  /**
   * Render title, back and save buttons
   * @returns {JSX} component
   */
  renderHeadLine() {
    const {
      loading, loggedUser, lastLocation, history,
    } = this.props;

    if (shouldFacilityBeEditable(loggedUser)) {
      return (
        <EditHeader
          goBack={goBackTo('/configuration/facilities', lastLocation?.pathname, history)}
          save={this.saveFacility}
          loading={loading}
        />
      );
    }

    return null;
  }

  /**
   * Handle Changes in Address Input Field and Updates the State
   * @param {object} event address
   * @returns {void}
   */
  onChangeHandlerAddress(event) {
    if (event && event.persist && event.preventDefault) {
      event.persist();
      event.preventDefault();
    }

    this.setState({
      hasTypedLocation: true,
    });
  }

  /**
   * Handle Changes in Organization Cascader
   * @param {Array} values array with id and name
   * @returns {void}
   */
  onChangeOrganizationHandler(values = []) {
    const resetFields = () => {
      this.formRef.current.setFieldsValue({
        customer: null,
        facility: null,
        searchBox: null,
      });
    }
    const { facility } = this.state;

    facility.organization = {
      id: values[0],
    };

    this.setState({ facility });
    if (facility.organization.id) {
      this.props.onGetCustomerOptions(facility.organization.id);
      resetFields();
    } else {
      resetFields();
    }

    this.setState((prevState) => ({
      focusCascader: {
        ...prevState.focusCascader,
        organization: false,
      },
    }));
  }

  /**
   * Handle Changes in Customer Cascader
   * @param {Array} values values
   * @returns {void}
   */
  onChangeCustomerHandler(values = []) {
    const resetFields = () => {
      this.formRef.current.setFieldsValue({
        facility: null,
        searchBox: null,
      });
    }
    const { facility } = this.state;

    facility.customer = {
      id: values[0],
    };
    this.setState({ facility });
    resetFields();
    this.setState((prevState) => ({
      focusCascader: {
        ...prevState.focusCascader,
        customer: false,
      },
    }));
  }

  /**
   * Handle Changes in Verified Checkbox
   * @param {object} event checkbox event data
   * @returns {void}
   */
  onChangeVerifiedHandler = (event) => {
    const { facility } = this.state;
    facility.caller_id_verified = event.target.checked;
    this.setState({ facility });
  }

  /**
   * Handles What Icon to Show in Cascade Menu - Expand or Search
   * @param {string} field field type
   * @returns {func} manipulate the state
   */
  changeDropdownExpandIcon = (field) => {
    const { focusCascader: { organization, customer }, focusCascader } = this.state;
    switch (field) {
      case 'organization':
        return this.setState({ focusCascader: { ...focusCascader, organization: !organization } });
      case 'customer':
        return this.setState({ focusCascader: { ...focusCascader, customer: !customer } });
      default:
    }
  }

  /**
   * Renders ManageFacility Component
   * @returns {JSX.Element} ManageFacility Component
   */
  render() {
    const {
      loading, optionsList, loggedUser, facility: { name, caller_id }, match,
    } = this.props;
    const { facility, focusCascader } = this.state;
    const { caller_id_verified } = facility;
    const callerIdRegEx = /^\+?[1-9]\d{1,14}$/;

    return (
      <Form className="manage-customer-wrapper" {...layout} ref={this.formRef} autoComplete="off">
        {this.renderHeadLine()}
        <div className="facility-edit">
          <Card
            className="gx-card customer-edit-perf-serve-card"
            loading={loading}
          >
            <Form.Item
              colon={false}
              className="facility-row"
              name="name"
              label={<IntlMessages id="configuration.facility.name" />}
              initialValue={match.path === '/configuration/facilities/new/' ? '' : name}
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="configuration.users.emptyField" />,
                },
                {
                  min: 3,
                  message: <IntlMessages id="common.error_name" />,
                },
              ]}
            >
              <Input
                placeholder="Name"
                autoComplete="off"
                id="perfectServeUrl"
                type="text"
                value={name}
                onChange={this.onChangeHandlerName}
                disabled={!shouldFacilityBeEditable(loggedUser)}
              />
            </Form.Item>
            <Popover content={<IntlMessages id="configuration.facility.callerIdTooltip" />}>
              <Form.Item
                colon={false}
                className="facility-row"
                name="callerId"
                label={<IntlMessages id="configuration.facility.callerId" />}
                initialValue={match.path === '/configuration/facilities/new/' ? '' : caller_id}
                rules={[
                  {
                    pattern: new RegExp(callerIdRegEx),
                    message: <IntlMessages id="configuration.facility.invalidNumber" />,
                  },
                  { min: 7, message: <IntlMessages id="configuration.facility.minNumLength" /> },
                  { max: 10, message: <IntlMessages id="configuration.facility.maxNumLength" /> },
                ]}
              >
                <Input
                  placeholder="Caller ID"
                  autoComplete="off"
                  id="perfectServeUrl"
                  type="text"
                  value={caller_id}
                  onChange={this.onChangeCallerIDHandler}
                  disabled={!shouldDisableCallerID(loggedUser)}
                />
              </Form.Item>
            </Popover>
            <Form.Item
              colon={false}
              className="facility-row verified-checkbox"
              name="verified"
              label={<IntlMessages id="configuration.facility.verified" />}
            >
              <Checkbox
                checked={caller_id_verified}
                onChange={this.onChangeVerifiedHandler}
                disabled={!shouldDisableVerifiedCheckbox(loggedUser)}
              />
            </Form.Item>
            {this.isNew && (
              <div>
                <Form.Item
                  colon={false}
                  className="facility-row"
                  name="organization"
                  label={<IntlMessages id="uhe.table.organization" />}
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id="configuration.users.emptyField" />,
                    },
                  ]}
                >
                  <Cascader
                    getPopupContainer={(event) => event.parentNode}
                    autoComplete={Math.random()}
                    suffixIcon={focusCascader.organization ? <SearchOutlined /> : <DownOutlined />}
                    onClick={() => this.changeDropdownExpandIcon('organization')}
                    dropdownClassName="top-filter-popup top-filter-dropdown"
                    fieldNames={{ label: 'name', value: 'id' }}
                    key="organization"
                    expandTrigger="hover"
                    size="large"
                    changeOnSelect
                    options={optionsList.organization || []}
                    onChange={(value) => this.onChangeOrganizationHandler(value)}
                    placeholder={this.intl.formatMessage({ id: 'configuration.customer.please_select' })}
                    showSearch={{ filter: cascaderSearchFilter }}
                    onBlur={() => this.setState({ focusCascader: removeSearchIcon() })}
                  />
                </Form.Item>
                <Form.Item
                  colon={false}
                  className="facility-row"
                  name="customer"
                  label={<IntlMessages id="uhe.table.customer" />}
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id="configuration.users.emptyField" />,
                    },
                  ]}
                >
                  <Cascader
                    getPopupContainer={(event) => event.parentNode}
                    dropdownClassName="top-filter-popup top-filter-dropdown"
                    suffixIcon={focusCascader.customer && facility.organization.id ? <SearchOutlined /> : <DownOutlined />}
                    onClick={() => this.changeDropdownExpandIcon('customer')}
                    fieldNames={{ label: 'name', value: 'id' }}
                    key="customer"
                    expandTrigger="hover"
                    size="large"
                    changeOnSelect
                    options={optionsList.customer || []}
                    onChange={(value) => this.onChangeCustomerHandler(value)}
                    placeholder={this.intl.formatMessage({ id: 'configuration.customer.please_select' })}
                    showSearch={{ filter: cascaderSearchFilter }}
                    disabled={!facility.organization.id}
                    onBlur={() => this.setState({ focusCascader: removeSearchIcon() })}
                  />
                </Form.Item>
              </div>
            )}
            <SimpleMap
              facility={facility}
              handlePlaceChange={this.handlePlaceChange}
              onChangeHandlerAddress={this.onChangeHandlerAddress}
              isNew={this.isNew}
              disabled={!shouldFacilityBeEditable(loggedUser)}
              hasTypedLocation={this.state.hasTypedLocation}
              hasChosenLocation={this.state.hasChosenLocation}
            />
            {!this.isNew && (
            <Row>
              <Form.Item
                colon={false}
                className="align-item-city form-item-row counter"
                label={<IntlMessages id="uhe.table.iConsultNo" />}
                labelCol={{
                  xl: { span: 20 },
                  lg: { span: 20 },
                  md: { span: 20 },
                  sm: { span: 20 },
                }}
                wrapperCol={{
                  xl: { span: 4 },
                  lg: { span: 4 },
                  md: { span: 4 },
                  sm: { span: 4 },
                }}
              >
                {facility.total_iconsult_users}
              </Form.Item>
              <Form.Item
                colon={false}
                className="align-item-city form-item-row counter"
                label={<IntlMessages id="uhe.table.iObserverNo" />}
                labelCol={{
                  xl: { span: 20 },
                  lg: { span: 18 },
                  md: { span: 20 },
                  sm: { span: 20 },
                }}
                wrapperCol={{
                  xl: { span: 4 },
                  lg: { span: 4 },
                  md: { span: 4 },
                  sm: { span: 4 },
                }}
              >
                {facility.total_iobserver_users}
              </Form.Item>
            </Row>
            )}
          </Card>
        </div>
        {this.renderHeadLine()}
      </Form>
    );
  }
}

/**
 * Prop types
 * */
ManageFacility.propTypes = {
  history: PropTypes.object,
};

/**
 * @description Maps Global State to Component's Props
 * @returns {Object}
 */
const mapStateToProps = ({
  ConfigurationFacilities, listingsTopFilter, subtitle, common, ConfigurationUsers,
}) => {
  const { savedFacilityId } = ConfigurationFacilities;

  return {
    subtitle,
    savedFacilityId,
    error: common.error,
    loading: ConfigurationFacilities.loading,
    facility: ConfigurationFacilities.selectedFacility || {},
    optionsList: listingsTopFilter,
    loggedUser: ConfigurationUsers.ownUser,
  };
};

/**
 * @description Returns Object Which Dispatch Actions to the Store
 * @param {function} dispatch
 * @returns {Object}
 */
const mapDispatchToProps = (dispatch) => ({
  onGetOrganizationOptions: (id) => dispatch(onGetOptions('organization', id)),
  onGetCustomerOptions: (id) => dispatch(onGetOptions('customer', id)),
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  actions: bindActionCreators(facilityActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLastLocation(injectIntl(withRouter(ManageFacility))));
