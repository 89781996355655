import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from 'util/IntlMessages';
import { Card, Col, Row, Switch, Radio } from 'antd';

/**
 * @description Render Clicician Sidebar Configuration card for edit/add
 * @param customer{object}
 * @param onChangeHandler{function}
 * @param layoutNames{object}
 * @param disabled
 * @param {{ customer: boolean }} readyMap Container for state of loading elements
 * @param {boolean} isNew Switch which tells if the component is new
 * @returns {object}
 */
const ClinicianSidebarConfig = ({
  customer, onChangeHandler, disabled, readyMap, isNew,
}) => {
  const section = 'clinicianSidebarConfig';
  /**
   * @description Render section with the radio buttons
   */
  const renderRadioGroup = () => {
    return (
      <Radio.Group onChange={(event) => onChangeHandler(event, 'clinician_sidebutton_layout')} value={customer.clinicianSidebarConfig.clinician_sidebutton_layout}>
        <Row gutter={[8, 8]}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Radio value={0} disabled={disabled}>
              <IntlMessages id="configuration.customer.default" />
            </Radio>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Radio value={1} disabled={disabled}>
              <IntlMessages id="configuration.customer.action_based" />
            </Radio>
          </Col>
        </Row>
      </Radio.Group>
    );
  };
  return (
    <Card
      className="gx-card customer-edit-clinician-sidebar-card"
      title={<IntlMessages id="configuration.customer.clinician_sidebar_info" />}
      loading={!isNew && !readyMap.customer}
    >
      <Row className="baseLineAlignedItems" gutter={16}>
        <Col lg={8} md={8} sm={24} xs={24}>
          <div><IntlMessages id="configuration.customer.layout" /></div>
        </Col>
        <Col lg={16} md={16} sm={24} xs={24}>
          {renderRadioGroup()}
        </Col>
      </Row>

      <Row className="baseLineAlignedItems" gutter={16}>
        <Col lg={8} md={8} sm={24} xs={24}>
          <div><IntlMessages id="configuration.customer.screenshot_permission" /></div>
        </Col>
        <Col lg={16} md={16} sm={24} xs={24}>
          <Switch
            checked={!!customer.clinicianSidebarConfig.screenshot_enabled}
            onClick={(checked, event) => onChangeHandler(event, 'screenshot_enabled', { val: !checked ? 0 : 1 })}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row className="baseLineAlignedItems" gutter={16}>
        <Col lg={8} md={8} sm={24} xs={24}>
          <div><IntlMessages id="configuration.customer.content_sharing_permission" /></div>
        </Col>
        <Col lg={16} md={16} sm={24} xs={24}>
          <Switch
            checked={!!customer.clinicianSidebarConfig.content_sharing_enabled}
            onClick={(checked, event) => onChangeHandler(event, 'content_sharing_enabled', { val: !checked ? 0 : 1 })}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Card>
  );
};

ClinicianSidebarConfig.defaultProps = {
  isNew: true,
};

ClinicianSidebarConfig.propTypes = {
  customer: PropTypes.object.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  readyMap: PropTypes.shape({ customer: PropTypes.bool }).isRequired,
  isNew: PropTypes.bool,
};

export default ClinicianSidebarConfig;
