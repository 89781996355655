import {
  CONFIGURATION_ORGANIZATIONS_FETCH_DATA,
  CONFIGURATION_ORGANIZATIONS_FETCH_SUCCESS,
  FETCH_ORGANIZATION_BRANDING_REQUEST,
  FETCH_ORGANIZATION_BRANDING_SUCCESS,
  FETCH_ORGANIZATION_REQUEST,
  FETCH_ORGANIZATION_SUCCESS,
  SAVE_ORGANIZATION_REQUEST,
  SAVE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_REQUEST,
  DELETE_ORGANIZATION_SUCCESS,
  CLEAR_ORGANIZATIONS_LOADING,
  FETCH_DATA_CUSTOMERS,
  FETCH_DATA_SUCCESS_CUSTOMERS,
  FETCH_DATA_SUCCESS_FACILITY,
  FETCH_DATA_FACILITY,
  SET_REDIRECT_URL,
} from '@constants/UHEActionTypes';

/**
 * @param  {number} page
 * @param  {string} sorting
 * @param  {string} filter
 * @return {Object}
 */
export const organizationsOnFetchData = (page, sorting, filter) => {
  return {
    type: CONFIGURATION_ORGANIZATIONS_FETCH_DATA,
    page,
    sorting,
    filter,
  };
};

/**
 * @param  {Object} data
 * @return {Object}
 */
export const organizationsFetchDataSuccess = (data) => {
  return {
    type: CONFIGURATION_ORGANIZATIONS_FETCH_SUCCESS,
    data: { ...data },
  };
};

/**
 * @param  {Object} payload
 * @return {Object}
 */
export const fetchOrganization = (payload) => {
  return {
    type: FETCH_ORGANIZATION_REQUEST,
    id: payload.id,
  };
};

/**
 * @param  {Object} payload
 * @return {Object}
 */
export const fetchOrganizationSuccess = (payload) => {
  return {
    type: FETCH_ORGANIZATION_SUCCESS,
    payload: { ...payload },
  };
};
/**
 * @param  {Object} payload
 * @return {Objectpayload: }
 */

export const fetchBranding = (payload) => {
  return {
    type: FETCH_ORGANIZATION_BRANDING_REQUEST,
    id: payload.id,
  };
};

/**
 * @param  {Object} payload
 * @return {Object}
 */
export const fetchBrandingSuccess = (payload) => {
  return {
    type: FETCH_ORGANIZATION_BRANDING_SUCCESS,
    payload,
  };
};

/**
 * @param  {Object} data
 * @return {Object}
 */
export const saveOrganization = (data) => {
  return {
    type: SAVE_ORGANIZATION_REQUEST,
    payload: { ...data },
  };
};

/**
 * @param  {Object} data
 * @return {Object}
 */
export const deleteOrganization = (id, page, sorting, filter, size) => {
  return {
    type: DELETE_ORGANIZATION_REQUEST,
    id,
    page,
    sorting,
    filter,
    size,
  };
};

/**
 * @param  {Object} payload
 * @return {Object}
 */
export const saveOrganizationSuccess = (payload) => {
  return {
    type: SAVE_ORGANIZATION_SUCCESS,
    payload: { ...payload },
  };
};

/**
 * @param  {Object} payload
 * @return {Object}
 */
export const deleteOrganizationSuccess = (payload) => {
  return {
    type: DELETE_ORGANIZATION_SUCCESS,
    payload: { ...payload },
  };
};

/**
 * @description Handles CLEAR_ORGANIZATIONS_LOADING Action
 * @returns {Object}
 */
export const clearLoading = () => {
  return {
    type: CLEAR_ORGANIZATIONS_LOADING,
  };
};
/**
 * @param  {number} page
 * @param  {string} sorting
 * @param  {string} filter
 * @return {Object}
 */
export const organizationFetchDataCustomers = (page, sorting, filter) => {
  return {
    type: FETCH_DATA_CUSTOMERS,
    page,
    sorting,
    filter,
  };
};

/**
 * @param  {Object} data
 * @return {Object}
 */
export const organizationsFetchDataCustomersSuccess = (data) => {
  return {
    type: FETCH_DATA_SUCCESS_CUSTOMERS,
    data: { ...data },
  };
};

/**
 * @param  {number} page
 * @param  {string} sorting
 * @param  {string} filter
 * @return {Object}
 */
export const organizationFetchDataFacility = (page, sorting, filter) => {
  return {
    type: FETCH_DATA_FACILITY,
    page,
    sorting,
    filter,
  };
};

/**
 * @param  {Object} data
 * @return {Object}
 */
export const organizationsFetchDataFacilitySuccess = (data) => {
  return {
    type: FETCH_DATA_SUCCESS_FACILITY,
    data: { ...data },
  };
};

/**
 * Base Set redirect url action
 * @param {Object} payload payload
 * @returns {Object} redirect url
 */
export const setRedirectUrl = (payload) => {
  return {
    type: SET_REDIRECT_URL,
    payload,
  };
};
