import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Row, Col, Card, Input, Checkbox, Form, Cascader, Modal, Select, Tabs, Button,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import EditHeader from '@components/uhe/configuration/EditHeader';
import * as userActions from '@uhe_actions/configuration/users/UsersActions';
import {
  getUserProgramsListing, resetUserProgramsListing,
  onFetchUserAccessData as onFetchUserAccessDataDispatch,
  fetchUserGrant as fetchUserGrantDispatch,
  onFetchOwnPofile as onFetchOwnPofileDispatch,
  clearSelectedUser as clearSelectedUserDispatch,
  changeUserPassword,
  resetUserPassword,
  getExcludedFacilities,
} from '@uhe_actions/configuration/users/UsersActions';

import { onGetOptions } from '@uhe_actions/filters/ListingsTopFilterActions';
import { injectIntl } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import UheHelper, { convertTimestampToDateTime } from 'util/UheHelper';
import {
  goBackTo,
  cascaderSearchFilter,
  removeSearchIcon,
} from '@util/UheHelper';
import { withLastLocation } from 'react-router-last-location';
import {
  PROVIDER,
  CLINICAL_ROLES,
  NURSING,
  ANCILLARY,
  NUMBERS_PATTERN,
  PREFIX,
  APP_PAGES_CONTEXT,
  TABLE_FILTER_PREFIX,
  LISTING_TABLES_PAGE_SIZE,
} from '@constants/UHESettings';
import AuditInfo, { AuditTypes } from '@components/uhe/configuration/AuditInfo';
import {
  shouldSaveUserDetails,
  shouldSaveUserPrograms,
  checkOrganizationOptions,
  shouldShowEditHeader,
  shouldEditTechAuthorized,
  shouldBeAbleToEditUserDetails,
  shouldBeAbleToEditRolesAndPermissions,
  shouldShowTechAuthorized,
  shouldShowChangePassword,
  shouldShowResetPassword,
  shouldBeAbleToEditProgramRolesAndPermissions,
} from '@util/UheRoleChecker';
import UserProgramsTable from '@containers/uhe/Configuration/ManageUsers/UserProgramsTable';
import PermissionsListing from '@components/uhe/configuration/PermssionsListing/PermissionsListing';
import AssignedProgramsTable from '@components/uhe/configuration/AssignedProgramsTable/AssignedProgramsTable';
import lodash from 'lodash';
import {
  PAGE_SIZE,
} from '@constants/SystemConstants';
import ChangePasswordModal from '@components/uhe/listings/ChangePasswordModal';
import cloneDeep from 'lodash/cloneDeep';
import UserPermissionsTable from './UserPermissionsTable';
import UserPermissionsUtils from './UserPermissionsUtils';

const { confirm } = Modal;
const { Option } = Select;
const { TextArea } = Input;

const layout = {
  labelCol: {
    xl: { span: 8 },
    lg: { span: 12 },
    md: { span: 12 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xl: { span: 16 },
    lg: { span: 12 },
    md: { span: 12 },
    sm: { span: 16 },
  },
};

/**
 * Renders User Add/Edit Page
 */
class ManageUser extends React.Component {
  static pageContext = APP_PAGES_CONTEXT.users;

  /**
   * ManageUser Constructor
   * @param {object} props ManageUser Props
   */
  constructor(props) {
    super(props);
    this.clinicalRoles = {
      provider: PROVIDER,
      nursing: NURSING,
      ancillary: ANCILLARY,
    };

    this.permissionColumns = [
      {
        title: <IntlMessages id="configuration.users.adminRole" />,
        dataIndex: 'adminRole',
        width: '30%',
        editable: false,
        render: (text, record, index) => <div className="action-btns">{record.adminRole.value}</div>,
      },
      {
        title: <IntlMessages id="uhe.table.organization" />,
        dataIndex: 'organization',
        width: '30%',
        editable: false,
        render: (text, record, index) => {
          if (record.organization.value === 'All') {
            return (
              <strong className="action-btns">
                {record.organization.value}
              </strong>
            );
          }
          return (
            <div className="action-btns">{record.organization.value}</div>
          );
        },
      },
      {
        title: <IntlMessages id="uhe.table.customer" />,
        dataIndex: 'customer',
        width: '30%',
        editable: false,
        render: (text, record, index) => {
          if (record.customer.value === 'All') {
            return (
              <strong className="action-btns">{record.customer.value}</strong>
            );
          }
          return <div className="action-btns">{record.customer.value}</div>;
        },
      },
      {
        title: <IntlMessages id="uhe.table.facility" />,
        dataIndex: 'facility',
        width: '30%',
        editable: false,
        render: (text, record, index) => {
          if (record.facility.value === 'All') {
            return (
              <strong className="action-btns">{record.facility.value}</strong>
            );
          }
          return <div className="action-btns">{record.facility.value}</div>;
        },
      },
      {
        title: <IntlMessages id="uhe.table.unit" />,
        dataIndex: 'unit',
        width: '30%',
        editable: false,
        render: (text, record, index) => {
          if (record.unit.value === 'All') {
            return <strong className="action-btns">{record.unit.value}</strong>;
          }
          return <div className="action-btns">{record.unit.value}</div>;
        },
      },
      {
        title: <IntlMessages id="uhe.table.device" />,
        dataIndex: 'device',
        width: '30%',
        editable: false,
        render: (text, record, index) => {
          if (record.device.value && record.device.value !== 'All') {
            return (
              <div className="action-btns">
                {record.device.isMobile ? (
                  <div>
                    <i className="icon icon-phone" />
                    {' '}
                    {`${record.device.value} `}
                  </div>
                ) : (
                  <div>
                    <i className="icon icon-data-display" />
                    {' '}
                    {record.device.value}
                    {' '}
                  </div>
                )}
              </div>
            );
          }
          return (
            <strong className="action-btns">{record.device.value}</strong>
          );
        },
      },
    ];
    this.isNew = this.props.location.pathname.indexOf('/new') !== -1;
    this.intl = this.props.intl;
    this.formRef = React.createRef();
    this.changePasswordFormRef = React.createRef();
    this.newTest = [];
    this.state = {
      role: '',
      isPopup: false,
      selectedOrganization: null,
      organizationName: '',
      userPermissionData: {},
      isDisabled: false,
      tableData: [],
      testArr: [],
      adminLevel: '',
      showTable: false,
      user: {
        id: null,
        mobile: {
          role: null,
          specialty: null,
        },
        organization: {
          id: null,
          name: '',
        },
        prefix: '',
        first_name: '',
        last_name: '',
        email: '',
        phone_number: null,
        notes: '',
        admin_level: null,
        is_notification: 0,
        is_clinician: 0,
        is_iobserver: 0,
        is_technician: 0,
        is_readonly: 0,
        tech_authorized: 0,
        is_technician_admin: false,
      },
      organizationOptions: [],
      dataCustomerServer: [],
      data: [],
      test: [],
      customer: [],
      error: false,
      hasChangedOrganization: false,
      userPrograms: [],
      selectedRowKeys: [],
      toggledAllPrograms: false,
      changedPrograms: {},
      currentPrograms: [],
      oldShowMore: 0,
      focusCascader: {
        organization: false,
      },
    };

    this.state.prefixOptions = PREFIX.map((prefix) => <Option key={prefix} value={prefix}>{prefix}</Option>);

    this.renderHeadLine = this.renderHeadLine.bind(this);

    if (this.isNew) {
      if (this.props.subtitle.langId !== 'configuration.users.addUser') {
        this.props.setSubtitle('configuration.users.addUser');
      }

      this.props.onGetOrganizationOptions();
      this.props.onGetCustomerOptions();
    } else if (this.props.subtitle.langId !== 'configuration.users.editUser') {
      this.props.setSubtitle('configuration.users.editUser');
    }

    this.history = this.props.history;
  }

  /**
   * Fires after the component mounts
   * @returns {void} void
   */
  componentDidMount() {
    const { match: { params: { id } } } = this.props;

    this.fetchData();
    this.fetchUserPrograms(id);
  }

  /**
   * Fires before component is unmounted
   * @returns {void} void
   */
  componentWillUnmount() {
    const { resetUserProgramsListingAction } = this.props;
    resetUserProgramsListingAction();
  }

  /**
   * Watch for empty data and if is empty set new state data
   * @param {Readonly<P>} prevProps prevProps
   * @return {void}
   */
  componentDidUpdate(prevProps) {
    const {
      savedUserId,
      error,
      history,
      user,
      data,
      userPermissionData,
      programsData,
    } = this.props;
    const user_data = this.state.user;

    if (prevProps.error !== this.props.error) {
      // handle system error
      if (error.code === 404 && !this.isNew) {
        history.push('/configuration/users');
      }
    }

    if (prevProps.programsData !== programsData) {
      this.setState({ userPrograms: programsData });
    }

    if (prevProps.location.pathname !== this.props.location.pathname) {
      const { match: { params: { id } } } = this.props;
      this.isNew = this.props.location.pathname.indexOf('/new') !== -1;
      this.fetchData();
      this.fetchUserPrograms(id);
    }

    if (this.isNew && savedUserId !== prevProps.savedUserId) {
      history.push(`/configuration/users/edit/${savedUserId}`);
    }
    if (!this.isNew && prevProps.userPermissionData && userPermissionData) {
      if (prevProps.userPermissionData !== userPermissionData) {
        const [_upd, globalGrantsData] = UserPermissionsUtils.fromApiToReact(userPermissionData);
        const { iconsult_notifications, iobserver_notifications } = userPermissionData;
        this.globalGrantsData = { ...globalGrantsData, iconsult_notifications, iobserver_notifications };
        this.setState({
          userPermissionData: UserPermissionsUtils.fromReactToApi(_upd, { ...globalGrantsData, iconsult_notifications, iobserver_notifications }),
          _upd,
          globalGrantsData: { ...globalGrantsData, iconsult_notifications, iobserver_notifications },
        }, this.customer);
      }
    }

    if (!this.isNew && prevProps.user && user && user.organization) {
      if (
        prevProps.user !== user
        || prevProps.user.id !== user.id
        || prevProps.user.saved !== user.saved
      ) {
        this.setState({ user });
        this.props.onGetOrganizationOptions();
        this.props.onGetCustomerOptions(user.organization.id);
      }
    }

    if (
      user
      && prevProps.user
      && prevProps.user.is_technician !== user.is_technician
    ) {
      this.setState({ user });
    }

    if (!this.isNew) {
      this.formRef.current.setFieldsValue({
        organization: [!user_data?.organization?.id ? null : user_data.organization.id],
      });
    }

    if (this.isNew && prevProps.lastLocation && prevProps.lastLocation.pathname.indexOf('edit') !== -1) {
      this.formRef.current.setFieldsValue({
        organization: [user_data?.organization?.id ? user_data.organization.id : ''],
      });
    }
    this.formRef.current.setFieldsValue({
      email: user_data.email,
      phone_number: user_data.phone_number,
      prefix: user_data.prefix,
      first_name: user_data.first_name,
      last_name: user_data.last_name,
      notes: user_data.notes,
    });
  }

  /**
   * Handle Changes in Organization Cascader
   * @param {Array} values values
   * @param {Boolean} hasChangedOrganization boolean value
   * @return {void}
   */
  onChangeOrganizationDropdown = ([{ id, name } = {}] = [], hasChangedOrganization = false) => {
    const { user, focusCascader } = this.state;

    user.organization = { id, name };

    this.setState({ user, hasChangedOrganization }, () => {
      if (hasChangedOrganization) {
        this.saveUser();
        setTimeout(() => {
          this.fetchData();
        }, 1000);
      }
    });
    this.setState({
      ...focusCascader, focusCascader: { organization: false },
    });
  }

  /**
   * Handle Changes in Prefix Select
   * @param {Array} prefix Prefix string value from the Select
   * @returns {void}
   */
  onChangePrefixDropdown = (prefix) => {
    const { user } = this.state;
    user.prefix = prefix;

    this.setState({ user });
  }

  /**
   *  Forwarder for updating user permissions from UserPermissionsTable
   * @param {Object.<string, ReactivePermissionData>} permissions permissions
   * @param {ReactivePermissionData} globalGrantsData global grants data
   * @returns {void}
   */
  updatePermissions = (permissions, globalGrantsData) => {
    this.setState({
      globalGrantsData: { ...globalGrantsData },
      userPermissionData: UserPermissionsUtils.fromReactToApi(permissions, globalGrantsData),
    });
  };

  /**
   * Save Changes on Save Button Click in the header
   * @returns {void}
   */
  saveUser = () => {
    const {
      actions, match: { params: { id } }, setSubtitle, loggedUser,
    } = this.props;
    const {
      user, _upd, hasChangedOrganization, globalGrantsData, userPrograms,
    } = this.state;
    if (!this.isNew) {
      const { iconsult_notifications, iobserver_notifications } = globalGrantsData;
      const body = UserPermissionsUtils.fromReactToApi(_upd, this.globalGrantsData);
      const updBody = { ...body, iconsult_notifications, iobserver_notifications };
      actions.saveUser({
        user,
        grants: { updBody, id },
        shouldSaveUserDetails: shouldSaveUserDetails(loggedUser),
        shouldEditTechAuthorized: shouldEditTechAuthorized(loggedUser),
        shouldSaveUserPrograms: shouldSaveUserPrograms(loggedUser),
        hasChangedOrganization,
        userPrograms,
      });
      actions.saveUserGrants({
        updBody, id,
      });
    } else {
      actions.saveUser({
        user,
        shouldSaveUserDetails: shouldSaveUserDetails(loggedUser),
        shouldSaveUserPrograms: shouldSaveUserPrograms(loggedUser),
        hasChangedOrganization,
      });
    }
    setSubtitle('configuration.users.editUser');
    this.setState({ hasChangedOrganization: false });
  }

  /**
   * Returns function for setting user field (related with name) according to key
   * @param {String} key Name of the user field
   * @returns {function(Event): void} Function for setting user
   * field (related with name) according to key
   */
  onChangeHandlerName = (key) =>
    /**
     * @description Sets user field (related with name) according to key
     * @param {Event} event
     * @returns {void}
     */
    (event) => {
      if (!!event && !!event.persist && !!event.preventDefault) {
        event.persist();
        event.preventDefault();
      }
      this.setState({
        user: {
          ...this.state.user,
          [key]: event.target.value || '',
        },
      });
    }

  /**
   * Returns function for setting user field (related with checkbox) according to key
   * @param {String} key Name of the user field
   * @returns {function(Event): void} Function for setting user field (related with checkbox)
   *  according to key
   */
  onChangeCheckbox = (key) =>
    /**
     * @description Sets user field (related with checkbox) according to key
     * @param {Event} event
     */
    (event) => {
      const newState = event.target.checked ? 1 : 0;
      this.setState({ user: { ...this.state.user, [key]: newState } });
    }

  /**
   * @param {string} prop Name of the state property that has been updated
   * @returns {dependentOf: (function(string): {buildResult: function(any=, any=): (undefined | {[p: string]: boolean, isAllowed: boolean})})}
   */
  changedCascaderOf = (prop) => {
    const capitalizedProp = prop.charAt(0).toUpperCase() + prop.slice(1);
    return {
      /**
       * @param {string} dependentProp Name of the parent cascader this cascader is dependent
       * @returns {buildResult: (function(any=, any=): {[p: string]: boolean, isAllowed: boolean})}
       */
      dependentOf: (dependentProp) => {
        const capitalizedDependentProp = dependentProp.charAt(0).toUpperCase() + dependentProp.slice(1);
        return {
          /**
           * @description Checks input parameters, if invalid sets to default values. Checks if selected values are allowed
           * @param {Array<string>} value
           * @param {Array<string>} label
           * @returns {Object} Object containing templated properties for 'selectedT', 'selectedTFull' and 'TName'
           */
          buildResult: (value, label) => {
            if (label[0] === undefined) return;

            if (value && value.length === 0) {
              this.setState({
                [`selected${capitalizedProp}`]: '',
                [`selected${capitalizedProp}Full`]: '',
                [`${prop}Name`]: '',
              });
            }
            if (!value || !label || !value[0] || !label[0]) return;

            const selectedDependentPropFull = { ...this.state[`selected${capitalizedDependentProp}Full`] };

            const propName = label[0].label;
            const selectedProp = value[0];
            const selectedPropFull = [...selectedDependentPropFull.nested]
              .find((item) => item.name === propName
                && item[prop] === selectedProp);
            if (!selectedPropFull || !selectedPropFull.allowed) return;

            return {
              [`selected${capitalizedProp}`]: selectedProp,
              [`${prop}Name`]: propName,
              [`selected${capitalizedProp}Full`]: selectedPropFull,
              isAllowed: true,
            };
          },
        };
      },
    };
  }

  /**
   * @description Adapt data returned by the server
   * @return {Array}
   */
  role() {
    if (!this.state.userPermissionData) {
      return [];
    }

    return Object.keys(this.state.userPermissionData).reduce(
      (acc, value) => [...acc, {
        label: this.intl.formatMessage({ id: `configuration.users.${value}` }),
        value,
      }], [],
    );
  }

  /**
   * @description Adapt data returned by the server
   * @return {Array}
   */
  organization() {
    const { selectedAdminLevel } = this.state;
    if (!selectedAdminLevel || !selectedAdminLevel.nested) return [];

    return [...selectedAdminLevel.nested].reduce((acc, option) => [...acc, {
      label: option.name,
      value: option.organization,
    }], []);
  }

  /**
   * @description Adapt data returned by the server
   * @return {Array}
   */
  customer() {
    const { selectedOrganizationFull } = this.state;
    if (!selectedOrganizationFull || !selectedOrganizationFull.nested) return [];

    return [...selectedOrganizationFull.nested].reduce((acc, option) => [...acc, {
      label: option.name,
      value: option.customer,
    }], []);
  }

  /**
   * @description Adapt data returned by the server
   * @return {Array}
   */
  facility() {
    const { selectedCustomerFull } = this.state;
    if (!selectedCustomerFull || !selectedCustomerFull.nested) return [];

    return [...selectedCustomerFull.nested].reduce((acc, option) => [...acc, {
      label: option.name,
      value: option.facility,
    }], []);
  }

  /**
   * @description Adapt data returned by the server
   * @return {Array}
   */
  unit() {
    const { selectedFacilityFull } = this.state;
    if (!selectedFacilityFull || !selectedFacilityFull.nested) return [];

    return [...selectedFacilityFull.nested].reduce((acc, option) => [...acc, {
      label: option.name,
      value: option.unit,
    }], []);
  }

  /**
   * @description Adapt data returned by the server
   * @return {Array}
   */
  device() {
    const { selectedUnitFull } = this.state;
    if (!selectedUnitFull || !selectedUnitFull.nested) return [];

    return [...selectedUnitFull.nested].reduce((acc, option) => {
      if (option.nested) {
        [option.nested].forEach((deviceOption) => {
          acc.push({
            label: deviceOption.device
              ? (
                <div>
                  <i className="icon icon-data-display" />
                  {' '}
                  {deviceOption.name}
                </div>
              )
              : (
                <div>
                  <i className="icon icon-phone" />
                  {' '}
                  {deviceOption.name}
                </div>
              ),
            value: deviceOption.device || deviceOption.user,
            isMobile: !deviceOption.device,
          });
        });
      }
      return acc;
    }, []);
  }

  /**
   * Render title, back and save buttons
   * @returns {JSX.Element|null} EditHeader Component | Null
   */
  renderHeadLine() {
    const {
      editMode, loading, lastLocation, history,
    } = this.props;
    const titleKey = editMode
      ? 'configuration.users.addUser'
      : 'configuration.users.editUser';

    if (shouldShowEditHeader()) {
      return (
        <EditHeader
          goBack={goBackTo('/configuration/users', lastLocation?.pathname, history)}
          loading={loading}
          titleKey={titleKey}
        />
      );
    }

    return null;
  }

  warning = () => {
    Modal.warning({
      content: this.intl.formatMessage({ id: 'common.notAllowed' }),
    });
  };

  /**
   * Fetches data for the component
   * @returns {void}
   */
  fetchData = () => {
    const { match: { params: { id: urlId } }, user: { id: userId } } = this.props;
    if (!this.isNew) {
      const {
        actions: { fetchUser }, onFetchUserAccessData, fetchUserGrant, getUserProgramsListingAction,
      } = this.props;
      fetchUser({ id: urlId });
      onFetchUserAccessData({ id: urlId });
      fetchUserGrant({ id: urlId });
      getUserProgramsListingAction(0, 0, 0, 10, urlId);
    } else {
      const { clearSelectedUser } = this.props;
      clearSelectedUser();
    }
  };

  /**
   * Renders Specialty Options Based on Clinical Role
   * @param {string} clinicalRoleKey clinical Role Key
   * @returns {Array} Specialty Options
   */
  renderSpecialtyOptions = (clinicalRoleKey) => {
    const key = clinicalRoleKey?.toLowerCase();

    // Linter breaks with message: TypeError: Cannot read property 'range' of null
    // According to github issues the problem is known and is scheduled to be fixed soon
    // Problem occurs when using template string inline in places
    const getId = (option) => `configuration.bulkUpload.${key}.${option}`;

    return this.clinicalRoles[key]?.map((option) => ({
      value: this.intl.formatMessage({ id: getId(option) }),
      label: this.intl.formatMessage({ id: getId(option) }),
    }
    ));
  }

  /**
   * @param {'role'|'specialty'} key
   * @return {function([string]=): void}
   */
  onChangeOf = (key) =>
    /**
     * @param {string} value
     */
    // eslint-disable-next-line implicit-arrow-linebreak
    ([value] = []) => {
      const { user } = this.state;
      this.setState({
        user: {
          ...user,
          mobile: { ...user.mobile, [key]: value },
        },
      });
    };

  /**
   * Shows Confirmational Modal on Organization Change && Hides Permission Tables
   * @param {array} selected Array with the Selected Organization Object
   * @returns {void}
   */
  showConfirm = (selected) => {
    const { user } = this.state;

    confirm({
      title: this.intl.formatMessage({ id: 'configuration.users.changeOrgMessage' }),
      onOk: () => {
        this.onChangeOrganizationDropdown(selected, true);
      },
      onCancel: () => this.setState({ user }),
      autoFocusButton: 'cancel',
      className: 'dark-mode-modal',
    });
  }

  /**
   * Renders formatted timestamp based on the logged user's timezone
   * @param {string} timestampType Timestamp Type
   * @returns {JXS.Element} Intl message with the formatted timestamp
   */
  renderTimestamp = (timestampType) => {
    const { user, loggedUser } = this.props;
    const { [timestampType]: timestamp } = user;
    const { timeZone } = loggedUser;
    let formattedDateTime = convertTimestampToDateTime(timestamp, timeZone);

    if (formattedDateTime) {
      let [date, time, timeZone] = formattedDateTime.split(' ');
      let index;

      if (timeZone.includes('(')) {
        timeZone = timeZone.slice(1, timeZone.length - 1);
      }
      if (timeZone.includes('+')) {
        index = timeZone.indexOf('+');
      } else if (timeZone.includes('-')) {
        index = timeZone.indexOf('-');
      }

      timeZone = timeZone.slice(0, index);

      formattedDateTime = [date, time, timeZone].join(' ');
    }
    let intlMessage;

    if (formattedDateTime === null) {
      formattedDateTime = <IntlMessages id="common.null" />;
    }

    if (timestampType === this.intl.formatMessage({ id: 'common.createdAt' })) {
      intlMessage = <IntlMessages id="common.created" />;
    } else if (timestampType === this.intl.formatMessage({ id: 'common.updatedAt' })) {
      intlMessage = <IntlMessages id="common.updated" />;
    } else {
      intlMessage = <IntlMessages id="common.lastLogin" />;
    }

    return (
      <div>
        {intlMessage}
        {formattedDateTime}
      </div>
    );
  };

  fetchUserPrograms = (id) => {
    const { getUserProgramsListingAction, programsData } = this.props;
    const { userPrograms } = this.state;
    const programs = userPrograms.length > 0 ? [...userPrograms] : [...programsData];
    // Is sorting required? If not, remove...
    const sort = [];
    const filter = [];

    this.setState({
      userPrograms: programs,
      selectedRowKeys: programs.filter((program) => program.checked).map((program) => program.id),
    });
    if (!this.isNew) {
      getUserProgramsListingAction(0, sort, filter, 10, id);
    }
  }

  /** User Programs */

  /**
   * Ant Design Table Columns Layout
   * @returns {Object} object
   */
  getProgramsColumns = () => (
    [
      {

        title: <div className="userPrograms__column -header"><IntlMessages id="configuration.userPrograms.programs" /></div>,
        dataIndex: 'name',
        key: 'name',
        width: '30%',
      },
      {
        title: <div className="userPrograms__column -header"><IntlMessages id="configuration.userPrograms.excludedFacilities" /></div>,
        dataIndex: 'excl_facilities',
        key: 'excl_facilities',
      },
    ]
  );

  /**
   * Renders Each Program's Checkbox(the User is assigned/not assigned).
   * @param {number} programId programId
   * @param {boolean} isGranted isGranted
   * @returns {JSX.Element} JSX
   */
  renderProgramCheckbox = (programId, isGranted) => {
    const {
      loggedUser,
      user: currentUser,
    } = this.props;
    return (
      <div className="userPrograms__column">
        <Checkbox
          onChange={
            () => this.userProgramToggle(programId, isGranted)
          }
          checked={isGranted}
          disabled={!shouldBeAbleToEditProgramRolesAndPermissions(loggedUser, currentUser)}
        />
      </div>
    );
  };

  /**
   * Each program name in the table.
   * @param {number} programId Program ID
   * @param {string} programName Program Name
   * @returns {JSX.Element} JSX
   */
  renderProgramName = (programId, programName) => (
    <div className="userPrograms__column">
      <Link to={`/configuration/programs/edit/${programId}`}>
        {programName}
      </Link>
    </div>
  )

  /**
   * Show facilities list
   * @param {Array} excluded facilities
   * @returns {Array} of options
   */
  showFacilities(excluded) {
    const { excludedFacilities } = this.props;
    if (excludedFacilities && excludedFacilities.list?.length) {
      return excludedFacilities.list.map((value) => (
        <Option key={value.id} value={value.id} label={value.name}>
          {value.name}
        </Option>
      ));
    } if (excluded && excluded.length) {
      return excluded.map((value) => (
        <Option key={value.id} value={value.id} label={value.name}>
          {value.name}
        </Option>
      ));
    }
    return [];
  }

  /**
   * Each program's relevant facilities
   * @param {number} programId program id
   * @param {number} facilityId facility id
   * @returns {JSX.Element} JSX
   */
  renderProgramFacilities = (programId) => {
    const { userPrograms } = this.state;
    const { loggedUser, user: currentUser } = this.props;
    const savedFacilities = [];

    if (userPrograms.length > 0 && programId) {
      const program_id = userPrograms.find((program) => program.id === programId);
      if (program_id?.excluded.length > 0) {
        program_id.excluded.forEach((options) => {
          savedFacilities.push(options.id);
        });
      }
      if (!program_id?.granted && program_id?.excluded.length > 0) {
        program_id.excluded.length = 0;
        savedFacilities.length = 0;
      }
      return (
        <div className={`userPrograms__column ${this.showFacilities(program_id?.excluded).length >= 8 ? 'showVirtualScrollbar' : ''}`} id="facilities">
          <Select
            mode="multiple"
            className="userPrograms__select"
            allowClear
            placeholder={this.intl.formatMessage({
              id: 'configuration.users.selectFacilities',
            })}
            defaultValue={[]}
            dropdownAlign={{ offset: [75, 4] }}
            bordered={false}
            onDropdownVisibleChange={(event) => this.onOpenSelectEvent(event, programId)}
            onChange={(event) => this.onChangeSelectFacilities(event, programId)}
            onDeselect={(event) => this.onDeselectSelectEvent(event, programId)}
            value={savedFacilities}
            getPopupContainer={() => document.getElementById('facilities')}
            disabled={!program_id?.granted || !shouldBeAbleToEditProgramRolesAndPermissions(loggedUser, currentUser)}
            optionFilterProp="label"
          >
            {this.showFacilities(program_id?.excluded)}
          </Select>
        </div>
      );
    }
  }

  /**
   * Event when click on select dropdown
   * @param {boolean} event true||false
   * @param {number} programId program id
   * @returns {boolean} of event
   */
  onOpenSelectEvent = (event, programId) => {
    const { pageSize, excludedFacilities, getExcludedFacilities } = this.props;
    if (event === true) {
      getExcludedFacilities(0, '', '', pageSize, programId);
    } else if (excludedFacilities.list.length > 0) {
      excludedFacilities.list.length = 0;
    }
  }

  /**
 * Event when click on select dropdown
 * @param {boolean} event true||false
 * @param {number} programId program id
 * @returns {boolean} of event
 */
  onDeselectSelectEvent = (event, programId) => {
    const { pageSize, getExcludedFacilities } = this.props;
    if (event.length > 0) {
      getExcludedFacilities(0, '', '', pageSize, programId);
    }
  }

  /**
 * Change facility selection event
 * @param {Array} event of select
 * @param {number} programId program id
 * @returns {Array} of selected facilities
 */
  onChangeSelectFacilities = (event, programId) => {
    const { userPrograms } = this.state;
    const newPrograms = cloneDeep(userPrograms);
    const { excludedFacilities } = this.props;
    const exactProgram = newPrograms.find((program) => program.id === programId);
    const excluded = event.map((ev) => {
      const facility = excludedFacilities?.list?.find((fac) => fac.id === ev);
      if (facility) {
        return { id: facility.id, name: facility.name };
      } if (exactProgram.excluded) {
        const facilitiesList = exactProgram.excluded.find((exact) => exact.id === ev);
        if (facilitiesList) {
          return { id: facilitiesList.id || null, name: facilitiesList.name };
        }
      }
    });
    exactProgram.excluded = excluded;
    this.setState({ userPrograms: newPrograms });
  }

  /**
   * Get granted programs
   * @returns {Array} of granted programs
   */
  getGrantedPrograms = () => {
    const { userPrograms } = this.state;
    return userPrograms.filter((program) => program.granted);
  }

  /**
   * Ant Design Data Source Array
   * @param {number} showMore page size
   * @returns {Array} array
   */
  getProgramsDataSource = (showMore) => {
    const { programsData } = this.props;
    const { userPrograms, oldShowMore, currentPrograms } = this.state;

    const pageSize = 10;
    if (userPrograms.length === 0 && programsData.length > 0) {
      this.setState({
        userPrograms: programsData,
      });
    }

    const programs = (userPrograms.length > 0 ? userPrograms : programsData).slice((showMore - 1) * pageSize, showMore * pageSize);
    if (oldShowMore !== showMore) {
      this.setState({
        oldShowMore: showMore,
        currentPrograms: [...currentPrograms, ...programs],
      });
    }
    return currentPrograms.map((program) => ({
      key: program.id,
      include_in_program: this.renderProgramCheckbox(program.id, program.granted),
      name: this.renderProgramName(program.id, program.name),
      excl_facilities: this.renderProgramFacilities(program.id),
    }));
  }

  /**
   * Reacts to the Admin's click on assign/unassign program from user.
   * @param {Object} programRow Current selected row
   * @returns {void} void
   */
  userProgramToggle = (programRow) => {
    const currentCheckboxId = programRow.key;
    const { userPrograms } = this.state;

    const programs = [...userPrograms];
    const index = programs.findIndex((e) => e.id === currentCheckboxId);

    programs[index].granted = !programs[index].granted;

    this.setState({
      userPrograms: programs,
      selectedRowKeys: this.getGrantedPrograms().map((program) => program.id),
      toggledAllPrograms: this.getGrantedPrograms().length === programs.length,
    });
  }

  /**
   * Ant design fn -> triggered when Select All Checkbox is clicked.
   * @returns {void} void
   */
  onSelectAll = () => {
    const { userPrograms } = this.state;

    const programs = [...userPrograms];

    if (this.getGrantedPrograms().length === programs.length) {
      this.setState({
        userPrograms: programs.map((program) => ({ ...program, granted: false })),
        selectedRowKeys: [],
        toggledAllPrograms: false,
      });
    } else {
      this.setState({
        userPrograms: userPrograms.map((program) => ({ ...program, granted: true })),
        selectedRowKeys: userPrograms.map((program) => program.id),
        toggledAllPrograms: true,
      });
    }
  }

  /**
   * Ant Design fn -> Determines whether the Select All Checkbox is in Indeterminate state or not.
   * @returns {boolean} boolean
   */
  isIndeterminate = () => {
    const { userPrograms } = this.state;
    const programs = [...userPrograms];

    return this.getGrantedPrograms().length > 0
      && this.getGrantedPrograms().length < programs.length;
  }

  /**
   * Resets userPrograms value on ComponentWillUnmount.
   * @return {void}
   */
  resetUserProgramsTable = () => {
    this.setState({
      userPrograms: [],
      loading: false,
    });
  }

  /**
   * Set password in modal
   * @param {Object} user info
   * @returns {Object} changed password
   */
  toggleChangePasswordModal = (user = null) => {
    this.setState({ passwordChangingUser: user });
  }

  /**
   * Change other users password
   * @return {function(): Promise<void>} Awaitable validation task
   */
  onSubmitChangePassword = async () => {
    const {
      passwordChangingUser,
    } = this.state;
    const { changeUserPassword } = this.props;
    await this.changePasswordFormRef.current?.validateFields();
    const password = this.changePasswordFormRef.current?.getFieldValue('password');
    changeUserPassword({ body: password, id: passwordChangingUser?.id });
    this.toggleChangePasswordModal();
  };

  /**
    * Show reset password confirming
    * @returns {void}
  */
  resetPassword = () => {
    const { user, resetUserPassword } = this.props;

    confirm({
      title: this.intl.formatMessage({ id: 'common.resetPassword' }),
      content: `${this.intl.formatMessage({ id: 'configuration.users.resetPassword' })} ${user.email}`,
      okText: this.intl.formatMessage({ id: 'configuration.users.sendEmail' }),
      onOk: () => {
        resetUserPassword({ body: {}, id: user.id });
      },
      className: 'dark-mode-modal',
    });
  };

  /**
   * Handles What Icon to Show in Cascade Menu - Expand or Search
   * @param {string} field field type
   * @returns {func} manipulate the state
   */
  changeDropdownExpandIcon = (field) => {
    const {
      focusCascader: {
        organization, customer, facility, unit, deviceTypes,
      }, focusCascader,
    } = this.state;
    switch (field) {
      case 'organization':
        return this.setState({ focusCascader: { ...focusCascader, organization: !organization } });
      case 'customer':
        return this.setState({ focusCascader: { ...focusCascader, customer: !customer } });
      case 'facility':
        return this.setState({ focusCascader: { ...focusCascader, facility: !facility } });
      case 'unit':
        return this.setState({ focusCascader: { ...focusCascader, unit: !unit } });
      case 'deviceTypes':
        return this.setState({ focusCascader: { ...focusCascader, deviceTypes: !deviceTypes } });
      default:
    }
  }

  /**
   * Renders ManageUsers Component
   * @returns {JXS.Element} ManageUsers Component
   */
  render() {
    const {
      loading,
      optionsList,
      loggedUser,
      user: currentUser,
      programsLoading,
      programsData,
    } = this.props;
    const {
      user,
      error,
      hasChangedOrganization,
      _upd,
      globalGrantsData,
      userPermissionData,
      selectedRowKeys,
      userPrograms,
      toggledAllPrograms,
      passwordChangingUser,
      prefixOptions,
      focusCascader,
    } = this.state;
    const options = {
      ...optionsList,
      organization: [
        {
          id: null,
          name: this.intl.formatMessage({ id: 'common.allOrganizations' }),
        },
        ...optionsList.organization,
      ],
    };
    const clinicalRoleOptions = CLINICAL_ROLES.map((option) => ({
      value: this.intl.formatMessage({ id: `configuration.bulkUpload.table.users.${option}` }),
      label: this.intl.formatMessage({ id: `configuration.bulkUpload.table.users.${option}` }),
    }));
    const filteredOrgOptions = options.organization.filter((item) => item.id);
    const sortedOrgOptions = options.organization.reduce((acc, current) => (current.id === null ? [current, ...acc] : [...acc, current]), []);

    const programsRowSelection = {
      selectedRowKeys: selectedRowKeys.length > 0
        ? selectedRowKeys
        : userPrograms
          .filter((program) => program.granted)
          .map((program) => program.id),
      onSelect: (programRow) => this.userProgramToggle(programRow),
      columnTitle: <Checkbox
        disabled={!shouldBeAbleToEditProgramRolesAndPermissions(loggedUser, currentUser)}
        onClick={this.onSelectAll}
        checked={
          toggledAllPrograms
          || this.getGrantedPrograms().length === userPrograms.length
        }
        indeterminate={this.isIndeterminate()}
      />,
      getCheckboxProps: (record) => ({
        disabled: !shouldBeAbleToEditProgramRolesAndPermissions(loggedUser, currentUser),
      }),
    };
    return (
      <div className="manage-customer-wrapper system">
        <Form
          ref={this.formRef}
          onFinish={() => this.saveUser(loggedUser)}
          autoComplete="off"
        >
          {this.renderHeadLine()}
          {!loading && error && this.showError()}
          <Row lg={24} md={24} sm={24} sx={24} gutter={16}>
            <Col lg={24} md={24} sm={24} sx={24}>
              {/* Personal info */}
              <Card
                className="gx-card customer-edit-info-card"
                title={<IntlMessages id="configuration.users.userDetails" />}
                loading={loading}
              >
                <Row lg={24} md={24} sm={24} sx={24} gutter={16}>
                  <Col lg={8} md={8} sm={24} sx={24}>
                    <Form.Item
                      colon={false}
                      {...layout}
                      className="form-item-row"
                      label={
                        <IntlMessages id="configuration.users.prefix" />
                      }
                    >
                      <Select
                        placeholder={this.intl.formatMessage({ id: 'configuration.customer.please_select' })}
                        value={user.prefix ? user.prefix : undefined}
                        onChange={this.onChangePrefixDropdown}
                        disabled={
                          !shouldBeAbleToEditUserDetails(loggedUser, currentUser, this.isNew)
                        }
                      >
                        {prefixOptions}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={8} sm={24} sx={24}>
                    <Form.Item
                      colon={false}
                      {...layout}
                      className="form-item-row"
                      name="first_name"
                      label={
                        <IntlMessages id="configuration.users.firstName" />
                      }
                      rules={[
                        {
                          required: true,
                          message: this.intl.formatMessage({
                            id: 'configuration.users.emptyField',
                          }),
                        },
                        {
                          pattern: new RegExp(/^[a-zA-Z0-9\-,']*$/),
                          message: this.intl.formatMessage({
                            id: 'configuration.mobilePatients.wrongNameFormat',
                          }),
                        },
                        {
                          min: 2,
                          message: this.intl.formatMessage({
                            id: 'configuration.mobilePatients.minChars',
                          }),
                        },
                      ]}
                    >
                      <Input
                        placeholder="First Name"
                        autoComplete="off"
                        id="aSettingsUsername"
                        onChange={this.onChangeHandlerName('first_name')}
                        value={user.first_name}
                        longdesc={this.intl.formatMessage({
                          id: 'configuration.users.firstNameInput',
                        })}
                        disabled={
                          !shouldBeAbleToEditUserDetails(loggedUser, currentUser, this.isNew)
                        }
                        rules={[
                          {
                            required: true,
                            message: this.intl.formatMessage({
                              id: 'configuration.users.emptyField',
                            }),
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={8} sm={24} sx={24}>
                    <Form.Item
                      colon={false}
                      {...layout}
                      className="form-item-row"
                      name="last_name"
                      label={<IntlMessages id="configuration.users.lastName" />}
                      rules={[
                        {
                          required: true,
                          message: this.intl.formatMessage({
                            id: 'configuration.users.emptyField',
                          }),
                        },
                        {
                          min: 2,
                          message: this.intl.formatMessage({
                            id: 'configuration.mobilePatients.minChars',
                          }),
                        },
                        {
                          pattern: new RegExp(/^[a-zA-Z0-9\-,']*$/),
                          message: this.intl.formatMessage({
                            id: 'configuration.mobilePatients.wrongNameFormat',
                          }),
                        },
                      ]}
                    >
                      <Input
                        placeholder="Last Name"
                        autoComplete="off"
                        type="text"
                        id="aSettingsUserlastname"
                        name="last_name"
                        onChange={this.onChangeHandlerName('last_name')}
                        value={user.last_name}
                        longdesc={this.intl.formatMessage({
                          id: 'configuration.users.lastNameInput',
                        })}
                        disabled={
                          !shouldBeAbleToEditUserDetails(loggedUser, currentUser, this.isNew)
                        }
                        rules={[
                          {
                            required: true,
                            message: this.intl.formatMessage({
                              id: 'configuration.users.emptyField',
                            }),
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={8} sm={24} sx={24}>
                    <Form.Item
                      colon={false}
                      {...layout}
                      className="form-item-row"
                      name="email"
                      label={<IntlMessages id="configuration.users.email" />}
                      rules={[
                        {
                          required: true,
                          type: 'email',
                          message: this.intl.formatMessage({
                            id: 'configuration.users.emptyField',
                          }),
                        },
                      ]}
                      initialValue={user.email}
                    >
                      <Input
                        placeholder="Email"
                        autoComplete="off"
                        id="email"
                        onChange={this.onChangeHandlerName('email')}
                        longdesc={this.intl.formatMessage({
                          id: 'configuration.users.emailInput',
                        })}
                        disabled={
                          !shouldBeAbleToEditUserDetails(loggedUser, currentUser, this.isNew)
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={8} sm={24} sx={24}>
                    <Form.Item
                      colon={false}
                      className="form-item-row"
                      name="phone_number"
                      label={
                        <IntlMessages id="configuration.users.phoneNumber" />
                      }
                      rules={[
                        {
                          required: false,
                        },
                        {
                          min: 6,
                          message: this.intl.formatMessage({ id: 'configuration.users.minPhoneNumberLength' }),
                        },
                        {
                          max: 20,
                          message: this.intl.formatMessage({ id: 'configuration.users.maxPhoneNumberLength' }),
                        },
                        {
                          pattern: NUMBERS_PATTERN,
                          message: this.intl.formatMessage({ id: 'configuration.users.phoneNumberFormat' }),
                        },
                      ]}
                      labelCol={{
                        xl: { span: 8 },
                        lg: { span: 12 },
                        md: { span: 12 },
                        sm: { span: 8 },
                      }}
                      wrapperCol={{
                        xl: { span: 16 },
                        lg: { span: 12 },
                        md: { span: 12 },
                        sm: { span: 16 },
                      }}
                    >
                      <Input
                        placeholder="Phone Number"
                        autoComplete="off"
                        id="aSettingsPhoneNumber"
                        value={user.phone_number}
                        onChange={this.onChangeHandlerName('phone_number')}
                        longdesc={this.intl.formatMessage({
                          id: 'configuration.users.phoneNumber',
                        })}
                        disabled={
                          !shouldBeAbleToEditUserDetails(loggedUser, currentUser, this.isNew)
                        }
                      />
                    </Form.Item>
                  </Col>
                  {this.isNew && (
                    <Col lg={8} md={8} sm={24} sx={24}>
                      <Form.Item
                        colon={false}
                        {...layout}
                        className="manageUsers__personalInfo--checkbox margin-bottom-0"
                        name="authentication"
                        label={
                          <IntlMessages id="configuration.users.partyAuth" />
                        }
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Checkbox
                          type="checkbox"
                          onChange={this.onChangeCheckbox('thirdpartyauth')}
                          checked={!!+user.thirdpartyauth}
                          longdesc={this.intl.formatMessage({
                            id: 'configuration.users.partyAuth',
                          })}
                          disabled={
                            !shouldBeAbleToEditUserDetails(loggedUser, currentUser, this.isNew)
                          }
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {shouldShowTechAuthorized(user, this.isNew) && (
                    <Col lg={8} md={8} sm={24} sx={24}>
                      <Form.Item
                        colon={false}
                        {...layout}
                        className="manageUsers__personalInfo--checkbox margin-bottom-0"
                        label={
                          <IntlMessages id="configuration.users.isTechAuthorized" />
                        }
                      >
                        <Checkbox
                          type="checkbox"
                          onChange={this.onChangeCheckbox('tech_authorized')}
                          checked={user.tech_authorized}
                          disabled={!shouldEditTechAuthorized(loggedUser)}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  <Col className="user-details-col" lg={24} md={24} sm={24} sx={24}>
                    <Row gutter={16}>
                      <Col
                        lg={8}
                        md={8}
                        sm={24}
                        sx={24}
                      >
                        <Form.Item
                          colon={false}
                          {...layout}
                          className="form-item-row overflow-wrap-anywhere"
                          name="organization"
                          label={<IntlMessages id="uhe.table.organization" />}
                          rules={[
                            {
                              required: true,
                              message: <IntlMessages id="configuration.users.emptyField" />,
                            },
                          ]}
                        >
                          <Cascader
                            getPopupContainer={(event) => event.parentNode}
                            autoComplete={Math.random()}
                            className="top-filter-popup"
                            suffixIcon={focusCascader.organization ? <SearchOutlined /> : null}
                            onClick={() => this.changeDropdownExpandIcon('organization')}
                            fieldNames={{ label: 'name', value: 'id' }}
                            key="organization"
                            expandTrigger="hover"
                            size="large"
                            changeOnSelect
                            options={
                              checkOrganizationOptions(loggedUser)
                                ? filteredOrgOptions : sortedOrgOptions || []
                            }
                            placeholder={this.intl.formatMessage({ id: 'configuration.customer.please_select' })}
                            showSearch={{ filter: cascaderSearchFilter }}
                            longdesc={this.intl.formatMessage({
                              id: 'configuration.users.organizationDropdown',
                            })}
                            value={[user?.organization?.name]}
                            disabled={!shouldBeAbleToEditUserDetails(loggedUser, currentUser, this.isNew)}
                            onChange={(label, selected) => (this.isNew || hasChangedOrganization
                              ? this.onChangeOrganizationDropdown(selected)
                              : this.showConfirm(selected))}
                            onBlur={() => this.setState({ focusCascader: removeSearchIcon() })}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        lg={8}
                        md={8}
                        sm={24}
                        sx={24}
                      >
                        <Form.Item
                          colon={false}
                          {...layout}
                          className="form-item-row"
                          label={
                            <IntlMessages id="configuration.users.clinicalRole" />
                          }
                        >
                          <Cascader
                            getPopupContainer={(event) => event.parentNode}
                            value={user?.mobile?.role ? [user?.mobile?.role] : undefined}
                            options={clinicalRoleOptions}
                            onChange={this.onChangeOf('role')}
                            placeholder={this.intl.formatMessage({ id: 'configuration.customer.please_select' })}
                            disabled={
                              !shouldBeAbleToEditUserDetails(loggedUser, currentUser, this.isNew)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        lg={8}
                        md={8}
                        sm={24}
                        sx={24}
                      >
                        <Form.Item
                          colon={false}
                          {...layout}
                          className="form-item-row"
                          label={
                            <IntlMessages id="configuration.users.specialty" />
                          }
                        >
                          <Cascader
                            getPopupContainer={(event) => event.parentNode}
                            value={user?.mobile?.specialty ? [user?.mobile?.specialty] : undefined}
                            options={this.renderSpecialtyOptions(user?.mobile?.role)}
                            onChange={this.onChangeOf('specialty')}
                            placeholder={this.intl.formatMessage({ id: 'configuration.customer.please_select' })}
                            disabled={
                              !shouldBeAbleToEditUserDetails(loggedUser, currentUser, this.isNew)
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="user-details-col" lg={24} md={24} sm={24} sx={24}>
                    <Row gutter={16}>
                      <Col
                        lg={8}
                        md={8}
                        sm={24}
                        sx={24}
                      >
                        <Form.Item
                          {...layout}
                          className="form-item-row"
                          label={<IntlMessages id="configuration.bedsCarts.acknowledged.notes" />}
                          name="notes"
                        >
                          <TextArea
                            autoSize={{ minRows: 4, maxRows: 10 }}
                            type="text"
                            id="aSettingsUserNotes"
                            name="notes"
                            value={user.notes}
                            onChange={this.onChangeHandlerName('notes')}
                            disabled={
                              !shouldBeAbleToEditUserDetails(loggedUser, currentUser, this.isNew)
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                  </Col>
                </Row>
                {!this.isNew && (
                  <div>
                    <Row lg={24} md={24} sm={24} sx={24} gutter={16}>
                      <div className="timestamps">
                        {this.renderTimestamp(this.intl.formatMessage({ id: 'common.createdAt' }))}
                        {this.renderTimestamp(this.intl.formatMessage({ id: 'common.updatedAt' }))}
                        {this.renderTimestamp(this.intl.formatMessage({ id: 'common.lastSignInAt' }))}
                      </div>
                    </Row>
                    {user?.is_sso_account
                      && (
                        <Row lg={24} md={24} sm={24} sx={24} gutter={16} className="password-btn-wrapper">
                          {shouldShowChangePassword(loggedUser, ManageUser.pageContext) && (
                            <Button
                              onClick={() => this.toggleChangePasswordModal({
                                id: user.id, firstName: user.first_name, lastName: user.last_name,
                              })}
                              title={this.intl.formatMessage({ id: 'common.changePassword' })}
                              className="button-password gx-d-flex change-password"
                            >
                              <i className="icon icon-forgot-password" />
                              <IntlMessages
                                id="common.changePassword"
                              />
                            </Button>
                          )}

                          {passwordChangingUser?.id
                            && (
                              <ChangePasswordModal
                                user={passwordChangingUser}
                                onSubmit={this.onSubmitChangePassword}
                                onCancel={this.toggleChangePasswordModal}
                                ref={this.changePasswordFormRef}
                              />
                            )}
                          {shouldShowResetPassword(loggedUser, ManageUser.pageContext) && (
                            <Button
                              onClick={this.resetPassword}
                              title={this.intl.formatMessage({ id: 'common.resetPassword' })}
                              className="button-password gx-d-flex change-password"
                            >
                              <i className="icon icon-reset-password" />
                              <IntlMessages
                                id="common.resetPassword"
                              />
                            </Button>
                          )}
                        </Row>
                      )}
                  </div>
                )}
              </Card>
            </Col>
          </Row>
          {!this.isNew ? (
            <>
              {/* Program/Role Assignment */}
              <Row gutter={16}>
                <Col lg={24} md={24} sm={24} sx={24}>
                  <Card
                    className="permission-table-card gx-card customer-edit-info-card"
                    title={
                      programsData.length > 0
                        ? (
                          <IntlMessages
                            id="configuration.users.roleAssignment"
                          />
                        )
                        : (
                          <IntlMessages
                            id="configuration.users.roleAssignmentWithoutPrograms"
                          />
                        )
                    }
                    loading={loading}
                  >
                    <Tabs
                      type="card"
                      className="roleAssignment__table--tabs"
                    >
                      {programsData.length > 0
                        && (
                          <Tabs.TabPane className="programs-tab" activeKey="1" key="1" tab="Programs">
                            <UserProgramsTable
                              getProgramsDataSource={(showMore) => this.getProgramsDataSource(showMore)}
                              getProgramsColumns={this.getProgramsColumns()}
                              rowSelection={programsRowSelection}
                              programsLoading={programsLoading}
                              disabled={
                                !shouldBeAbleToEditRolesAndPermissions(loggedUser, currentUser)
                              }
                            />
                          </Tabs.TabPane>
                        )}
                      <Tabs.TabPane key={programsData.length === 0 ? '1' : '2'} tab="User Profile">
                        <UserPermissionsTable
                          dataSource={_upd}
                          globalGrantsData={globalGrantsData}
                          updatePermissions={this.updatePermissions}
                          formatMessage={this.intl.formatMessage}
                          disabled={
                            !shouldBeAbleToEditRolesAndPermissions(loggedUser, currentUser)
                          }
                          showAdminTab
                        />
                      </Tabs.TabPane>
                    </Tabs>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
          {!this.isNew && (
            <>
              <PermissionsListing
                loading={loading}
                permissionsData={userPermissionData}
                globalGrantsData={globalGrantsData}
                isProgramPage={false}
              />
              {userPrograms.length > 0
              && (
                <AssignedProgramsTable
                  loading={loading}
                  userProgramsData={userPrograms}
                />
              )}
            </>
          )}
          {this.renderHeadLine()}
        </Form>
      </div>
    );
  }
}

ManageUser.defaultProps = {
  user: {},
  userPermissionData: {},
  loading: true,
  lastLocation: {
    pathname: '/',
    search: '',
    hash: '',
    state: undefined,
    key: '',
  },
  pageSize: PAGE_SIZE,
  excludedFacilities: {},
};

ManageUser.propTypes = {
  user: PropTypes.shape({}),
  userPermissionData: PropTypes.object,
  intl: PropTypes.object,
  onFetchUserAccessData: PropTypes.func.isRequired,
  fetchUserGrant: PropTypes.func.isRequired,
  setSubtitle: PropTypes.func.isRequired,
  loggedUser: PropTypes.object,
  loading: PropTypes.bool,
  history: PropTypes.object,
  lastLocation: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: undefined,
    key: PropTypes.string,
  }),
  actions: PropTypes.shape({
    saveUser: PropTypes.func.isRequired,
    saveUserGrants: PropTypes.func.isRequired,
  }).isRequired,
  getUserProgramsListingAction: PropTypes.func.isRequired,
  programsData: PropTypes.shape({
    length: PropTypes.number,
    program: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      granted: PropTypes.bool,
    }),
  }).isRequired,

  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  programsLoading: PropTypes.bool.isRequired,
  excludedFacilities: PropTypes.object,
};

/**
 * Maps Global State to Component's Props
 * @returns {Object} object
 */
const mapStateToProps = ({
  ConfigurationUsers,
  listingsTopFilter,
  subtitle,
  common,
}) => {
  const {
    savedUserId,
    userPerm,
    selectedUser,
  } = ConfigurationUsers;
  UheHelper.setOwnUserProfile(ConfigurationUsers.ownUser);

  return {
    subtitle,
    savedUserId,
    error: common.error,
    loading: ConfigurationUsers.loading,
    user: ConfigurationUsers.selectedUser || {},
    userPermissionData: userPerm,
    optionsList: listingsTopFilter,
    loggedUser: ConfigurationUsers.ownUser,
    programsData: selectedUser?.userPrograms?.userProgramsTable/* ?.list */ || [],
    programsLoading: selectedUser?.userPrograms?.programsLoading,
    excludedFacilities: selectedUser?.excludedFacilities?.excludedFacilitiesTable || {},
  };
};

/**
 * Returns Object Which Dispatch Actions to the Store
 * @param {function} dispatch Dispatches Action to Props
 * @returns {object} Object with Actions
 */
const mapDispatchToProps = (dispatch) => ({
  onGetOrganizationOptions: () => dispatch(onGetOptions('organization')),
  onGetCustomerOptions: (id) => dispatch(onGetOptions('customer', id === 'All' ? 0 : id)),
  onGetFacilityOptions: (id) => dispatch(onGetOptions('facility', id)),
  onGetUnitOptions: (id) => dispatch(onGetOptions('unit', id)),
  onFetchUserAccessData: (id) => dispatch(onFetchUserAccessDataDispatch(id)),
  fetchUserGrant: (id) => dispatch(fetchUserGrantDispatch(id)),
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  onFetchOwnPofile: () => dispatch(onFetchOwnPofileDispatch()),
  clearSelectedUser: () => dispatch(clearSelectedUserDispatch()),
  actions: bindActionCreators(userActions, dispatch),
  getUserProgramsListingAction:
    (page, sort, filter, size, userId) => dispatch(
      getUserProgramsListing(page, sort, filter, size, userId),
    ),
  resetUserProgramsListingAction: () => dispatch(resetUserProgramsListing()),
  changeUserPassword: (id) => dispatch(changeUserPassword(id)),
  resetUserPassword: (id) => dispatch(resetUserPassword(id)),
  getExcludedFacilities:
    (page, sort, filter, size, programId) => dispatch(
      getExcludedFacilities(page, sort, filter, size, programId),
    ),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLastLocation(injectIntl(withRouter(ManageUser))));
