import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';
import {
  deleteUheBedCartSuccess,
  deleteNonUheBedCartSuccess,
  fetchBedCartSuccess,
  fetchDeviceTypesSuccess,
  fetchDeviceQrCodeSuccess,
  fetchDeviceAdditionalQrCodeSuccess,
  saveUheBedCartSuccess,
  uploadBedCartPictureSuccess,
  fetchBedCartImageSuccess,
  fetchCareDeviceModesSuccess,
  deleteRoomPictureSuccess,
  saveBedCartFail,
  verifyConnectivitySuccess,
  fetchAcknowledgedByEmailSuccess,
  fetchCommandsStatusSuccess,
  fetchBedCart,
} from '@actions/uhe/configuration/bedsCarts/bedsCartsActions';
import {
  DELETE_UHE_BED_CART_REQUEST,
  DELETE_NON_UHE_BED_CART_REQUEST,
  FETCH_BED_CART_REQUEST,
  FETCH_DEVICE_TYPES_REQUEST,
  FETCH_DEVICE_QR_CODE_REQUEST,
  FETCH_DEVICE_ADDITIONAL_QR_CODE_REQUEST,
  SAVE_UHE_BEDCART_REQUEST,
  ADD_BEDCART_REQUEST,
  FETCH_DEVICE_ACTION,
  FETCH_CALL_ACTION,
  UPLOAD_BEDCART_PICTURE_REQUEST,
  FETCH_BEDCART_IMAGE_REQUEST,
  FETCH_CARE_DEVICE_MODES,
  DELETE_ROOM_PICTURE,
  VERIFY_CONNECTIVITY,
  FETCH_ACKNOWLEDGED_EMAIL,
  FETCH_COMMANDS_STATUS,
} from '@constants/UHEActionTypes';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { fetchError, showMessage } from '@actions/Common';
import RestManager from '@util/RestManager';
import { onFetchData } from '@uhe_actions/monitoring/UHEActions';

/**
 * Sends GET Request
 * @param {string} device_id device id
 * @returns {Object} object
 */
const fetchBedCartById = async (device_id) => await RestManager.request(
  `${ENDPOINTS.monitoring.UHETable}/${device_id}`,
);

/**
 * Sends GET Request
 * @param {string} id device id
 * @returns {Array} array
 */
const getCommandsStatus = async (id) => await RestManager.request(`${ENDPOINTS.monitoring.UHETable}/${id}${ENDPOINTS.command}status`);
/**
 * Sends POST Request
 * @param {Object} data data
 * @param {string} id id
 * @returns {string} string
 */
const fetchDeviceAction = async (data, id) => await RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.monitoring.UHETable}/${id}${ENDPOINTS.command}${data}`, 'POST', data,
);

/**
 * Sends GET Request
 * @returns {Array} array
 */
const getDeviceTypes = async () => await RestManager.request(`${ENDPOINTS.bedsCarts.deviceTypes}?size=1000&sort=list_priority,desc&sort=device_common_name,asc`);

/**
 * Sends GET Request
 * @returns {Object} object
 */

const fetchAcknowledgedByEmail = async () => await RestManager.request(`${ENDPOINTS.monitoring.UHETable}/acknowledged_email`);

/**
 * Sends GET Request
 * @param {object} data data
 * @returns {Object} object
 */
const getDeviceQrCode = async (data) => await RestManager.request(`${ENDPOINTS.bedsCarts.technician}${data.payload}${ENDPOINTS.bedsCarts.qrCodeMain}/one`);

/**
 *  Sends GET Request
 * @param {object} data data
 * @returns {Object} object
 */
const getDeviceAdditionalQrCode = async (data) => await RestManager.request(`${ENDPOINTS.bedsCarts.technician}${data.payload}${ENDPOINTS.bedsCarts.qrCodeMain}/two`);

/**
 * Sends GET Request
 * @param {object} data data
 * @returns {Object} object
 */
const getDeviceComponentImage = async (data) => await RestManager.request(`${ENDPOINTS.monitoring.UHETable}/${data.payload.device_id}/components/${data.payload.component_id}/image`);

/**
 * Sends DELETE Request
 * @param {String} device_id device id
 * @returns {Object} object
 */
const doDeleteUheBedCart = async (device_id) => await RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.monitoring.UHETable}/${device_id}`,
  'DELETE',
);

/**
 * Sends POST Requst to /endpoints/:id
 * @param {Object} bedCart bedCart
 * @param {string} id id
 * @returns {Object} object
 */
const saveBedCartById = async (bedCart, id) => await RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.monitoring.UHETable}/${id}`,
  'POST',
  bedCart,
);

/**
 * Sends POST Requst to /endpoints
 * @param {Object} body body
 * @returns {Object} object
 */
const addNewBedCart = async (body) => await RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.monitoring.UHETable}`,
  'POST',
  body,
);

/**
 * Upload bedCart picture request
 * @param {Object} file file
 * @param {string} id id
 * @returns {Object} object
 */
const uploadBedCartPictureRequest = async (file, id) => await RestManager.formDataRequest(
  `${ENDPOINTS.monitoring.UHETable}/${id}/components/images`,
  file,
);

/**
 * Sends DELETE Request
 * @param {Number} imgId for img delete
 * @param {String} id for bedCart
 * @returns {Object} to delete
 */
const doDeletePictrure = async (imgId, id) => await RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.monitoring.UHETable}/${id}/components/${imgId}/image`,
  'DELETE',
);

/**
 * Handles Response and Request for uploading Image file
 * @param {Object} payload data
 * @returns {void}
 */
function* onUploadBedCartPicture(payload) {
  try {
    const uploadBedCartPicture = yield call(
      uploadBedCartPictureRequest,
      payload.file,
      payload.id,
    );

    if (uploadBedCartPicture) {
      yield put(uploadBedCartPictureSuccess());
      yield put(showMessage('saved_images_success'));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Fetches Bed/Cart by ID
 * @param {Object} payload data
 * @returns {void}
 */
function* doFetchBedCartById({ payload }) {
  try {
    const fetchedBedCart = yield call(fetchBedCartById, payload.id);
    if (fetchedBedCart) {
      yield put(fetchBedCartSuccess({ ...fetchedBedCart }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Fetches Acknowledged_email
 * @returns {void}
 */
function* doFetchAknowledgedByEmail() {
  try {
    const fetchedAcknowledgedByemail = yield call(fetchAcknowledgedByEmail);

    if (fetchedAcknowledgedByemail) {
      yield put(fetchAcknowledgedByEmailSuccess(fetchedAcknowledgedByemail));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Fetches Device Action
 * @param {Object} payload data for actions
 * @returns {void}
 */
function* doFetchDeviceAction({ payload }) {
  try {
    const fetchAction = yield call(fetchDeviceAction, payload.command, payload.id);

    if (fetchAction) {
      switch (payload.command) {
        case 'test_call':
          return yield put(showMessage('testCallStart'));
        case 'end_test_call':
          return yield put(showMessage('endTestCall'));
        case 'selftest':
          return yield put(showMessage('selfTestStart'));
        case 'endselftest':
          return yield put(showMessage('selfTestEnd'));
        case 'ring_doorbell' || 'play_sound':
          return yield put(showMessage('sound'));
        case 'end_call':
          return yield put(showMessage('hangUpCall'));
        case 'alert':
          return yield put(showMessage('alertFromRoom'));
        case 'factory_reset':
          yield put(fetchBedCart({ id: payload.id }));
          return yield put(showMessage('factory_reset'));
        case 'hard_reboot':
          return yield put(showMessage('hardReboot'));
        case 'soft_reboot':
          return yield put(showMessage('softReboot'));
        case 'reboot_os':
          return yield put(showMessage('restartOS'));
        default:
          return null;
      }
    }
  } catch (error) {
    yield put(fetchError(error));
    yield put(saveBedCartFail());
  }
}

/**
 * Sends POST Request
 * @param {Object} id data
 * @returns {string} sting
 */
const fetchCallAction = async (id) => await RestManager.request(
  `${ENDPOINTS.monitoring.UHETable}/${id}/call-room`,
);

/**
 * Fetches Call Room Action
 * @param {Object} data data
 * @returns {void}
 */
function* doFetchCallAction(data) {
  try {
    const response = yield call(fetchCallAction, data.payload.id, data.payload.machine_name);
    if (response) {
      const accessToken = localStorage.getItem('accessToken');
      const serverTag = window.env_overrides.REACT_APP_SERVER_TAG
      || process.env.REACT_APP_SERVER_TAG;
      const isIConsultV2 = window.env_overrides.REACT_APP_ICONSULTV2
      || process.REACT_APP_ICONSULTV2;
      const IConsultBASEURL = window.env_overrides.REACT_APP_CALL_ROOM_URL
        || process.env.REACT_APP_CALL_ROOM_URL;
      const IConsultV2BASEURL = window.env_overrides.REACT_APP_ICONSULT_GATEWAY_URL
      || process.env.REACT_APP_ICONSULT_GATEWAY_URL;
      const IConsultURL = `${IConsultBASEURL}/clinician_sso?access_token=${accessToken}&endpoint_id=${serverTag}_${data.payload.id}&private=false`;
      const IConsultV2URL = `${IConsultV2BASEURL}/api/internal_call?private=false&silent=false&no_prompt=false&product=NOTIFICATION&access_token=${accessToken}&device_id=${data.payload.id}&display_name=${data.payload.name}`;
      window.open(isIConsultV2 ? IConsultV2URL : IConsultURL, '_blank');
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Fetches UHE/NON UHE Device Types
 * @param {Object} data data
 * @returns {void}
 */
function* doFetchDeviceTypes({ data }) {
  try {
    const fetchedDeviceTypes = yield call(getDeviceTypes, data);
    if (fetchedDeviceTypes) {
      yield put(fetchDeviceTypesSuccess({ types: fetchedDeviceTypes }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Fetches UHE QR Code
 * @param {Object} data data
 * @returns {void}
 */
function* doFetchDeviceQrCode(data) {
  try {
    const fetchedQrCode = yield call(getDeviceQrCode, data);
    if (fetchedQrCode) {
      yield put(fetchDeviceQrCodeSuccess(fetchedQrCode));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Fetches Additional UHE QR Code
 * @param {Object} data data
 * @returns {void}
 */
function* doFetchDeviceAdditionalQrCode(data) {
  try {
    const fetchedAdditionalQrCode = yield call(getDeviceAdditionalQrCode, data);
    if (fetchedAdditionalQrCode) {
      yield put(fetchDeviceAdditionalQrCodeSuccess(fetchedAdditionalQrCode));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Fetches Device Image
 * @param {Object} data data
 * @returns {void}
 */
function* doFetchDeviceImage(data) {
  try {
    const fetchedDeviceImage = yield call(getDeviceComponentImage, data);
    if (fetchedDeviceImage) {
      yield put(fetchBedCartImageSuccess(fetchedDeviceImage));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Handles Delete UHE Bed/Cart by device_id
 * @param {string} data data
 * @returns {void}
 */
function* deleteUheBedCartById(data) {
  try {
    const deletedUheBedCart = yield call(
      doDeleteUheBedCart,
      data.payload.device_id,
    );
    if (deletedUheBedCart) {
      yield put(onFetchData(data.payload.page, data.payload.sorting, data.payload.filter));
      yield put(deleteUheBedCartSuccess());
      yield put(showMessage('apsDeleted'));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Handles Delete NON UHE Bed/Cart by device_id
 * @param {string} data data
 * @returns {void}
 */
function* deleteNonUheBedCartById(data) {
  try {
    const deletedUheBedCart = yield call(
      doDeleteUheBedCart,
      data.payload.device_id,
    );
    if (deletedUheBedCart) {
      yield put(onFetchData(data.payload.page, data.payload.sorting, data.payload.filter, 'standards'));
      yield put(deleteNonUheBedCartSuccess());
      yield put(showMessage('sbapsDeleted'));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Handles Edit for the Bed/Cart
 * @param {Object} data data
 * @returns {void}
 */
function* saveBedCartEdit({ payload }) {
  try {
    if (payload) {
      const { body, id, showToast = true } = payload;
      const bedCart = yield call(
        saveBedCartById,
        body,
        id,
      );

      if (bedCart) {
        yield put(saveUheBedCartSuccess(bedCart));
        if (showToast) {
          if (payload.body.endpoint_type === 'Standards Based') {
            yield put(showMessage('sbapsUpdated'));
          } else {
            yield put(showMessage('apsUpdated'));
          }
        }
      }
      if (!payload.isFactoryReset) {
        const fetchedBedCart = yield call(fetchBedCartById, id);
        if (fetchedBedCart) {
          yield put(fetchBedCartSuccess({ ...fetchedBedCart }));
        }
      }
    }
  } catch (error) {
    yield put(fetchError(error));
    yield put(saveBedCartFail());
  }
}

/**
 * Handles Create functionality for Bed/Cart
 * @param {Oject} data data
 * @returns {void}
 */
function* createBedCart(data) {
  try {
    if (data.payload) {
      const newBedCart = yield call(addNewBedCart, data.payload.body);

      if (newBedCart) {
        if (data.payload.body.endpoint_type === 'Standards Based') {
          yield put(showMessage('sbapsCreated'));
        } else {
          yield put(showMessage('apsCreated'));
        }
        data.payload.redirectOnNewBedCart(
          newBedCart.id,
          data.payload.body.endpoint_type === 'Standards Based',
        );
      }
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Sends GET Request
 * @returns {Object} object
 */
const fetchModesRequest = async () => await RestManager.request(
  `${ENDPOINTS.monitoring.UHETable}/device_config_modes`,
);

/**
 * Fetches Bed/Cart modes
 * @param {Object} payload data
 * @returns {void}
 */
function* doFetchModesRequest() {
  try {
    const fetchedModes = yield call(fetchModesRequest);
    if (fetchedModes) {
      yield put(fetchCareDeviceModesSuccess({ ...fetchedModes }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Handles Delete Room Picture
 * @param {string} data to delete
 * @returns {void}
 */
function* deleteRoomPicture(data) {
  try {
    const deletedUheBedCart = yield call(
      doDeletePictrure,
      data.payload.imgId,
      data.payload.id,
    );
    if (deletedUheBedCart) {
      yield put(deleteRoomPictureSuccess());
      yield put(showMessage('delete_success'));
      const fetchedBedCart = yield call(fetchBedCartById, data.payload.id);
      if (fetchedBedCart) {
        yield put(fetchBedCartSuccess({ ...fetchedBedCart }));
      }
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Request to check connection
 * @param  {Object} bodyData for connection
 * @param {string} id id
 * @return {Object} data
 */
const verifyConnectivity = async (bodyData, id) => {
  const res = await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.monitoring.UHETable}/${id}/verify_connection`,
    'POST',
    bodyData,
  );
  return res;
};

/**
 * Verify connection
 * @param {Object} data payload
 * @returns {void}
 */
function* doVerifyConnectivity(data) {
  try {
    yield call(verifyConnectivity, data.payload.body, data.payload.id);
    yield put(verifyConnectivitySuccess());
  } catch (error) {
    yield put(fetchError(error));
  }
}
/**
 * Verify connection
 * @param {Object} data payload
 * @returns {void}
 */
function* doFetchCommandsStatus(data) {
  try {
    const response = yield call(getCommandsStatus, data.payload);
    if (response) {
      yield put(fetchCommandsStatusSuccess(response));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Generator function which handles FETCH_BED_CART_REQUEST action
 * @returns {void}
 */
export function* fetchBedCartWatcher() {
  yield takeEvery(FETCH_BED_CART_REQUEST, doFetchBedCartById);
}

/**
 * Generator function which handles FETCH_ACKNOWLEDGED_EMAIL action
 * @returns {void}
 */
export function* fetchAknowledgedByEmailWatcher() {
  yield takeEvery(FETCH_ACKNOWLEDGED_EMAIL, doFetchAknowledgedByEmail);
}

/**
 * Generator function which handles FETCH_DEVICE_ACTION action
 * @returns {void}
 */
export function* fetchDeviceActionWatcher() {
  yield takeEvery(FETCH_DEVICE_ACTION, doFetchDeviceAction);
}

/**
 * Generator function which handles FETCH_DEVICE_ACTION action
 * @returns {void}
 */
export function* fetchCallActionWatcher() {
  yield takeEvery(FETCH_CALL_ACTION, doFetchCallAction);
}

/**
 * Generator function which handles FETCH_DEVICE_TYPES_REQUEST action
 * @returns {void}
 */
export function* fetchDeviceTypesWatcher() {
  yield takeEvery(FETCH_DEVICE_TYPES_REQUEST, doFetchDeviceTypes);
}

/**
 * Generator function which handles FETCH_DEVICE_QR_CODE_REQUEST action
 * @returns {void}
 */
export function* fetchDeviceQrCodeWatcher() {
  yield takeEvery(FETCH_DEVICE_QR_CODE_REQUEST, doFetchDeviceQrCode);
}

/**
 * Generator function which handles FETCH_DEVICE_ADDITIONAL_QR_CODE_REQUEST action
 * @returns {void}
 */
export function* fetchDeviceAdditionalQrCodeWatcher() {
  yield takeEvery(FETCH_DEVICE_ADDITIONAL_QR_CODE_REQUEST, doFetchDeviceAdditionalQrCode);
}

/**
 * Generator function which handles FETCH_BEDCART_IMAGE_REQUEST action
 * @returns {void}
 */
export function* fetchBedCartImageWatcher() {
  yield takeEvery(FETCH_BEDCART_IMAGE_REQUEST, doFetchDeviceImage);
}

/**
 * Generator function which handles DELETE_UHE_UNIT_REQUEST action
 * @returns {void}
 */
export function* deleteUheBedCart() {
  yield takeEvery(DELETE_UHE_BED_CART_REQUEST, deleteUheBedCartById);
}

/**
 * Generator function which handles DELETE_NON_UHE_BED_CART_REQUEST action
 * @returns {void}
 */
export function* deleteNonUheBedCart() {
  yield takeEvery(DELETE_NON_UHE_BED_CART_REQUEST, deleteNonUheBedCartById);
}

/**
 * Generator function which handles SAVE_UHE_BEDCART_REQUEST action
 * @returns {void}
 */
export function* saveBedCartWatcher() {
  yield takeEvery(SAVE_UHE_BEDCART_REQUEST, saveBedCartEdit);
}

/**
 * Generator function which handles ADD_BEDCART_REQUEST action
 * @returns {void}
 */
export function* createBedCartWatcher() {
  yield takeEvery(ADD_BEDCART_REQUEST, createBedCart);
}

/**
 * Generator function which handles UPLOAD_BEDCART_PICTURE_REQUEST action
 * @returns {void}
 */
export function* uploadBedCartPictureWatcher() {
  yield takeEvery(UPLOAD_BEDCART_PICTURE_REQUEST, onUploadBedCartPicture);
}

/**
 * Generator function which handles FETCH_BED_CART_REQUEST action
 * @returns {void}
 */
export function* fetchModesWatcher() {
  yield takeEvery(FETCH_CARE_DEVICE_MODES, doFetchModesRequest);
}

/**
 * Generator function which handles DELETE_ROOM_PICTURE action
 * @returns {void}
 */
export function* deleteRoomPictureWatcher() {
  yield takeEvery(DELETE_ROOM_PICTURE, deleteRoomPicture);
}

/**
 * Generator function which handles VERIFY_CONNECTIVITY action
 * @returns {void}
 */
export function* verifyConnectivityWatcher() {
  yield takeEvery(VERIFY_CONNECTIVITY, doVerifyConnectivity);
}

/**
 * Generator function which handles fetchCommandsStatus action
 * @returns {void}
 */
export function* fetchCommandsStatus() {
  yield takeEvery(FETCH_COMMANDS_STATUS, doFetchCommandsStatus);
}

/**
 * RootSaga
 * @returns {void}
 */
export default function* rootSaga() {
  yield all([
    fork(deleteUheBedCart),
    fork(deleteNonUheBedCart),
    fork(fetchBedCartWatcher),
    fork(fetchDeviceActionWatcher),
    fork(fetchDeviceTypesWatcher),
    fork(fetchDeviceQrCodeWatcher),
    fork(fetchDeviceAdditionalQrCodeWatcher),
    fork(saveBedCartWatcher),
    fork(createBedCartWatcher),
    fork(fetchAknowledgedByEmailWatcher),
    fork(fetchBedCartImageWatcher),
    fork(fetchModesWatcher),
    fork(fetchCallActionWatcher),
    fork(deleteRoomPictureWatcher),
    fork(verifyConnectivityWatcher),
    fork(fetchCommandsStatus),
  ]);
}
