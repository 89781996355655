import {
    MONITORING_STANDARDS_FETCH_SUCCESS,
  } from "@constants/UHEActionTypes";
  
  /**
   * @param  {Object} data
   * @return {Object}
   */
  export const standardsFetchDataSuccess = (data) => {
    return {
      type: MONITORING_STANDARDS_FETCH_SUCCESS,
      data,
    }
  };
  