import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import IntlMessages from 'util/IntlMessages';

import { fetchVmrReport } from '@uhe_actions/reports/VmrReportActions';
import { setSubtitle } from '@uhe_actions/SubtitleActions';

class VmrReport extends Component {
  constructor(props) {
    super(props);

    this.props.setSubtitle('vmr.title');

    this.renderVmrCard = this.renderVmrCard.bind(this);
  }

  /**
   * @description Populate vmrReports.vmrData Prop
   * @returns {void}
   */
  componentDidMount() {
    this.props.fetchVmrReport();
  }

  /**
   * @description Renders Each VMR Card
   * @param {Object} vmr
   * @param {Number} index
   * @returns {JSX}
   */
  renderVmrCard(vmr, index) {
    this.props.vmrReport.vmrData.sort((a, b) => {
      if (a.organization.name !== null && b.organization.name) {
        const texta = a.organization.name.toUpperCase();
        const textb = b.organization.name.toUpperCase();
        return (texta < textb) ? -1 : (texta > textb) ? 1 : 0;
      }
    });
    return (
      <Col lg={8} key={index}>
        <Card
          title={vmr.organization.name ? vmr.organization.name : ''}
          className="gx-card"
          loading={this.props.loading}
        >
          <div className="gx-d-flex gx-justify-content-between gx-mt-2">
            <p>
              <IntlMessages id="vmr.vmr_available" />
              :
            </p>
            <span>{vmr.vmrs_available}</span>
          </div>
        </Card>
      </Col>
    );
  }

  render() {
    const { vmrReport } = this.props;

    return (
      <div>
        <Row gutter={16}>
          {Array.isArray(vmrReport.vmrData) &&
            vmrReport.vmrData.map((vmr, index) => {
              return this.renderVmrCard(vmr, index);
            })}
        </Row>
      </div>
    );
  }
}

VmrReport.defaultProps = {
  vmrReport: {},
  loading: true,
};

VmrReport.propTypes = {
  vmrReport: PropTypes.object,
  fetchVmrReport: PropTypes.func,
  setSubtitle: PropTypes.func,
};

const mapStateToProps = ({ vmrReport, subtitle }) => {
  return {
    vmrReport: vmrReport,
    loading: vmrReport.loading,
    subtitle,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchVmrReport: (data) => dispatch(fetchVmrReport(data)),
    setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VmrReport));
