import {
  all, takeEvery, fork, put, call,
} from 'redux-saga/effects';
import { UPLOAD_SBAPS_DOCUMENT_REQUEST } from '@constants/UHEActionTypes';
import { uploadSBAPSDocumentSuccess } from '@actions/uhe/configuration/nonUheUnits/NonUheUnitsActions';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { fetchError, showMessage } from '@actions/Common';
import RestManager from '@util/RestManager';
import { fetchUploadLogsRequest } from '@actions/uhe/configuration/users/UsersActions';

/**
 * Upload SBAPS document request
 * @param {Object} file file
 * @returns {Object} response
 */
const uploadDocumentRequest = async (file) => {
  const response = await RestManager.formDataRequest(
    `${ENDPOINTS.nonUheUnits.uploadSBAPS}`,
    file,
  );
  return response;
};

/**
 * Handles Response and Request for uploading CSV file
 * @param {Object} payload data
 * @returns {void}
 */
function* uploadDocument(payload) {
  try {
    const uploadDocumentCall = yield call(uploadDocumentRequest, payload.payload.file);

    if (uploadDocumentCall) {
      yield put(uploadSBAPSDocumentSuccess());
      yield put(fetchUploadLogsRequest());

      yield put(showMessage('save_success'));
    }
  } catch (error) {
    yield put(fetchUploadLogsRequest());
    yield put(fetchError(error));
  }
}

/**
 * Saga Watcher
 * @returns {void}
 */
export function* actionsWatcher() {
  yield takeEvery(UPLOAD_SBAPS_DOCUMENT_REQUEST, uploadDocument);
}

/**
 * Root Saga
 * @returns {void}
 */
export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
