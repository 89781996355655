import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import lodash from 'lodash';
import { Button, Card, Table } from 'antd';
import PropTypes from 'prop-types';

import ListingsTableInputFilter from '@filters/ListingsTableInputFilter';
import IntlMessages from 'util/IntlMessages';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import {
  LISTING_TABLES_PAGE_SIZE,
  TABLE_FILTER_PREFIX,
  APP_PAGES_CONTEXT,
  IS_EDIT_PAGE_REGEX,
} from '@constants/UHESettings';
import {
  onFetchTwilioData,
  deleteTwilio,
} from '@uhe_actions/system/SystemActions';
import ActionsCell from '@components/tables/cells/ActionsCell';
import { shouldResetPaginationSuccess } from '@actions';
import { withLastLocation } from 'react-router-last-location';
import { DownOutlined } from '@ant-design/icons';

/**
 * Twilio Class Component
 * @returns {void}
 */
class Twilio extends Component {
  /**
   * Twilio Component Constructor
   * @param {object} props Twilio Component Props
   * @returns {void}
   */
  constructor(props) {
    super(props);
    const {
      history, subtitle, setSubtitleAction,
    } = this.props;
    this.state = {
      showMore: 2,
    };

    this.tableFilterMap = {
      countryName: 'name',
      dialingCode: 'dial_code',
      isoCode: 'flag_code',
      format: 'format',
    };

    this.tableKeys = [
      'countryName',
      'dialingCode',
      'isoCode',
      'format',
      'actions',
    ];

    this.data = [];
    this.columns = [];
    this.filterTypes = {};

    this.tableKeys.forEach((value, index) => {
      const filter = this.filterTypes[value] || {};
      this.columns.push({
        title: (cellData) => (
          <ListingsTableInputFilter
            filterType={filter.type}
            filterOptions={filter.options}
            showFilter={
              !(
                value === 'actions'
              )
            }
            cellData={cellData}
            title={`uhe.table.${value}`}
            dataKey={value}
          />
        ),
        ellipsis: {
          showTitle: true,
        },
        sorter: (value === 'actions') ? false : { multiple: index },
        defaultSortOrder: (value === 'name') ? this.checkSortTableOrder() : false,
        align: index > 3 ? 'center' : 'left',
        minWidth: 200,
        dataIndex: value,
        render: (content) => this.cellRenderer(content, value),
      });
    });

    if (subtitle?.langId !== 'system.twilioTitle') {
      setSubtitleAction('system.twilioTitle');
    }

    this.qParams = new URLSearchParams(history.location.search);
  }

  /**
   * componentDidMount() is invoked immediately after
   * a component is mounted (inserted into the tree)
   * @returns {void} void
   */
  componentDidMount() {
    const {
      lastLocation,
      pagination,
      shouldResetPaginationFlag,
      resetPaginationSuccess,
    } = this.props;

    if (!IS_EDIT_PAGE_REGEX.test(lastLocation?.pathname) || shouldResetPaginationFlag) {
      this.onPageChange(1);
      resetPaginationSuccess();
      return;
    }

    this.onPageChange(pagination?.current || 1);
  }

  /**
   * Invokes on Update
   * @param {object} prevProps Twilio PrevProps
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const {
      location,
      lastLocation,
      shouldResetPaginationFlag,
      resetPaginationSuccess,
    } = this.props;

    if (location.search !== prevProps.location.search) {
      this.qParams = new URLSearchParams(location.search);
      this.onPageChange(1);
    }

    if (location.hash !== prevProps.location.hash
      && (!IS_EDIT_PAGE_REGEX.test(lastLocation?.pathname) || shouldResetPaginationFlag)) {
      this.onPageChange(1);
      resetPaginationSuccess();
    }
  }

  /**
   * Checks Sort Order
   * @returns {string} Order string
   */
  checkSortTableOrder = () => {
    const { location } = this.props;
    let checkOrder = location.search.split('%2C')[1];

    if (checkOrder === 'asc') {
      checkOrder = 'ascend';
    }
    if (checkOrder === 'desc') {
      checkOrder = 'descend';
    }
    if (!checkOrder) {
      checkOrder = false;
    }

    return checkOrder;
  }

  /**
   * Handles Page Changes
   * @param {number} page Page Number
   * @returns {void}
   */
  onPageChange = (page) => {
    const { onFetchTwilioData } = this.props;
    this.currentPage = page - 1;
    const currSort = this.qParams.getAll('sort') || [];
    const defaultSort = ['name,asc'];
    const sort = [];
    const filter = [];

    lodash.forOwn(this.topFilterMap, (value, key) => {
      const filterParam = this.qParams.get(key);
      if (filterParam) {
        filter.push(`${value}=${filterParam}`);
      }
    });

    lodash.forOwn(this.tableFilterMap, (value, key) => {
      const filterParam = this.qParams.get(`${TABLE_FILTER_PREFIX}${key}`);
      const sorter = currSort.find(
        (element) => element === `${key},asc` || element === `${key},desc`,
      );

      if (filterParam) {
        if (
          this.filterTypes[key]
          && this.filterTypes[key].type === 'dropdown'
        ) {
          filter.push(`${value}=${encodeURIComponent(filterParam)}`);
        } else {
          filter.push(`${value}~=${encodeURIComponent(`%${filterParam}%`)}`);
        }
      }
      if (sorter) {
        sort.push(sorter.replace(key, value));
      }
    });

    this.filter = filter;
    this.sort = sort;
    this.setState({ showMore: page + 1 });
    onFetchTwilioData(this.currentPage, sort.length > 0 ? sort : defaultSort, filter);
  }

  /**
   * Adapts Listing Data
   * @param {array} data Listing
   * @returns {array} Listing
   */
  dataAdapter = (data = []) => {
    const adaptedData = [];

    data.forEach((value, index) => {
      adaptedData.push({
        key: index,
        countryName: value.name,
        dialingCode: value.dial_code,
        format: value.format,
        isoCode: value.flag_code,
        actions: {
          id: value.id,
        },
      });
    });
    return adaptedData;
  }

  /**
   * Renders Table Cells
   * @param {object} content Content Data
   * @param {string} key Content Key e.g. Organization
   * @return {JSX} Cell
   */
  cellRenderer(content, key) {
    let cell;
    const { loggedUser, data, deleteTwilio } = this.props;

    switch (key) {
      case 'actions':
        cell = (
          <ActionsCell
            page={APP_PAGES_CONTEXT.programs}
            loggedUser={loggedUser}
            content={content}
            data={data}
            editLink={`/system/settings/system/twilio/edit/${content.id}`}
            deleteAction={() => {
              deleteTwilio({
                id: content.id,
                page: this.currentPage,
                sort: this.sort,
                filter: this.filter,
              });
            }}
          />
        );
        break;
      default:
        cell = content;
    }

    return cell;
  }

  /**
   * Render Add Button
  * @returns {JSX} component
  */
  renderAddButton = () => (
    <Link
      to="/system/settings/system/twilio/new"
      shape="circle"
      icon="+"
      className="page-icons page-icon-plus"
    >
      <i className="icon icon-add" />
      <span><IntlMessages id="common.addText" /></span>
    </Link>
  )

  /**
 * Listings page
 * @returns {JSX} Twilio Listing page
 */
  render() {
    const { pagination, data, loading } = this.props;
    const { showMore } = this.state;
    pagination.onChange = this.onPageChange;

    return (
      <div>
        <div className="uhe-table-header">
          <div className="buttons-container">
            {this.renderAddButton()}
          </div>
          <p>
            <IntlMessages id="uhe.table.matchingResults" />
            <span>{pagination.total}</span>
          </p>
        </div>
        <Card className="filter-boxes gx-card">
          <Table
            bordered
            loading={loading}
            className="gx-table-responsive"
            dataSource={this.dataAdapter(data)}
            columns={this.columns}
            pagination={false}
            footer={() => (
              <div className="table-footer">
                <Button type="text" onClick={() => this.onPageChange(showMore)}>
                  <IntlMessages id="common.showMore" />
                  <DownOutlined />
                </Button>
              </div>
            )}
          />
        </Card>
      </div>
    );
  }
}

Twilio.defaultProps = {
  shouldResetPaginationFlag: false,
  lastLocation: {
    pathname: '/',
    search: '',
    hash: '',
    state: undefined,
    key: '',
  },
};

Twilio.propTypes = {
  pagination: PropTypes.shape().isRequired,
  data: PropTypes.shape().isRequired,
  loggedUser: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  onFetchTwilioData: PropTypes.func.isRequired,
  deleteTwilio: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  subtitle: PropTypes.shape().isRequired,
  setSubtitleAction: PropTypes.func.isRequired,
  lastLocation: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: undefined,
    key: PropTypes.string,
  }),
  shouldResetPaginationFlag: PropTypes.bool,
  resetPaginationSuccess: PropTypes.func.isRequired,
};

/**
 * Maps State to Props
 * @returns {object} Object with States
 */
const mapStateToProps = ({ SystemSettingsSystem, subtitle, common }) => ({
  loading: SystemSettingsSystem.loading,
  pagination: {
    total: SystemSettingsSystem?.table?.page?.totalElements || 0,
    current: SystemSettingsSystem?.table?.page?.number + 1 || 0,
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  },
  data: SystemSettingsSystem.table.list,
  loggedUser: SystemSettingsSystem.ownUser,
  subtitle,
  shouldResetPaginationFlag: common.shouldResetPagination,
});

/**
 * Maps Actions to Props
 * @param {function} dispatch Dispatches Action to Props
 * @returns {object} Object with Actions
 */
const mapDispatchToProps = (dispatch) => ({
  onFetchTwilioData:
    (page, sort, filter) => dispatch(onFetchTwilioData(page, sort, filter)),
  deleteTwilio: (payload) => dispatch(deleteTwilio(payload)),
  setSubtitleAction: (langId) => dispatch(setSubtitle(langId)),
  resetPaginationSuccess: () => dispatch(shouldResetPaginationSuccess()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withLastLocation(withRouter(Twilio))));
