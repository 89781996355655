import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Button, Table } from 'antd';
import lodash from 'lodash';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { injectIntl } from 'react-intl';
import IntlMessages from 'util/IntlMessages';

import ListingsTableInputFilter from '@filters/ListingsTableInputFilter';
import OrganizationCell from '@components/tables/cells/OrganizationCell';
import ActionsCell from '@components/tables/cells/ActionsCell';
import NumberCell from '@components/tables/cells/NumberCell';
import ExportCsv from '@components/ExportCsv/ExportCsv';
import { organizationsOnFetchData, deleteOrganization } from '@uhe_actions/configuration/organizations/OrganizationsActions';
import {
  LISTING_TABLES_PAGE_SIZE,
  TABLE_FILTER_PREFIX,
  APP_PAGES_CONTEXT, IS_EDIT_PAGE_REGEX,
} from '@constants/UHESettings';
import UheHelper, { getCurrentSort } from 'util/UheHelper';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import { withLastLocation } from 'react-router-last-location';
import { shouldResetPaginationSuccess } from '@actions';
import { DownOutlined } from '@ant-design/icons';

/**
 * @description Renders Organization table
 */
class Organizations extends Component {
  /**
   * @description Adapts the data from the API
   * @param {Array} data
   * @returns {Array}
   */
  static dataAdapter(data = []) {
    const adaptedData = [];

    data.forEach((value, index) => {
      adaptedData.push({
        key: index,
        organization: {
          id: value.id,
          name: value.name,
        },
        actions: {
          id: value.id,
        },
        customerNo: value.total_customers,
        clinicians: value.no_clinicians,
        numberOfBedsCarts: value.total_devices + value.total_mobile_users,
        advancedReports: value.status === 1 ? <IntlMessages id="common.on" /> : <IntlMessages id="common.off" />,
        configuredApsNo: value.total_configured_endpoints,
        sbtDevicesNo: value.total_endpoints_sbt,
        byodNo: value.total_mobile_users,
      });
    });

    return adaptedData;
  }

  constructor(props) {
    super(props);

    this.onPageChange = this.onPageChange.bind(this);
    const {
      intl,
      subtitle,
      history,
      setSubtitle,
      pagination,
    } = this.props;

    this.data = [];
    this.columns = [];
    this.filterTypes = {
      advancedReports: {
        type: 'dropdown',
        options: [
          { value: '1', label: intl.formatMessage({ id: 'common.on' }) },
          { value: '0', label: intl.formatMessage({ id: 'common.off' }) },
        ],
      },
    };

    this.tableFilterMap = {
      organization: 'name',
      customerNo: 'total_customers',
      clinicians: 'no_clinicians',
      numberOfBedsCarts: 'total_devices',
      advancedReports: 'status',
    };

    this.tableKeys = [
      'organization',
      'customerNo',
      'numberOfBedsCarts',
      'configuredApsNo',
      'sbtDevicesNo',
      'byodNo',
      'actions',
    ];

    this.tableKeys.forEach((value, index) => {
      const filter = this.filterTypes[value] || {};
      this.columns.push({
        title: (cellData) => (
          <ListingsTableInputFilter
            filterType={filter.type}
            filterOptions={filter.options}
            showFilter={value === 'organization'}
            cellData={cellData}
            title={`uhe.table.${value}`}
            dataKey={value}
            triggerCharsNum={value === 'customerNo' || value === 'clinicians' || value === 'numberOfBedsCarts' ? 0 : undefined}
          />
        ),
        sorter: (value === 'actions'
          || value === 'customerNo'
          || value === 'numberOfBedsCarts'
          || value === 'iConsultNo'
          || value === 'iObserverNo'
          || value === 'configuredApsNo'
          || value === 'sbtDevicesNo'
          || value === 'byodNo')
          ? false : { multiple: index },
        defaultSortOrder: (value) ? this.checkSortTableOrder() : false,
        align: index > 3 ? 'center' : 'left',
        minWidth: 200,
        dataIndex: value,
        render: (content) => this.cellRenderer(content, value),
      });
    });

    if (
      subtitle
      && subtitle.langId !== 'configuration.organizations.title'
    ) {
      setSubtitle('configuration.organizations.title');
    }

    this.history = history;
    this.qParams = new URLSearchParams(this.history.location.search);
    this.defaultSorted = true;
    this.state = {
      showMore: 2,
    };
  }

  /**
   * componentDidMount() is invoked immediately after
   * a component is mounted (inserted into the tree)
   * @returns {void} void
   */
  componentDidMount() {
    const {
      lastLocation,
      pagination,
      shouldResetPaginationFlag,
      resetPaginationSuccess,
    } = this.props;

    if (!IS_EDIT_PAGE_REGEX.test(lastLocation?.pathname) || shouldResetPaginationFlag) {
      this.onPageChange(1);
      resetPaginationSuccess();
      return;
    }

    this.onPageChange(pagination?.current || 1);
  }

  /**
   * Updates table on location change
   * @param {Object} prevProps previous props
   * @returns {void} void
   */
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.search !== prevProps.location.search) {
      this.qParams = new URLSearchParams(location.search);
      this.onPageChange(1);
    }
  }

  /**
   * @param  {number} page
   * @return {void}
   */
  onPageChange(page) {
    const { organizationsOnFetchData } = this.props;

    this.currentPage = page - 1;
    const currSort = this.qParams.getAll('sort') || [];
    const filter = [];

    lodash.forOwn(this.tableFilterMap, (value, key) => {
      const filterParam = this.qParams.get(`${TABLE_FILTER_PREFIX}${key}`);

      if (filterParam) {
        if (this.filterTypes[key] && this.filterTypes[key].type === 'dropdown') {
          filter.push(`${value}=${encodeURIComponent(filterParam)}`);
        } else {
          filter.push(`${value}~=${encodeURIComponent(`%${filterParam}%`)}`);
        }
      }
    });

    let sort = getCurrentSort(currSort, this.tableFilterMap);

    if (this.defaultSorted && currSort.length === 0) {
      sort = ['name,asc'];
    } else {
      this.defaultSorted = false;
    }

    this.filter = filter;
    this.sort = sort;

    this.setState({ showMore: page + 1 });
    organizationsOnFetchData(page - 1, sort, filter);
  }

  /**
   * @description Check the default sort order for table
   * @returns {string}
   */
  checkSortTableOrder() {
    const { location } = this.props;
    let checkOrder = location.search.split('%2C')[1];

    if (checkOrder === 'asc') {
      checkOrder = 'ascend';
    }
    if (checkOrder === 'desc') {
      checkOrder = 'descend';
    }
    if (!checkOrder) {
      checkOrder = false;
    }

    return checkOrder;
  }

  /**
   * @param {string} content
   * @param {string} key
   * @returns {JSX}
   */
  cellRenderer(content, key) {
    let cell;
    const { intl, loggedUser } = this.props;
    switch (key) {
      case 'organization':
        cell = <OrganizationCell content={content} />;
        break;
      case 'actions':
        cell = (
          <ActionsCell
            page={APP_PAGES_CONTEXT.organizations}
            loggedUser={loggedUser}
            intl={intl}
            content={content}
            editLink={`/configuration/organizations/edit/${content.id}`}
            deleteAction={() => {
              this.props.deleteOrganization(
                content.id,
                this.currentPage,
                this.sort,
                this.filter,
              );
            }}
          />
        );
        break;

      default:
        cell = <NumberCell content={content} />;
    }

    return cell;
  }

  /**
  * @description Render Add Button
  * @returns {JSX}
  */
  renderAddButton = () => {
    const { isCaregilitySystemAdmin } = this.props.loggedUser;

    if (isCaregilitySystemAdmin) {
      return (
        <Link
          to="/configuration/organizations/new"
          shape="circle"
          icon="+"
          className="page-icons page-icon-plus"
        >
          <i className="icon icon-add" />
          <span><IntlMessages id="common.addText" /></span>
        </Link>
      );
    }

    return null;
  }

  /**
  * @description Render Export Csv Button
  * @returns {JSX}
  */
  renderExportCsvButton = () => {
    const { isCaregilitySystemAdmin, isCaregilityAdmin } = this.props.loggedUser;

    if (isCaregilitySystemAdmin || isCaregilityAdmin) {
      return (
        <ExportCsv
          getCsvUrl={UheHelper.getCsvUrl(
            this.filter, this.sorting, ENDPOINTS.ExportCsv.organizations,
          )}
        />
      );
    }

    return null;
  }

  /**
  * Render table
  * @returns {JSX} table
  */
  renderTable = () => {
    const {
      pagination, data, loading, loggedUser,
    } = this.props;
    const { showMore } = this.state;
    pagination.onChange = this.onPageChange;

    if (loggedUser.roles) {
      if (loggedUser.isCaregilitySystemAdmin) {
        if (this.columns.length <= 4) {
          const foundActions = this.tableKeys.filter((key) => key === 'actions');

          if (foundActions.length === 0) {
            this.tableKeys.push('actions');
          }

          this.tableKeys.filter((key) => key === 'actions').forEach((value, index) => {
            const filter = this.filterTypes[value] || {};
            this.columns.push({
              title: (cellData) => (
                <ListingsTableInputFilter
                  filterType={filter.type}
                  filterOptions={filter.options}
                  showFilter={!(value === 'actions' || value === 'customerNo' || value === 'clinicians' || value === 'numberOfBedsCarts')}
                  cellData={cellData}
                  title={`uhe.table.${value}`}
                  dataKey={value}
                  triggerCharsNum={value === 'customerNo' || value === 'clinicians' || value === 'numberOfBedsCarts' ? 0 : undefined}
                />
              ),
              sorter: (value === 'actions') ? false : { multiple: index },
              defaultSortOrder: (value) ? this.checkSortTableOrder() : false,
              align: index > 3 ? 'center' : 'left',
              minWidth: 200,
              dataIndex: value,
              render: (content) => this.cellRenderer(content, value),
            });
          });
        }
      }

      return (
        <Table
          bordered
          className="gx-table-responsive custom-scrollbar"
          columns={this.columns}
          dataSource={Organizations.dataAdapter(data)}
          pagination={false}
          loading={loading}
          footer={() => (
            <div className="table-footer">
              <Button type="text" onClick={() => this.onPageChange(showMore)}>
                <IntlMessages id="common.showMore" />
                <DownOutlined />
              </Button>
            </div>
          )}
        />
      );
    }
  }

  render() {
    const { pagination, data, loading } = this.props;
    pagination.onChange = this.onPageChange;

    return (
      <div className="organizations">
        <div className="uhe-table-header">
          <div className="buttons-container">
            {this.renderAddButton()}
            {this.renderExportCsvButton()}
          </div>
          <p>
            <IntlMessages id="uhe.table.matchingResults" />
            <span>{pagination.total}</span>
          </p>
        </div>
        <div className="uhe-table">
          {this.renderTable()}
        </div>
      </div>
    );
  }
}

Organizations.defaultProps = {
  lastLocation: {
    pathname: '/',
    search: '',
    hash: '',
    state: undefined,
    key: '',
  },
  shouldResetPaginationFlag: false,
};

Organizations.propTypes = {
  data: PropTypes.array,
  intl: PropTypes.object,
  subtitle: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  pagination: PropTypes.object,
  setSubtitle: PropTypes.func,
  loading: PropTypes.bool,
  lastLocation: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: undefined,
    key: PropTypes.string,
  }),
  shouldResetPaginationFlag: PropTypes.bool,
  resetPaginationSuccess: PropTypes.func.isRequired,
};

/**
 * mapStateToProps function
 * @param {Object} ConfigurationUnits object
 * @param {Object} subtitle object
 * @param {Object} ConfigurationUsers object
 * @param {Object} common object
 * @returns {Object} object
 */
const mapStateToProps = ({
  ConfigurationOrganizations,
  subtitle,
  ConfigurationUsers,
  common,
}) => {
  const { list, page } = ConfigurationOrganizations.table || {};
  const { loading } = ConfigurationOrganizations;
  const { shouldResetPagination } = common;

  const pagination = {
    total: page.totalElements || 0,
    current: page.number + 1 || 0,
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  };

  return {
    data: list,
    pagination,
    loading,
    subtitle,
    loggedUser: ConfigurationUsers.ownUser,
    shouldResetPaginationFlag: shouldResetPagination,
  };
};

/**
 * mapDispatchToProps function
 * @param {Function} dispatch dispatch
 * @returns {Object} object
 */
const mapDispatchToProps = (dispatch) => ({
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  deleteOrganization: (id, page, sort, filter, size) => dispatch(deleteOrganization(
    id, page, sort, filter, size,
  )),
  organizationsOnFetchData: (page, sort, filter) => dispatch(organizationsOnFetchData(
    page, sort, filter,
  )),
  resetPaginationSuccess: () => dispatch(shouldResetPaginationSuccess()),
});

export default
connect(
  mapStateToProps, mapDispatchToProps,
)(injectIntl(withLastLocation(withRouter(Organizations))));
