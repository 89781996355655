import React from 'react';
import {
  Popover,
} from 'antd';

const IsLiveCell = ({ content }) => {
  return (
    <div className="uhe-table-td">
      <Popover content={content}>
        <span className="maint-mode">
          {!!content && <i className="icon icon-data-display" />}
        </span>
      </Popover>
    </div>
  );
};

export default IsLiveCell;