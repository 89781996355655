import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import { Card, Table, Button } from 'antd';

import ListingsTopFilter from '@filters/ListingsTopFilter';
import ListingsTableInputFilter from '@filters/ListingsTableInputFilter';
import OrganizationCell from '@components/tables/cells/OrganizationCell';
import CustomerCell from '@components/tables/cells/CustomerCell';
import AddressCell from '@components/tables/cells/AddressCell';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import { fetchSignInReports } from '@uhe_actions/reports/SignInReportsActions';
import {
  LISTING_TABLES_PAGE_SIZE,
  TOP_FILTER_PREFIX,
  TABLE_FILTER_PREFIX,
} from '@constants/UHESettings';
import { getCurrentSort } from '@util/UheHelper';
import { DownOutlined } from '@ant-design/icons';

/**
 * Renders SignInReport component
 */
class SignInReport extends Component {
  /**
   * Adapt data returned from the server
   * @param  {Array<Object>} data Data
   * @return {Array<Object>} returns adapated data
   */
  static dataAdapter(data = []) {
    const adaptedData = [];

    data.forEach((value, index) => {
      adaptedData.push({
        key: index,
        name: value.customer.name,
        email: value.email,
        organization: {
          name: value.customer.organization.name,
          id: value.customer.organization.id,
        },
        customer: {
          id: value.customer.id,
          organizationId: value.customer.organization.id,
          name: value.customers,
        },
        signInCount: value.sign_in_count,
        callsCount: value.call_amount,
      });
    });

    return adaptedData;
  }

  /**
   * Use different cell type depending on the column
   * @param {Object} content content
   * @param {string} key Key
   * @return {ReactElement|Object} cell renderer
   */
  static cellRenderer(content, key) {
    let cell;

    switch (key) {
      case 'organization':
        cell = <OrganizationCell content={content} />;
        break;
      case 'customer':
        cell = <CustomerCell content={content} />;
        break;
      case 'email':
        cell = <AddressCell content={content} />;
        break;

      default:
        cell = content;
    }

    return cell;
  }

  /**
   * SignInReport constructor
   * @param {Object} props Props
   */
  constructor(props) {
    super(props);

    this.data = [];
    this.columns = [];
    this.tableKeys = [
      'name',
      'email',
      'organization',
      'customer',
      'signInCount',
      'callsCount',
    ];

    this.tableFilterMap = {
      name: 'customer.name',
      organization: 'customer.organization.name',
      email: 'email',
      customer: 'customers',
      firstName: 'first_name',
      lastName: 'last_name',
    };

    this.topFilterMap = {
      [`${TOP_FILTER_PREFIX}organization`]: 'customer.organization.id',
      [`${TOP_FILTER_PREFIX}customer`]: 'customer.id',
    };

    this.topFilters = [
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byOrganization',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'organization',
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byCustomer',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'customer',
      },
    ];

    this.filterTypes = {};
    this.tableKeys.forEach((value, index) => {
      const filter = this.filterTypes[value] || {};
      this.columns.push({
        title: (cellData) => (
          <ListingsTableInputFilter
            filterType={filter.type}
            filterOptions={filter.options}
            cellData={cellData}
            title={`uhe.table.${value}`}
            dataKey={value}
            showFilter={!(value === 'signInCount' || value === 'callsCount' || value === 'customer')}
          />
        ),
        sorter: true,
        align: index > 3 ? 'center' : 'left',
        minWidth: 200,
        dataIndex: value,
        render: (content) => SignInReport.cellRenderer(content, value),
      });
    });
    const { onSetSubtitle, history } = this.props;

    onSetSubtitle('signInReport.title');

    this.history = history;
    this.qParams = new URLSearchParams(this.history.location.search);
    this.defaultSorted = true;

    this.onPageChange = this.onPageChange.bind(this);
    this.onPageChange(1);

    this.state = {
      showMore: 2,
    };
  }

  /**
   * Updates the component on location change
   * @param {Object} prevProps data
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.search !== prevProps.location.search) {
      this.qParams = new URLSearchParams(location.search);
      this.onPageChange(this.currentPage + 1);
    }
  }

  /**
   * Change data after filtering
   * @param {number} page Page
   * @return {void}
   */
  onPageChange(page) {
    const { SignInReports } = this.props;
    this.currentPage = page - 1;
    const currSort = this.qParams.getAll('sort') || [];
    const filter = [];

    lodash.forOwn(this.topFilterMap, (value, key) => {
      const filterParam = this.qParams.get(key);
      if (filterParam) {
        filter.push(`${value}=${filterParam}`);
      }
    });

    lodash.forOwn(this.tableFilterMap, (value, key) => {
      const filterParam = this.qParams.get(`${TABLE_FILTER_PREFIX}${key}`);

      if (filterParam) {
        if (
          this.filterTypes[key]
          && this.filterTypes[key].type === 'dropdown'
        ) {
          filter.push(`${value}=${encodeURIComponent(filterParam)}`);
        } else {
          filter.push(`${value}~=${encodeURIComponent(`%${filterParam}%`)}`);
        }
      }
    });

    let sort = getCurrentSort(currSort, this.tableFilterMap);

    if (this.defaultSorted && currSort.length === 0) {
      sort = ['customer.name,asc', 'email,asc', 'customer.organization.name,asc', 'customers,asc'];
    } else {
      this.defaultSorted = false;
    }

    this.filter = filter;
    this.sort = sort;

    this.setState({ showMore: page + 1 });

    SignInReports(page - 1, sort, filter);
  }

  /**
    * Renders SingInreport Component
    * @returns {JSX.Element} Uhe Component
  */
  render() {
    const { loading, data, pagination } = this.props;
    const { showMore } = this.state;

    pagination.onChange = this.onPageChange;

    return (
      <div className="dashboard configuration">
        <div className="customers-filter-box">
          <div className="beds-carts">
            <Card
              className="filter-boxes gx-card"
              title={<IntlMessages id="uhe.title.filters" />}
            >
              <ListingsTopFilter filters={this.topFilters} />
            </Card>
            <div className="uhe-table-header sign-in-table-header">
              <p>
                <IntlMessages id="uhe.table.matchingResults" />
                <span>{pagination.total}</span>
              </p>
            </div>
            <div className="uhe-table">
              <Table
                bordered
                className="gx-table-responsive sign-in-table"
                columns={this.columns}
                dataSource={SignInReport.dataAdapter(data)}
                pagination={false}
                loading={loading}
                footer={() => (
                  <div className="table-footer">
                    <Button type="text" onClick={() => this.onPageChange(showMore)}>
                      <IntlMessages id="common.showMore" />
                      <DownOutlined />
                    </Button>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SignInReport.defaultProps = {
  data: [],
  loading: true,
  pagination: {
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  },
  location: {},
  history: {},
};

SignInReport.propTypes = {
  data: PropTypes.array,
  SignInReports: PropTypes.func,
  onSetSubtitle: PropTypes.func,
  pagination: PropTypes.shape(),
  loading: PropTypes.bool,
  location: PropTypes.shape(),
  history: PropTypes.shape(),
};

/**
 * Map Global State to Props
 * @param {Object} SignInReports siginreports
 * @param {Object} subtitle subtitle
 * @return {Object} data
 */
const mapStateToProps = ({ SignInReports, subtitle }) => {
  const { list, page } = SignInReports.table || { page: {}, list: [] };
  const { loading } = SignInReports;
  const { signInData } = SignInReports;
  const pagination = {
    total: page.totalElements || 0,
    current: page.number + 1 || 0,
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  };

  return {
    data: list, pagination, loading, subtitle, signInData,
  };
};

/**
 * Map Dispatch to Props
 * @param {function} dispatch Dispatch
 * @return {Object} data
 */
const mapDispatchToProps = (dispatch) => ({
  onSetSubtitle: (langId) => dispatch(setSubtitle(langId)),
  SignInReports: (page, sort, filter) => dispatch(fetchSignInReports(page, sort, filter)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SignInReport));
