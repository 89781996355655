import {
  FETCH_ERROR, FETCH_START, FETCH_SUCCESS, HIDE_MESSAGE, SHOW_MESSAGE,
} from '@constants/ActionTypes';
import {
  SHOULD_RESET_PAGINATION,
  SHOULD_RESET_PAGINATION_SUCCESS,
} from '@constants/UHEActionTypes';

/**
 * action fetchStart
 * @returns {Object} object
 */
export const fetchStart = () => ({
  type: FETCH_START,
});

/**
 * action fetchSuccess
 * @returns {Object} object
 */
export const fetchSuccess = () => ({
  type: FETCH_SUCCESS,
});

/**
 * action fetchError
 * @param {string} error error
 * @returns {Object} object
 */
export const fetchError = (error) => ({
  type: FETCH_ERROR,
  payload: error,
});

/**
 * action fetchError
 * @param {string} message message
 * @returns {Object} object
 */
export const showMessage = (message) => ({
  type: SHOW_MESSAGE,
  payload: message,
});

/**
 * action hideMessage
 * @returns {Object} object
 */
export const hideMessage = () => ({
  type: HIDE_MESSAGE,
});

/**
 * When triggered the pagination should reset.
 * @returns {Object} object
 */
export const shouldResetPagination = () => ({
  type: SHOULD_RESET_PAGINATION,
});

/**
 * When triggered the page has already been reset
 * @returns {Object} object
 */
export const shouldResetPaginationSuccess = () => ({
  type: SHOULD_RESET_PAGINATION_SUCCESS,
});
