import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

import RestManager from '@util/RestManager';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { fetchError, showMessage } from '@actions/Common';
import { MACHINE_INFO_REQUEST, SEND_ADVANCED_DEV_COMMAND } from '@constants/UHEActionTypes';
import { getMachineInfoSuccess } from '@uhe_actions/system/AdvancedDevActions';

/**
 * Machine Info Listing Request
 * @param {number} page Page Number
 * @param {string} sorting Sorting String
 * @param {string} filter Filter String
 * @returns {Promise<*|{}>} Response
 */
const machineInfoRequest = async ({ page, sorting, filter }) => {
  const filterQueryString = filter?.length ? `&${filter.join('&')}` : '';
  const sortingQueryString = sorting?.length ? `&sort=${sorting.join('&sort=')}` : '';
  const request = await RestManager.request(`${ENDPOINTS.advancedDev.machineInfo}?page=${page || 0}${sortingQueryString}${filterQueryString}`);

  return request;
};

/**
 * Send to Patient Device|Bulk Patient Device Request
 * @param {string} url Request URL
 * @param {object} body Request Body
 * @returns {Promise<*|{}>} Response
 */
const sendAdvancedDevCommandRequest = async (url, body) => {
  const request = await RestManager.requestWithoutQueryParams(url, 'POST', body);

  return request;
};

/**
 * patientDeviceCommandSaga Saga Worker
 * @param {object} data Body Data
 * @returns {void}
 */
function* patientDeviceCommandSaga(data) {
  try {
    const response = yield call(sendAdvancedDevCommandRequest, data.payload.url, data.payload.body);

    if (response) {
      yield put(showMessage('save_success'));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * machineInfoSaga Saga Worker
 * @param {number} page Page Number
 * @param {string} sorting Sorting String
 * @param {string} filter Filter String
 * @returns {void}
 */
function* machineInfoSaga(page, sorting, filter) {
  try {
    const response = yield call(machineInfoRequest, page, sorting, filter);

    if (response) {
      yield put(getMachineInfoSuccess(response));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Saga Watcher
 * @returns {void}
 */
export function* actionsWatcher() {
  yield takeEvery(MACHINE_INFO_REQUEST, machineInfoSaga);
  yield takeEvery(SEND_ADVANCED_DEV_COMMAND, patientDeviceCommandSaga);
}

/**
 * Root Saga
 * @returns {void}
 */
export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
