import React from 'react';
import { Col, Row } from 'antd';
import IntlMessages from 'util/IntlMessages';

export const AuditTypes = Object.freeze({
  createdAt: 1,
  updatedAt: 2,
});

/**
 * @description Component which works with audit info from b/e (updated_at, updated_by, created_at)
 * @param {number} type
 * @param data
 * @param className For styling nested components
 * @returns {JSX.Element}
 * @constructor
 */
export default function AuditInfo({ type, data, className }) {
  const auditDate = new Date();
  const dateInMilliseconds = data[type === AuditTypes.updatedAt ? 'updated_at' : 'created_at']
    || data[type === AuditTypes.updatedAt ? 'updatedAt' : 'createdAt'];
  if (!dateInMilliseconds) {
    return <></>;
  }
  auditDate.setTime(dateInMilliseconds);
  return (
    <Row gutter={16} className={className}>
      <Col>
        <IntlMessages id={`configuration.auditInfo.types.${type}`} />
      </Col>
      <Col>
        <IntlMessages id="configuration.auditInfo.date" />
        {auditDate.toLocaleDateString()}
      </Col>
      <Col>
        <IntlMessages id="configuration.auditInfo.time" />
        {auditDate.toLocaleTimeString()}
      </Col>
      {type === AuditTypes.updatedAt && (data.updated_by || data.updatedBy)
        ? (
          <Col>
            <IntlMessages id="configuration.auditInfo.by" />
            {data.updated_by || data.updatedBy}
          </Col>
        )
        : <></>}
    </Row>
  );
}

AuditInfo.types = AuditTypes;
