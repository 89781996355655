import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import {
  Card, Form, Row, Col, Input, Button, Popover,
} from 'antd';

import {
  fetchBedCart,
  fetchDeviceTypes,
  clearBedCart,
  saveUheBedCart,
} from '@uhe_actions/configuration/bedsCarts/bedsCartsActions';
import EditHeader from '@components/uhe/configuration/EditHeader';
import DeviceTypeCard from '@components/uhe/configuration/bedsCarts/DeviceTypeCard';
import ActiveComponentsCard from '@components/uhe/configuration/bedsCarts/ActiveComponentsCard';
import { FORM_LAYOUT, HOSTNAME_REGEX } from '@constants/UHESettings';
import { goBackTo } from '@util/UheHelper';
import { withLastLocation } from 'react-router-last-location';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import PropTypes from 'prop-types';
import { shouldShowEditHeader, canDoAction, shouldShowCiscoDeviceCard } from '@util/UheRoleChecker';
import { injectIntl } from 'react-intl';
import RestManager from '@util/RestManager';
import { ENDPOINTS } from '@constants/UHEEndpoints';

/**
 * ManageNonUheBedCart Class Component
 */
class ManageNonUheBedCart extends Component {
  formRef = React.createRef();

  /**
   * BulkActions Constructor
   * @param {object} props Props
  /**
   * ManageNonUheBedCart Constructor
   * @param {object} props ManageNonUheBedCart Constructor Props
   * @returns {void}
   */
  constructor(props) {
    super(props);
    const { intl } = this.props;
    this.formRef = React.createRef();

    this.state = {
      bedCart: {
        components: [],
        name: '',
      },
      verifiedHostname: '',
      deviceTypes: [],
      connection: {},
    };

    this.saveBedCart = this.saveBedCart.bind(this);
    this.onChangeDeviceType = this.onChangeDeviceType.bind(this);
    this.onChangeCallFormat = this.onChangeCallFormat.bind(this);
    this.onChangeAddress = this.onChangeAddress.bind(this);
    this.onChangeRoomBed = this.onChangeRoomBed.bind(this);
    this.getClickableLink = this.getClickableLink.bind(this);
    this.intl = intl;
  }

  /**
   * componentDidMount() is invoked immediately after
   * a component is mounted (inserted into the tree)
   * @returns {void} void
   */
  componentDidMount() {
    const {
      clearBedCart,
      fetchDeviceTypes,
      match,
      location,
      fetchBedCart,
      setSubtitle,
    } = this.props;
    const urlId = match.params.id || '';

    if (urlId) {
      clearBedCart();
      fetchDeviceTypes();

      if (!location.state) {
        fetchBedCart({ id: urlId });
      }
    }

    if (location.state) {
      const uheBedCart = location.state.uheDevice;
      uheBedCart.endpoint_type = location.state.selectedDevice;
      this.setState({ bedCart: uheBedCart });
    }

    setSubtitle('nonCareDevices.titles.edit');
  }

  /**
   * Updates the component on location change
   * @param {Object} prevProps object
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const { bedCart } = this.state;
    const { error, history, deviceTypes } = this.props;
    if (prevProps.error !== error) {
      // handle system error
      if (error.code === 404) {
        history.push('/configuration/beds-carts/non-uhe-units');
        return;
      }
    }

    if (
      Object.keys(prevProps.bedCart).length === 0
      && Object.keys(this.props.bedCart).length > 0
    ) {
      this.setState({
        bedCart: this.props.bedCart,
      });
      this.formRef.current.setFieldsValue({
        name: this.props.bedCart.name,
      });
    }

    if (
      prevProps.deviceTypes.length === 0
      && this.props.deviceTypes.length > 0
    ) {
      this.setState({
        deviceTypes,
      });
    }

    /**
      * Validate Fields
    */
    this.formRef.current.setFieldsValue({
      alias: bedCart.alias,
      endpoint_type: [bedCart.endpoint_type],
      cisco_hostname: bedCart.cisco_hostname,
      cisco_username: bedCart.cisco_username,
      cisco_password: bedCart.cisco_password,
    });
  }

  /**
   * Handle Changes on Device Types Dropdown and Updates Local State
   * @param {array} device device
   * @returns {void}
   */
  onChangeDeviceType(device) {
    const { bedCart } = this.state;
    const { history, match } = this.props;

    bedCart.endpoint_type = device[0];
    if (device[0] !== 'Standards Based') {
      history.push(
        `/configuration/beds-carts/uhe-units/edit/${match.params.id}`,
        { nonUheDevice: bedCart, selectedDevice: device[0] },
      );
      return;
    }
    this.setState({ bedCart });
  }

  /**
   * Handle Changes on the Call Format Dropdown and Updates Local State
   * @param {Object} format option
   * @param {string} key value
   * @returns {void}
   */
  onChangeCallFormat(format, key) {
    const { bedCart } = this.state;
    const newState = { ...bedCart, [key]: format.props.children };
    this.setState({
      bedCart: newState,
    });
  }

  /**
   * Handle Changes on Sip Address Input Field and Updates Local State
   * @param {Object} event in input field
   * @param {string} key property
   * @returns {void}
   */
  onChangeAddress(event, key) {
    const { bedCart } = this.state;
    const newState = { ...bedCart, [key]: event.target.value };

    this.setState({
      bedCart: newState,
    });
  }

  /**
   * Handles Changes on Room/Bed Input
   * @param {Object} event in input field
   * @returns {void}
   */
  onChangeRoomBed(event) {
    const { bedCart } = this.state;
    const newState = { ...bedCart, name: event.target.value };
    this.setState({
      bedCart: newState,
    });
  }

  /**
   * Event in input field
   * @param {Object} event in input field
   * @param {String} key property
   * @returns {String} input value
   */
  onChangeHandlerCisco(event, key) {
    const { bedCart } = this.state;
    const newState = { ...bedCart, [key]: event.target.value };
    this.setState({
      bedCart: newState,
    });
  }

  /*
  * Return HTTP Or HTTPS URL Link
   * @param {string} link URL Link
   * @returns {string} HTTP/HTTPS URL
   */
  getClickableLink = (link) => (link?.startsWith('http://') || link?.startsWith('https://')
    ? link
    : `http://${link}`)

  /**
   * Verify cisco device connectivity
   * @param {Object} event in input field
   * @returns {void}
   */
  verifyConnectivity = async (event) => {
    event.persist();
    event.preventDefault();
    const { match } = this.props;
    const { bedCart } = this.state;
    try {
      const res = await RestManager.requestWithoutQueryParams(
        `${ENDPOINTS.monitoring.UHETable}/${match.params.id}/verify_connection`,
        'POST',
        {
          user_name: bedCart.cisco_username,
          host_name: bedCart.cisco_hostname,
          password: bedCart.cisco_password,
        },
      );
      if (res) {
        this.setState({
          connection: res,
          verifiedHostname: bedCart.cisco_hostname,
        });
      }
    } catch (info) {
      console.log('info:', info);
    }
  }

  /**
 * Sends POST Request on Save Button click w/ the state as body
 * @param {Object} event to save
 * @returns {void}
 */
  saveBedCart(event) {
    event.persist();
    event.preventDefault();
    const { saveNonUheBedCartEdit, match } = this.props;
    this.formRef.current.validateFields()
      .then(() => {
        const { bedCart } = this.state;
        saveNonUheBedCartEdit({
          body: bedCart,
          id: match.params.id,
        });
      })
      .catch((info) => {
        console.log('info:', info);
      });
  }

  /**
 * Render title, back and save buttons
 * @returns {JSX} headline
 */
  renderHeadLine() {
    const { loading, lastLocation, history } = this.props;

    if (shouldShowEditHeader()) {
      return (
        <EditHeader
          goBack={goBackTo('/configuration/beds-carts/non-uhe-units', lastLocation?.pathname, history)}
          save={this.saveBedCart}
          loading={loading}
        />
      );
    }

    return null;
  }

  /**
   * Return HTTP Or HTTPS URL Link
   * @param {string} link URL Link
   * @returns {string} HTTP/HTTPS URL
   */
  getClickableLink(link) {
    return link?.startsWith('http://') || link?.startsWith('https://')
      ? link
      : `http://${link}`;
  }

  /**
   * Show status connectivity icon
   * @param {String} statusFigure icon
   * @returns {JSX} render JSX
   */
  renderConnectivityIcon(statusFigure) {
    const { connection, bedCart, verifiedHostname } = this.state;
    if (connection && connection.device_access === 1 && connection.status_access === 1) {
      statusFigure = 'icon-uncheck-squire';
      return (
        <>
          <div className="margin-right-10 show-IP">
            {verifiedHostname}
            :
          </div>
          <Popover content={<IntlMessages id="common.connectionSuccess" />}>
            <i className={`icon ${statusFigure} connectivity-icon-success connectivity-icon`} />
          </Popover>
        </>
      );
    } if (connection && connection.device_access === 1 && connection.status_access === 0) {
      statusFigure = 'icon-circle';
      return (
        <div className="verify-IP">
          <div className="margin-right-10 show-IP">
            {verifiedHostname}
            :
          </div>
          <Popover content={<IntlMessages id="common.connectionHostFail" />}>
            <i className={`icon ${statusFigure} connectivity-icon-fail connectivity-icon`} />
          </Popover>
        </div>
      );
    } if (connection && connection.device_access === 0 && connection.status_access === 1) {
      statusFigure = 'icon-circle';
      return (
        <div className="verify-IP">
          <div className="margin-right-10 show-IP">
            {verifiedHostname}
            :
          </div>
          <Popover content={<IntlMessages id="common.connectionAccessFail" />}>
            <i className={`icon ${statusFigure} connectivity-icon-fail connectivity-icon`} />
          </Popover>
        </div>
      );
    } if (connection && connection.device_access === 0 && connection.status_access === 0) {
      statusFigure = 'icon-circle';
      return (
        <div className="verify-IP">
          <div className="margin-right-10 show-IP">
            {verifiedHostname}
            :
          </div>
          <Popover content={<IntlMessages id="common.connectionHostAccessFail" />}>
            <i className={`icon ${statusFigure} connectivity-icon-fail connectivity-icon`} />
          </Popover>
        </div>

      );
    }
  }

  /**
   * Renders ManageUsers Component
   * @returns {JXS.Element} ManageUsers Component
   */
  render() {
    const { bedCart, deviceTypes } = this.state;
    const {
      loading,
      form,
      match,
      loggedUser,
      intl,
    } = this.props;
    const {
      color_microphone,
      color_camera,
      color_online,
      cisco_hostname,
      sbaps_monitoring_enabled,
    } = bedCart;

    return (
      <div className="manage-customer-wrapper">
        <Form {...FORM_LAYOUT} name="nonUheBedCart" ref={this.formRef} autoComplete="off">
          {this.renderHeadLine()}
          <Row gutter={16}>
            <Col lg={12} md={24} sm={24} xs={24}>
              {(sbaps_monitoring_enabled)

              && (
              <Card
                loading={loading}
                className="gx-card"
                title={
                  <IntlMessages id="configuration.bedsCarts.statusReport.title" />
                }
              >
                <Row className="main-row">
                  <Col lg={11} md={24}>
                    <Row className="status-items-row status-online">
                      <Col className="margin-right-10" lg={17} md={24}>
                        <IntlMessages id="configuration.bedsCarts.statusReport.online" />
                        {' '}
                        :
                      </Col>
                      <Col lg={3} md={24}>
                        <div
                          className={`status-icon-${color_online}`}
                        />
                      </Col>
                    </Row>
                    <Row className="status-items-row status-online">
                      <Col className="margin-right-10" lg={17} md={24}>
                        <IntlMessages id="configuration.bedsCarts.statusReport.deviceId" />
                        {' '}
                        :
                      </Col>
                      <Col lg={3} md={24}>
                        <div>{match.params.id}</div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={13} md={24}>
                    <Row className="status-items-row status-online">
                      <Col className="margin-right-10" lg={17} md={24}>
                        <IntlMessages id="configuration.bedsCarts.statusReport.camera" />
                        {' '}
                        :
                      </Col>
                      <Col lg={3} md={24}>
                        <div
                          className={`status-icon-${color_camera}`}
                        />
                      </Col>
                    </Row>
                    <Row className="status-items-row status-online">
                      <Col className="margin-right-10" lg={17} md={24}>
                        <IntlMessages id="configuration.bedsCarts.statusReport.microphone" />
                        {' '}
                        :
                      </Col>
                      <Col lg={3} md={24}>
                        <div
                          className={`status-icon-${color_microphone}`}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
              )}
              <Card
                loading={loading}
                className="gx-card"
                title={<IntlMessages id="configuration.bedsCarts.roomBedName" />}
              >
                <Form.Item
                  colon={false}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'configuration.users.emptyField' }),
                    },
                  ]}
                  wrapperCol={{
                    xl: { span: 24 },
                  }}
                >
                  <Input
                    autoComplete="off"
                    onChange={this.onChangeRoomBed}
                    className="room-bed-name"
                    value={bedCart.name}
                  />
                </Form.Item>

              </Card>
              <DeviceTypeCard
                bedCart={bedCart}
                loading={loading}
                deviceTypes={deviceTypes}
                onChangeDeviceType={this.onChangeDeviceType}
                onChangeCallFormat={this.onChangeCallFormat}
                onChangeAddress={this.onChangeAddress}
                form={form}
              />
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              {(sbaps_monitoring_enabled)

              && (
              <Card
                loading={loading}
                className="gx-card"
                title={
                  <IntlMessages id="configuration.bedsCarts.ciscoTitle" />
                }
              >
                <Form.Item
                  colon={false}
                  label={<IntlMessages id="configuration.bedsCarts.hostName" />}
                  name="cisco_hostname"
                  rules={[
                    {
                      pattern: HOSTNAME_REGEX,
                      message: this.intl.formatMessage({ id: 'common.hostnameError' }),
                    },
                  ]}
                >
                  <Input
                    id="cisco_hostname"
                    onChange={(event) => this.onChangeHandlerCisco(event, 'cisco_hostname')}
                    value={bedCart.cisco_hostname}
                  />
                </Form.Item>
                <Form.Item
                  colon={false}
                  label={<IntlMessages id="configuration.bedsCarts.username" />}
                  name="cisco_username"
                >
                  <Input
                    onChange={(event) => this.onChangeHandlerCisco(event, 'cisco_username')}
                    value={bedCart.cisco_username}
                    id="cisco_username"
                  />
                </Form.Item>
                <Form.Item
                  colon={false}
                  label={<IntlMessages id="configuration.bedsCarts.password" />}
                  name="cisco_password"
                >
                  <Input.Password
                    onChange={(event) => this.onChangeHandlerCisco(event, 'cisco_password')}
                    value={bedCart.cisco_password}
                    autoComplete="new-password"
                    id="cisco_password"
                    visibilityToggle={!canDoAction(loggedUser)}
                    disabled={canDoAction(loggedUser)}
                  />
                </Form.Item>
                <Col className="console-btn-wrapper" lg={24} md={24} sm={24} xs={24}>
                  <Row>
                    <a
                      href={this.getClickableLink(cisco_hostname)}
                      shape="circle"
                      target="_blank"
                      rel="noreferrer"
                      className="page-icons page-icon-plus console-btn"
                      disabled={!cisco_hostname}
                    >
                      <IntlMessages id="configuration.bedsCarts.openManagementConsole" />
                    </a>
                  </Row>
                </Col>
                <Col lg={24} md={24} sm={24} xs={24}>
                  <Row className="verify-button-wrapper">
                    <Button
                      className="page-icons page-icon-plus verify-button"
                      onClick={this.verifyConnectivity}
                      disabled={!cisco_hostname}
                    >
                      <IntlMessages id="configuration.bedsCarts.verifyConnectivity" />
                    </Button>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    {this.renderConnectivityIcon()}
                  </Row>
                </Col>
              </Card>
              )}
              <Card
                loading={loading}
                className="gx-card"
                title={
                  <IntlMessages id="configuration.bedsCarts.activeComponents.title" />
                }
              >
                <ActiveComponentsCard
                  bedCart={bedCart}
                  bedCartId={match.params.id}
                  imgColSize={6}
                />
              </Card>
            </Col>
          </Row>
          {this.renderHeadLine()}
        </Form>
      </div>
    );
  }
}

ManageNonUheBedCart.defaultProps = {
  loading: true,
  lastLocation: {},
  form: {},
};

ManageNonUheBedCart.propTypes = {
  history: PropTypes.shape().isRequired,
  lastLocation: PropTypes.objectOf(PropTypes.string),
  intl: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  loading: PropTypes.bool,
  loggedUser: PropTypes.shape().isRequired,
  form: PropTypes.shape(),
  saveNonUheBedCartEdit: PropTypes.func.isRequired,
};

/**
 * Maps State to Props
 * @param {object} param Desctructed Store Objects
 * @returns {object} Props
 */
const mapStateToProps = ({ bedsCarts, common, ConfigurationUsers }) => ({
  error: common.error,
  bedCart: bedsCarts.selectedBedCart,
  deviceTypes: bedsCarts.deviceTypes,
  loading: bedsCarts.loading,
  loggedUser: ConfigurationUsers.ownUser,
  connectionStatus: bedsCarts.connectivity,
  custAttr: ConfigurationUsers.customerAttr,
});

/**
 * Maps Actions to Props
 * @param {function} dispatch Dispatches Actions to Props
 * @returns {object} Actions
 */
const mapDispatchToProps = (dispatch) => ({
  clearBedCart: () => dispatch(clearBedCart()),
  fetchBedCart: (id) => dispatch(fetchBedCart(id)),
  fetchDeviceTypes: (data) => dispatch(fetchDeviceTypes(data)),
  saveNonUheBedCartEdit: (data) => dispatch(saveUheBedCart(data)),
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLastLocation(withRouter(injectIntl(ManageNonUheBedCart))));
