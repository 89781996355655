import {
  CREATE_SUPPORT_VIDEO_REQUEST,
  CREATE_SUPPORT_VIDEO_SUCCESS,
  DELETE_SUPPORT_VIDEO_REQUEST,
  DELETE_SUPPORT_VIDEO_SUCCESS,
  SUPPORT_DOCUMENT_FETCH_DATA,
  SUPPORT_DOCUMENT_FETCH_DATA_SUCCESS,
  CREATE_SUPPORT_DOCUMENT_REQUEST,
  CREATE_SUPPORT_DOCUMENT_SUCCESS,
  SUPPORT_SECTION_FETCH_DATA,
  SUPPORT_SECTION_FETCH_SUCCESS,
  DOWNLOAD_PDF_REQUEST,
  DOWNLOAD_PDF_SUCCESS,
  DELETE_SUPPORT_DOCUMENT_REQUEST,
  DELETE_SUPPORT_DOCUMENT_SUCCESS,
  CLEAR_SUPPORT_STATE,
  CREATE_SUPPORT_SECTION_REQUEST,
  CREATE_SUPPORT_SECTION_SUCCESS,
  DELETE_SUPPORT_SECTION_REQUEST,
  DELETE_SUPPORT_SECTION_SUCCESS,
  SUPPORT_DOCUMENT_FETCH_DATA_SEARCH,
  SUPPORT_DOCUMENT_FETCH_DATA_SEARCH_SUCCESS,
} from '@constants/UHEActionTypes';

/**
 * Fetch Documents by Search Query
 * @param {string} searchValue search value
 * @return {Object} action
 */
export const fetchDocumentsBySearch = (searchValue) => ({
  type: SUPPORT_DOCUMENT_FETCH_DATA_SEARCH,
  value: searchValue,
});

/**
 * Fetch Documents by Search Query success
 * @param  {Object} data data
 * @return {Object} action
 */
export const fetchDocumentsBySearchSuccess = (data) => ({
  type: SUPPORT_DOCUMENT_FETCH_DATA_SEARCH_SUCCESS,
  data,
});

/**
 * Handle CREATE_SUPPORT_VIDEO_REQUEST Action
 * @param {Object} data data
 * @returns {Object} action with payload
 */
export const saveSupportVideo = (data) => ({
  type: CREATE_SUPPORT_VIDEO_REQUEST,
  payload: { ...data },
});

/**
 * Handle CREATE_SUPPORT_VIDEO_SUCCESS Action
 * @param {Object} payload data
 * @returns {Object} action with payload
 */
export const saveSupportVideoSuccess = (payload) => ({
  type: CREATE_SUPPORT_VIDEO_SUCCESS,
  payload: { ...payload },
});

/**
 * DELETE request
 * @param {Object} data data
 * @return {Object} action with payload
 */
export const deleteVideo = (data) => ({
  type: DELETE_SUPPORT_VIDEO_REQUEST,
  payload: data,
});

/**
 * DELETE request success
 * @param {Object} data data
 * @return {Object} action with payload
 */
export const deleteVideoSuccess = (data) => ({
  type: DELETE_SUPPORT_VIDEO_SUCCESS,
  payload: data,
});

/**
 * Fetch support video data
 * @return {Object} action
 */
export const supportDocumentOnFetchData = () => ({
  type: SUPPORT_DOCUMENT_FETCH_DATA,
});

/**
 * Fetch success
 * @param  {Object} data data
 * @return {Object} action with payload
 */
export const fetchSupportDocumentDataSuccess = (data) => ({
  type: SUPPORT_DOCUMENT_FETCH_DATA_SUCCESS,
  data,
});

/**
 * Handles CREATE_SUPPORT_DOCUMENT_REQUEST Action
 * @param {file} file file
 * @param {string} name file name
 * @param {string} sectionId file section
 * @returns {Object} action with payload
 */
export const uploadDocument = (file, name, sectionId) => ({
  type: CREATE_SUPPORT_DOCUMENT_REQUEST,
  payload: { file, name, sectionId },
});

/**
 * Handles CREATE_SUPPORT_DOCUMENT_SUCCESS Action
 * @param {file} uploadedDocumentId file
 * @param {string} filename filename
 * @param {string} name name input,
 * @param {string} sectionId file section
 * @returns {Object} action with payload
 */
export const uploadDocumentSuccess = (uploadedDocumentId, filename, name, sectionId) => ({
  type: CREATE_SUPPORT_DOCUMENT_SUCCESS,
  payload: {
    uploadedDocumentId, filename, name, sectionId,
  },
});

/**
 * Fetch support video data
 * @return {Object} action with payload
 */
export const supportSectionOnFetchData = () => ({
  type: SUPPORT_SECTION_FETCH_DATA,

});

/**
 * Fetch success
 * @param  {Object} data data
 * @return {Object} action with payload
 */
export const supportSectionOnFetchDataSuccess = (data) => ({
  type: SUPPORT_SECTION_FETCH_SUCCESS,
  data,
});

/**
 * Fetch success
 * @param  {Number} id file id
 * @param  {String} name file name
 * @return {Object} action with payload
 */
export const downloadPdf = (id, name) => ({
  type: DOWNLOAD_PDF_REQUEST,
  payload: {
    id,
    name,
  },
});

/**
 * Fetch success
 * @param  {Object} data file data
 * @return {Object} action with payload
 */
export const downloadPdfSuccess = (data) => ({
  type: DOWNLOAD_PDF_SUCCESS,
  data,
});

/**
 * DELETE request document
 * @param {Object} data document data
 * @return {Object} action with payload
 */
export const deleteDocument = (data) => ({
  type: DELETE_SUPPORT_DOCUMENT_REQUEST,
  payload: data,
});

/**
 * DELETE request success
 * @param {number} id document id
 * @returns {Object} action with payload
 */
export const deleteDocumentSuccess = (id) => ({
  type: DELETE_SUPPORT_DOCUMENT_SUCCESS,
  payload: { id },
});

/**
 * Clear State
 * @returns {Object} action
 */
export const clearSupportState = () => ({
  type: CLEAR_SUPPORT_STATE,
});

/**
 * Add section
 * @param {Object} data section data
 * @returns {Object} action with payload
 */
export const addSection = (data) => ({
  type: CREATE_SUPPORT_SECTION_REQUEST,
  payload: { ...data },
});

/**
 * Add section success action
 * @param {Object} payload response data
 * @returns {Object} action with payload
 */
export const addSectionSuccess = (payload) => ({
  type: CREATE_SUPPORT_SECTION_SUCCESS,
  payload: { ...payload },
});

/**
 * DELETE request
 * @param {Object} data section data
 * @return {Object} action with payload
 */
export const deleteSection = (data) => ({
  type: DELETE_SUPPORT_SECTION_REQUEST,
  payload: data,
});

/**
 * DELETE request success
 * @param {Object} data response data
 * @return {Object} action with payload
 */
export const deleteSectionSuccess = (data) => ({
  type: DELETE_SUPPORT_SECTION_SUCCESS,
  payload: data,
});
