import {
  LISTINGS_TOP_FILTER_GET_OPTIONS,
  LISTINGS_TOP_FILTER_FETCH_SUCCESS,
  RESET_TOP_FILTERS,
} from '@constants/UHEActionTypes';

/**
 * @param {string} key
 * @param {string} filter
 * @param {boolean} shouldSort
 * @return {Object}
 */
export const onGetOptions = (key, filter, shouldSort) => {
  return {
    type: LISTINGS_TOP_FILTER_GET_OPTIONS,
    key,
    filter,
    shouldSort,
  };
};

/**
 * @param  {Object} payload
 * @param  {[string} key
 * @return {Object}
 */
export const fetchOptionsSuccess = (payload, key) => {
  return {
    type: LISTINGS_TOP_FILTER_FETCH_SUCCESS,
    payload,
    key,
  };
};
/**
 * @return {Object}
 */
export const resetFilters = () => ({
  type: RESET_TOP_FILTERS,
});
