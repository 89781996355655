import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Settings from '@reducers/Settings';
import Auth from '@reducers/Auth';
import Notes from '@reducers/Notes';
import Contact from '@reducers/Contact';
import Common from '@reducers/Common';
import ListingsTopFilterReducers from '@uhe_reducers/filters/ListingsTopFilterReducers';
import UHEReducers from '@uhe_reducers/monitoring/UHEReducers';
import FacilitiesReducers from '@uhe_reducers/configuration/facilities/FacilitiesReducers';
import OrganizationsReducers from '@uhe_reducers/configuration/organizations/OrganizationsReducers';
import CustomersReducers from '@uhe_reducers/configuration/customers/CustomersReducers';
import StandardsReducers from '@uhe_reducers/monitoring/StandardsReducers';
import UhePieChartReducers from '@uhe_reducers/monitoring/UhePieChartReducers';
import UnitReducers from '@uhe_reducers/configuration/UnitReducers';
import LoadingReducer from '@uhe_reducers/loadingReducer';
import SubtitleReducer from '@uhe_reducers/subtitleReducer';
import UnitsReducers from '@uhe_reducers/configuration/units/UnitsReducers';
import UheGmapReducers from '@uhe_reducers/monitoring/UheGmapReducers';
import BedsCartsReducers from '@uhe_reducers/configuration/bedsCarts/BedsCartsReducers';
import UsersReducers from '@uhe_reducers/configuration/users/UsersReducers';
import MobilePatientsReducers from '@uhe_reducers/configuration/mobilePatients/MobilePatientsReducers';
import SystemReducers from '@uhe_reducers/system/SystemReducers';
import iObserverReducers from '@uhe_reducers/system/iObserverReducers';
import SupportReducers from '@uhe_reducers/system/SupportReducers';
import SignInReportsReducers from '@uhe_reducers/reports/SignInReportsReducers';
import VmrReportReducers from '@uhe_reducers/reports/VmrReportReducers';
import SsoProvidersReducers from '@uhe_reducers/system/SsoProvidersReducers';
import DeveloperReducer from '@uhe_reducers/system/DeveloperReducer';
import NotificationsReducers from '@uhe_reducers/system/NotificationsReducers';
import ResetPasswordReducers from '@uhe_reducers/ResetPasswordReducers';
import ProgramsReducers from '@uhe_reducers/configuration/programs/ProgramsReducers';
import AdvancedDevReducer from '@uhe_reducers/system/AdvancedDevReducer';
import AnnouncementsReducer from '@uhe_reducers/system/AnnouncementsReducer';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  notes: Notes,
  contact: Contact,
  common: Common,

  // Add UHE reducers below
  listingsTopFilter: ListingsTopFilterReducers,
  MonitoringUHE: UHEReducers,
  ConfigurationOrganizations: OrganizationsReducers,
  ConfigurationCustomers: CustomersReducers,
  ConfigurationFacilities: FacilitiesReducers,
  ConfigurationUnits: UnitsReducers,
  UhePieChart: UhePieChartReducers,
  UheGmap: UheGmapReducers,
  MonitoringStandards: StandardsReducers,
  unit: UnitReducers,
  loading: LoadingReducer,
  ConfigurationUsers: UsersReducers,
  subtitle: SubtitleReducer,
  bedsCarts: BedsCartsReducers,
  SystemSettingsSystem: SystemReducers,
  iObserverSettings: iObserverReducers,
  SignInReports: SignInReportsReducers,
  vmrReport: VmrReportReducers,
  ConfigurationMobilePatients: MobilePatientsReducers,
  SupportSettings: SupportReducers,
  SsoProvidersSettings: SsoProvidersReducers,
  SystemDeveloper: DeveloperReducer,
  ResetPassword: ResetPasswordReducers,
  Notifications: NotificationsReducers,
  programs: ProgramsReducers,
  advancedDev: AdvancedDevReducer,
  announcements: AnnouncementsReducer,
});

export default createRootReducer;
