import React, { useState } from 'react';
import {
  Col, Row, Collapse, Table, Select,
} from 'antd';
import { DOCUMENT_TYPE } from '@constants/UHESettings';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import IntlMessages from 'util/IntlMessages';
import { FileTextOutlined, PlayCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

const { Panel } = Collapse;

/**
 * Handles Section Functional Component
 * @param {object} props props
 * @returns {JSX} Render Section Component
 */
const Section = (props) => {
  const {
    section, downloadFile, renderDeleteDocumentButton,
    loading, searchDocumentID, searchType, searchValue,
  } = props;
  const { subsections } = section;
  const alwaysFullWidth = {
    lg: 24, md: 24, sm: 24, xs: 24,
  };
  const { intl: { formatMessage } } = props;
  const [sortUpdate, setSortUpdate] = useState(true);
  const [singleData, setSingleData] = useState(null);

  /**
  * Adapts the Upload Log Table Documents
  * @param {array} data data
  * @returns {array} data
  */
  const documentsAdapter = (data) => {
    const adaptedData = [];
    if (singleData) {
      const filterData = data.filter((document) => document.name === singleData);
      filterData.forEach((document) => {
        adaptedData.push({
          key: document.id,
          name: document.name,
          lastUpdated: document.last_updated || new Date().toLocaleDateString('en-US'),
          document,
        });
      });
    } else {
      data.forEach((document) => {
        adaptedData.push({
          key: document.id,
          name: document.name,
          lastUpdated: document.last_updated || new Date().toLocaleDateString('en-US'),
          document,
        });
      });
    }
    const compareFirstReturn = sortUpdate ? -1 : 1;
    const compareSecondReturn = sortUpdate ? 1 : -1;
    /**
     * Sort function
     * @param {object} a current object
     * @param {object} b next object
     * @returns {num} sort
     */
    const compare = (a, b) => {
      if (a.lastUpdated > b.lastUpdated) {
        return compareFirstReturn;
      }
      if (a.lastUpdated < b.lastUpdated) {
        return compareSecondReturn;
      }
      return 0;
    };
    return adaptedData.sort(compare);
  };

  const documentColumns = [
    {
      title: <IntlMessages id="support.columnTableName" />,
      key: 'key',
      dataIndex: 'document',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (doc) => {
        if (doc.documentType === 'FILE') {
          return downloadFile(doc.id, doc.name, doc.documentType);
        }
        return (
          <div className="download-file">
            {doc.documentType === 'LINK' ? <PlayCircleOutlined /> : <FileTextOutlined />}
            <a href={doc.document} className="video-columns" target="_blank">
              {doc.name}
            </a>
          </div>
        );
      },
    },
    {
      title: <IntlMessages id="support.lastUpdated" />,
      key: 'lastUpdated',
      dataIndex: 'lastUpdated',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.lastUpdated - b.lastUpdated,
      render: (lastUpdated) => (new Date(lastUpdated).toLocaleDateString('en-US')),
    },
    {
      title: 'Delete',
      key: 'key',
      dataIndex: 'key',

      render: (document) => (renderDeleteDocumentButton(document)),
    },
  ];

  /**
   * Handle documents filtering
   * @param {array} documents all documents details
   * @param {string} id  section/subsection id
   * @returns {array} filtered documents
   */
  const whichDocumentsToRender = (documents, id) => {
    const filteredDocuments = documents.filter((document) => document.section.id === id);
    const videoTypes = filteredDocuments.filter((doc) => doc.documentType === 'LINK');
    const documentTypes = filteredDocuments.filter((doc) => doc.documentType === 'FILE');
    if (searchType === 1) {
      return documentTypes;
    }
    if (searchType === 0) {
      return videoTypes;
    }
    return filteredDocuments;
  };

  /**
  * Handles subsection
  * @param {object} subsectionData subsection details
  * @returns {JSX} render Subsection
  */
  const renderSubsection = (subsectionData) => {
    let subsectionDocuments = [];
    if (searchDocumentID) {
      subsectionDocuments = subsectionData.documents.filter((doc) => doc.id === searchDocumentID);
      if (!subsectionDocuments.length > 0) {
        return null;
      }
    }
    if (!subsectionData.documents.length > 0) {
      return null;
    }
    return (
      <Collapse className="section-titles collapses" accordion defaultActiveKey={[subsectionData.name.indexOf]}>
        <Panel className="section-titles" header={subsectionData.name} key={subsectionData.name.indexOf}>
          <Row {...alwaysFullWidth} className="gx-d-flex gx-align-items-center" key={subsectionData.id} gutter={16}>
            <Col {...alwaysFullWidth}>
              <Table
                bordered
                className="gx-table-responsive"
                columns={documentColumns}
                dataSource={documentsAdapter(whichDocumentsToRender(
                  tableDocumentsData(subsectionData),
                  subsectionData.id,
                ))}
                pagination={false}
                loading={loading}
              />
            </Col>
          </Row>
        </Panel>
      </Collapse>
    );
  };
  /**
   * tableDocumentsData
   * @param {object} section section data
   * @returns {array} documents data
   */
  const tableDocumentsData = (section) => {
    const { searchDocuments, documents } = section;
    const sectionDocuments = searchDocumentID ? section.documents.filter(
      (doc) => doc.id === searchDocumentID,
    ) : [];
    if (searchDocuments.length > 0 || searchValue.length > 2) {
      return searchDocuments;
    }
    if (sectionDocuments.length > 0) {
      return sectionDocuments;
    }
    return documents;
  };
  return (
    <Collapse className="section-titles collapses" accordion defaultActiveKey={[section.name.indexOf]}>
      <Panel className="section-titles" header={section.name} key={section.name.indexOf}>
        <Row {...alwaysFullWidth} className="gx-d-flex gx-align-items-center" key={section.id} gutter={16}>
          <Col {...alwaysFullWidth}>
            {section.documents.length > 0
            && (
            <Table
              bordered
              className="gx-table-responsive"
              columns={documentColumns}
              dataSource={documentsAdapter(whichDocumentsToRender(
                tableDocumentsData(section),
                section.id,
              ))}
              pagination={false}
              loading={loading}
            />
            )}
          </Col>
        </Row>
        {subsections && subsections.map((subsection) => renderSubsection(subsection))}
      </Panel>
    </Collapse>
  );
};

Section.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(withRouter(Section));
