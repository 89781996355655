import {
  GET_ANNOUNCEMENTS_LIST_REQUEST,
  GET_ANNOUNCEMENTS_LIST_SUCCESS,
  DELETE_ANNOUNCEMENT_REQUEST,
  DELETE_ANNOUNCEMENT_SUCCESS,
  CREATE_ANNOUNCEMENT_REQUEST,
  UPDATE_ANNOUNCEMENT_REQUEST,
  UPDATE_ANNOUNCEMENT_SUCCESS,
} from '@constants/UHEActionTypes';

/**
 * updateAnnouncementRequest Action creator
 * @param {string} id announcement id
 * @param {string} message announcement content
 * @param {string} date date
 * @returns {object} action
 */
export const updateAnnouncementRequest = (id, message, date) => ({
  type: UPDATE_ANNOUNCEMENT_REQUEST,
  payload: { id, message, date },
});

/**
 * updateAnnouncementSuccess
 * @param {string} payload announcement data
 * @returns {object} action
 */
export const updateAnnouncementSuccess = (payload) => ({
  type: UPDATE_ANNOUNCEMENT_SUCCESS,
  payload,
});

/**
 * getAnnouncements Action creator
 * @returns {object} action
 */
export const getAnnouncements = () => ({
  type: GET_ANNOUNCEMENTS_LIST_REQUEST,
});

/**
 * getAnnouncementsSuccess Action creator
 * @param {object} payload all announcements data
 * @returns {object} action
 */
export const getAnnouncementsSuccess = (payload) => ({
  type: GET_ANNOUNCEMENTS_LIST_SUCCESS,
  payload,
});

/**
 * deleteAnnouncementRequest Action creator
 * @param {object} id announcement id
 * @returns {object} action
 */
export const deleteAnnouncementRequest = (id) => ({
  type: DELETE_ANNOUNCEMENT_REQUEST,
  id,
});

/**
 * deleteAnnouncementSuccess Action creator
 * @param {object} payload announcement id
 * @returns {object} action
 */
export const deleteAnnouncementSucces = (payload) => ({
  type: DELETE_ANNOUNCEMENT_SUCCESS,
  payload,
});

/**
 * addAnnouncementRequest Action creator
 * @param {string} message annnouncement message
 * @param {string} date expire date
 * @returns {object} action
 */
export const addAnnouncementRequest = (message, date) => ({
  type: CREATE_ANNOUNCEMENT_REQUEST,
  payload: { message, date },
});
