import {
  all, fork, takeEvery, call, put,
} from 'redux-saga/effects';
import {
  GET_ANNOUNCEMENTS_LIST_REQUEST, DELETE_ANNOUNCEMENT_REQUEST, CREATE_ANNOUNCEMENT_REQUEST,
  UPDATE_ANNOUNCEMENT_REQUEST,
} from '@constants/UHEActionTypes';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { fetchError, showMessage } from '@actions/Common';
import RestManager from '@util/RestManager';
import { getAnnouncementsSuccess, deleteAnnouncementSucces, updateAnnouncementSuccess } from '@uhe_actions/system/AnnouncementsActions';
import moment from 'moment';

/**
 * Update Announcement POST request
 * @param {string} id annnouncement id
 * @param {string} message announcement message
 * @returns {void}
 */
const updateAnnouncement = async ({ id, message, date }) => {
  const body = { message, expire_at: parseInt(moment(date).format('x')) };
  const response = await RestManager.requestWithoutQueryParams(`${ENDPOINTS.announcements.list}/${id}`, 'POST', body);
  return response;
};

/**
 * updateAnnouncementSaga saga worker
 * @param {string} payload announcement data
 * @returns {void}
 */
function* updateAnnouncementSaga({ payload }) {
  const { message } = payload;
  try {
    if (message) {
      const response = yield call(updateAnnouncement, payload);
      if (response) {
        yield put(updateAnnouncementSuccess(response));
        yield put(showMessage('announcementUpdated'));
      }
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Create Announcement POST request
 * @param {string} payload announcement message
 * @returns {void}
 */
const addAnnouncement = async (payload) => {
  const { message, date } = payload;
  const body = { message, expire_at: parseInt(moment(date).format('x')) };
  const response = await RestManager.requestWithoutQueryParams(`${ENDPOINTS.announcements.list}`, 'POST', body);
  return response;
};

/**
 * createAnnouncementSaga saga worker
 * @param {string} payload announcement message
 * @returns {void}
 */
function* createAnnouncementSaga(payload) {
  try {
    const response = yield call(addAnnouncement, payload.payload);
    if (response) {
      const announcementsResponse = yield call(fetchAnnouncements);
      if (announcementsResponse) {
        yield put(getAnnouncementsSuccess(announcementsResponse));
        yield put(showMessage('announcementCreated'));
      }
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}
/**
 * Announcement DELETE request
 * @param {string} id announcement id
 * @returns {void}
 */
const deleteAnnouncement = async (id) => {
  const request = await RestManager.requestWithoutQueryParams(`${ENDPOINTS.announcements.list}/${id}`, 'DELETE');
  return request;
};

/**
 * deleteAnnouncementSaga saga worker
 * @param {string} payload announcement id
 * @returns {void}
 */
function* deleteAnnouncementSaga(payload) {
  const { id } = payload;
  try {
    const response = yield call(deleteAnnouncement, id);
    if (response) {
      yield put(deleteAnnouncementSucces(id));
      yield put(showMessage('announcementDeleted'));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}
/**
 * Announcements List Request
 * @returns {Promise} Response
 */
const fetchAnnouncements = async () => {
  const request = await RestManager.request(`${ENDPOINTS.announcements.list}`);
  return request;
};

/**
 * announcementsListSaga saga worker
 * @returns {void}
 */
function* announcementsListSaga() {
  try {
    const response = yield call(fetchAnnouncements);
    if (response) {
      yield put(getAnnouncementsSuccess(response));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}
/**
 * Saga Watcher
 * @returns {void}
 */
export function* actionsWatcher() {
  yield takeEvery(GET_ANNOUNCEMENTS_LIST_REQUEST, announcementsListSaga);
  yield takeEvery(DELETE_ANNOUNCEMENT_REQUEST, deleteAnnouncementSaga);
  yield takeEvery(CREATE_ANNOUNCEMENT_REQUEST, createAnnouncementSaga);
  yield takeEvery(UPDATE_ANNOUNCEMENT_REQUEST, updateAnnouncementSaga);
}

/**
   * Root Saga
   * @returns {void}
   */
export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
