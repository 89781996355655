import React, {
  memo, forwardRef,
} from 'react';
import {
  Col, Form, Input, Modal, Row,
} from 'antd';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { PASSWORD_REGEX } from '@constants/UHESettings';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const ChangePasswordModal = memo(forwardRef(({
  onSubmit,
  user,
  onCancel,
}, ref) => {
  const intl = useIntl();
  const apdPasswordModalTitle = user?.firstName;
  const userPasswordModalTitle = `${user?.firstName} ${user?.lastName}`;
  const passwordModalTitle = user?.page ? apdPasswordModalTitle : userPasswordModalTitle;

  return (
    <Modal
      visible={user?.id}
      onOk={onSubmit}
      onCancel={onCancel}
      okText={intl.formatMessage({ id: 'configuration.users.updatePassword' })}
      afterClose={ref.current?.resetFields}
      className="dark-mode-modal"
    >
      <Row gutter={16}>
        <Col>
          <ExclamationCircleOutlined className="modal__icon modal__icon--warning" />
        </Col>
        <Col flex="auto">
          <div className="modal__title">
            {`${intl.formatMessage({ id: 'configuration.users.changePassword' })} ${passwordModalTitle}`}
          </div>
          <Form
            autoComplete="off"
            layout="vertical"
            ref={ref}
            className="changePassword"
            onFinish={onSubmit}
          >
            <Form.Item
              colon={false}
              label={intl.formatMessage({ id: 'configuration.users.newPassword' })}
              name="password"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'configuration.users.emptyField' }),
                },
                {
                  pattern: PASSWORD_REGEX,
                  message: intl.formatMessage({ id: 'configuration.users.newPassRule' }),
                },
              ]}
            >
              <Input.Password
                className="change-confirm-password"
                autoComplete="off"
                id="password"
                type="password"
                autoFocus
              />
            </Form.Item>
            <Form.Item
              colon={false}
              label={intl.formatMessage({ id: 'configuration.users.confirmPassword' })}
              name="confirmPassword"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'configuration.users.emptyField' }),
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(intl.formatMessage({ id: 'configuration.users.confirmField' }));
                  },
                }),
              ]}
            >
              <Input.Password
                className="change-confirm-password"
                autoComplete="off"
                id="confirmPassword"
                type="password"
              />
            </Form.Item>
            {/* For submitting on enter keypress */}
            <input autoComplete="off" type="submit" hidden />
          </Form>
        </Col>
      </Row>
    </Modal>
  );
}));

ChangePasswordModal.displayName = 'ChangePasswordModal';

ChangePasswordModal.defaultProps = {
  user: null,
};

ChangePasswordModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    page: PropTypes.string,
  }),
  onCancel: PropTypes.func.isRequired,
};

export default ChangePasswordModal;
