import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import URLSearchParams from 'url-search-params';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';

import AppLocale from 'lngProvider';
import MainApp from './MainApp';
import { setInitUrl } from 'appRedux/actions/Auth';
import {
  onLayoutTypeChange,
  onNavStyleChange,
  setThemeType,
} from 'appRedux/actions/Setting';

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from '@constants/ThemeSetting';

import {
  ACCESS_TOKEN_PARAM,
  ACCESS_TOKEN_KEY,
  INVITE_TOKEN,
} from '@constants/UHESettings';

const App = (props) => {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

  const qParams = new URLSearchParams(history.location.search);
  const hParams = new URLSearchParams(
    String(history.location.hash).replace('#', '?'),
  );
  const newAccessToken = qParams.get(ACCESS_TOKEN_PARAM) || hParams.get(ACCESS_TOKEN_PARAM);
  const invitationToken = qParams.get(INVITE_TOKEN);

  if (invitationToken && location.pathname.indexOf('/activate-user') === -1) {
    const path = `/activate-user?${INVITE_TOKEN}=${invitationToken}`;
    history.push(path);
  } else if (
    (!accessToken) && (!newAccessToken) && location.pathname.indexOf('/activate-user') === -1
  ) {
    window.location.href = window.env_overrides.REACT_APP_SSO_REDIRECT_URL
      || process.env.REACT_APP_SSO_REDIRECT_URL;
  } else if (newAccessToken) {
    localStorage.setItem(ACCESS_TOKEN_KEY, newAccessToken);
  }

  const dispatch = useDispatch();
  const { locale, navStyle, layoutType } = useSelector(
    ({ settings }) => settings,
  );
  const { initURL } = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }
    const params = new URLSearchParams(location.search);

    if (params.has('theme')) {
      dispatch(setThemeType(params.get('theme')));
    }
    if (params.has('nav-style')) {
      dispatch(onNavStyleChange(params.get('nav-style')));
    }
    if (params.has('layout-type')) {
      dispatch(onLayoutTypeChange(params.get('layout-type')));
    }
    setLayoutType(layoutType);
    setNavStyle(navStyle);
    setJACss();
  });

  const setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  const setJACss = () => {
    // adding / removing css class fixes for Arabic and Japanese language layout
    switch(locale.locale){
      case 'ja': { // japanese
        // removing css class fixes for arabic
        document.body.classList.remove('ar-full-layout');

        document.body.classList.add('ja-full-layout');
        break;
      }
      case 'ar': { // arabic
        // removing css class fixes for japanese
        document.body.classList.remove('ja-full-layout');

        document.body.classList.add('ar-full-layout');
        break;
      }
      default: {
        document.body.classList.remove('ja-full-layout', 'ar-full-layout');
        break;
      }
    }
  }

  const setNavStyle = (navStyle) => {
    if (
      navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER
    ) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  const currentAppLocale = AppLocale[locale.locale];

  const flattenMessages = (nestedMessages, prefix = '') => {
    return Object.keys(nestedMessages).reduce((messages, key) => {
      let value = nestedMessages[key];
      let prefixedKey = prefix ? `${prefix}.${key}` : key;

      if (typeof value === 'string') {
        messages[prefixedKey] = value;
      } else {
        Object.assign(messages, flattenMessages(value, prefixedKey));
      }

      return messages;
    }, {});
  };

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={flattenMessages(currentAppLocale.messages)}
      >
        <Route path={`${match.url}`} location={location} component={MainApp} />
      </IntlProvider>
    </ConfigProvider>
  );
};

export default memo(App);
