import {
  all,
  call,
  fork,
  put,
  takeEvery,
} from 'redux-saga/effects';
import {
  ssoProvidersFetchDataSuccess,
  saveSsoProviderSuccess,
  saveSsoProviderByIdSuccess,
  onFetchSamlProvidersDataSuccess,
  saveSamlProviderSuccess,
  onFetchDomainDataSuccess,
  saveDomainSuccess,
} from '@actions/uhe/system/SsoProvidersActions';
import {
  SSO_PROVIDERS_FETCH_DATA,
  SAVE_SSO_PROVIDER,
  SAVE_SSO_PROVIDER_ID,
  DELETE_SSO_PROVIDER_REQUEST,
  SAML_PROVIDERS_FETCH_DATA,
  SAVE_SAML_PROVIDER,
  DOMAIN_FETCH_DATA,
  SAVE_DOMAIN_DATA,
  DELETE_DOMAIN_REQUEST,
} from '@constants/UHEActionTypes';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { fetchError, showMessage } from '@actions/Common';
import RestManager from '@util/RestManager';

/**
 * @description Request SSO providers data
 * @param  {number} page
 * @param  {string} sorting
 * @param  {string} filter
 * @return {Object}
 */
const fetchSsoProvidersData = async (page, sorting, filter) => {
  const filterQueryString = filter && filter.length ? `&${filter.join('&')}` : '';
  const sortingQueryString = sorting && sorting.length ? `&sort=${sorting.join('&sort=')}` : '';
  const fetch = await RestManager.request(
    `${ENDPOINTS.ssoProviders.providers}?page=${
      page || 0
    }${sortingQueryString}${filterQueryString}`,
  );
  return fetch;
};

/**
 * @description Request filtering SSO Providers data
 * @param {number} page
 * @param {Array} sorting
 * @param {string} filter
 * @return {Objects}
 */
function* fetchTableData({ page, sorting, filter }) {
  try {
    const fetchedData = yield call(fetchSsoProvidersData, page, sorting, filter);
    yield put(ssoProvidersFetchDataSuccess(fetchedData));
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Request for add new mobile patient
 * @param  {Object} bodyData
 * @return {Object}
 */
const addSsoProvider = async (bodyData) => {
  const add = await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.ssoProviders.providers}`,
    'POST',
    bodyData,
  );
  return add;
};

/**
 * @description Sends POST Request
 * @param {Object} ssoProvider
 * @param {number} id
 * @returns {Array}
 */
const saveSsoProviderById = async (ssoProvider, id) => {
  const save = await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.ssoProviders.providers}/${id}`,
    'POST',
    ssoProvider,
  );
  return save;
};

/**
 * @description Save video
 * @param {Object} data
 * @returns {void}
 */
function* saveSsoProvider(data) {
  try {
    if (data.payload.id) {
      const ssoProvider = yield call(saveSsoProviderById, data.payload.body, data.payload.body.id);

      if (ssoProvider) {
        ssoProvider.id = data.payload.id;
        ssoProvider.saved = true;
        yield put(saveSsoProviderByIdSuccess(ssoProvider));
        yield put(showMessage('save_success'));
      }
    } else {
      const ssoProviderId = yield call(addSsoProvider, data.payload.body);
      if (ssoProviderId) {
        yield put(saveSsoProviderSuccess(ssoProviderId));
        yield put(showMessage('save_success'));
      }
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Sends GET Request
 * @param {String} id
 * @returns {Array}
 */
const doFetchSsoProviderById = async (id) => {
  const doFetch = await RestManager.request(`${ENDPOINTS.ssoProviders.providers}/${id}`);
  return doFetch;
};

/**
 * @description Fetching SSO Provider by Id
 * @param {Object} id
 * @returns {void}
 */
function* fetchMobilePatientById({ id }) {
  try {
    const fetchedSsoProvider = yield call(doFetchSsoProviderById, id);
    if (fetchedSsoProvider) {
      yield put(saveSsoProviderByIdSuccess({ ...fetchedSsoProvider, id }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Delete request
 * @param {string} id
 * @return  {Promise}
 */
const deleteSsoProvider = async (id) => {
  const deleteSso = await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.ssoProviders.providers}/${id}`,
    'DELETE',
  );
  return deleteSso;
};

/**
 * @description Delete request
 * @param {string} id
 * @return  {Promise}
 */
const deleteSamlProvider = async (id) => {
  const deleteSaml = await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.ssoProviders.providers}/${id}/saml`,
    'DELETE',
  );
  return deleteSaml;
};

/**
 * @description Delete sso provider and saml provider by given id
 * @param {Object} data
 * @return {void}
 */
function* delSsoData(data) {
  try {
    let deletedSsoProvider = yield call(deleteSamlProvider, data.payload.id);
    if (deletedSsoProvider && deletedSsoProvider.status >= 200 && deletedSsoProvider.status < 300) {
      yield call(fetchTableData, {
        sorting: data.payload.sorting,
        page: data.payload.page,
        filter: data.payload.filter,
      });
      if (yield put(showMessage('delete_success'))) {
        deletedSsoProvider = yield call(deleteSsoProvider, data.payload.id);
        if (deletedSsoProvider && deletedSsoProvider.status >= 200
          && deletedSsoProvider.status < 300) {
          yield call(fetchTableData, {
            sorting: data.payload.sorting,
            page: data.payload.page,
            filter: data.payload.filter,
          });
        } else {
          yield put(fetchError(deletedSsoProvider));
        }
      }
    } else {
      yield put(fetchError(deletedSsoProvider));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Request saml data
 * @param {number} id
 * @return {Object}
 */
const doFetchSamlData = async (id) => {
  const doFetchSaml = await RestManager.request(
    `${ENDPOINTS.ssoProviders.providers}/${id}/saml`,
  );
  return doFetchSaml;
};

function* fetchEditSamlData({ id }) {
  try {
    const fetchedData = yield call(doFetchSamlData, id);
    if (fetchedData) {
      yield put(onFetchSamlProvidersDataSuccess({ ...fetchedData, id }));
    }
  // eslint-disable-next-line no-empty
  } catch (error) {
  }
}

/**
 * @description Request for saml provider
 * @param  {Object} bodyData
 * @return {Object}
 */
// eslint-disable-next-line no-unused-vars
const addSamlProvider = async (ssoProvider, id) => {
  const addSaml = await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.ssoProviders.providers}/${id}/saml`,
    'POST',
    ssoProvider,
  );
  return addSaml;
};

/**
 * @description Sends POST Request
 * @param {Object} ssoProvider
 * @param {number} id
 * @returns {Array}
 */
const saveSamlProviderById = async (ssoProvider, id) => {
  const saveSaml = await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.ssoProviders.providers}/${id}/saml`,
    'POST',
    ssoProvider,
  );
  return saveSaml;
};

/**
 * @description Save saml provider
 * @param {Object} data
 * @returns {void}
 */
function* saveSamlProvider(data) {
  try {
    if (data.payload.id) {
      const samlProvider = yield call(saveSamlProviderById, data.payload.body, data.payload.id);

      if (samlProvider) {
        samlProvider.id = data.payload.id;
        samlProvider.saved = true;
        yield put(saveSamlProviderSuccess(samlProvider));
        yield put(showMessage('save_success'));
      }
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Request Domain Data
 * @param {number} id
 * @return {Object}
 */
const doFetchDomainData = async (id) => {
  const dofetchDomain = await RestManager.request(
    `${ENDPOINTS.ssoProviders.providers}/${id}/domains`,
  );
  return dofetchDomain;
};

function* fetchEditDomainData({ id }) {
  try {
    const fetchedData = yield call(doFetchDomainData, id);
    if (fetchedData) {
      yield put(onFetchDomainDataSuccess({ data: fetchedData }));
    }
  // eslint-disable-next-line no-empty
  } catch (error) {
  }
}

/**
 * @description Save request for domain
 * @param  {object} bodyData
 * @return {Object}
 */
const saveDomain = async (idSSO, bodyData, id) => {
  const save = await RestManager.requestWithoutQueryParams(`${ENDPOINTS.ssoProviders.providers}/${idSSO}/domains/${id}`, 'POST', bodyData);
  return save;
};

/**
 * @description Save request for domain
 * @param  {object} bodyData
 * @return {Object}
 */
const saveDomainID = async (bodyData, idSSO) => {
  const save = await RestManager.requestWithoutQueryParams(`${ENDPOINTS.ssoProviders.providers}/${idSSO}/domains`, 'POST', bodyData);
  return save;
};

/**
 * @description Save domain data
 * @param {Object} data
 */
function* saveDomainData(data) {
  try {
    if (data.payload.body.id) {
      const domain = yield call(saveDomain, data.payload.id, data.payload.body, data.payload.body.id);
      if (domain) {
        yield put(saveDomainSuccess(domain));
        yield put(showMessage('save_success'));
      }
    } else {
      const toSave = { version: data.payload.body.version, domain: data.payload.body.domain };
      const domainList = yield call(saveDomainID, toSave, data.payload.id);
      if (domainList) {
        domainList.id = data.payload.body.id;
        domainList.saved = true;
        yield put(onFetchDomainDataSuccess(domainList));
        yield put(showMessage('save_success'));
      }
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Delete request
 * @param {string} id
 * @return  {Promise}
 */
const delDomainData = async (idSSO, id) => {
  const deleteDomain = await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.ssoProviders.providers}/${idSSO}/domains/${id}`,
    'DELETE',
  );
  return deleteDomain;
};

/**
 * @description Delete domain id
 * @param {string} data
 * @return {void}
 */
function* deleteDomainData(data) {
  try {
    const deletedUser = yield call(delDomainData, data.payload.ssoId, data.payload.id);
    if (deletedUser && deletedUser.status >= 200 && deletedUser.status < 300) {
      yield put(showMessage('delete_success'));
      const fetchedData = yield call(doFetchDomainData, data.payload.ssoId);
      if (fetchedData) {
        yield put(onFetchDomainDataSuccess({ data: fetchedData }));
      }
    } else {
      yield put(fetchError(deletedUser));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* actionsWatcher() {
  yield takeEvery(SSO_PROVIDERS_FETCH_DATA, fetchTableData);
  yield takeEvery(SAVE_SSO_PROVIDER, saveSsoProvider);
  yield takeEvery(SAVE_SSO_PROVIDER_ID, fetchMobilePatientById);
  yield takeEvery(DELETE_SSO_PROVIDER_REQUEST, delSsoData);
  yield takeEvery(SAML_PROVIDERS_FETCH_DATA, fetchEditSamlData);
  yield takeEvery(SAVE_SAML_PROVIDER, saveSamlProvider);
  yield takeEvery(DOMAIN_FETCH_DATA, fetchEditDomainData);
  yield takeEvery(SAVE_DOMAIN_DATA, saveDomainData);
  yield takeEvery(DELETE_DOMAIN_REQUEST, deleteDomainData);
}

export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
