import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';
import {
  Button, Card, Col, Form, Input, Popconfirm, Popover, Radio, Row, Table, Upload,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  CLOUDBREAK, INDEMAND, LANGUAGELINE, LANGUAGELINEPSTN, STRATUS,
} from '@constants/UHESettings';
import EditableCell from '@components/uhe/configuration/customer/EditableCell';

export const EditableContext = React.createContext();

/**
 * InterpreterSettings Class Component
 * @returns {void}
 */
class InterpreterSettings extends React.Component {
  /**
   * InterpreterSettings Constructor
   * @param {object} props InterpreterSettings Constructor Props
   * @returns {void}
   */
  constructor(props) {
    super(props);

    this.state = {
      editingId: '',
      selectedFile: null,
    };

    this.components = {
      body: {
        cell: EditableCell,
      },
    };

    this.codesColumns = [
      {
        title: 'Language',
        dataIndex: 'name',
      },
      {
        title: 'Prefix',
        dataIndex: 'prefix',
      },
    ];

    this.formRef = React.createRef();
  }

  /**
   * Handle Save Row After Editing it
   * @param {Object} editForm Data
   * @param {Object} record Cell Data Record
   * @returns {void}
   */
  save = (editForm, record) => {
    const { handleStratusSave } = this.props;

    this.formRef.current.validateFields().then((values) => {
      handleStratusSave(record.id, values);
      this.setState({ editingId: '' });
    }).catch((error) => {
      console.log(error);
    });
  };

  /**
   * Handles Row Editing Upon Edit Button Click
   * @param {number} record Cell Record
   * @returns {void}
   */
  edit = (record) => {
    this.setState({ editingId: record });
  };

  /**
   * Handles Cancelation for Editing Upon Cancel Button Click
   * @returns {void}
   */
  cancel = () => this.setState({ editingId: '' })

  /**
   * On change File Upload
   * @param {Object} file Selected File Data
   * @returns {void}
   */
  onFileChange = (file) => {
    this.setState({ selectedFile: file });
    return false;
  };

  /**
   * Handles File Upload=
   * @returns {void}
   */
  selectedFileHandler = () => {
    const { uploadInterpreter, customer } = this.props;
    const { selectedFile } = this.state;
    const fd = new FormData();

    fd.append('patch[file]', selectedFile);
    uploadInterpreter(customer.interpreter.id, customer.id, fd);
  };

  /**
   * Column Constructor
   * @param {object} col Column
   * @returns {object} Title / Data Index / Editable Flag
   */
  prepareColumn = (col) => {
    const { isEditing } = this.props;
    return {
      title: col.title,
      dataIndex: col.dataIndex,
      editable: col.editable,
      /**
       * Cell Constructor
       * @param {object} record Data for Each Record
       * @returns {{dataIndex, inputType: (string), title, editing: (function(): *)}} Cell Structure
       */
      onCell(record) {
        return {
          inputType: col.dataIndex === 'age' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: () => isEditing(record),
        };
      },
    };
  }

  /**
   * Renders Mappings Table Columns
   * @returns {[void, void, {title: string, render: ((function(*, *=): *)|*)}]} Columns
   */
 columns = () => ([
   this.prepareColumn({
     title: 'Description',
     dataIndex: 'description',
     editable: true,
   }),
   this.prepareColumn({
     title: 'URI',
     dataIndex: 'uri',
     editable: true,
   }),
   {
     title: 'Actions',
     align: 'center',
     render: (text, record) => {
       const {
         isEditing, intl, disabled, handleStratusDelete, customer,
       } = this.props;
       const { editingId } = this.state;
       const editable = isEditing ? isEditing(record) : null;

       if (editable) {
         return (
           <div>
             <span>
               <EditableContext.Consumer>
                 {(editForm) => (
                   <Button
                     onClick={() => this.save(editForm, record)}
                     style={{ marginRight: 8 }}
                   >
                     <IntlMessages id="configuration.save" />
                   </Button>
                 )}
               </EditableContext.Consumer>
               <Popconfirm
                 title={intl.formatMessage({ id: 'configuration.customer.confirm_cancel' })}
                 onConfirm={() => this.cancel(record.id)}
               >
                 <Button>
                   <IntlMessages id="common.cancel" />
                 </Button>
               </Popconfirm>
             </span>
           </div>
         );
       }
       return (
         <div className="gx-d-flex gx-justify-content-around">
           <Button
             disabled={editingId.editingId !== '' || disabled}
             onClick={() => this.edit(record.id)}
           >
             <i className="icon icon-ckeditor" />
           </Button>
           <Popconfirm
             title={intl.formatMessage({ id: 'configuration.customer.confirm_delete' })}
             onConfirm={() => handleStratusDelete(customer.interpreter.id, customer.id, record.id)}
             disabled={disabled}
           >
             <Button title="Delete" disabled={disabled}>
               <i className="icon icon-trash" />
             </Button>
           </Popconfirm>
         </div>
       );
     },
   },
 ]);

  /**
   * Renders Radio Button
   * @param {object} interpreter Interprete Object with ID and Name
   * @returns {JSX.Element} Radio Button
   */
  interpreterToRadio = (interpreter) => {
    const { customer, disabled } = this.props;

    return (
      <Radio
        className="interpreter-setting"
        value={interpreter.id}
        checked={interpreter.id === (customer.interpreter.id || 0)}
        disabled={disabled}
        key={interpreter.id}
      >
        {interpreter.name}
      </Radio>
    );
  }

  /**
   * Render radio buttons section
   * @returns {JSX.Element} Radio Group
   */
  renderRadioGroup = () => {
    const { customer, interpreters, onChangeRadio } = this.props;

    return (
      <Radio.Group onChange={onChangeRadio} value={customer.interpreter.id}>
        {[{ id: null, name: 'None' }, ...interpreters].map(this.interpreterToRadio)}
      </Radio.Group>
    );
  }

  currentInterpreter = () => {
    const { customer, interpreters } = this.props;
    return interpreters
      ?.find((interpreter) => interpreter.id === customer.interpreter.id) || {};
  };

  /**
   * Renders In Demand Table
   * @returns {JSX.Element|null} In Demand Table | null
   */
  renderCodes = () => (this.currentInterpreter().name === CLOUDBREAK
      || this.currentInterpreter().name === STRATUS
      || this.currentInterpreter().name === LANGUAGELINE
      || this.currentInterpreter().name === LANGUAGELINEPSTN ? null
    : (
      <Table
        bordered
        className="gx-table-responsive"
        pagination={{ pageSize: this.currentInterpreter()?.codes?.page.size }}
        columns={this.codesColumns}
        dataSource={this.currentInterpreter()?.codes?.list}
        rowKey="id"
      />
    )
  )

  /**
   * Renders Cloudbreak ID Based on the Selected Interpreter
   * @returns {JSX.Element} Mappings
   */
  renderCloudbreakID = () => {
    const { intl } = this.props;
    return (this.currentInterpreter().name === INDEMAND
    || this.currentInterpreter().name === STRATUS
    || this.currentInterpreter().name === LANGUAGELINE
    || this.currentInterpreter().name === LANGUAGELINEPSTN ? null : (
      <>
        <Row className="baseLineAlignedItems" gutter={16}>
          <Col lg={12} md={12} sm={24} xs={24} className="gx-d-flex gx-align-items-center">
            <IntlMessages id="configuration.customer.cloudbreakId" />
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Input
              className="definedInCustomerAttr"
              autoComplete="off"
              readOnly
              bordered={false}
              value={intl.formatMessage({ id: 'configuration.customer.definedInCustomerAttr' })}
            />
          </Col>
        </Row>
      </>
      ));
  }

  /**
   * Returns content of Popover based on currentInterpreterName
   * @param {string} currentInterpreterName Current Interpreter Name
   * @returns {JSX.Element} Content of Popover
   */
  returnContent = (currentInterpreterName) => (
    <div>
      {currentInterpreterName === LANGUAGELINEPSTN ? (
        <>
          <p><IntlMessages id="configuration.customer.modal_content_phone" /></p>
          <h3><IntlMessages id="configuration.customer.example" /></h3>
          <p><IntlMessages id="configuration.customer.modal_content_phone_a" /></p>
          <p><IntlMessages id="configuration.customer.modal_content_phone_b" /></p>
          <p><IntlMessages id="configuration.customer.modal_content_phone_c" /></p>
        </>
      ) : (
        <>
          <p><IntlMessages id="configuration.customer.modal_content_uri" /></p>
          <h3><IntlMessages id="configuration.customer.example" /></h3>
          <p><IntlMessages id="configuration.customer.modal_content_uri_a" /></p>
          <p><IntlMessages id="configuration.customer.modal_content_uri_b" /></p>
          <p><IntlMessages id="configuration.customer.modal_content_uri_c" /></p>
        </>
      )}
    </div>
  );

  /**
    *  Render Mapping Based on the Selected Interpreter
    * @returns {JSX.Element} Mappings
    */
  renderMappings = () => {
    const {
      disabled, form, fetchInterpreter,
    } = this.props;

    return (this.currentInterpreter()?.name === INDEMAND
    || this.currentInterpreter()?.name === CLOUDBREAK ? null : (
      <>
        <Row className="baseLineAlignedItems interpreter-buttons-row" gutter={16}>
          <Col lg={24} md={24} sm={24} xs={24} className="gx-d-flex gx-align-items-center upload-box">
            <Row className="upload-box" gutter={16}>
              <Col lg={6} md={24} sm={24} xs={24}>
                <div>
                  <IntlMessages id="configuration.customer.interpreter_data" />
                </div>
              </Col>
              <Col lg={18} md={24} sm={24} xs={24}>
                <Upload
                  type="file"
                  accept=".csv"
                  beforeUpload={this.onFileChange}
                  maxCount={1}
                  className="upload-box"
                >
                  <Button className="upload-box" disabled={disabled}>
                    <IntlMessages id="configuration.bulkUpload.selectFile" />
                  </Button>
                </Upload>
              </Col>
            </Row>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24} className="gx-d-flex gx-align-items-center upload-box">
            <Row className="upload-box" gutter={16}>
              <Col className="horizontal-vertical-align" lg={2} md={2} sm={1} xs={1}>
                <Popover
                  content={this.returnContent(this.currentInterpreter()?.name)}
                  title={(
                    <h1>
                      <IntlMessages
                        id="configuration.customer.modal_title_upload"
                      />
                    </h1>
                  )}
                >
                  <QuestionCircleOutlined style={{
                    pointerEvents: `${disabled ? 'none' : 'all'}`,
                  }}
                  />
                </Popover>
              </Col>
              <Col lg={22} md={22} sm={23} xs={23} className="gx-d-flex gx-align-items-center">
                <Button
                  htmlType="submit"
                  className="sendButton2 upload-btn"
                  onClick={this.selectedFileHandler}
                  disabled={disabled}
                >
                  <IntlMessages id="sidebar.dataEntry.upload" />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <EditableContext.Provider value={form}>
          <Form ref={this.formRef} autoComplete="off">
            <Table
              bordered
              className="gx-table-responsive gx-mt-4"
              pagination={{
                pageSize: this.currentInterpreter()?.mappings?.page.size,
                total: this.currentInterpreter()?.mappings?.page.totalElements || 0,
                current: this.currentInterpreter()?.mappings?.page.number + 1 || 0,
                defaultCurrent: 1,
                onChange: (page) => fetchInterpreter(page - 1),
              }}
              columns={this.columns()}
              dataSource={this.currentInterpreter()?.mappings?.list}
              rowClassName="editable-row"
              rowKey="id"
            />
          </Form>
        </EditableContext.Provider>
      </>
      ));
  }

  /**
   * Renders InterpreterSettings
   * @returns {JSX.Element} InterpreterSettings
   */
  render() {
    const { readyMap, isNew } = this.props;

    return (
      <Card
        className="gx-card unit-edit-card"
        title={
          <IntlMessages id="configuration.customer.interpreter_settings" />
        }
        loading={!readyMap.interpreters}
      >
        <Row gutter={16}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <div>
              <IntlMessages id="configuration.customer.interpreter_service" />
            </div>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            {this.renderRadioGroup()}
          </Col>
        </Row>
        <Card loading={!isNew && !readyMap.selectedInterpreter} bordered={false}>
          {this.currentInterpreter()?.codes?.list && this.renderCodes()}
          {this.currentInterpreter()?.mappings?.list && this.renderMappings()}
          {this.currentInterpreter()?.mappings?.list && this.renderCloudbreakID()}
        </Card>
      </Card>
    );
  }
}

InterpreterSettings.defaultProps = {
  isNew: true,
  disabled: true,
};

InterpreterSettings.propTypes = {
  customer: PropTypes.shape().isRequired,
  readyMap: PropTypes.shape({
    interpreters: PropTypes.bool,
    selectedInterpreter: PropTypes.bool,
  }).isRequired,
  isNew: PropTypes.bool,
  intl: PropTypes.shape().isRequired,
  handleStratusSave: PropTypes.func.isRequired,
  uploadInterpreter: PropTypes.func.isRequired,
  isEditing: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  handleStratusDelete: PropTypes.func.isRequired,
  interpreters: PropTypes.shape().isRequired,
  onChangeRadio: PropTypes.func.isRequired,
  fetchInterpreter: PropTypes.func.isRequired,
};

export default injectIntl(InterpreterSettings);
