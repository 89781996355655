import {
  UPLOAD_SBAPS_DOCUMENT_REQUEST,
  UPLOAD_SBAPS_DOCUMENT_SUCCESS,
} from '@constants/UHEActionTypes';

/**
 * uploadSBAPSDocument action creator
 * @param {Object} file  file
 * @returns {Object} action
 */
export const uploadSBAPSDocument = (file) => ({
  type: UPLOAD_SBAPS_DOCUMENT_REQUEST,
  payload: {
    file,
  },
});

/**
 * uploadSBAPSDocumentSuccess action creator
 * @returns {Object} action
 */
export const uploadSBAPSDocumentSuccess = () => ({
  type: UPLOAD_SBAPS_DOCUMENT_SUCCESS,
});
