import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';
import QRCode from 'qrcode.react';
import PropTypes from 'prop-types';

import TechActionsCard from '@components/uhe/configuration/bedsCarts/TechActionsCard';
import SettingOptionsCard from '@components/uhe/configuration/bedsCarts/SettingOptionsCard';
import ActiveComponentsCard from '@components/uhe/configuration/bedsCarts/ActiveComponentsCard';
import {
  fetchBedCart,
  fetchDeviceQrCode,
  fetchDeviceAdditionalQrCode,
  fetchDeviceAction,
  saveUheBedCart,
  uploadBedCartPicture,
  fetchDeviceTypes,
} from '@uhe_actions/configuration/bedsCarts/bedsCartsActions';
import { factory_reset } from '@constants/UHEDeviceActions';
import { onGetOptions } from '@uhe_actions/filters/ListingsTopFilterActions';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import { FORM_LAYOUT, PROTOCOL_TYPES } from '@constants/UHESettings';
import { cascaderSearchFilter } from '@util/UheHelper';
import {
  Row, Col, Form, Card, Button, Modal, Cascader, Popover,
} from 'antd';
import { cloneDeep } from 'lodash';

const { confirm } = Modal;

const settingOptionslayout = {
  labelCol: {
    xl: { span: 12 },
    lg: { span: 12 },
    md: { span: 12 },
    sm: { span: 12 },
  },
  wrapperCol: {
    xl: { span: 12 },
    lg: { span: 12 },
    md: { span: 12 },
    sm: { span: 12 },
  },
};

/**
 * Technician Class Component
 */
class Technician extends Component {
  formRef = React.createRef();

  /**
   * Technician Component Constructor
   * @param {object} props Technician Component Props
   * @returns {void}
   */
  constructor(props) {
    super(props);
    this.props.onGetOrganizationOptions();
    const { setSubtitle, intl } = this.props;
    this.intl = intl;

    this.state = {
      organization_id: null,
      organization_name: '',
      customer_id: null,
      customer_name: '',
      facility_id: null,
      facility_name: '',
      unit_id: null,
      unit_name: '',
      bedCart_id: null,
      bedCart_name: '',
      bedCart_type: '',
      bedCart: {},
      resetModalIsVisible: false,
      deviceTypes: [],
      currentOptionsList: {
        organization: [],
        customer: [],
        facility: [],
        unit: [],
        bedCart: [],
      },
    };

    setSubtitle('configuration.bedsCarts.technician');
    this.onChangeOrganization = this.onChangeOrganization.bind(this);
    this.onChangeCustomer = this.onChangeCustomer.bind(this);
    this.onChangeFacility = this.onChangeFacility.bind(this);
    this.onChangeUnit = this.onChangeUnit.bind(this);
    this.onChangeBedCart = this.onChangeBedCart.bind(this);
    this.onChangeOption = this.onChangeOption.bind(this);
  }

  /**
 * Updates local state
 * @returns {void}
 */
  componentDidMount() {
    const {
      fetchDeviceTypes,
      optionsList
    } = this.props;
    fetchDeviceTypes();
    this.setState({
      currentOptionsList: optionsList,
    });
  }

  /**
 * Updates local state depends on props
 * @param {object} prevProps prevProps
 * @returns {void}
 */
  componentDidUpdate(prevProps) {
    const {
      deviceTypes,
      optionsList
    } = this.props;
    const {
      currentOptionsList,
    } = this.state;

    if (
      prevProps.deviceTypes.length === 0
      && deviceTypes.length > 0
    ) {
      this.setState({ deviceTypes });
    }
    if (JSON.stringify(optionsList) !== JSON.stringify(currentOptionsList)) {
      this.setState({
        currentOptionsList: optionsList,
      });
    }
  }

  /**
   * @description Handle Changes in the Organizations Cascader and Updates Local State
   * @param {Array} index
   * @param {Array} organizationArr
   * @returns {void}
   */
  onChangeOrganization(index, organizationArr) {
    const { onGetCustomerOptions } = this.props;

    if (organizationArr && organizationArr.length > 0) {
      this.setState(
        {
          organization_id: organizationArr[0].id,
          organization_name: organizationArr[0].name,
          customer_id: null,
          facility_id: null,
          unit_id: null,
          bedCart_id: null,
          bedCart: {},
        },
        () => onGetCustomerOptions(this.state.organization_id),
      );
    } else {
      this.setState({
        organization_id: null,
        customer_id: null,
        facility_id: null,
        unit_id: null,
        bedCart_id: null,
        bedCart: {},
      });
    }
  }

  /**
   * @description Handle Changes in the Customers Cascader and Updates Local State
   * @param {Array} index
   * @param {Array} customerArr
   * @returns {void}
   */
  onChangeCustomer(index, customerArr) {
    if (customerArr && customerArr.length > 0) {
      const { onGetFacilityOptions } = this.props;

      this.setState(
        {
          customer_id: customerArr[0].id,
          customer_name: customerArr[0].name,
          facility_id: null,
          unit_id: null,
          bedCart_id: null,
          bedCart: {},
        },
        () => onGetFacilityOptions(this.state.customer_id),
      );
    } else {
      this.setState({
        customer_id: null,
        facility_id: null,
        unit_id: null,
        bedCart_id: null,
        bedCart: {},
      });
    }
  }

  /**
   * @description Handle Changes in the Facilities Cascader and Updates Local State
   * @param {Array} index
   * @param {Array} facilityArr
   * @returns {void}
   */
  onChangeFacility(index, facilityArr) {
    if (facilityArr && facilityArr.length > 0) {
      const { onGetUnitOptions } = this.props;

      this.setState(
        {
          facility_id: facilityArr[0].id,
          facility_name: facilityArr[0].name,
          unit_id: null,
          bedCart_id: null,
          bedCart: {},
        },
        () => onGetUnitOptions(this.state.facility_id),
      );
    } else {
      this.setState({
        facility_id: null,
        unit_id: null,
        bedCart_id: null,
        bedCart: {},
      });
    }
  }

  /**
   * @description Handle Changes in the Units Cascader and Updates Local State
   * @param {Array} index
   * @param {Array} unitArr
   * @returns {void}
   */
  onChangeUnit(index, unitArr) {
    if (unitArr && unitArr.length > 0) {
      const { onGetBedCartOptions } = this.props;

      this.setState({
        unit_id: unitArr[0].id,
        unit_name: unitArr[0].name,
        bedCart_id: null,
        bedCart: {},
      },
      () => onGetBedCartOptions(this.state.unit_id));
    } else {
      this.setState({
        unit_id: null,
        bedCart_id: null,
        bedCart: {},
      });
    }
  }

  /**
   * @description Handle Changes in the Room/Bed Cascader and Updates Local State
   * @param {Array} index
   * @param {Array} unitArr
   * @returns {void}
   */
  onChangeBedCart(index, bedCartArr) {
    if (bedCartArr && bedCartArr.length > 0) {
      const {
        fetchDeviceQrCode,
        fetchBedCart,
        fetchDeviceAdditionalQrCode,
        fetchDeviceTypes,
      } = this.props;
      fetchDeviceTypes();
      fetchDeviceQrCode(bedCartArr[0].device_id);
      fetchBedCart({ id: bedCartArr[0].device_id });
      fetchDeviceAdditionalQrCode(bedCartArr[0].device_id);

      this.setState({
        bedCart: {},
        bedCart_id: bedCartArr[0].device_id,
        bedCart_name: bedCartArr[0].cart_name,
        bedCart_type: bedCartArr[0].type,
      });
    } else {
      this.setState({
        bedCart_id: null,
        bedCart: {},
      });
    }
  }

  /**
   * @description Handle Changes on Each Setting Options Dropdown
   * @param {number} vol
   * @param {string} key
   * @returns {void}
   */
  onChangeOption(vol, key) {
    const { bedCart, bedCart_id } = this.state;

    if (bedCart && Object.entries(bedCart).length > 0) {
      const changedBedCart = { ...bedCart, [key]: parseInt(vol) };
      this.setState({ bedCart: changedBedCart });
      this.props.saveUheBedCartEdit({
        body: changedBedCart,
        id: bedCart_id,
      });
    }
  }

  /**
   * @description Checks for selected value and Updates Local State
   * @param {Array} index
   * @param {Array} unitArr
   * @returns {void}
   */
  selectDevice = () => {
    const { bedCart_id } = this.state;

    if (bedCart_id && Object.entries(this.props.bedCart).length > 0) {
      this.setState({
        bedCart: this.props.bedCart,
      });

      this.formRef.current.setFieldsValue({
        mic: this.props.bedCart.default_input,
        speaker: this.props.bedCart.default_output,
        bell: this.props.bedCart.default_bell,
        camSpeed: this.props.bedCart.default_speed,
      });
    }
  }

  /**
   * @description Show Confirmation Modal When Factory Reset Button Being Clicked
   * @returns {void}
   */
  showConfirm = () => {
    confirm({
      title: this.intl.formatMessage({ id: 'configuration.technician.confirmResetMsg' }),
      onOk: this.factoryReset,
      autoFocusButton: 'cancel',
    });
  };

  /**
  * @description Sends Factory Reset command
  * @param {string} bedCart.machine_name
  * @param {string} command
  * @param {string} params_p
  * @returns {void}
  */
  factoryReset = () => {
    const { bedCart, doFetchDeviceAction, intl } = this.props;
    const { bedCart_id } = this.state;

    doFetchDeviceAction({
      machine_name: bedCart.machine_name,
      command: factory_reset,
      id: bedCart_id,
    });
  }

  renderQrCode = () => {
    const { qrCode } = this.props;
    let qrString = '';

    if (Object.entries(this.state.bedCart).length === 0) {
      return null;
    }

    if (Object.entries(qrCode).length > 0) {
      for (const [key, value] of Object.entries(qrCode)) {
        qrString += `${key}=${value},`;
      }

      qrString = qrString.substring(0, qrString.length - 1);

      return (
        <QRCode value={qrString} renderAs="svg" />
      );
    }

    return null;
  }

  renderAdditionalQrCode = () => {
    const { qrAdditionalCode } = this.props;
    let additionalQrString = '';

    if (Object.entries(this.state.bedCart).length === 0) {
      return null;
    }

    if (Object.entries(qrAdditionalCode).length > 0) {
      for (const [key, value] of Object.entries(qrAdditionalCode)) {
        additionalQrString += `${key}=${value},`;
      }

      additionalQrString = additionalQrString.substring(0, additionalQrString.length - 1);

      return (
        <QRCode value={additionalQrString} renderAs="svg" />
      );
    }

    return null;
  }

  renderAlertUrl = () => {
    const { bedCart } = this.state;
    if (bedCart && Object.entries(bedCart).length > 0) {
      if (bedCart.elert_url) {
        return (
          bedCart.elert_url
        );
      }
    }

    return (
      <IntlMessages id="configuration.technician.notConfigured" />
    );
  }

  /**
  * Render Device Type
  * @param {string} bedCart.endpoint_type device type
  * @returns {void}
  */
  renderDeviceType = () => {
    const { bedCart } = this.state;
    if (bedCart && Object.entries(bedCart).length > 0) {
      if (bedCart.endpoint_type) {
        return (
          bedCart.endpoint_type
        );
      }
    }

    return (
      <IntlMessages id="configuration.technician.notConfigured" />
    );
  }

  /**
   * @description Renders Network Type
   * @returns {JSX.Element}
   */
  renderNetworkType = () => {
    const { bedCart } = this.state;

    switch (bedCart?.protocol) {
      case PROTOCOL_TYPES.staticIp:
        return <IntlMessages id="configuration.technician.staticIP" />;

      case PROTOCOL_TYPES.wifi:
        return <IntlMessages id="configuration.technician.wiFiStandard" />;

      case PROTOCOL_TYPES.enterpriseWifi:
        return <IntlMessages id="configuration.technician.wiFiEnterprise" />;

      default:
        return <IntlMessages id="configuration.technician.defaultNetwork" />;
    }
  }

  renderSettingOptions = () => {
    const { bedCart } = this.state;
    const { loading } = this.props;

    if (Object.entries(bedCart).length === 0) {
      return null;
    }

    return (
      <SettingOptionsCard
        bedCart={bedCart}
        onChangeOption={this.onChangeOption}
        loading={loading}
        layout={settingOptionslayout}
      />
    );
  }

  /**
   * Check for can_hard_reboot property property for device
   * @returns {boolean} true||false
   */
  canHardReboot = () => {
    const { bedCart } = this.state;
    const { deviceTypes } = this.props;
    let canReboot = false;
    if (deviceTypes.length > 0) {
      deviceTypes.forEach((device, index) => {
        if (device.value === bedCart.endpoint_type && device.can_hard_reboot !== 1) {
          canReboot = true;
        }
      });
    }
    return canReboot;
  }

  renderTechActions = () => {
    const { bedCart, bedCart_id } = this.state;
    const { loading } = this.props;

    if (Object.entries(bedCart).length === 0) {
      return null;
    }

    return (
      <TechActionsCard
        bedCart={bedCart}
        loading={loading}
        bedCartId={bedCart_id}
        canHardReboot={this.canHardReboot()}
      />
    );
  }

  renderDetails = () => {
    const { bedCart, bedCart_name } = this.state;

    if (Object.entries(bedCart).length === 0) {
      return null;
    }

    return (
      <Card
        title="Details"
        className="tech-card gx-card"
      >
        <Row gutter={[16, 20]} className="form-item-row">
          <Col lg={12} md={12} sm={12} xs={12}>
            <div className="tech-cell">
              <span className="tech-prop">
                <IntlMessages id="configuration.technician.deviceName" />
              </span>
            </div>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <div className="tech-cell overflow-wrap-break-word">
              <span>{bedCart_name}</span>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 20]} className="form-item-row">
          <Col lg={12} md={12} sm={12} xs={12}>
            <div className="tech-cell">
              <span className="tech-prop">
                <IntlMessages id="configuration.technician.deviceType" />
              </span>
            </div>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <div className="tech-cell">
              <span>{this.renderDeviceType()}</span>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 20]} className="form-item-row">
          <Col lg={12} md={12} sm={12} xs={12}>
            <div className="tech-cell">
              <span className="tech-prop">
                <IntlMessages id="configuration.technician.networkType" />
              </span>
            </div>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <div className="tech-cell">
              <span>
                {this.renderNetworkType()}
              </span>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 20]} className="form-item-row">
          <Col lg={12} md={12} sm={12} xs={12}>
            <div className="tech-cell">
              <span className="tech-prop">
                <IntlMessages id="configuration.technician.alertUrl" />
              </span>
            </div>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <div className="tech-cell overflow-wrap-break-word">
              <span>
                {this.renderAlertUrl()}
              </span>
            </div>
          </Col>
        </Row>
      </Card>
    );
  }

  renderResetCard = () => {
    const { bedCart, resetModalIsVisible } = this.state;

    if (Object.entries(bedCart).length === 0) {
      return null;
    }

    return (
      <Card
        title="Reset"
        className="tech-card gx-card"
      >
        <Row gutter={[16, 20]} className="form-item-row">
          <Col lg={12} md={12} sm={12} xs={24} className="tech-cell gx-p-0">
            <span className="margin-left-8 tech-prop">
              <IntlMessages id="configuration.bedsCarts.actions.factoryReset" />
            </span>
          </Col>
          <Col lg={12} md={12} sm={12} xs={24} className="reset-button reset-col">
            <Popover content={<IntlMessages id="configuration.bedsCarts.actions.factoryResetDescription" />}>
              <Button
                className="tech-button"
                onClick={this.showConfirm}
              >
                <IntlMessages id="configuration.bedsCarts.actions.reset" />
              </Button>
            </Popover>
          </Col>
        </Row>
      </Card>
    );
  }

  renderPicturesRoom = () => {
    const { bedCart, bedCart_id } = this.state;
    const { loading } = this.props;

    if (Object.entries(bedCart).length === 0 || !bedCart_id) {
      return null;
    }

    return (
      <Card
        loading={loading}
        className="gx-card"
        title={
          <IntlMessages id="configuration.bedsCarts.activeComponents.title" />
        }
      >
        <ActiveComponentsCard bedCart={bedCart} bedCartId={bedCart_id} imgColSize={4} />
      </Card>
    );
  }

  renderQrCard = () => {
    const { bedCart } = this.state;

    if (Object.entries(bedCart).length === 0) {
      return null;
    }

    return (
      <Card
        className="tech-card gx-card gx-justify-content-center gx-align-items-center"
      >
        <div className="qr-container gx-align-items-center gx-justify-content-center gx-align-items-center">
          {this.renderQrCode()}
          {this.renderAdditionalQrCode()}
        </div>
      </Card>
    );
  }

  /**
   * Renders Technician Component
   * @returns {JSX.Element} Technician Component
   */
  render() {
    const {
      organization_id,
      customer_id,
      facility_id,
      unit_id,
      bedCart_id,
      currentOptionsList
    } = this.state;

    return (
      <div className="manage-customer-wrapper uheBedCart dashboard configuration">
        <Form ref={this.formRef} {...FORM_LAYOUT} name="uheBedCart">
          <Card
            className="filter-boxes gx-card"
            title={<IntlMessages id="uhe.title.filters" />}
          >
            <div className="listings-top-filter">
              <div className="filter-inputs">
                <Row gutter={[16, 20]}>
                  <Col lg={4} md={4} sm={24} xs={24}>
                    <Cascader
                      getPopupContainer={(el) => el.parentNode}
                      expandTrigger="hover"
                      showSearch={{ filter: cascaderSearchFilter }}
                      size="large"
                      value={organization_id || ''}
                      options={currentOptionsList.organization || []}
                      fieldNames={{ label: 'name', value: 'id' }}
                      onChange={this.onChangeOrganization}
                      placeholder={this.intl.formatMessage({
                        id: 'configuration.technician.organization',
                      })}
                    />
                  </Col>
                  <Col lg={4} md={4} sm={24} xs={24}>
                    <Cascader
                      getPopupContainer={(el) => el.parentNode}
                      expandTrigger="hover"
                      showSearch={{ filter: cascaderSearchFilter }}
                      size="large"
                      value={customer_id || ''}
                      options={currentOptionsList.customer || []}
                      fieldNames={{ label: 'name', value: 'id' }}
                      onChange={this.onChangeCustomer}
                      changeOnSelect
                      ref={this.customerDropdown}
                      id="customerDropdown"
                      disabled={!organization_id}
                      placeholder={this.intl.formatMessage({
                        id: 'configuration.technician.customer',
                      })}
                    />
                  </Col>
                  <Col lg={4} md={4} sm={24} xs={24}>
                    <Cascader
                      getPopupContainer={(el) => el.parentNode}
                      expandTrigger="hover"
                      showSearch={{ filter: cascaderSearchFilter }}
                      size="large"
                      value={facility_id || ''}
                      options={currentOptionsList.facility || []}
                      fieldNames={{ label: 'name', value: 'id' }}
                      onChange={this.onChangeFacility}
                      changeOnSelect
                      disabled={!customer_id}
                      ref={this.facilityDropdown}
                      id="facilityDropdown"
                      placeholder={this.intl.formatMessage({
                        id: 'configuration.technician.facility',
                      })}
                    />
                  </Col>
                  <Col lg={4} md={4} sm={24} xs={24}>
                    <Cascader
                      getPopupContainer={(el) => el.parentNode}
                      expandTrigger="hover"
                      showSearch={{ filter: cascaderSearchFilter }}
                      size="large"
                      value={unit_id || ''}
                      options={currentOptionsList.unit || []}
                      fieldNames={{ label: 'name', value: 'id' }}
                      onChange={this.onChangeUnit}
                      changeOnSelect
                      ref={this.unitDropdown}
                      id="unitDropdown"
                      disabled={!facility_id}
                      placeholder={this.intl.formatMessage({
                        id: 'configuration.technician.unit',
                      })}
                    />
                  </Col>
                  <Col lg={4} md={4} sm={24} xs={24}>
                    <Cascader
                      getPopupContainer={(el) => el.parentNode}
                      expandTrigger="hover"
                      showSearch={{ filter: cascaderSearchFilter }}
                      size="large"
                      value={bedCart_id || ''}
                      options={currentOptionsList.bedCart || []}
                      fieldNames={{ label: 'cart_name', value: 'device_id' }}
                      changeOnSelect
                      ref={this.bedcartDropdown}
                      id="bedcartDropdown"
                      onChange={this.onChangeBedCart}
                      disabled={!unit_id}
                      placeholder={this.intl.formatMessage({
                        id: 'configuration.technician.bedCart',
                      })}
                    />
                  </Col>
                  <Col lg={4} md={4} sm={24} xs={24}>
                    <Button
                      className="ant-btn reset-btn clear-filter-btn"
                      onClick={this.selectDevice}
                      disabled={!this.state.bedCart_id && !this.props.loading}
                    >
                      <IntlMessages id="configuration.technician.selectDevice" />
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Card>
          <Row gutter={16}>
            <Col lg={8} md={24} sm={24} xs={24}>
              {this.renderDetails()}
              {this.renderQrCard()}
              {this.renderTechActions()}
            </Col>
            <Col lg={16} md={24} sm={24} xs={24} className="tech-container">
              {this.renderSettingOptions()}
              {this.renderResetCard()}
              {this.renderPicturesRoom()}
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

Technician.defaultProps = {
  bedCart: {},
  deviceTypes: [],
  loading: true,
};

Technician.propTypes = {
  bedCart: PropTypes.object,
  intl: PropTypes.object,
  loading: PropTypes.bool,
  fetchBedCart: PropTypes.func,
  fetchDeviceQrCode: PropTypes.func,
  fetchBedCart: PropTypes.func,
  fetchDeviceAdditionalQrCode: PropTypes.func,
  onGetOrganizationOptions: PropTypes.func,
  onGetCustomerOptions: PropTypes.func,
  onGetFacilityOptions: PropTypes.func,
  onGetUnitOptions: PropTypes.func,
  onGetBedCartOptions: PropTypes.func,
  canHardReboot: PropTypes.func,
  fetchDeviceTypes: PropTypes.func,
  deviceTypes: PropTypes.array,
};

/**
 * Maps State to Props
 * @param {object} bedsCarts Bed Carts Data
 * @param {object} common Common Data
 * @param {object} subtitle Subtitle Data
 * @param {object} listingsTopFilter Top Filters Data
 * @returns {object} Data from the Store
 */
const mapStateToProps = ({
  bedsCarts,
  common,
  subtitle,
  listingsTopFilter,
}) => ({
  error: common.error,
  bedCart: bedsCarts.selectedBedCart,
  selectedBedCart: bedsCarts,
  qrCode: bedsCarts.qrData,
  qrAdditionalCode: bedsCarts.qrAdditionalData,
  loading: bedsCarts.loading,
  subtitle,
  optionsList: listingsTopFilter,
  deviceTypes: bedsCarts.deviceTypes,
});

/**
 * Maps Actions to Props
 * @param {function} dispatch Dispatches Actions
 * @returns {object} Actions
 */
const mapDispatchToProps = (dispatch) => ({
  onGetOrganizationOptions: () => dispatch(onGetOptions('organization')),
  onGetCustomerOptions: (id) => dispatch(onGetOptions('customer', id)),
  onGetFacilityOptions: (id) => dispatch(onGetOptions('facility', id)),
  onGetUnitOptions: (id) => dispatch(onGetOptions('unit', id)),
  onGetBedCartOptions: (id) => dispatch(onGetOptions('bedCart', id)),
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  fetchBedCart: (id) => dispatch(fetchBedCart(id)),
  fetchDeviceQrCode: (id) => dispatch(fetchDeviceQrCode(id)),
  fetchDeviceAdditionalQrCode: (id) => dispatch(fetchDeviceAdditionalQrCode(id)),
  doFetchDeviceAction: (data) => dispatch(fetchDeviceAction(data)),
  saveUheBedCartEdit: (data) => dispatch(saveUheBedCart(data)),
  uploadBedCartPicture: (file, id) => dispatch(uploadBedCartPicture(file, id)),
  fetchDeviceTypes: (data) => dispatch(fetchDeviceTypes(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withRouter(Technician)));
