import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, Button, Popover } from 'antd';

import CustomScrollbars from 'util/CustomScrollbars';
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from '@constants/ThemeSetting';
import IntlMessages from '@util/IntlMessages';
import { useDispatch, useSelector } from 'react-redux';
import {
  ExceptionOutlined,
  DesktopOutlined,
  ExclamationCircleOutlined,
  FundViewOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import {
  onNavStyleChange,
  toggleCollapsedSideNav,
} from 'appRedux/actions/Setting';
import {
  shouldRenderNotificationsLink,
  shouldRenderMonitoringLink,
  shouldRenderRoomBedLink,
  shouldRenderUnitLink,
  shouldRenderFacilityLink,
  shouldRenderCustomerLink,
  shouldRenderOrganizationLink,
  shouldRenderUsersLink,
  shouldRenderSSOProvidersLink,
  shouldRenderReportingLink,
  shouldRenderSystemLink,
  shouldRenderIObserverLink,
  shouldRenderTechnicianLink,
  shouldRenderDeveloperLink,
  shouldRenderSettingsLink,
  isTechnicianAccess,
  shouldRenderProgramsLink,
  shouldRenderAdvancedDevMenuItem,
} from '@util/UheRoleChecker';
import { useIntl } from 'react-intl';
import { shouldResetPagination } from '@actions';
import { getUserPreferences } from '@uhe_actions/configuration/users/UsersActions';
import { Logo } from 'uhe-corporate-identity-lib';
import Customizer from '@containers/Customizer';

import nav_configuration from '@assets/images/nav_configuration.svg';
import nav_configuration_on from '@assets/images/nav_configuration_on.svg';
import nav_monitor from '@assets/images/nav_monitor.svg';
import nav_monitor_on from '@assets/images/nav_monitor_on.svg';
import nav_system from '@assets/images/nav_system.svg';
import nav_system_on from '@assets/images/nav_system_on.svg';
import logoImg from '@assets/images/c-logo.png';
import { settings } from 'nprogress';

const { SubMenu } = Menu;
/**
 * Handles sidebar menu
 * @returns {JSX} render
 */
const SidebarContent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    width, navCollapsed, themeType, pathname,
  } = useSelector(
    ({ settings }) => settings,
  );

  const [closedSubmenu, setClosedSubmenu] = useState({
    monitoring: true,
    configuration: true,
    bedsCarts: true,
    system: true,
    'system/settings': true,
    reporting: true,
  });
  const [closedBedCartsCollapsed, setClosedBedCartsCollapsed] = useState(true);
  const [closedSystemSettingsCollapsed, setClosedSystemSettingsCollapsed] = useState(true);

  const loggedUser = useSelector(({ ConfigurationUsers }) => ConfigurationUsers.ownUser);

  const [isCaregilitySystemAdmin, setCaregilitySystemAdmin] = useState(false);
  const [isUserAdmin, setUserAdmin] = useState(false);
  const [isCaregilityAdmin, setCaregilityAdmin] = useState(false);
  const [isOrganizationAdmin, setOrganizationAdmin] = useState(false);
  const [isCustomerAdmin, setCustomerAdmin] = useState(false);
  const [isFacilityAdmin, setFacilityAdmin] = useState(false);
  const [isUnitAdmin, setUnitAdmin] = useState(false);
  const [isTechnicianAdmin, setTechnicianAdmin] = useState(false);
  const [isTechnician, setTechnician] = useState(false);
  let navStyle = useSelector(({ settings }) => settings.navStyle);

  useEffect(() => {
    if (localStorage.getItem('sidebar-options')) {
      setClosedSubmenu(JSON.parse(localStorage.getItem('sidebar-options')));
    }
    if (loggedUser.roles) {
      const caregilitySystemAdmin = loggedUser.isCaregilitySystemAdmin;
      const userAdmin = loggedUser.isUserAdmin;
      const caregilityAdmin = loggedUser.isCaregilityAdmin;
      const customerAdmin = loggedUser.isCustomerAdmin;
      const organizationAdmin = loggedUser.isOrganizationAdmin;
      const facilityAdmin = loggedUser.isFacilityAdmin;
      const unitAdmin = loggedUser.isUnitAdmin;
      const technicianAdmin = loggedUser.isTechnicianAdmin;
      const technician = loggedUser.isTechnician;

      if (caregilitySystemAdmin && !isCaregilitySystemAdmin) {
        setCaregilitySystemAdmin(true);
      }

      if (userAdmin && !isUserAdmin) {
        setUserAdmin(true);
      }

      if (caregilityAdmin && !isCaregilityAdmin) {
        setCaregilityAdmin(true);
      }

      if (customerAdmin && !isCustomerAdmin) {
        setCustomerAdmin(true);
      }

      if (organizationAdmin && !isOrganizationAdmin) {
        setOrganizationAdmin(true);
      }

      if (facilityAdmin && !isFacilityAdmin) {
        setFacilityAdmin(true);
      }

      if (unitAdmin && !isUnitAdmin) {
        setUnitAdmin(true);
      }

      if (technicianAdmin && !isTechnicianAdmin) {
        setTechnicianAdmin(true);
      }

      if (technician && !isTechnician) {
        setTechnician(true);
      }
    }

    dispatch(getUserPreferences());
  }, []);

  useEffect(() => {
    setClosedSystemSettingsCollapsed(true);
    setClosedBedCartsCollapsed(true);
  }, [navStyle]);

  useEffect(() => {
    localStorage.setItem('sidebar-options', JSON.stringify(closedSubmenu));
  }, [closedSubmenu]);

  /**
   * Triggers pagination reset.
   * @returns {void} void
   */
  const paginationReset = () => {
    dispatch(shouldResetPagination());
  };

  /**
   * Fix menu arrows
   * @param {string} key,
   * @returns {object} any
   */
  const changeMenu = (key) => {
    if (navStyle === 'NAV_STYLE_FIXED') {
      setClosedSubmenu((state) => ({
        ...state,
        [key]: !closedSubmenu[key],
      }));
    }

    if (navStyle === 'NAV_STYLE_DRAWER') {
      setClosedSubmenu((state) => ({
        ...state,
        [key]: !closedSubmenu[key],
      }));
    }
  };

  /**
  * Render Developer Link
  * @returns {JSX} render
  */
  const renderDeveloperLink = () => {
    if (loggedUser.roles) {
      if (shouldRenderDeveloperLink(loggedUser)) {
        return (
          <Menu.Item
            key="system/developer"
            title={<IntlMessages id="sidebar.developer" />}
          >
            <Link to={`/system/developer#${Math.random().toFixed(3)}`}>
              <DesktopOutlined id="developer-link-side-icon" />
              <IntlMessages id="sidebar.developer" />
            </Link>
          </Menu.Item>
        );
      }
    }

    return null;
  };

  /**
  * Render Developer Link
  * @returns {JSX} render
  */
  const renderTechnicianLink = () => {
    if (loggedUser.roles) {
      if (shouldRenderTechnicianLink(loggedUser)) {
        return (
          <Menu.Item
            key="technician"
            title={<IntlMessages id="sidebar.technician" />}
          >
            <Link to="/technician">
              <i className="icon icon-social support-icon" />
              <IntlMessages id="sidebar.technician" />
            </Link>
          </Menu.Item>
        );
      }
    }

    return null;
  };

  /**
  * Renders iObserver MenuItem
  * @returns {JSX.Element} IObserver Menu Link
  */
  const renderiObserverMenuItem = () => {
    if (loggedUser.roles && shouldRenderIObserverLink(loggedUser)) {
      return (
        <Menu.Item
          key="system/settings/iobserver"
        >
          <Link to="/system/settings/iobserver">
            <FundViewOutlined className="iobserver-icon" id="iobserver-side-icon" />
            <IntlMessages id="sidebar.iobserver" />
          </Link>
        </Menu.Item>
      );
    }

    return null;
  };

  /**
  * Renders Announcements MenuItem
  * @returns {JSX.Element} Announcements Menu Link
  */
  const renderAnnouncementsMenuItem = () => {
    if (loggedUser.roles && shouldRenderIObserverLink(loggedUser)) {
      return (
        <Menu.Item
          key="system/settings/announcements"
        >
          <Link to="/system/settings/announcements">
            <i className="icon icon-lising-dbrd" />
            <IntlMessages id="sidebar.announcements" />
          </Link>
        </Menu.Item>
      );
    }

    return null;
  };

  /**
  * Renders Settings System MenuItem
  * @returns {JSX.Element} System Menu Link
  */
  const renderSettingsSystemMenuItem = () => {
    if (loggedUser.roles && shouldRenderSystemLink(loggedUser)) {
      return (
        <Menu.Item
          key="system/settings/system"
        >
          <Link to="/system/settings/system">
            <i className="icon icon-components" />
            <IntlMessages id="sidebar.system" />
          </Link>
        </Menu.Item>
      );
    }

    return null;
  };

  /**
* Renders Settings Advanced Dev Tools MenuItem
* @returns {JSX.Element} Advanced Dev Tools Menu Link
*/
  const renderSettingsAdvancedDevToolsMenuItem = () => {
    if (loggedUser.roles && shouldRenderSystemLink(loggedUser)) {
      return (
        <Menu.Item
          key="system/settings/advanced-dev"
        >
          <Link to="/system/settings/advanced-dev">
            <i className="icon icon-wysiwyg" />
            <IntlMessages id="sidebar.advancedDevTools" />
          </Link>
        </Menu.Item>
      );
    }

    return null;
  };

  /**
  * Render Reporting Submenu
  * @returns {JSX} render
  */
  const renderReportingSubmenu = () => {
    const isReporting = location.pathname.includes('/reporting');
    if (loggedUser.roles) {
      if (shouldRenderReportingLink(loggedUser)) {
        return (
          <SubMenu
            className="reporting-submenu"
            popupClassName={navStyle === 'NAV_STYLE_FIXED' ? 'main-menu-expand' : 'main-menu-collapsed'}
            key="reporting"
            onTitleClick={({ key }) => (changeMenu(key))}
            title={(
              <span className={`gx-d-flex group-item ${isReporting ? 'on' : ''}`}>
                <i className="icon icon-copy" />
                <IntlMessages id="sidebar.reporting" />
                <span className={navStyle === NAV_STYLE_FIXED ? 'menu-msg' : 'menu-msg-collapsed'}>
                  <i className={closedSubmenu.reporting ? 'icon icon-chevron-right gx-ml-5' : 'icon icon-chevron-down gx-ml-5'} />
                </span>
              </span>
            )}
          >
            <Menu.Item
              key="reporting/sign-in-summary"
            >
              <Link to={`/reporting/sign-in-summary#${Math.random().toFixed(3)}`}>
                <i className="icon icon-signin" />
                <IntlMessages id="sidebar.signIn" />
              </Link>
            </Menu.Item>
            <Menu.Item
              key="reporting/vmr-availability"
            >
              <Link to="/reporting/vmr-availability">
                <i className="icon icon-chart" />
                <IntlMessages id="sidebar.vmr" />
              </Link>
            </Menu.Item>
          </SubMenu>
        );
      }
    }

    return null;
  };

  /**
  * Render SSO Providers Link
  * @returns {JSX} render
  */
  const renderSSOProvidersLink = () => {
    if (loggedUser.roles) {
      if (shouldRenderSSOProvidersLink(loggedUser)) { }
    }

    return null;
  };

  /**
  * Render Users MenuItem
  * @returns {JSX} render
  */
  const renderUsersMenuItem = () => {
    if (loggedUser.roles) {
      if (shouldRenderUsersLink(loggedUser)) {
        return (
          <Menu.Item
            key="configuration/users"
          >
            <Link to={`/configuration/users#${Math.random().toFixed(3)}`} onClick={paginationReset}>
              <i className="icon icon-avatar" />
              <IntlMessages id="sidebar.users" />
            </Link>
          </Menu.Item>
        );
      }
    }

    return null;
  };

  /**
  * Render Organizations MenuItem
  * @returns {JSX} render
  */
  const renderOrganizationsMenuItem = () => {
    if (loggedUser.roles) {
      if (shouldRenderOrganizationLink(loggedUser)) {
        return (
          <Menu.Item
            key="configuration/organizations"
          >
            <Link to={`/configuration/organizations#${Math.random().toFixed(3)}`} onClick={paginationReset}>
              <i className="icon icon-radiobutton" />
              <IntlMessages id="sidebar.organizations" />
            </Link>
          </Menu.Item>
        );
      }
    }

    return null;
  };

  /**
  * Render Customers MenuItem
  * @returns {JSX} render
  */
  const renderCustomersMenuItem = () => {
    if (loggedUser.roles) {
      if (shouldRenderCustomerLink(loggedUser)) {
        return (
          <Menu.Item
            key="configuration/customers"
          >
            <Link to={`/configuration/customers#${Math.random().toFixed(3)}`} onClick={paginationReset}>
              <i className="icon icon-auth-screen" />
              <IntlMessages id="sidebar.customers" />
            </Link>
          </Menu.Item>
        );
      }
    }

    return null;
  };

  /**
  * Render Facilities MenuItem
  * @returns {JSX} render
  */
  const renderFacilitiesMenuItem = () => {
    if (loggedUser.roles) {
      if (shouldRenderFacilityLink(loggedUser)) {
        return (
          <Menu.Item
            key="configuration/facilities"
          >
            <Link to={`/configuration/facilities#${Math.random().toFixed(3)}`} onClick={paginationReset}>
              <i className="icon icon-location" />
              <IntlMessages id="sidebar.facilities" />
            </Link>
          </Menu.Item>
        );
      }
    }

    return null;
  };

  /**
  * Render Practice MenuItem
  * @returns {JSX} render
  */
  const renderUnitPracticeMenuItem = () => {
    if (loggedUser.roles) {
      if (shouldRenderUnitLink(loggedUser)) {
        return (
          <Menu.Item
            key="configuration/units"
          >
            <Link to={`/configuration/units#${Math.random().toFixed(3)}`} onClick={paginationReset}>
              <i className="icon icon-company" />
              <IntlMessages id="sidebar.units" />
            </Link>
          </Menu.Item>
        );
      }
    }

    return null;
  };

  /**
  * Render Room Bed Submenu
  * @returns {JSX} render
  */
  const renderRoomBedSubmenu = () => {
    if (loggedUser.roles) {
      if (shouldRenderRoomBedLink(loggedUser)) {
        return (
          <SubMenu
            key="bedsCarts"
            popupClassName={navStyle === 'NAV_STYLE_FIXED' ? 'main-menu-expand' : 'main-menu-collapsed'}
            onTitleClick={({ key }) => (changeMenu(key))}
            title={(
              <span className="gx-d-flex">
                <i className="icon icon-map-traffic-layer gx-d-flex gx-align-items-center" />
                <span className="bedcarts">
                  <IntlMessages id="sidebar.bedsCarts" />
                </span>
                <i className={closedSubmenu.bedsCarts ? 'icon icon-chevron-right gx-ml-5' : 'icon icon-chevron-down gx-ml-5'} />
              </span>
            )}
          >
            <Menu.Item
              key="configuration/beds-carts/uhe-units"
            >
              <Link to={`/configuration/beds-carts/uhe-units#${Math.random().toFixed(3)}`} onClick={paginationReset}>
                <i className="icon icon-radiobutton" />
                <IntlMessages id="sidebar.APS" />
                <Popover className="menu-item-info gx-ml-2" overlayClassName={navStyle !== 'NAV_STYLE_MINI_SIDEBAR' ? 'tooltip-info' : 'tooltip-info tooltip-collapsed'} placement="right" content={<IntlMessages id="sidebar.APS.info" />}>
                  <Button
                    icon={<InfoCircleOutlined />}
                    ghost
                    type="text"
                  />
                </Popover>
              </Link>
            </Menu.Item>
            <Menu.Item
              key="configuration/beds-carts/non-uhe-units"
            >
              <Link to={`/configuration/beds-carts/non-uhe-units#${Math.random().toFixed(3)}`} onClick={paginationReset}>
                <i className="icon icon-auth-screen" />
                <IntlMessages id="sidebar.SBAPS" />
                <Popover className="menu-item-info gx-ml-2" overlayClassName={navStyle !== 'NAV_STYLE_MINI_SIDEBAR' ? 'tooltip-info' : 'tooltip-info tooltip-collapsed'} placement="right" content={<IntlMessages id="sidebar.SBAPS.info" />}>
                  <Button
                    icon={<InfoCircleOutlined />}
                    ghost
                    type="text"
                  />
                </Popover>
              </Link>
            </Menu.Item>
            <Menu.Item
              key="configuration/mobile-patients"
            >
              <Link to={`/configuration/mobile-patients#${Math.random().toFixed(3)}`} onClick={paginationReset}>
                <i className="icon icon-avatar" />
                <IntlMessages id="sidebar.APD" />
                <Popover className="menu-item-info gx-ml-2" overlayClassName={navStyle !== 'NAV_STYLE_MINI_SIDEBAR' ? 'tooltip-info' : 'tooltip-info tooltip-collapsed'} overlayStyle={{ position: 'absolute', left: '400px' }} placement="right" content={<IntlMessages id="sidebar.APD.info" />}>
                  <Button
                    icon={<InfoCircleOutlined />}
                    ghost
                    type="text"
                  />
                </Popover>
              </Link>
            </Menu.Item>
          </SubMenu>
        );
      }
    }

    return null;
  };

  /**
  * Render Monitoring MenuItem
  * @returns {JSX} render
  */
  const renderMonitoringMenuItem = () => {
    const showSBAPS = loggedUser?.features?.show_sbaps;

    const isMonitoring = location.pathname.includes('/uhe-units') || location.pathname.includes('/monitoring');

    if (loggedUser.roles) {
      if (shouldRenderMonitoringLink(loggedUser)) {
        return (
          <SubMenu
            className={`main-menu-item ${navStyle === 'NAV_STYLE_FIXED' ? 'expand' : 'collapsed'}`}
            popupClassName={navStyle === 'NAV_STYLE_FIXED' ? 'main-menu-expand' : 'main-menu-collapsed'}
            key="monitoring"
            onTitleMouseEnter={(e) => (!isMonitoring ? e.domEvent.currentTarget.getElementsByTagName('img')[0].src = nav_monitor_on : null)}
            onTitleMouseLeave={(e) => (!isMonitoring ? e.domEvent.currentTarget.getElementsByTagName('img')[0].src = nav_monitor : null)}
            onTitleClick={({ key }) => (changeMenu(key))}
            title={(
              <span className={`gx-d-flex group-item main-menu-title ${isMonitoring ? 'on' : ''}`}>
                <span>
                  <img alt="menu-icon" src={isMonitoring ? nav_monitor_on : nav_monitor} />
                  <IntlMessages id="sidebar.monitoring" />
                </span>
                <i className={closedSubmenu.monitoring ? 'icon icon-chevron-right' : 'icon icon-chevron-down'} />
              </span>
            )}
          >
            <Menu.Item
              key="uhe-units"
              multiple
            >
              <Link to="/uhe-units" onClick={paginationReset}>
                <i className="icon icon-radiobutton" />
                <IntlMessages id="sidebar.APS" />
              </Link>
            </Menu.Item>
            {showSBAPS
              && (
                <Menu.Item
                  key="monitoring/non-uhe-units"
                >
                  <Link to="/monitoring/non-uhe-units" onClick={paginationReset}>
                    <i className="icon icon-auth-screen" />
                    <IntlMessages id="sidebar.bedsCarts.non_uhe" />
                  </Link>
                </Menu.Item>
              )}
          </SubMenu>
        );
      }
    }

    return null;
  };

  /**
  * Renders Notifications Link
  * @returns {JSX.Element} render action
  */
  const renderNotificationsLink = () => {
    if (loggedUser.roles) {
      if (shouldRenderNotificationsLink(loggedUser)) {
        return (
          <Menu.Item
            key="system/notifications"
            title={<IntlMessages id="sidebar.notifications" />}
          >
            <Link to="/system/notifications" onClick={paginationReset}>
              <ExclamationCircleOutlined id="notifications-side-icon" />
              <IntlMessages id="sidebar.notifications" />
            </Link>
          </Menu.Item>
        );
      }
    }

    return null;
  };

  /**
   * Renders Programs Menu Item
   * @returns {JSX.Element} Programs Menu Item
   */
  const renderProgramsLink = () => {
    if (loggedUser.roles && shouldRenderProgramsLink(loggedUser)) {
      return (
        <Menu.Item
          key="configuration/programs"
          title={<IntlMessages id="sidebar.programs" />}
        >
          <Link to={`/configuration/programs#${Math.random().toFixed(3)}`} onClick={paginationReset}>
            <i className="icon icon-apps" />
            <IntlMessages id="sidebar.programs" />
          </Link>
        </Menu.Item>
      );
    }
    return null;
  };

  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }
  /**
   * Gets no headers class
   * @param {string} navStyle navStyle
   * @returns {string} class name
   */
  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR
      || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return 'gx-no-header-notifications';
    }
    return '';
  };

  let selectedKeys = pathname.substr(1);
  const tokens = selectedKeys.split('/');
  const defaultOpenKeys = tokens[1];
  const intl = useIntl();

  selectedKeys = selectedKeys.replace('/new', '');
  selectedKeys = selectedKeys.replace('/edit', '');
  selectedKeys = selectedKeys.replace('/bulk/upload', '');
  selectedKeys = selectedKeys.replace('/bulk-actions', '');
  selectedKeys = selectedKeys.replace(/\/[0-9]+/g, '');
  selectedKeys = selectedKeys.replace('/twilio', '');

  if (selectedKeys[selectedKeys.length - 1] === '/') {
    selectedKeys = selectedKeys.substring(0, selectedKeys.length - 1);
  }
  const isConfiguration = location.pathname.includes('/configuration');
  const isSystem = location.pathname.includes('/system') || location.pathname.includes('/reporting');
  const isSettings = location.pathname.includes('/system/settings') || location.pathname.includes('/reporting');

  /**
   * On open change submenu
   * @param {array} openKeys open submenus
   * @returns {void}
   */
  const onOpenChange = (openKeys) => {
    if (openKeys.length === 0 && navStyle !== 'NAV_STYLE_FIXED') {
      setClosedBedCartsCollapsed(true);
      setClosedSystemSettingsCollapsed(true);
    }
  };

  let openKeys = [];
  if (localStorage.getItem('sidebar-options')) {
    openKeys = Object.entries(JSON.parse(localStorage.getItem('sidebar-options'))).filter((k) => k[1] === false).map((k) => k[0]);
  }

  return (
    <>
      <div className="gx-layout-sider-header gx-d-flex gx-justify-content-center">
        <Logo
          cssClass="cil-logo no-selectable-element no-draggable"
          click={() => {}}
          image={logoImg}
        />
      </div>
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <Button
            shape="circle"
            className={`config-icon prev-icon ${navStyle === 'NAV_STYLE_FIXED' ? 'nav-slider' : 'nav-slider-collapsed'}`}
            onClick={() => {
              if (navStyle === NAV_STYLE_DRAWER) {
                dispatch(toggleCollapsedSideNav(!navCollapsed));
              } else if (navStyle === NAV_STYLE_FIXED) {
                dispatch(onNavStyleChange(NAV_STYLE_MINI_SIDEBAR));
              } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
                dispatch(toggleCollapsedSideNav(!navCollapsed));
              } else {
                dispatch(onNavStyleChange(NAV_STYLE_FIXED));
              }
            }}
          >
            <i className={`icon ${navStyle === 'NAV_STYLE_FIXED' ? 'icon-charvlet-left' : 'icon-charvlet-right'}`} />
          </Button>
        </div>
        <div>
          <Customizer />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys, ...openKeys]}
            selectedKeys={[selectedKeys]}
            onOpenChange={onOpenChange}
            triggerSubMenuAction="click"
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline"
            icon="vertical"
          >
            {renderMonitoringMenuItem()}
            {isTechnicianAccess(loggedUser) && (
              <SubMenu
                key="configuration"
                className={`main-menu-item ${navStyle === 'NAV_STYLE_FIXED' ? 'expand' : 'collapsed'}`}
                popupClassName={navStyle === 'NAV_STYLE_FIXED' ? 'main-menu-expand' : 'main-menu-collapsed'}
                onTitleMouseEnter={(e) => (!isConfiguration ? e.domEvent.currentTarget.getElementsByTagName('img')[0].src = nav_configuration_on : null)}
                onTitleMouseLeave={(e) => (!isConfiguration ? e.domEvent.currentTarget.getElementsByTagName('img')[0].src = nav_configuration : null)}
                onTitleClick={({ key }) => (changeMenu(key))}
                title={(
                  <span className={`gx-d-flex group-item main-menu-title ${isConfiguration ? 'on' : ''}`}>
                    <span>
                      <img alt="menu-icon" src={isConfiguration ? nav_configuration_on : nav_configuration} />
                      <IntlMessages id="sidebar.configuration" />
                    </span>
                    <i className={closedSubmenu.configuration ? 'icon icon-chevron-right' : 'icon icon-chevron-down'} />
                  </span>
                )}
              >
                {renderOrganizationsMenuItem()}
                {renderCustomersMenuItem()}
                {renderFacilitiesMenuItem()}
                {renderUnitPracticeMenuItem()}
                {renderRoomBedSubmenu()}
                {renderUsersMenuItem()}
                {renderProgramsLink()}
              </SubMenu>
            )}
            <SubMenu
              key="system"
              className={`main-menu-item ${navStyle === 'NAV_STYLE_FIXED' ? 'expand' : 'collapsed'}`}
              popupClassName={navStyle === 'NAV_STYLE_FIXED' ? 'main-menu-expand' : 'main-menu-collapsed'}
              onTitleMouseEnter={(e) => (!isSystem ? e.domEvent.currentTarget.getElementsByTagName('img')[0].src = nav_system_on : null)}
              onTitleMouseLeave={(e) => (!isSystem ? e.domEvent.currentTarget.getElementsByTagName('img')[0].src = nav_system : null)}
              onTitleClick={({ key }) => (changeMenu(key))}
              title={(
                <span className={`gx-d-flex group-item main-menu-title ${isSystem ? 'on' : ''}`}>
                  <span>
                    <img alt="menu-icon" src={isSystem ? nav_system_on : nav_system} />
                    <IntlMessages id="sidebar.system" />
                  </span>
                  <i className={closedSubmenu.system ? 'icon icon-chevron-right' : 'icon icon-chevron-down'} />
                </span>
              )}
            >
              {shouldRenderSettingsLink(loggedUser)
                && (
                  <SubMenu
                    className="submenu-item"
                    popupClassName={navStyle === 'NAV_STYLE_FIXED' ? 'main-menu-expand' : 'main-menu-collapsed'}
                    onTitleClick={({ key }) => (changeMenu(key))}
                    key="system/settings"
                    title={(
                      <span className={`gx-d-flex group-item ${isSettings ? 'on' : ''}`}>
                        <i className="icon icon-extra-components" />
                        <IntlMessages id="sidebar.settings" />
                        <i className={closedSubmenu['system/settings'] ? 'icon icon-chevron-right gx-ml-5' : 'icon icon-chevron-down gx-ml-5'} />

                      </span>
                    )}
                  >
                    {renderSettingsSystemMenuItem()}
                    {shouldRenderAdvancedDevMenuItem(loggedUser)
                    && renderSettingsAdvancedDevToolsMenuItem()}
                    {renderiObserverMenuItem()}
                    {renderAnnouncementsMenuItem()}
                    {renderSSOProvidersLink()}
                    {renderReportingSubmenu()}
                  </SubMenu>
                )}
              {isTechnicianAccess(loggedUser) && (
                <Menu.Item
                  className="product-documentation"
                  key="system/support"
                  title={intl.formatMessage({ id: 'sidebar.support' })}
                >
                  <Link to="/system/support">
                    <ExceptionOutlined id="product-documentation-side-icon" />
                    <IntlMessages id="sidebar.support" />
                  </Link>
                </Menu.Item>
              )}
              {renderDeveloperLink()}
              {renderNotificationsLink()}
              {renderTechnicianLink()}
            </SubMenu>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
