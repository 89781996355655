import {
  MONITORING_UHE_FETCH_DATA,
  MONITORING_UHE_FETCH_SUCCESS,
  MONITORING_UHE_FETCH_PIE_CHART_DATA,
  MONITORING_UHE_FETCH_PIE_CHART_SUCCESS,
  MONITORING_UHE_FETCH_GMAP_DATA,
  MONITORING_UHE_FETCH_GMAP_SUCCESS,
} from '@constants/UHEActionTypes';

/**
 * action onFetchData
 * @param  {number} page page
 * @param  {string} sorting sorting
 * @param  {string} filter filter
 * @param  {string} dashboardType dashboardType
 * @return {Object} object
 */
export const onFetchData = (page, sorting, filter, dashboardType) => ({
  type: MONITORING_UHE_FETCH_DATA,
  page,
  sorting,
  filter,
  dashboardType,
});

/**
 * action fetchDataSuccess
 * @param  {Object} data data
 * @return {Object} object
 */
export const fetchDataSuccess = (data) => ({
  type: MONITORING_UHE_FETCH_SUCCESS,
  data,
});

/**
 * action onFetchPieChartData
 * @param  {string} filter filter
 * @return {Object} object
 */
export const onFetchPieChartData = (filter) => ({
  type: MONITORING_UHE_FETCH_PIE_CHART_DATA,
  filter,
});

/**
 * action fetchPieChartDataSuccess
 * @param  {Object} data data
 * @return {Object} object
 */
export const fetchPieChartDataSuccess = (data) => ({
  type: MONITORING_UHE_FETCH_PIE_CHART_SUCCESS,
  data,
});

/**
 * action onFetchGmapData
 * @param  {string} filter filter
 * @return {Object} object
 */
export const onFetchGmapData = (filter) => ({
  type: MONITORING_UHE_FETCH_GMAP_DATA,
  filter,
});

/**
 * action fetchGmapDataSuccess
 * @param  {Object} data data
 * @return {Object} object
 */
export const fetchGmapDataSuccess = (data) => ({
  type: MONITORING_UHE_FETCH_GMAP_SUCCESS,
  data,
});
