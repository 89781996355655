import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import IntlMessages from 'util/IntlMessages';
import {
  Row, Col, Card, Table,
} from 'antd';

/**
 * AssignedProgramsTable Functional Component
 * @param {object} props AssignedProgramsTable Component Props
 * @returns {JSX.Element} AssignedProgramsTable Component
 */
const AssignedProgramsTable = (props) => {
  const { loading, userProgramsData, intl } = props;
  const assignedProgramsColumns = [
    {
      title: <IntlMessages id="configuration.userPrograms.program" />,
      dataIndex: 'program',
      width: '30%',
      editable: false,
      render: (text, record) => <div className="action-btns">{record.program}</div>,
    },
    {
      title: <IntlMessages id="configuration.userPrograms.excludedFacilities" />,
      dataIndex: 'facilities',
      width: '30%',
      editable: false,
      render: (text, record) => {
        if (record.facilities === 'All') {
          return <strong className="action-btns">{intl.formatMessage({ id: 'common.none' })}</strong>;
        }
        return <div className="action-btns">{record.facilities}</div>;
      },
    },
  ];
  /**
   * Adapts Data for the Assigned Programs Table
   * @return {Array} Assigned Programs Table Data Data
   */
  const dataAdapter = () => {
    const adaptedData = [];
    const dataCopy = userProgramsData.slice();

    Object.keys(dataCopy).forEach((key) => {
      const currentProgram = dataCopy[key];
      if (currentProgram.granted) {
        if (currentProgram.excluded.length >= 1) {
          const excludedFacilities = [];
          Object.keys(currentProgram.excluded).forEach(((excludedKey) => {
            const currentExcluded = currentProgram.excluded;
            excludedFacilities.push(currentExcluded[excludedKey].name);
          }));
          adaptedData.push({ program: currentProgram.name, facilities: excludedFacilities.join(', ') });
        } else {
          adaptedData.push({ program: currentProgram.name, facilities: 'All' });
        }
      }
    });
    return adaptedData;
  };
  /**
   * Renders the Card Title
   * @returns {JSX.Element} Card Title
   */
  const renderTitle = () => (
    <IntlMessages
      id="configuration.userPrograms.assignedPrograms"
    />
  );
  return (
    <>
      <Row lg={24} md={24} sm={24} sx={24} gutter={16}>
        <Col lg={24} md={24} sm={24} sx={24}>
          <Card
            className="permission-table-card gx-card customer-edit-info-card"
            title={renderTitle()}
          >
            <Row lg={24} md={24} sm={24} sx={24} gutter={16}>
              <Table
                className="permission-table gx-table-responsive"
                scroll={{ x: false }}
                bordered
                dataSource={dataAdapter()}
                columns={assignedProgramsColumns}
                pagination={false}
                loading={loading}
              />
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

AssignedProgramsTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  userProgramsData: PropTypes.shape().isRequired,
  intl: PropTypes.shape().isRequired,
};
export default withRouter(injectIntl(AssignedProgramsTable));
