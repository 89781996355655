import {
  MONITORING_UHE_FETCH_GMAP_DATA,
  MONITORING_UHE_FETCH_GMAP_SUCCESS,
} from '@constants/UHEActionTypes';

const INIT_STATE = {
  loading: true,
  data: [],
};

/**
 * @param  {Object} state
 * @param  {string} action
 * @return {Object}
 */
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MONITORING_UHE_FETCH_GMAP_DATA:
      return Object.assign({}, state, { loading: true });
      break;

    case MONITORING_UHE_FETCH_GMAP_SUCCESS:
      const newState = { 'data': action.data, loading: false }
      return Object.assign({}, state, newState);
      break;

    default:
      return state;
  }
};
