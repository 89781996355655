import React, { Component } from 'react';
import IntlMessages from 'util/IntlMessages';
import { Input, Form } from 'antd';

const EditableContext = React.createContext();

/**
 * @description Renders an editable row
 * @param {Object} props
 * @param {Object} form
 * @returns {JSX}
 */
const EditableFormRow = (props, form) => {
    return (
        <EditableContext.Provider value={form}>
            <tr {...props} />
        </EditableContext.Provider>
    )
}

/**
 * @description Renders an editable cell
 */
class EditableCell extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editing: false
        };
    }

    /**
     * @description Toggles input when it is focused
     * @returns {void}
     */
    toggleEdit = () => {
        const editing = !this.state.editing;
        this.setState({ editing }, () => {
            if (editing) {
                this.input.focus();
            }
        });
    }

    /**
     * @description Save the form on blur or when the enter key is clicked
     * @returns {void}
     */
    save = () => {
        const { record, handleSave } = this.props;

        this.form.current.validateFields()
        .then(values => {
            this.toggleEdit();
            handleSave({ ...record, ...values });
        })
        .catch(error => {
            console.error(error);
        });
    }

    /**
     * @description Get the rules of required input fields
     * @param {string} dataIndex
     * @returns {Array<ReactElement>}
     */
    getRules = (dataIndex) => {
        if (dataIndex === "domain") {
            return [
                {
                    type: "string",
                    required: true,
                    message: <IntlMessages id="common.requiredDomain" />
                }
            ];
        }

        if (dataIndex === "version") {
            return [
                {
                    required: true,
                    message: <IntlMessages id="common.requiredVersion" />
                }
            ]
        }
        return [];
    }

    /**
     * @description Render cell in the table
     * @param {Object} form
     * @returns {ReactElement}
     */
    renderCell = form => {
        this.form = form;
        const { editing } = this.state;
        const { dataIndex, children } = this.props;

        return editing ? (
            <Form.Item
                colon={false}
                style={{
                    margin: 0,
                    padding: 0
                }}
                rules={this.getRules(dataIndex)}
                name={dataIndex}
            >
                <Input autoComplete="off" ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />
            </Form.Item>
        ) : (
            <div
                className="uhe-table-td"
                style={{ paddingRight: 24 }}
            >
                { children }

                <a onClick={this.toggleEdit}>
                    <i className="icon icon-ckeditor"></i>
                </a>
            </div>
        );
    }

    render() {
        const {
            editable,
            dataIndex,
            title,
            record,
            index,
            handleSave,
            children,
            ...restProps
        } = this.props;

        return (
            <td { ...restProps }>
                {editable ? (
                    <EditableContext.Consumer>{ this.renderCell }</EditableContext.Consumer>
                ): (
                    children
                )}
            </td>
        )
    }
}

export { EditableFormRow, EditableCell };
