import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import IntlMessages from 'util/IntlMessages';
import {
  Row, Col, Card, Button,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import UheUnitCell from '@components/tables/cells/UheUnitCell';
import moment from 'moment-timezone';

/**
   * Renders the Side Panel
   * @param {Object} props props
   * @returns {JSX.Element} Side Panel
   */
const SidePanelCard = (props) => {
  const { selectedBedCart, loading, showSidePanel } = props;

  /**
   * Renders the created at date and time
   * @returns {JSX} created at
   */
  const convertToCustomerTimezone = () => {
    const { timeZone } = props;
    const timeStamp = selectedBedCart.endpointStatus.created_at;
    const date = timeZone
      ? moment(timeStamp).tz(timeZone).format('YYYY-MM-DD hh:mm:ss A z')
      : moment(timeStamp).format('YYYY-MM-DD hh:mm:ss A z');
    return date;
  };

  /**
   * Renders the side panel title
   * @returns {JSX} title
   */
  const renderTitle = () => {
    const { onClickClose, selectedRoom } = props;
    return (
      <div className="side-panel-title" title={selectedRoom.name}>
        <div className="side-panel-title-icon">
          <UheUnitCell content={selectedRoom} />
          <div className={`status-icon-${selectedBedCart.color_online}`} />
        </div>
        <Button className="side-panel-btn" onClick={onClickClose}>
          <CloseOutlined className="close-icon" />
        </Button>
      </div>
    );
  };

  /**
   * Renders device general information
   * @returns {JSX.Element} general info
   */
  const renderGeneralInfo = () => {
    const { endpoint_type, room, endpointStatus } = props.selectedBedCart;
    return (
      <div>
        <Row className="side-panel-row">
          <Col className="side-panel-label">
            <IntlMessages id="uhe.table.id" />
            {' '}
            :
          </Col>
          <Col>
            <div>{room?.id}</div>
          </Col>
        </Row>
        <Row className="side-panel-row">
          <Col className="side-panel-label">
            <IntlMessages id="uhe.table.version" />
            {' '}
            :
          </Col>
          <Col>
            <div>{endpointStatus?.version || <IntlMessages id="common.null" />}</div>
          </Col>
        </Row>
        <Row className="side-panel-row">
          <Col className="side-panel-label">
            <IntlMessages id="uhe.table.type" />
            {' '}
            :
          </Col>
          <Col>
            <div>{endpoint_type}</div>
          </Col>
        </Row>
      </div>
    );
  };

  /**
   * Renders the device call information
   * @returns {JSX.Element} device call information
   */
  const renderCallInfo = () => {
    const {
      endpointStatus, battery, color_online, color_camera, color_camera_controls, color_alert_button,
    } = props.selectedBedCart;
    return (
      <div className="side-panel-section">
        <Row className="side-panel-row">
          <Col className="side-panel-label">
            <IntlMessages id="uhe.table.inCall" />
            {' '}
            :
          </Col>
          <Col>
            <div>{endpointStatus?.in_a_call === 1 ? <IntlMessages id="common.yes" /> : <IntlMessages id="common.no" />}</div>
          </Col>
        </Row>
        <Row className="side-panel-row">
          <Col className="side-panel-label">
            <IntlMessages id="uhe.table.status" />
            {' '}
            :
          </Col>
          <Col>
            <div className={`status-icon-${color_online}`} />
          </Col>
        </Row>
        <Row className="side-panel-row">
          <Col className="side-panel-label">
            <IntlMessages id="configuration.bedsCarts.statusReport.camera" />
            {' '}
            :
          </Col>
          <Col>
            <div
              className={`status-icon-${color_camera}`}
            />
          </Col>
        </Row>
        <Row className="side-panel-row">
          <Col className="side-panel-label">
            <IntlMessages id="configuration.bedsCarts.statusReport.cameraControls" />
            {' '}
            :
          </Col>
          <Col>
            <div
              className={`status-icon-${color_camera_controls}`}
            />
            {' '}
          </Col>
        </Row>
        <Row className="side-panel-row">
          <Col className="side-panel-label">
            <IntlMessages id="configuration.bedsCarts.statusReport.alertButton" />
            {' '}
            :
          </Col>
          <Col>
            <div
              className={`status-icon-${color_alert_button}`}
            />
          </Col>
        </Row>
        <Row className="side-panel-row">
          <Col className="side-panel-label">
            <IntlMessages id="configuration.bedsCarts.statusReport.batteryLife" />
            {' '}
            :
          </Col>
          <Col>
            <div>{battery?.level || <IntlMessages id="common.null" />}</div>
          </Col>
        </Row>
      </div>
    );
  };

  /**
   * Renders the device config information
   * @returns {JSX} device config
   */
  const renderDeviceConfig = () => {
    const {
      acknowledged_by, endpointStatus, customer_auto_notification, device_config_mode, under_maintenance, is_live,
    } = props.selectedBedCart;
    return (
      <div className="side-panel-section">
        <Row className="side-panel-row">
          <Col className="side-panel-label">
            <IntlMessages id="configuration.bedsCarts.deviceConfig.configMode" />
            {' '}
            :
          </Col>
          <Col>
            <div>{device_config_mode}</div>
          </Col>
        </Row>
        <Row className="side-panel-row">
          <Col className="side-panel-label">
            <IntlMessages id="configuration.bedsCarts.deviceConfig.caregilityMonitor" />
            {' '}
            :
          </Col>
          <Col>
            <div>{is_live ? <IntlMessages id="common.true" /> : <IntlMessages id="common.false" />}</div>
          </Col>
        </Row>
        <Row className="side-panel-row">
          <Col className="side-panel-label">
            <IntlMessages id="configuration.bedsCarts.deviceConfig.customerNotification" />
            {' '}
            :
          </Col>
          <Col>
            <div>{customer_auto_notification ? <IntlMessages id="common.true" /> : <IntlMessages id="common.false" />}</div>
          </Col>
        </Row>
        <Row className="side-panel-row">
          <Col className="side-panel-label">
            <IntlMessages id="configuration.bedsCarts.deviceConfig.callInOut" />
            {' '}
            :
          </Col>
          <Col>
            <div>{under_maintenance === 1 ? <IntlMessages id="common.callDisabled" /> : <IntlMessages id="common.callEnabled" />}</div>
          </Col>
        </Row>
        <Row className="side-panel-row">
          <Col className="side-panel-label">
            <IntlMessages id="configuration.bedsCarts.statusReport.firstCameOnline" />
            {' '}
            :
          </Col>
          <Col>
            <div>{endpointStatus ? convertToCustomerTimezone() : <IntlMessages id="common.null" />}</div>
          </Col>
        </Row>
        <Row className="side-panel-row">
          <Col className="side-panel-label">
            <IntlMessages id="uhe.table.acknowledgedBy" />
            {' '}
            :
          </Col>
          <Col>
            <div>{acknowledged_by || <IntlMessages id="common.null" />}</div>
          </Col>
        </Row>
      </div>
    );
  };

  /**
   * Renders connection information
   * @returns {JSX} connection information
   */
  const renderConnectionInfo = () => {
    const { endpointStatus, internal_ip } = props.selectedBedCart;
    return (
      <div className="side-panel-section">
        <Row className="side-panel-row">
          <Col className="side-panel-label">
            <IntlMessages id="configuration.bedsCarts.statusReport.InternalIp" />
            {' '}
            :
          </Col>
          <Col>
            <div>{internal_ip || <IntlMessages id="common.null" />}</div>
          </Col>
        </Row>
        <Row className="side-panel-row">
          <Col className="side-panel-label">
            <IntlMessages id="configuration.bedsCarts.statusReport.macAddress" />
            {' '}
            :
          </Col>
          <Col>
            <div>
              {endpointStatus
                ? endpointStatus.machine_id
                : <IntlMessages id="common.null" />}
            </div>
          </Col>
        </Row>

      </div>
    );
  };

  return (
    <Card className={showSidePanel ? 'side-panel open' : 'side-panel'} loading={loading}>
      {renderTitle()}
      {renderGeneralInfo()}
      {renderCallInfo()}
      {renderDeviceConfig()}
      {renderConnectionInfo()}
    </Card>
  );
};

SidePanelCard.propTypes = {
  selectedBedCart: PropTypes.shape().isRequired,
  selectedRoom: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  onClickClose: PropTypes.func.isRequired,
  timeZone: PropTypes.string.isRequired,
  showSidePanel: PropTypes.bool.isRequired,

};

export default withRouter(injectIntl(SidePanelCard));
