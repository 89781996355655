import React from 'react';
import PropTypes from 'prop-types';
import { Input, Cascader, DatePicker } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import lodash from 'lodash';
import {
  INPUT_DEBOUNCE_MILLSEC,
  SEARCH_MIN_CHAR_LENGTH,
} from '@constants/SystemConstants';
import {
  TOP_FILTER_PREFIX,
  TABLE_FILTER_PREFIX,
} from '@constants/UHESettings';
import moment from 'moment';
import { checkInputSymbols } from '@util/UheHelper';

const { RangePicker } = DatePicker;

/**
 * ListingsTableInputFilter Class Component
 */
class ListingsTableInputFilter extends React.Component {
  /**
   * Part of React lifecycle
   * @param  {object} nextProps Next Props
   * @param {object} nextState Next State
   * @return {null} null
   */
  static getDerivedStateFromProps(nextProps, nextState) {
    const {
      cellData,
      dataKey,
      tableData,
      setTableData,
    } = nextProps;
    const { sortColumns } = cellData;

    const sortColumn = sortColumns.find((element) => element.column.dataIndex === dataKey);
    if (!sortColumn) {
      return null;
    }

    const ascKey = `${dataKey},asc`;
    const descKey = `${dataKey},desc`;
    let tableSorter;

    if (sortColumn.order === 'ascend') {
      tableSorter = ascKey;
    } else if (sortColumn.order === 'descend') {
      tableSorter = descKey;
    }

    const { history } = nextProps;
    const qParams = new URLSearchParams(history.location.search);
    const currSort = qParams.getAll('sort') || [];
    let isTableDefaultSorted = true;

    const sorterFound = currSort.find((element) => element === ascKey || element === descKey);

    if (sorterFound === tableSorter) {
      return null;
    }

    if (tableData) {
      Object.keys(tableData).forEach((column) => {
        if (tableData[column].isClicked) {
          isTableDefaultSorted = false;
        }
      });

      if (isTableDefaultSorted) {
        qParams.delete('sort');
      }

      if (nextState.isClicked && tableSorter) {
        const [column, sort] = tableSorter.split(',');
        if (tableData[column].currSort !== sort) {
          setTableData(column, true, sort);
        }
      }
    }

    if (!sorterFound) {
      currSort.push(ascKey);
    } else if (sorterFound) {
      const index = currSort.indexOf(sorterFound);
      if (sorterFound === ascKey) {
        if (index !== -1) {
          currSort[index] = descKey;
        }
      } else {
        lodash.remove(currSort, (n) => n === sorterFound);
      }
    }

    qParams.delete('sort');
    currSort.forEach((value) => qParams.append('sort', value));
    history.push({ search: qParams.toString(), hash: history.location.hash });

    return null;
  }

  /**
   * ListingsTableInputFilter Constructor
   * @param {object} props ListingsTableInputFilter Props
   * @returns {void}
   */
  constructor(props) {
    super(props);

    this.state = {
      isClicked: false,
    };

    const {
      dataKey,
      selectedTable,
      intl,
      history,
    } = this.props;
    this.intl = intl;
    this.history = history;
    this.ascKey = `${dataKey},asc`;
    this.descKey = `${dataKey},desc`;
    this.qParams = new URLSearchParams(this.history.location.search);
    this.filterValue = selectedTable ? '' : this.qParams.get(`${TABLE_FILTER_PREFIX}${dataKey}`);
    this.showSearchIcon = !!(!this.filterValue || !this.filterValue.length);
    this.textInput = React.createRef();

    this.handleSearchProxy = this.handleSearchProxy.bind(this);
    this.getPopupContainerFilters = this.getPopupContainerFilters.bind(this);
  }

  /**
   * componentDidMount() is invoked immediately after
   * a component is mounted (inserted into the tree)
   * @returns {void} void
   */
  componentDidMount() {
    const { location } = this.props;
    if (this.textInput.current && this.textInput.current.state?.value) {
      this.textInput.current.focus();
      if (location.pathname.includes('/bulk-actions')) {
        this.textInput.current.state.value = '';
      }
    }
  }

  /**
   * Handles componentDidUpdate
   * @param {object} prevProps prevProps
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.search !== prevProps.location.search) {
      this.qParams = new URLSearchParams(location.search);
    }
  }

  /**
   * Find if there is sort for this column
   *
   * @return {string|undefined} [description]
   */
  getCurrentSort() {
    const currSort = this.qParams.getAll('sort') || [];
    return currSort.find((element) => element === this.ascKey || element === this.descKey);
  }

  /**
   * Stop click propagation to prevent sort triggering
   * when the user clicks on the input
   *
   * @param  {Event} e Event
   * @return {void}
   */
  inputClickHandler(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }

  /**
   * Proxy function between the change event and the debounced handler
   * Used to control the search icon visibility
   *
   * @param  {Event} e Event
   * @return {void}
   */
  handleSearchProxy(e) {
    const { filterType, dataKey, clearFilters } = this.props;
    switch (filterType) {
      case 'dropdown':
        e && e.length
          ? this.qParams.set(`${TABLE_FILTER_PREFIX}${dataKey}`, e[0])
          : this.qParams.delete(`${TABLE_FILTER_PREFIX}${dataKey}`);
        this.history.push({ search: this.qParams.toString() });
        if (dataKey === 'status') {
          if (e[0]) {
            localStorage.setItem('selectedPie', e[0]);
          } else {
            clearFilters();
          }
        }
        break;
      case 'datepicker':
        if (e && e.length) {
          this.qParams.delete(`${TABLE_FILTER_PREFIX}${dataKey}`);
          this.qParams.append(`${TABLE_FILTER_PREFIX}${dataKey}`, moment(e[0]).format('X'));
          this.qParams.append(`${TABLE_FILTER_PREFIX}${dataKey}`, moment(e[1]).format('X'));
        } else {
          this.qParams.delete(`${TABLE_FILTER_PREFIX}${dataKey}`);
        }
        this.history.push({ search: this.qParams.toString() });
        break;
      default:
        const { value } = e.currentTarget;
        this.showSearchIcon = !value.length;
        this.handleSearch();
        this.forceUpdate();
    }
  }

  /**
   * Debounced search change handler
   *
   * @return {void}
   */
  handleSearch = lodash.debounce(() => {
    const { dataKey, triggerCharsNum } = this.props;
    const { value } = this.textInput.current.input;

    if (!value.length) {
      this.qParams.delete(`${TABLE_FILTER_PREFIX}${dataKey}`);
    } else if (value.length < triggerCharsNum) {
      // No action yet
      return;
    } else {
      this.qParams.set(`${TABLE_FILTER_PREFIX}${dataKey}`, value);
    }

    this.history.push({ search: this.qParams.toString() });
  }, INPUT_DEBOUNCE_MILLSEC);

  /**
 * Renders Input
 * @return {HTMLElement} element
 */
  renderFilterInput() {
    const { filterType } = this.props;

    switch (filterType) {
      case 'dropdown':
        return this.renderCascader();
        break;
      case 'datepicker':
        return this.renderDatePicker();
        break;
      default:
        return this.renderTextInput();
    }
  }

  /**
   * Converts to lowercase and filter options.
   * @param {string} inputValue value
   * @param {object} path Input's path
   * @returns {string} string
   */
  tableCascaderFilter = (inputValue, path) => path.some((option) => option.label
    .toLowerCase()
    .indexOf(inputValue.toLowerCase()) > -1);

  /**
   * Renders Sort Icon Next to the Column Name
   * @return {ReactElement|null} Sort Icon
   */
  renderSortIcon() {
    const { isClicked } = this.state;
    const sort = this.getCurrentSort();
    let icon = null;
    if (!sort) {
      return icon;
    } if (sort === this.descKey) {
      icon = <span><i className="sort-icon icon icon-long-arrow-down" /></span>;
    } else if (sort === this.ascKey) {
      icon = <span><i className="sort-icon icon icon-long-arrow-up" /></span>;
    }

    return icon;
  }

  /**
   * Get container for Popup
   * @param {*} event HTML Event
   * @returns {HTMLElement} getInfo
   */
  getPopupContainerFilters(event) {
    const { dataKey } = this.props;
    let getInfo;
    if (dataKey === 'interpreter') {
      getInfo = event.parentNode;
    } else if (dataKey === 'lastEvent') {
      getInfo = document.querySelector('.lastEvent-dropdown');
    } else {
      getInfo = event.parentNode;
    }
    return getInfo;
  }

  /**
   * Renders Cascader
   * @return {ReactElement|null} Cascader
   */
  renderCascader() {
    const { filterOptions, dataKey, intl } = this.props;
    const topFilterExists = !!this.qParams.get(`${TOP_FILTER_PREFIX}${dataKey}`);
    const filter = this.qParams.get(`${TABLE_FILTER_PREFIX}${dataKey}`);
    return (
      <Cascader
        getPopupContainer={this.getPopupContainerFilters}
        className={dataKey === 'lastEvent' ? 'lastEvent-dropdown' : ''}
        dropdownClassName="table-cascader-dropdown top-filter-popup"
        showSearch={{ filter: this.tableCascaderFilter }}
        changeOnSelect
        size="large"
        expandTrigger="hover"
        disabled={topFilterExists}
        value={filter ? [filter] : undefined}
        defaultValue={this.filterValue ? [this.filterValue] : undefined}
        options={filterOptions}
        onChange={this.handleSearchProxy}
        placeholder={intl.messages['common.select']}
      />
    );
  }

  /**
   * Render Text Input
   * @return {Input} Text Input
   */
  renderTextInput() {
    const { dataKey, intl, isClearedInputs } = this.props;
    const topFilterExists = !!this.qParams.get(`${TOP_FILTER_PREFIX}${dataKey}`);

    if (isClearedInputs && this.textInput.current) {
      this.textInput.current.state.value = '';
    }

    return (
      <Input
        className="table-input"
        autoComplete="off"
        allowClear
        defaultValue={this.filterValue}
        disabled={topFilterExists}
        ref={this.textInput}
        prefix={this.showSearchIcon ? <i className="icon icon-search-new" /> : <span />}
        onChange={(е) => this.handleSearchProxy(е)}
        type={dataKey !== 'deviceId' ? 'string' : 'number'}
        onKeyDown={(evt) => checkInputSymbols(dataKey, evt)}
      />
    );
  }

  /**
   * Render Date Picker
   * @return {ReactElement|null} Date Picker
   */
  renderDatePicker() {
    return (
      <RangePicker
        dropdownClassName="table-date-picker-dropdown"
        allowClear
        onChange={(e) => this.handleSearchProxy(e)}
      />
    );
  }

  /**
   * Renders ListingsTableInputFilter Component
   * @return {ReactElement} ListingsTableInputFilter Component
   */
  render() {
    const { title, showFilter } = this.props;
    return (
      <div className="table-filter-holder" onClick={() => this.setState({ isClicked: true })}>
        <span className="uhe-table-head">
          <FormattedMessage id={title} />
          {this.renderSortIcon()}
        </span>
        {showFilter && <div onClick={this.inputClickHandler}>{this.renderFilterInput()}</div>}
      </div>
    );
  }
}

ListingsTableInputFilter.defaultProps = {
  showFilter: true,
  filterType: 'input',
  filterOptions: [],
  triggerCharsNum: SEARCH_MIN_CHAR_LENGTH,

};

ListingsTableInputFilter.propTypes = {
  intl: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  filterType: PropTypes.string,
  showFilter: PropTypes.bool,
  filterOptions: PropTypes.array,
  triggerCharsNum: PropTypes.number,
  tableData: PropTypes.object,
  setTableData: PropTypes.func,
};

export default (injectIntl(withRouter(ListingsTableInputFilter)));
