import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {
  Card, Row, Col, Input, Form, Cascader,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import IntlMessages from 'util/IntlMessages';
import {
  cascaderSearchFilter,
  convertTimestampToDateTime,
  goBackTo,
  removeSearchIcon,
} from '@util/UheHelper';
import EditHeader from '@components/uhe/configuration/EditHeader';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import {
  saveProgram,
  getProgram,
  updateProgram,
  getProgramSuccess,
  getProgramGrants,
  updateProgramGrants,
  clearProgramState,
} from '@actions/uhe/configuration/programs/ProgramsActions';
import { onGetOptions } from '@uhe_actions/filters/ListingsTopFilterActions';
import UserPermissionsTable from '@containers/uhe/Configuration/ManageUsers/UserPermissionsTable';
import UserPermissionsUtils from '@containers/uhe/Configuration/ManageUsers/UserPermissionsUtils';
import PermissionsListing from '@components/uhe/configuration/PermssionsListing/PermissionsListing';
import { canEditPrograms } from '@util/UheRoleChecker';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

/**
 * ManagePrograms Class Component
 */
class ManagePrograms extends Component {
  formRef = React.createRef();

  /**
   * Constructor
   * @param {*} props values
   */
  constructor(props) {
    super(props);
    const {
      location,
      match,
      subtitle,
      setSubtitleAction,
      intl,
    } = this.props;

    this.urlId = match.params.id;

    this.intl = intl;

    this.state = {
      program: {
        name: '',
        organization: {
          id: null,
          name: '',
        },
      },
      grants: {},
      isNew: location.pathname.indexOf('/new') !== -1,
      focusCascader: {
        organization: false,
      },
    };

    const { isNew } = this.state;

    if (isNew) {
      if (subtitle.langId !== 'program.newProgram') {
        setSubtitleAction('program.newProgram');
      }
    } else if (subtitle.langId !== 'program.editProgram') {
      setSubtitleAction('program.editProgram');
    }
  }

  /**
   * Update local state
   * @returns {void}
   */
  componentDidMount() {
    const {
      onGetOrganizationOptions,
      getProgramAction,
      getProgramGrantsAction,
      match,
      clearProgramState,
    } = this.props;
    const { isNew } = this.state;

    const urlId = match.params.id;
    onGetOrganizationOptions();

    if (urlId) {
      clearProgramState();
    }
    if (!isNew) {
      getProgramAction(this.urlId);
      getProgramGrantsAction(this.urlId);
    }
  }

  /**
   * Watch for empty data and if is empty set new state data
   * @param {Readonly<T>} prevProps data
   * @return {Object} data
   */
  componentDidUpdate(prevProps, prevState) {
    const {
      programId,
      history,
      program,
      grants,
      getProgramAction,
      getProgramGrantsAction,
      match,
    } = this.props;

    const { isNew } = this.state;

    if (isNew && programId !== prevProps.programId) {
      history.push(`/configuration/programs/edit/${programId}`);
      this.setState({ isNew: false });
    }

    if (Object.keys(prevProps.program).length === 0 && Object.keys(program).length > 0) {
      this.setState({ program });
    }

    if (!isNew
      && !prevProps.program?.organization?.id
      && program?.organization?.id
      && !prevProps.program?.name
      && program?.name) {
      this.formRef.current.setFieldsValue({
        organization: [program?.organization?.id],
        name: [program?.name],
      });
    }

    if (!isNew && prevProps.grants && grants) {
      if (prevProps.grants !== grants) {
        const [
          programPermissionData, globalGrantsData,
        ] = UserPermissionsUtils.fromApiToReact(grants);
        const { iconsult_notifications, iobserver_notifications } = grants;
        this.globalGrantsData = { ...globalGrantsData, iconsult_notifications, iobserver_notifications };
        this.setState({
          grants: UserPermissionsUtils.fromReactToApi(programPermissionData, { ...globalGrantsData, iconsult_notifications, iobserver_notifications }),
          programPermissionData,
          globalGrantsData: { ...globalGrantsData, iconsult_notifications, iobserver_notifications },
        });
      }
    }

    if (prevState.isNew !== isNew) {
      const { clearProgramState } = this.props;
      const { id } = match.params;
      getProgramAction(id);
      getProgramGrantsAction(id);
      clearProgramState();
    }
  }

  /**
   * Cleanup Method
   * @returns {void}
   */
  componentWillUnmount() {
    const { getProgramSuccessAction } = this.props;

    getProgramSuccessAction({});
  }

  /**
   * Event in input field
   * @param {Object} event in input field
   * @param {String} key property
   * @returns {String} input value
   */
  onChangeHandler(event, key) {
    const { program } = this.state;
    const newState = { ...program, [key]: event.target.value };
    this.setState({
      program: newState,
    });
  }

  /**
   * Handle Changes in the Organizations Cascader and Updates Local State
   * @param {array} index Array of Numbers(IDs)
   * @param {number} id Organization ID
   * @param {string} name Organization Name
   * @returns {void}
   */
  onChangeOrganization = ([{ id, name } = {}] = []) => {
    const { program, focusCascader } = this.state;
    program.organization = { id, name };

    this.setState({ program }, () => {
      this.formRef.current.setFieldsValue({
        organization: [id],
      });
    });
    if (name !== '') {
      this.setState({ ...focusCascader, focusCascader: { organization: false } });
    }
  }

  /**
   * Save Program
   * @returns {void}
   */
  saveProgram = () => {
    this.formRef.current
      .validateFields(['name', 'organization'])
      .then(() => {
        const {
          saveProgramAction,
          updateProgramAction,
          updateProgramGrantsAction,
          setSubtitleAction,
          match,
        } = this.props;
        const {
          program, programPermissionData, globalGrantsData, isNew,
        } = this.state;
        if (isNew) {
          saveProgramAction({ program });
        } else {
          const body = UserPermissionsUtils
            .fromReactToApi(programPermissionData, globalGrantsData);
          const { iconsult_notifications, iobserver_notifications } = globalGrantsData;
          const updBody = { ...body, iconsult_notifications, iobserver_notifications };

          updateProgramAction({ body: program, id: match.params.id });
          updateProgramGrantsAction({ body: updBody, id: match.params.id });
        }
        setSubtitleAction('program.editProgram');
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /**
  * Render title, back and save buttons
  * @returns {JSX} buttons
  */
  renderHeadLine = () => {
    const { lastLocation, history } = this.props;

    return (
      <EditHeader
        save={this.saveProgram}
        goBack={goBackTo('/configuration/programs', lastLocation?.pathname, history)}
        backToPreviousPage={this.onBackButtonClick}
      />
    );
  }

  /**
   * Renders formatted timestamp based on the logged user's timezone
   * @param {string} timestampType Created At / Updated At
   * @returns {JXS.Element} Intl message with the formatted timestamp
   */
  renderTimestamp = (timestampType) => {
    const { program, loggedUser } = this.props;
    const { [timestampType]: timestamp } = program;
    const { timeZone } = loggedUser;
    let formattedDateTime = convertTimestampToDateTime(timestamp, timeZone);
    if (formattedDateTime) {
      let [date, time, timeZone] = formattedDateTime.split(' ');
      let index;

      if (timeZone.includes('(')) {
        timeZone = timeZone.slice(1, timeZone.length - 1);
      }
      if (timeZone.includes('+')) {
        index = timeZone.indexOf('+');
      } else if (timeZone.includes('-')) {
        index = timeZone.indexOf('-');
      }

      timeZone = timeZone.slice(0, index);

      formattedDateTime = [date, time, timeZone].join(' ');
    }
    let intlMessage;

    if (formattedDateTime === null) {
      formattedDateTime = <IntlMessages id="common.null" />;
    }

    if (timestampType === this.intl.formatMessage({ id: 'common.createdAt' })) {
      intlMessage = <IntlMessages id="common.created" />;
    } else if (timestampType === this.intl.formatMessage({ id: 'common.updatedAt' })) {
      intlMessage = <IntlMessages id="common.updated" />;
    } else {
      intlMessage = <IntlMessages id="common.lastLogin" />;
    }

    return (
      <div>
        {intlMessage}
        {formattedDateTime}
      </div>
    );
  }

  /**
   * Forwarder for updating user permissions from UserPermissionsTable
   * @param {Object.<string, ReactivePermissionData>} permissions TODO
   * @param {ReactivePermissionData} globalGrantsData TODO
   * @returns {void}
   */
  updatePermissions = (permissions, globalGrantsData) => {
    this.setState({
      programPermissionData: { ...permissions },
      globalGrantsData: { ...globalGrantsData },
      grants: UserPermissionsUtils.fromReactToApi(permissions, globalGrantsData),
    });
  };

  /**
   * Handles Back Button Functionality && Clears Input/Local State if necessary
   * @returns {void}
   */
  onBackButtonClick = () => {
    const { isNew } = this.state;
    if (!isNew) {
      this.formRef.current.setFieldsValue({
        name: '',
        organization: [],
      });

      this.setState({
        program: {
          name: '',
          organization: {
            id: null,
            name: '',
          },
        },
        isNew: true,
      });
    }

    window.history.back();
  }

  /**
   * Handles What Icon to Show in Cascade Menu - Expand or Search
   * @param {string} field field type
   * @returns {func} manipulate the state
   */
  changeDropdownExpandIcon = (field) => {
    const {
      focusCascader: {
        organization, customer, facility, unit, deviceTypes,
      }, focusCascader,
    } = this.state;
    switch (field) {
      case 'organization':
        return this.setState({ focusCascader: { ...focusCascader, organization: !organization } });
      case 'customer':
        return this.setState({ focusCascader: { ...focusCascader, customer: !customer } });
      case 'facility':
        return this.setState({ focusCascader: { ...focusCascader, facility: !facility } });
      case 'unit':
        return this.setState({ focusCascader: { ...focusCascader, unit: !unit } });
      case 'deviceTypes':
        return this.setState({ focusCascader: { ...focusCascader, deviceTypes: !deviceTypes } });
      default:
    }
  }

  /**
  * Renders ManageOrganization Component
  * @returns {JSX.Element} ManageOrganization
  */
  render() {
    const {
      programPermissionData, grants, globalGrantsData, isNew, focusCascader,
    } = this.state;
    const {
      optionsList, intl, loading, program, loggedUser,
    } = this.props;

    return (
      <div className="manage-customer-wrapper">
        <Form ref={this.formRef} name="manage-organizations" {...layout} autoComplete="off">
          {this.renderHeadLine()}
          <Row gutter={16}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Card
                className="gx-card"
                title={<IntlMessages id="program.programDetails" />}
                loading={loading}
              >
                <Row lg={24} md={24} sm={24} xs={24} gutter={16}>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      colon={false}
                      className="align-item-city form-item-row"
                      label={<IntlMessages id="program.programName" />}
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                        },
                      ]}
                      initialValue={!isNew ? program.name : ''}
                    >
                      <Input
                        autoComplete="off"
                        name="name"
                        type="text"
                        placeholder={intl.formatMessage({ id: 'program.programName' })}
                        title={intl.formatMessage({ id: 'program.programName' })}
                        onChange={(event) => this.onChangeHandler(event, 'name')}
                        disabled={!canEditPrograms(loggedUser)}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      colon={false}
                      className="align-item-city form-item-row"
                      label={<IntlMessages id="program.organization" />}
                      name="organization"
                      rules={[
                        {
                          required: true,
                          message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                        },
                      ]}
                    >
                      <Cascader
                        getPopupContainer={(event) => event.parentNode}
                        showSearch={{ filter: cascaderSearchFilter }}
                        suffixIcon={focusCascader.organization ? <SearchOutlined /> : null}
                        onClick={() => this.changeDropdownExpandIcon('organization')}
                        expandTrigger="hover"
                        size="large"
                        options={optionsList.organization || []}
                        fieldNames={{ label: 'name', value: 'id' }}
                        onChange={(label, selected) => (this.onChangeOrganization(selected))}
                        longdesc={this.intl.formatMessage({
                          id: 'configuration.bedsCarts.descriptions.organization',
                        })}
                        disabled={!isNew}
                        onBlur={() => this.setState({ focusCascader: removeSearchIcon() })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {!isNew && (
                  <div className="timestamps">
                    {this.renderTimestamp(this.intl.formatMessage({ id: 'common.createdAt' }))}
                    {this.renderTimestamp(this.intl.formatMessage({ id: 'common.updatedAt' }))}
                  </div>
                )}
              </Card>
              {!isNew && (
                <div>
                  <Card
                    className="permission-table-card gx-card customer-edit-info-card"
                    title={(
                      <IntlMessages
                        id="configuration.users.roleAssignment"
                      />
                    )}
                    loading={loading}
                  >
                    <UserPermissionsTable
                      dataSource={programPermissionData}
                      globalGrantsData={globalGrantsData}
                      updatePermissions={this.updatePermissions}
                      formatMessage={this.intl.formatMessage}
                      showAdminTab={false}
                      disabled={!canEditPrograms(loggedUser)}
                    />
                  </Card>
                  <PermissionsListing
                    globalGrantsData={globalGrantsData}
                    loading={loading}
                    permissionsData={grants}
                    isProgramPage
                  />
                </div>
              )}
            </Col>
          </Row>
          {this.renderHeadLine()}
        </Form>
      </div>
    );
  }
}

ManagePrograms.defaultProps = {
  history: PropTypes.object,
  subtitle: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  optionsList: PropTypes.array,
  intl: PropTypes.shape(),
  programId: PropTypes.shape(),
};

ManagePrograms.propTypes = {
  history: PropTypes.shape(),
  lastLocation: PropTypes.shape().isRequired,
  intl: PropTypes.shape(),
  setSubtitleAction: PropTypes.func.isRequired,
  subtitle: PropTypes.shape(),
  match: PropTypes.shape(),
  location: PropTypes.shape(),
  onGetOrganizationOptions: PropTypes.func.isRequired,
  saveProgramAction: PropTypes.func.isRequired,
  optionsList: PropTypes.shape(),
  programId: PropTypes.shape(),
  getProgramAction: PropTypes.func.isRequired,
  program: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  updateProgramAction: PropTypes.func.isRequired,
  getProgramSuccessAction: PropTypes.func.isRequired,
  getProgramGrantsAction: PropTypes.func.isRequired,
  grants: PropTypes.shape().isRequired,
  updateProgramGrantsAction: PropTypes.func.isRequired,
  timeZone: PropTypes.string.isRequired,
  loggedUser: PropTypes.string.isRequired,
};

/**
 * Maps Global State to Component's Props
 * @returns {Object} data
 */
const mapStateToProps = ({
  subtitle,
  common,
  listingsTopFilter,
  ConfigurationUsers,
  programs,
}) => {
  const {
    programId, selectedProgram, loading, programGrants,
  } = programs;
  return {
    error: common.error,
    subtitle,
    loggedUser: ConfigurationUsers.ownUser,
    programId,
    optionsList: listingsTopFilter,
    program: selectedProgram,
    loading,
    grants: programGrants,
  };
};

/**
 * Returns Object Which Dispatch Actions to the Store
 * @param {function} dispatch data
 * @returns {Object} data
 */
const mapDispatchToProps = (dispatch) => ({
  setSubtitleAction: (langId) => dispatch(setSubtitle(langId)),
  onGetOrganizationOptions: (id) => dispatch(onGetOptions('organization', id)),
  saveProgramAction: (data) => dispatch(saveProgram(data)),
  getProgramAction: (id) => dispatch(getProgram(id)),
  updateProgramAction: (data) => dispatch(updateProgram(data)),
  getProgramSuccessAction: (data) => dispatch(getProgramSuccess(data)),
  getProgramGrantsAction: (id) => dispatch(getProgramGrants(id)),
  updateProgramGrantsAction: (data) => dispatch(updateProgramGrants(data)),
  clearProgramState: () => dispatch(clearProgramState()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLastLocation(injectIntl(withRouter(ManagePrograms))));
