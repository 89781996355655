import {
  FETCH_SIGNIN_REPORTS_SUCCESSS,
  FETCH_SIGNIN_REPORTS_REQUEST,
} from '@constants/UHEActionTypes';
import { tableContent } from 'util/UheHelper';

const INIT_STATE = {
  loading: true,
  table: {
    list: [],
    page: {},
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_SIGNIN_REPORTS_REQUEST: {
      return { ...state, loading: true };
    }

    case FETCH_SIGNIN_REPORTS_SUCCESSS: {
      const currentTable = tableContent(action.data, state.table);
      return { ...state, table: currentTable, loading: false };
    }

    default:
      return state;
  }
};
