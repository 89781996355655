import {
  FETCH_PASSWORD_DATA,
  FETCH_PASSWORD_DATA_SUCCESS,
} from '@constants/UHEActionTypes';

/**
 * @description Fetch password data
 * @return {Object}
 */
export const passwordOnFetchData = (data) => {
  return {
    type: FETCH_PASSWORD_DATA,
    data,
  };
};

/**
 * @description Fetch success
 * @param  {Object} data
 * @return {Object}
 */
export const fetchDataSuccess = (data) => {
  return {
    type: FETCH_PASSWORD_DATA_SUCCESS,
    data,
  };
};
