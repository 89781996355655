import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Card, Modal, Button, Row, Col, Popover,
} from 'antd';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';
import {
  fetchDeviceAction,
  saveUheBedCart,
  doFetchDeviceAction,
} from '@uhe_actions/configuration/bedsCarts/bedsCartsActions';
import {
  soft_reboot, hard_reboot, test_call, end_test_call, ring_doorbell, reboot_os,
} from '@constants/UHEDeviceActions';

const { confirm } = Modal;

/**
 * Renders Action Card in ManageUheBedCart file
 * @param {Object} bedCart object
 * @param {Object} intl
 * @param {function} onChangeAction
 * @param {boolean} loading
 * @returns {JSX} card
 */
class TechActionsCard extends React.PureComponent {
  /**
   * Technician actions card Constructor
   * @param {object} props Technician actions card Props
   * @returns {void}
   */
  constructor(props) {
    super(props);

    this.sendDeviceCommand = this.sendDeviceCommand.bind(this);
    this.testCall = this.testCall.bind(this);
    this.endTestCall = this.endTestCall.bind(this);
    this.ringDoorbell = this.ringDoorbell.bind(this);

    this.state = {
      command: '',
    };
  }

  /**
  * Confirms Action command
  * @returns {void}
  */
  confirmAction = () => {
    this.sendDeviceCommand();

    this.setState({
      command: '',
    });
  };

  /**
  * Clears State when Action Modal is Closed
  * @returns {void}
  */
  resetCommandState = () => {
    this.setState({
      command: '',
    });
  };

  /**
   * Show Confirmation Modal When Action Button Being Clicked
   * @param {string} commandToSend action
   * @returns {void}
   */
  showConfirm = (commandToSend) => {
    const { intl } = this.props;
    this.setState({
      command: commandToSend,
    });
    confirm({
      title: intl.formatMessage({ id: `configuration.technician.${commandToSend}` }),
      onOk: this.confirmAction,
      onCancel: this.resetCommandState,
      autoFocusButton: 'cancel',
    });
  }

  /**
   * Sends Test Call command
   * @param {string} bedCart.machine_name string
   * @param {string} command name
    * @param {object} params_p name
   * @returns {void}
   */
  testCall() {
    const { bedCart, doFetchDeviceAction, bedCartId } = this.props;

    doFetchDeviceAction({
      machine_name: bedCart.machine_name,
      command: test_call,
      id: bedCartId,
    });
  }

  /**
   * Sends End Test Call command
   * @param {string} bedCart.machine_name string
   * @param {string} command name
   * @param {object} params_p name
   * @returns {void}
   */
  endTestCall() {
    const { bedCart, doFetchDeviceAction, bedCartId } = this.props;

    doFetchDeviceAction({
      machine_name: bedCart.machine_name,
      command: end_test_call,
      id: bedCartId,
    });
  }

  /**
   * @description Sends Play Sound command
   * * @param {string} bedCart.machine_name
     * @param {string} command
     * @param {object} params_p
   * @returns {void}
   */
  ringDoorbell() {
    const { bedCart, doFetchDeviceAction, bedCartId } = this.props;

    doFetchDeviceAction({
      machine_name: bedCart.machine_name,
      command: ring_doorbell,
      id: bedCartId,
    });
  }

  /**
   * @description Sends Device command and turns off TeamViewer
   * * @param {string} bedCart.machine_name
     * @param {string} command
     * @param {object} params_p
   * @returns {void}
   */
  sendDeviceCommand() {
    const { command } = this.state;
    const {
      bedCart, bedCartId, doFetchDeviceAction, saveUheBedCartEdit,
    } = this.props;
    bedCart.teamviewer_on = 0;

    saveUheBedCartEdit({
      showToast: false,
      body: bedCart,
      id: bedCartId,
    });

    doFetchDeviceAction({
      machine_name: bedCart.machine_name,
      command,
      id: bedCartId,
    });
  }

  /**
   * Renders Tech actions card
   * @returns {JSX.Element} Technician Component
   */
  render() {
    const { loading, canHardReboot } = this.props;

    return (
      <div>
        <Card
          loading={loading}
          title={<IntlMessages id="configuration.bedsCarts.actions.title" />}
          className="gx-card"
        >
          <Row gutter={[16, 20]} className="form-item-row tech-cell">
            <Col lg={12} md={12} sm={12} xs={24}>
              <span className="tech-prop">
                <IntlMessages id="configuration.technician.testCall" />
              </span>
            </Col>
            <Col lg={12} md={12} sm={12} xs={24}>
              <Button
                onClick={this.testCall}
                className="tech-button"
              >
                <IntlMessages id="configuration.bedsCarts.actions.testCall" />
              </Button>
            </Col>
          </Row>
          <Row gutter={[16, 20]} className="form-item-row tech-cell">
            <Col lg={12} md={12} sm={12} xs={24}>
              <span className="tech-prop"><IntlMessages id="configuration.technician.endTestCall" /></span>
            </Col>
            <Col lg={12} md={12} sm={12} xs={24}>
              <Button onClick={this.endTestCall} className="tech-button">
                <IntlMessages id="configuration.bedsCarts.actions.endCall" />
              </Button>
            </Col>
          </Row>
          <Row gutter={[16, 20]} className="form-item-row tech-cell">
            <Col lg={12} md={12} sm={12} xs={24}>
              <span className="tech-prop"><IntlMessages id="configuration.technician.ringDoorbell" /></span>
            </Col>
            <Col lg={12} md={12} sm={12} xs={24}>
              <Button onClick={this.ringDoorbell} className="tech-button">
                <IntlMessages id="configuration.bedsCarts.actions.ringDoorbell" />
              </Button>
            </Col>
          </Row>
          <Row gutter={[16, 20]} className="form-item-row tech-cell">
            <Col lg={12} md={12} sm={12} xs={24}>
              <span className="tech-prop"><IntlMessages id="configuration.bedsCarts.actions.softReboot" /></span>
            </Col>
            <Col lg={12} md={12} sm={12} xs={24}>
              <Popover content={<IntlMessages id="configuration.bedsCarts.actions.softRebootDescription" />}>
                <Button onClick={() => this.showConfirm(soft_reboot)} className="tech-button">
                  <IntlMessages id="configuration.bedsCarts.actions.reboot" />
                </Button>
              </Popover>
            </Col>
          </Row>
          <Row gutter={[16, 20]} className="form-item-row tech-cell">
            <Col lg={12} md={12} sm={12} xs={24}>
              <span className="tech-prop"><IntlMessages id="configuration.bedsCarts.actions.restartOs" /></span>
            </Col>
            <Col lg={12} md={12} sm={12} xs={24}>
              <Popover content={<IntlMessages id="configuration.bedsCarts.actions.restartOsDescription" />}>
                <Button onClick={() => this.showConfirm(reboot_os)} className="tech-button">
                  <IntlMessages id="configuration.bedsCarts.actions.reboot" />
                </Button>
              </Popover>
            </Col>
          </Row>
          <Row gutter={[16, 20]} className="form-item-row tech-cell">
            <Col lg={12} md={12} sm={12} xs={24}>
              <span className="tech-prop"><IntlMessages id="configuration.bedsCarts.actions.hardReboot" /></span>
            </Col>
            <Col lg={12} md={12} sm={12} xs={24}>
              <Popover content={<IntlMessages id="configuration.bedsCarts.actions.hardRebootDescription" />}>
                <Button disabled={canHardReboot} onClick={() => this.showConfirm(hard_reboot)} className="tech-button">
                  <IntlMessages id="configuration.bedsCarts.actions.reboot" />
                </Button>
              </Popover>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

TechActionsCard.defaultProps = {
  canHardReboot: () => false,
};

TechActionsCard.propTypes = {
  bedCart: PropTypes.object,
  doFetchDeviceAction: PropTypes.func,
  fetchCallAction: PropTypes.func,
  saveUheBedCartEdit: PropTypes.func,
  intl: PropTypes.object,
  loading: PropTypes.bool,
  canHardReboot: PropTypes.func,
};

/**
 * Maps Actions to Props
 * @param {function} dispatch Dispatches Actions
 * @returns {object} Actions
 */
const mapDispatchToProps = (dispatch) => ({
  doFetchDeviceAction: (data) => dispatch(fetchDeviceAction(data)),
  saveUheBedCartEdit: (data) => dispatch(saveUheBedCart(data)),
});

export default connect(
  null,
  mapDispatchToProps,
)(injectIntl(withRouter(TechActionsCard)));
