export const machine_name = 'machine_name';
export const command = 'command';
export const params_p = 'params_p';
export const soft_reboot = 'soft_reboot';
export const hard_reboot = 'hard_reboot';
export const factory_reset = 'factory_reset';
export const hang_up_call = 'end_call';
export const alert = 'alert';
export const update_version = 'update_version';
export const mac_refresh = 'mac_refresh';
export const test_call = 'test_call';
export const end_test_call = 'end_test_call';
export const ring_doorbell = 'ring_doorbell';
export const reboot_os = 'reboot_os';
