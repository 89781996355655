import React from 'react';
import ExportCsv from '@components/ExportCsv/ExportCsv';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

export const AddButton = React.memo(({
  visible,
  url,
}) => {
  const intl = useIntl();
  return visible ? (
    <Link to={url} shape="circle" icon="+" className="page-icons page-icon-plus">
      <i className="icon icon-add" />
      <span>{intl.formatMessage({ id: 'common.addText' })}</span>
    </Link>
  ) : null;
});
AddButton.displayName = 'AddButton';
AddButton.propTypes = {
  visible: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
};

export const ImportButton = React.memo(({
  visible,
  url,
}) => {
  const intl = useIntl();
  return visible ? (
    <Link
      to={url}
      shape="circle"
      className="page-icons page-icon-bulk-upload"
    >
      <i className="icon icon-upload" />
      <span>{intl.formatMessage({ id: 'common.bulkUpload' })}</span>
    </Link>
  ) : null;
});
ImportButton.displayName = 'ImportButton';
ImportButton.propTypes = {
  visible: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
};

export const ExportButton = React.memo(({
  visible,
  url,
}) => (visible
  ? (
    <ExportCsv
      getCsvUrl={url}
    />
  ) : null));
ExportButton.displayName = 'ExportButton';
ExportButton.propTypes = {
  visible: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
};
