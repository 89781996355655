import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Card, Input, Cascader, Row, Col, Form, Switch, Popover, Modal,
} from 'antd';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import EditHeader from '@components/uhe/configuration/EditHeader';
import ActionsCard from '@components/uhe/configuration/bedsCarts/ActionsCard';
import SettingOptionsCard from '@components/uhe/configuration/bedsCarts/SettingOptionsCard';
import IpConfigurationCard from '@components/uhe/configuration/bedsCarts/IpConfigurationCard';
import DeviceAttrCard from '@components/uhe/configuration/bedsCarts/DeviceAttrCard';
import StatusReport from '@components/uhe/configuration/bedsCarts/StatusReport';
import ActiveComponentsCard from '@components/uhe/configuration/bedsCarts/ActiveComponentsCard';
import {
  fetchBedCart,
  fetchDeviceTypes,
  clearBedCart,
  saveUheBedCart,
  fetchCareDeviceModes,
  fetchAcknowledgedByEmail,
} from '@uhe_actions/configuration/bedsCarts/bedsCartsActions';
import { fetchCustomer } from '@uhe_actions/configuration/customers/CustomersActions';
import { onFetchOwnPofile } from '@uhe_actions/configuration/users/UsersActions';
import { systemOnFetchData } from '@uhe_actions/system/SystemActions';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import { FORM_LAYOUT } from '@constants/UHESettings';
import { goBackTo } from '@util/UheHelper';
import { withLastLocation } from 'react-router-last-location';
import { shouldShowEditHeader } from '@util/UheRoleChecker';
import { cloneDeep } from 'lodash';

const { confirm } = Modal;
const { TextArea } = Input;
/**
 * Renders Manage uhe bed/carts view
 */
class ManageUheBedCart extends Component {
  /**
   * Validate if URL has protocol prefix and
   * if there is no blank spaces or invalid characters
   * @param {string} url URL String
   * @returns {boolean} Regex Pattern
   */
  static validateURL(url) {
    const pattern = new RegExp('^(https?:\\/\\/)'
      + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'
      + '((\\d{1,3}\\.){3}\\d{1,3}))'
      + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'
      + '(\\?[;&a-z\\d%_.~+=-]*)?'
      + '(\\#[-a-z\\d_]*)?$', 'i');

    return !!pattern.test(url);
  }

  /**
   * Renders error message if the URL is not valid
   * @param {string} url url
   * @returns {JSX} render error message
   */
  static renderErrorMessage(url) {
    let message = null;
    if (!ManageUheBedCart.validateURL(url) && url) {
      message = (
        <div className="errorMessage">
          <IntlMessages id="configuration.bedsCarts.alert.invalidUrl" />
        </div>
      );
    }

    return message;
  }

  formRef = React.createRef();

  /**
   * ManageUheBedCart Constructor
   * @param {*} props Props
   */
  constructor(props) {
    super(props);
    const { setSubtitle, intl } = this.props;
    this.intl = intl;

    this.formRef = React.createRef();

    this.state = {
      bedCart: {
        endpointStatus: {},
        endpointAttributes: [],
        components: [],
        notes: '',
        support_ticket: '',
        under_maintenance: 0,
        device_config_mode: '',
        name: '',
        acknowledged_by: '',
      },
      deviceTypes: [],
    };

    setSubtitle('configuration.bedsCarts.editUheTitle');

    this.onChangeDeviceType = this.onChangeDeviceType.bind(this);
    this.saveUheBedCart = this.saveUheBedCart.bind(this);
    this.onChangeInputHandler = this.onChangeInputHandler.bind(this);
    this.onChangeOption = this.onChangeOption.bind(this);
    this.onChangeAction = this.onChangeAction.bind(this);
    this.onChangeDeviceAttr = this.onChangeDeviceAttr.bind(this);
    this.onChangeAuth = this.onChangeAuth.bind(this);
    this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
  }

  /**
   * Updates local state
   * @returns {void}
   */
  componentDidMount() {
    const {
      match,
      clearBedCart,
      fetchDeviceTypes,
      systemOnFetchData,
      location,
      fetchBedCart,
      fetchCareDeviceModes,
      fetchCustomer,
      fetchAcknowledgedByEmail,
    } = this.props;
    const urlId = match.params.id || '';
    const customerId = match.params.customer_id || '';

    if (urlId) {
      clearBedCart();
      fetchDeviceTypes();
      systemOnFetchData();
      fetchCareDeviceModes();
      fetchCustomer({ id: customerId });
      fetchBedCart({ id: urlId });
      fetchAcknowledgedByEmail();
    }

    if (location.state) {
      const nonUheBedCart = location.state.nonUheDevice;
      nonUheBedCart.endpoint_type = location.state.selectedDevice;
      this.setState({ bedCart: nonUheBedCart });
    }
  }

  /**
   * Updates local state depends on props
   * @param {object} prevProps prevProps
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const {
      bedCart, deviceTypes, error, history,
    } = this.props;
    if (prevProps.error !== error) {
      // handle system error
      if (error.code === 404) {
        history.push('/configuration/beds-carts/uhe-units');
        return;
      }
    }
    if (prevProps.bedCart !== bedCart) {
      this.setState({ bedCart });
      this.formRef.current.setFieldsValue({
        name: bedCart.name,
      });
    }
    if (
      Object.keys(prevProps.bedCart).length === 0
      && Object.keys(bedCart).length > 0
    ) {
      this.setState({ bedCart: cloneDeep(bedCart) });
    }
    if (
      prevProps.deviceTypes.length === 0
      && deviceTypes.length > 0
    ) {
      this.setState({ deviceTypes });
    }
  }

  /**
   * Handle Changes on Device Types Dropdown and Updates Local State
   * @param {array} device Device Data
   * @returns {void}
   */
  onChangeDeviceType(device) {
    const { bedCart } = this.state;
    const { history, match } = this.props;

    bedCart.endpoint_type = device[0];
    if (device[0] === 'Standards Based') {
      history.push(
        `/configuration/beds-carts/non-uhe-units/edit/${match.params.id}`,
        { uheDevice: bedCart, selectedDevice: device[0] },
      );
      return;
    }
    this.setState({ bedCart });
  }

  /**
   * Handle Changes on the Alert URL Input Field and Updates Local State
   * @param {Object} event event
   * @param {string} key key
   * @returns {void}
   */
  onChangeInputHandler(event, key) {
    if (key === 'hostname') {
      const { bedCart: originalBedCart } = this.state;
      const bedCart = cloneDeep(originalBedCart);
      const hardware = bedCart.endpointHardware;
      hardware.hostname = event.target.value;
      this.setState({ bedCart });
    } else {
      const { bedCart } = this.state;
      const newState = { ...bedCart, [key]: event.target.value };
      this.setState({ bedCart: newState });
    }
  }

  /**
   * Handle Changes on the Alert URL CheckBox and Updates Local State
   * @param {Object} event event
   * @param {string} key key
   * @returns {void}
   */
  onChangeCheckBox(event, key) {
    const { bedCart } = this.state;
    const newState = { ...bedCart, [key]: event.target.checked };
    this.setState({ bedCart: newState });
  }

  /**
   * Handle Changes on Each Setting Options Dropdown and Updates Local State
   * @param {number} vol vol
   * @param {string} key key
   * @returns {void}
   */
  onChangeOption(vol, key) {
    const { bedCart } = this.state;
    const newState = { ...bedCart, [key]: parseInt(vol) };

    this.setState({ bedCart: newState });
  }

  /**
   * Handle Changes on Each Switch Action and Updates Local State
   * @param {boolean} isOn isOn
   * @param {string} key key
   * @returns {void}
   */
  onChangeAction(isOn, key) {
    const { bedCart } = this.state;
    const newState = { ...bedCart, [key]: isOn ? 1 : 0 };

    this.setState({
      bedCart: newState,
    });
  }

  /**
   * Handle Changes in Device Attributes Input Fields and Updates Local State
   * @param {string|number} value Value
   * @param {object} attr Attribute Data
   * @returns {void}
   */
  onChangeDeviceAttr(value, attr) {
    const { bedCart: originalBedCart } = this.state;
    const bedCart = cloneDeep(originalBedCart);
    const attributes = bedCart.endpointAttributes
      .find((attribute) => attribute.endpointAttributeType.id === attr.endpointAttributeType.id);
    attributes.value = value;

    this.setState({ bedCart });
  }

  /**
   * Handle Changes in the Enterprise Wifi Authentication and
   * Inner Authentication Dropdown and Updates Local State
   * @param {string} value Auth Value
   * @param {Object} auth auth
   * @param {string} key key
   * @returns {void}
   */
  onChangeAuth(value, auth, key) {
    const { bedCart: originalBedCart } = this.state;
    const bedCart = cloneDeep(originalBedCart);
    bedCart[key] = value;
    this.setState({ bedCart });
  }

  /**
   *  Handle Changes in Acknowledged Switch and Updates Local State
   * @param {any} key key
   * @param {boolean} checked checked
   * @returns {void}
   */
  onChangeHendleSwitch(key, checked) {
    const newState = !!checked;
    const { bedCart } = this.state;
    const { profile } = this.props;
    if (newState) {
      this.setState({ bedCart: { ...bedCart, [key]: profile.username } });
    } else {
      this.setState({ bedCart: { ...bedCart, [key]: null } });
    }
    return newState;
  }

  /**
   * Change cascader options
   * @param {String} value value
   * @param {Object} label label
   * @returns {void}
   */
  onSelectMode(value, label) {
    const { bedCart } = this.state;
    bedCart.device_config_mode = label[0].label;

    if (bedCart.device_config_mode === 'INSTALL_TESTING') {
      bedCart.is_live = 0;
      bedCart.customer_auto_notification = false;
      bedCart.under_maintenance = 0;
    } else if (bedCart.device_config_mode === 'OPERATIONAL') {
      bedCart.is_live = 1;
      bedCart.customer_auto_notification = true;
      bedCart.under_maintenance = 0;
    } else if (bedCart.device_config_mode === 'PRIVATE_DISABLED') {
      bedCart.is_live = 1;
      bedCart.customer_auto_notification = true;
      bedCart.under_maintenance = 1;
    }

    this.setState({ ...bedCart });
  }

  /**
   * Change isLive switch
   * @param {String} key key
   * @param {Object} checked checked
   * @returns {void}
   */
  onChangeIsLiveSwitch(key, checked) {
    const { bedCart } = this.state;
    const newState = checked ? 1 : 0;

    if (newState) {
      this.setState({ bedCart: { ...bedCart, ...bedCart, [key]: newState } });
    } else {
      this.setState({ bedCart: { ...bedCart, ...bedCart, [key]: newState } });
    }
  }

  /**
   * Change Auto Notification switch
   * @param {String} key key
   * @param {Object} checked checked
   * @returns {void}
   */
  onChangeAutoNotification(key, checked) {
    const { bedCart } = this.state;
    const newState = !!checked;

    if (newState) {
      this.setState({ bedCart: { ...bedCart, ...bedCart, [key]: newState } });
    } else {
      this.setState({ bedCart: { ...bedCart, ...bedCart, [key]: newState } });
    }
  }

  /**
 * Check for can_hard_reboot property property for device
 * @returns {boolean} true||false
 */
  canHardReboot = () => {
    const { deviceTypes, bedCart } = this.state;
    let test = false;
    deviceTypes.forEach((device, index) => {
      if (device.value === bedCart.endpoint_type && device.can_hard_reboot !== 1) {
        test = true;
      }
    });
    return test;
  }

  /**
   * Show Confirmation Modal When Save Button is Clicked and hostname is changed
   * @returns {void}
   */
   showConfirm = () => {
     if (this.state.bedCart?.endpointHardware?.hostname !== this.props.bedCart?.endpointHardware?.hostname) {
       const { intl } = this.props;
       confirm({
         title: intl.formatMessage({ id: 'configuration.bedsCarts.statusReport.ChangeHostname' }),
         onOk: this.saveUheBedCart,
         autoFocusButton: 'cancel',
         className: 'dark-mode-modal',
       });
     } else {
       this.saveUheBedCart();
     }
   }

   /**
   * TODO - Save chanages upon click on the save button
   * @param {event} event event
   * @returns {void}
   */
   saveUheBedCart() {
     Modal.destroyAll();
     const { bedCart } = this.state;
     const {
       match,
       saveUheBedCartEdit,
     } = this.props;
     const isValidUrl = this.validateURL(bedCart.elert_url);

     if (isValidUrl || !bedCart.elert_url) {
       saveUheBedCartEdit({
         body: bedCart,
         id: match.params.id,
       });
     }
   }

   /**
   * Validate if URL has protocol prefix and
   * if there is no blank spaces or invalid characters
   * @param {string} url url
   * @return {boolean} regex
   */
   validateURL(url) {
     const pattern = new RegExp('^(https?:\\/\\/)'
      + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'
      + '((\\d{1,3}\\.){3}\\d{1,3}))'
      + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'
      + '(\\?[;&a-z\\d%_.~+=-]*)?'
      + '(\\#[-a-z\\d_]*)?$', 'i');

     return !!pattern.test(url);
   }

   /**
   *  Adapt data returned by the server
   * @return {Array} array
   */
   modesData() {
     const { modes } = this.props;
     const adaptedData = [];
     if (modes) {
       Object.values(modes).forEach((value, index) => {
         adaptedData.push({
           label: value,
           value: index,
         });
       });
     }
     return adaptedData;
   }

   /**
   * Render title, back and save buttons
   * @returns {JSX} Header Title, Back and Save Buttons
   */
   renderHeadLine() {
     const { loading, lastLocation, history } = this.props;

     if (shouldShowEditHeader()) {
       return (
         <EditHeader
           goBack={goBackTo('/configuration/beds-carts/uhe-units', lastLocation?.pathname, history)}
           save={this.showConfirm}
           loading={loading}
         />
       );
     }

     return null;
   }

   /**
   * Renders ManageUheBedCart Component
   * @returns {JSX} ManageUheBedCart Component
   */
   render() {
     const { bedCart, deviceTypes } = this.state;
     const {
       intl, loading, latestVersion, match, customer, profile, acknowledged_email,
     } = this.props;
     const { acknowledged_by } = bedCart;
     const room = bedCart ? bedCart.room : '';
     const unitName = room ? room.name : '';
     const facilityName = room ? room.building.name : '';

     return (
       <div className="manage-customer-wrapper uheBedCart">
         {this.renderHeadLine()}
         <Form ref={this.formRef} {...FORM_LAYOUT} name="uheBedCart" autoComplete="off">
           <Row gutter={16}>
             <Col xl={8} lg={24} md={24} sm={24} xs={24}>
               <Card
                 loading={loading}
                 className="gx-card"
                 title={(
                   <>
                     <div className="fac-unit-names">
                       {facilityName}
                       {' '}
                       /
                       {' '}
                       {unitName}
                       {' '}
                     </div>
                     <IntlMessages id="configuration.bedsCarts.roomBedName" />
                   </>
                )}
               >
                 <Form.Item
                   colon={false}
                   name="name"
                   rules={[
                     {
                       required: true,
                       message: intl.formatMessage({ id: 'configuration.users.emptyField' }),
                     },
                   ]}
                   wrapperCol={{
                     xl: { span: 24 },
                   }}
                 >
                   <Input
                     className="room-bed-name"
                     autoComplete="off"
                     onChange={(e) => this.onChangeInputHandler(e, 'name')}
                     value={bedCart.name}
                   />
                 </Form.Item>
               </Card>
               <Card
                 loading={loading}
                 className="gx-card"
                 title={<IntlMessages id="configuration.bedsCarts.deviceDetails" />}
               >
                 <Form.Item
                   colon={false}
                   className="gx-align-items-center"
                   label={<IntlMessages id="configuration.bedsCarts.deviceType" />}
                 >
                   <Cascader
                     getPopupContainer={() => document.querySelector('.ant-cascader-picker')}
                     expandTrigger="hover"
                     size="large"
                     key="deviceTypes"
                     options={deviceTypes || []}
                     fieldNames={{ label: 'value', value: 'value' }}
                     value={[bedCart.endpoint_type]}
                     onChange={this.onChangeDeviceType}
                   />
                 </Form.Item>
                 <Form.Item
                   colon={false}
                   className="gx-align-items-center"
                   label={<IntlMessages id="configuration.bedsCarts.parenPartSerialNumber" />}
                 >
                   {bedCart.machine_parent_part_serial_number}
                 </Form.Item>
                 <Form.Item
                   colon={false}
                   className="gx-align-items-center"
                   label={<IntlMessages id="configuration.bedsCarts.acknowledged.notes" />}
                 >
                   <TextArea
                     autoSize={{ minRows: 3, maxRows: 10 }}
                     autoComplete="off"
                     name="notes"
                     value={bedCart.notes}
                     onChange={(e) => this.onChangeInputHandler(e, 'notes')}
                   />
                 </Form.Item>
               </Card>
               <ActionsCard
                 bedCart={bedCart}
                 match={match}
                 onChangeAction={this.onChangeAction}
                 loading={loading}
                 loggedUser={profile}
                 canHardReboot={this.canHardReboot()}
               />
               <Card
                 loading={loading}
                 className="gx-card"
                 title={
                   <IntlMessages id="configuration.bedsCarts.alert.alertUrl" />
                }
               >
                 <Form.Item
                   colon={false}
                   className="gx-align-items-center"
                   label={
                     <IntlMessages id="configuration.bedsCarts.alert.alertUrl" />
                  }
                 >
                   <Input
                     autoComplete="off"
                     onChange={(e) => this.onChangeInputHandler(e, 'elert_url')}
                     value={bedCart.elert_url}
                     longdesc={this.intl.formatMessage({
                       id: 'configuration.bedsCarts.descriptions.alertUrl',
                     })}
                   />
                   {ManageUheBedCart.renderErrorMessage(bedCart.elert_url)}
                 </Form.Item>
               </Card>
               <SettingOptionsCard
                 bedCart={bedCart}
                 onChangeOption={this.onChangeOption}
                 loading={loading}
               />
               <Card
                 loading={loading}
                 title={<IntlMessages id="configuration.bedsCarts.acknowledged.title" />}
                 className="gx-card"
               >

                 <Form.Item
                   colon={false}
                   className="gx-align-items-center"
                   label={(
                     <Popover content={<IntlMessages id="configuration.bedsCarts.acknowledged.popoverAcknowledged" />} overlayStyle={{ width: '35%' }}>
                       <span className="device-attr-title">
                         <IntlMessages id="configuration.bedsCarts.acknowledged.acknowledgedSwitchTitle" />
                       </span>
                     </Popover>
                  )}
                 >
                   <Switch
                     name="acknowledged_by"
                     checked={bedCart.acknowledged_by !== null}
                     onChange={(e) => { this.onChangeHendleSwitch('acknowledged_by', e); }}
                   />
                 </Form.Item>
                 <Form.Item
                   colon={false}
                   className="gx-align-items-center"
                   label={(
                     <Popover content={<IntlMessages id="configuration.bedsCarts.acknowledged.popoverAcknowledgedBy" />}>
                       <span className="device-attr-title">
                         <IntlMessages id="configuration.bedsCarts.acknowledged.acknowledgedby" />
                       </span>
                     </Popover>
                  )}
                 >
                   {bedCart.acknowledged_by
                    && <span className="acknowledged-email">{acknowledged_by}</span>}
                 </Form.Item>
                 <Form.Item
                   colon={false}
                   className="gx-align-items-center"
                   label={<IntlMessages id="configuration.bedsCarts.acknowledged.supportTicket" />}
                 >
                   <Input
                     autoComplete="off"
                     name="support_ticket"
                     value={bedCart.support_ticket}
                     onChange={(e) => this.onChangeInputHandler(e, 'support_ticket')}
                   />
                 </Form.Item>
               </Card>
               {/* Stop */}
               <Card
                 loading={loading}
                 className="gx-card"
                 title={
                   <IntlMessages id="configuration.bedsCarts.activeComponents.title" />
                }
               >
                 <ActiveComponentsCard bedCart={bedCart} bedCartId={match.params.id} imgColSize={6} />
               </Card>
             </Col>
             <Col xl={16} lg={24} md={24} sm={24} xs={24}>
               <Card
                 loading={loading}
                 className="gx-card"
                 title={
                   <IntlMessages id="configuration.bedsCarts.statusReport.title" />
                }
               >
                 <StatusReport
                   updateHostname={this.onChangeInputHandler}
                   bedCart={bedCart}
                   latestVersion={latestVersion}
                   loading={loading}
                   timeZone={customer.time_zone}
                 />
               </Card>
               <Card
                 loading={loading}
                 className="gx-card"
                 title={<IntlMessages id="configuration.bedsCarts.deviceConfig.deviceConfigStatus" />}
               >
                 <Form.Item
                   colon={false}
                   className="gx-align-items-center"
                   label={(
                     <Popover content={<IntlMessages id="configuration.bedsCarts.deviceConfig.titleDeviceConfig" />}>
                       <span className="device-attr-title">
                         <IntlMessages id="configuration.bedsCarts.deviceConfig.mode" />
                       </span>
                     </Popover>
                  )}
                 >
                   <Cascader
                     getPopupContainer={() => document.querySelector('.ant-cascader-picker-focused')}
                     className="top-filter-popup"
                     expandTrigger="hover"
                     size="large"
                     value={[bedCart.device_config_mode]}
                     options={this.modesData()}
                     onChange={(value, label) => this.onSelectMode(value, label)}
                   />
                 </Form.Item>
                 <Form.Item
                   colon={false}
                   className="gx-align-items-center"
                   label={(
                     <Popover content={<IntlMessages id="configuration.bedsCarts.deviceConfig.titleCaregilityMonitor" />}>
                       <span className="device-attr-title">
                         <IntlMessages id="configuration.bedsCarts.deviceConfig.caregilityMonitor" />
                       </span>
                     </Popover>
                  )}
                 >
                   <Switch
                     name="is_live"
                     checked={!!bedCart.is_live}
                     onChange={(e) => { this.onChangeIsLiveSwitch('is_live', e); }}
                   />
                 </Form.Item>
                 <Form.Item
                   colon={false}
                   className="gx-align-items-center"
                   label={(
                     <Popover content={<IntlMessages id="configuration.bedsCarts.deviceConfig.titleCustomerNotification" />}>
                       <span className="device-attr-title">
                         <IntlMessages id="configuration.bedsCarts.deviceConfig.customerNotification" />
                       </span>
                     </Popover>
                  )}
                 >
                   <Switch
                     name="customer_auto_notification"
                     checked={!!bedCart.customer_auto_notification}
                     onChange={(e) => { this.onChangeAutoNotification('customer_auto_notification', e); }}
                   />
                 </Form.Item>
                 <Form.Item
                   colon={false}
                   className="gx-align-items-center"
                   label={(
                     <Popover content={<IntlMessages id="configuration.bedsCarts.deviceConfig.titileCallInOut" />}>
                       <span className="device-attr-title">
                         <IntlMessages id="configuration.bedsCarts.deviceConfig.callInOut" />
                       </span>
                     </Popover>
                  )}
                 >
                   {bedCart.under_maintenance === 1
                     ? this.intl.formatMessage({ id: 'common.callDisabled' })
                     : this.intl.formatMessage({ id: 'common.callEnabled' })}
                 </Form.Item>
               </Card>
               <IpConfigurationCard
                 bedCart={bedCart}
                 onChangeInputHandler={this.onChangeInputHandler}
                 onChangeAuth={this.onChangeAuth}
                 onChangeCheckBox={this.onChangeCheckBox}
                 loading={loading}
                 loggedUser={profile}
               />
               <DeviceAttrCard
                 bedCart={bedCart}
                 onChangeDeviceAttr={this.onChangeDeviceAttr}
                 loading={loading}
               />
             </Col>
           </Row>
         </Form>
         {this.renderHeadLine()}
       </div>
     );
   }
}

ManageUheBedCart.defaultProps = {
  bedCart: {},
  deviceTypes: [],
  loading: true,
  acknowledged_email: '',
};

ManageUheBedCart.propTypes = {
  bedCart: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  deviceTypes: PropTypes.array,
  intl: PropTypes.object,
  location: PropTypes.object,
  loading: PropTypes.bool,
  latestVersion: PropTypes.number,
  fetchBedCart: PropTypes.func,
  fetchDeviceTypes: PropTypes.func,
  setSubtitle: PropTypes.func,
  systemOnFetchData: PropTypes.func,
  profile: PropTypes.object,
  fetchCareDeviceModes: PropTypes.func,
  modes: PropTypes.object,
  lastLocation: PropTypes.object.isRequired,
  customer: PropTypes.shape().isRequired,
  fetchAcknowledgedByEmail: PropTypes.func,
  acknowledged_email: PropTypes.string,
  canHardReboot: PropTypes.func,
  hasBattery: PropTypes.func,
};

const mapStateToProps = ({
  bedsCarts,
  SystemSettingsSystem,
  common,
  subtitle,
  ConfigurationUsers,
  ConfigurationCustomers,
}) => {
  const { production_version = {} } = SystemSettingsSystem.data;
  const latestVersion = parseFloat(production_version.value) || null;

  return {
    acknowledged_email: bedsCarts.acknowledged_email,
    error: common.error,
    bedCart: bedsCarts.selectedBedCart,
    latestVersion,
    deviceTypes: bedsCarts.deviceTypes,
    loading: bedsCarts.loading,
    subtitle,
    profile: ConfigurationUsers.ownUser,
    modes: bedsCarts.modes,
    customer: ConfigurationCustomers.selectedCustomer,
  };
};

/**
 * Maps Actions to Props
 * @param {func} dispatch dispatch function
 * @returns {object} Actions
 */
const mapDispatchToProps = (dispatch) => ({
  clearBedCart: () => dispatch(clearBedCart()),
  fetchBedCart: (id) => dispatch(fetchBedCart(id)),
  fetchAcknowledgedByEmail: (id) => dispatch(fetchAcknowledgedByEmail(id)),
  fetchDeviceTypes: (data) => dispatch(fetchDeviceTypes(data)),
  systemOnFetchData: (page) => dispatch(systemOnFetchData(page)),
  saveUheBedCartEdit: (data) => dispatch(saveUheBedCart(data)),
  onFetchOwnPofile: () => dispatch(onFetchOwnPofile()),
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  fetchCareDeviceModes: () => dispatch(fetchCareDeviceModes()),
  fetchCustomer: (id) => dispatch(fetchCustomer(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLastLocation(injectIntl(withRouter(ManageUheBedCart))));
