import {
  CONFIGURATION_FACILITIES_FETCH_SUCCESS,
  CONFIGURATION_FACILITIES_FETCH_DATA,
  DELETE_FACILITY_SUCCESS,
  SAVE_FACILITY_REQUEST,
  SAVE_FACILITY_SUCCESS,
  FETCH_FACILITY_SUCCESS,
  FETCH_FACILITY_REQUEST,
  CLEAR_FACILITY_STATE,
  SAVE_FACILITY_FAIL,
} from '@constants/UHEActionTypes';
import { tableContent } from 'util/UheHelper';

const INIT_STATE = {
  selectedFacility: {},
  loading: false,
  table: {
    list: [],
    page: {},
  },
};

/**
 * Facilities Reducers
 * @param  {Object} state Current state
 * @param  {String} action object
 * @returns {Object} state
 */
export default (state = { ...INIT_STATE }, action) => {
  let currentTable;
  let newState;
  switch (action.type) {
    case CONFIGURATION_FACILITIES_FETCH_SUCCESS:
      currentTable = tableContent(action.data, state.table);
      newState = { table: currentTable, loading: false };
      return { ...state, ...newState };
    case CONFIGURATION_FACILITIES_FETCH_DATA:
      return { ...state, loading: true };
    case FETCH_FACILITY_REQUEST:
      return { ...state, loading: true, savedFacilityId: null };
    case DELETE_FACILITY_SUCCESS: {
      const selectedFacility = { ...INIT_STATE.selectedFacility };
      newState = { selectedFacility };
      return { ...state, ...newState };
    }
    case SAVE_FACILITY_REQUEST: {
      return { ...state, loading: true };
    }
    case SAVE_FACILITY_FAIL:
      return { ...state, loading: false };
    case SAVE_FACILITY_SUCCESS: {
      newState = { savedFacilityId: action.payload.id };
      return { ...state, ...newState, loading: false };
    }
    case FETCH_FACILITY_SUCCESS: {
      const selectedFacility = { ...state.selectedFacility, ...action.payload };
      newState = { selectedFacility, loading: false };
      return { ...state, ...newState };
    }
    case CLEAR_FACILITY_STATE: {
      return { ...INIT_STATE };
    }

    default:
      return state;
  }
};
