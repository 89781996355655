import React, { PureComponent, useRef } from 'react';
import { GoogleMap, withGoogleMap } from 'react-google-maps';
import _ from 'lodash';
import { Input, Card, Form } from 'antd';
import { SearchBox } from 'react-google-maps/lib/components/places/SearchBox';
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel';
import IntlMessages from '@util/IntlMessages';
import { injectIntl } from 'react-intl';

import {
  ADDRESS_POSTAL_CODE,
  ADDRESS_COUNTRY,
  ADDRESS_CITY,
  ADDRESS_STATE,
} from '@constants/UHESettings';
import { connect } from 'react-redux';

const FacilityMap = withGoogleMap((props) => {
  const {
    facilityData, intl, onChangeHandlerAddress, facility,
  } = props;
  const { google } = window;
  const marker = useRef(null);
  const searchBox = useRef(null);
  const map = useRef(null);

  /**
   * Changes marker on location change and updates state with the proper data
   * @returns {void}
   */
  const onPlacesChanged = () => {
    const places = searchBox.current.getPlaces();
    let lat;
    let lng;
    let country = '';
    let adrState = '';
    let city = '';
    let street = '';
    let zip = '';

    places.forEach((place) => {
      lat = place.geometry.location.lat();
      lng = place.geometry.location.lng();
      street = place.formatted_address;

      if (place.address_components) {
        place.address_components.forEach((adr_component) => {
          adr_component.types.forEach((type) => {
            switch (type) {
              case ADDRESS_POSTAL_CODE:
                zip = adr_component.short_name;
                break;

              case ADDRESS_COUNTRY:
                country = adr_component.long_name;
                break;

              case ADDRESS_CITY:
                city = adr_component.short_name;
                break;

              case ADDRESS_STATE:
                adrState = adr_component.short_name;
                break;
              default:
                return null;
            }
          });
        });
      }

      props.handlePlaceChange(
        { lat, lon: lng },
        zip,
        country,
        city,
        adrState,
        street,
      );
    });

    map.current.panTo({ lat, lng });
  };
  const markerPos = {
    lat: facility.geo.lat,
    lng: facility.geo.lon,
  };
  const defaultPos = {
    lat: 37.773972,
    lon: -122.431297,
  };
  const selectMarker = {
    lat: facilityData?.geo?.lat ?? facility.geo.lat,
    lng: facilityData?.geo?.lon ?? facility.geo.lon,
  };

  const {
    disabled, isNew, hasTypedLocation, hasChosenLocation,
  } = props;

  return (
    <GoogleMap
      ref={map}
      defaultZoom={15}
      options={{ streetViewControl: false, fullscreenControl: false }}
      defaultCenter={{
        lat: facilityData?.geo?.lat ?? facility.geo.lat ?? defaultPos.lat,
        lng: facilityData?.geo?.lon ?? facility.geo.lon ?? defaultPos.lon,
      }}
    >
      <SearchBox
        ref={searchBox}
        onPlacesChanged={onPlacesChanged}
        controlPosition={google.maps.ControlPosition.TOP_CENTER}
      >
        <Form.Item
          colon={false}
          className="search-box"
          name="searchBox"
          initialValue={isNew ? '' : facility.address1}
          rules={[
            {
              required: true,
              message: <IntlMessages id="configuration.users.emptyField" />,
            },
            ({ getFieldValue }) => ({
              validator() {
                if (hasTypedLocation && !hasChosenLocation || getFieldValue('searchBox') !== facility.address1) {
                  return Promise.reject(new Error(intl.formatMessage({ id: 'common.error_wrong_address' })));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            autoComplete="off"
            className="facility-map-input"
            type="text"
            placeholder={intl.formatMessage({ id: 'common.error_address' })}
            onChange={onChangeHandlerAddress}
            value={facility.address1}
            disabled={disabled}
          />
        </Form.Item>
      </SearchBox>
      {facilityData.address1 && (
      <MarkerWithLabel
        ref={marker}
        position={facilityData ? selectMarker : markerPos}
        labelAnchor={new google.maps.Point(0, 190)}
      >
        <Card className="gx-card">
          <div>
            <p>
              {intl.formatMessage({ id: 'uhe.table.street' })}
              :
              {' '}
              {facilityData.address1}
            </p>
            <p>
              {intl.formatMessage({ id: 'uhe.table.city' })}
              :
              {' '}
              {facilityData.city}
            </p>
            <p>
              {intl.formatMessage({ id: 'uhe.table.state' })}
              :
              {' '}
              {facilityData.state}
            </p>
            <p>
              {intl.formatMessage({ id: 'uhe.table.zip' })}
              :
              {' '}
              {facilityData.zip}
            </p>
          </div>
        </Card>
      </MarkerWithLabel>
      )}
    </GoogleMap>
  );
});
/**
 * SimpleMap Component
 */
class SimpleMap extends PureComponent {
  /**
   * Render SimpleMap
   * @returns {JSX} render jsx
   */
  render() {
    return (
      <Card className="gx-card facility-map">
        <FacilityMap
          loadingElement={<div className="height-100-percent" />}
          containerElement={<div className="facility-map-container" />}
          mapElement={<div className="height-100-percent" />}
          {...this.props}
        />
      </Card>
    );
  }
}

/**
 * Maps Global State to Component's Props
 * @returns {Object} object
 */
const mapStateToProps = ({
  ConfigurationFacilities,
}) => ({
  facilityData: ConfigurationFacilities.selectedFacility,
});

export default connect(mapStateToProps, null)(injectIntl(SimpleMap));
