import {
  FETCH_VMR_REPORT,
  FETCH_VMR_REPORT_SUCCESS,
} from 'constants/UHEActionTypes';

/**
 * @description Handle FETCH_VMR_REPORT Action
 * @returns {Object}
 */
export const fetchVmrReport = () => {
  return {
    type: FETCH_VMR_REPORT,
  };
};

/**
 * @description Handle FETCH_VMR_REPORT_SUCCESS Action
 * @param {Object} data
 * @returns {Object}
 */
export const fetchVmrReportSuccess = (data) => {
  return {
    type: FETCH_VMR_REPORT_SUCCESS,
    data,
  };
};
