import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Row, Spin,
} from 'antd';
import IntlMessages from 'util/IntlMessages';
import { shouldShowButton, shouldShowSaveButton } from '@util/UheRoleChecker';
import { getOwnUser } from '@uhe_selectors/configuration/users/UsersSelectors';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';

/**
 * Render Header for edit/add screens
 * @param {function} goBack Cancel Button Functionality
 * @param {function} save Save Button Functionality
 * @param {boolean} loading Loading
 * @param {function} backToPreviousPage Back Button Functionality
 * @returns {JSX.Element} Edit Header
 */
const EditHeader = ({
  save, loading, backToPreviousPage,
}) => {
  const loggedUser = useSelector(getOwnUser);
  const currentUser = useSelector(({ ConfigurationUsers }) => ConfigurationUsers.selectedUser);
  const history = useHistory();

  /**
   * Handles Cancel Button Functionality
   * @return {void}
   */
  const cancelClicked = () => {
    history.goBack();
  };

  return (
    <div className="gx-mb-3">
      {loading ? (
        <Row>
          <Col align="center" lg={24} md={24} sm={24} xs={24}>
            <Spin />
          </Col>
        </Row>
      ) : (
        <Row gutter={16}>
          <Col lg={12} md={12} sm={12} xs={12} />
          <Col lg={12} md={12} sm={12} xs={12} className="save-btn-container gx-d-flex gx-justify-content-end">
            <Button
              className="back-btn header-btn gx-d-flex gx-justify-content-between"
              onClick={backToPreviousPage || (() => window.history.back())}
            >
              <i className="icon icon-arrow-left" />
              <IntlMessages id="common.back" />
            </Button>
            {shouldShowSaveButton(loggedUser, currentUser) && (
              <Button
                className="save-btn header-btn gx-d-flex gx-justify-content-between"
                onClick={save}
                htmlType="submit"
              >
                <i className="icon icon-check" />
                <IntlMessages id="configuration.save" />
              </Button>
            )}
            {shouldShowButton(loggedUser) && (
              <Button
                className="header-btn cancel-btn gx-d-flex gx-justify-content-between"
                onClick={cancelClicked}
              >
                <i className="icon icon-close" />
                <IntlMessages id="common.cancel" />
              </Button>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};

EditHeader.defaultProps = {
  loading: false,
  save: null,
  backToPreviousPage: null,
};

EditHeader.propTypes = {
  loading: PropTypes.bool,
  save: PropTypes.func,
  goBack: PropTypes.func.isRequired,
  backToPreviousPage: PropTypes.func,
};

export default EditHeader;
