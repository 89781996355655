import {
  SYSTEM_SETTINGS_SYSTEM_FETCH_DATA,
  SYSTEM_SETTINGS_SYSTEM_FETCH_SUCCESS,
  SAVE_SYSTEM_SETTINGS_SYSTEM_REQUEST,
  SAVE_SYSTEM_SETTINGS_SYSTEM_SUCCESS,
  CLEAR_SYSTEM_SETTINGS,
  SEND_TEST_EMAIL,
  SEND_TEST_EMAIL_SUCCESS,
  FETCH_LICENSING_REQUEST,
  FETCH_LICENSING_SUCCESS,
  SEND_LICENSING_KEY_REQUEST,
  SEND_LICENSING_KEY_SUCCESS,
  FETCH_APPLICATION_VERSION_REQUEST,
  FETCH_APPLICATION_VERSION_SUCCESS,
  FETCH_TWILIO_DATA,
  FETCH_TWILIO_DATA_SUCCESS,
  DELETE_TWILIO_REQUEST,
  DELETE_TWILIO_SUCCESS,
  SAVE_TWILIO,
  SAVE_TWILIO_SUCCESS,
  GET_TWILIO_REQUEST,
  GET_TWILIO_SUCCESS,
  UPDATE_TWILIO_REQUEST,
  UPDATE_TWILIO_SUCCESS,
} from '@constants/UHEActionTypes';

/**
 * @description Fetch system data
 * @param  {number} page
 * @return {Object}
 */
export const systemOnFetchData = (page) => {
  return {
    type: SYSTEM_SETTINGS_SYSTEM_FETCH_DATA,
    page,
  };
};

/**
 * @description System data response
 * @param  {Object} data
 * @return {Object}
 */
export const systemFetchDataSuccess = (data) => {
  return {
    type: SYSTEM_SETTINGS_SYSTEM_FETCH_SUCCESS,
    data,
  };
};

/**
 * @description Save system request
 * @param {Object} data
 * @return {Object}
 */
export const saveSystem = (data) => {
  return {
    type: SAVE_SYSTEM_SETTINGS_SYSTEM_REQUEST,
    payload: data,
  };
};

/**
 * @description Save request success
 * @param {object} payload
 * @return {object}
 */
export const saveSystemSuccess = (payload) => {
  return {
    type: SAVE_SYSTEM_SETTINGS_SYSTEM_SUCCESS,
    payload,
  };
};

/**
 * @description Send email request
 * @param {Object} data
 * @return {Object}
 */
export const sendTestEmail = (mail) => {
  return {
    type: SEND_TEST_EMAIL,
    payload: mail,
  };
};

/**
 * @description Send email success
 * @param {object} payload
 * @return {object}
 */
export const sendTestEmailSuccess = (payload) => {
  return {
    type: SEND_TEST_EMAIL_SUCCESS,
    payload,
  };
};

/**
 * @description Clear system settings
 */
export const clearSelection = () => {
  return {
    type: CLEAR_SYSTEM_SETTINGS,
  };
};

/**
 * @description Handles FETCH_LICENSING_REQUEST Action
 * @returns {Object}
 */
export const fetchLicensing = () => {
  return {
    type: FETCH_LICENSING_REQUEST,
  };
};

/**
 * @description Handles FETCH_LICENSING_SUCCESS Action
 * @returns {Object}
 */
export const fetchLicensingSuccess = (data) => {
  return {
    type: FETCH_LICENSING_SUCCESS,
    data,
  };
};

/**
 * @description Handles SEND_LICENSING_KEY_REQUEST Action
 * @param {Object} bodyData
 * @returns {Object}
 */
export const sendLicensingKey = (body) => {
  return {
    type: SEND_LICENSING_KEY_REQUEST,
    body,
  };
};

/**
 * @description Handles SEND_LICENSING_KEY_SUCCESS Action
 * @returns {Object}
 */
export const sendLicensingKeySuccess = () => {
  return {
    type: SEND_LICENSING_KEY_SUCCESS,
  };
};

/**
 * @description Handles FETCH_APPLICATION_VERSION_REQUEST Action
 * @returns {Object}
 */
export const fetchApplicationVersion = () => {
  return {
    type: FETCH_APPLICATION_VERSION_REQUEST
  };
};

/**
 * @description Handles FETCH_APPLICATION_VERSION_SUCCESS Action
 * @param {Object} data 
 * @returns {Object}
 */
export const fetchApplicationVersionSuccess = (data) => {
  return {
    type: FETCH_APPLICATION_VERSION_SUCCESS,
    data
  };
};

/**
 * Handles FETCH_TWILIO_DATA Action
 * @param  {number} page number
 * @param  {string} sorting of table
 * @param {string} filter columns
 * @return {Object} data
 */
export const onFetchTwilioData = (page, sorting, filter) => ({
  type: FETCH_TWILIO_DATA,
  page,
  sorting,
  filter,
});

/**
 * Handles FETCH_TWILIO_DATA_SUCCESS Action
 * @param  {Object} payload array
 * @return {Object} array
 */
export const onFetchTwilioDataSuccess = (payload) => ({
  type: FETCH_TWILIO_DATA_SUCCESS,
  payload,
});

/**
 * deleteTwilio Action Creator
 * @param {Object} payload Twilio ID
 * @return {object} Action Type, Payload ID
 */
export const deleteTwilio = (payload) => ({
  type: DELETE_TWILIO_REQUEST,
  payload,
});

/**
 * deleteTwilioSuccess Action Creator
 * @param {Object} payload Twilio ID
 * @return {object} Action Type, Payload ID
 */
export const deleteTwilioSuccess = (payload) => ({
  type: DELETE_TWILIO_SUCCESS,
  payload,
});

/**
* Handle SAVE_TWILIO Action
 * @param {Object} data payload
 * @returns {Object} data
 */
export const saveTwilio = (data) => ({
  type: SAVE_TWILIO,
  payload: { ...data },
});

/**
 * Handle SAVE_TWILIO_SUCCESS Action
 * @param {Object} payload data
 * @returns {Object} data
 */
export const saveTwilioSuccess = (payload) => ({
  type: SAVE_TWILIO_SUCCESS,
  payload: { ...payload },
});

/**
 * getTwilio Action Creator
 * @param {string} id Twilio ID
 * @returns {object} Type, Payload
 */
export const getTwilio = (id) => ({
  type: GET_TWILIO_REQUEST,
  id,
});

/**
 * getTwilioSuccess Action Creator
 * @param {object} payload Twilio Data
 * @returns {object} Type, Payload
 */
export const getTwilioSuccess = (payload) => ({
  type: GET_TWILIO_SUCCESS,
  payload,
});

/**
 * updateTwilio Action Creator
 * @param {string} payload Twilio ID
 * @returns {object} Action Type, Twilio ID
 */
export const updateTwilio = (payload) => ({
  type: UPDATE_TWILIO_REQUEST,
  payload,
});

/**
 * updateTwilioSuccess Action Creator
 * @param {object} payload Twilio Data
 * @returns {object} Action Type, Twilio Data
 */
export const updateTwilioSuccess = (payload) => ({
  type: UPDATE_TWILIO_SUCCESS,
  payload,
});
