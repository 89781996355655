import React from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'antd';
import { injectIntl } from 'react-intl';
import IntlMessages from 'util/IntlMessages';

const { confirm } = Modal;

/**
 * @description Action cell for edit and delete functionality
 * @param {string} content
 * @param {string} editLink
 * @param {string} intl
 * @param {Void} deleteAction
 * @return {JSX}
 */
const OptionsCell = ({
  editLink,
  samlLink,
  domainLink,
  intl,
  deleteAction,
}) => {

  /**
   * @description Show delete confirming
   * @returns {void}
   */
  const showDeleteConfirm = () => {
    confirm({
      title: intl.formatMessage({ id: `common.deleteQuestion` }),
      okText: intl.formatMessage({ id: `common.yes` }),
      okType: "danger",
      cancelText: intl.formatMessage({ id: `common.no` }),
      onOk: deleteAction,
    });
  };

  return (
    <div className="uhe-table-td action-butons">
      <Link
        to={editLink}
        className="edit-icon"
        title={intl.formatMessage({ id: "common.edit" })}
      >
        <IntlMessages id="common.edit" />
      </Link>
      <Button
        onClick={showDeleteConfirm}
        className="delete-icon delete icon-trash icon"
        title={intl.formatMessage({ id: "common.delete" })}
      >
        <IntlMessages className="delete icon-trash icon" id="common.delete" />
      </Button>
      <Link
        to={samlLink}
        className="edit-icon"
        title={intl.formatMessage({ id: "common.saml" })}
      >
        <IntlMessages id="common.saml" />
      </Link>
      <Link
        to={domainLink}
        className="edit-icon"
        title={intl.formatMessage({ id: "common.domain" })}
      >
        <IntlMessages id="common.domain" />
      </Link>
    </div>
  );
};

export default injectIntl(OptionsCell);
