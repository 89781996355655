import {
  CONFIGURATION_UNITS_FETCH_SUCCESS,
  CONFIGURATION_UNITS_FETCH_DATA,
  DELETE_UNITS_SUCCESS,
} from '@constants/UHEActionTypes';
import { tableContent } from 'util/UheHelper';

const INIT_STATE = {
  loading: true,
  table: {
    list: [],
    page: {},
  },
};

/**
 * Units Reducers
 * @param  {Object} state Current state
 * @param  {String} action object
 * @returns {Object} state
 */
export default (state = INIT_STATE, action) => {
  let currentTable;
  let newState;
  switch (action.type) {
    case CONFIGURATION_UNITS_FETCH_SUCCESS:
      currentTable = tableContent(action.data, state.table);
      newState = { table: currentTable, loading: false };
      return { ...state, ...newState };
    case CONFIGURATION_UNITS_FETCH_DATA:
      return { ...state, loading: true };
    case DELETE_UNITS_SUCCESS: {
      newState = {
        ...state,
        table: {
          list: state.table.list.filter((i) => i.id !== action.payload.id),
          page: {
            ...state.table.page,
            totalElements: state.table.page.totalElements - 1,
          },
        },
      };
      return newState;
    }

    default:
      return state;
  }
};
