import {
  SYSTEM_DEVELOPER_FETCH_DATA,
  SYSTEM_DEVELOPER_FETCH_DATA_SUCCESS,
} from '@constants/UHEActionTypes';
import { tableContent } from 'util/UheHelper';

const INIT_STATE = {
  loading: true,
  table: {
    list: [],
    page: {},
  },
};

/**
 * Developer Reducers
 * @param  {Object} state Current state
 * @param  {String} action object
 * @returns {Object} state
 */
export default (state = INIT_STATE, action) => {
  let currentTable;
  let newState;
  switch (action.type) {
    case SYSTEM_DEVELOPER_FETCH_DATA:
      return { ...state, loading: true };
    case SYSTEM_DEVELOPER_FETCH_DATA_SUCCESS:
      currentTable = tableContent(action.data, state.table);
      newState = { table: currentTable, loading: false };
      return { ...state, ...newState };
    default:
      return state;
  }
};
