import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * ProgramCell Functional Component
 * @param {object} content Program Content
 * @returns {JSX.Element} ProgramCell Component
 */
const ProgramCell = ({ content }) => {
  const { id, name } = content;

  if (name == null) {
    return (
      <div className="uhe-table-td">
        <Link to={`/configuration/programs/edit/${id}`} title={name}>
          <span>{name}</span>
        </Link>
      </div>
    );
  }
  return (
    <div className="uhe-table-td">
      <Link to={`/configuration/programs/edit/${id}`} title={name}>
        <span>{name}</span>
      </Link>
    </div>
  );
};

ProgramCell.propTypes = {
  content: PropTypes.shape().isRequired,
};

export default ProgramCell;
