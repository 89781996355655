import React from 'react';
import { BASE_URL, ENDPOINTS } from '@constants/UHEEndpoints';
import RestManager from '@util/RestManager';
import {
  ACCESS_TOKEN_KEY,
  IMPERSONATE_TOKEN,
  CURRENT_USER_KEY,

  NOTIFICATION_ROLE,
  ALL_ORGANIZATIONS,
  ORGANIZATION,
  CUSTOMER,
  FACILITY,
  UNIT,
} from '@constants/UHESettings';
import { store } from '@root/NextApp';
import { getOwnUser } from '@uhe_selectors/configuration/users/UsersSelectors';
import { Select } from 'antd';
import moment from 'moment-timezone';
import defaultTimezones from 'timezones.json';
import {
  TIMEZONES,
} from '@constants/SystemConstants';
import TIME_ZONES from '../constants/TimeZones';

import devices_in_alarm from '@assets/images/show_devices_in_alarm.svg';
import devices_not_configured from '@assets/images/show_devices_not_configured.svg';
import devices_online from '@assets/images/show_devices_online.svg';
import total_devices from '@assets/images/show_total_devices.svg';

const { Option } = Select;
const UheHelper = {
  /**
   * @param  {String} filter
   * @param  {String} sorting
   * @param  {String} endpoint
   * @description Get endpoint url for data export
   * @return {String}
   */
  getCsvUrl: (filter, sorting, endpointUrl) => {
    const token = RestManager.getToken();

    const filterQueryString = filter && filter.length ? `&${filter.join('&')}` : '';
    const sortingQueryString = sorting && sorting.length ? `&sort=${sorting.join('&sort=')}` : '';

    const tokenParam = `${filterQueryString || sortingQueryString ? `&token=${token}` : `token=${token}`}`;
    return `${BASE_URL}${endpointUrl}${filterQueryString}${sortingQueryString}${tokenParam}`;
  },

  /**
   * Clears all auth tokens
   * @returns {void}
   */
  clearAuthTokens() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(IMPERSONATE_TOKEN);
  },

  /**
   * Clears the impersonate token
   * @returns {void}
   */
  clearImpersonateAuthTokens() {
    localStorage.removeItem(IMPERSONATE_TOKEN);
  },

  /**
   * Returns the user impersonate auth token
   * @returns {string}
   */
  getImpersonateAuthToken() {
    localStorage.getItem(IMPERSONATE_TOKEN);
  },

  /**
   * Saves the current user profile in local storage
   * @params {Object} profile
   * @returns {void}
   */
  setOwnUserProfile(profile) {
    localStorage.setItem(CURRENT_USER_KEY, profile);
  },

  /**
   * Deletes the current user profile from the localstorage
   * @returns {void}
   */
  removeOwnUserProfile() {
    localStorage.removeItem(CURRENT_USER_KEY);
  },
};

/**
 * Back and cancel buttons logic
 * @param {string} location Fallback location to return to
 * @param {string} lastLocation Last location from react-router-last-location from props
 * @param {object} history History from react-router from props
 * @returns {function(): void} Action which pushes to state
 * or calls goBack whether the user has been on App page or not
 */
export const goBackTo = (location, lastLocation, history) => () => {
  if ((lastLocation === location) || (lastLocation === '/uhe-units') || (lastLocation === '/monitoring/non-uhe-units') || (lastLocation.includes('/bulk-actions'))) {
    history.goBack();
  } else {
    history.push(location);
  }
};
UheHelper.goBackTo = goBackTo;

/**
 * @description Utility singleton for easy case transformation
 * @type {{
 *  fromUpperSnakeToPascalCase(string): string
 * }}
 */
export const CaseHelper = {
  /**
   * @param {string} value
   * @returns {string}
   */
  fromUpperSnakeToPascalCase(value) {
    return value.split('_')
      .map(([...y]) => y.shift().toUpperCase() + y.join('').toLowerCase())
      .join('');
  },
  fromPascalToCamelCase(value) {
    return [...value].map((char, index) => (index === 0 ? char.toLowerCase() : char)).join('');
  },
  fromSpaceToCamelCase(value) {
    return value
      .split(' ')
      .map(([...word], index) => (index === 0
        ? word.join('').toLowerCase()
        : word.shift().toUpperCase() + word.join('').toLowerCase()))
      .join('');
  },
};
UheHelper.CaseHelper = CaseHelper;

/**
 * @description Makes Type Ahead of Filter Input Case Insensitive
 * @param {string} inputValue
 * @param {Object} path
 * @returns {Object}
 */
export const cascaderSearchFilter = (inputValue, path) => path.some((option) => (option.name || option.cart_name).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
export const cascaderCustomerFiscalFilter = ((input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0);
export const cascaderDeviceSearchFilter = (inputValue, path) => path.some((option) => option.value.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

/**
 * Checks if input value is in dropdown menu items
 * @param {string} input - select input
 * @param {Object} option - dropdown list option
 * @returns {boolean} True/False
 */
export const searchSelectFilter = (input, option) => {
  const childExists = option.children.toLowerCase().includes(input.toLowerCase());
  const timezoneExists = option.fullTimeZoneName.toLowerCase().includes(input.toLowerCase());
  const hiddenCityExists = option.hiddenCities?.toLowerCase().includes(input.toLowerCase());

  if (childExists || timezoneExists || hiddenCityExists) {
    return true;
  }

  return false;
};
/**
 * Get the currently logged user from the global state ConfigurationUsers space
 * @returns {{}} global state ConfigurationUsers.ownUser
 */
export const getLoggedUser = () => getOwnUser(store.getState());
UheHelper.getLoggedUser = getLoggedUser;

/**
 * Returns new array without empty or falsy entries
 * @param {Array} array Items
 * @returns {Array} New array without empty or falsy entries
 */
export const withoutEmptyEntries = (array) => array.filter((item) => !!item);

/**
 * Sets all properties with empty string values to null
 * @param {object} object Object
 * @returns {object} New object empty string values
 */
export const setObjectEmptyStringPropertiesToNull = (object) => {
  for (const propName in object) {
    if (object[propName] === '') {
      object[propName] = null;
    }
  }
  return object;
};

/**
 * Sets all properties with empty string values to null
 * @param {number} timestamp Timestamp int
 * @param {string} timeZone timeZone string
 * @returns {string} Formatted Date and Time
 */
export const convertTimestampToDateTime = (timestamp, timeZone = null) => {
  let formattedDateTime;

  if (!timestamp) {
    formattedDateTime = null;
  } else if (timestamp && !timeZone) {
    formattedDateTime = `${moment(timestamp).utc().format('YYYY-MM-DD HH:mm:ss (zZ)')}`;
  } else if (timeZone.substring(0, 4) === '(GMT') {
    const GMT = timeZone.match(/\(([^)]+)\)/)[1];
    const UTCOffset = GMT.split('GMT').pop().slice(0, -3);

    const currentTimezone = defaultTimezones.find((element) => element.offset === parseInt(UTCOffset));

    formattedDateTime = `${moment(timestamp).utc().utcOffset(UTCOffset).format('YYYY-MM-DD HH:mm:ss')} ${currentTimezone.abbr} (${GMT})`;
  } else {
    formattedDateTime = `${moment(timestamp).tz(timeZone).format('YYYY-MM-DD HH:mm:ss z Z')}`;
  }

  return formattedDateTime;
};

const zonesListMap = {};
let zonesList = [];
/**
 * Populate the object with correct timezone
 * @returns {Object} Formatted Timezone
 */
export const getZones = () => {
  if (Object.keys(zonesListMap).length === 0) {
    TIMEZONES.map((timeZone, index) => {
      const zone = moment.tz(timeZone).format('Z zz z ZZ');
      let [country, city] = timeZone.split('/');

      city = city?.replaceAll('_', ' ');
      const [offset, abr, tz, offsetNum] = zone.split(' ');

      if (city !== undefined && !city.includes('GMT') && (!abr.includes('0') && !abr.includes('1'))) {
        const key = `${abr} ${offsetNum}`;

        if (TIME_ZONES[key]) {
          const timeZoneObj = TIME_ZONES[key];
          const { fullTimeZoneName, shownCities, hiddenCities } = timeZoneObj;
          if (timeZoneObj) {
            if (zonesListMap[key]) {
              zonesListMap[key].cities.push(city);
              zonesListMap[key].fullTimeZoneName = fullTimeZoneName || '';
              zonesListMap[key].hiddenCities = hiddenCities || '';
            } else {
              zonesListMap[key] = {
                offset,
                timeZone,
                cities: [city],
                abr,
                offsetNum: Number(offsetNum),
                fullTimeZoneName: fullTimeZoneName || '',
                hiddenCities: hiddenCities || '',
              };
            }
            if (shownCities) {
              zonesListMap[key].cities.push(...shownCities);
            }
            zonesListMap[key].cities = [...new Set(zonesListMap[key].cities)];
          }
        }
      }
    });

    zonesList = Object.values(zonesListMap).sort((a, b) => {
      if (a.offsetNum !== b.offsetNum) {
        return a.offsetNum - b.offsetNum;
      }
      return a.timeZone.localeCompare(b.timeZone);
    });
  }

  return zonesList;
};
/**
 * Use flatten messages
 * @param {Object} nestedMessages object
 * @param {String} prefix string
 * @returns {object} New object empty string values
 */
export const flattenMessages = (nestedMessages, prefix = '') => Object.keys(nestedMessages).reduce((messages, key) => {
  const value = nestedMessages[key];
  const prefixedKey = prefix ? `${prefix}.${key}` : key;

  if (typeof value === 'string') {
    messages[prefixedKey] = value;
  } else {
    Object.assign(messages, flattenMessages(value, prefixedKey));
  }

  return messages;
}, {});
/**
 * Get the sorted column
 * @param {string} key - column name
 * @param {string}  search - query string
 * @returns {Object|Boolean} column and type of sort (asc, desc) or false if there is no column
 */
export const getColumnSorting = (key, { search }) => new URLSearchParams(search)
  .getAll('sort')
  ?.map((x) => x.split(','))
  ?.map(([k, v]) => [k, {
    asc: 'ascend',
    desc: 'descend',
  }[v]])
  ?.find(([k]) => k === key)
  ?.pop() ?? false;

/**
 * Set table state
 * @param {string} columnName Column Name
 * @param {Boolean} clicked Is Column Clicked
 * @param {string} sort Current Column Sort
 * @return {void}
 */
export function setTableData(columnName, clicked, sort) {
  const { tableData } = this.state;
  const currentState = tableData;
  currentState[columnName] = { currSort: sort, isClicked: clicked };

  this.setState({ tableData: currentState });
}

/**
 * Save default sort when user click to sort
 * @param {Array} defaultSort default sort array
 * @param {Array} customSort custom sort array
 * @returns {Array} return sorted columns
 */
export const saveDefaultSortOnClick = (defaultSort, customSort) => defaultSort.filter(
  (column) => !customSort.includes(column),
);

/**
 * Get Time zones
 * @returns {JSX} option
 */
export function getTimeZones() {
  const showTimeZone = getZones();
  if (showTimeZone) {
    return showTimeZone.map((zoneData) => {
      const {
        offset, timeZone, cities, hiddenCities, abr, fullTimeZoneName,
      } = zoneData;
      const label = `(GMT${offset}) ${cities.join(', ')} - ${abr}`;
      return (
        <Option key={`${abr} ${offset}`} fullTimeZoneName={fullTimeZoneName} hiddenCities={hiddenCities} value={timeZone}>{label}</Option>
      );
    });
  }

  return null;
}

/**
   * Deletes Notification Portal for inferior levels
   * @param {Array} data data
   * @param {string} level level
   * @returns {Array} data
   */
export const deleteNotificationPortal = (data) => {
  data.forEach((notification, index) => {
    const notificationPortal = notification.adminRole.value === NOTIFICATION_ROLE;
    const organization = notification.organization.key !== 'All';
    const customer = notification.customer.key !== 'All';
    const facility = notification.facility.key !== 'All';
    const unit = notification.unit.key !== 'All';
    const device = notification.device.key !== 'All';
    data.some((el) => {
      if (el.adminRole.value === NOTIFICATION_ROLE && notificationPortal) {
        if (organization
        && el.organization.key === 'All') {
          data.splice(index, 1);
        } else if (customer
        && el.organization.value === notification.organization.value
        && el.customer.key === 'All') {
          data.splice(index, 1);
        } else if (facility
        && el.customer.value === notification.customer.value
        && el.facility.key === 'All') {
          data.splice(index, 1);
        } else if (unit
        && el.facility.value === notification.facility.value
        && el.unit.key === 'All') {
          data.splice(index, 1);
        } else if (device
        && el.unit.value === notification.unit.value
        && el.device.key === 'All') {
          data.splice(index, 1);
        }
      }
    });
  });
};

/**
 * whichPageisRendered
 * @param {string} page which page to check
 * @return {void}
 */
export function whichEndpointToUse() {
  const isPrograms = window.location.pathname.indexOf('/programs') !== -1;
  const isSBAPS = window.location.pathname.indexOf('/non-uhe-units') !== -1;
  let checkPage;
  if (isPrograms) {
    checkPage = ENDPOINTS.programs.uploadLogDetailedReport;
  } else if (isSBAPS) {
    checkPage = ENDPOINTS.nonUheUnits.uploadLogDetailedReport;
  } else {
    checkPage = ENDPOINTS.users.uploadLogDetailedReport;
  }
  return checkPage;
}

/**
 * whichPageisRendered
 * @param {array} currSort Array Of Query Sorting Parameters
 * @param {aray} tableFilterMap Table Filter Map
 * @return {void}
 */
export function getCurrentSort(currSort, tableFilterMap) {
  const sort = [];

  currSort.forEach((sortItem) => {
    const key = sortItem.split(',')[0];
    const value = tableFilterMap[key];

    if (value) {
      sort.push(sortItem.replace(key, value));
    }
  });

  return sort;
}

/**
 * Check if input with type number has some of prohibited symbols
 * @param {String} dataKey Table key
 * @param {Object} event event object
 * @return {void} prevents from default action when some of prohibited symbols are entered
 */
export function checkInputSymbols(dataKey, event) {
  const prohibitedSymbols = ['e', 'E', '+', '-'];
  let result;

  if (dataKey === 'deviceId' || dataKey === 'Device Id') {
    result = prohibitedSymbols.some((symbol) => symbol === event.key);

    if (result) {
      event.preventDefault();
    }
  }
}

/**
 * Gets the app dropdwon options according to the user permissions
 * @param {Object} loggedUser logged user
 * @return {array} the menu options
 */
export function getMenuOptions(loggedUser) {
  if (loggedUser) {
    const result = [];

    result.push({ label: 'Dashboard', value: 'landing' });

    if (loggedUser?.roles?.includes('UHE')) {
      result.push({ label: 'iConsult', value: 'iConsult' });
    }

    if (loggedUser?.roles?.includes('ESITTER')) {
      result.push({ label: 'iObserver', value: 'iObserver' });
    }

    if (loggedUser?.roles?.includes('REPORTING')) {
      result.push({ label: 'Reporting', value: 'reporting' });
    }

    if (loggedUser?.roles?.includes('NOTIFICATION_PORTAL')) {
      result.push({ label: 'Notifications', value: 'notification' });
    }

    if ((loggedUser?.roles?.includes('TECH') || loggedUser?.roles?.includes('TECH_ADMIN')) && loggedUser?.techAuthorized) {
      result.push({ label: 'Technician', value: 'technician' });
    }

    return result;
  }

  return [];
}

/**
 * Retrieves Access Token from Local Storage
 * @returns {string} Access Token
 */
export const getAccesToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);

/**
 * Construct Table Content
 * @param {object} newData newData
 * @param {object} currentData currentData
 * @returns {array} table content
 */
export const tableContent = (newData, currentData) => {
  const updatedTable = currentData;
  if (newData.page.number === 0) {
    return newData;
  }
  newData.list.map((item) => updatedTable.list.push(item));
  return updatedTable;
};

/**
 * Renders the status icon
 * @param {string} value value
 * @returns {JSX} icon
 */
export const statusIcon = (value) => {
  let icon;
  switch (value) {
    case 'healthy':
      icon = devices_online;
      break;
    case 'unhealthy':
      icon = devices_in_alarm;
      break;
    case 'not_configured':
      icon = devices_not_configured;
      break;
    default:
      icon = total_devices;
  }
  return <img alt="pie-icon" className="pie-icon" src={icon} />;
};

/**
 * Check for show/hide Cascader
 * @returns {Object} Cascader status
 */
export const removeSearchIcon = () => {
  const obj = {
    organization: false,
    customer: false,
    facility: false,
    unit: false,
    deviceTypes: false,
  };

  return obj;
};

export default UheHelper;
