import {
  all,
  call,
  fork,
  put,
  takeEvery,
} from 'redux-saga/effects';
import {
  systemFetchDataSuccess,
  saveSystemSuccess,
  clearSelection,
  sendTestEmailSuccess,
  fetchLicensingSuccess,
  sendLicensingKeySuccess,
  fetchApplicationVersionSuccess,
  onFetchTwilioDataSuccess,
  deleteTwilioSuccess,
  saveTwilioSuccess,
  getTwilioSuccess,
  updateTwilioSuccess,
  getTwilio,
  onFetchTwilioData,
} from '@actions/uhe/system/SystemActions';
import {
  SYSTEM_SETTINGS_SYSTEM_FETCH_DATA,
  SAVE_SYSTEM_SETTINGS_SYSTEM_REQUEST,
  SEND_TEST_EMAIL,
  FETCH_LICENSING_REQUEST,
  SEND_LICENSING_KEY_REQUEST,
  FETCH_APPLICATION_VERSION_REQUEST,
  FETCH_TWILIO_DATA,
  DELETE_TWILIO_REQUEST,
  SAVE_TWILIO,
  GET_TWILIO_REQUEST,
  UPDATE_TWILIO_REQUEST,
} from '@constants/UHEActionTypes';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { fetchError, showMessage } from '@actions/Common';
import RestManager from '@util/RestManager';

/**
 * @description Request data for system page
 * @param  {Array} // Array of objects
 */
const doFetchData = async () => {
  return await RestManager.request(`${ENDPOINTS.system.SystemData}`);
};

/**
 * @description Save request
 * @param  {object} bodyData
 * @return {Object}
 */
const save = async (bodyData) => {
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.system.SaveSystemData}`,
    'POST',
    bodyData
  );
};

const send = async (bodyData) => {
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.system.TestEmail}`,
    'POST',
    bodyData
  );
};

/**
 * @description Sends GET Request to /api/licensing
 * @returns {Object}
 */
const fetchLicensing = async () => {
  return await RestManager.request(`${ENDPOINTS.system.licensing}`);
};

/**
 * @description Sends POST Request to /api/licensing
 * @returns {Object}
 */
const sendLicensing = async (body) => {
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.system.licensing}`,
    'POST',
    body
  );
};

function* sendLicensingKey(payload) {
  try {
    const licensingKey = yield call(sendLicensing, payload.body);
    if (licensingKey) {
      yield put(sendLicensingKeySuccess(licensingKey));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Get Response From GET /api/licensing
 * @param {Object} data
 * @returns {void}
 */
function* getLicensing(data) {
  try {
    const licensingData = yield call(fetchLicensing, data);
    if (licensingData) {
      yield put(fetchLicensingSuccess(licensingData));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Save System
 * @param {string} mail - Data for saving
 */
function* sendEmail(mail) {
  try {
    const { email } = yield call(send, mail.payload);
    if (email) {
      yield put(sendTestEmailSuccess(email));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @param {number} page
 * @param filter {Array}
 */
function* fetchSystemData({ page }) {
  try {
    yield put(clearSelection());
    const fetchedData = yield call(doFetchData, page);
    yield put(systemFetchDataSuccess(fetchedData));
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Save System
 * @param {string} data - Data for saving
 */
function* saveSystem(data) {
  try {
    const system = yield call(save, data.payload);
    if (system) {
      yield put(saveSystemSuccess(system));
      yield put(showMessage('save_success'));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Sends GET Request to /version to get back-end version data
 * @returns {Object}
 */
const fetchApplicationVersion = async () => {
  return await RestManager.request(`${ENDPOINTS.system.version}`);
}

/**
 * @description Gets Response from GET /version
 * @param {Object} data 
 * @returns {void}
 */
function* getApplicationVersion(data) {
  try {
    const versionData = yield call(fetchApplicationVersion, data);

    if (versionData) {
      yield put(fetchApplicationVersionSuccess(versionData));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Twilio Listing Request
 * @param {number} page Page Number
 * @param {string} sorting Sorting String
 * @param {string} filter Filter String
 * @returns {object} Response
 */
const twilioListRequest = async (page, sorting, filter) => {
  const filterQueryString = filter?.length ? `&${filter.join('&')}` : '';
  const sortingQueryString = sorting?.length ? `&sort=${sorting.join('&sort=')}` : '';
  const request = await RestManager.request(`${ENDPOINTS.system.phones}?page=${page || 0}${sortingQueryString}${filterQueryString}`);

  return request;
};

/**
 * getTwilioList Saga Worker
 * @param {number} page Page Number
 * @param {string} sorting Sorting String
 * @param {string} filter Filter String
 * @returns {void}
 */
function* getTwilioList({ page, sorting, filter }) {
  try {
    const response = yield call(twilioListRequest, page, sorting, filter);
    yield put(onFetchTwilioDataSuccess(response));
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Twilio Delete Request
 * @param {string} id Twilio ID
 * @returns {object} Response
 */
const deleteTwilioRequest = async (id) => {
  const request = await RestManager.requestWithoutQueryParams(`${ENDPOINTS.system.phones}/${id}`, 'DELETE');

  return request;
};

/**
 * deleteTwilioSaga Saga Worker
 * @param {string} id Twilio ID
 * @param {number} page Page Number
 * @param {string} sort Sorting String
 * @param {string} filter Filter String
 * @returns {void}
 */
function* deleteTwilioSaga({
  payload: {
    id, page, sort, filter,
  },
}) {
  try {
    const response = yield call(deleteTwilioRequest, id);

    if (response) {
      yield put(deleteTwilioSuccess(id));
      yield put(onFetchTwilioData(page, sort, filter));
      yield put(showMessage('delete_success'));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Request for add new twilio
 * @param  {Object} bodyData for new twilio
 * @return {Object} data
 */
const addNewTwilio = async (bodyData) => {
  const res = await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.system.phones}`,
    'POST',
    bodyData,
  );
  return res;
};

/**
 * Save twilio
 * @param {Object} data payload
 * @returns {void}
 */
function* saveTwilioData(data) {
  try {
    const response = yield call(addNewTwilio, data.payload.twilio);

    if (response) {
      yield put(saveTwilioSuccess(response));
      yield put(showMessage('save_success'));
      yield put(getTwilio(response.id));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Get Twilio by ID Request
 * @param {string} id Twilio ID
 * @returns {object} Response
 */
const getTwilioRequest = async (id) => {
  const request = await RestManager.request(`${ENDPOINTS.system.phones}/${id}`);

  return request;
};

/**
 * getTwilioSaga Saga Worker
 * @param {object} payload Payload Data
 * @returns {void}
 */
function* getTwilioSaga(payload) {
  try {
    const response = yield call(getTwilioRequest, payload.id);

    if (response) {
      yield put(getTwilioSuccess(response));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Twilio Update Request
 * @param {string} id Twilio ID
 * @param {object} body Request Body
 * @returns {object} Response
 */
const updateTwilioRequest = async (id, body) => {
  const request = await RestManager.requestWithoutQueryParams(`${ENDPOINTS.system.phones}/${id}`, 'POST', body);

  return request;
};

/**
 * updateTwilioSaga Saga Worker
 * @param {object} data Payload Data
 * @returns {void}
 */
function* updateTwilioSaga(data) {
  try {
    const response = yield call(updateTwilioRequest, data.payload.id, data.payload.body);

    if (response) {
      yield put(updateTwilioSuccess(response));
      yield put(showMessage('save_success'));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* actionsWatcher() {
  yield takeEvery(SYSTEM_SETTINGS_SYSTEM_FETCH_DATA, fetchSystemData);
  yield takeEvery(SAVE_SYSTEM_SETTINGS_SYSTEM_REQUEST, saveSystem);
  yield takeEvery(SEND_TEST_EMAIL, sendEmail);
  yield takeEvery(FETCH_LICENSING_REQUEST, getLicensing);
  yield takeEvery(SEND_LICENSING_KEY_REQUEST, sendLicensingKey);
  yield takeEvery(FETCH_APPLICATION_VERSION_REQUEST, getApplicationVersion);
  yield takeEvery(FETCH_TWILIO_DATA, getTwilioList);
  yield takeEvery(DELETE_TWILIO_REQUEST, deleteTwilioSaga);
  yield takeEvery(SAVE_TWILIO, saveTwilioData);
  yield takeEvery(GET_TWILIO_REQUEST, getTwilioSaga);
  yield takeEvery(UPDATE_TWILIO_REQUEST, updateTwilioSaga);
}

export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
