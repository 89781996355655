import React from 'react';
import lodash from 'lodash';
import {
  Row, Col, Card, Input, Checkbox, Button, Form, Cascader, Table, Popconfirm,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { injectIntl } from 'react-intl';
import IntlMessages from 'util/IntlMessages';
import PropTypes from 'prop-types';

import {
  iobserverOnFetchData,
  saveIobserver,
  defaultTurnServer,
  customerTurnServer,
  saveDefaultTurnServer,
  saveCustomerTurnServer,
  deleteDefaultTurnServer,
  deleteCustomerTurnServer,
  clearIobserverState,
} from '@uhe_actions/system/iObserverActions';
import { onGetOptions } from '@uhe_actions/filters/ListingsTopFilterActions';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { EditableFormRow, EditableCell } from './EditableContext';

class Iobserver extends React.Component {
  systemForm = React.createRef();

  customerForm = React.createRef();

  constructor(props) {
    super(props);
    this.defaultColumns = [
      {
        title: <IntlMessages id="uhe.table.type" />,
        dataIndex: 'proto',
        width: '30%',
        editable: false,
        key: 'proto',
      },
      {
        title: <IntlMessages id="uhe.table.ipAddress" />,
        dataIndex: 'address',
        width: '30%',
        editable: true,
        key: 'address',
      },
      {
        title: <IntlMessages id="uhe.table.userName" />,
        dataIndex: 'username',
        width: '30%',
        editable: true,
        key: 'username',
      },
      {
        title: <IntlMessages id="configuration.customer.password" />,
        dataIndex: 'credential',
        width: '30%',
        editable: true,
        key: 'credential',
      },
      {
        title: <IntlMessages id="configuration.unit.actions" />,
        dataIndex: 'action',
        key: 'action',
        render: (text, record, index) => (this.state.dataDefaultServer.length >= 1 ? (
          <div className="action-btns">
            <Popconfirm title={<IntlMessages id="common.deleteQuestion" />} onConfirm={() => this.handleDefaultDelete(index)} placement="topRight">
              <Button htmlType="submit" className="iObserver-btn" title={this.intl.formatMessage({ id: 'button.delete' })}><i className="icon icon-trash" /></Button>
            </Popconfirm>
            <Popconfirm okType="submit" title={<IntlMessages id="common.saveQuestion" />} onConfirm={() => this.saveDefaultTurnServerData(record)} placement="topRight">
              <Button htmlType="submit" className="iObserver-btn" title={this.intl.formatMessage({ id: 'button.save' })}><i className="icon icon-check-cricle save-row iObserver-btn" /></Button>
            </Popconfirm>
          </div>
        ) : null),
      },
    ];
    this.customerColumns = [
      {
        title: <IntlMessages id="uhe.table.customer" />,
        dataIndex: 'customerName',
        width: '30%',
        editable: false,
      },
      {
        title: <IntlMessages id="uhe.table.groupName" />,
        dataIndex: 'group',
        width: '30%',
        editable: false,
      },
      {
        title: <IntlMessages id="uhe.table.type" />,
        dataIndex: 'proto',
        width: '30%',
        editable: false,
      },
      {
        title: <IntlMessages id="uhe.table.ipAddress" />,
        dataIndex: 'address',
        width: '30%',
        editable: true,
      },
      {
        title: <IntlMessages id="uhe.table.userName" />,
        dataIndex: 'username',
        width: '30%',
        editable: true,
      },
      {
        title: <IntlMessages id="configuration.customer.password" />,
        dataIndex: 'credential',
        width: '30%',
        editable: true,
      },
      {
        title: <IntlMessages id="configuration.unit.actions" />,
        dataIndex: 'action',
        render: (text, record, index) => (this.state.dataCustomerServer.length >= 1 ? (
          <div className="action-btns">
            <Popconfirm title={<IntlMessages id="common.deleteQuestion" />} onConfirm={() => this.handleCustomerDelete(record)} placement="topRight">
              <Button htmlType="submit" className="iObserver-btn" title={this.intl.formatMessage({ id: 'button.delete' })}><i className="icon icon-trash" /></Button>
            </Popconfirm>
            <Popconfirm title={<IntlMessages id="common.saveQuestion" />} onConfirm={() => this.saveCustomerTurnServerData(record)} placement="topRight">
              <Button htmlType="submit" className="iObserver-btn" title={this.intl.formatMessage({ id: 'button.save' })}><i className="icon icon-check-cricle save-row" /></Button>
            </Popconfirm>
          </div>
        ) : null),
      },
    ];

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
    this.intl = this.props.intl;
    this.state = {
      data: {
        esitter_controller_default: { value: '' },
        update_matching_customers: false,
        customer: [],
      },
      defaultTurnServer: {},
      dataDefaultServer: [],
      dataCustomerServer: [],
      focusCustomerCascader: false,
    };

    if (this.props.subtitle && this.props.subtitle.langId !== 'iobserver.title') {
      this.props.setSubtitle('iobserver.title');
    }
    this.props.onGetCustomerOptions();
    this.data = [];
  }

  componentDidMount() {
    const {
      iobserverOnFetchData, defaultTurnServer, customerTurnServer, saveCustomerTurnServer,
    } = this.props;

    iobserverOnFetchData();
    defaultTurnServer();
    customerTurnServer();
    saveCustomerTurnServer();
  }

  componentWillUnmount() {
    this.props.clearIobserverState();
  }

  /**
   * @description Watch for empty data and if is empty set new state data
   * @param {Object} prevProps
   * @return {Object}
   */
  componentDidUpdate(prevProps) {
    const {
      data, defaultServer, customerServer, error, history,
    } = this.props;

    if (Object.keys(prevProps.data).length === 0 && Object.keys(data).length > 0) {
      this.setState({ data: { ...data } });
    }

    if (prevProps.defaultServer.length === 0 && defaultServer.length > 0) {
      const newData = [...defaultServer];
      newData.forEach((data, index) => {
        data.key = index + 1;
      });
      this.setState({ dataDefaultServer: newData });
    }

    if (prevProps.customerServer.length === 0 && customerServer.length > 0) {
      const newData = [...customerServer];
      let key = 1;
      newData.forEach((data) => {
        data.servers.forEach((server) => {
          server.key = key++;
        });
      });
      this.setState({ dataCustomerServer: newData });
    }

    if (prevProps.error !== error) {
      // handle system error
      if (error.code === 404 && !this.isNew) {
        history.push('/system/settings/iobserver');
      }
    }
  }

  /**
   * @description Event for change value in input fields
   * @param {string} key
   * @param {Object} event
   * @return {Void}
   */
  onChangeIobserverData(key, event) {
    const { data } = this.state;

    if (event && event.persist && event.preventDefault) {
      event.persist();
      event.preventDefault();
    }
    this.setState({ data: { ...data, [key]: { ...data[key], value: event.target.value } } });
  }

  /**
   * @description Event for change value in the checkbox
   * @param {string} key
   * @param {Object} event
   * @return {Void}
   */
  onChangeCheckbox(key, event) {
    const { data } = this.state;
    const newState = !!event.target.checked;
    this.setState({ data: { ...data, [key]: { ...data[key], value: newState } } });
  }

  /**
   * @description Save data controller settings
   * @param {Object} event
   * @return {Void}
   */
  onSaveButtonClick(event) {
    event.persist();
    event.preventDefault();
    const { saveIobserverData } = this.props;
    const { data } = this.state;

    saveIobserverData(data);
  }

  /**
   * @description Save record of default turn server table
   * @returns {Void}
   */
  saveDefaultTurnServerData(record) {
    if (record) {
      if (record.address === this.intl.formatMessage({ id: 'common.addIP' }) || record.address.trim().length === 0) {
        return;
      }
      const { saveDefaultTurnServer } = this.props;
      const { dataDefaultServer } = this.state;
      saveDefaultTurnServer(dataDefaultServer);
    }
  }

  /**
   * @description Save record of default turn server table
   * @returns {Void}
   */
  saveCustomerTurnServerData(record) {
    let isFound = false;
    const { saveCustomerTurnServer } = this.props;
    const { dataCustomerServer, customer } = this.state;

    if (customer && customer.id) {
      const newData = [];
      dataCustomerServer.forEach((data) => {
        data.servers.forEach((server, index) => {
          if (server.key === record.key && !isFound) {
            isFound = true;
          }
          newData.push(server);
        });
      });
      if (isFound) {
        saveCustomerTurnServer({ body: newData, id: customer.id });
      }
    } else {
      dataCustomerServer.forEach((data) => {
        data.servers.forEach((server, index) => {
          if (server.key === record.key && !isFound) {
            isFound = true;
            saveCustomerTurnServer({ body: data.servers, id: data.customer.id });
          }
        });
      });
    }
  }

  /**
   * @description Handle Changes in Customer Cascader
   * @param {Array} values
   * @return {Void}
   */
  onChangeCustomerHandler(values = []) {
    const { customerServer } = this.props;
    const selectedCustomer = values[0];
    let filterServers;

    if (values.length > 0) {
      filterServers = customerServer.filter((server) => server.customer.id === selectedCustomer.id);
    } else {
      filterServers = customerServer;
    }

    this.setState({ customer: selectedCustomer, dataCustomerServer: filterServers });
    this.setState({ focusCustomerCascader: false });
  }

  /**
  * @description Change data after filtering
  * @param {number} page
  * @return {Void}
  */
  onPageChange(page) {
    this.currentPage = page - 1;
    const filter = [];
    lodash.forOwn(this.topFilterMap, (value, key) => {
      const filterParam = this.qParams.get(key);
      if (filterParam) {
        filter.push(`${value}=${filterParam}`);
      }
    });
    this.filter = filter;
  }

  /**
   * @description Delete row
   * @argument {Number} key
   * @return {Void}
   */
  handleDefaultTurnServerDelete = (key) => {
    const { dataDefaultServer } = this.state;
    const { saveDefaultTurnServer } = this.props;
    const newData = dataDefaultServer.filter((item) => item.key !== key);
    this.setState({ dataDefaultServer: newData });
    saveDefaultTurnServer(newData);
  };

  /**
   * @description Delete row
   * @argument {Number} key
   * @return {Promise}
   */
  handleCustomerTurnServerDelete = (key) => {
    const { dataCustomerServer } = this.state;
    const { saveCustomerTurnServer } = this.props;
    const newData = dataCustomerServer.filter((item) => item.key !== key);
    this.setState({ dataCustomerServer: newData });
    saveCustomerTurnServer(newData);
  };

  /**
   * @description Add empty row
   * @returns {Object}
   */
  handleAddDefaultTurnServer = () => {
    const { dataDefaultServer } = this.state;
    const newData = {
      key: (Math.random() * 1000000000),
      proto: 'TURN',
      address: this.intl.formatMessage({ id: 'common.addIP' }),
      username: this.intl.formatMessage({ id: 'common.addUser' }),
      credential: this.intl.formatMessage({ id: 'common.addPassword' }),
    };
    this.setState({
      dataDefaultServer: [...dataDefaultServer, newData],
    });
  };

  /**
   * @description Add empty row
   * @param {Array} values
   * @returns {Object}
   */
  handleAddCustomerTurnServer = (values = []) => {
    const { dataCustomerServer, customer } = this.state;

    const newData = {
      customer: { ...customer },
      group: '-',
      servers: [
        {
          key: (Math.random() * 1000000000),
          proto: 'TURN',
          address: this.intl.formatMessage({ id: 'common.addIP' }),
          username: this.intl.formatMessage({ id: 'common.addUser' }),
          credential: this.intl.formatMessage({ id: 'common.addPassword' }),
        },
      ],
    };
    this.setState({
      dataCustomerServer: [...dataCustomerServer, newData],
    });
  };

  /**
   * @description Save Default System Servers
   * @param {Array} row
   */
  handleDefaultSave = (row) => {
    const newData = [...this.state.dataDefaultServer];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataDefaultServer: newData });
  };

  /**
   * @description Save Add/Edit Customer Server Groups
   * @param {Array} row
   * @returns {Void}
   */
  handleCustomerSave = (row) => {
    const { dataCustomerServer } = this.state;
    let server_index = 0;

    for (const _row of dataCustomerServer) {
      server_index = 0;
      for (const item of _row.servers) {
        if (item.key === row.key) {
          _row.servers.splice(server_index, 1, row);
          this.setState({ dataCustomerServer });
          return;
        }
        server_index++;
      }
    }
  };

  /**
   * @description Save Default System Turn Servers
   */
  systemSave = () => {
    this.systemForm.validateFields()
      .then()
      .catch((error_info) => {
        console.error(error_info);
      });
  }

  /**
  *@description Delete record of default turn server table
  * @param {*} index
  * @return {Promise}
  */
  handleDefaultDelete(index) {
    const { dataDefaultServer } = this.state;
    dataDefaultServer.splice(index, 1);
    this.setState({ dataDefaultServer }, () => this.saveDefaultTurnServerData());
  }

  /**
   * @description Delete record of customer turn server table
   * @param {Object} text
   * @return {Promise}
   */
  handleCustomerDelete(text) {
    const { dataCustomerServer } = this.state;
    const newData = [];

    if (text) {
      const textCopy = lodash.cloneDeep(text);
      delete textCopy.customerName;
      delete textCopy.group;
      let isFound = false;

      for (const data of dataCustomerServer) {
        if (data.servers) {
          const newServers = [];
          for (const server of data.servers) {
            const isEqual = lodash.isEqual(server, textCopy);
            if (isEqual) {
              isFound = true;
            } else {
              newServers.push(server);
            }
          }

          data.servers = newServers;
          newData.push(data);
          if (isFound) {
            this.props.saveCustomerTurnServer({ reason: 'delete', body: newServers, id: data.customer.id });
            isFound = false;
          }
        } else {
          newData.push(data);
        }
      }
      this.setState({ dataCustomerServer: newData });
    }
  }

  /**
   * @description Populate Columns for Add/Edit Customer Turn Server
   * @param {Array} dataCustomerServer
   * @param {Boolean} editable
   */
  dataAdapter(dataCustomerServer = [], editable) {
    const adaptedData = [];
    dataCustomerServer.forEach((data) => {
      const { customer, group, servers } = data;
      if (servers) {
        servers.forEach((server) => {
          adaptedData.push({ ...server, customerName: customer.name, group });
        });
      }
    });
    return adaptedData;
  }

  render() {
    const { loading, optionsList } = this.props;
    const {
      data, dataDefaultServer, dataCustomerServer, customer, focusCustomerCascader,
    } = this.state;
    if (Object.keys(data).length === 0) {
      return null;
    }
    const customers = optionsList.customer.map((customer) => {
      return { id: customer.id, name: customer.name }
    })
    const options = {
      ...optionsList,
      customer: customers,
    };
    const components = {
      body: {
        row: (props) => EditableFormRow(props, this.systemForm),
        cell: EditableCell,
      },
    };
    const customer_components = {
      body: {
        row: (props) => EditableFormRow(props, this.customerForm),
        cell: EditableCell,
      },
    };
    const defaultColumns = this.defaultColumns.map((col, index) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleDefaultSave,
        }),
      };
    });
    const customerColumns = this.customerColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          type: col.type,
          handleSave: this.handleCustomerSave,
        }),
      };
    });
    return (
      <div className="manage-customer-wrapper system">
        <Row gutter={16}>
          <Col lg={24} md={24} sm={24} sx={24}>
            <Row gutter={16}>
              <Col lg={24} md={24} sm={24} sx={24}>
                <Form onSubmit={this.onSaveButtonClick} name="form" autoComplete="off">
                  {/* Controller Settings */}
                  <Card
                    className="gx-card customer-edit-info-card"
                    title={<IntlMessages id="iobserver.controllerSettings" />}
                    loading={loading}
                  >
                    <Row className="baseLineAlignedItems" gutter={16}>
                      <Col lg={12} md={24} sm={24} xs={24}>
                        <div>
                          <IntlMessages id="iobserver.defaultSystemConstroller" />
                        </div>
                      </Col>
                      <Col className="padding-right-0" lg={12} md={24} sm={24} xs={24}>
                        <Input
                          autoComplete="off"
                          type="text"
                          onChange={(e) => { this.onChangeIobserverData('esitter_controller_default', e); }}
                          value={data?.esitter_controller_default?.value}
                        />
                      </Col>
                    </Row>
                    <Row className="baseLineAlignedItems" gutter={16}>
                      <Col lg={12} md={24} sm={24} xs={24}>
                        <IntlMessages id="iobserver.updateClientsWithMatchingController" />
                      </Col>
                      <Col lg={12} md={24} sm={24} xs={24}>
                        <Checkbox
                          type="checkbox"
                          onChange={(e) => { this.onChangeCheckbox('update_matching_customers', e); }}
                        />
                      </Col>
                    </Row>
                    <Row className="baseLineAlignedItems" gutter={16}>
                      <Col lg={18} md={24} sm={24} xs={24}>
                        <div>
                          <IntlMessages id="iobserver.saveSettings" />
                        </div>
                      </Col>
                      <Col lg={6} md={24} sm={24} xs={24}>
                        <Button
                          title="Update"
                          alt="Update Link"
                          className="sendButton"
                          htmlType="submit"
                        >
                          <IntlMessages id="configuration.save" />
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </Form>
                <Form onSubmit={this.systemSave} ref={this.systemForm} autoComplete="off">
                  {/* Default System Turn Servers */}
                  <Card
                    className="gx-card customer-edit-info-card"
                    title={<IntlMessages id="iobserver.defaultSystemTurnServers" />}
                    loading={loading}
                  >

                    <Row className="baseLineAlignedItems" gutter={16}>
                      <Col lg={6} md={6} sm={24} xs={24}>
                        <Button className="sendButton margin-left-0" onClick={this.handleAddDefaultTurnServer}>
                          <IntlMessages id="iobserver.addARow" />
                        </Button>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col lg={24} md={6} sm={24} xs={24}>
                        <div className="uhe-table">
                          <Table
                            components={components}
                            rowClassName={() => 'editable-row iobserver-tables'}
                            bordered
                            dataSource={dataDefaultServer}
                            columns={defaultColumns}
                            pagination={false}
                            rowKey={(rec) => `${rec.address}_${(Math.random() * 1000000000)}`}
                            className="gx-table-responsive"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Form>
                <Form ref={this.customerForm} autoComplete="off">
                  {/* Add/Edit Customer Turn Server */}
                  <Card
                    className="gx-card customer-edit-info-card"
                    title={<IntlMessages id="iobserver.addEditCustomerTurnServerGroup" />}
                    loading={loading}
                  >
                    <Row className="baseLineAlignedItems" gutter={16}>
                      <Col lg={12}>
                        <Cascader
                          getPopupContainer={(event) => event.parentNode}
                          dropdownClassName="top-filter-popup"
                          suffixIcon={focusCustomerCascader ? <SearchOutlined /> : null}
                          onFocus={() => this.setState({ focusCustomerCascader: true })}
                          onBlur={() => this.setState({ focusCustomerCascader: false })}
                          fieldNames={{ label: 'name', value: 'id' }}
                          key="customer"
                          expandTrigger="hover"
                          size="large"
                          changeOnSelect
                          allowClear
                          options={options.customer || []}
                          onChange={(id, values) => this.onChangeCustomerHandler(values)}
                          placeholder={this.intl.formatMessage({ id: 'uhe.table.customer' })}
                          showSearch
                          longdesc={this.intl.formatMessage({ id: 'configuration.users.customerDropdown' })}
                        />
                      </Col>
                      <Col className="iobserver-add-row-col" lg={3} md={6} sm={24} xs={24}>
                        <Button className="sendButton" onClick={this.handleAddCustomerTurnServer} disabled={!(customer && customer.id)}>
                          <IntlMessages id="iobserver.addARow" />
                        </Button>
                      </Col>
                    </Row>
                    <Row className="baseLineAlignedItems" gutter={16}>
                      <Col lg={16} md={24} sm={24} xs={24}>
                        <div />
                      </Col>
                    </Row>
                    <Row className="baseLineAlignedItems" gutter={16}>

                      <Col lg={24} md={24} sm={24} xs={24}>
                        <div className="uhe-table">
                          <Table
                            components={customer_components}
                            rowClassName={() => 'editable-row iobserver-tables'}
                            bordered
                            dataSource={this.dataAdapter(dataCustomerServer)}
                            columns={customerColumns}
                            pagination={false}
                            rowKey={(rec) => `${rec.address}_${(Math.random() * 1000000000)}`}
                            className="gx-table-responsive"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

Iobserver.defaultProps = {
  data: {},
  defaultServer: [],
  customerServer: [],
  loading: true,
};

Iobserver.propTypes = {
  data: PropTypes.object,
  defaultServer: PropTypes.array,
  customerServer: PropTypes.array,
  iobserverOnFetchData: PropTypes.func,
  defaultTurnServer: PropTypes.func,
  customerTurnServer: PropTypes.func,
  setSubtitle: PropTypes.func,
};

/**
 * @description Map global state to props
 * @param {Object} subtitle
 * @return {Object}
 */
const mapStateToProps = ({ iObserverSettings, subtitle, listingsTopFilter }) => {
  const data = iObserverSettings.data || {};
  const defaultServer = iObserverSettings.defaultServer || [];
  const customerServer = iObserverSettings.customerServer || [];
  const { loading } = iObserverSettings;

  return {
    data,
    loading,
    subtitle,
    defaultServer,
    customerServer,
    optionsList: listingsTopFilter,
  };
};

/**
 * @description Map dispatch to props
 * @param {function} dispatch
 * @return {Object}
 */
const mapDispatchToProps = (dispatch) => ({
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  iobserverOnFetchData: (page) => dispatch(iobserverOnFetchData(page)),
  saveIobserverData: (data) => dispatch(saveIobserver(data)),
  saveDefaultTurnServer: (data) => dispatch(saveDefaultTurnServer(data)),
  defaultTurnServer: (page) => dispatch(defaultTurnServer(page)),
  deleteDefaultTurnServer: (data) => dispatch(deleteDefaultTurnServer(data)),
  customerTurnServer: (page) => dispatch(customerTurnServer(page)),
  saveCustomerTurnServer: (data) => dispatch(saveCustomerTurnServer(data)),
  deleteCustomerTurnServer: (data) => dispatch(deleteCustomerTurnServer(data)),
  onGetCustomerOptions: (id) => dispatch(onGetOptions('customer', id === 'All' ? 0 : id)),
  clearIobserverState: () => dispatch(clearIobserverState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(Iobserver)));
