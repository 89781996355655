import ApiMobilePatient from './ApiMobilePatient';
import ReactiveMobilePatient from './ReactiveMobilePatient';

export const MobilePatientTransformer = {
  /**
   * @param {ApiMobilePatient} patient
   * @returns {ReactiveMobilePatient}
   */
  fromApiToReact(patient) {
    return new ReactiveMobilePatient({
      id: patient.id,
      unit: patient.mobile.cart,
      facility: patient.mobile.cart.facility,
      customer: patient.mobile.cart.facility.customer,
      organization: patient.mobile.cart.facility.customer.organization,
      prefix: patient.prefix,
      firstName: patient.first_name,
      lastName: patient.last_name,
      email: patient.email,
      phoneNumber: patient.phone_number,
      notes: patient.notes,
      techAuthorized: patient.tech_authorized,
      isClinician: patient.is_clinician,
      isIobserver: patient.is_iobserver,
      isTechnician: patient.is_technician,
      isNotification: patient.is_notification,
      isReadonly: patient.is_readonly,
      isMobileUser: patient.is_mobile_user,
      alias: patient.mobile.alias,
      role: patient.mobile.role,
      specialty: patient.mobile.specialty,
      updatedAt: patient.updated_at,
      updatedBy: patient.updated_by,
      createdAt: patient.created_at,
      is_sso_account: patient.is_sso_account
    });
  },
  /**
   * Handles clearing patient information
   * @returns {ReactiveMobilePatient} empty ReactiveMobilePatient object
   */
  clearInputs() {
    return new ReactiveMobilePatient({});
  },

  /**
   * @param {ReactiveMobilePatient} patient
   * @param {boolean} isCreation
   * @returns {ApiMobilePatient}
   */
  fromReactToApi(patient, isCreation = false) {
    const apiPatient = new ApiMobilePatient({
      id: patient.id,
      prefix: patient.prefix,
      first_name: patient.firstName,
      last_name: patient.lastName,
      email: patient.email,
      password: patient.password,
      phone_number: patient.phoneNumber,
      notes: patient.notes,
      tech_authorized: +patient.techAuthorized,
      is_clinician: +patient.isClinician,
      is_iobserver: +patient.isIobserver,
      is_technician: +patient.isTechnician,
      is_notification: +patient.isNotification,
      is_readonly: +patient.isReadonly,
      is_mobile_user: +patient.isMobileUser,
      is_sso_account: +patient.is_sso_account,
      mobile: {
        alias: patient.alias,
        role: patient.role,
        specialty: patient.specialty,
        cart: {
          id: patient.unit?.id,
          name: patient.unit?.name,
          facility: {
            id: patient.facility?.id,
            name: patient.facility?.name,
            customer: {
              id: patient.customer?.id,
              name: patient.customer?.name,
              organization: {
                id: patient.organization?.id,
                name: patient.organization?.name,
              },
            },
          },
        },
      },
    });

    if (isCreation) {
      delete apiPatient.id;
      delete apiPatient.tech_authorized;
      delete apiPatient.is_clinician;
      delete apiPatient.is_iobserver;
      delete apiPatient.is_technician;
      delete apiPatient.is_notification;
      delete apiPatient.is_readonly;
      apiPatient.is_mobile_user = 1;
    } else {
      delete apiPatient.password;
    }

    return apiPatient;
  },
};
