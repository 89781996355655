/**
 * User Preferences API Model
 */
export default class ApiUserPreferences {
  /**
   * ApiUserPreferences Constructor
   * @param {object} obj ApiUserPreferences Members
   */
  constructor(obj) {
    /**
     * @type {string}
     */
    this.screen = undefined;

    /**
     * @type {object}
     */
    this.preferences = undefined;

    if (obj) {
      Object.assign(this, obj);
    }
  }
}
