import {
  SYSTEM_DEVELOPER_FETCH_DATA,
  SYSTEM_DEVELOPER_FETCH_DATA_SUCCESS,
} from "@constants/UHEActionTypes";

/**
 * @description Handle SYSTEM_DEVELOPER_FETCH_DATA action
 * @param {number} page
 * @param {string} sorting
 * @param {string} filter
 * @returns {object}
 */
export const developerOnFetchData = (page, sorting, filter) => {
  return {
    type: SYSTEM_DEVELOPER_FETCH_DATA,
    page,
    sorting,
    filter,
  };
};

/**
 * @description Handle SYSTEM_DEVELOPER_FETCH_DATA_SUCCESS
 * @param  {Object} data
 * @returns {Object}
 */
export const developerOnFetchDataSuccess = (data) => {
  return {
    type: SYSTEM_DEVELOPER_FETCH_DATA_SUCCESS,
    data,
  };
};
