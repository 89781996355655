import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchSignInReportsSuccess } from '@actions/uhe/reports/SignInReportsActions';
import { fetchError, showMessage } from '@actions/Common';
import RestManager from '@util/RestManager';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { FETCH_SIGNIN_REPORTS_REQUEST } from '@constants/UHEActionTypes';

/**
 * @description Sends GET Request at /sign_in_summary
 * @param {number} page
 * @param {Array} sorting
 * @param {string} filter
 * @returns {Object}
 */
const fetchSingInReports = async (page, sorting, filter) => {
  const filterQueryString =
    filter && filter.length ? `&${filter.join('&')}` : '';
  const sortingQueryString =
    sorting && sorting.length ? `&sort=${sorting.join('&sort=')}` : '';
  return await RestManager.request(
    `${ENDPOINTS.reports.signInReports}?page=${
      page || 0
    }${sortingQueryString}${filterQueryString}`
  );
};

/**
 * @description Handles Response from GET /sign-in-summary
 * @param {number} page
 * @param {Array} sorting
 * @param {string} filter
 * @return {void}
 */
function* doFetchSignInReports({ page, sorting, filter }) {
  try {
    const fetchedData = yield call(fetchSingInReports, page, sorting, filter);
    yield put(fetchSignInReportsSuccess(fetchedData));
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Store All Watcher Sagas
 * @returns {void}
 */
export function* actionsWatcher() {
  yield takeEvery(FETCH_SIGNIN_REPORTS_REQUEST, doFetchSignInReports);
}

export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
