/**
 * @description Class JavaScript object for mobile patient data from API type checking
 */
export default class ApiMobilePatient {
  constructor(obj) {
    this.id = 0;
    this.prefix = '';
    this.first_name = '';
    this.last_name = '';
    this.email = '';
    this.phone_number = '';
    this.tech_authorized = false;
    this.is_clinician = false;
    this.is_iobserver = false;
    this.is_technician = false;
    this.is_notification = false;
    this.is_readonly = false;
    this.is_mobile_user = false;
    this.mobile = {
      alias: '',
      role: '',
      specialty: '',
      cart: {
        id: 0,
        name: '',
        facility: {
          id: 0,
          name: '',
          customer: {
            id: 0,
            name: '',
            organization: {
              id: 0,
              name: '',
            },
          },
        },
      },
    };
    /**
     * @type {number}
     */
    this.created_at = undefined;
    /**
     * @type {number}
     */
    this.updated_at = undefined;
    /**
     * @type {string}
     */
    this.updated_by = undefined;

    if (obj) {
      Object.assign(this, obj);
    }
  }
}
