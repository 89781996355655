import {
  MONITORING_UHE_FETCH_SUCCESS,
  MONITORING_UHE_FETCH_DATA,
} from '@constants/UHEActionTypes';
import { tableContent } from 'util/UheHelper';

const INIT_STATE = {
  loading: true,
  table: {
    list: [],
    page: {},
  },
};

/**
 * Uhe Reducers
 * @param  {Object} state Current state
 * @param  {String} action object
 * @returns {Object} state
 */
export default (state = INIT_STATE, action) => {
  let currentTable = {};
  switch (action.type) {
    case MONITORING_UHE_FETCH_SUCCESS:
      currentTable = tableContent(action.data, state.table);
      return { ...state, table: currentTable, loading: false };

    case MONITORING_UHE_FETCH_DATA:
      return { ...state, loading: true };

    default:
      return state;
  }
};
