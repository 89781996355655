import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';
import {
  saveSupportVideoSuccess,
  fetchSupportDocumentDataSuccess,
  uploadDocumentSuccess,
  supportSectionOnFetchDataSuccess,
  addSectionSuccess,
  deleteDocumentSuccess,
} from '@actions/uhe/system/SupportActions';
import {
  CREATE_SUPPORT_VIDEO_REQUEST,
  DELETE_SUPPORT_VIDEO_REQUEST,
  SUPPORT_DOCUMENT_FETCH_DATA,
  CREATE_SUPPORT_DOCUMENT_REQUEST,
  SUPPORT_SECTION_FETCH_DATA,
  DOWNLOAD_PDF_REQUEST,
  DELETE_SUPPORT_DOCUMENT_REQUEST,
  CREATE_SUPPORT_SECTION_REQUEST,
  DELETE_SUPPORT_SECTION_REQUEST,
  SUPPORT_DOCUMENT_FETCH_DATA_SEARCH,
} from '@constants/UHEActionTypes';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { fetchError, showMessage } from '@actions/Common';
import RestManager from '@util/RestManager';
import { fetchDocumentsBySearchSuccess } from '../../../actions/uhe/system/SupportActions';

/**
 * Request for add new video
 * @param  {string} link video url
 * @param  {string} name video name
 * @param  {string} id video id
 * @return {Object} json response
 */
const saveVideoData = async (link, name, id) => await RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.support.uploadVideo}${name}&section.id=${id}&link=${link}`,
  'POST',
);

/**
 * Save video
 * @param {Object} data data
 * @returns {void}
 */
function* saveVideo(data) {
  const { youtube_path, video_description, section } = data.payload;
  try {
    const videoId = yield call(saveVideoData, youtube_path, video_description, section.id);
    if (videoId) {
      yield put(saveSupportVideoSuccess(videoId));
      const fetchedData = yield call(doFetchDocumentData);
      if (fetchedData) {
        yield put(fetchSupportDocumentDataSuccess(fetchedData));
      }
      yield put(showMessage('videoAdded'));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Delete request
 * @param {Number} id video id
 * @return  {Promise} request response
 */
const deleteVideo = async (id) => await RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.support.deleteVideo}/${id}`,
  'DELETE',
  { id },
);

/**
 * Delete video by given id
 * @param {string} data video data
 * @return {void}
 */
function* delVideo(data) {
  try {
    const deletedVideo = yield call(deleteVideo, data.payload.id);
    if (deletedVideo && deletedVideo.status >= 200 && deletedVideo.status < 300) {
      yield put(showMessage('videoDeleted'));
    } else {
      yield put(fetchError(deletedVideo));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Request data
 * @param {string} searchValue searc input value
 * @return {Object} request object data
 */
const doFetchDocumentsDataBySearch = async (searchValue) => {
  const request = await RestManager.request(
    `${ENDPOINTS.support.documentSupportSearchQuery}%25${searchValue}%25`,
  );
  return request;
};

/** Fetch document data
 * @param {object} payload payload
 * @return {Objects} data
 */
function* fetchDocumentsDataBySearch(payload) {
  try {
    const fetchedData = yield call(doFetchDocumentsDataBySearch, payload.value);
    if (fetchedData) {
      yield put(fetchDocumentsBySearchSuccess(fetchedData));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}
/**
 * Request data
 * @param {string} num specify api page number
 * @return {Object} request object data
 */
const doFetchDocumentData = async () => {
  const request = await RestManager.request(
    `${ENDPOINTS.support.documentSupport}`,
  );
  return request;
};

/** Fetch document data
 * @return {Objects} data
 */
function* fetchDocumentData() {
  try {
    const fetchedData = yield call(doFetchDocumentData);
    if (fetchedData) {
      yield put(fetchSupportDocumentDataSuccess(fetchedData));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Upload document request
 * @param {Object} file data
 * @param {string} sectionId section id
 * @param {string} documentName document name
 * @returns {Object} request data
 */
const uploadDocumentRequest = async (file, sectionId, documentName) => await RestManager.formDataRequest(
  `${ENDPOINTS.support.uploadFile}${documentName}&section.id=${sectionId}`,
  file,
);

/**
 *  Handles Response and Request for Uploading CSV File
 * @returns {void}
 */
function* uploadDocument({ payload: { file, name, sectionId } }) {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('section.id', sectionId);
    const { id: uploadedDocumentId } = yield call(uploadDocumentRequest, formData, sectionId, name);

    if (uploadedDocumentId) {
      yield put(uploadDocumentSuccess(uploadedDocumentId, file.name, name, sectionId));
      const fetchedData = yield call(doFetchDocumentData);
      if (fetchedData) {
        yield put(fetchSupportDocumentDataSuccess(fetchedData));
      }
      yield put(showMessage('documentUploaded'));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 *  Request section data
 * @return {Object} data
 */
const doFetchSectionData = async () => await RestManager.request(
  `${ENDPOINTS.support.sectionSupport}`,
);

/**
 * Fetch section Data
 * @return {Objects} data
 */
function* fetchSectionData() {
  try {
    const fetchedData = yield call(doFetchSectionData);
    yield put(supportSectionOnFetchDataSuccess(fetchedData));
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Request download PDF
 * @param  {Number} id file id
 * @return {Object} data
 */
const doDownloadPdf = async (id) => await RestManager.requestFile(
  `${ENDPOINTS.support.download(id)}`,
);

/**
 * Download pdf file
 * @return {void}
 */
function* DownloadPdf({ payload: { id, name } }) {
  try {
    const res = yield call(doDownloadPdf, id);
    const url = URL.createObjectURL(res);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Delete request
 * @param {string} id document id
 * @return  {object} request object response
 */
const deleteDocumentRequest = (id) => RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.support.deleteDocument}/${id}`,
  'DELETE',
  { id },
);

/**
 * Delete document by given id
 * @param {string} data document data
 * @return {void}
 */
function* deleteDocument({ payload: id }) {
  try {
    const deletedDocument = yield call(deleteDocumentRequest, id);
    if (deletedDocument && deletedDocument.status >= 200 && deletedDocument.status < 300) {
      yield put(deleteDocumentSuccess(id));
      yield put(showMessage('documentDeleted'));
    } else {
      yield put(fetchError(deletedDocument));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Request for add new section data
 * @param  {Object} bodyData section data
 * @return {Object} request object response
 */
const addSectionData = async (bodyData) => await RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.support.addSection}`,
  'POST',
  bodyData,
);

/**
 * Save section
 * @param {Object} data section data
 * @returns {void}
 */
function* addSection(data) {
  const { parent_section: { id } } = data.payload;
  try {
    const sectionId = yield call(addSectionData, data.payload);

    if (sectionId) {
      yield put(addSectionSuccess(sectionId));
      yield put(showMessage(id ? 'subsectionCreated' : 'sectionCreated'));
      const fetchedData = yield call(doFetchSectionData);
      yield put(supportSectionOnFetchDataSuccess(fetchedData));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Delete request
 * @param {string} id section id
 * @return {object} request object response
 */
const deleteSection = async (id) => await RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.support.deleteSection}/${id}`,
  'DELETE',
  { id },
);

/**
 * Delete document by given id
 * @param {string} data section data
 * @return {void}
 */
function* delSection(data) {
  const { section } = data.payload;
  const { parent_section: { id } } = section;
  try {
    const deletedSection = yield call(deleteSection, section.id);
    if (deletedSection && deletedSection.status >= 200 && deletedSection.status < 300) {
      yield put(showMessage(id ? 'subsectionDeleted' : 'sectionDeleted'));
      const fetchedData = yield call(doFetchSectionData);
      yield put(supportSectionOnFetchDataSuccess(fetchedData));
    } else {
      yield put(fetchError(deletedSection));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}
/**
* Sagas Watcher
* @returns {void}
*/
export function* actionsWatcher() {
  yield takeEvery(CREATE_SUPPORT_VIDEO_REQUEST, saveVideo);
  yield takeEvery(DELETE_SUPPORT_VIDEO_REQUEST, delVideo);
  yield takeEvery(SUPPORT_DOCUMENT_FETCH_DATA, fetchDocumentData);
  yield takeEvery(CREATE_SUPPORT_DOCUMENT_REQUEST, uploadDocument);
  yield takeEvery(SUPPORT_SECTION_FETCH_DATA, fetchSectionData);
  yield takeEvery(DOWNLOAD_PDF_REQUEST, DownloadPdf);
  yield takeEvery(DELETE_SUPPORT_DOCUMENT_REQUEST, deleteDocument);
  yield takeEvery(CREATE_SUPPORT_SECTION_REQUEST, addSection);
  yield takeEvery(DELETE_SUPPORT_SECTION_REQUEST, delSection);
  yield takeEvery(SUPPORT_DOCUMENT_FETCH_DATA_SEARCH, fetchDocumentsDataBySearch);
}

/**
* Performs Non-Blocking Call
* @returns {void}
*/
export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
