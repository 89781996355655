//ListingsTopFilterSaga
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { fetchOptionsSuccess } from '@actions/uhe/filters/ListingsTopFilterActions';
import { LISTINGS_TOP_FILTER_GET_OPTIONS } from '@constants/UHEActionTypes';
import { TOP_FILTER_PAGE_SIZE } from '@constants/UHESettings';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { fetchError } from "@actions/Common";
import RestManager from "@util/RestManager";

/**
 * @param  {string} key
 * @param  {string} filter
 * @return {Object}
 */
const getOptions = async (key, filter, shouldSort = true) => {
  let sortString = shouldSort ? '&sort=name,asc' : '';

  switch (key) {
    case 'organization':
      return await RestManager.request(`${ENDPOINTS.topFilter.organization}&size=${TOP_FILTER_PAGE_SIZE}${sortString}`);
    case 'customer':
      return await RestManager.request(`${ENDPOINTS.topFilter.customer}&size=${TOP_FILTER_PAGE_SIZE}${filter ? `&organization.id=${filter}` : ''}${sortString}`);
    case 'facility':
      return await RestManager.request(`${ENDPOINTS.topFilter.facility}&size=${TOP_FILTER_PAGE_SIZE}&customer.id=${filter}${sortString}`);
    case 'unit':
      return await RestManager.request(`${ENDPOINTS.topFilter.unit}&size=${TOP_FILTER_PAGE_SIZE}&facility.id=${filter}${sortString}`);
    case 'bedCart':
      sortString = shouldSort ? '&sort=cart_name,asc' : '';
      return await RestManager.request(`${ENDPOINTS.topFilter.bedCart}&size=${TOP_FILTER_PAGE_SIZE}&unit_id=${filter}${sortString}`);
    default:
      return null;
  }
};

/**
 * @param {string} type
 * @param {string} key
 * @param {string} filter
 */
function* fetchFilterOptions({ key, filter, shouldSort }) {
  try {
    const fetchedOptions = yield call(getOptions, key, filter, shouldSort);
    yield put(fetchOptionsSuccess(fetchedOptions, key));
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* fetchOptions() {
  yield takeEvery(LISTINGS_TOP_FILTER_GET_OPTIONS, fetchFilterOptions);
}

export default function* rootSaga() {
  yield all(
    [
      fork(fetchOptions),
    ],
  );
}
