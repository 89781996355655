import {
  LISTINGS_TOP_FILTER_FETCH_SUCCESS,
  RESET_TOP_FILTERS,
} from '@constants/UHEActionTypes';

const INIT_STATE = {
  organization: [],
  customer: [],
  facility: [],
  unit: [],
  bedCart: [],
};

/**
 * @param  {Object} state
 * @param  {string} action
 * @return {Object}
 */
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTINGS_TOP_FILTER_FETCH_SUCCESS: {
      const newState = { [action.key]: action.payload.list };

      return { ...state, ...newState };
    }
    case RESET_TOP_FILTERS:
      return {};
    default:
      return state;
  }
};
