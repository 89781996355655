export default class ApiUserPermission {
  constructor(obj) {
    /**
     * @type {string}
     */
    this.name = undefined;
    /**
     * @type {boolean}
     */
    this.allowed = undefined;
    /**
     * @type {boolean}
     */
    this.granted = undefined;
    /**
     * @type {number}
     */
    this.organization = undefined;
    /**
     * @type {number}
     */
    this.customer = undefined;
    /**
     * @type {number}
     */
    this.facility = undefined;
    /**
     * @type {number}
     */
    this.unit = undefined;

    /**
     * @description "DEV" or "USR"
     * where "DEV" stands for "device"
     * and "USR" stands for "mobile device"
     * @type {string}
     */
    this.type = undefined;
    /**
     * @type {number}
     */
    this.device = undefined;
    /**
     * @type {number}
     */
    this.user = undefined;

    /**
     * @type {ApiUserPermission[]}
     */
    this.nested = undefined;

    if (obj) {
      Object.assign(this, obj);
    }
  }
}
