import React, { useState } from 'react';
import { Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setThemeType } from 'appRedux/actions/Setting';

import {
  THEME_TYPE_DARK,
  THEME_TYPE_LITE,
} from '@constants/ThemeSetting';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';

/**
 * Customize theme mode light||dark
 * @return {JSX.Element} switch
 */
const Customizer = () => {
  const dispatch = useDispatch();

  const themeType = useSelector(({ settings }) => settings.themeType);

  /**
   * Event from theme change
   * @param {String} event string
   * @returns {String} string
   */
  const onThemeTypeChange = (event) => {
    dispatch(setThemeType(event.target.value));
  };

  /**
   * Get customizer content
   * @returns {String} element
   */
  const getCustomizerContent = () => {
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add('lite-theme');
      const link = document.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = '/css/dark_theme.css';
      link.className = 'style_dark_theme';
      document.body.appendChild(link);
    } else if (document.body.classList.contains('lite-theme')) {
      document.body.classList.remove('lite-theme');
      const children = document.getElementsByClassName('style_dark_theme');
      if (children.length > 1) {
        for (let index = 0; index < children.length; index += 1) {
          if (index < children.length) {
            const child = children[index];
            child.parentNode.removeChild(child);
          }
        }
      }
    }

    return (
      <div className="gx-customizer-item">
        <Radio.Group value={themeType} onChange={onThemeTypeChange}>
          <Radio.Button value={THEME_TYPE_LITE}><IntlMessages id="common.darkMode" /></Radio.Button>
          <Radio.Button value={THEME_TYPE_DARK}><IntlMessages id="common.liteMode" /></Radio.Button>
        </Radio.Group>
      </div>
    );
  };

  return (
    getCustomizerContent()
  );
};

export default (Customizer);
