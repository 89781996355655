import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card, Table } from 'antd';
import IntlMessages from 'util/IntlMessages';
import lodash from 'lodash';
import RestManager from '@util/RestManager';
import { BASE_URL, ENDPOINTS } from '@constants/UHEEndpoints';
import UheHelper from 'util/UheHelper';

import ListingsTopFilter from '@filters/ListingsTopFilter';
import ListingsTableInputFilter from '@filters/ListingsTableInputFilter';
import OrganizationCell from '@components/tables/cells/OrganizationCell';
import CustomerCell from '@components/tables/cells/CustomerCell';
import FacilityCell from '@components/tables/cells/FacilityCell';
import UnitCell from '@components/tables/cells/UnitCell';
import AddressCell from '@components/tables/cells/AddressCell';
import UheUnitCell from "@components/tables/cells/UheUnitCell";
import ExportCsv from '@components/ExportCsv/ExportCsv';

import { onFetchData } from '@uhe_actions/monitoring/UHEActions';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import { LISTING_TABLES_PAGE_SIZE, TOP_FILTER_PREFIX, TABLE_FILTER_PREFIX } from '@constants/UHESettings';

/**
 * @description Renders Standards page
 */
class Standards extends Component {
  /**
   * Adapt data returned from the server
   *
   * @param  {Array<Object>} data
   * @return {Array<Object>}
   */
  static dataAdapter(data = []) {
    const adaptedData = [];

    data.forEach((value, index) => {
      adaptedData.push({
        key: index,
        organization: {
          id: value.organization_id,
          name: value.organization_name,
        },
        customer: {
          id: value.customer_id,
          organizationId: value.organization_id,
          name: value.customer_name,
        },
        facility: {
          id: value.facility_id,
          organizationId: value.organization_id,
          customerId: value.customer_id,
          name: value.facility_name,
        },
        unit: {
          id: value.unit_id,
          organizationId: value.organization_id,
          customerId: value.customer_id,
          facilityId: value.facility_id,
          name: value.unit_name,
        },
        bedCart: {
          id: value.device_id,
          name: value.cart_name,
          customerId: value.customer_id,
        },
        protocol: value.dial_out_type,
        address: value.alias,
      });
    });

    return adaptedData;
  }

  /**
   * Use different cell type depending on the column
   *
   * @param  {Object} content
   * @param  {string} key
   * @return {ReactElement|Object}
   */
  static cellRenderer(content, key) {
    let cell;
    switch (key) {
      case 'organization':
        cell = <OrganizationCell content={content} />;
        break;
      case 'customer':
        cell = <CustomerCell content={content} />;
        break;
      case 'facility':
        cell = <FacilityCell content={content} />;
        break;
      case 'unit':
        cell = <UnitCell content={content} />;
        break;
      case "bedCart":
        cell = <UheUnitCell content={content} />;
        break;
      case 'address':
        cell = <AddressCell content={content} />;
        break;

      default:
        cell = content;
    }

    return cell;
  }

  constructor(props) {
    super(props);

    const { history, subtitle } = this.props;

    this.data = [];
    this.columns = [];
    this.topFilterMap = {
      [`${TOP_FILTER_PREFIX}organization`]: 'organization_id',
      [`${TOP_FILTER_PREFIX}customer`]: 'customer_id',
      [`${TOP_FILTER_PREFIX}facility`]: 'facility_id',
      [`${TOP_FILTER_PREFIX}unit`]: 'unit_id',
    };
    this.tableFilterMap = {
      organization: 'organization_name',
      customer: 'customer_name',
      facility: 'facility_name',
      unit: 'unit_name',
      bedCart: 'cart_name',
      protocol: 'dial_out_type',
      address: 'alias',
    };
    this.filterTypes = {};
    this.tableKeys = [
      'organization',
      'customer',
      'facility',
      'unit',
      'bedCart',
      'protocol',
      'address',
    ];

    this.tableKeys.forEach((value, index) => {
      this.columns.push({
        title: (cellData) => (
          <ListingsTableInputFilter
            cellData={cellData}
            title={`uhe.table.${value}`}
            dataKey={value}
          />
        ),
        sorter: (value === 'actions') ? false : { multiple: index },
        align: index > 3 ? 'center' : 'left',
        minWidth: 200,
        dataIndex: value,
        render: (content) => Standards.cellRenderer(content, value),
      });
    });

    this.topFilters = [
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byOrganization',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'organization',
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byCustomer',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'customer',
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byFacility',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'facility',
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byUnit',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'unit',
      },
    ];

    this.history = history;
    this.qParams = new URLSearchParams(this.history.location.search);

    if (subtitle && subtitle.langId !== 'standarts.title') {
      this.props.setSubtitle('standarts.title');
    }

    this.onPageChange = this.onPageChange.bind(this);

    this.onPageChange(1);
  }

  /**
   * @description Updates the view on locaiton change
   * @param {Object} prevProps
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.search !== prevProps.location.search) {
      this.qParams = new URLSearchParams(location.search);
      this.onPageChange(1);
    }
  }

  /**
   * @param  {number} page
   * @return {void}
   */
  onPageChange(page) {
    this.currentPage = page - 1;
    const currSort = this.qParams.getAll('sort') || [];
    const sort = [];
    const filter = [];

    lodash.forOwn(this.topFilterMap, (value, key) => {
      const filterParam = this.qParams.get(key);
      if (filterParam) {
        filter.push(`${value}=${filterParam}`);
      }
    });

    lodash.forOwn(this.tableFilterMap, (value, key) => {
      const filterParam = this.qParams.get(`${TABLE_FILTER_PREFIX}${key}`);
      const sorter = currSort.find((element) => element === `${key},asc` || element === `${key},desc`);

      if (filterParam) {
        if (this.filterTypes[key] && this.filterTypes[key].type === 'dropdown') {
          filter.push(`${value}=${encodeURIComponent(filterParam)}`);
        } else {
          filter.push(`${value}~=${encodeURIComponent(`%${filterParam}%`)}`);
        }
      }

      if (sorter) {
        sort.push(sorter.replace(key, value));
      }
    });

    this.filter = filter;

    this.props.onFetchData(this.currentPage, sort, filter);
    this.forceUpdate();
  }

  /**
   * @description Get endpoint url for data export
   * @returns {string}
   */
  getCsvUrl = () => {
    const token = RestManager.getToken();

    const filterQueryString = this.filter
      && this.filter.length ? `&${this.filter.join('&')}` : '';
    const sortingQueryString = this.sorting
      && this.sorting.length ? `&sort=${this.sorting.join('&sort=')}` : '';

    const tokenParam = `&token=${token}`;
    return `${BASE_URL}${ENDPOINTS.bedCart.nonUheCsv}${filterQueryString}${sortingQueryString}${tokenParam}`;
  }

  render() {
    const { pagination, loading, data } = this.props;
    pagination.onChange = this.onPageChange;

    return (
      <div>
        <Card
          className="filter-boxes gx-card"
          title={<IntlMessages id="uhe.title.filters" />}
        >
          <ListingsTopFilter filters={this.topFilters} />
        </Card>

        <div className="gx-d-flex gx-justify-content-end">
          <p>
            <IntlMessages id="uhe.table.matchingResults" />
            <span>{pagination.total}</span>
          </p>
        </div>
        <div className="uhe-table">
          <Table
            bordered
            className="gx-table-responsive"
            columns={this.columns}
            dataSource={Standards.dataAdapter(data)}
            pagination={pagination}
            loading={loading}
          />
        </div>
      </div>
    );
  }
}

Standards.defaultProps = {
  data: [],
  loading: true,
  pagination: {
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  },
};

Standards.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  pagination: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  subtitle: PropTypes.object,
  onFetchData: PropTypes.func,
  setSubtitle: PropTypes.func,
};

const mapStateToProps = ({ MonitoringStandards, subtitle }) => {
  const { list, page } = MonitoringStandards.table || {};
  const { loading } = MonitoringStandards;
  const pagination = {
    total: page.totalElements || 0,
    current: page.number + 1 || 0,
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  };

  return {
    data: list, pagination, loading, subtitle,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  onFetchData: (page, sort, filter) => dispatch(onFetchData(page, sort, filter, 'standards')),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Standards));
