const UsersSelectors = {};

/**
 * Select currently logged user from the global state ConfigurationUsers space
 * @param {{}} state Global state
 * @returns {*} global state.ConfigurationUsers.ownUser
 */
export const getOwnUser = (state) => state.ConfigurationUsers.ownUser;
UsersSelectors.getOwnUser = getOwnUser;

/**
 * Select currently customer attribute for logged user
 * from the global state ConfigurationUsers space
 * @param {{}} state Global state
 * @returns {*} global state.ConfigurationUsers.customerAttr
 */
export const getCustAttr = (state) => state.ConfigurationUsers.customerAttr;

export default UsersSelectors;
