import React from 'react';
import { injectIntl } from 'react-intl';

import devices_in_alarm from '@assets/images/show_devices_in_alarm.svg';
import devices_not_configured from '@assets/images/show_devices_not_configured.svg';
import devices_online from '@assets/images/show_devices_online.svg';


const VersionCell = ({ content, latestVersion, intl }) => {
  let icon = devices_online;
  let title = intl.formatMessage({ id: 'uhe.table.versionUpToDate' });
  if (content && content < latestVersion) {
    icon = devices_in_alarm;
    title = intl.formatMessage({ id: 'uhe.table.versionOutdated' });
  } else if (content && content > latestVersion) {
    icon = devices_not_configured;
    title = intl.formatMessage({ id: 'uhe.table.versionAhead' });
  }
  return (
    <div className="uhe-table-td" title={title}>
      <span>{content}</span>
      <span className="version">{content && <img className="status-icon" alt="icon" src={icon}/>}</span>
    </div>
  );
};

export default injectIntl(VersionCell);
