import {
    SYSTEM_IOBSERVER_FETCH_DATA,
    SYSTEM_IOBSERVER_FETCH_SUCCESS,
    SAVE_IOBSERVER_SETTINGS,
    SAVE_IOBSERVER_SETTINGS_SUCCESS,
    DEFAULT_TURN_SERVERS,
    DEFAULT_TURN_SERVERS_SUCCESS,
    CUSTOMER_TURN_SERVER,
    CUSTOMER_TURN_SERVER_SUCCESS,
    SAVE_DEFAULT_TURN_SERVER,
    SAVE_DEFAULT_TURN_SERVER_SUCCESS,
    DELETE_DEFAULT_TURN_SERVER_REQUEST,
    DELETE_DEFAULT_TURN_SERVER_REQUEST_SUCCESS,
    SAVE_CUSTOMER_TURN_SERVER,
    SAVE_CUSTOMER_TURN_SERVER_SUCCESS,
    DELETE_CUSTOMER_TURN_SERVER_REQUEST,
    DELETE_CUSTOMER_TURN_SERVER_SUCCESS,
    CUSTOMER_TURN_SERVER_BY_ID,
    CUSTOMER_TURN_SERVER_BY_ID_SUCCESS,
    CLEAR_IOBSERVER_STATE,
} from "@constants/UHEActionTypes";

/**
 * @description Fetch controller settings data
 * @param  {number} page
 * @return {Object}
 */
export const iobserverOnFetchData = (page) => {
  return {
    type: SYSTEM_IOBSERVER_FETCH_DATA,
    page,
  };
};

/**
 * @description controller settings response
 * @param  {Object} data
 * @return {Object}
 */
export const iobserverFetchDataSuccess = data => {
  return {
    type: SYSTEM_IOBSERVER_FETCH_SUCCESS,
    data
  };
};

/**
 * @description Save controller settings request 
 * @param {Object} data 
 * @return {Object}
 */
export const saveIobserver = (data) => {
  return {
    type: SAVE_IOBSERVER_SETTINGS,
    payload: data,
  };
};

/**
 * @description Save controller settings request success
 * @param {object} payload 
 * @return {object}
 */
export const saveIobserverSuccess = (payload) => {
  return {
    type: SAVE_IOBSERVER_SETTINGS_SUCCESS,
    payload,
  };
};

/**
 * @description Fetch default turn server data
 * @param  {number} page
 * @return {Object}
 */
export const defaultTurnServer = (page) => {
  return {
    type: DEFAULT_TURN_SERVERS,
    page,
  };
};

/**
 * @description Default turn server data response
 * @param  {Object} data
 * @return {Object}
 */
export const defaultTurnServerSuccess = data => {
  return {
    type: DEFAULT_TURN_SERVERS_SUCCESS,
    data
  };
};

/**
 * @description Save default turn server request 
 * @param {Object} data 
 * @return {Object}
 */
export const saveDefaultTurnServer = (data) => {
  return {
    type: SAVE_DEFAULT_TURN_SERVER,
    payload: data,
  };
};

/**
 * @description Save default turn server request success
 * @param {object} payload 
 * @return {object}
 */
export const saveDefaultTurnServerSuccess = (payload) => {
  return {
    type: SAVE_DEFAULT_TURN_SERVER_SUCCESS,
    payload,
  };
};

/**
 * @description DELETE request
 * @param {Object} data
 * @return {Promise}
 */
export const deleteDefaultTurnServer = (id) => {
  return {
    type: DELETE_DEFAULT_TURN_SERVER_REQUEST,
    id,
  };
};

/**
 * @description DELETE request success
 * @param {Object} data
 * @return {Promise}
 */
export const deleteDefaultTurnServerSuccess = (data) => {
  return {
    type:  DELETE_DEFAULT_TURN_SERVER_REQUEST_SUCCESS,
    payload: data,
  };
};


/**
 * @description Fetch customer turn server data
 * @param  {number} id
 * @return {Object}
 */
export const customerTurnServer = (id) => {
  return {
    type: CUSTOMER_TURN_SERVER,
    id,
  };
};

/**
 * @description Customer turn server data response
 * @param  {Object} data
 * @return {Object}
 */
export const customerTurnServerSuccess = data => {
  return {
    type: CUSTOMER_TURN_SERVER_SUCCESS,
    data
  };
};

/**
 * @description Save customer turn server request 
 * @param {Object} data 
 * @return {Object}
 */
export const saveCustomerTurnServer = (data) => {
  return {
    type: SAVE_CUSTOMER_TURN_SERVER,
    payload: data
  };
};

/**
 * @description Save customer turn server request success
 * @param {object} payload 
 * @return {object}
 */
export const saveCustomerTurnServerSuccess = (payload) => {
  return {
    type: SAVE_CUSTOMER_TURN_SERVER_SUCCESS,
    payload,
  };
};

/**
 * @description DELETE request
 * @param {Object} data
 * @return {Promise}
 */
export const deleteCustomerTurnServer = (id) => {
  return {
    type: DELETE_CUSTOMER_TURN_SERVER_REQUEST,
    id,
  };
};

/**
 * @description DELETE request success
 * @param {Object} data
 * @return {Promise}
 */
export const deleteCustomerTurnServerSuccess = (data) => {
  return {
    type:  DELETE_CUSTOMER_TURN_SERVER_SUCCESS,
    payload: data,
  };
};

/**
 * @description Handle FETCH_USER_REQUEST Action
 * @param {Object} payload
 * @returns {Object}
 */
export const fetchCustomerTurnServerById = (payload) => {
  return {
    type: CUSTOMER_TURN_SERVER_BY_ID,
    id: payload.id,
  };
};

/**
 * @description Handle FETCH_USER_SUCCESS Action
 * @param {Object} payload
 * @returns {Object}
 */
export const fetchCustomerTurnServerByIdSuccess = (payload) => {
  return {
    type: CUSTOMER_TURN_SERVER_BY_ID_SUCCESS,
    payload: { ...payload },
  };
};

export const clearIobserverState = () => {
  return {
    type: CLEAR_IOBSERVER_STATE,
  };
};
