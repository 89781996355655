import React, { memo, useEffect } from 'react';
import {
  Card, Select, Col, Row, Input, Form, Checkbox, Popover,
} from 'antd';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import {
  UHE_BEDCART_AUTH,
  UHE_BEDCART_INNER_AUTH,
  CONNECTION_TYPES,
  PROTOCOL_TYPES,
} from '@constants/UHESettings';
import { shouldShowEditPasswordIpConfiguration, shouldNotEditIpConfiguration } from '@util/UheRoleChecker';
import { connect } from 'react-redux';

const { Option } = Select;

const connections = Object.values(PROTOCOL_TYPES).map((type) => (
  <Option key={type} title={CONNECTION_TYPES[type]} value={type}>
    {CONNECTION_TYPES[type]}
  </Option>
));

const authTypes = Object.values(UHE_BEDCART_AUTH).map((auth) => (
  <Option key={auth} title={auth} value={auth}>
    {auth}
  </Option>
));

const innerAuthTypes = UHE_BEDCART_INNER_AUTH.map((innerAuth) => (
  <Option key={innerAuth} title={innerAuth} value={innerAuth}>
    {innerAuth}
  </Option>
));
/**
 * Renders IP Configuration Card in ManageUheBedCart file
 * @param {object} bedCart bedCart
 * @param {object} intl intl
 * @param {function} onChangeInputHandler Handles Input Changes
 * @param {function} onChangeCheckBox Handles CA Certificate Required Checkbox Changes
 * @param {function} onChangeAuth on change Auth
 * @param {boolean} loading loading
 * @param {boolean} loggedUser logged User
 * @param {string} connectionProtocol Connection Type Protocol Number
 * @returns {JSX} IpConfigurationCard Component
 */
const IpConfigurationCard = ({
  bedCart,
  intl,
  onChangeInputHandler,
  onChangeCheckBox,
  onChangeAuth,
  loading,
  loggedUser,
  connectionProtocol,
}) => {
  /**
   * Handle Changes in the Connection Input
   * @param {string} protocolType protocolType
   * @returns {void}
   */
  const setProtocol = (protocolType) => {
    onChangeInputHandler({
      target: {
        value: protocolType,
      },
    }, 'protocol');
  };

  useEffect(() => {
    if (!bedCart.protocol) {
      setProtocol(PROTOCOL_TYPES.dhcp);
    }
  }, [bedCart]);

  const isConfigured = bedCart.internal_ip !== 'unknown';
  const shouldNotEditIp = !!((connectionProtocol !== '1' && isConfigured));

  /**
   * Depending on Connection Type Renders it's Input Fields
   * @returns {JSX.Element} renderConnectionType
   */
  const renderConnectionType = () => {
    switch (bedCart.protocol) {
      case PROTOCOL_TYPES.staticIp:
        return (
          <div>
            <Form.Item
              colon={false}
              className="gx-align-items-center"
              name="ip4v"
              label={
                <IntlMessages id="configuration.bedsCarts.ipConfig.ipAddress" />
              }
              initialValue={bedCart.ip4v}
            >
              <Input
                autoComplete="off"
                onChange={(e) => onChangeInputHandler(e, 'ip4v')}
                longdesc={intl.formatMessage({
                  id: 'configuration.bedsCarts.descriptions.ipAddress',
                })}
                disabled={shouldNotEditIpConfiguration(loggedUser, shouldNotEditIp)}
              />
            </Form.Item>
            <Form.Item
              colon={false}
              className="gx-align-items-center"
              name="netmask"
              label={
                <IntlMessages id="configuration.bedsCarts.ipConfig.subMask" />
              }
              initialValue={bedCart.netmask}
            >
              <Input
                autoComplete="off"
                onChange={(e) => onChangeInputHandler(e, 'netmask')}
                longdesc={intl.formatMessage({
                  id: 'configuration.bedsCarts.descriptions.subMask',
                })}
                disabled={shouldNotEditIpConfiguration(loggedUser, shouldNotEditIp)}
              />
            </Form.Item>
            <Form.Item
              colon={false}
              className="gx-align-items-center"
              name="gateway"
              label={
                <IntlMessages id="configuration.bedsCarts.ipConfig.gateway" />
              }
              initialValue={bedCart.gateway}
            >
              <Input
                autoComplete="off"
                onChange={(e) => onChangeInputHandler(e, 'gateway')}
                longdesc={intl.formatMessage({
                  id: 'configuration.bedsCarts.descriptions.gateway',
                })}
                disabled={shouldNotEditIpConfiguration(loggedUser, shouldNotEditIp)}
              />
            </Form.Item>
            <Form.Item
              colon={false}
              className="gx-align-items-center"
              name="preferred_dns"
              label={
                <IntlMessages id="configuration.bedsCarts.ipConfig.prefDns" />
              }
              initialValue={bedCart.preferred_dns}
            >
              <Input
                autoComplete="off"
                onChange={(e) => onChangeInputHandler(e, 'preferred_dns')}
                longdesc={intl.formatMessage({
                  id: 'configuration.bedsCarts.descriptions.prefDns',
                })}
                disabled={shouldNotEditIpConfiguration(loggedUser, shouldNotEditIp)}
              />
            </Form.Item>
            <Form.Item
              colon={false}
              className="gx-align-items-center"
              name="alternate_dns"
              label={
                <IntlMessages id="configuration.bedsCarts.ipConfig.altDns" />
              }
              initialValue={bedCart.alternate_dns}
            >
              <Input
                autoComplete="off"
                onChange={(e) => onChangeInputHandler(e, 'alternate_dns')}
                longdesc={intl.formatMessage({
                  id: 'configuration.bedsCarts.descriptions.altDns',
                })}
                disabled={shouldNotEditIpConfiguration(loggedUser, shouldNotEditIp)}
              />
            </Form.Item>
          </div>
        );

      case PROTOCOL_TYPES.wifi:
        return (
          <div>
            <Popover
              content={<IntlMessages id="configuration.bedsCarts.ipConfig.caseSensitiveField" />}
              placement="topRight"
            >
              <Form.Item
                colon={false}
                className="gx-align-items-center"
                name="ssid"
                label={
                  <IntlMessages id="configuration.bedsCarts.ipConfig.ssid" />
                }
                initialValue={bedCart.ssid}
              >
                <Input
                  autoComplete="off"
                  onChange={(e) => onChangeInputHandler(e, 'ssid')}
                  longdesc={intl.formatMessage({
                    id: 'configuration.bedsCarts.descriptions.ssid',
                  })}
                  disabled={shouldNotEditIpConfiguration(loggedUser, shouldNotEditIp)}
                />
              </Form.Item>
            </Popover>
            <Form.Item
              colon={false}
              className="gx-align-items-center"
              name="ssid_password"
              label={
                <IntlMessages id="configuration.bedsCarts.ipConfig.ssidPass" />
              }
              initialValue={bedCart.ssid_password}
            >
              <Input.Password
                autoComplete="off"
                onChange={(e) => onChangeInputHandler(e, 'ssid_password')}
                longdesc={intl.formatMessage({
                  id: 'configuration.bedsCarts.descriptions.ssidPass',
                })}
                visibilityToggle={shouldShowEditPasswordIpConfiguration(loggedUser)}
                disabled={shouldNotEditIpConfiguration(loggedUser, shouldNotEditIp)}
              />
            </Form.Item>
          </div>
        );

      case PROTOCOL_TYPES.enterpriseWifi:
        return (
          <div>
            <Form.Item
              colon={false}
              className="gx-align-items-center"
              name="enterprise_network_name"
              label={
                <IntlMessages id="configuration.bedsCarts.ipConfig.netName" />
              }
              initialValue={bedCart.enterprise_network_name}
            >
              <Input
                autoComplete="off"
                onChange={(e) => onChangeInputHandler(e, 'enterprise_network_name')}
                longdesc={intl.formatMessage({
                  id: 'configuration.bedsCarts.descriptions.netName',
                })}
                disabled={shouldNotEditIpConfiguration(loggedUser, shouldNotEditIp)}
              />
            </Form.Item>
            <Form.Item
              colon={false}
              className="gx-align-items-center"
              name="enterprise_username"
              label={
                <IntlMessages id="configuration.bedsCarts.ipConfig.user" />
              }
              initialValue={bedCart.enterprise_username}
            >
              <Input
                autoComplete="off"
                onChange={(e) => onChangeInputHandler(e, 'enterprise_username')}
                longdesc={intl.formatMessage({
                  id: 'configuration.bedsCarts.descriptions.user',
                })}
                disabled={shouldNotEditIpConfiguration(loggedUser, shouldNotEditIp)}

              />
            </Form.Item>
            <Form.Item
              colon={false}
              className="gx-align-items-center"
              name="enterprise_password"
              label={
                <IntlMessages id="configuration.bedsCarts.ipConfig.pass" />
              }
              initialValue={bedCart.enterprise_password}
            >
              <Input.Password
                autoComplete="off"
                onChange={(e) => onChangeInputHandler(e, 'enterprise_password')}
                longdesc={intl.formatMessage({
                  id: 'configuration.bedsCarts.descriptions.pass',
                })}
                visibilityToggle={shouldShowEditPasswordIpConfiguration(loggedUser)}
                disabled={shouldNotEditIpConfiguration(loggedUser, shouldNotEditIp)}
              />
            </Form.Item>
            <Form.Item
              colon={false}
              className="gx-align-items-center"
              name="enterprise_auth"
              label={
                <IntlMessages id="configuration.bedsCarts.ipConfig.auth" />
              }
              initialValue={bedCart.enterprise_auth}
            >
              <Select
                onChange={(e, auth) => onChangeAuth(e, auth, 'enterprise_auth')}
                disabled={shouldNotEditIpConfiguration(loggedUser, shouldNotEditIp)}

              >
                {authTypes}
              </Select>
            </Form.Item>
            <Form.Item
              colon={false}
              className="gx-align-items-center"
              name="enterprise_inner_auth"
              label={
                <IntlMessages id="configuration.bedsCarts.ipConfig.innerAuth" />
              }
              initialValue={bedCart?.enterprise_inner_auth?.toUpperCase()}
            >
              <Select
                onChange={(e, auth) => onChangeAuth(e, auth, 'enterprise_inner_auth')}
                disabled={shouldNotEditIpConfiguration(loggedUser, shouldNotEditIp)}
              >
                {innerAuthTypes}
              </Select>
            </Form.Item>
            {bedCart.enterprise_auth === UHE_BEDCART_AUTH.peap && (
              <div>
                <Popover content={<IntlMessages id="configuration.bedsCarts.ipConfig.damainmessage" />}>
                  <Form.Item
                    colon={false}
                    className="gx-align-items-center"
                    name="enterprise_domain"
                    label={
                      <IntlMessages id="configuration.bedsCarts.ipConfig.domainName" />
                    }
                    initialValue={bedCart.enterprise_domain}
                  >

                    <Input
                      autoComplete="off"
                      onChange={(e) => onChangeInputHandler(e, 'enterprise_domain')}
                      longdesc={intl.formatMessage({
                        id: 'configuration.bedsCarts.descriptions.domain',
                      })}
                      disabled={shouldNotEditIpConfiguration(loggedUser, shouldNotEditIp)}
                    />

                  </Form.Item>
                </Popover>
                <Popover
                  overlayStyle={{ width: '20vw' }}
                  content={<IntlMessages id="configuration.bedsCarts.ipConfig.camessage" />}
                >
                  <Form.Item
                    colon={false}
                    className="gx-align-items-center"
                    name="enterprise_ca_cert_required"
                    label={
                      <IntlMessages id="configuration.bedsCarts.ipConfig.cacertificaterequired" />
                    }
                    initialValue={bedCart.enterprise_ca_cert_required}
                  >

                    <Checkbox
                      type="checkbox"
                      onChange={(e) => onChangeCheckBox(e, 'enterprise_ca_cert_required')}
                      checked={bedCart.enterprise_ca_cert_required}
                      longdesc={intl.formatMessage({
                        id: 'configuration.bedsCarts.descriptions.cacertificaterequired',
                      })}
                      disabled={shouldNotEditIpConfiguration(loggedUser, shouldNotEditIp)}
                    />

                  </Form.Item>
                </Popover>
                <Popover
                  overlayStyle={{ width: '20vw' }}
                  content={<IntlMessages id="configuration.bedsCarts.ipConfig.anonymousmessage" />}
                >
                  <Form.Item
                    colon={false}
                    className="gx-align-items-center"
                    name="enterprise_anonymous_Identity"
                    label={
                      <IntlMessages id="configuration.bedsCarts.ipConfig.anonymousIdentity" />
                    }
                    initialValue={bedCart.enterprise_anonymous_identity}
                  >

                    <Input
                      autoComplete="off"
                      onChange={(e) => onChangeInputHandler(e, 'enterprise_anonymous_identity')}
                      longdesc={intl.formatMessage({
                        id: 'configuration.bedsCarts.descriptions.anonymous_identity',
                      })}
                      disabled={shouldNotEditIpConfiguration(loggedUser, shouldNotEditIp)}
                    />
                  </Form.Item>
                </Popover>
              </div>
            )}
          </div>
        );

      default:
        return <></>;
    }
  };

  return (
    <div>
      <Card
        className="gx-card"
        title={<IntlMessages id="configuration.bedsCarts.ipConfig.title" />}
        loading={loading}
      >
        <Row className="gx-align-items-center care-device-row">
          <div>
            <p><IntlMessages id="configuration.bedsCarts.actions.description1" /></p>
            <p><IntlMessages id="configuration.bedsCarts.actions.description2" /></p>
            <p><IntlMessages id="configuration.bedsCarts.actions.description3" /></p>
            <p><IntlMessages id="configuration.bedsCarts.actions.description4" /></p>
          </div>
        </Row>
        <Row className="care-device-row">
          <Col lg={10}>
            <Form.Item
              colon={false}
              className="gx-align-items-center"
              label={
                <IntlMessages id="configuration.bedsCarts.ipConfig.conn" />
              }
            >
              <Select value={bedCart.protocol} onChange={setProtocol} disabled={!!((connectionProtocol !== '1' && isConfigured))}>
                {connections}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={14}>
            {renderConnectionType()}
          </Col>
        </Row>
      </Card>
    </div>
  );
};

IpConfigurationCard.defaultProps = {
  loading: true,
};

IpConfigurationCard.propTypes = {
  bedCart: PropTypes.shape().isRequired,
  intl: PropTypes.shape().isRequired,
  onChangeInputHandler: PropTypes.func.isRequired,
  onChangeCheckBox: PropTypes.func.isRequired,
  onChangeAuth: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  loggedUser: PropTypes.shape().isRequired,
  connectionProtocol: PropTypes.string.isRequired,
};

/**
 * Maps State to Props
 * @param {object} bedsCarts object
 * @returns {object} object with states
 */
const mapStateToProps = ({ bedsCarts }) => ({
  connectionProtocol: bedsCarts.selectedBedCart.protocol,
});

export default connect(mapStateToProps, null)(injectIntl(memo(IpConfigurationCard)));
