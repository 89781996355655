import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'antd';
import { useIntl } from 'react-intl';
import { IMPERSONATE_TOKEN, APP_PAGES_CONTEXT } from '@constants/UHESettings';
import {
  shouldShowDeleteAction,
  shouldShowChangePassword,
  shouldShowResetPassword,
  shouldShowImpersonateAction,
  shouldShowEditAction,
  shouldShowBulkActionsAction,
} from '@util/UheRoleChecker';
import { getOwnUser } from '@uhe_selectors/configuration/users/UsersSelectors';
import IntlMessages from 'util/IntlMessages';

const { confirm } = Modal;

/**
 * @description Action cell for edit and delete functionality
 * @param {string} content
 * @param {string} editLink
 * @param {string} intl
 * @param {void} deleteAction
 * @return {JSX.Element}
 */
const ActionsCell = ({
  editLink,
  bulkLink,
  deleteAction,
  getToken,
  content,
  data,
  resetPasswordAction,
  openChangePasswordModal,
  page,
  reportLog,
}) => {
  const intl = useIntl();
  const loggedUser = useSelector(getOwnUser);

  /**
   * @description Show delete confirming
   * @returns {void}
   */
  const showDeleteConfirm = () => {
    confirm({
      title: intl.formatMessage({ id: 'common.deleteQuestion' }),
      okText: intl.formatMessage({ id: 'common.yes' }),
      cancelText: intl.formatMessage({ id: 'common.no' }),
      onOk: deleteAction,
      autoFocusButton: 'cancel',
      className: 'dark-mode-modal',
    });
  };

  /**
   * @description Sends GET Request to /users/:id/impersonate and Sets Item to the Local Storage
   * @returns {void}
   */
  const getImpersonateToken = () => {
    confirm({
      title: intl
        .formatMessage({ id: 'profile.loginAs' })
        .replace('$name', ` ${content.firstName} ${content.lastName}`),
      okText: intl.formatMessage({ id: 'common.yes' }),
      cancelText: intl.formatMessage({ id: 'common.no' }),
      onOk: getToken,
      className: 'dark-mode-modal',
    });
  };

  /**
   * @description Show reset password confirming
   * @returns {void}
   */
  const resetPassword = () => {
    const email = data.find((user) => user.id === content.id)?.email;
    confirm({
      title: intl.formatMessage({ id: 'common.resetPassword' }),
      content: `${intl.formatMessage({ id: 'configuration.users.resetPassword' })} ${email}`,
      okText: intl.formatMessage({ id: 'configuration.users.sendEmail' }),
      onOk: resetPasswordAction,
    });
  };

  return (
    <div className="uhe-table-td action-butons">
      {shouldShowEditAction(loggedUser, page) && (
        <Link
          to={editLink}
          className="edit-icon"
          title={intl.formatMessage({ id: 'common.edit' })}
        >
          <i className="icon icon-ckeditor" />
        </Link>
      )}
      {shouldShowDeleteAction(loggedUser, page)
        && (
          <Button
            onClick={showDeleteConfirm}
            className="delete-icon"
            title={intl.formatMessage({ id: 'common.delete' })}
          >
            <i className="icon icon-trash" />
          </Button>
        )}
      {shouldShowBulkActionsAction(loggedUser, page) && (
        <Link
          to={bulkLink}
          className="bulk-icon"
          title={intl.formatMessage({ id: 'common.bulk' })}
        >
          <i className="icon icon-extra-components" />
        </Link>
      )}
      {shouldShowImpersonateAction(loggedUser, page) && (
        <Button
          className="login-as"
          onClick={getImpersonateToken}
          title={intl.formatMessage({ id: 'common.impersonate' })}
        >
          <i className="icon icon-user-o" />
        </Button>
      )}
    </div>
  );
};

ActionsCell.defaultProps = {
  openChangePasswordModal: () => {},
  changePasswordAction: () => {},
  resetPasswordAction: () => {},
  getToken: () => {},
  deleteAction: () => {},
};

ActionsCell.propTypes = {
  editLink: PropTypes.string,
  content: PropTypes.object,
  loggedUser: PropTypes.object,
  data: PropTypes.array,
  deleteAction: PropTypes.func,
  getToken: PropTypes.func,
  resetPasswordAction: PropTypes.func,
  changePasswordAction: PropTypes.func,
  openChangePasswordModal: PropTypes.func,
  page: PropTypes.oneOf(Object.values(APP_PAGES_CONTEXT)).isRequired,
};

export default ActionsCell;
