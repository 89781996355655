import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {
  Button, Card, Form, Input, Table, Row, Col,
} from 'antd';

import IntlMessages from 'util/IntlMessages';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import { getMachineInfo, sendAdvancedDevCommand } from '@uhe_actions/system/AdvancedDevActions';
import lodash from 'lodash';
import {
  IS_EDIT_PAGE_REGEX,
  LISTING_TABLES_PAGE_SIZE,
  PARAMS_REGEX,
  TABLE_FILTER_PREFIX,
  JSON_OBJECT_REGEX,
} from '@constants/UHESettings';
import ListingsTableInputFilter from '@filters/ListingsTableInputFilter';
import { shouldResetPaginationSuccess } from '@actions';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { getCurrentSort } from '@util/UheHelper';
import { DownOutlined } from '@ant-design/icons';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

/**
 * Advanced Dev Tools Class Component
 */
class AdvancedDev extends Component {
  /**
   * Constructor
   * @param {object} props Constructor Props
   */
  constructor(props) {
    super(props);
    const { subtitle, setSubtitleAction, history } = this.props;
    this.qParams = new URLSearchParams(history.location.search);
    this.patientFormRef = React.createRef();
    this.bulkPatientFormRef = React.createRef();

    this.state = {
      showMore: 2,
      machine_name: '',
      send_command: '',
      send_params: '',
      batch_name: '',
      command_name: '',
      json_object: '',
      form_password: '9876',
    };

    this.tableFilterMap = {
      room: 'name',
      caregilityId: 'id',
      machineName: 'machine_name',
      isOnline: 'is_online',
    };

    this.tableKeys = [
      'room',
      'caregilityId',
      'machineName',
      'isOnline',
    ];

    this.data = [];
    this.columns = [];
    this.filterTypes = {};
    this.defaultSorted = true;

    this.tableKeys.forEach((value, index) => {
      const filter = this.filterTypes[value] || {};
      this.columns.push({
        title: (cellData) => (
          <ListingsTableInputFilter
            filterType={filter.type}
            filterOptions={filter.options}
            showFilter={value === 'room'}
            cellData={cellData}
            title={`uhe.table.${value}`}
            dataKey={value}
          />
        ),
        sorter: (value === 'actions' || value === 'isOnline') ? false : { multiple: index },
        defaultSortOrder: (value === 'room') ? this.checkSortTableOrder() : false,
        align: index > 3 ? 'center' : 'left',
        minWidth: 200,
        dataIndex: value,
      });
    });

    if (this.isNew) {
      if (subtitle.langId !== 'advancedDev.title') {
        setSubtitleAction('advancedDev.title');
      }
    } else if (subtitle.langId !== 'advancedDev.title') {
      setSubtitleAction('advancedDev.title');
    }
  }

  /**
   * componentDidMount() is invoked immediately after
   * a component is mounted (inserted into the tree)
   * @returns {void} void
   */
  componentDidMount() {
    const { pagination } = this.props;

    this.onPageChange(pagination?.current || 1);
  }

  /**
   * Invokes on Update
   * @param {object} prevProps Advanced Dev PrevProps
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const {
      location, lastLocation, resetPaginationSuccess, shouldResetPaginationFlag,
    } = this.props;

    if (location.search !== prevProps.location.search) {
      this.qParams = new URLSearchParams(location.search);
      this.onPageChange(1);
    }

    if (location.hash !== prevProps.location.hash
      && (!IS_EDIT_PAGE_REGEX.test(lastLocation?.pathname) || shouldResetPaginationFlag)) {
      this.onPageChange(1);
      resetPaginationSuccess();
    }
  }

  /**
   * Handles Page Changes
   * @param {number} page Page Number
   * @returns {void}
   */
  onPageChange = (page) => {
    const { getMachineInfoAction } = this.props;
    this.currentPage = page - 1;
    const currSort = this.qParams.getAll('sort') || [];
    const filter = [];

    lodash.forOwn(this.topFilterMap, (value, key) => {
      const filterParam = this.qParams.get(key);
      if (filterParam) {
        filter.push(`${value}=${filterParam}`);
      }
    });

    lodash.forOwn(this.tableFilterMap, (value, key) => {
      const filterParam = this.qParams.get(`${TABLE_FILTER_PREFIX}${key}`);

      if (filterParam) {
        if (
          this.filterTypes[key]
          && this.filterTypes[key].type === 'dropdown'
        ) {
          filter.push(`${value}=${encodeURIComponent(filterParam)}`);
        } else {
          filter.push(`${value}~=${encodeURIComponent(`%${filterParam}%`)}`);
        }
      }
    });

    let sort = getCurrentSort(currSort, this.tableFilterMap);

    if (this.defaultSorted && currSort.length === 0) {
      sort = ['name,asc', 'id,asc', 'machine_name,asc'];
    } else {
      this.defaultSorted = false;
    }

    this.filter = filter;
    this.sort = sort;
    this.setState({ showMore: page + 1 });

    getMachineInfoAction(page - 1, sort, filter);
  }

  /**
   * Checks Sort Order
   * @returns {string} Order string
   */
  checkSortTableOrder = () => {
    const { location } = this.props;
    let checkOrder = location.search.split('%2C')[1];

    if (checkOrder === 'asc') {
      checkOrder = 'ascend';
    }
    if (checkOrder === 'desc') {
      checkOrder = 'descend';
    }
    if (!checkOrder) {
      checkOrder = false;
    }

    return checkOrder;
  }

  /**
   * Adapts Listing Data
   * @param {array} data Listing Data
   * @returns {array} Listing
   */
  dataAdapter = (data = []) => data.map((value, index) => ({
    key: index,
    caregilityId: value.id,
    room: value.name,
    machineName: value.machine_name,
    isOnline: value.is_online,
  }))

  /**
   * Handles Input Changes
   * @param {object} event Event Data
   * @param {string} key State Key
   * @returns {void}
   */
  inputChangeHandler = (event, key) => {
    this.setState({
      [key]: event.target.value,
    });
  }

  /**
   * Sends sendAdvancedDevCommand Action Patient Device
   * @returns {void}
   */
  sendPatientDevice = () => {
    const { sendAdvancedDevCommandAction } = this.props;
    const {
      machine_name,
      send_command,
      send_params,
    } = this.state;
    const reqBody = {
      body: {
        machine_name,
        send_command,
        send_params,
      },
      url: ENDPOINTS.advancedDev.sendCommand,
    };

    sendAdvancedDevCommandAction(reqBody);
    this.patientFormRef.current.resetFields();
    this.setState({
      machine_name: '',
      send_command: '',
      send_params: '',
    });
  }

  /**
   * Sends sendAdvancedDevCommand Action for Bulk Device Patient
   * @returns {void}
   */
  sendBulkPatientDevice = () => {
    const { sendAdvancedDevCommandAction } = this.props;
    const {
      batch_name,
      command_name,
      json_object,
      form_password,
    } = this.state;
    const reqBody = {
      body: {
        batch_name,
        command_name,
        json_object,
        form_password,
      },
      url: ENDPOINTS.advancedDev.bulkUpdate,
    };

    sendAdvancedDevCommandAction(reqBody);
    this.bulkPatientFormRef.current.resetFields();
    this.setState({
      batch_name: '',
      command_name: '',
      json_object: '',
    });
  }

  /**
  * Renders ManageOrganization Component
  * @returns {JSX.Element} ManageOrganization
  */
  render() {
    const {
      pagination, data, loading, intl,
    } = this.props;
    const { form_password, showMore } = this.state;
    pagination.onChange = this.onPageChange;

    return (
      <div className="manage-customer-wrapper">
        <div className="gx-d-flex gx-justify-content-end">
          <p className="matching-results-label">
            <IntlMessages id="uhe.table.matchingResults" />
            <span>{pagination.total}</span>
          </p>
        </div>
        <Card
          className="gx-card advanced-dev"
          title={<IntlMessages id="advancedDev.getMachineInfo" />}
        >
          <Table
            columns={this.columns}
            dataSource={this.dataAdapter(data)}
            className="custom-scrollbar"
            pagination={false}
            bordered
            loading={loading}
            footer={() => (
              <div className="table-footer">
                <Button type="text" onClick={() => this.onPageChange(showMore)}>
                  <IntlMessages id="common.showMore" />
                  <DownOutlined />
                </Button>
              </div>
            )}
          />
        </Card>
        <Row gutter={16}>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Form
              name="sendToPatientForm"
              {...layout}
              onFinish={this.sendPatientDevice}
              autoComplete="off"
              ref={this.patientFormRef}
            >
              <Card
                className="gx-card"
                title={<IntlMessages id="advancedDev.sendCommandToDevice" />}
              >
                <Form.Item
                  colon={false}
                  autoComplete="off"
                  className="align-item-city form-item-row"
                  label={<IntlMessages id="advancedDev.machineName" />}
                  name="machineName"
                  initialValue=""
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'configuration.users.emptyField' }),
                    },
                  ]}
                >
                  <Input onChange={(event) => this.inputChangeHandler(event, 'machine_name')} />
                </Form.Item>
                <Form.Item
                  colon={false}
                  autoComplete="off"
                  className="align-item-city form-item-row"
                  label={<IntlMessages id="advancedDev.command" />}
                  name="command"
                  initialValue=""
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'configuration.users.emptyField' }),
                    },
                  ]}
                >
                  <Input onChange={(event) => this.inputChangeHandler(event, 'send_command')} />
                </Form.Item>
                <Form.Item
                  colon={false}
                  autoComplete="off"
                  className="align-item-city form-item-row"
                  label={<IntlMessages id="advancedDev.params" />}
                  name="params"
                  initialValue=""
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'configuration.users.emptyField' }),
                    },
                    {
                      pattern: PARAMS_REGEX,
                      message: intl.formatMessage({ id: 'advancedDev.paramsRegexMsg' }),
                    },
                  ]}
                >
                  <Input onChange={(event) => this.inputChangeHandler(event, 'send_params')} />
                </Form.Item>
                <div className="gx-d-flex gx-justify-content-end gx-pr-3">
                  <Button
                    htmlType="submit"
                    className="command-btn command-btn--green"
                  >
                    <IntlMessages id="advancedDev.sendCommand" />
                  </Button>
                </div>
              </Card>
            </Form>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Form
              name="sendBulkPatientForm"
              {...layout}
              onFinish={this.sendBulkPatientDevice}
              autoComplete="off"
              ref={this.bulkPatientFormRef}
            >
              <Card
                className="gx-card"
                title={<IntlMessages id="advancedDev.sendBulkCommandToDevice" />}
              >
                <Form.Item
                  colon={false}
                  autoComplete="off"
                  className="align-item-city form-item-row"
                  label={<IntlMessages id="advancedDev.batchName" />}
                  name="batchName"
                  initialValue=""
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'configuration.users.emptyField' }),
                    },
                  ]}
                >
                  <Input onChange={(event) => this.inputChangeHandler(event, 'batch_name')} />
                </Form.Item>
                <Form.Item
                  colon={false}
                  autoComplete="off"
                  className="align-item-city form-item-row"
                  label={<IntlMessages id="advancedDev.commandName" />}
                  name="commandName"
                  initialValue=""
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'configuration.users.emptyField' }),
                    },
                  ]}
                >
                  <Input onChange={(event) => this.inputChangeHandler(event, 'command_name')} />
                </Form.Item>
                <Form.Item
                  colon={false}
                  autoComplete="off"
                  className="align-item-city form-item-row"
                  label={<IntlMessages id="advancedDev.jsonObject" />}
                  name="jsonObject"
                  initialValue=""
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'configuration.users.emptyField' }),
                    },
                    {
                      pattern: JSON_OBJECT_REGEX,
                      message: intl.formatMessage({ id: 'advancedDev.jsonObjectRegexMsg' }),
                    },
                  ]}
                >
                  <Input onChange={(event) => this.inputChangeHandler(event, 'json_object')} />
                </Form.Item>
                <Form.Item
                  colon={false}
                  autoComplete="off"
                  className="align-item-city form-item-row"
                  label={<IntlMessages id="advancedDev.password" />}
                  name="password"
                  initialValue={form_password}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'configuration.users.emptyField' }),
                    },
                  ]}
                >
                  <Input.Password
                    type="password"
                    onChange={(event) => this.inputChangeHandler(event, 'form_password')}
                    disabled
                  />
                </Form.Item>
                <div className="gx-d-flex gx-justify-content-end gx-pr-3">
                  <Button className="command-btn command-btn--green" htmlType="submit">
                    <IntlMessages id="advancedDev.update" />
                  </Button>
                </div>
              </Card>
            </Form>
          </Col>
        </Row>

      </div>
    );
  }
}

AdvancedDev.defaultProps = {
  history: PropTypes.object,
  subtitle: PropTypes.object,
  location: PropTypes.object,
  intl: PropTypes.shape(undefined),
  lastLocation: PropTypes.object,
  shouldResetPaginationFlag: false,
};

AdvancedDev.propTypes = {
  history: PropTypes.shape(undefined),
  intl: PropTypes.shape(undefined),
  setSubtitleAction: PropTypes.func.isRequired,
  subtitle: PropTypes.shape(undefined),
  location: PropTypes.shape(undefined),
  lastLocation: PropTypes.shape(undefined),
  getMachineInfoAction: PropTypes.func.isRequired,
  pagination: PropTypes.shape().isRequired,
  data: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  shouldResetPaginationFlag: PropTypes.bool,
  resetPaginationSuccess: PropTypes.func.isRequired,
  sendAdvancedDevCommandAction: PropTypes.func.isRequired,
};

/**
 * Maps Global State to Component's Props
 * @returns {Object} data
 */
const mapStateToProps = ({
  advancedDev, subtitle, ConfigurationUsers, common,
}) => ({
  subtitle,
  loggedUser: ConfigurationUsers.ownUser,
  pagination: {
    total: advancedDev?.table?.page?.totalElements || 0,
    current: advancedDev?.table?.page?.number + 1 || 0,
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  },
  data: advancedDev.table.list,
  loading: advancedDev.loading,
  shouldResetPaginationFlag: common.shouldResetPagination,
});

/**
 * Returns Object Which Dispatch Actions to the Store
 * @param {function} dispatch data
 * @returns {Object} data
 */
const mapDispatchToProps = (dispatch) => ({
  setSubtitleAction: (langId) => dispatch(setSubtitle(langId)),
  getMachineInfoAction: (page, sorting, filter) => dispatch(getMachineInfo(page, sorting, filter)),
  resetPaginationSuccess: () => dispatch(shouldResetPaginationSuccess()),
  sendAdvancedDevCommandAction: (data) => dispatch(sendAdvancedDevCommand(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLastLocation(injectIntl(withRouter(AdvancedDev))));
