import { getLoggedUser } from '@util/UheHelper';
import { APP_PAGES_CONTEXT } from '@constants/UHESettings';

/**
 * Checks for role permission to disable Verified Checkbox
 * @param {Object} loggedUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldDisableVerifiedCheckbox = (loggedUser) => {
  const { isCaregilitySystemAdmin } = loggedUser;
  return isCaregilitySystemAdmin;
};

/**
 * Checks for role permission to disable Caller ID input
 * @param {Object} loggedUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldDisableCallerID = (loggedUser) => {
  const { isCaregilitySystemAdmin, isOrganizationAdmin, isCustomerAdmin } = loggedUser;

  return isCaregilitySystemAdmin || isOrganizationAdmin || isCustomerAdmin;
};
/**
 * Checks for role permission to show Customer ActionsCell
 * @param {Object} loggedUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldShowCustomerActionsCell = (loggedUser) => {
  const { isCaregilitySystemAdmin, isOrganizationAdmin } = loggedUser;

  return isCaregilitySystemAdmin || isOrganizationAdmin;
};

/**
 * Checks for role permission to disable input fields
 * @param {Object} loggedUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldDisableInputFields = (loggedUser) => {
  const isUnitsPage = window.location.href.includes('configuration/units/');
  const {
    isCaregilitySystemAdmin,
    isOrganizationAdmin,
    isCustomerAdmin,
    isFacilityAdmin,
  } = loggedUser;

  return (
    !isCaregilitySystemAdmin
    && !isOrganizationAdmin
    && !isCustomerAdmin)
    && (!isFacilityAdmin && isUnitsPage);
};

/**
 * Checks for role permission to show User ActionsCell
 * @param {Object} loggedUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldShowUsersActionsCell = (loggedUser) => {
  const {
    isCaregilitySystemAdmin,
    isCaregilityAdmin,
    isUserAdmin,
    isCustomerAdmin,
    organizationAdmin,
    isFacilityAdmin,
    isUnitAdmin,
  } = loggedUser;

  return isCaregilitySystemAdmin
    || isUserAdmin
    || isCustomerAdmin
    || organizationAdmin
    || isFacilityAdmin
    || !isCaregilityAdmin
    && !isUnitAdmin;
};

/**
 * redirectForuserAdminRole
 * @param {Object} loggedUser Logged User Information
 * @returns {boolean} true|false
 */
export const redirectForUserAdminRole = (loggedUser) => {
  const { isUserAdmin } = loggedUser;

  return isUserAdmin;
};

/**
 * Depending on the Role Shows Delete Action
 * @param {{}} loggedUser Logged User Information
 * @param {number} page One of APP_PAGES_CONTEXT
 * @returns {boolean} is logged in user allowed to view the delete action
 */
export const shouldShowDeleteAction = (loggedUser, page) => {
  const {
    isCaregilitySystemAdmin,
    isOrganizationAdmin,
    isCustomerAdmin,
    isFacilityAdmin,
    isUserAdmin,
    isTechnicianAdmin,
  } = loggedUser;

  const deviceUnitsPermissions = isCaregilitySystemAdmin
    || isOrganizationAdmin
    || isCustomerAdmin
    || isFacilityAdmin
    || isTechnicianAdmin;

  switch (page) {
    case APP_PAGES_CONTEXT.organizations:
      return isCaregilitySystemAdmin;
    case APP_PAGES_CONTEXT.customers:
      return isCaregilitySystemAdmin || isOrganizationAdmin;
    case APP_PAGES_CONTEXT.facilities:
      return isCaregilitySystemAdmin || isOrganizationAdmin || isCustomerAdmin;
    case APP_PAGES_CONTEXT.units:
      return isCaregilitySystemAdmin || isOrganizationAdmin || isCustomerAdmin || isFacilityAdmin;
    case APP_PAGES_CONTEXT.nonUheUnits:
      return deviceUnitsPermissions;
    case APP_PAGES_CONTEXT.uheUnits:
      return deviceUnitsPermissions;
    case APP_PAGES_CONTEXT.mobilePatients:
      return isCaregilitySystemAdmin
        || isOrganizationAdmin
        || isCustomerAdmin
        || isTechnicianAdmin;
    case APP_PAGES_CONTEXT.users:
      return isCaregilitySystemAdmin || isUserAdmin;
    case APP_PAGES_CONTEXT.programs:
      return isCaregilitySystemAdmin || isOrganizationAdmin;
    default:
      return false;
  }
};

/**
 * Depending on the Role Shows User Icon Action
 * @param {{}} loggedUser Logged User Information
 * @param {number} page One of APP_PAGES_CONTEXT
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldShowImpersonateAction = (loggedUser, page) => {
  const { isCaregilitySystemAdmin, isUserAdmin } = loggedUser;

  switch (page) {
    case APP_PAGES_CONTEXT.users:
      return isCaregilitySystemAdmin || isUserAdmin;
    default:
      return false;
  }
};

/**
 * Depending on the Role Shows Change Password Action
 * @param {{}} loggedUser Logged User Information
 * @param {number} page One of APP_PAGES_CONTEXT
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldShowChangePassword = (loggedUser, page) => {
  const {
    isCaregilitySystemAdmin, isOrganizationAdmin, isCustomerAdmin, isUserAdmin, isTechnicianAdmin,
  } = loggedUser;

  switch (page) {
    case APP_PAGES_CONTEXT.users:
      return isCaregilitySystemAdmin || isUserAdmin;
    case APP_PAGES_CONTEXT.mobilePatients:
      return isCaregilitySystemAdmin
        || isOrganizationAdmin
        || isCustomerAdmin
        || isTechnicianAdmin;
    default:
      return false;
  }
};

/**
 * Depending on the Role Shows Reset Password Action
 * @param {{}} loggedUser Logged User Information
 * @param {number} page One of APP_PAGES_CONTEXT
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldShowResetPassword = (loggedUser, page) => {
  const {
    isCaregilitySystemAdmin, isOrganizationAdmin, isCustomerAdmin, isUserAdmin, isTechnicianAdmin,
  } = loggedUser;

  switch (page) {
    case APP_PAGES_CONTEXT.users:
      return isCaregilitySystemAdmin || isUserAdmin;
    case APP_PAGES_CONTEXT.mobilePatients:
      return isCaregilitySystemAdmin
        || isOrganizationAdmin
        || isCustomerAdmin
        || isTechnicianAdmin;
    default:
      return false;
  }
};

/**
 * Redirects to Support Page if User is Only userAdmin
 * @param {Object} loggedUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldRedirectUserAdmin = (loggedUser) => {
  const {
    isUserAdmin,
    isOrganizationAdmin,
    isCaregilityAdmin,
    isCaregilitySystemAdmin,
    isChannelAdmin,
    isCustomerAdmin,
    isFacilityAdmin,
    isSuperAdmin,
    isTechnician,
    isTechnicianAdmin,
    isUnitAdmin,
  } = loggedUser;

  return isUserAdmin
    && !isOrganizationAdmin
    && !isCustomerAdmin
    && !isCaregilityAdmin
    && !isCaregilitySystemAdmin
    && !isChannelAdmin
    && !isFacilityAdmin
    && !isSuperAdmin
    && !isTechnician
    && !isTechnicianAdmin
    && !isUnitAdmin;
};

/**
 * Check for any role
 * @param {Object} loggedUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const hasAnyRole = (loggedUser) => (loggedUser?.roles?.length);

/**
 * hideSystemMessages
 * @param {Object} loggedUser Logged User Information
 * @returns {boolean} true|false
 */
export const hideSystemMessages = (loggedUser) => {
  const {
    isUserAdmin,
  } = loggedUser;

  return !(isUserAdmin);
};

/**
 * Depending on the Role Invokes saveUser Action
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldSaveUserDetails = (loggedUser) => {
  const { isCaregilitySystemAdmin, isUserAdmin } = loggedUser;

  return (isCaregilitySystemAdmin || isUserAdmin);
};

/**
 * Depending on the Role  allows saving user programs or not
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldSaveUserPrograms = (loggedUser) => {
  const { isOrganizationAdmin } = loggedUser;
  return isOrganizationAdmin;
};

/**
 * Based on Roles Invokes Different Array Methods
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const checkOrganizationOptions = (loggedUser) => {
  const { isCaregilitySystemAdmin, isUserAdmin } = loggedUser;

  return !isCaregilitySystemAdmin && isUserAdmin;
};

/**
 * Based on the User's Roles Shows Notifications Menu Item
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldRenderNotificationsLink = (loggedUser) => {
  const { isCaregilitySystemAdmin, isCaregilityAdmin, isOrganizationAdmin } = loggedUser;

  return isCaregilitySystemAdmin || isCaregilityAdmin || isOrganizationAdmin;
};

/**
 * Based on the User's Roles Shows Monitoring Menu Item
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldRenderMonitoringLink = (loggedUser) => {
  const {
    isCaregilitySystemAdmin,
    isCaregilityAdmin,
    isCustomerAdmin,
    isOrganizationAdmin,
    isUnitAdmin,
    isFacilityAdmin,
    isTechnicianAdmin,
  } = loggedUser;

  return isCaregilitySystemAdmin || isCaregilityAdmin || isCustomerAdmin || isOrganizationAdmin
    || isUnitAdmin || isFacilityAdmin || isTechnicianAdmin;
};

/**
 * Based on the User's Roles Shows Room/Bed Menu Item
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldRenderRoomBedLink = (loggedUser) => {
  const {
    isCaregilitySystemAdmin,
    isCaregilityAdmin,
    isCustomerAdmin,
    isOrganizationAdmin,
    isUnitAdmin,
    isFacilityAdmin,
    isTechnicianAdmin,
  } = loggedUser;

  return isCaregilitySystemAdmin || isCaregilityAdmin || isCustomerAdmin || isOrganizationAdmin
    || isUnitAdmin || isFacilityAdmin || isTechnicianAdmin;
};

/**
 * Based on the User's Roles Shows Unit/Practice Menu Item
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldRenderUnitLink = (loggedUser) => {
  const {
    isCaregilitySystemAdmin,
    isCaregilityAdmin,
    isCustomerAdmin,
    isOrganizationAdmin,
    isUnitAdmin,
    isFacilityAdmin,
    isTechnicianAdmin,
  } = loggedUser;

  return isCaregilitySystemAdmin || isCaregilityAdmin || isCustomerAdmin || isOrganizationAdmin
    || isUnitAdmin || isFacilityAdmin || isTechnicianAdmin;
};

/**
 * Based on the User's Roles Shows Teamviewer Credentials
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldRenderTeamviewerCredentials = (loggedUser) => {
  const { isCaregilitySystemAdmin } = loggedUser;

  return isCaregilitySystemAdmin;
};

/**
 * Based on the User's Roles Shows Facility Menu Item
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldRenderFacilityLink = (loggedUser) => {
  const {
    isCaregilitySystemAdmin,
    isCaregilityAdmin,
    isCustomerAdmin,
    isOrganizationAdmin,
    isUnitAdmin,
    isFacilityAdmin,
    isTechnicianAdmin,
  } = loggedUser;

  return isCaregilitySystemAdmin || isCaregilityAdmin || isCustomerAdmin || isOrganizationAdmin
    || isUnitAdmin || isFacilityAdmin || isTechnicianAdmin;
};

/**
 * Based on the User's Roles Shows Customer Menu Item
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldRenderCustomerLink = (loggedUser) => {
  const {
    isCaregilitySystemAdmin,
    isCaregilityAdmin,
    isCustomerAdmin,
    isOrganizationAdmin,
    isUnitAdmin,
    isFacilityAdmin,
    isTechnicianAdmin,
  } = loggedUser;

  return isCaregilitySystemAdmin || isCaregilityAdmin || isCustomerAdmin || isOrganizationAdmin
    || isUnitAdmin || isFacilityAdmin || isTechnicianAdmin;
};

/**
 * Based on the User's Roles Shows Organization Menu Item
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldRenderOrganizationLink = (loggedUser) => {
  const {
    isCaregilitySystemAdmin,
    isCaregilityAdmin,
    isCustomerAdmin,
    isOrganizationAdmin,
    isUnitAdmin,
    isFacilityAdmin,
    isTechnicianAdmin,
  } = loggedUser;

  return isCaregilitySystemAdmin || isCaregilityAdmin || isCustomerAdmin || isOrganizationAdmin
    || isUnitAdmin || isFacilityAdmin || isTechnicianAdmin;
};

/**
 * Based on the User's Roles Shows Users Menu Item
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldRenderUsersLink = (loggedUser) => {
  const {
    isCaregilitySystemAdmin,
    isCaregilityAdmin,
    isCustomerAdmin,
    isOrganizationAdmin,
    isUnitAdmin,
    isFacilityAdmin,
    isUserAdmin,
  } = loggedUser;

  return isCaregilitySystemAdmin || isUserAdmin || isCaregilityAdmin
    || isCustomerAdmin || isFacilityAdmin || isUnitAdmin || isOrganizationAdmin;
};

/**
 * Based on the User's Roles Shows SSO Providers Menu Item
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldRenderSSOProvidersLink = (loggedUser) => {
  const { isCaregilitySystemAdmin } = loggedUser;

  return isCaregilitySystemAdmin;
};

/**
 * Based on the User's Roles Shows Reporting Menu Item
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldRenderReportingLink = (loggedUser) => {
  const { isCaregilitySystemAdmin } = loggedUser;

  return isCaregilitySystemAdmin;
};

/**
 * Based on the User's Roles Shows System Menu Item
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldRenderSystemLink = (loggedUser) => {
  const { isCaregilitySystemAdmin } = loggedUser;

  return isCaregilitySystemAdmin;
};

/**
 * Based on the User's Roles Shows IObserver Menu Item
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldRenderIObserverLink = (loggedUser) => {
  const { isCaregilitySystemAdmin } = loggedUser;

  return isCaregilitySystemAdmin;
};

/**
 * Based on the User's Roles Shows Technician Menu Item
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldRenderTechnicianLink = (loggedUser) => {
  const {
    isCaregilitySystemAdmin, isTechnicianAdmin, isTechnician, techAuthorized,
  } = loggedUser;

  return isCaregilitySystemAdmin || ((isTechnicianAdmin || isTechnician) && techAuthorized);
};

/**
 * Based on the User's Roles Shows Developer Menu Item
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldRenderDeveloperLink = (loggedUser) => {
  const { isCaregilitySystemAdmin, isOrganizationAdmin, isCustomerAdmin } = loggedUser;

  return isCaregilitySystemAdmin || isOrganizationAdmin || isCustomerAdmin;
};

/**
 * Based on the User's Roles Shows Settings Menu Item
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldRenderSettingsLink = (loggedUser) => {
  const { isCaregilitySystemAdmin } = loggedUser;

  return isCaregilitySystemAdmin;
};

/**
 * Disables element according to role permission
 * @param {object} loggedUser logged user information
 * @returns {boolean} true/false
 */
export const shouldShowSystemWideNotificationEmailSection = () => {
  const { isCaregilitySystemAdmin } = getLoggedUser();
  return isCaregilitySystemAdmin;
};

/**
 * Support sections to be seen only by Caregility System Admin
 * @return {Requireable<boolean>} isCaregilitySystemAdmin
 */
export const shouldBeAbleToViewSupportSectionsSection = () => getLoggedUser().isCaregilitySystemAdmin;

/**
 * Save and Delete button next to each file to be seen only by Caregility System Admin
 * @return {Requireable<boolean>} isCaregilitySystemAdmin
 */
export const shouldBeAbleToEditOnSupportPage = () => getLoggedUser().isCaregilitySystemAdmin;

/**
 * Shows Actions Column on Notifications Page Depending on the User's Role
 * @returns {boolean} isCaregilityAdmin
 */
export const shouldShowNotficationsActions = () => {
  const { isCaregilitySystemAdmin, isCaregilityAdmin } = getLoggedUser();

  return !isCaregilityAdmin || isCaregilitySystemAdmin;
};

/**
 * Able to Save Global Notifications Based on User's Role
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldSaveGlobalNotifications = () => {
  const { isCaregilitySystemAdmin, isCaregilityAdmin, isOrganizationAdmin } = getLoggedUser();

  return (isOrganizationAdmin || isCaregilityAdmin) && !isCaregilitySystemAdmin;
};

/**
 * Able to Edit Global Notifications Input Fields Based on User's Role
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldEditGlobalNotificationsInpunts = () => !getLoggedUser().isCaregilitySystemAdmin;

/**
 * Renders UheUnits Table Based on User's Role
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldRenderUheUnitsTable = () => {
  const {
    isCaregilitySystemAdmin,
    isTechnicianAdmin,
    isOrganizationAdmin,
    isCustomerAdmin,
    isFacilityAdmin,
  } = getLoggedUser();

  return isCaregilitySystemAdmin
    || isCustomerAdmin
    || isOrganizationAdmin
    || isFacilityAdmin
    || isTechnicianAdmin;
};

/**
 * Depending on the Role Shows Edit Header
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldShowEditHeader = () => {
  const {
    isCaregilitySystemAdmin,
    isUserAdmin,
    isCustomerAdmin,
    isOrganizationAdmin,
    isFacilityAdmin,
    isUnitAdmin,
    isCaregilityAdmin,
    isTechnicianAdmin,
  } = getLoggedUser();

  return (isCaregilitySystemAdmin
    || isUserAdmin
    || isOrganizationAdmin
    || isCustomerAdmin
    || isFacilityAdmin
    || isUnitAdmin
    || isCaregilityAdmin
    || isTechnicianAdmin
  );
};

/**
 * Shows Save/Cancel button if user has privilliges.
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldShowButton = (loggedUser) => {
  const isOrganizationsPage = window.location.href.includes('configuration/organizations/');
  const isCustomersPage = window.location.href.includes('configuration/customers/');
  const isFacilitiesPage = window.location.href.includes('configuration/facilities/');
  const isUnitsPage = window.location.href.includes('configuration/units/');
  const isUheUnitsPage = window.location.href.includes('configuration/beds-carts/uhe-units/');
  const isNonUheUnitsPage = window.location.href.includes('configuration/beds-carts/non-uhe-units/');
  const isByodPage = window.location.href.includes('configuration/mobile-patients/');
  const isUsersPage = window.location.href.includes('configuration/users/');
  const isProgramPage = window.location.href.includes('configuration/program');
  const isTwilioPage = window.location.href.includes('system/settings/system/twilio');
  const isNotificationsPage = window.location.href.includes('system/notifications/');
  const isBulkActionsPage = window.location.href.includes('bulk-actions');

  const {
    isCaregilitySystemAdmin,
    isUserAdmin,
    isFacilityAdmin,
    isOrganizationAdmin,
    isCustomerAdmin,
    isTechnicianAdmin,
  } = loggedUser;
  if (isBulkActionsPage) {
    return false;
  }
  if (isOrganizationsPage) {
    return isCaregilitySystemAdmin;
  }
  if (isUsersPage) {
    return isCaregilitySystemAdmin || isUserAdmin || isOrganizationAdmin || isCustomerAdmin || isFacilityAdmin;
  }
  if (isCustomersPage || isNotificationsPage) {
    return isCaregilitySystemAdmin || isOrganizationAdmin;
  }
  if (isFacilitiesPage) {
    return isCaregilitySystemAdmin || isOrganizationAdmin || isCustomerAdmin;
  }
  if (isUnitsPage) {
    return isCaregilitySystemAdmin || isOrganizationAdmin || isCustomerAdmin || isFacilityAdmin;
  }
  if (isByodPage) {
    return isCaregilitySystemAdmin || isOrganizationAdmin || isCustomerAdmin || isTechnicianAdmin;
  }
  if (isUheUnitsPage || isNonUheUnitsPage) {
    return isCaregilitySystemAdmin || isOrganizationAdmin || isCustomerAdmin || isFacilityAdmin || isTechnicianAdmin;
  }
  if (isProgramPage) {
    return isOrganizationAdmin || isCaregilitySystemAdmin;
  }
  if (isTwilioPage) {
    return isCaregilitySystemAdmin;
  }
};

/**
 * Depending on the Role Shows Cancel button
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldShowCancelButton = (loggedUser) => {
  const isByodPage = window.location.href.includes('mobile-patients/edit/');
  const {
    isCaregilityAdmin,
    isUnitAdmin,
    isFacilityAdmin,
  } = loggedUser;

  return (isCaregilityAdmin
    || isUnitAdmin
    || (isFacilityAdmin && isByodPage)
  );
};

/**
 * Depending on the role removes only save button
 * @param {object} loggedUser Logged User Information
 * @param {object} currentUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldShowSaveButton = (loggedUser, currentUser) => {
  const {
    isCaregilitySystemAdmin,
    isOrganizationAdmin,
    isCustomerAdmin,
    isUserAdmin,
    isFacilityAdmin,
  } = loggedUser;
  const isFacilityEditPage = window.location.href.includes('facilities/edit');
  const isProgramsEditPage = window.location.href.includes('/programs/edit');
  const isOrganizationEditPage = window.location.href.includes('/organizations/edit');
  const isCustomerEditPage = window.location.href.includes('/customers/edit');
  const isCustomerBulkActionsPage = window.location.href.includes('bulk-actions');
  const isNotSameUser = loggedUser?.username !== currentUser?.email;
  return (!isCustomerBulkActionsPage
    && (
      isCaregilitySystemAdmin
    || (isCustomerAdmin && isFacilityEditPage)
    || (isOrganizationAdmin && isNotSameUser && !isOrganizationEditPage)
    || (isUserAdmin && isNotSameUser && !isProgramsEditPage
      && !isOrganizationEditPage && !isFacilityEditPage)
    || (isCustomerAdmin && isNotSameUser && !isProgramsEditPage
      && !isOrganizationEditPage && !isCustomerEditPage)
    || (isFacilityAdmin && isNotSameUser && !isProgramsEditPage
      && !isOrganizationEditPage && !isCustomerEditPage && !isFacilityEditPage)
    ));
};

/**
 * Depending on the Role Shows Edit header on BYOD button
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldShowEditHeaderByod = () => {
  const {
    isCaregilitySystemAdmin,
    isOrganizationAdmin,
    isCustomerAdmin,
    isTechnicianAdmin,
    isUnitAdmin,
    isFacilityAdmin,
    isCaregilityAdmin,
  } = getLoggedUser();

  return (isCaregilitySystemAdmin
    || isOrganizationAdmin
    || isCustomerAdmin
    || isTechnicianAdmin
    || isUnitAdmin
    || isFacilityAdmin
    || isCaregilityAdmin
  );
};

/**
 * Based on the User's Roles Shows Export CSV Button
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldShowExportButton = () => {
  const {
    isCaregilitySystemAdmin,
    isCaregilityAdmin,
    isOrganizationAdmin,
    isCustomerAdmin,
    isFacilityAdmin,
  } = getLoggedUser();

  return isCaregilitySystemAdmin
    || isCaregilityAdmin
    || isOrganizationAdmin
    || isCustomerAdmin
    || isFacilityAdmin;
};

/**
 * Based on the User's Roles Enables isTechAuthorized Checkbox
 * @param {{}} loggedUser Principal info for the logged user
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldEditTechAuthorized = (loggedUser) => {
  const { isCaregilitySystemAdmin } = loggedUser;

  return isCaregilitySystemAdmin;
};

/**
 * Whether to show edit action on actions cell
 * @param {{}} loggedUser Logged User Information
 * @param {number} page One of APP_PAGES_CONTEXT
 * @returns {boolean} Can edit if is on any page other than Customer Bulk Actions
 */
export const shouldShowEditAction = (loggedUser, page) => {
  const {
    isCaregilitySystemAdmin,
    isUserAdmin,
    isOrganizationAdmin,
    isCustomerAdmin,
    isFacilityAdmin,
    isTechnicianAdmin,
    isCaregilityAdmin,
    isUnitAdmin,
  } = loggedUser;

  const deviceUnitsPermissions = isCaregilitySystemAdmin
    || isOrganizationAdmin
    || isCustomerAdmin
    || isFacilityAdmin
    || isTechnicianAdmin
    || isCaregilityAdmin
    || isUnitAdmin;

  switch (page) {
    case APP_PAGES_CONTEXT.users:
      return isCaregilitySystemAdmin
        || isUserAdmin
        || isCaregilityAdmin
        || isCustomerAdmin
        || isOrganizationAdmin
        || isFacilityAdmin
        || isUnitAdmin;
    case APP_PAGES_CONTEXT.organizations:
      return isCaregilitySystemAdmin;
    case APP_PAGES_CONTEXT.customers:
      return isCaregilitySystemAdmin || isOrganizationAdmin;
    case APP_PAGES_CONTEXT.facilities:
      return isCaregilitySystemAdmin || isOrganizationAdmin || isCustomerAdmin;
    case APP_PAGES_CONTEXT.units:
      return isCaregilitySystemAdmin || isOrganizationAdmin || isCustomerAdmin || isFacilityAdmin;
    case APP_PAGES_CONTEXT.uheUnits:
      return deviceUnitsPermissions;
    case APP_PAGES_CONTEXT.nonUheUnits:
      return deviceUnitsPermissions;
    case APP_PAGES_CONTEXT.notifications:
      return isCaregilitySystemAdmin || isOrganizationAdmin;
    case APP_PAGES_CONTEXT.mobilePatients:
      return isCaregilitySystemAdmin
        || isOrganizationAdmin
        || isCustomerAdmin
        || isFacilityAdmin
        || isTechnicianAdmin
        || isCaregilityAdmin
        || isUnitAdmin;
    case APP_PAGES_CONTEXT.programs:
      return isCaregilitySystemAdmin
        || isOrganizationAdmin;
    default:
      return false;
  }
};

/**
 * Whether to show bulk actions icon on actions cell
 * @param {{}} loggedUser Logged User Information
 * @param {number} page One of APP_PAGES_CONTEXT
 * @returns {boolean} Should be shown only on customers list page
 */
export const shouldShowBulkActionsAction = (loggedUser, page) => {
  const { isCaregilitySystemAdmin, isTechnicianAdmin } = loggedUser;

  switch (page) {
    case APP_PAGES_CONTEXT.customers:
      return isCaregilitySystemAdmin || isTechnicianAdmin;
    default:
      return false;
  }
};

/**
 * Based on the User's Roles Show Render Headline
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldFacilityBeEditable = () => {
  const {
    isCaregilitySystemAdmin, isOrganizationAdmin, isCustomerAdmin, isCaregilityAdmin,
    isUnitAdmin, isFacilityAdmin, isTechnicianAdmin,
  } = getLoggedUser();

  return isCaregilitySystemAdmin || isOrganizationAdmin || isCustomerAdmin
    || isCaregilityAdmin || isUnitAdmin || isFacilityAdmin || isTechnicianAdmin;
};

/**
 * Based on the User's Roles Show and edit password in IP Configuration
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldShowEditPasswordIpConfiguration = () => {
  const { isCaregilitySystemAdmin, isTechnicianAdmin } = getLoggedUser();

  return isCaregilitySystemAdmin || isTechnicianAdmin;
};

/**
 * Based on the User's Roles and if device is configured edit IP Configuration
 * @param {object} loggedUser logged user
 * @param {boolean} shouldNotEditIp boolean
 * @returns {boolean} true|false
 */
export const shouldNotEditIpConfiguration = (loggedUser, shouldNotEditIp) => {
  const {
    isCaregilitySystemAdmin,
    isTechnicianAdmin,
    isOrganizationAdmin,
    isFacilityAdmin,
    isCustomerAdmin,
  } = loggedUser;
  if (shouldNotEditIp === true) {
    return true;
  }
  return !(isCaregilitySystemAdmin
    || isTechnicianAdmin
    || isOrganizationAdmin
    || isFacilityAdmin
    || isCustomerAdmin);
};

/**
 * Based on the User's Roles Show and edit password in IP Configuration
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldDoHardReboot = () => {
  const { isCaregilitySystemAdmin, isTechnicianAdmin } = getLoggedUser();

  return isCaregilitySystemAdmin || isTechnicianAdmin;
};

/**
 * Based on the User's Roles disable customer bulk action radio button
 * @param {{}} loggedUser Principal info for the logged user
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldshouldDisableActionRadio = (loggedUser) => {
  const { isCaregilitySystemAdmin, isTechnicianAdmin } = loggedUser;

  return isCaregilitySystemAdmin || isTechnicianAdmin;
};

/**
 * Checks whether user is viewing own profile.
 * A user cannot edit their own profile (e.g. name, email)
 * including their own roles and location permissions (IT Compliance).
 * The exception is Caregility Super Admin only.
 * @param {{}} loggedUser Principal info for the logged user
 * @param {{}} currentUser Object returned from b/e (from the props, not from the state)
 * @param {boolean} isNew Indicates whether currently on editing or creating user
 * @returns {boolean} User Role
 */
export const shouldBeAbleToEditUserDetails = (loggedUser, currentUser, isNew) => {
  const { isCaregilitySystemAdmin, isUserAdmin } = loggedUser;
  return isCaregilitySystemAdmin
    || (isUserAdmin && (isNew || currentUser?.email !== loggedUser?.username));
};

/**
 * Checks if user can edit roles and permissions
 * @param {{}} loggedUser Principal info of logged user
 * @param {{}} currentUser Object returned from b/e (from the props, not from the state)
 * @returns {boolean} Whether user has readonly or readwrite permissions
 */
export const shouldBeAbleToEditRolesAndPermissions = (loggedUser, currentUser) => {
  const {
    isCaregilitySystemAdmin, isOrganizationAdmin, isUserAdmin, isCustomerAdmin, isFacilityAdmin,
  } = loggedUser;
  const isNotSameUser = loggedUser.username !== currentUser.email;

  return isCaregilitySystemAdmin
    || (isOrganizationAdmin && isNotSameUser)
    || (isUserAdmin && isNotSameUser)
    || (isCustomerAdmin && isNotSameUser)
    || (isFacilityAdmin && isNotSameUser);
};

/**
 * Checks if user can edit programs roles and permissions
 * @param {Object} loggedUser Principal info of logged user
 * @param {Object} currentUser Object returned from b/e (from the props, not from the state)
 * @returns {boolean} Whether user has readonly or readwrite permissions
 */
export const shouldBeAbleToEditProgramRolesAndPermissions = (loggedUser, currentUser) => {
  const {
    isCaregilitySystemAdmin, isOrganizationAdmin, isUserAdmin,
  } = loggedUser;
  const isNotSameUser = loggedUser.username !== currentUser.email;

  return isCaregilitySystemAdmin
    || (isOrganizationAdmin && isNotSameUser)
    || (isUserAdmin && isNotSameUser);
};

/**
 * Whether to show the export icon on list pages
 * @param {{}} loggedUser Principal info of logged user
 * @param {number} page One of APP_PAGES_CONTEXT
 * @return {boolean} true/false
 */
export const shouldBeAbleToExport = (loggedUser, page) => {
  const {
    isCaregilitySystemAdmin, isUserAdmin, isCaregilityAdmin,
    isOrganizationAdmin, isCustomerAdmin, isFacilityAdmin,
  } = loggedUser;
  switch (page) {
    case APP_PAGES_CONTEXT.users:
      return isCaregilitySystemAdmin || isUserAdmin || isCaregilityAdmin;
    case APP_PAGES_CONTEXT.mobilePatients:
      return isCaregilitySystemAdmin || isCaregilityAdmin
        || isOrganizationAdmin || isCustomerAdmin || isFacilityAdmin;
    case APP_PAGES_CONTEXT.programs:
      return isCaregilitySystemAdmin || isOrganizationAdmin;
    default:
      return false;
  }
};

/**
 * Whether to show the import icon on list pages
 * @param {{}} loggedUser Principal info of logged user
 * @param {number} page One of APP_PAGES_CONTEXT
 * @return {boolean} true/false
 */
export const shouldBeAbleToImport = (loggedUser, page) => {
  const {
    isCaregilitySystemAdmin, isOrganizationAdmin, isCustomerAdmin, isTechnicianAdmin,
  } = loggedUser;

  switch (page) {
    case APP_PAGES_CONTEXT.users:
      return isCaregilitySystemAdmin;
    case APP_PAGES_CONTEXT.mobilePatients:
      return isCaregilitySystemAdmin || isOrganizationAdmin || isCustomerAdmin || isTechnicianAdmin;
    case APP_PAGES_CONTEXT.programs:
      return isCaregilitySystemAdmin;
    case APP_PAGES_CONTEXT.nonUheUnits:
      return isCaregilitySystemAdmin;
    default:
      return false;
  }
};

/**
 * Whether to show the add icon on list pages
 * @param {{}} loggedUser Principal info of logged user
 * @param {number} page One of APP_PAGES_CONTEXT
 * @return {boolean} true/false
 */
export const shouldBeAbleToAdd = (loggedUser, page) => {
  const {
    isCaregilitySystemAdmin, isUserAdmin, isOrganizationAdmin, isCustomerAdmin, isTechnicianAdmin,
  } = loggedUser;

  switch (page) {
    case APP_PAGES_CONTEXT.users:
      return isCaregilitySystemAdmin || isUserAdmin;
    case APP_PAGES_CONTEXT.mobilePatients:
      return isCaregilitySystemAdmin || isOrganizationAdmin || isCustomerAdmin || isTechnicianAdmin;
    case APP_PAGES_CONTEXT.programs:
      return isCaregilitySystemAdmin || isOrganizationAdmin;
    default:
      return false;
  }
};

/**
 * Based on the User You Editing Shows The Tech Authorized Checkbox
 * @param {object} user User's Data
 * @param {boolean} isNew is it New Page
 * @returns {boolean} User's Role
 */
export const shouldShowTechAuthorized = (user, isNew) => {
  const { is_technician, is_technician_admin, has_tech_program } = user;

  return !isNew && (!!is_technician || !!is_technician_admin || has_tech_program);
};

/**
 * Based on the User's Roles Validate Global Notification Emails
 * @param {object} loggedUser Logged User Data
 * @returns {boolean} User's Role
 */
export const shouldValidateGlobalNotifications = (loggedUser) => loggedUser.isCaregilitySystemAdmin;
/**
 * Whether to log out user
 * @param {object} loggedUser current logged user
 * @returns {boolean} Should be logedout if not admin or technician
 */
export const isAuthorizedTechnician = (loggedUser) => loggedUser?.roles?.some((role) => role === 'TECH') && loggedUser?.techAuthorized;
/**
 * Check if it Technician authorization and Technician page
 * @param {object} loggedUser current logged user
 * @returns {boolean} Check Technician authorization and Technician page
 */
export const isTechnicianPage = (loggedUser) => window.location.href.includes('/technician') && loggedUser.techAuthorized;
/**
 * Whether to log out user
 * @param {object} loggedUser current logged user
 * @returns {boolean} Should be logedout if not admin
 */
export const isNotAdmin = (loggedUser) => loggedUser
  && loggedUser.roles
  && !loggedUser?.roles?.some((role) => role.toLowerCase().endsWith('_admin'));

/**
 * Based on the User's Role Hides Specific Menu Item
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} User's Role
 */
export const isTechnicianAccess = (loggedUser) => (loggedUser?.roles
  && loggedUser.roles.some((role) => role.toLowerCase().endsWith('_admin')))
  || !loggedUser.isTechnician;

/**
 * Check if user is Caregility System Admin
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} User's Role
 */
export const isCaregilitySystemAdmin = (loggedUser) => {
  const { isCaregilitySystemAdmin } = loggedUser;

  return isCaregilitySystemAdmin;
};

/**
 * Check if user is Caregility Admin
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} User's Role
 */
export const isCaregilityAdmin = (loggedUser) => {
  const { isCaregilityAdmin } = loggedUser;

  return isCaregilityAdmin;
};

/**
 * Based on the User's Roles Shows Programs Menu Item
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} is Specific User Role true|false
 */
export const shouldRenderProgramsLink = (loggedUser) => {
  const {
    isCaregilitySystemAdmin,
    isCaregilityAdmin,
    isCustomerAdmin,
    isOrganizationAdmin,
    isUnitAdmin,
    isFacilityAdmin,
    isTechnicianAdmin,
    isUserAdmin,
  } = loggedUser;

  return isCaregilitySystemAdmin
    || isCaregilityAdmin
    || isCustomerAdmin
    || isFacilityAdmin
    || isUnitAdmin
    || isOrganizationAdmin
    || isTechnicianAdmin
    || isUserAdmin;
};

/**
 * Restricts input fields(APD) for the relevant roles
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} User's Role
 */
export const shouldDisableMobileInputFields = (loggedUser) => {
  const {
    isCaregilitySystemAdmin,
    isOrganizationAdmin,
    isCustomerAdmin,
    isTechnicianAdmin,
  } = loggedUser;

  return !(isCaregilitySystemAdmin || isOrganizationAdmin || isCustomerAdmin || isTechnicianAdmin);
};

/**
 * Restricts Organisation input fields for the relevant roles
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} User's Role
 */
export const shouldDisableOrganisationInputFields = (loggedUser) => {
  const { isCaregilitySystemAdmin } = loggedUser;

  return !isCaregilitySystemAdmin;
};

/**
 * Restricts Customer input fields for the relevant roles.
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} User's Role
 */
export const shouldDisableCustomerInput = (loggedUser) => {
  const { isCaregilitySystemAdmin, isOrganizationAdmin } = loggedUser;

  return !(isCaregilitySystemAdmin || isOrganizationAdmin);
};

/**
 * Can do hardreboot
 * @param {Object} loggedUser Logged user information
 * @returns {Boolean} User's Role
 */
export const canDoAction = (loggedUser) => {
  const { isCaregilitySystemAdmin, isTechnicianAdmin } = loggedUser;
  return !(isCaregilitySystemAdmin || isTechnicianAdmin);
};

/**
 * Restricts Mobile Patients MDM Token for the relevant role/s.
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} User's Role
 */
export const shouldHideMDMToken = (loggedUser) => {
  const {
    isCaregilitySystemAdmin,
    isCustomerAdmin,
    isOrganizationAdmin,
    isFacilityAdmin,
    isTechnicianAdmin,
  } = loggedUser;
  return !(
    isCaregilitySystemAdmin
    || isCustomerAdmin
    || isOrganizationAdmin
    || isFacilityAdmin
    || isTechnicianAdmin);
};

/**
 * Restricts Mobile Patients MDM Token for the relevant role/s.
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} User's Role
 */
export const shouldDisableMDMToken = (loggedUser) => {
  const { isCaregilitySystemAdmin, isUserAdmin } = loggedUser;

  if (isCaregilitySystemAdmin) {
    return false;
  }

  return isUserAdmin;
};

/**
 * shouldShowCiscoDeviceCard
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} User's Role
 */
export const shouldShowCiscoDeviceCard = (loggedUser) => {
  const { isCaregilitySystemAdmin } = loggedUser;
  if (isCaregilitySystemAdmin) {
    return true;
  }
  return false;
};

/**
 * canEditPrograms
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} User's Role
 */
export const canEditPrograms = (loggedUser) => {
  const { isSuperAdmin, isOrganizationAdmin, isCaregilitySystemAdmin } = loggedUser;
  if (isSuperAdmin || isOrganizationAdmin || isCaregilitySystemAdmin) {
    return true;
  }
  return false;
};

/**
 * is only user admin
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} User's Role
 */
export const isOnlyUserAdmin = (loggedUser) => {
  const {
    isCaregilitySystemAdmin,
    isCustomerAdmin,
    isOrganizationAdmin,
    isFacilityAdmin,
    isUnitAdmin,
    isUserAdmin,
  } = loggedUser;
  if (isUserAdmin
    && !isCaregilitySystemAdmin
    && !isOrganizationAdmin
    && !isCustomerAdmin
    && !isFacilityAdmin
    && !isUnitAdmin) {
    return true;
  }
  return false;
};

/**
 * shouldRenderAdvancedDevMenuItem
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} User's Role
 */
export const shouldRenderAdvancedDevMenuItem = (loggedUser) => {
  const { features } = loggedUser;
  if (features?.advanced_dev) {
    return true;
  }
  return false;
};

/**
 * should show EPIC password
 * @param {object} loggedUser Logged User Information
 * @returns {boolean} User's Role
 */
export const shouldShowEpicPassword = (loggedUser) => {
  const { isCaregilitySystemAdmin, isOrganizationAdmin } = loggedUser;
  return isCaregilitySystemAdmin || isOrganizationAdmin;
};
