import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Row, Col, Card, Input, Select, Form, Cascader, Button, notification, Modal,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import EditHeader from '@components/uhe/configuration/EditHeader';
import * as mobilePatientActions from '@uhe_actions/configuration/mobilePatients/MobilePatientsActions';
import {
  mobilePatientAccessData as mobilePatientAccessDataDispatch,
  fetchMDMToken,
  clearMobileSelectedUser as clearSelectedUserDispatch,
} from '@uhe_actions/configuration/mobilePatients/MobilePatientsActions';
import { onGetOptions } from '@uhe_actions/filters/ListingsTopFilterActions';
import { setSubtitle as setSubtitleDispatch } from '@uhe_actions/SubtitleActions';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import {
  MEDICAL_ROLE_OPTIONS,
  MEDICAL_SPECIALITY_OPTIONS,
  APP_PAGES_CONTEXT,
  PASSWORD_REGEX,
  EMAIL_REGEX,
} from '@constants/UHESettings';
import ListingsTableInputFilter from '@filters/ListingsTableInputFilter';
import CustomerCell from '@components/tables/cells/CustomerCell';
import {
  goBackTo,
  cascaderSearchFilter,
  convertTimestampToDateTime,
  removeSearchIcon,
} from '@util/UheHelper';
import { withLastLocation } from 'react-router-last-location';
import AuditInfo, { AuditTypes } from '@components/uhe/configuration/AuditInfo';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import RestManager from '@util/RestManager';
import {
  shouldShowEditHeaderByod,
  shouldDisableMobileInputFields,
  shouldDisableMDMToken,
  shouldHideMDMToken,
  shouldShowChangePassword,
  shouldShowResetPassword,
} from '@util/UheRoleChecker';
import ChangePasswordModal from '@components/uhe/listings/ChangePasswordModal';
import dialog_check from '@assets/images/dialog_check.svg';
import { resetUserPassword, changeUserPassword } from '@uhe_actions/configuration/users/UsersActions';
import { MobilePatientTransformer } from './MobilePatientUtils';
import ReactiveMobilePatient from './ReactiveMobilePatient';

const { confirm } = Modal;
const { TextArea } = Input;
const { Option } = Select;

const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 17,
  },
};

/**
 * Render options
 * @param {Array<String>} data array
 * @returns {Select.Option[]} value
 */
const renderOptions = (data = []) => data
  .map((item) => (<Option key={item} value={item}>{item}</Option>));

const optionMedicalRoleItems = renderOptions(MEDICAL_ROLE_OPTIONS);
const optionMedicalSpecialtyItems = renderOptions(MEDICAL_SPECIALITY_OPTIONS);

/**
 * Renders Mobile Patient Add/Edit Page
 */
class ManageMobilePatient extends React.Component {
  formRef = React.createRef();

  static pageContext = APP_PAGES_CONTEXT.mobilePatients;

  data = [];

  /**
   * Constructor
   * @param {*} props values
   */
  constructor(props) {
    super(props);
    const { location, intl } = this.props;
    this.isNew = location.pathname.indexOf('/new') !== -1;
    this.changePasswordFormRef = React.createRef();
    this.intl = intl;

    this.state = {
      mobilePatient: new ReactiveMobilePatient(),
      data: [],
      error: null,
      focusCascader: {
        organization: false,
        customer: false,
        facility: false,
        unit: false,
        deviceTypes: false,
      },
    };

    const { subtitle, setSubtitle } = this.props;
    if (this.isNew) {
      const { onGetOrganizationOptions } = this.props;
      if (subtitle.langId !== 'configuration.mobilePatients.add') {
        setSubtitle('configuration.mobilePatients.add');
      }

      onGetOrganizationOptions();
    } else if (subtitle.langId !== 'configuration.mobilePatients.edit') {
      setSubtitle('configuration.mobilePatients.edit');
    }

    this.tableKeys = [
      'customer',
      'clinician',
      'iobserver',
      'technician',
    ];

    this.columns = this.tableKeys
      .reduce((acc, value, index) => {
        acc.push({
          title: (cellData) => (
            <ListingsTableInputFilter
              showFilter={!this.tableKeys.filter((x) => x === value)}
              cellData={cellData}
              title={`configuration.users.${value}`}
              dataKey={value}
            />
          ),
          sorter: false,
          align: index > 3 ? 'center' : 'left',
          minWidth: 200,
          dataIndex: value,
        });

        return acc;
      }, []);
  }

  /**
   * Update local state
   * @returns {void}
   */
  componentDidMount() {
    const {
      clearSelectedUser,
      match: { params: { id } },
      mobilePatientAccessData,
      actions: { fetchMobilePatient },
      mobilePatient: { id: mobilePatientId },
    } = this.props;
    if (!this.isNew) {
      mobilePatientAccessData({ id });
      fetchMobilePatient({ id });
    }
    if (this.isNew && !mobilePatientId) {
      clearSelectedUser();
    }
    if (id && id !== mobilePatientId) {
      fetchMobilePatient({ id });
    }
  }

  /**
   * Watch for empty data and if is empty set new state data
   * @param {Readonly<T>} prevProps data
   * @return {Object} data
   */
  componentDidUpdate(prevProps) {
    const { savedMobilePatientId } = this.props;
    const { clearSelectedUser } = this.props;
    const { location } = this.props;
    this.isNew = location.pathname.indexOf('/new') !== -1;

    if (prevProps.location.pathname.includes('/edit') && location.pathname.includes('/new')) {
      clearSelectedUser();
      this.setState({ mobilePatient: MobilePatientTransformer.clearInputs() });
    }

    {
      const { error, history } = this.props;
      if (prevProps.error !== error) {
        // handle system error
        switch (error.code) {
          case 404:
            if (!this.isNew) {
              history.push('/configuration/mobile-patients');
            }
            break;
          case 400:
            history.push(location.pathname);
            break;
          default:
            break;
        }
      }

      if (this.isNew && savedMobilePatientId !== prevProps.savedMobilePatientId) {
        const {
          actions: { fetchMobilePatient },
        } = this.props;
        fetchMobilePatient({ id: savedMobilePatientId });
        history.push(`/configuration/mobile-patients/edit/${savedMobilePatientId}`);
        return;
      }
    }

    {
      const {
        mobilePatient,
        onGetOrganizationOptions,
        onGetCustomerOptions,
        onGetFacilityOptions,
        onGetUnitOptions,
      } = this.props;
      if (!this.isNew
        && prevProps.mobilePatient
        && mobilePatient
        && mobilePatient.organization) {
        if (prevProps.mobilePatient !== mobilePatient) {
          const normalizedPatient = MobilePatientTransformer.fromApiToReact(mobilePatient);
          this.setState({ mobilePatient: normalizedPatient });
          const { facility, customer, organization } = normalizedPatient;
          onGetOrganizationOptions();
          onGetCustomerOptions(organization.id);
          onGetFacilityOptions(customer.id);
          onGetUnitOptions(facility.id);
        }
      }
    }

    {
      const { data } = this.props;
      if (prevProps.data.length === 0 && data.length > 0) {
        this.setState({ data });
      }
    }

    const { mobilePatient } = this.state;
    this.formRef.current.setFieldsValue({
      ...mobilePatient,
      organization: [mobilePatient.organization?.name].filter((x) => !!x),
      customer: [mobilePatient.customer?.name].filter((x) => !!x),
      facility: [mobilePatient.facility?.name].filter((x) => !!x),
      unit: [mobilePatient.unit?.name].filter((x) => !!x),
      mobileDeviceType: mobilePatient.specialty,
    });
  }

  /**
   * Renders different cell type depending on the column
   * @param  {Object} content data
   * @param  {string} key value
   * @return {ReactElement|Object} data
   */
  cellRenderer = (content, key) => {
    let cell;

    switch (key) {
      case 'customer':
        cell = content.id ? <CustomerCell content={content} /> : 'All Customers';
        break;
      default:
        cell = content;
    }
    return cell;
  }

  /**
   * Save Changes on Save Button Click for Permission Settings
   * @param {Object} event in permissions
   * @returns {void}
   */
  saveUserPermissions = (event) => {
    event.persist();
    event.preventDefault();
    const { actions, match: { params: { id } } } = this.props;
    const { data: body } = this.state;

    actions.saveMobilePermissions({ body, id });
  }

  /**
   * Save Changes on Save Button Click in the header
   * @param {Object} event in form
   * @returns {void}
   */
  saveMobilePatient = async (event) => {
    event.persist();
    event.preventDefault();

    try {
      await this.formRef.current.validateFields();

      const { actions, setSubtitle } = this.props;
      const { mobilePatient } = this.state;
      actions.saveMobilePatient({
        mobilePatient: {
          ...MobilePatientTransformer.fromReactToApi(mobilePatient, this.isNew),
        },
      });
      setSubtitle('configuration.mobilePatients.edit');
    } catch (info) {
      console.log('info:', info);
    }
  }

  /**
   * Render title, back and save buttons
   * @returns {JSX.Element|null} editheader
   */
  renderHeadLine = () => {
    const {
      editMode,
      loading,
      lastLocation,
      history,
    } = this.props;
    const titleKey = editMode
      ? 'configuration.users.addUser'
      : 'configuration.users.editUser';

    if (shouldShowEditHeaderByod()) {
      return (
        <EditHeader
          goBack={goBackTo('/configuration/mobile-patients', lastLocation?.pathname, history)}
          save={this.saveMobilePatient}
          loading={loading}
          titleKey={titleKey}
        />
      );
    }

    return null;
  }

  /**
   * Render title, back and save buttons
   * @returns {JSX.Element} data
   */
  renderSaveTable = () => {
    const { loading } = this.props;
    return (
      <EditHeader
        save={this.saveUserPermissions}
        loading={loading}
        goBack={this.goBack}
      />
    );
  }

  /**
   * Change of event
   * @param {string} key data
   * @returns {void}
   */
  onChangeOf = (key) => ({
    /**
     * Handles changes in input fields
     * @param {ChangeEvent<HTMLInputElement>} event from input
     * @returns {void}
     */
    input: (event) => {
      if (event && event.persist && event.preventDefault) {
        event.persist();
        event.preventDefault();
      }

      const { mobilePatient } = this.state;
      this.setState({
        mobilePatient: {
          ...mobilePatient,
          [key]: event.target.value,
        },
      });
    },
    /**
     * Handles changes in checkboxes
     * @param {ChangeEvent<HTMLInputElement>} event from checkbox
     * @returns {void}
     */
    checkbox: (event) => {
      const { mobilePatient } = this.state;
      this.setState({
        mobilePatient: {
          ...mobilePatient,
          [key]: event.target.checked ? 1 : 0,
        },
      });
    },
    /**
     * Returns event handler for specified key with data loader for dependency
     * @param {string|null} dependentProp If specified,
     * calls function for data load onGet_PROPERTY_Options
     * @returns {function} cascader
     */
    cascader: (dependentProp = null) =>
      /**
       * Handles changes in Cascader
       * @param {CascaderValueType} value string value
       * @param {string} currentData current object data
       * @returns {void}
       */
      // eslint-disable-next-line implicit-arrow-linebreak
      (value, [currentData]) => {
        const { mobilePatient } = this.state;
        const resetValues = {
          id: null,
          name: '',
        };

        if (currentData?.id !== undefined && !!dependentProp) {
          const { mobilePatient: { [key]: { id: prevId } } } = this.state;
          if (prevId !== currentData?.id) {
            mobilePatient[dependentProp] = { id: null, name: '' };
            if (currentData?.id && dependentProp === 'customer') {
              mobilePatient.customer = resetValues;
              mobilePatient.facility = resetValues;
              mobilePatient.unit = resetValues;
              this.setState({ mobilePatient });
            } else if (currentData?.id && dependentProp === 'facility') {
              mobilePatient.facility = resetValues;
              mobilePatient.unit = resetValues;
              this.setState({ mobilePatient });
            } else if (currentData?.id && dependentProp === 'unit') {
              mobilePatient.unit = resetValues;
              this.setState({ mobilePatient });
            }
          }
          const upperCasePropName = dependentProp.charAt(0).toUpperCase() + dependentProp.slice(1);
          const { [`onGet${upperCasePropName}Options`]: func } = this.props;
          if (func) {
            func(currentData?.id);
          }
        }
        const id = currentData ? currentData.id : '';
        const name = currentData ? currentData.name : '';
        this.setState({ mobilePatient: { ...mobilePatient, [key]: { id, name } } });
        if (!currentData?.id && dependentProp === 'customer') {
          mobilePatient.organization = resetValues;
          mobilePatient.customer = resetValues;
          mobilePatient.facility = resetValues;
          mobilePatient.unit = resetValues;
          this.setState({ mobilePatient });
        } else if (!currentData?.id && dependentProp === 'facility') {
          mobilePatient.customer = resetValues;
          mobilePatient.facility = resetValues;
          mobilePatient.unit = resetValues;
          this.setState({ mobilePatient });
        } else if (!currentData?.id && dependentProp === 'unit') {
          mobilePatient.facility = resetValues;
          mobilePatient.unit = resetValues;
          this.setState({ mobilePatient });
        }
        this.setState((prevState) => ({
          focusCascader: {
            ...prevState.focusCascader,
            [key]: false,
          },
        }));
      },
    /**
     * Handles changes in SelectOption
     * @param {string} value option
     * @returns {void} selection
     */
    option: (value) => {
      const { mobilePatient } = this.state;
      mobilePatient[key] = value;
      this.setState({ mobilePatient });
    },
  })

  /**
   * Handles changes in Permission checkboxes
   * @param {string} key value
   * @param {number} index value
   * @returns {void}
   */
  onChangePermissionCheckbox = (key, index) =>
    /**
     * @param {CheckboxChangeEvent} event
     * @returns {Object} data
     */
    // eslint-disable-next-line implicit-arrow-linebreak
    (event) => {
      const { data: [...data] } = this.state;
      data[index][key] = event.target.checked;
      this.setState({ data });
    }

  /**
   * Copy text helper method
   * @param {String} text for token
   * @param {Boolean} useRange
   * @returns copied text
   */

  copyTextToClipboard = (text, useRange) => {
    if (!navigator.clipboard) {
      let isSuccessful = true;
      const temp = document.createElement(useRange ? 'DIV' : 'INPUT');
      document.body.append(temp);
      if (useRange) {
        temp.innerText = text;
        const range = document.createRange();
        range.selectNode(temp);
        window.getSelection().addRange(range);
      } else {
        temp.setAttribute('type', 'text');
        temp.setAttribute('value', text);
        temp.focus();
        temp.select();
      }
      try {
        isSuccessful = document.execCommand('copy');
        if (!isSuccessful && !useRange) {
          document.body.removeChild(temp);
          return this.copyTextToClipboard(text, true);
        }
      } catch (error) {
        isSuccessful = false;
        console.log('[GO] Copy to clipboard Fallback error', error);
      }
      window.getSelection().removeAllRanges();
      document.body.removeChild(temp);
      temp.remove();
      return isSuccessful ? Promise.resolve(text) : Promise.reject(text);
    }
    return navigator.clipboard.writeText(text)
      .then(() => text, (err) => {
        console.log('[GO] Async copy to clipboard: error', err);
        throw text;
      });
  };

  /**
   * Show notification message
   * @param {String} type
   */

  openNotificationWithIcon = (type) => {
    notification[type]({
      message: this.intl.formatMessage({ id: 'common.copySuccess' }),
      description: this.intl.formatMessage({ id: 'common.copySuccessInfo' }),
      className: 'ant-notification-copy-success',
      icon: <img alt="check" src={dialog_check} />,
    });
  };

  /**
   * Generate mdm token
   * @param {Object} event
   */

  copyMdmToken = async (event) => {
    event.persist();
    event.preventDefault();
    const { match } = this.props;
    try {
      const generatedToken = await RestManager.requestWithoutQueryParams(
        `${ENDPOINTS.configuration.UsersTable}/${match.params.id}/mdm_token`,
        'POST',
      );
      if (generatedToken) {
        await this.copyTextToClipboard(generatedToken.mdm_token, false);
        this.openNotificationWithIcon('success');
      }
    } catch (info) {
      console.log('info:', info);
    }
  }

  /**
   * Renders formatted timestamp based on the logged user's timezone
   * @param {string} timestampType Timestamp Type
   * @returns {JXS.Element} Intl message with the formatted timestamp
   */
  renderTimestamp = (timestampType) => {
    const { mobilePatient, loggedUser } = this.props;
    const { [timestampType]: timestamp } = mobilePatient;
    const { timeZone } = loggedUser;
    let formattedDateTime = convertTimestampToDateTime(timestamp, timeZone);
    let intlMessage;
    if (formattedDateTime) {
      let [date, time, timeZone] = formattedDateTime.split(' ');
      let index;

      if (timeZone.includes('(')) {
        timeZone = timeZone.slice(1, timeZone.length - 1);
      }
      if (timeZone.includes('+')) {
        index = timeZone.indexOf('+');
      } else if (timeZone.includes('-')) {
        index = timeZone.indexOf('-');
      }

      timeZone = timeZone.slice(0, index);

      formattedDateTime = [date, time, timeZone].join(' ');
    }
    if (formattedDateTime === null) {
      formattedDateTime = <IntlMessages id="common.null" />;
    }

    if (timestampType === this.intl.formatMessage({ id: 'common.createdAt' })) {
      intlMessage = <IntlMessages id="common.created" />;
    } else if (timestampType === this.intl.formatMessage({ id: 'common.updatedAt' })) {
      intlMessage = <IntlMessages id="common.updated" />;
    } else {
      intlMessage = <IntlMessages id="common.lastLogin" />;
    }

    return (
      <div>
        {intlMessage}
        {formattedDateTime}
      </div>
    );
  };

  /**
   * Set password in modal
   * @param {Object} mobilePatient info
   * @returns {Object} changed password
   */
  toggleChangePasswordModal = (mobilePatient = null) => {
    this.setState({ passwordChangingUser: mobilePatient });
  }

  /**
   * Change other users password
   * @return {function(): Promise<void>} Awaitable validation task
   */
    onSubmitChangePassword = async () => {
      const {
        passwordChangingUser,
      } = this.state;
      const { changeUserPassword } = this.props;
      await this.changePasswordFormRef.current?.validateFields();
      const password = this.changePasswordFormRef.current?.getFieldValue('password');
      changeUserPassword({ body: password, id: passwordChangingUser?.id });
      this.toggleChangePasswordModal();
    };

    /**
    * Show reset password confirming
    * @returns {void}
  */
  resetPassword = () => {
    const { mobilePatient, resetUserPassword } = this.props;

    confirm({
      title: this.intl.formatMessage({ id: 'common.resetPassword' }),
      content: `${this.intl.formatMessage({ id: 'configuration.users.resetPassword' })} ${mobilePatient.email}`,
      okText: this.intl.formatMessage({ id: 'configuration.users.sendEmail' }),
      onOk: () => {
        resetUserPassword({ body: {}, id: mobilePatient.id });
      },
      className: 'dark-mode-modal',
    });
  };

  /**
   * Handles What Icon to Show in Cascade Menu - Expand or Search
   * @param {string} field field type
   * @returns {func} manipulate the state
   */
  changeDropdownExpandIcon = (field) => {
    const {
      focusCascader: {
        organization, customer, facility, unit, deviceTypes,
      }, focusCascader,
    } = this.state;
    switch (field) {
      case 'organization':
        return this.setState({ focusCascader: { ...focusCascader, organization: !organization } });
      case 'customer':
        return this.setState({ focusCascader: { ...focusCascader, customer: !customer } });
      case 'facility':
        return this.setState({ focusCascader: { ...focusCascader, facility: !facility } });
      case 'unit':
        return this.setState({ focusCascader: { ...focusCascader, unit: !unit } });
      case 'deviceTypes':
        return this.setState({ focusCascader: { ...focusCascader, deviceTypes: !deviceTypes } });
      default:
    }
  }

  /**
   * Renders ManageUsers Component
   * @returns {JXS.Element} ManageUsers Component
   */
  render() {
    const {
      loading, optionsList: options, token, loggedUser,
    } = this.props;
    const { isCaregilityAdmin, isUnitAdmin } = loggedUser;
    const {
      mobilePatient, error, passwordChangingUser, focusCascader,
    } = this.state;
    const { specialty } = mobilePatient;
    return (
      <div className="manage-customer-wrapper system">
        <Form {...layout} ref={this.formRef} autoComplete="off">
          {this.renderHeadLine()}
          {!loading && error && this.showError()}
          <Row gutter={16}>
            <Col lg={10} md={24} sm={24} xs={24}>
              {/* Personal info */}
              <Card
                className="gx-card customer-edit-info-card edit-apd-details"
                title={<IntlMessages id="configuration.users.apdDetails" />}
                loading={loading}
              >
                <Form.Item
                  colon={false}
                  className="form-item-row"
                  name="mobileDeviceRole"
                  label={<IntlMessages id="configuration.mobilePatients.mobileDeviceRole" />}
                  initialValue={MEDICAL_ROLE_OPTIONS[0]}
                  rules={[
                    {
                      required: true,
                      message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                    },
                  ]}
                >
                  <Select
                    onChange={this.onChangeOf('role').option}
                    className="top-filter-popup"
                    value={mobilePatient.role = MEDICAL_ROLE_OPTIONS[0] || undefined}
                    longdesc={this.intl.formatMessage({ id: 'configuration.mobilePatients.mobileDeviceRole' })}
                    placeholder={this.intl.formatMessage({ id: 'configuration.customer.please_select' })}
                    disabled={shouldDisableMobileInputFields(loggedUser)}
                  >
                    {optionMedicalRoleItems}
                  </Select>
                </Form.Item>
                <Form.Item
                  colon={false}
                  className="form-item-row"
                  name={specialty}
                  label={<IntlMessages id="configuration.mobilePatients.fieldMobileDeviceType" />}
                  rules={[
                    {
                      required: true,
                      message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                    },
                  ]}
                  initialValue={mobilePatient.specialty || undefined}
                >
                  <Select
                    onChange={this.onChangeOf('specialty').option}
                    className="top-filter-popup"
                    value={specialty || undefined}
                    longdesc={this.intl.formatMessage({ id: 'configuration.mobilePatients.fieldMobileDeviceType' })}
                    disabled={shouldDisableMobileInputFields(loggedUser)}
                    placeholder={this.intl.formatMessage({ id: 'configuration.customer.please_select' })}
                  >
                    {optionMedicalSpecialtyItems}
                  </Select>
                </Form.Item>
                <Form.Item
                  colon={false}
                  className="form-item-row"
                  name="email"
                  label={<IntlMessages id="configuration.users.email" />}
                  rules={[
                    {
                      required: true,
                      message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                    },
                    {
                      pattern: EMAIL_REGEX,
                      message: this.intl.formatMessage({ id: 'configuration.mobilePatients.emailInfo' }),
                    },
                  ]}
                  initialValue={mobilePatient.email}
                >
                  <Input
                    autoComplete="off"
                    onChange={this.onChangeOf('email').input}
                    longdesc={this.intl.formatMessage({ id: 'configuration.users.emailInput' })}
                    disabled={shouldDisableMobileInputFields(loggedUser)}
                    placeholder={this.intl.formatMessage({ id: 'configuration.mobilePatients.email' })}
                  />
                </Form.Item>
                {this.isNew
                  ? (
                    <Form.Item
                      colon={false}
                      className="form-item-row"
                      name="password"
                      label={<IntlMessages id="appModule.password" />}
                      initialValue={mobilePatient.password}
                      rules={[
                        {
                          required: mobilePatient.specialty === MEDICAL_SPECIALITY_OPTIONS[0],
                          message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                        },
                        {
                          pattern: PASSWORD_REGEX,
                          message: this.intl.formatMessage({ id: 'configuration.users.newPassRule' }),
                        },
                      ]}
                    >
                      <Input.Password
                        autoComplete="off"
                        disabled={mobilePatient.specialty !== MEDICAL_SPECIALITY_OPTIONS[0] || shouldDisableMobileInputFields(loggedUser)}
                        onChange={this.onChangeOf('password').input}
                        longdesc={this.intl.formatMessage({ id: 'appModule.password' })}
                        placeholder={this.intl.formatMessage({ id: 'configuration.mobilePatients.password' })}
                      />
                    </Form.Item>
                  )
                  : <></>}
                <Form.Item
                  colon={false}
                  className="form-item-row"
                  name="firstName"
                  label={
                    mobilePatient.specialty === MEDICAL_SPECIALITY_OPTIONS[0] ? (
                      <IntlMessages id="configuration.users.roomName" />
                    ) : (
                      <IntlMessages id="configuration.users.firstName" />
                    )
                  }
                  initialValue={mobilePatient.firstName}
                  rules={[
                    {
                      required: true,
                      message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    id="aSettingsUsername"
                    onChange={this.onChangeOf('firstName').input}
                    value={mobilePatient.firstName}
                    longdesc={this.intl.formatMessage({ id: 'configuration.users.roomNameInput' })}
                    disabled={shouldDisableMobileInputFields(loggedUser)}
                    placeholder={
                      mobilePatient.specialty === MEDICAL_SPECIALITY_OPTIONS[0] ? (
                        this.intl.formatMessage({ id: 'configuration.mobilePatients.roomName' })
                      ) : (
                        this.intl.formatMessage({ id: 'configuration.mobilePatients.firstName' })
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  colon={false}
                  className="form-item-row"
                  name="lastName"
                  label={
                    mobilePatient.specialty === MEDICAL_SPECIALITY_OPTIONS[0] ? (
                      <IntlMessages id="configuration.users.roomDetails" />
                    ) : (
                      <IntlMessages id="configuration.users.lastName" />
                    )
                  }
                >
                  <Input
                    autoComplete="off"
                    id="aSettingsUserlastname"
                    onChange={this.onChangeOf('lastName').input}
                    value={mobilePatient.lastName}
                    longdesc={this.intl.formatMessage({ id: 'configuration.users.roomDetailsInput' })}
                    disabled={shouldDisableMobileInputFields(loggedUser)}
                    placeholder={
                      mobilePatient.specialty === MEDICAL_SPECIALITY_OPTIONS[0] ? (
                        this.intl.formatMessage({ id: 'configuration.mobilePatients.roomDetails' })
                      ) : (
                        this.intl.formatMessage({ id: 'configuration.mobilePatients.lastName' })
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  colon={false}
                  className="form-item-row notes"
                  label={<IntlMessages id="configuration.bedsCarts.acknowledged.notes" />}
                  name="notes"
                >
                  <TextArea
                    autoSize={{ minRows: 3, maxRows: 10 }}
                    autoComplete="off"
                    name="notes"
                    value={mobilePatient.notes}
                    onChange={this.onChangeOf('notes').input}
                    disabled={shouldDisableMobileInputFields(loggedUser)}
                    placeholder={this.intl.formatMessage({ id: 'configuration.mobilePatients.notes' })}
                  />
                </Form.Item>
              </Card>
            </Col>
            <Col lg={14} md={24} sm={24} xs={24}>
              {/* Customer Assignment */}
              <Card
                className="gx-card customer-edit-info-card"
                title={<IntlMessages id="configuration.users.customerAssignment" />}
                loading={loading}
              >
                <Form.Item
                  colon={false}
                  className="form-item-row"
                  label={<IntlMessages id="uhe.table.organization" />}
                  name="organization"
                  rules={[
                    {
                      type: 'array',
                      required: true,
                      message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                    },
                  ]}
                >
                  <Cascader
                    getPopupContainer={(event) => event.parentNode}
                    autoComplete={false}
                    suffixIcon={focusCascader.organization && !shouldDisableMobileInputFields(loggedUser) ? <SearchOutlined /> : null}
                    onClick={() => this.changeDropdownExpandIcon('organization')}
                    className="top-filter-popup"
                    fieldNames={{ label: 'name', value: 'id' }}
                    key="organization"
                    expandTrigger="hover"
                    size="large"
                    changeOnSelect
                    value={[mobilePatient.organization.id]}
                    options={options.organization || []}
                    onChange={this.onChangeOf('organization').cascader('customer')}
                    placeholder={this.intl.formatMessage({ id: 'configuration.customer.please_select' })}
                    showSearch={{ filter: cascaderSearchFilter }}
                    longdesc={this.intl.formatMessage({ id: 'configuration.users.organizationDropdown' })}
                    disabled={shouldDisableMobileInputFields(loggedUser)}
                    onBlur={() => this.setState({ focusCascader: removeSearchIcon() })}
                  />
                </Form.Item>
                <Form.Item
                  colon={false}
                  className="form-item-row"
                  label={<IntlMessages id="uhe.table.customer" />}
                  name="customer"
                  rules={[
                    {
                      type: 'array',
                      required: true,
                      message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                    },
                  ]}
                >
                  <Cascader
                    getPopupContainer={(event) => event.parentNode}
                    autoComplete={Math.random()}
                    className="top-filter-popup"
                    suffixIcon={focusCascader.customer && mobilePatient.organization.id || shouldDisableMobileInputFields(loggedUser) ? <SearchOutlined /> : null}
                    onClick={() => this.changeDropdownExpandIcon('customer')}
                    fieldNames={{ label: 'name', value: 'id' }}
                    key="customer"
                    expandTrigger="hover"
                    size="large"
                    changeOnSelect
                    value={[mobilePatient.customer.id]}
                    options={options.customer || []}
                    onChange={this.onChangeOf('customer').cascader('facility')}
                    placeholder={this.intl.formatMessage({ id: 'configuration.customer.please_select' })}
                    showSearch={{ filter: cascaderSearchFilter }}
                    disabled={!mobilePatient.organization.id || shouldDisableMobileInputFields(loggedUser)}
                    onBlur={() => this.setState({ focusCascader: removeSearchIcon() })}
                  />
                </Form.Item>
                <Form.Item
                  colon={false}
                  label={<IntlMessages id="uhe.table.facility" />}
                  className="gx-mb-3 form-item-row"
                  name="facility"
                  rules={[
                    {
                      type: 'array',
                      required: true,
                      message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                    },
                  ]}
                >
                  <Cascader
                    getPopupContainer={(event) => event.parentNode}
                    className="top-filter-popup"
                    suffixIcon={focusCascader.facility && mobilePatient.customer.id || shouldDisableMobileInputFields(loggedUser) ? <SearchOutlined /> : null}
                    onClick={() => this.changeDropdownExpandIcon('facility')}
                    fieldNames={{ label: 'name', value: 'id' }}
                    key="facility"
                    expandTrigger="hover"
                    size="large"
                    changeOnSelect
                    value={[mobilePatient.facility.id]}
                    options={options.facility || []}
                    onChange={this.onChangeOf('facility').cascader('unit')}
                    placeholder={this.intl.formatMessage({ id: 'configuration.customer.please_select' })}
                    showSearch={{ filter: cascaderSearchFilter }}
                    disabled={!mobilePatient.customer.id || shouldDisableMobileInputFields(loggedUser)}
                    longdesc={this.intl.formatMessage({ id: 'configuration.users.facilityDropdown' })}
                    onBlur={() => this.setState({ focusCascader: removeSearchIcon() })}
                  />
                </Form.Item>
                <Form.Item
                  colon={false}
                  className="form-item-row"
                  label={<IntlMessages id="uhe.table.unit" />}
                  name="unit"
                  rules={[
                    {
                      type: 'array',
                      required: true,
                      message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                    },
                  ]}
                >
                  <Cascader
                    getPopupContainer={(event) => event.parentNode}
                    className="top-filter-popup"
                    suffixIcon={focusCascader.unit && mobilePatient.facility.id || shouldDisableMobileInputFields(loggedUser) ? <SearchOutlined /> : null}
                    onClick={() => this.changeDropdownExpandIcon('unit')}
                    fieldNames={{ label: 'name', value: 'id' }}
                    key="unit"
                    expandTrigger="hover"
                    size="large"
                    changeOnSelect
                    value={[mobilePatient.unit.id]}
                    options={options.unit || []}
                    onChange={this.onChangeOf('unit').cascader()}
                    placeholder={this.intl.formatMessage({ id: 'configuration.customer.please_select' })}
                    showSearch={{ filter: cascaderSearchFilter }}
                    disabled={!mobilePatient.facility.id || shouldDisableMobileInputFields(loggedUser)}
                    longdesc={this.intl.formatMessage({ id: 'configuration.users.unitDropdown' })}
                    onBlur={() => this.setState({ focusCascader: removeSearchIcon() })}
                  />
                </Form.Item>
                <Form.Item
                  colon={false}
                  className="form-item-row"
                  label={<IntlMessages id="configuration.mobilePatients.alias" />}
                >
                  <Input
                    autoComplete="off"
                    onChange={this.onChangeOf('alias').input}
                    value={mobilePatient.alias}
                    longdesc={this.intl.formatMessage({ id: 'configuration.users.lastNameInput' })}
                    disabled={shouldDisableMobileInputFields(loggedUser)}
                    placeholder={this.intl.formatMessage({ id: 'configuration.mobilePatients.alias' })}
                  />
                </Form.Item>
                {!this.isNew
                  && (
                    <Form.Item
                      colon={false}
                      className="form-item-row"
                      label={<IntlMessages id="common.mdmToken" />}
                      hidden={shouldHideMDMToken(loggedUser)}
                    >
                      <Button
                        onClick={this.copyMdmToken}
                        title={this.intl.formatMessage({ id: 'common.copy' })}
                      >
                        <i className="icon icon-copy" />
                      </Button>
                    </Form.Item>
                  )}
              </Card>
            </Col>
          </Row>
          {!this.isNew && (
          <Row gutter={16}>
            <Col lg={24} md={24} sm={24} sx={24}>
              <Card className="gx-card customer-edit-info-card" loading={loading}>
                <div className="timestamps">
                  {this.renderTimestamp(this.intl.formatMessage({ id: 'common.createdAt' }))}
                  {this.renderTimestamp(this.intl.formatMessage({ id: 'common.lastSignInAt' }))}
                </div>
                {mobilePatient?.is_sso_account
                    && (
                    <Row lg={24} md={24} sm={24} sx={24} gutter={16} className="password-btn-wrapper custom-mobile-patient-wrapper">
                      {shouldShowChangePassword(loggedUser, ManageMobilePatient.pageContext) && (
                      <Button
                        onClick={() => this.toggleChangePasswordModal({
                          id: mobilePatient.id, firstName: mobilePatient.firstName, lastName: mobilePatient.lastName, page: 'apd',
                        })}
                        title={this.intl.formatMessage({ id: 'common.changePassword' })}
                        className="button-password gx-d-flex change-password"
                      >
                        <i className="icon icon-forgot-password" />
                        <IntlMessages
                          id="common.changePassword"
                        />
                      </Button>
                      )}

                      {passwordChangingUser?.id
                      && (
                      <ChangePasswordModal
                        user={passwordChangingUser}
                        onSubmit={this.onSubmitChangePassword}
                        onCancel={this.toggleChangePasswordModal}
                        ref={this.changePasswordFormRef}
                      />
                      )}
                      {shouldShowResetPassword(loggedUser, ManageMobilePatient.pageContext) && (
                      <Button
                        onClick={this.resetPassword}
                        title={this.intl.formatMessage({ id: 'common.resetPassword' })}
                        className="button-password gx-d-flex change-password"
                      >
                        <i className="icon icon-reset-password" />
                        <IntlMessages
                          id="common.resetPassword"
                        />
                      </Button>
                      )}
                    </Row>
                  )}
              </Card>
            </Col>
          </Row>
          )}
          {this.renderHeadLine()}
        </Form>
      </div>
    );
  }
}

ManageMobilePatient.defaultProps = {
  mobilePatient: {},
  data: [],
  loading: true,
  savedMobilePatientId: undefined,
  error: {},
};

ManageMobilePatient.propTypes = {
  mobilePatient: PropTypes.shape(),
  data: PropTypes.arrayOf(PropTypes.object),
  mobilePatientAccessData: PropTypes.func.isRequired,
  setSubtitle: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  location: PropTypes.shape(window.location).isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
  subtitle: PropTypes.shape({ langId: PropTypes.string }).isRequired,
  onGetOrganizationOptions: PropTypes.func.isRequired,
  match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string }) }).isRequired,
  actions: PropTypes.shape({
    fetchMobilePatient: PropTypes.func,
    saveMobilePermissions: PropTypes.func,
    saveMobilePatient: PropTypes.func,
  }).isRequired,
  savedMobilePatientId: PropTypes.number,
  error: PropTypes.shape({ code: PropTypes.number }),
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  onGetCustomerOptions: PropTypes.func.isRequired,
  onGetFacilityOptions: PropTypes.func.isRequired,
  onGetUnitOptions: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  loggedUser: PropTypes.shape({
    isCaregilitySystemAdmin: PropTypes.bool,
    isCustomerAdmin: PropTypes.bool,
    isOrganizationAdmin: PropTypes.bool,
    isTechnicianAdmin: PropTypes.bool,
  }).isRequired,
  optionsList: PropTypes.shape({
    organization: PropTypes.arrayOf(PropTypes.object),
    customer: PropTypes.arrayOf(PropTypes.object),
    facility: PropTypes.arrayOf(PropTypes.object),
    units: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  history: PropTypes.object,
  lastLocation: PropTypes.object.isRequired,
};

/**
 * @description Maps Global State to Component's Props
 * @returns {Object}
 */
const mapStateToProps = ({
  ConfigurationMobilePatients, listingsTopFilter, subtitle, common, ConfigurationUsers,
}) => {
  const { savedMobilePatientId, editTable } = ConfigurationMobilePatients;

  return {
    subtitle,
    savedMobilePatientId,
    error: common.error,
    loading: ConfigurationMobilePatients.loading,
    mobilePatient: ConfigurationMobilePatients.selectedMobilePatient || {},
    token: ConfigurationMobilePatients.token.mdm_token,
    optionsList: listingsTopFilter,
    data: editTable,
    loggedUser: ConfigurationUsers.ownUser,
  };
};

/**
 * @description Returns Object Which Dispatch Actions to the Store
 * @param {function} dispatch
 * @returns {Object}
 */
const mapDispatchToProps = (dispatch) => ({
  onGetOrganizationOptions: (id) => dispatch(onGetOptions('organization', id)),
  onGetCustomerOptions: (id) => dispatch(onGetOptions('customer', id)),
  onGetFacilityOptions: (id) => dispatch(onGetOptions('facility', id)),
  onGetUnitOptions: (id) => dispatch(onGetOptions('unit', id)),
  setSubtitle: (langId) => dispatch(setSubtitleDispatch(langId)),
  actions: bindActionCreators(mobilePatientActions, dispatch),
  mobilePatientAccessData: (id) => dispatch(mobilePatientAccessDataDispatch(id)),
  fetchMDMToken: (id) => dispatch(fetchMDMToken(id)),
  clearSelectedUser: () => dispatch(clearSelectedUserDispatch()),
  changeUserPassword: (id) => dispatch(changeUserPassword(id)),
  resetUserPassword: (id) => dispatch(resetUserPassword(id)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLastLocation(injectIntl(withRouter(ManageMobilePatient))));
