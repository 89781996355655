import React from 'react';

/**
 * Number cells
 * @param {obejct} content values
 * @returns {JSX} component
 */
const NumberCell = ({ content }) => {
  return (
    <p className={typeof content === 'string' ? '' : 'align-numeric-cell'}>
      {content}
    </p>
  );
};

export default NumberCell;
