import {
  GET_ANNOUNCEMENTS_LIST_REQUEST,
  GET_ANNOUNCEMENTS_LIST_SUCCESS,
  DELETE_ANNOUNCEMENT_SUCCESS,
  DELETE_ANNOUNCEMENT_REQUEST,
  CREATE_ANNOUNCEMENT_REQUEST,
  UPDATE_ANNOUNCEMENT_REQUEST,
  UPDATE_ANNOUNCEMENT_SUCCESS,
} from '@constants/UHEActionTypes';

const INIT_STATE = {
  announcements: {
    list: [],
    page: {},
  },
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ANNOUNCEMENTS_LIST_REQUEST: {
      return { ...state, loading: true };
    }

    case GET_ANNOUNCEMENTS_LIST_SUCCESS: {
      const newState = { announcements: action.payload, loading: false };
      return { ...state, ...newState };
    }

    case DELETE_ANNOUNCEMENT_REQUEST: {
      return { ...state, loading: true };
    }

    case DELETE_ANNOUNCEMENT_SUCCESS: {
      const id = action.payload;
      const announcementsList = state.announcements.list.filter(
        (announcement) => announcement.id !== id,
      );
      const newState = { announcements: { list: announcementsList }, loading: false };
      return { ...state, ...newState };
    }

    case CREATE_ANNOUNCEMENT_REQUEST: {
      return { ...state, loading: true };
    }

    case UPDATE_ANNOUNCEMENT_REQUEST: {
      return { ...state, loading: true };
    }

    case UPDATE_ANNOUNCEMENT_SUCCESS: {
      const { id } = action.payload;
      const announcementsList = state.announcements.list.map((announcement) => (announcement.id === id ? action.payload : announcement));
      const newState = { announcements: { list: announcementsList }, loading: false };
      return { ...state, ...newState };
    }

    default:
      return state;
  }
};
