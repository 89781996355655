import {
    CONFIGURATION_UNITS_FETCH_DATA,
    CONFIGURATION_UNITS_FETCH_SUCCESS,
    DELETE_UNITS_REQUEST,
    DELETE_UNITS_SUCCESS,
  } from "@constants/UHEActionTypes";

/**
 * @description Fetch units data
 * @param  {number} page
 * @param  {string} sorting
 * @param  {string} filter
 * @return {Object}
 */
  export const unitsOnFetchData = (page, sorting, filter) => {
    return {
      type: CONFIGURATION_UNITS_FETCH_DATA,
      page,
      sorting,
      filter
    };
  };

  /**
   * @description Units data response
   * @param  {Object} data
   * @return {Object}
   */
  export const unitsFetchDataSuccess = (data) => {
    return {
      type: CONFIGURATION_UNITS_FETCH_SUCCESS,
      data,
    }
  };

  /**
   * @description DELETE request
   * @param {Object} data
   * @return {Promise}
   */
  export const deleteUnits = (data) => {
    return {
      type: DELETE_UNITS_REQUEST,
      payload: data,
    };
  };

  /**
   * @description DELETE request success
   * @param {Object} data
   * @return {Promise}
   */
  export const deleteUnitsSuccess = (data) => {
    return {
      type: DELETE_UNITS_SUCCESS,
      payload: data,
    };
  };
