import {
  NOTIFICATIONS_REQUEST,
  NOTIFICATIONS_REQUEST_SUCCESS,
  GLOBAL_NOTIFICATIONS_REQUEST,
  GLOBAL_NOTIFICATIONS_REQUEST_SUCCESS,
  UPDATE_GLOBAL_NOTIFICATIONS_REQUEST,
  UPDATE_GLOBAL_NOTIFICATIONS_SUCCESS,
  CUSTOMER_NOTIFICATIONS_REQUEST,
  CUSTOMER_NOTIFICATIONS_SUCCESS,
  UPDATE_CUSTOMER_NOTIFICATIONS_REQUEST,
  UPDATE_CUSTOMER_NOTIFICATIONS_SUCCESS,
} from '@constants/UHEActionTypes';

const INIT_STATE = {
  notifications: {},
  globalNotifications: {},
  customerNotifications: {},
  loading: false,
};

/**
 * @description Notifications Reducers
 * @param {Object} state
 * @param {Object} actions
 * @returns {Object}
 */
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case NOTIFICATIONS_REQUEST: {
      return { ...state, loading: true };
    }

    case NOTIFICATIONS_REQUEST_SUCCESS: {
      const notifications = { ...state.notifications, ...action.payload };
      const newState = { notifications, loading: false };

      return { ...state, ...newState };
    }

    case GLOBAL_NOTIFICATIONS_REQUEST: {
      return { ...state, loading: true };
    }

    case GLOBAL_NOTIFICATIONS_REQUEST_SUCCESS: {
      const globalNotifications = {
        ...state.globalNotifications,
        ...action.payload,
      };
      const newState = { globalNotifications, loading: false };

      return { ...state, ...newState };
    }

    case UPDATE_GLOBAL_NOTIFICATIONS_REQUEST: {
      return { ...state, loading: true };
    }

    case UPDATE_GLOBAL_NOTIFICATIONS_SUCCESS: {
      const newState = { ...action.payload };

      return { ...state, globalNotifications: { ...newState }, loading: false };
    }

    case CUSTOMER_NOTIFICATIONS_REQUEST: {
      return { ...state, loading: true };
    }

    case CUSTOMER_NOTIFICATIONS_SUCCESS: {
      const customerNotifications = {
        ...state.customerNotifications,
        ...action.payload,
      };
      const newState = { customerNotifications, loading: false };

      return { ...state, ...newState };
    }

    case UPDATE_CUSTOMER_NOTIFICATIONS_REQUEST: {
      return { ...state, loading: true };
    }

    case UPDATE_CUSTOMER_NOTIFICATIONS_SUCCESS: {
      const newState = { ...action.payload };

      return {
        ...state,
        customerNotifications: { ...newState },
        loading: false,
      };
    }

    default:
      return state;
  }
};
