import {
  FETCH_PASSWORD_DATA,
  FETCH_PASSWORD_DATA_SUCCESS,
} from '@constants/UHEActionTypes';

const INIT_STATE = {
  saved: false,
  loading: false,
  currentPassword: {},
};

/**
 * @description USERS reducer
 * @param  {Object} state
 * @param  {string} action
 * @return {Object}
 */
export default (state = INIT_STATE, action) => {
  let newState;

  switch (action.type) {
    case FETCH_PASSWORD_DATA: {
      newState = { loading: true, saved: false };
      return { ...state, ...newState };
    }
    case FETCH_PASSWORD_DATA_SUCCESS: {
      const currentPassword = { ...state.currentPassword, ...action.payload };
      newState = { currentPassword, loading: false };
      return { ...state, ...newState };
    }

    default:
      return state;
  }
};