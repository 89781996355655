import React, { useEffect, useState } from 'react';
import { Layout, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { switchLanguage, toggleCollapsedSideNav } from '@actions/Setting';
import { fetchApplicationVersion } from '@uhe_actions/system/SystemActions';
import { onFetchOwnPofile, getUserPreferences } from '@uhe_actions/configuration/users/UsersActions';
import {
  AppSelector,
  Profile,
  ProfileDialog,
  AboutDialog,
  Logout,
} from 'uhe-corporate-identity-lib';
import { getOwnUser } from '@uhe_selectors/configuration/users/UsersSelectors';

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from '@constants/ThemeSetting';
import UheHelper, { getMenuOptions, getAccesToken } from 'util/UheHelper';
import { PROFILE_DROPDOWN, WINDOW_DIMENSIONS } from '@constants/UHESettings';
import languageData from './languageData';

const { Header } = Layout;
const { Option } = Select;

const profileDropdownOptions = PROFILE_DROPDOWN.map((element) => (
  <Option key={element.value} value={element.value}>{element.label}</Option>
));

/**
 * Renders Topbar Component
 * @param {object} intl Translation Services
 * @returns {JSX} Topbar Component
 */
const Topbar = ({ intl }) => {
  const {
    width,
    navCollapsed,
    navStyle,
  } = useSelector(
    ({ settings }) => settings,
  );
  const loggedUser = useSelector(getOwnUser);
  const { platform } = useSelector(
    ({ SystemSettingsSystem }) => SystemSettingsSystem.version,
  );

  const dispatch = useDispatch();

  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showAboutModal, setShowAboutModal] = useState(false);

  const {
    firstName, lastName, username, organization, clinicianRole, specialty,
  } = loggedUser;

  useEffect(() => {
    const langFromSettings = localStorage.getItem('appLang');
    if (langFromSettings) {
      dispatch(switchLanguage(JSON.parse(langFromSettings)));
      return;
    }
    const browserLangs = navigator.languages;
    if (browserLangs && Array.isArray(browserLangs)) {
      const browserLang = browserLangs[0].split('-')[0];
      const lang = languageData.find((l) => l.locale === browserLang);
      if (lang) {
        dispatch(switchLanguage(lang));
      }
    }
    dispatch(onFetchOwnPofile());
    dispatch(getUserPreferences());
    dispatch(fetchApplicationVersion());
    dispatch(onFetchOwnPofile());
    dispatch(getUserPreferences());
  }, []);

  /**
   * Opens the app in new window
   * @param {string} url url
   * @param {string} dimensions Window Dimensions - Width/Height
   * @returns {void} void
   */
  const openInNewTab = (url, dimensions = '') => {
    window.open(url, '_blank', dimensions);
  };

  /**
   * Forwards to the selected app
   * @param {string} menu navStyle
   * @returns {void} void
   */
  const onMenuSelected = (menu) => {
    switch (menu) {
      case 'iObserver': {
        openInNewTab(
          `${process.env.REACT_APP_IOBSERVER_URL}`,
        );
        return;
      }
      case 'iConsult': {
        openInNewTab(
          `${process.env.REACT_APP_ICONSULT_URL}`,
        );
        return;
      }
      case 'notification': {
        openInNewTab(
          `${process.env.REACT_APP_NOTIFICATIONS_URL}`,
          WINDOW_DIMENSIONS,
        );
        return;
      }
      case 'landing': {
        openInNewTab(`${process.env.REACT_APP_LANDING_URL}?access_token=${getAccesToken()}`);
        return;
      }
      case 'reporting': {
        openInNewTab(`${process.env.REACT_APP_REPORTING_URL}`);
        return;
      }
      case 'technician': {
        openInNewTab(`${process.env.REACT_APP_TECHNICIAN_URL}`);
        break;
      }
      default:
        break;
    }
  };

  /**
   * Handles Changes in the Profile Dropdown
   * @param {string} menuItem Dropdown Menu Item
   * @returns {void}
   */
  const profileDropdownHandler = (menuItem) => {
    switch (menuItem) {
      case 'profile':
        setShowProfileModal(true);
        break;
      case 'about':
        setShowAboutModal(true);
        break;
      default:
        break;
    }
  };

  /**
   * Renders System Version and Releaes Version
   * @returns {JSX} System Version and Releaes Version
   */
  const renderVersion = () => {
    const formattedVersion = process.env.REACT_APP_VERSION
      .split('.')
      .slice(0, -1)
      .join('.');

    return `${intl.formatMessage({ id: 'common.version' })}${formattedVersion} ${intl.formatMessage({ id: 'common.releaseVersion' })}${platform}`;
  };

  /**
   * Decodes the Access Token and Returns Program Name to be Used in Profile Dialog
   * @returns {string} Program Name
   */
  const programName = () => {
    const accessToken = getAccesToken();
    const tokenInfo = jwt_decode(accessToken);

    if (tokenInfo && tokenInfo.program_name) {
      return tokenInfo.program_name;
    }

    return '';
  };

  /**
   * Handles Logout Functionality
   * @returns {void}
   */
  const logoutHandler = () => {
    UheHelper.clearAuthTokens();
    UheHelper.removeOwnUserProfile();
    window.location.href = (window.env_overrides.REACT_APP_SSO_REDIRECT_URL
      || process.env.REACT_APP_SSO_REDIRECT_URL).replace('?', 'logout/?');
  };

  return (
    <Header className="top-header">
      {navStyle === NAV_STYLE_DRAWER
        || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR)
          && width < TAB_SIZE) ? (
            <div className="gx-linebar gx-mr-1">
              <i
                className="gx-icon-btn icon icon-menu"
                onClick={() => {
                  dispatch(toggleCollapsedSideNav(!navCollapsed));
                }}
              />
            </div>
        ) : null}
      <AppSelector
        cssClass="app-selector"
        options={getMenuOptions(loggedUser)}
        value="Admin Portal"
        onSelect={onMenuSelected}
      />
      <div className="gx-d-flex gx-align-items-center profile-wrapper">
        {(firstName || lastName) && (
          <Profile
            cssClass="gx-d-flex cil-profile no-selectable-element"
            name={`${firstName} ${lastName}`}
            email={username || ''}
            options={profileDropdownOptions}
            onSelect={profileDropdownHandler}
            avatarClick={() => setShowProfileModal(true)}
            nameClick={() => {}}
          />
        )}
        <div className="separator-line" />
        <Logout
          cssClass="logout-btn no-selectable-element"
          title={intl.formatMessage({ id: 'profile.logout' })}
          click={logoutHandler}
        />
      </div>
      <ProfileDialog
        name={`${firstName} ${lastName}`}
        email={username || ''}
        clinicianRole={clinicianRole || ''}
        specialty={specialty ? [specialty] : []}
        open={showProfileModal}
        close={() => setShowProfileModal(false)}
        selectedProgram={programName()}
        organization={organization ? organization.name : intl.formatMessage({ id: 'common.allOrganizations' })}
      />
      <AboutDialog
        open={showAboutModal}
        close={() => setShowAboutModal(false)}
        caregilityVersion={renderVersion()}
      />
    </Header>
  );
};

Topbar.propTypes = {
  intl: PropTypes.shape().isRequired,
};

export default injectIntl(Topbar);
