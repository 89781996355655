import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import * as ssoDomainActions from '@uhe_actions/system/SsoProvidersActions';
import { onFetchDomainData, deleteDomain, saveDomain, clearDomainListState } from "@uhe_actions/system/SsoProvidersActions";
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {
  Popconfirm, Button, Row, Col, Form, Table, Card,
} from 'antd';
import EditHeader from '@components/uhe/configuration/EditHeader.jsx';
import { EditableFormRow, EditableCell } from './EditableContext';

/**
 * @description Renders domain list table
 */
class DomainList extends Component {
  constructor(props) {
    super(props);

    this.domainColumns = [
      {
        title: <IntlMessages id="sso.domain.table.domain" />,
        dataIndex: 'domain',
        width: '50%',
        editable: true,
        key: 'domain',
      },
      {
        title: <IntlMessages id="sso.domain.table.version" />,
        dataIndex: 'version',
        width: '20%',
        editable: false,
        key: 'version',
      },
      {
        title: <IntlMessages id="sso.domain.table.options" />,
        dataIndex: 'options',
        key: 'options',
        render: (text, record, index) => (
          this.state.domain.length >= 1 ? (
            <div className="action-btns">
              <Popconfirm title={<IntlMessages id="common.saveQuestion" />} onConfirm={() => this.handleSave(record, this.props.match.params.id)} placement="topRight">
                <Button htmlType="submit" className="providers-table-btn" title={this.intl.formatMessage({ id: 'button.save' })}>
                  <i className="icon icon-check-cricle save-row" />
                </Button>
              </Popconfirm>
              <Popconfirm title={<IntlMessages id="common.deleteQuestion" />} onConfirm={() => this.props.deleteDomain(record, this.props.match.params.id)} placement="topRight">
                <Button htmlType="submit" className="providers-table-btn" title={this.intl.formatMessage({ id: 'button.delete' })}>
                  <i className="icon icon-trash" />
                </Button>
              </Popconfirm>
            </div>
          ) : null
        ),
      },
    ];

    this.intl = this.props.intl;

    this.state = {
      testDomain: [
        {
          domain: '',
          version: 0,
        }
      ],
      domain: [],
    };

    if (
      this.props.subtitle &&
      this.props.subtitle.langId !== "sso.domain.subtitle"
    ) {
      this.props.setSubtitle("sso.domain.subtitle");
    }

    this.handleAddRow = this.handleAddRow.bind(this);
    this.renderHeadLine = this.renderHeadLine.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  domainForm = React.createRef();

  componentDidMount() {
    const urlId = this.props.match.params.id;
    this.props.onFetchDomainData({ id: urlId });
  }

  /**
   * @description Watch for empty data and if is empty set new state data
   * @param {Object} prevProps
   * @return {Object}
   */
  componentDidUpdate(prevProps) {
    if (prevProps.domain.length === 0 && this.props.domain.length > 0) {
      this.setState({domain: this.props.domain});
    }
  }

  componentWillUnmount() {
    this.props.clearDomainListState();
  } 

  /**
   * @description Return to previous page
   * @returns {void}
   */
  goBack() {
    const { history } = this.props;
    history.push('/system/settings/sso-providers');
  }

  /**
   * @description Delete record from domain list
   * @param {string} index
   * @returns {void}
   */
  handleDelete(index) {
    const domain = [...this.state.domain];
    this.setState({ domain: domain.filter(record => record.index !== index) });
  }

  /**
   * @description Save record in domain list
   * @param {Object} row
   * @returns {void}
   */
  handleSave(record) {
    const { saveDomain } = this.props;
    const { domain } = this.state;
    // saveDomain({ ...domain[index]});
    domain.forEach((data, index) => {
      console.log(data);
      if(index === record.key) {
        saveDomain({ body: domain[index], id: this.props.match.params.id });
      }
    })
  }

  /**
   * @description Save changed record
   * @param {Array} row 
   */
  handleChanges = (row) => {
    const newData = [...this.state.domain];
    const index = newData.findIndex(item => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
        ...item,
        ...row,
    });
     this.setState( {domain: newData});
  };

  /**
   * @description Add an empty row
   * @returns {void}
   */
  handleAddRow() {
    const { domain } = this.state;
    const newDomain = [...domain ];
    newDomain.push({
        domain: '',
        version: 0,
    });
    this.setState({
      domain: [...newDomain],
    });
  }

  /**
   * @description Save Domain list form
   * @returns {void}
   */
  saveForm() {
    this.domainForm.validateFields()
      .then()
      .catch((errorMsg) => {
        console.log(errorMsg);
      });
  }

  /**
   * @description Populate Columns for Add/Edit Domain List
   * @param {Array} dataCustomerServer
   * @param {Boolean} editable
   */
  dataAdapter(domain = []) {
    const adaptedData = [];
    if(domain !== undefined) {
      domain.forEach((item, index) => {
        adaptedData.push(
          {
            key: index,
            id: item.id,
            domain: item.domain,
            version: item.version,
            ssoId: this.props.match.params.id
          },
        );
      });
    }
    return adaptedData;
  }

  /**
   * @description Render title, back and save buttons
   * @returns {JSX}
   */
  renderHeadLine() {
    const { editMode, isFetching } = this.props;
    const { loading } = this.props;
    return (
      <EditHeader
        goBack={this.goBack}
        loading={loading}
      />
    );
  }

  render() {
    const { domain } = this.state;
    const loading = this.props;
    const components = {
      body: {
        row: (props) => EditableFormRow(props, this.domainForm),
        cell: EditableCell,
      },
    };
    const domainColumns = this.domainColumns.map((col, index) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleChanges,
        }),
      };
    });
    return (
      <div className="manage-customer-wrapper system">
        <Form className="domainList" onSubmit={this.saveForm} ref={this.domainForm} loading={loading}>
          {this.renderHeadLine()}
          <Card
            className="gx-card customer-edit-info-card"
          >
            <Row className="baseLineAlignedItems" gutter={16}>
              <Col lg={3} md={6} sm={24} xs={24}>
                <Button className="sendButton" onClick={this.handleAddRow}>
                  Add a row
                </Button>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col lg={24} md={24} sm={24} xs={24}>
                <div className="uhe-table">
                  <Table
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered
                    dataSource={this.dataAdapter(domain)}
                    columns={domainColumns}
                    pagination={false}
                  />
                </div>
              </Col>
            </Row>
          </Card>
        </Form>
      </div>
    );
  }
}

DomainList.defaultProps = {
  domain: [],
  loading: true,
};

DomainList.propTypes = {
  domain: PropTypes.array,
  setSubtitle: PropTypes.func,
};

/**
 * @description Maps Global State to Component's Props
 * @returns {Object}
 */
const mapStateToProps = ({ SsoProvidersSettings, subtitle, common }) => {
  const domain = SsoProvidersSettings.selectedDomain || [];
  const { loading } = SsoProvidersSettings;

  return {
    subtitle,
    error: common.error,
    loading,
    domain,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSubtitle: (langId) => dispatch(setSubtitle(langId)),
    actions: bindActionCreators(ssoDomainActions, dispatch),
    saveDomain: (data) => dispatch(saveDomain(data)),
    onFetchDomainData: (id) => dispatch(onFetchDomainData(id)),
    deleteDomain: (id) => dispatch(deleteDomain(id)),
    clearDomainListState: () => dispatch(clearDomainListState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(DomainList)));
