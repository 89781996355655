import { developerOnFetchDataSuccess } from "@actions/uhe/system/DeveloperActions";
import { SYSTEM_DEVELOPER_FETCH_DATA } from "@constants/UHEActionTypes";
import { ENDPOINTS } from "@constants/UHEEndpoints";
import RestManager from "@util/RestManager";
import { fetchError } from "@actions/Common";
import { call, fork, put, takeEvery, all } from "redux-saga/effects";

/**
 * @description Fetch developers data
 * @param {number} page
 * @param {Array} sorting
 * @param {Array} filter
 * @returns {Promise}
 */
const fetchDevelopersData = async (page, sorting, filter) => {
  const filterQueryString =
    filter && filter.length ? `&${filter.join("&")}` : "";
  const sortingQueryString =
    sorting && sorting.length ? `&sort=${sorting.join("&sort=")}` : "";

  return await RestManager.request(
    `${ENDPOINTS.developer.developersTable}?page=${
      page || 0
    }${sortingQueryString}${filterQueryString}`
  );
};

/**
 * @description Update table by page, sort and filter
 * @param {number} page
 * @param {Array} sorting
 * @param {Array} filter
 * @returns {void}
 */
function* getDevelopersData({ page, sorting, filter }) {
  try {
    const developersData = yield call(
      fetchDevelopersData,
      page,
      sorting,
      filter
    );
    if (developersData) {
      yield put(developerOnFetchDataSuccess(developersData));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* actionsWatcher() {
  yield takeEvery(SYSTEM_DEVELOPER_FETCH_DATA, getDevelopersData);
}

export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
