import {
  SSO_PROVIDERS_FETCH_DATA,
  SSO_PROVIDERS_FETCH_DATA_SUCCESS,
  SAVE_SSO_PROVIDER,
  SAVE_SSO_PROVIDER_SUCCESS,
  SAVE_SSO_PROVIDER_ID,
  SAVE_SSO_PROVIDER_ID_SUCCESS,
  DELETE_SSO_PROVIDER_REQUEST,
  DELETE_SSO_PROVIDER_REQUEST_SUCCESS,
  SAML_PROVIDERS_FETCH_DATA,
  SAML_PROVIDERS_FETCH_DATA_SUCCESS,
  SAVE_SAML_PROVIDER,
  SAVE_SAML_PROVIDER_SUCCESS,
  DOMAIN_FETCH_DATA,
  DOMAIN_FETCH_DATA_SUCCESS,
  SAVE_DOMAIN_DATA,
  SAVE_DOMAIN_DATA_SUCCESS,
  SAVE_DOMAIN_ID,
  SAVE_DOMAIN_ID_SUCCESS,
  DELETE_DOMAIN_REQUEST,
  DELETE_DOMAIN_REQUEST_SUCCESS,
  CLEAR_DOMAIN_STATE,
} from '@constants/UHEActionTypes';

/**
 * @description Fetch data
 * @param  {number} page
 * @param  {string} sorting
 * @return {Object}
 */
export const ssoProvidersOnFetchData = (page, sorting, filter) => ({
  type: SSO_PROVIDERS_FETCH_DATA,
  page,
  sorting,
  filter,
});

/**
 * @description Fetch success
 * @param  {Object} data
 * @return {Object}
 */
export const ssoProvidersFetchDataSuccess = (data) => ({
  type: SSO_PROVIDERS_FETCH_DATA_SUCCESS,
  data,
});

/**
 * @description Handle SAVE_SSO_PROVIDER Action
 * @param {Object} data
 * @returns {Object}
 */
export const saveSsoProvider = (data) => ({
  type: SAVE_SSO_PROVIDER,
  payload: data,
});

/**
 * @description Handle SAVE_SSO_PROVIDER_SUCCESS Action
 * @param {Object} payload
 * @returns {Object}
 */
export const saveSsoProviderSuccess = (payload) => ({
  type: SAVE_SSO_PROVIDER_SUCCESS,
  payload,
});

/**
 * @description Handle SAVE_SSO_PROVIDER_ID Action
 * @param {Object} payload
 * @returns {Object}
 */
export const saveSsoProviderById = (payload) => ({
  type: SAVE_SSO_PROVIDER_ID,
  id: payload.id,
});

/**
 * @description Handle SAVE_SSO_PROVIDER_ID_SUCCESS Action
 * @param {Object} payload
 * @returns {Object}
 */
export const saveSsoProviderByIdSuccess = (payload) => ({
  type: SAVE_SSO_PROVIDER_ID_SUCCESS,
  payload: { ...payload },
});

/**
 * @description DELETE request
 * @param {Object} data
 * @return {Promise}
 */
export const deleteSsoProvider = (data) => ({
  type: DELETE_SSO_PROVIDER_REQUEST,
  payload: data,
});

/**
 * @description DELETE request success
 * @param {Object} data
 * @return {Promise}
 */
export const deleteSsoProviderSuccess = (data) => ({
  type: DELETE_SSO_PROVIDER_REQUEST_SUCCESS,
  payload: data,
});

/**
 * @description Handle SAML_PROVIDERS_FETCH_DATA Action
 * @param {Object} payload
 * @returns {Object}
 */
export const onFetchSamlProvidersData = (payload) => ({
  type: SAML_PROVIDERS_FETCH_DATA,
  id: payload.id,
});

/**
 * @description Fetch success SAML_PROVIDERS_FETCH_DATA_SUCCESS
 * @param  {Object} data
 * @return {Object}
 */
export const onFetchSamlProvidersDataSuccess = (payload) => ({
  type: SAML_PROVIDERS_FETCH_DATA_SUCCESS,
  payload,
});

/**
 * @description Handle SAVE_SAML_PROVIDER Action
 * @param {Object} data
 * @returns {Object}
 */
export const saveSamlProvider = (data) => ({
  type: SAVE_SAML_PROVIDER,
  payload: data,
});

/**
 * @description Handle SAVE_SAML_PROVIDER_SUCCESS Action
 * @param {Object} payload
 * @returns {Object}
 */
export const saveSamlProviderSuccess = (payload) => ({
  type: SAVE_SAML_PROVIDER_SUCCESS,
  payload,
});

/**
 * @description Fetch domain Action
 * @param {Object} payload
 * @returns {Object}
 */
export const onFetchDomainData = (payload) => ({
  type: DOMAIN_FETCH_DATA,
  id: payload.id,
});

/**
 * @description Fetch domain success
 * @param  {Object} data
 * @return {Object}
 */
export const onFetchDomainDataSuccess = (payload) => ({
  type: DOMAIN_FETCH_DATA_SUCCESS,
  payload,
});

/**
 * @description Save domain request
 * @param {Object} data
 * @return {Object}
 */
export const saveDomain = (data) => ({
  type: SAVE_DOMAIN_DATA,
  payload: data,
});

/**
 * @description Save domain request success
 * @param {object} payload
 * @return {object}
 */
export const saveDomainSuccess = (payload) => ({
  type: SAVE_DOMAIN_DATA_SUCCESS,
  payload,
});

/**
 * @description Handle SAVE_DOMAIN_ID Action
 * @param {Object} payload
 * @returns {Object}
 */
export const saveDomainById = (payload) => {
  return {
    type: SAVE_DOMAIN_ID,
    id: payload.id,
  };
};

/**
 * @description Handle SAVE_DOMAIN_ID_SUCCESS Action
 * @param {Object} payload
 * @returns {Object}
 */
export const saveDomainByIdByIdSuccess = (payload) => {
  return {
    type: SAVE_DOMAIN_ID_SUCCESS,
    payload: { ...payload },
  };
};

/**
 * @description DELETE request
 * @param {Object} data
 * @return {Promise}
 */
export const deleteDomain = (data) => ({
  type: DELETE_DOMAIN_REQUEST,
  payload: data,
});

/**
 * @description DELETE request success
 * @param {Object} data
 * @return {Promise}
 */
export const deleteDomainSuccess = (data) => ({
  type: DELETE_DOMAIN_REQUEST_SUCCESS,
  payload: data,
});

/**
 * @description Clear domain list data
 * @return {Promise}
 */
export const clearDomainListState = () => {
  return {
    type: CLEAR_DOMAIN_STATE,
  };
};
