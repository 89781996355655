import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Card,
  Row,
  Col,
  Checkbox,
  Input,
  Form,
} from 'antd';
import { injectIntl } from 'react-intl';
import lodash from 'lodash';
import { bindActionCreators } from 'redux';
import * as ssoProviderActions from '@uhe_actions/system/SsoProvidersActions';
import IntlMessages from 'util/IntlMessages';
import EditHeader from '@components/uhe/configuration/EditHeader';
import { setSubtitle } from '@uhe_actions/SubtitleActions';

class ManageSAMLProvider extends Component {
  constructor(props) {
    super(props);
    this.isSaml = this.props.location.pathname.indexOf('/saml') !== -1;
    this.urlId = this.props.match.params.id;

    this.state = {
      samlProvider: {
        id: null,
        version: '0',
        idpWebLogoutUrl: null,
        idpWebSsoUrl: null,
        isForcedLogout: false,
        localEntityId: null,
        remoteIdpEntityId: null,
        verificationKey: null,
      },
    };
    this.goBack = this.goBack.bind(this);
    this.saveSamlProvider = this.saveSamlProvider.bind(this);

    this.data = [];

    this.history = this.props.history;
    this.qParams = new URLSearchParams(this.history.location.search);

    this.onPageChange(1);
  }

  componentDidMount() {
    const urlId = this.props.match.params.id;
    const { actions } = this.props;
    actions.onFetchSamlProvidersData({ id: urlId });
  }

  /**
   * @description Watch for empty data and if is empty set new state data
   * @param {Object} prevProps
   * @return {Object}
   */
  componentDidUpdate(prevProps) {
    const { savedSamlProviderId } = this.props;

    if (prevProps.error !== this.props.error) {
      // handle system error
      if (this.props.error.code === 404 && !this.isNew) {
        this.props.history.push('/system/settings/sso-providers');
      }
    }

    if (this.isNew && savedSamlProviderId !== prevProps.savedSamlProviderId) {
      this.props.history.push(`/system/settings/sso-providers/edit/${savedSamlProviderId}`);
      return;
    }

    if (!this.isNew && prevProps.samlProvider && this.props.samlProvider) {
      if ((prevProps.samlProvider.id !== this.props.samlProvider.id)
      || (prevProps.samlProvider.saved !== this.props.samlProvider.saved)) {
        this.setState({ samlProvider: this.props.samlProvider });
      }
    }
    if (prevProps.data.length === 0 && this.props.data.length > 0) {
      this.setState({ data: this.props.data });
    }
  }

  /**
   * @description Change data after filtering
   * @param {number} page
   * @return {Void}
   */
  onPageChange(page) {
    this.currentPage = page - 1;
    const filter = [];

    lodash.forOwn(this.topFilterMap, (value, key) => {
      const filterParam = this.qParams.get(key);
      if (filterParam) {
        filter.push(`${value}=${filterParam}`);
      }
    });

    this.filter = filter;
  }

  /**
   * @description Handle change in input
   * @param {Object} event
   */
  onChangeHandler(key, event) {
    if (event && event.persist && event.preventDefault) {
      event.persist();
      event.preventDefault();
    }
    this.setState({
      samlProvider: {
        ...this.state.samlProvider,
        ...this.state.samlProvider,
        [key]: event.target.value,
      },
    });
  }

  onChangeCheckbox(key, event) {
    const isChecked = event.target.checked;
    this.setState({
      samlProvider: {
        ...this.state.samlProvider,
        [key]: isChecked,
      },
    });
  }

  goBack() {
    const { history } = this.props;
    history.push('/system/settings/sso-providers');
  }

  /**
   * @description Save Changes on Save Button Click in the header
   * @param {Object} event
   * @returns {void}
   */
  saveSamlProvider(event) {
    event.persist();
    event.preventDefault();
    const { actions } = this.props;
    const { samlProvider } = this.state;
    actions.saveSamlProvider({ body: samlProvider, id: this.props.match.params.id });
  }

  renderHeadLine() {
    const { editMode, isFetching } = this.props;
    const titleKey = editMode
      ? 'configuration.organizations.title_edit'
      : 'configuration.customer.title_add_new';

    return (
      <EditHeader
        goBack={this.goBack}
        save={this.saveSamlProvider}
        loading={isFetching}
        titleKey={titleKey}
      />
    );
  }

  render() {
    const { loading } = this.props;
    const { samlProvider } = this.state;
    return (
      <div className="manage-ssoProvider-wrapper">
        <Form loading={loading} ref={this.formRef} name="manage-samlProvider" autoComplete="off">
          {this.renderHeadLine()}
          <Row gutter={16}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Card
                className="gx-card"
              >
                <Row className="gx-mb-3" gutter={16}>
                  <Col className="gx-d-flex gx-align-items-center" lg={6} md={6} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="sso.saml.version" />
                    </div>
                  </Col>
                  <Col lg={18} md={18} sm={24} xs={24}>
                    <Form.Item
                      colon={false}
                    >
                      <Input
                        autoComplete="off"
                        value={samlProvider.version}
                        min={0}
                        max={100}
                        placeholder={this.props.intl.formatMessage({ id: 'sso.saml.version' })}
                        title={this.props.intl.formatMessage({ id: 'sso.saml.version' })}
                        onChange={(event) => { this.onChangeHandler("version", event); }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="gx-mb-3" gutter={16}>
                  <Col className="gx-d-flex gx-align-items-center" lg={6} md={6} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="sso.saml.webLogoutURL" />
                    </div>
                  </Col>
                  <Col lg={18} md={18} sm={24} xs={24}>
                    <Form.Item
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message: <IntlMessages id="sso.saml.validation.webLogoutURL" />,
                        },
                      ]}
                    >
                      <Input
                        autoComplete="off"
                        type="text"
                        name="webLogoutURL"
                        value={samlProvider.idpWebLogoutUrl}
                        placeholder={this.props.intl.formatMessage({ id: 'sso.saml.webLogoutURL' })}
                        title={this.props.intl.formatMessage({ id: 'sso.saml.webLogoutURL' })}
                        onChange={(event) => { this.onChangeHandler('idpWebLogoutUrl', event); }}
                      />
                    </Form.Item>

                  </Col>
                </Row>
                <Row className="gx-mb-3" gutter={16}>
                  <Col className="gx-d-flex gx-align-items-center" lg={6} md={6} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="sso.saml.webSSOURL" />
                    </div>
                  </Col>
                  <Col lg={18} md={18} sm={24} xs={24}>
                    <Form.Item
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message: <IntlMessages id="sso.saml.validation.webSSOURL" />,
                        },
                      ]}
                    >
                      <Input
                        autoComplete="off"
                        name="webSSOURL"
                        value={samlProvider.idpWebSsoUrl}
                        placeholder={this.props.intl.formatMessage({ id: 'sso.saml.webSSOURL' })}
                        title={this.props.intl.formatMessage({ id: 'sso.saml.webSSOURL' })}
                        onChange={(event) => { this.onChangeHandler("idpWebSsoUrl", event); }}
                      />
                    </Form.Item>

                  </Col>
                </Row>
                <Row className="gx-mb-3" gutter={16}>
                  <Col className="gx-d-flex gx-align-items-center" lg={6} md={6} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="sso.saml.isForcedLogout" />
                    </div>
                  </Col>
                  <Col lg={18} md={18} sm={24} xs={24}>
                    <Form.Item
                      colon={false}
                    >
                      <Checkbox
                        checked={samlProvider.isForcedLogout}
                        type="checkbox"
                        onChange={(event) => { this.onChangeCheckbox('isForcedLogout', event); }}
                      />
                    </Form.Item>

                  </Col>
                </Row>
                <Row className="gx-mb-3" gutter={16}>
                  <Col className="gx-d-flex gx-align-items-center" lg={6} md={6} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="sso.saml.localEntityID" />
                    </div>
                  </Col>
                  <Col lg={18} md={18} sm={24} xs={24}>
                    <Form.Item
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message: <IntlMessages id="sso.saml.validation.localEntityID" />,
                        },
                      ]}
                    >
                      <Input
                        autoComplete="off"
                        name="localEntityID"
                        value={samlProvider.localEntityId}
                        placeholder={this.props.intl.formatMessage({ id: 'sso.saml.localEntityID' })}
                        title={this.props.intl.formatMessage({ id: 'sso.saml.localEntityID' })}
                        onChange={(event) => { this.onChangeHandler("localEntityId", event); }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="gx-mb-3" gutter={16}>
                  <Col className="gx-d-flex gx-align-items-center" lg={6} md={6} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="sso.saml.remoteEntityID" />
                    </div>
                  </Col>
                  <Col lg={18} md={18} sm={24} xs={24}>
                    <Form.Item
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message: <IntlMessages id="sso.saml.validation.remoteEntityID" />,
                        },
                      ]}
                    >
                      <Input
                        autoComplete="off"
                        name="remoteEntityID"
                        value={samlProvider.remoteIdpEntityId}
                        placeholder={this.props.intl.formatMessage({ id: 'sso.saml.remoteEntityID' })}
                        title={this.props.intl.formatMessage({ id: 'sso.saml.remoteEntityID' })}
                        onChange={(event) => { this.onChangeHandler('remoteIdpEntityId', event); }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="gx-mb-3" gutter={16}>
                  <Col className="gx-d-flex gx-align-items-center" lg={6} md={6} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="sso.saml.verificationKey" />
                    </div>
                  </Col>
                  <Col lg={18} md={18} sm={24} xs={24}>
                    <Form.Item
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message: <IntlMessages id="sso.saml.validation.verificationKey" />,
                        },
                      ]}
                    >
                      <Input
                        autoComplete="off"
                        name="verificationKey"
                        value={samlProvider.verificationKey}
                        placeholder={this.props.intl.formatMessage({ id: 'sso.saml.verificationKey' })}
                        title={this.props.intl.formatMessage({ id: 'sso.saml.verificationKey' })}
                        onChange={(event) => { this.onChangeHandler('verificationKey', event); }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}

ManageSAMLProvider.defaultProps = {
  samlProvider: {},
  data: [],
  loading: true,
};

ManageSAMLProvider.propTypes = {
  samlProvider: PropTypes.object,
  data: PropTypes.array,
  setSubtitle: PropTypes.func,
};

/**
 * @description Maps Global State to Component's Props
 * @returns {Object}
 */
const mapStateToProps = ({ SsoProvidersSettings, subtitle, common }) => {
  const { savedSamlProviderId, editTable } = SsoProvidersSettings;
  return {
    subtitle,
    savedSamlProviderId,
    error: common.error,
    loading: SsoProvidersSettings.loading,
    samlProvider: SsoProvidersSettings.selectedSamlProvider || {},
    data: editTable,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  actions: bindActionCreators(ssoProviderActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withRouter(ManageSAMLProvider)));
