import {
  FETCH_VMR_REPORT,
  FETCH_VMR_REPORT_SUCCESS,
} from 'constants/UHEActionTypes';

const INIT_STATE = {
  vmrData: [],
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_VMR_REPORT: {
      return { ...state, loading: true };
    }

    case FETCH_VMR_REPORT_SUCCESS: {
      const newState = { vmrData: action.data, loading: false };

      return { ...state, ...newState };
    }

    default:
      return state;
  }
};
