import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import { Table } from 'antd';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';
import RestManager from '@util/RestManager';
import { BASE_URL, ENDPOINTS } from '@constants/UHEEndpoints';

import ListingsTableInputFilter from '@filters/ListingsTableInputFilter';
import OptionsCell from '@components/tables/cells/OptionsCell';

import { ssoProvidersOnFetchData, deleteSsoProvider } from '@uhe_actions/system/SsoProvidersActions';
import { LISTING_TABLES_PAGE_SIZE, TABLE_FILTER_PREFIX } from '@constants/UHESettings';
import { setSubtitle } from '@uhe_actions/SubtitleActions';

class SsoProviders extends React.Component {
  constructor(props) {
    super(props);

    this.onPageChange = this.onPageChange.bind(this);

    this.tableFilterMap = {
      name: 'name',
      version: 'version',
    };

    this.data = [];
    this.columns = [];
    this.tableKeys = [
      'name',
      'version',
      'actions',
    ];

    this.filterTypes = {};
    this.tableKeys.forEach((value, index) => {
      const filter = this.filterTypes[value] || {};
      this.columns.push({
        title: (cellData) => (
          <ListingsTableInputFilter
            filterType={filter.type}
            filterOptions={filter.options}
            showFilter={
              !(
                value === 'actions'
              )
            }
            cellData={cellData}
            title={`uhe.table.${value}`}
            dataKey={value}
          />
        ),
        sorter: true,
        align: index > 3 ? 'center' : 'left',
        minWidth: 200,
        dataIndex: value,
        render: (content) => this.cellRenderer(content, value),
      });
    });

    this.history = this.props.history;
    this.qParams = new URLSearchParams(this.history.location.search);

    if (
      this.props.subtitle && this.props.subtitle.langId !== 'sso.title'
    ) {
      this.props.setSubtitle('sso.title');
    }

    this.onPageChange(1);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.qParams = new URLSearchParams(this.props.location.search);
      this.onPageChange(1);
    }
    if (prevProps.error !== this.props.error) {
      // handle system error
      if (this.props.error.code === 404) {
        this.props.history.push('/system/settings/sso-providers');
      }
      if (this.props.error === 409) {
        this.props.history.push('/system/settings/sso-providers');
      }
    }
  }

  /**
   * @description Change data after filtering
   * @param {number} page
   * @return {Void}
   */
  onPageChange(page) {
    this.currentPage = page - 1;
    const currSort = this.qParams.getAll('sort') || [];
    const sort = [];
    const filter = [];


    lodash.forOwn(this.tableFilterMap, (value, key) => {
      const filterParam = this.qParams.get(`${TABLE_FILTER_PREFIX}${key}`);
      const sorter = currSort.find(
        (element) => element === `${key},asc` || element === `${key},desc`,
      );

      if (filterParam) {
        if (
          this.filterTypes[key]
          && this.filterTypes[key].type === 'dropdown'
        ) {
          filter.push(`${value}=${encodeURIComponent(filterParam)}`);
        } else {
          filter.push(`${value}~=${encodeURIComponent(`%${filterParam}%`)}`);
        }
      }

      if (sorter) {
        sort.push(sorter.replace(key, value));
      }
    });

    this.filter = filter;
    this.props.ssoProvidersOnFetchData(page - 1, sort, filter);
  }

  /**
   * Adapt data returned by the server
   * @param  {Array<Object>} data
   * @return {Object}
   */
  dataAdapter(data = []) {
    const adaptedData = [];

    data.forEach((value, index) => {
      adaptedData.push({
        key: index,
        name: value.name,
        version: value.version,
        actions: {
          id: value.id,
        },
      });
    });
    return adaptedData;
  }

  /**
   * @description Renders cell
   * @param {string} content
   * @param {string} key
   * @return {JSX}
   */
  cellRenderer(content, key) {
    let cell;

    switch (key) {
      case 'actions':
        cell = (
          <OptionsCell
            intl={this.props.intl}
            content={content}
            editLink={`/system/settings/sso-providers/edit/${content.id}`}
            samlLink={`/system/settings/sso-providers/edit/${content.id}/saml`}
            domainLink={`/system/settings/sso-providers/edit/${content.id}/domains`}
            data={this.props.data}
            deleteAction={() => {
              this.props.deleteSsoProvider(
                content.id,
                this.currentPage,
                this.sort,
                this.filter,
              );
            }}
          />
        );
      break;
      default:
        cell = content;
    }
    return cell;
  }

  render() {
    const { pagination, loading } = this.props;
    pagination.onChange = this.onPageChange;
    return (
      <div className="dashboard configuration" loading={loading}>
        <div>
          <div className="uhe-table-header">
            <div className="buttons-container">
              <Link to={'/system/settings/sso-providers/new'} shape="circle" icon="+" className="page-icons page-icon-plus">
                <i className="icon icon-add"></i>
                <span><IntlMessages id="common.addText" /></span>
              </Link>
            </div>
            <p>
              <IntlMessages id="uhe.table.matchingResults" />
              <span>{this.props.pagination.total}</span>
            </p>
          </div>
          <div className="uhe-table">
            <Table
              bordered
              className="gx-table-responsive"
              columns={this.columns}
              dataSource={this.dataAdapter(this.props.data)}
              pagination={pagination}
              loading={this.props.loading}
            />
          </div>
        </div>
      </div>
    );
  }
}

SsoProviders.defaultProps = {
  data: [],
  loading: true,
  pagination: {
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  },
};

SsoProviders.propTypes = {
  data: PropTypes.array,
  ssoProvidersOnFetchData: PropTypes.func,
  setSubtitle: PropTypes.func,
};

/**
 * @description Map global state to props
 * @param {Object} subtitle
 * @return {Object}
 */
const mapStateToProps = ({ SsoProvidersSettings, subtitle, common }) => {
  const { content } = SsoProvidersSettings.table || { pageable: {}, content: [] };
  const { loading } = SsoProvidersSettings;
  const pagination = {
    total: SsoProvidersSettings.table.totalElements,
    current: SsoProvidersSettings.table.number + 1 || 0,
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  };

  return {
    data: content,
    pagination,
    loading,
    subtitle,
    error: common.error,
  };
};

/**
 * @description Map dispatch to props
 * @param {function} dispatch
 * @return {Object}
 */
const mapDispatchToProps = (dispatch) => ({
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  ssoProvidersOnFetchData: (
    page,
    sort,
    filter,
  ) => dispatch(ssoProvidersOnFetchData(
    page,
    sort,
    filter,
  )),
  deleteSsoProvider: (
    id,
    page,
    sorting,
    filter,
  ) => dispatch(deleteSsoProvider({
    id,
    page,
    sorting,
    filter,
  })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withRouter(SsoProviders)));
