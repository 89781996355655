import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import { Button, Card, Table } from 'antd';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import UheHelper from 'util/UheHelper';
import { shouldShowCustomerActionsCell } from '@util/UheRoleChecker';
import { injectIntl } from 'react-intl';

import ListingsTopFilter from '@filters/ListingsTopFilter';
import ListingsTableInputFilter from '@filters/ListingsTableInputFilter';
import OrganizationCell from '@components/tables/cells/OrganizationCell';
import CustomerCell from '@components/tables/cells/CustomerCell';
import ActionsCell from '@components/tables/cells/ActionsCell';
import NumberCell from '@components/tables/cells/NumberCell';
import ExportCsv from '@components/ExportCsv/ExportCsv';

import {
  customersOnFetchData,
  deleteCustomer,
  fetchInterpreterOptions,
} from '@uhe_actions/configuration/customers/CustomersActions';
import {
  LISTING_TABLES_PAGE_SIZE,
  TOP_FILTER_PREFIX,
  TABLE_FILTER_PREFIX,
  APP_PAGES_CONTEXT, IS_EDIT_PAGE_REGEX,
} from '@constants/UHESettings';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import { withLastLocation } from 'react-router-last-location';
import {
  getColumnSorting, setTableData, getCurrentSort, saveDefaultSortOnClick,
} from '@util/UheHelper';
import { shouldResetPaginationSuccess } from '@actions';
import { DownOutlined } from '@ant-design/icons';

/**
 * Renders customers page
 */
class Customers extends React.Component {
  /**
   * Adapts the data from the API
   * @param {Array} data of list
   * @returns {Array} formated list
   */
  static dataAdapter(data = []) {
    const adaptedData = [];

    data.forEach((value, index) => {
      if (value.organization) {
        adaptedData.push({
          key: index,
          organization: {
            id: value.organization.id,
            name: value.organization.name,
          },
          customer: {
            id: value.id,
            organizationId: value.organization.id,
            name: value.name,
          },
          clinicians: value.no_clinicians,
          interpreter: value.interpreter.name,
          numberOfBedsCarts: value.total_devices_active,
          advancedReports:
            value.advanced_reports.active === 1 ? (
              <IntlMessages id="common.on" />
            ) : (
              <IntlMessages id="common.off" />
            ),
          bedCartNo: value.total_endpoints + value.total_mobile_users,
          configuredApsNo: value.total_configured_endpoints,
          sbtDevicesNo: value.total_endpoints_sbt,
          byodNo: value.total_mobile_users,
          actions: {
            id: value.id,
          },
        });
      }
    });

    return adaptedData;
  }

  /**
   * Customers Constructor
   * @param {object} props Customers Props
   */
  constructor(props) {
    super(props);

    this.onPageChange = this.onPageChange.bind(this);
    this.setTableData = setTableData.bind(this);
    const {
      history,
      subtitle,
      setSubtitle,
      intl,
    } = this.props;

    this.state = {
      tableData: {
        organization: {
          currSort: 'asc',
          isClicked: false,
        },
        customer: {
          currSort: 'asc',
          isClicked: false,
        },
        interpreter: {
          currSort: 'asc',
          isClicked: false,
        },
      },
      showMore: 2,
    };

    this.data = [];
    this.columns = [];
    this.filterTypes = {
      advancedReports: {
        type: 'dropdown',
        options: [
          {
            value: '1',
            label: intl.formatMessage({ id: 'common.on' }),
          },
          {
            value: '0',
            label: intl.formatMessage({ id: 'common.off' }),
          },
        ],
      },
      interpreter: {
        type: 'dropdown',
        options: [],
      },
    };

    this.topFilterMap = {
      [`${TOP_FILTER_PREFIX}organization`]: 'organization.id',
      [`${TOP_FILTER_PREFIX}customer`]: 'id',
    };
    this.tableFilterMap = {
      organization: 'organization.name',
      customer: 'name',
      clinicians: 'no_clinicians',
      unit: 'unit.name',
      numberOfBedsCarts: 'total_devices_active',
      interpreter: 'interpreter.name',
      advancedReports: 'advanced_reports.active',
      branding: 'branding',
    };
    this.tableKeys = [
      'organization',
      'customer',
      'interpreter',
      'bedCartNo',
      'configuredApsNo',
      'sbtDevicesNo',
      'byodNo',
      'actions',
    ];

    const { location } = props;
    const { tableData } = this.state;
    this.tableKeys.forEach((value, index) => {
      const filter = this.filterTypes[value] || {};
      this.columns.push({
        title: (cellData) => (
          <ListingsTableInputFilter
            filterType={filter.type}
            filterOptions={filter.options}
            setTableData={this.setTableData}
            tableData={tableData}
            showFilter={
              !(
                value === 'actions'
                || value === 'bedCartNo'
                || value === 'configuredApsNo'
                || value === 'sbtDevicesNo'
                || value === 'byodNo'
                || value === 'iConsultNo'
                || value === 'iObserverNo'
              )
            }
            cellData={cellData}
            title={`uhe.table.${value}`}
            dataKey={value}
            triggerCharsNum={
              value === 'clinicians' || value === 'numberOfBedsCarts'
                ? 0
                : undefined
            }
          />
        ),
        sorter: (value === 'actions'
          || value === 'bedCartNo'
          || value === 'iConsultNo'
          || value === 'iObserverNo'
          || value === 'configuredApsNo'
          || value === 'sbtDevicesNo'
          || value === 'byodNo')
          ? false : { multiple: index },
        defaultSortOrder: (value) ? getColumnSorting(value, location) : false,
        align: index > 3 ? 'center' : 'left',
        minWidth: 200,
        dataIndex: value,
        render: (content) => this.cellRenderer(content, value),
      });
    });

    this.topFilters = [
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byOrganization',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'organization',
      },
    ];

    if (
      subtitle && subtitle.langId !== 'customers.title'
    ) {
      setSubtitle('customers.title');
    }

    this.history = history;
    this.qParams = new URLSearchParams(this.history.location.search);
    this.defaultSorted = true;
    this.setTableData = setTableData.bind(this);
    this.tableFooterRef = React.createRef();
  }

  /**
   * componentDidMount() is invoked immediately after
   * a component is mounted (inserted into the tree)
   * @returns {void} void
   */
  componentDidMount() {
    this.tableFooterRef.current.parentNode.parentNode.addEventListener('scroll', this.handleScroll, true);

    const {
      lastLocation,
      pagination,
      shouldResetPaginationFlag,
      resetPaginationSuccess,
      fetchInterpreterOptions,
    } = this.props;
    fetchInterpreterOptions();
    if (!IS_EDIT_PAGE_REGEX.test(lastLocation?.pathname) || shouldResetPaginationFlag) {
      this.onPageChange(1);
      resetPaginationSuccess();
      return;
    }

    this.onPageChange(pagination?.current || 1);
  }

  /**
   * Updates table on location change
   * @param {Object} prevProps previous props
   * @returns {void} void
   */
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.search !== prevProps.location.search) {
      this.qParams = new URLSearchParams(location.search);
      this.onPageChange(1);
    }
    const { interpreterOptions } = this.props;
    if (prevProps.interpreterOptions !== interpreterOptions) {
      this.filterTypes.interpreter.options = interpreterOptions.list.map((option) => ({
        value: option.name,
        label: option.name,
      }));
      this.forceUpdate();
    }
  }

  /**
   * Remove Event Listener
   * @returns {void}
   */
  componentWillUnmount() {
    this.tableFooterRef.current.parentNode.parentNode.removeEventListener('scroll', this.handleScroll);
  }

  /**
   * onPageChange handler
   * @param  {number} page page
   * @return {void}
   */
  onPageChange(page) {
    const { customersOnFetchData } = this.props;

    this.currentPage = page - 1;
    const currSort = this.qParams.getAll('sort') || [];
    const filter = [];
    const defaultSort = ['organization.name,asc', 'name,asc', 'interpreter.name,asc'];

    lodash.forOwn(this.topFilterMap, (value, key) => {
      const filterParam = this.qParams.get(key);
      if (filterParam) {
        filter.push(`${value}=${filterParam}`);
      }
    });

    lodash.forOwn(this.tableFilterMap, (value, key) => {
      const filterParam = this.qParams.get(`${TABLE_FILTER_PREFIX}${key}`);
      if (filterParam) {
        if (
          this.filterTypes[key]
          && this.filterTypes[key].type === 'dropdown'
        ) {
          filter.push(`${value}=${encodeURIComponent(filterParam)}`);
        } else {
          filter.push(`${value}~=${encodeURIComponent(`%${filterParam}%`)}`);
        }
      }
    });

    let sort = getCurrentSort(currSort, this.tableFilterMap);

    if (this.defaultSorted && currSort.length === 0) {
      sort = defaultSort;
    } else if (currSort.length) {
      const customSort = getCurrentSort(currSort, this.tableFilterMap);
      const nonCustomFilteredColumns = saveDefaultSortOnClick(defaultSort, customSort);
      sort = [...customSort, ...nonCustomFilteredColumns];
      this.defaultSorted = false;
    }

    this.filter = filter;
    this.sort = sort;

    this.setState({ showMore: page + 1 });

    customersOnFetchData(this.currentPage, sort, filter);
  }

  /**
   * Renders cell
   * @param {string} content cell content
   * @param {string} key cell key
   * @return {JSX} structured cell
   */
  cellRenderer(content, key) {
    let cell;
    const { intl, loggedUser } = this.props;

    switch (key) {
      case 'organization':
        cell = <OrganizationCell content={content} />;
        break;
      case 'customer':
        cell = <CustomerCell content={content} showIco={false} />;
        break;
      case 'actions':
        cell = (
          <ActionsCell
            page={APP_PAGES_CONTEXT.customers}
            loggedUser={loggedUser}
            intl={intl}
            content={content}
            editLink={`/configuration/customers/edit/${content.id}`}
            deleteAction={() => {
              this.props.deleteCustomer(
                content.id,
                this.currentPage,
                this.sort,
                this.filter,
              );
            }}
            bulkLink={`/configuration/customers/edit/${content.id}/bulk-actions`}
          />
        );
        break;
      default:
        cell = <NumberCell content={content} />;
    }

    return cell;
  }

  /**
  * Render Add Button
  * @returns {JSX} add button
  */
  renderAddButton = () => {
    const { loggedUser } = this.props;
    const { isCaregilitySystemAdmin, isOrganizationAdmin } = loggedUser;

    if (isCaregilitySystemAdmin || isOrganizationAdmin) {
      return (
        <Link
          to="/configuration/customers/new"
          shape="circle"
          icon="+"
          className="page-icons page-icon-plus"
        >
          <i className="icon icon-add" />
          <span>
            <IntlMessages id="common.addText" />
          </span>
        </Link>
      );
    }

    return null;
  }

  /**
  * Render Export Csv Button
  * @returns {JSX} export button
  */
  renderExportCsvButton = () => {
    const { loggedUser } = this.props;
    const { isCaregilitySystemAdmin, isCaregilityAdmin, isOrganizationAdmin } = loggedUser;

    if (isCaregilitySystemAdmin || isCaregilityAdmin || isOrganizationAdmin) {
      return (
        <ExportCsv
          getCsvUrl={UheHelper.getCsvUrl(
            this.filter,
            this.sorting,
            ENDPOINTS.ExportCsv.customers,
          )}
        />
      );
    }
  }

  /**
  * Handles horizontal scroll in table
  * @param {event} event table
  * @returns {void}
  */
  handleScroll = (event) => {
    const currentScroll = event.target.scrollLeft;
    if (this.tableFooterRef.current) {
      this.tableFooterRef.current.parentNode.style.transform = `translateX(${currentScroll}px)`;
    }
  };

  /**
  * Render table
  * @returns {JSX} table
  */
  renderTable = () => {
    const {
      pagination, data, loading, loggedUser,
    } = this.props;
    const { showMore } = this.state;
    pagination.onChange = this.onPageChange;

    if (loggedUser.roles) {
      const hasAdminPermission = shouldShowCustomerActionsCell(loggedUser);

      if (hasAdminPermission) {
        if (this.columns.length <= 5) {
          const foundActions = this.tableKeys.filter(
            (key) => key === 'actions',
          );

          if (foundActions.length === 0) {
            this.tableKeys.push('actions');
          }
          const { location } = this.props;
          const { tableData } = this.state;
          this.tableKeys.filter((key) => key === 'actions').forEach((value, index) => {
            const filter = this.filterTypes[value] || {};
            this.columns.push({
              title: (cellData) => (
                <ListingsTableInputFilter
                  filterType={filter.type}
                  filterOptions={filter.options}
                  setTableData={this.setTableData}
                  tableData={tableData}
                  showFilter={
                    !(
                      value === 'actions'
                      || value === 'numberOfBedsCarts'
                      || value === 'clinicians'
                    )
                  }
                  cellData={cellData}
                  title={`uhe.table.${value}`}
                  dataKey={value}
                  triggerCharsNum={
                    value === 'clinicians' || value === 'numberOfBedsCarts'
                      ? 0
                      : undefined
                  }
                />
              ),
              sorter: (value === 'actions') ? false : { multiple: index },
              defaultSortOrder: (value) ? getColumnSorting(value, location) : false,
              align: index > 3 ? 'center' : 'left',
              minWidth: 200,
              dataIndex: value,
              render: (content) => this.cellRenderer(content, value),
            });
          });
        }
      }

      return (
        <Table
          bordered
          className="gx-table-responsive custom-scrollbar"
          columns={this.columns}
          dataSource={Customers.dataAdapter(data)}
          pagination={false}
          loading={loading}
          footer={() => (
            <div className="table-footer" ref={this.tableFooterRef}>
              <Button type="text" onClick={() => this.onPageChange(showMore)}>
                <IntlMessages id="common.showMore" />
                <DownOutlined />
              </Button>
            </div>
          )}
        />
      );
    }
  }

  /**
   * Renders Customers Component
   * @returns {JSX.Element} Customers Component
   */
  render() {
    const { pagination } = this.props;
    pagination.onChange = this.onPageChange;

    return (
      <div className="dashboard configuration">
        <div className="customers-filter-box">
          <Card
            className="filter-boxes gx-card"
            title={<IntlMessages id="uhe.title.filters" />}
          >
            <ListingsTopFilter filters={this.topFilters} />
          </Card>
        </div>
        <div>
          <div className="uhe-table-header">
            <div className="buttons-container">
              {this.renderAddButton()}
              {this.renderExportCsvButton()}
            </div>
            <p>
              <IntlMessages id="uhe.table.matchingResults" />
              <span>{pagination.total}</span>
            </p>
          </div>
          <div className="uhe-table">
            {this.renderTable()}
          </div>
        </div>
      </div>
    );
  }
}

Customers.defaultProps = {
  data: [],
  loading: true,
  pagination: {
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  },
  showIco: false,
  lastLocation: {
    pathname: '/',
    search: '',
    hash: '',
    state: undefined,
    key: '',
  },
  fetchInterpreterOptions: undefined,
  shouldResetPaginationFlag: false,
};

Customers.propTypes = {
  data: PropTypes.array,
  location: PropTypes.object,
  history: PropTypes.object,
  subtitle: PropTypes.object,
  pagination: PropTypes.object,
  intl: PropTypes.object,
  loading: PropTypes.bool,
  setSubtitle: PropTypes.func,
  customersOnFetchData: PropTypes.func,
  interpreterOptions: PropTypes.func.isRequired,
  lastLocation: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: undefined,
    key: PropTypes.string,
  }),
  fetchInterpreterOptions: PropTypes.func,
  shouldResetPaginationFlag: PropTypes.bool,
  resetPaginationSuccess: PropTypes.func.isRequired,
};
/**
 * mapStateToProps function
 * @param {Object} ConfigurationCustomers object
 * @param {Object} subtitle object
 * @param {Object} ConfigurationUsers object
 * @param {Object} common object
 * @returns {Object} object
 */
const mapStateToProps = ({
  ConfigurationCustomers, subtitle, ConfigurationUsers, common,
}) => {
  const { list, page } = ConfigurationCustomers.table || {};
  const { loading, interpreterOptions } = ConfigurationCustomers;
  const { shouldResetPagination } = common;

  const pagination = {
    total: page.totalElements || 0,
    current: page.number + 1 || 0,
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  };

  return {
    data: list,
    pagination,
    loading,
    subtitle,
    loggedUser: ConfigurationUsers.ownUser,
    interpreterOptions,
    shouldResetPaginationFlag: shouldResetPagination,
  };
};

/**
 * mapDispatchToProps function
 * @param {Function} dispatch function
 * @returns {Object} object
 */
const mapDispatchToProps = (dispatch) => ({
  deleteCustomer: (id, page, sorting, filter) => dispatch(deleteCustomer({
    id, page, sorting, filter,
  })),
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  customersOnFetchData: (page, sort, filter) => dispatch(customersOnFetchData(page, sort, filter)),
  fetchInterpreterOptions: () => dispatch(fetchInterpreterOptions()),
  resetPaginationSuccess: () => dispatch(shouldResetPaginationSuccess()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withLastLocation(withRouter(Customers))));
