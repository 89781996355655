import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Cascader, Form } from 'antd';
import { injectIntl } from 'react-intl';
import { onGetOptions, fetchOptionsSuccess, resetFilters } from '@uhe_actions/filters/ListingsTopFilterActions';
import { SearchOutlined, DownOutlined } from '@ant-design/icons';
import { removeSearchIcon } from '@util/UheHelper';
import IntlMessages from '@util/IntlMessages';

/**
 * FilterDropdowns class component
 */
class FilterDropdowns extends React.Component {
  formRef = React.createRef();

  /**
   * FilterDropdowns Constructor
   * @param {object} props Dropdowns Props
   */
  constructor(props) {
    super(props);

    this.selectedFilterVals = {};
    this.clearFiltersOnSelectionChange = this.clearFiltersOnSelectionChange.bind(this);
    this.setInitFilter = this.setInitFilter.bind(this);
    this.updateValuesByFilters = this.updateValuesByFilters.bind(this);

    this.state = {
      selectedFilterVals: {},
      focusCascader: {
        organization: false,
        customer: false,
        facility: false,
      },
    };

    this.setInitFilter();
  }

  /**
   * Handle onChange event
   * @param  {Array<string>} value value
   * @param  {number} index index
   * @return {void}
   */
  onFilterChange(value, index) {
    const { filters } = this.props;
    const { key } = filters[index];

    if (!value.length) {
      const selVals = { ...this.state.selectedFilterVals };
      delete selVals[key];
      this.setState({ selectedFilterVals: { ...selVals } });
    }
    this.setState((prevState) => ({
      focusCascader: {
        ...prevState.focusCascader,
        [key]: false,
      },
    }));

    this.updateValuesByFilters(value, index);
  }

  /**
   * Set initial filter
   * @return {void}
   */
  setInitFilter() {
    const { filters, onGetOptions } = this.props;
    onGetOptions(filters[0].key);
  }

  /**
   * Handles What Icon to Show in Cascade Menu - Expand or Search
   * @param {string} field field type
   * @returns {func} manipulate the state
   */
  changeDropdownExpandIcon = (field) => {
    const { focusCascader: { organization, customer, facility }, focusCascader } = this.state;
    switch (field) {
      case 'organization':
        return this.setState({ focusCascader: { ...focusCascader, organization: !organization } });
      case 'customer':
        return this.setState({ focusCascader: { ...focusCascader, customer: !customer } });
      case 'facility':
        return this.setState({ focusCascader: { ...focusCascader, facility: !facility } });
      default:
    }
  }

  /**
   * Clear all filters after the selected change
   *
   * @param  {number} filterIndex filterIndex
   * @param {filters} filters filters
   * @param  {Array<string>} selectedFilters selectedFilters
   * @return {void}
   */

  clearFiltersOnSelectionChange(filterIndex, filters, selectedFilters) {
    const { selectedFilterVals } = this.state;
    if (selectedFilterVals.customer && selectedFilterVals.facility && filterIndex === 0) {
      this.formRef.current.setFieldsValue({
        facility: null,
        customer: null,
      });
    } else if (selectedFilterVals.customer && filterIndex === 0) {
      this.formRef.current.setFieldsValue({
        customer: null,
      });
    } else if (selectedFilterVals.facility && filterIndex === 1) {
      this.formRef.current.setFieldsValue({
        facility: null,
      });
    }
    for (let i = filterIndex + 1; i < filters.length; i++) {
      delete selectedFilters[filters[i].key];
    }
  }

  /**
   * Triggered when input is cleared or option is selected
   *
   * @param  {Array<string>} value value
   * @param  {number} filterIndex filterIndex
   * @return {void}
   */
  updateValuesByFilters(value, filterIndex) {
    const { filters, onChangeParent, onGetOptions } = this.props;

    const selectedFilter = filters[filterIndex];
    const selectedFilters = { ...this.state.selectedFilterVals };
    selectedFilters[selectedFilter.key] = value;
    this.clearFiltersOnSelectionChange(filterIndex, filters, selectedFilters);
    this.setState({ selectedFilterVals: { ...selectedFilters } });

    onChangeParent({ name: selectedFilter.key, id: selectedFilters[selectedFilter.key][0] });
    const filter = filters[filterIndex + 1] || null;
    this.isFilterActive();
    if (filter && value[0]) {
      onGetOptions(filter.key, value[0]);
    }
  }

  /**
   * Returns true if any of the widgets filters are active
   * @return {Boolean} true/false
   */
  isFilterActive() {
    const { selectedFilterVals } = this.state;
    const activeFilters = Object.values(selectedFilterVals).reduce((active, filter) => {
      if (filter) {
        active += active + 1;
      }
      return active;
    }, 0);

    return activeFilters > 0;
  }

  /**
   * Renders input titles
   * @param {Object} config config
   * @returns {JSX} jsx
   */
  renderInputTitles(config) {
    let field;
    const { intl } = this.props;

    if (config.key === 'facility') {
      field = intl.formatMessage({ id: config.placeholder });
    } else {
      field = intl.formatMessage({ id: config.placeholder });
    }

    return field;
  }

  /**
   * Renders filter inputs
   * @return {Array<ReactElement>|null} Array
   */
  renderFilterDropdowns() {
    const { filters, optionsList, intl } = this.props;
    const { selectedFilterVals, focusCascader } = this.state;

    if (!filters || !Array.isArray(filters)) {
      return null;
    }

    const inputs = [];

    filters.forEach((config, index) => {
      /**
       * Handle Dropdown Menu Disability
       * @param {string} key id-key for specific field
       * @returns {Boolean} Boolean value for disabled property
       */
      const checkDisabled = (key) => {
        const { organization, customer } = selectedFilterVals;
        const customerDisabled = !(organization && organization.length > 0);
        const facilityDisabled = !(customer && customer.length > 0);
        switch (key) {
          case 'organization':
            return false;
          case 'customer':
            return customerDisabled;
          case 'facility':
            return facilityDisabled;
          default:
            return true;
        }
      };

      inputs.push(
        <Form.Item
          colon={false}
          key={config.key}
          className="gx-d-flex gx-align-items-center"
          name={config.key}
          label={this.renderInputTitles(config)}
          rules={[
            {
              required: true,
              message: <IntlMessages id="configuration.users.emptyField" />,
            },
          ]}
          labelCol={{
            xl: { span: 8 },
          }}
          wrapperCol={{
            xl: { span: 16 },
          }}
        >
          <Cascader
            getPopupContainer={(event) => event.parentNode}
            dropdownClassName="top-filter-popup top-filter-dropdown"
            suffixIcon={focusCascader[config.key] && !checkDisabled(config.key) ? <SearchOutlined /> : <DownOutlined />}
            onClick={() => this.changeDropdownExpandIcon(config.key)}
            fieldNames={config.fieldNames}
            key={config.key}
            expandTrigger="hover"
            size="large"
            changeOnSelect
            value={selectedFilterVals[config.key]}
            options={optionsList[config.key] || []}
            onChange={(value) => this.onFilterChange(value, index)}
            placeholder={intl.formatMessage({ id: 'configuration.customer.please_select' })}
            showSearch={config.showSearch}
            disabled={checkDisabled(config.key)}
            onBlur={() => this.setState({ focusCascader: removeSearchIcon() })}
          />
        </Form.Item>,
      );
    });

    return inputs;
  }

  /**
   * Renders the component
   * @return {ReactElement} element
   */
  render() {
    return (
      <div className="filterDropdowns">
        <Form ref={this.formRef}>
          {this.renderFilterDropdowns()}
        </Form>
      </div>
    );
  }
}

FilterDropdowns.defaultProps = {
  cssClass: '',
};

FilterDropdowns.propTypes = {
  fieldNames: PropTypes.shape(),
  intl: PropTypes.shape().isRequired,
  filters: PropTypes.array.isRequired,
  cssClass: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  editMode: PropTypes.bool.isRequired,
  onChangeParent: PropTypes.func.isRequired,
  onGetOptions: PropTypes.func.isRequired,
};

const mapStateToProps = ({ listingsTopFilter }) => ({ optionsList: listingsTopFilter });

export default connect(mapStateToProps, {
  onGetOptions,
  fetchOptionsSuccess,
  resetFilters,
})(injectIntl(withRouter(FilterDropdowns)));
