import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';
import {
  Row, Col, Table, Card, Button,
} from 'antd';
import lodash from 'lodash';

import { developerOnFetchData } from '@uhe_actions/system/DeveloperActions';
import ListingsTopFilter from '@filters/ListingsTopFilter';
import ListingsTableInputFilter from '@filters/ListingsTableInputFilter';
import OrganizationCell from '@components/tables/cells/OrganizationCell';
import CustomerCell from '@components/tables/cells/CustomerCell';
import PropTypes from 'prop-types';
import {
  TOP_FILTER_PREFIX,
  LISTING_TABLES_PAGE_SIZE,
  TABLE_FILTER_PREFIX,
  IS_EDIT_PAGE_REGEX,
} from '@constants/UHESettings';
import URLSearchParams from 'url-search-params';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import { shouldResetPaginationSuccess } from '@actions';
import { withLastLocation } from 'react-router-last-location';
import { getCurrentSort } from '@util/UheHelper';
import { DownOutlined } from '@ant-design/icons';

/**
 * @description Renders developers table
 */
class Developer extends Component {
  /**
   * @description Adapt data from the API
   * @param  {Array} data
   * @returns {Array}
   */
  static dataAdapter(data = []) {
    const adaptedData = [];

    data.forEach((value, index) => {
      adaptedData.push({
        key: index,
        organization: {
          name: value.customer.organization.name,
          id: value.customer.organization.id,
        },
        customer: {
          name: value.customer.name,
          id: value.customer.id,
        },
        apiUsername: value.api_username,
        apiPassword: value.api_password,
        apiEncryptKey: value.api_encrypt_key,
      });
    });

    return adaptedData;
  }

  /**
   * @description Renders a cell from the developers table
   * @param {string} content
   * @param {string} key
   * @returns {JSX}
   */
  static cellRenderer(content, key) {
    let cell;

    switch (key) {
      case 'organization':
        cell = <OrganizationCell content={content} />;
        break;
      case 'customer':
        cell = <CustomerCell content={content} />;
        break;
      default:
        cell = content;
    }

    return cell;
  }

  constructor(props) {
    super(props);

    this.onPageChange = this.onPageChange.bind(this);

    this.data = [];
    this.columns = [];
    this.filterTypes = {};

    const { history, subtitle } = this.props;

    this.topFilters = [
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byOrganization',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'organization',
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byCustomer',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'customer',
      },
    ];

    this.topFilterMap = {
      [`${TOP_FILTER_PREFIX}organization`]: 'customer.organization.id',
      [`${TOP_FILTER_PREFIX}customer`]: 'customer.id',
    };

    this.tableFilterMap = {
      organization: 'customer.organization.name',
      customer: 'customer.name',
      apiUsername: 'api_username',
      apiPassword: 'api_password',
      apiEncryptKey: 'api_encrypt_key',
    };

    this.tableKeys = [
      'organization',
      'customer',
      'apiUsername',
      'apiPassword',
      'apiEncryptKey',
    ];

    this.tableKeys.forEach((value, index) => {
      this.columns.push({
        title: (cellData) => (
          <ListingsTableInputFilter
            cellData={cellData}
            title={`uhe.table.${value}`}
            dataKey={value}
            disable={false}
          />
        ),
        sorter: { multiple: index },
        defaultSortOrder: (value) ? this.checkSortTableOrder() : false,
        align: 'left',
        minWidth: 200,
        dataIndex: value,
        render: (content) => Developer.cellRenderer(content, value),
      });
    });

    if (
      subtitle && subtitle.langId !== 'developer.title'
    ) {
      this.props.setSubtitle('developer.title');
    }

    this.history = history;
    this.qParams = new URLSearchParams(this.history.location.search);
    this.defaultSorted = true;
    this.state = {
      showMore: 2,
    };
  }

  /**
   * componentDidMount() is invoked immediately after
   * a component is mounted (inserted into the tree)
   * @returns {void} void
   */
  componentDidMount() {
    const {
      lastLocation,
      pagination,
      shouldResetPaginationFlag,
      resetPaginationSuccess,
    } = this.props;

    if (!IS_EDIT_PAGE_REGEX.test(lastLocation?.pathname) || shouldResetPaginationFlag) {
      this.onPageChange(1);
      resetPaginationSuccess();
      return;
    }

    this.onPageChange(pagination?.current || 1);
  }

  /**
   * @description Update table when using filter
   * @param {Object} prevProps
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.search !== prevProps.location.search) {
      this.qParams = new URLSearchParams(location.search);
      this.onPageChange(1);
    }
  }

  /**
   * @description Handle pagination and changes data after filtering
   * @param  {number} page
   * @returns {void}
   */
  onPageChange(page) {
    const { developerOnFetchData } = this.props;
    this.currentPage = page - 1;
    const currSort = this.qParams.getAll('sort') || [];
    const filter = [];

    lodash.forOwn(this.topFilterMap, (value, key) => {
      const filterParam = this.qParams.get(key);

      if (filterParam) {
        filter.push(`${value}=${filterParam}`);
      }
    });

    lodash.forOwn(this.tableFilterMap, (value, key) => {
      const filterParam = this.qParams.get(`${TABLE_FILTER_PREFIX}${key}`);

      if (filterParam) {
        if (
          this.filterTypes[key]
          && this.filterTypes[key].type === 'dropdown'
        ) {
          filter.push(`${value}=${encodeURIComponent(filterParam)}`);
        } else {
          filter.push(`${value}~=${encodeURIComponent(`%${filterParam}%`)}`);
        }
      }
    });

    let sort = getCurrentSort(currSort, this.tableFilterMap);

    if (this.defaultSorted && currSort.length === 0) {
      sort = ['customer.organization.name,asc', 'customer.name,asc', 'api_username,asc', 'api_password,asc', 'api_encrypt_key,asc'];
    } else {
      this.defaultSorted = false;
    }

    this.filter = filter;
    this.sort = sort;
    this.setState({ showMore: page + 1 });

    developerOnFetchData(this.currentPage, sort, filter);
  }

  /**
   * @description Check the default sort order for table
   * @returns {string}
   */
  checkSortTableOrder() {
    const { location } = this.props;
    let checkOrder = location.search.split('%2C')[1];

    if (checkOrder === 'asc') {
      checkOrder = 'ascend';
    }
    if (checkOrder === 'desc') {
      checkOrder = 'descend';
    }
    if (!checkOrder) {
      checkOrder = false;
    }

    return checkOrder;
  }

  /**
   * Render Developer Component
   * @returns {JSX} Developer Component
   */
  render() {
    const { pagination, data, loading } = this.props;
    const { showMore } = this.state;
    pagination.onChange = this.onPageChange;

    return (
      <div className="manage-customer-wrapper system dashboard configuration">
        <div className="customers-filter-box">
          <Card
            className="filter-boxes gx-card"
            title={<IntlMessages id="uhe.title.filters" />}
          >
            <ListingsTopFilter filters={this.topFilters} />
          </Card>
        </div>
        <div className="uhe-table-header developer">
          <p>
            <IntlMessages id="uhe.table.matchingResults" />
            <span>{pagination.total}</span>
          </p>
        </div>
        <Row gutter={16}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <div className="uhe-table">
              <Table
                bordered
                dataSource={Developer.dataAdapter(data)}
                columns={this.columns}
                loading={loading}
                pagination={false}
                className="gx-table-responsive"
                footer={() => (
                  <div className="table-footer">
                    <Button type="text" onClick={() => this.onPageChange(showMore)}>
                      <IntlMessages id="common.showMore" />
                      <DownOutlined />
                    </Button>
                  </div>
                )}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

Developer.defaultProps = {
  data: [],
  loading: true,
  pagination: {
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  },
  lastLocation: {
    pathname: '/',
    search: '',
    hash: '',
    state: undefined,
    key: '',
  },
  shouldResetPaginationFlag: false,
};

Developer.propTypes = {
  data: PropTypes.array,
  pagination: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  subtitle: PropTypes.object,
  loading: PropTypes.bool,
  developerOnFetchData: PropTypes.func,
  setSubtitle: PropTypes.func,
  lastLocation: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: undefined,
    key: PropTypes.string,
  }),
  shouldResetPaginationFlag: PropTypes.bool,
  resetPaginationSuccess: PropTypes.func.isRequired,
};

const mapStateToProps = ({ SystemDeveloper, subtitle, common }) => {
  const { list, page } = SystemDeveloper.table || {};
  const { loading } = SystemDeveloper;
  const { shouldResetPagination } = common;
  const pagination = {
    total: page.totalElements || 0,
    current: page.number + 1 || 0,
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  };

  return {
    data: list,
    loading,
    pagination,
    subtitle,
    shouldResetPaginationFlag: shouldResetPagination,
  };
};

const mapDispatchToProps = (dispatch) => ({
  developerOnFetchData: (page, sort, filter) => dispatch(developerOnFetchData(page, sort, filter)),
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  resetPaginationSuccess: () => dispatch(shouldResetPaginationSuccess()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withLastLocation(withRouter(Developer))));
