import React from 'react';

const InCallCell = ({ content }) => {
  return (
    <div className="uhe-table-td">
      <span className="in-call">
        {content}
      </span>
    </div>
  );
};

export default InCallCell;
