import React, { memo, useRef } from 'react';
import { useIntl } from 'react-intl';
import {
  Modal, Form, Button, Upload, Row, Col,
} from 'antd';
import { UploadOutlined, ExclamationCircleTwoTone } from '@ant-design/icons';

const layout = {
  labelCol: {
    span: 12,
  },
  wrapperCol: {
    span: 12,
  },
};

/**
 * Renders Update Network Modal
 * @returns {React.MemoExoticComponent} React.MemoExoticComponent
 */
const UpdateNetworkCertificateModal = memo(({
  getCertContent, onOk, onCancel, visible,
}) => {
  const intl = useIntl();
  const formRef = useRef();

  return (
    <Modal
      className="update-certificate-modal, dark-mode-modal "
      visible={visible}
      onOk={() => {
        onOk();
        formRef.current.validateFields();
      }}
      onCancel={onCancel}
      autoFocusButton="cancel"
    >
      <Row gutter={16} className="gx-my-4">
        <Col lg={2}>
          <ExclamationCircleTwoTone className="modal__icon modal__icon--warning" twoToneColor="#fa8c16" />
        </Col>
        <Col lg={22}>
          {intl.formatMessage({ id: 'configuration.bedsCarts.actions.updateCertificateMessage' })}
        </Col>
      </Row>
      <Form ref={formRef} {...layout}>
        <Form.Item
          colon={false}
          name="crt"
          className="gx-d-flex gx-align-items-center"
          label={intl.formatMessage({ id: 'configuration.customer.selectCrtCertificate' })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'configuration.customer.selectCertificateMessage' }),
            },
          ]}
        >
          <Upload
            beforeUpload={getCertContent('crtContent')}
            accept=".crt"
            showUploadList={{
              showRemoveIcon: false,
            }}
          >
            <Button
              icon={<UploadOutlined />}
            >
              {intl.formatMessage({ id: 'sidebar.dataEntry.upload' })}
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item
          colon={false}
          name="pem"
          className="gx-d-flex gx-align-items-center"
          label={intl.formatMessage({ id: 'configuration.customer.selectPemCertificate' })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'configuration.customer.selectCertificateMessage' }),
            },
          ]}
        >
          <Upload
            beforeUpload={getCertContent('pemContent')}
            accept=".pem"
            showUploadList={{
              showRemoveIcon: false,
            }}
          >
            <Button
              icon={<UploadOutlined />}
            >
              {intl.formatMessage({ id: 'sidebar.dataEntry.upload' })}
            </Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default UpdateNetworkCertificateModal;
