import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Card, Input, Cascader, Form,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';

import EditHeader from '@components/uhe/configuration/EditHeader';
import { FORM_LAYOUT } from '@constants/UHESettings';
import {
  fetchDeviceTypes,
  clearBedCart,
  addBedCart,
} from '@uhe_actions/configuration/bedsCarts/bedsCartsActions';
import { onGetOptions } from '@uhe_actions/filters/ListingsTopFilterActions';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import {
  goBackTo,
  cascaderSearchFilter,
  cascaderDeviceSearchFilter,
  removeSearchIcon,
}
  from '@util/UheHelper';
import { withLastLocation } from 'react-router-last-location';

const defaultDeviceType = 'Standards Based';

/**
 * CreateBedCart Class Component
 */
class CreateBedCart extends Component {
  formRef = React.createRef();

  /**
   * CreateBedCart Constructor
   * @param {*} props Props
   */
  constructor(props) {
    super(props);
    const { setSubtitle, location, intl } = this.props;
    this.intl = intl;
    this.isNew = location.pathname.indexOf('/new') !== -1;
    this.isUheUnit = location.pathname.indexOf('/uhe-units/new') !== -1;

    this.isUheUnit
      ? setSubtitle('configuration.bedsCarts.addNewTitle')
      : setSubtitle('nonCareDevices.titles.new');

    this.state = {
      organization_id: null,
      organization_name: '',
      customer_id: null,
      customer_name: '',
      facility_id: null,
      facility_name: '',
      unit_id: null,
      unit_name: '',
      name: '',
      endpoint_type: 'Standards Based',
      deviceTypes: [],
      focusCascader: {
        organization: false,
        customer: false,
        facility: false,
        unit: false,
        deviceTypes: false,
      },
    };

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeDeviceType = this.onChangeDeviceType.bind(this);
    this.onChangeOrganization = this.onChangeOrganization.bind(this);
    this.onChangeCustomer = this.onChangeCustomer.bind(this);
    this.onChangeFacility = this.onChangeFacility.bind(this);
    this.onChangeUnit = this.onChangeUnit.bind(this);
    this.addNewBedCart = this.addNewBedCart.bind(this);
    this.redirectOnNewBedCart = this.redirectOnNewBedCart.bind(this);

    /**
     * @description Return to previous page
     * @returns {void}
     */
    this.goBack = goBackTo(this.props.location.pathname.indexOf('beds-carts/uhe-units') !== -1
      ? '/configuration/beds-carts/uhe-units'
      : '/configuration/beds-carts/non-uhe-units', this.props);
  }

  componentDidMount() {
    this.props.clearBedCart();
    this.props.fetchDeviceTypes();
    this.props.onGetOrganizationOptions();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.deviceTypes.length === 0
      && this.props.deviceTypes.length > 0
    ) {
      this.setState({
        deviceTypes: this.props.deviceTypes,
      });
    }
  }

  /**
   * Render title, back and save buttons
   * @returns {JSX} EditHeader Component
   */
  renderHeadLine() {
    const { lastLocation, history } = this.props;
    return (
      <EditHeader
        goBack={goBackTo(this.props.location.pathname.indexOf('beds-carts/uhe-units') !== -1
          ? '/configuration/beds-carts/uhe-units'
          : '/configuration/beds-carts/non-uhe-units', lastLocation?.pathname, history)}
        save={this.addNewBedCart}
        loading={this.props.loading}
      />
    );
  }

  /**
   * @description Handle Changes on Device Types Dropdown and Updates Local State
   * @param {array} device
   * @returns {void}
   */
  onChangeDeviceType(device) {
    this.setState({ endpoint_type: device[0] });
    this.setState((prevState) => ({
      focusCascader: {
        ...prevState.focusCascader,
        deviceType: false,
      },
    }));
  }

  /**
   * @description Handle Changes
   * @param {Object} event
   */
  onChangeInput(event) {
    this.setState({
      name: event.target.value,
    });
  }

  /**
   * Handle Changes in the Organizations Cascader and Updates Local State
   * @param {array} index Array of Numbers(IDs)
   * @param {number} id Organization ID
   * @param {string} name Organization Name
   * @returns {void}
   */
  onChangeOrganization(index, [{ id, name } = {}] = []) {
    const { onGetCustomerOptions } = this.props;

    if (id) {
      onGetCustomerOptions(id);
    }

    this.formRef.current.setFieldsValue({
      organization: [id].filter((value) => !!value),
      customer: null,
      facility: null,
      unit: null,
    });
    this.formRef.current.validateFields(['organization']);

    this.setState(
      {
        organization_id: id,
        organization_name: name,
        customer_id: null,
        facility_id: null,
        unit_id: null,
      },
    );
    this.setState((prevState) => ({
      focusCascader: {
        ...prevState.focusCascader,
        organization: false,
      },
    }));
  }

  /**
   * Handle Changes in the Customers Cascader and Updates Local State
   * @param {array} index Array of Numbers(IDs)
   * @param {number} id Organization ID
   * @param {string} name Organization Name
   * @returns {void}
   */
  onChangeCustomer(index, [{ id, name } = {}] = []) {
    const { onGetFacilityOptions } = this.props;

    if (id) {
      onGetFacilityOptions(id);
    }

    this.formRef.current.setFieldsValue({
      customer: [id].filter((value) => !!value), facility: null, unit: null,
    });
    this.formRef.current.validateFields(['customer']);

    this.setState(
      {
        customer_id: id,
        customer_name: name,
        facility_id: null,
        unit_id: null,
      },
    );
    this.setState((prevState) => ({
      focusCascader: {
        ...prevState.focusCascader,
        customer: false,
      },
    }));
  }

  /**
   * Handle Changes in the Facilities Cascader and Updates Local State
   * @param {array} index Array of Numbers(IDs)
   * @param {number} id Organization ID
   * @param {string} name Organization Name
   * @returns {void}
   */
  onChangeFacility(index, [{ id, name } = {}] = []) {
    const { onGetUnitOptions } = this.props;

    if (id) {
      onGetUnitOptions(id);
    }

    this.formRef.current.setFieldsValue({
      facility: [id].filter((value) => !!value), unit: null,
    });
    this.formRef.current.validateFields(['facility']);

    this.setState(
      {
        facility_id: id,
        facility_name: name,
        unit_id: null,
      },
    );
    this.setState((prevState) => ({
      focusCascader: {
        ...prevState.focusCascader,
        facility: false,
      },
    }));
  }

  /**
   * Handle Changes in the Units Cascader and Updates Local State
   * @param {array} index Array of Numbers(IDs)
   * @param {number} id Organization ID
   * @param {string} name Organization Name
   * @returns {void}
   */
  onChangeUnit(index, [{ id, name } = {}] = []) {
    this.formRef.current.setFieldsValue({
      unit: [id].filter((value) => !!value),
    });
    this.formRef.current.validateFields(['unit']);

    this.setState(
      {
        unit_id: id,
        unit_name: name,
      },
    );
    this.setState((prevState) => ({
      focusCascader: {
        ...prevState.focusCascader,
        unit: false,
      },
    }));
  }

  /**
   * @description Redirect to the Edit Page Upon Creating a New Bed/Cart
   * @param {string} id
   * @param {boolean} isNonUhe
   * @returns {void}
   */
  redirectOnNewBedCart(id, isNonUhe) {
    const { customer_id } = this.state;
    if (isNonUhe) {
      this.props.history.push(
        `/configuration/beds-carts/non-uhe-units/edit/${id}/${customer_id}`,
      );
    } else {
      this.props.history.push(`/configuration/beds-carts/uhe-units/edit/${id}/${customer_id}`);
    }
  }

  /**
   * @description Sends POST Request on Save Button click w/ the state as body
   * @param {Object} event
   * @returns {void}
   */
  addNewBedCart(event) {
    event.persist();
    event.preventDefault();
    this.formRef.current.validateFields(['organization', 'customer', 'facility', 'unit', 'deviceName', 'deviceType'])
      .then((values) => {
        const { unit_id, name, endpoint_type } = this.state;

        this.props.addBedCart({
          body: { unit_id, name, endpoint_type },
          redirectOnNewBedCart: this.redirectOnNewBedCart,
        });
      })
      .catch((info) => {
        console.log('info:', info);
      });
  }

  /**
   * Handles What Icon to Show in Cascade Menu - Expand or Search
   * @param {string} field field type
   * @returns {func} manipulate the state
   */
     changeDropdownExpandIcon = (field) => {
       const {
         focusCascader: {
           organization, customer, facility, unit, deviceTypes,
         }, focusCascader,
       } = this.state;
       switch (field) {
         case 'organization':
           return this.setState({ focusCascader: { ...focusCascader, organization: !organization } });
         case 'customer':
           return this.setState({ focusCascader: { ...focusCascader, customer: !customer } });
         case 'facility':
           return this.setState({ focusCascader: { ...focusCascader, facility: !facility } });
         case 'unit':
           return this.setState({ focusCascader: { ...focusCascader, unit: !unit } });
         case 'deviceTypes':
           return this.setState({ focusCascader: { ...focusCascader, deviceTypes: !deviceTypes } });
         default:
       }
     }

     /**
   * Renders CreateBedCart Component
   * @returns {JSX.Element} CreateBedCart Component
   */
     render() {
       const {
         deviceTypes,
         organization_id,
         customer_id,
         facility_id,
         focusCascader,
       } = this.state;
       const { loading, optionsList } = this.props;

       return (
         <div className="manage-customer-wrapper add-bed-cart">
           <Form {...FORM_LAYOUT} name="addBedCart" ref={this.formRef} autoComplete={Math.random()}>
             {this.renderHeadLine()}
             <Card
               title={this.isUheUnit ? <IntlMessages id="configuration.bedsCarts.addNewTitle" /> : <IntlMessages id="nonCareDevices.titles.new" />}
               className="gx-card add-new-card"
               loading={loading}
             >
               <Form.Item
                 colon={false}
                 label={<IntlMessages id="uhe.table.organization" />}
                 className="gx-mb-3"
                 name="organization"
                 rules={[
                   {
                     required: true,
                     message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                   },
                 ]}
               >
                 <Cascader
                   getPopupContainer={(event) => event.parentNode}
                   showSearch={{ filter: cascaderSearchFilter }}
                   suffixIcon={focusCascader.organization ? <SearchOutlined /> : null}
                   onClick={() => this.changeDropdownExpandIcon('organization')}
                   expandTrigger="hover"
                   size="large"
                   options={optionsList.organization || []}
                   fieldNames={{ label: 'name', value: 'id' }}
                   onChange={this.onChangeOrganization}
                   longdesc={this.intl.formatMessage({
                     id: 'configuration.bedsCarts.descriptions.organization',
                   })}
                   onBlur={() => this.setState({ focusCascader: removeSearchIcon() })}
                 />
               </Form.Item>
               <Form.Item
                 colon={false}
                 label={<IntlMessages id="uhe.table.customer" />}
                 className="gx-mb-3"
                 name="customer"
                 rules={[
                   {
                     required: true,
                     message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                   },
                 ]}
               >
                 <Cascader
                   getPopupContainer={(event) => event.parentNode}
                   showSearch={{ filter: cascaderSearchFilter }}
                   suffixIcon={focusCascader.customer && organization_id ? <SearchOutlined /> : null}
                   onClick={() => this.changeDropdownExpandIcon('customer')}
                   expandTrigger="hover"
                   size="large"
                   options={optionsList.customer || []}
                   fieldNames={{ label: 'name', value: 'id' }}
                   onChange={this.onChangeCustomer}
                   disabled={!organization_id}
                   id="customerUheDropdown"
                   longdesc={this.intl.formatMessage({
                     id: 'configuration.bedsCarts.descriptions.customer',
                   })}
                   onBlur={() => this.setState({ focusCascader: removeSearchIcon() })}
                 />
               </Form.Item>
               <Form.Item
                 colon={false}
                 label={<IntlMessages id="uhe.table.facility" />}
                 className="gx-mb-3"
                 name="facility"
                 rules={[
                   {
                     required: true,
                     message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                   },
                 ]}
               >
                 <Cascader
                   getPopupContainer={(event) => event.parentNode}
                   showSearch={{ filter: cascaderSearchFilter }}
                   suffixIcon={focusCascader.facility && customer_id ? <SearchOutlined /> : null}
                   onClick={() => this.changeDropdownExpandIcon('facility')}
                   expandTrigger="hover"
                   size="large"
                   options={optionsList.facility || []}
                   fieldNames={{ label: 'name', value: 'id' }}
                   onChange={this.onChangeFacility}
                   disabled={!customer_id}
                   id="facilityUheDropdown"
                   longdesc={this.intl.formatMessage({
                     id: 'configuration.bedsCarts.descriptions.facility',
                   })}
                   onBlur={() => this.setState({ focusCascader: removeSearchIcon() })}
                 />
               </Form.Item>
               <Form.Item
                 colon={false}
                 label={<IntlMessages id="uhe.table.unit" />}
                 className="gx-mb-3"
                 name="unit"
                 rules={[
                   {
                     required: true,
                     message: this.intl.formatMessage({
                       id: 'configuration.users.emptyField',
                     }),
                   },
                 ]}
               >
                 <Cascader
                   getPopupContainer={(event) => event.parentNode}
                   showSearch={{ filter: cascaderSearchFilter }}
                   suffixIcon={focusCascader.unit && facility_id ? <SearchOutlined /> : null}
                   onClick={() => this.changeDropdownExpandIcon('unit')}
                   expandTrigger="hover"
                   size="large"
                   options={optionsList.unit || []}
                   fieldNames={{ label: 'name', value: 'id' }}
                   onChange={this.onChangeUnit}
                   disabled={!facility_id}
                   id="unitUheDropdown"
                   longdesc={this.intl.formatMessage({
                     id: 'configuration.bedsCarts.descriptions.unit',
                   })}
                   onBlur={() => this.setState({ focusCascader: removeSearchIcon() })}
                 />
               </Form.Item>
               <Form.Item
                 colon={false}
                 label={<IntlMessages id="uhe.table.name" />}
                 className="gx-mb-3"
                 rules={[
                   {
                     required: true,
                     message: this.intl.formatMessage({
                       id: 'configuration.users.emptyField',
                     }),
                   },
                 ]}
                 name="deviceName"
               >
                 <Input
                   autoComplete={Math.random()}
                   size="large"
                   onChange={this.onChangeInput}
                   placeholder={this.intl.formatMessage({
                     id: 'configuration.bedsCarts.name',
                   })}
                   longdesc={this.intl.formatMessage({
                     id: 'configuration.bedsCarts.descriptions.deviceName',
                   })}
                 />
               </Form.Item>
               <Form.Item
                 colon={false}
                 initialValue={!this.isUheUnit ? [this.intl.formatMessage({ id: 'standarts.table.title' })] : []}
                 label={<IntlMessages id="configuration.bedsCarts.deviceType" />}
                 className="gx-mb-3"
                 rules={[
                   {
                     type: 'array',
                     required: true,
                     message: this.intl.formatMessage({
                       id: 'configuration.users.emptyField',
                     }),
                   },
                 ]}
                 name="deviceType"
               >
                 <Cascader
                   getPopupContainer={(event) => event.parentNode}
                   showSearch={{ filter: cascaderDeviceSearchFilter }}
                   suffixIcon={focusCascader.deviceType ? <SearchOutlined /> : null}
                   onClick={() => this.changeDropdownExpandIcon('deviceType')}
                   expandTrigger="hover"
                   size="large"
                   key="deviceTypes"
                   options={deviceTypes || []}
                   fieldNames={{ label: 'value', value: 'value' }}
                   onChange={this.onChangeDeviceType}
                   longdesc={this.intl.formatMessage({
                     id: 'configuration.bedsCarts.descriptions.deviceType',
                   })}
                 />
               </Form.Item>
             </Card>
             {this.renderHeadLine()}
           </Form>
         </div>
       );
     }
}

const mapStateToProps = ({ bedsCarts, listingsTopFilter, subtitle }) => ({
  deviceTypes: bedsCarts.deviceTypes,
  loading: bedsCarts.loading,
  optionsList: listingsTopFilter,
  subtitle,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDeviceTypes: (data) => dispatch(fetchDeviceTypes(data)),
  clearBedCart: () => dispatch(clearBedCart()),
  onGetOrganizationOptions: () => dispatch(onGetOptions('organization')),
  onGetCustomerOptions: (id) => dispatch(onGetOptions('customer', id)),
  onGetFacilityOptions: (id) => dispatch(onGetOptions('facility', id)),
  onGetUnitOptions: (id) => dispatch(onGetOptions('unit', id)),
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  addBedCart: (data) => dispatch(addBedCart(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLastLocation(injectIntl(withRouter(CreateBedCart))));
