import React, {
  useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  Table, Col, Row, Card, Form, Button, Upload, List, Radio,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import IntlMessages from '@util/IntlMessages';
import * as UserActions from '@uhe_actions/configuration/users/UsersActions';
import * as ProgramActions from '@uhe_actions/configuration/programs/ProgramsActions';
import { uploadMobilePatientsDocument as uploadMobilePatientsDocumentAction } from '@uhe_actions/configuration/mobilePatients/MobilePatientsActions';
import { uploadSBAPSDocument as uploadSBAPSDocumentAction } from '@uhe_actions/configuration/nonUheUnits/NonUheUnitsActions';
import { setSubtitle as setSubtitleAction } from '@uhe_actions/SubtitleActions';
import moment from 'moment';
import { PROVIDER, NURSING, ANCILLARY } from '@constants/UHESettings';
import {
  BULK_UPLOAD_TEMPLATE_USER, BULK_UPLOAD_TEMPLATE_MOBILE_DEVICE,
  BULK_UPLOAD_TEMPLATE_PROGRAM, BULK_UPLOAD_TEMPLATE_SBAPS,
} from '@constants/UHEResources';
import { withoutEmptyEntries } from '@util/UheHelper';
import Countdown from 'react-countdown';

const UsersContext = Object.freeze({
  users: 1,
  mobilePatients: 2,
  programs: 3,
  nonUheUnits: 4,
});

const bulkUploadColumns = [
  {
    title: <IntlMessages id="configuration.bulkUpload.field" />,
    width: '25%',
    dataIndex: 'field',
    key: 'field',
  },
  {
    title: <IntlMessages id="configuration.bulkUpload.example" />,
    width: '20%',
    dataIndex: 'example',
    key: 'example',
  },
  {
    title: <IntlMessages id="configuration.bulkUpload.optional" />,
    width: '5%',
    dataIndex: 'optional',
    key: 'optional',
  },
  {
    title: <IntlMessages id="configuration.bulkUpload.description" />,
    width: '50%',
    dataIndex: 'description',
    key: 'description',
    className: 'bulk-upload-description-cell',
    render: (text) => ({
      props: {
        className: 'bulk-upload-description-cell',
      },
      children: text,
    }),
  },
];

const provider = PROVIDER.map((providerRole) => <li key={providerRole}><IntlMessages id={`configuration.bulkUpload.provider.${providerRole}`} /></li>);
const nursing = NURSING.map((nursingRole) => <li key={nursingRole}><IntlMessages id={`configuration.bulkUpload.nursing.${nursingRole}`} /></li>);
const ancillary = ANCILLARY.map((ancillaryRole) => <li key={ancillaryRole}><IntlMessages id={`configuration.bulkUpload.ancillary.${ancillaryRole}`} /></li>);

/**
 * Maps array of column properties to intl messages
 * @param {string} tableName Name of the table
 * @return {function([*, *, *]): {field, description: *, optional, example}}
 * Object of intl messages for table columns
 */
const tableMapper = (tableName) => ([field, optional, description]) => ({
  field: (
    <IntlMessages id={`configuration.bulkUpload.table.${tableName}.field${field}`} />
  ),
  example: (
    <IntlMessages id={`configuration.bulkUpload.table.${tableName}.example${field}`} />
  ),
  optional: (
    <IntlMessages id={`configuration.bulkUpload.table.${tableName}.optional${optional}`} />
  ),
  description: typeof description !== 'undefined'
    ? description
    : <IntlMessages id={`configuration.bulkUpload.table.${tableName}.description${field}`} />,
});

const uploadLogColumns = [
  'fileName', 'uploadTime', 'status', 'totalRows', 'successCount', 'failCount', 'detailedReport',
].map((column) => ({
  title: <IntlMessages id={`configuration.bulkUpload.${column}`} />,
  key: column,
  dataIndex: column,
}));
const templateProgramUserTable = [
  ['Prefix', 'Y'],
  ['FirstName', 'N'],
  ['LastName', 'N'],
  ['Channel', 'N'],
  ['Email', 'N'],
  ['ThirdPartyAuth', 'N'],
  ['Programs', 'N'],
  ['ClinicalRole', 'Y'],
  ['Specialty', 'Y', (
    <div>
      <IntlMessages id="configuration.bulkUpload.table.users.descriptionSpecialty" />
      <ul className="outter-list">
        <li>
          <IntlMessages id="configuration.bulkUpload.table.users.provider" />
          <ul className="inner-list">
            {provider}
          </ul>
        </li>
        <li>
          <IntlMessages id="configuration.bulkUpload.table.users.nursing" />
          <ul className="inner-list">
            {nursing}
          </ul>
        </li>
        <li>
          <IntlMessages id="configuration.bulkUpload.table.users.ancillary" />
          <ul className="inner-list">
            {ancillary}
          </ul>
        </li>
      </ul>
    </div>
  )],
].map(tableMapper('programUser'));

const templateUserTable = [
  ['Prefix', 'Y'],
  ['FirstName', 'N'],
  ['LastName', 'N'],
  ['Channel', 'N'],
  ['Email', 'N'],
  ['ThirdPartyAuth', 'N'],
  ['Role', 'N'],
  ['NotificationPortal', 'Y'],
  ['Customer', 'N'],
  ['Facility', 'N'],
  ['Unit', 'N'],
  ['Device', 'N'],
  ['ClinicalRole', 'Y'],
  ['Specialty', 'Y', (
    <div>
      <IntlMessages id="configuration.bulkUpload.table.users.descriptionSpecialty" />
      <ul className="outter-list">
        <li>
          <IntlMessages id="configuration.bulkUpload.table.users.provider" />
          <ul className="inner-list">
            {provider}
          </ul>
        </li>
        <li>
          <IntlMessages id="configuration.bulkUpload.table.users.nursing" />
          <ul className="inner-list">
            {nursing}
          </ul>
        </li>
        <li>
          <IntlMessages id="configuration.bulkUpload.table.users.ancillary" />
          <ul className="inner-list">
            {ancillary}
          </ul>
        </li>
      </ul>
    </div>
  )],
].map(tableMapper('users'));

const templateMobilePatientsTable = [
  ['FirstName', 'N'],
  ['Channel', 'N'],
  ['Customer', 'N'],
  ['Location', 'N'],
  ['Unit', 'N'],
  ['Email', 'N'],
  ['Password', 'Password'],
  ['MobileDeviceType', 'N'],
  ['LastName', 'Y'],
  ['Alias', 'Y'],
].map(tableMapper('mobile_patients'));

const templateNonUheUnitsTable = [
  ['Channel', 'N'],
  ['Customer', 'N'],
  ['Location', 'N'],
  ['Unit', 'N'],
  ['RoomName', 'N'],
  ['DeviceType', 'N'],
  ['CallFormat', 'N'],
  ['SIP', 'N'],
  ['CiscoHostname', 'Y'],
  ['CiscoUsername', 'Y'],
  ['CiscoPassword', 'Y'],
].map(tableMapper('nonUheUnits'));

/**
 * Adapts data for tables in user and mobile patients
 * @param {Array} data The template data according to path (users or mobile_patients)
 * @returns {Array} Array objects in shape { key, field, example, optional, description }
 */
const templateDescriptionDataAdapter = (data = []) => data
  .reduce((acc, {
    field, example, optional, description,
  }, index) => [
    ...acc,
    {
      key: index, field, example, optional, description,
    },
  ], []);

/**
* handleUsersContext
* @param {string} location location
* @returns {number} usersContext index
*/
const handleUsersContext = (location) => {
  if (location.pathname.indexOf('/users') !== -1) return UsersContext.users;
  if (location.pathname.indexOf('/programs') !== -1) return UsersContext.programs;
  if (location.pathname.indexOf('/beds-carts/non-uhe-units') !== -1) return UsersContext.nonUheUnits;
  return UsersContext.mobilePatients;
};

/**
 * @description Renders bulk upload page for both users and mobile patients
 */
const BulkUpload = React.memo(() => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const usersContext = handleUsersContext(location);

  const [userPolicy, setUserPolicy] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [hideError, setHideError] = useState(false);
  const fileFormRef = useRef();

  const uploadLogs = useSelector((state) => state.ConfigurationUsers.uploadLogs);
  const loading = useSelector((state) => state.ConfigurationUsers.loading);
  const uploadCompleted = useSelector((state) => state.ConfigurationUsers.uploadCompleted);
  const subtitle = useSelector((state) => state.subtitle);
  const dispatch = useDispatch();
  const setSubtitle = (langId) => dispatch(setSubtitleAction(langId));
  const uploadUserDocument = (file) => dispatch(UserActions.uploadUserDocument(file));
  const uploadProgramDocument = (file) => dispatch(ProgramActions.uploadProgramDocument(file));
  const uploadMobilePatientsDocument = (documentId, file) => dispatch(uploadMobilePatientsDocumentAction(documentId, file));
  const uploadSBAPSDocument = (documentId, file) => dispatch(uploadSBAPSDocumentAction(documentId, file));
  const fetchUploadLogsRequest = (payload) => dispatch(UserActions.fetchUploadLogsRequest(payload));
  const fetchDetailedReportRequest = (id) => dispatch(UserActions.fetchDetailedReportRequest(id));
  const [showCounter, setShowCounter] = useState(false);
  let date = Date.now();

  const onFinish = React.useCallback(() => {
    fetchUploadLogsRequest();
    setShowCounter(false);
  }, [fetchUploadLogsRequest]);

  useEffect(() => {
    fetchUploadLogsRequest();
    if (
      subtitle
      && (subtitle.langId !== 'configuration.users.bulkUpload'
        || subtitle.langId !== 'configuration.mobilePatients.bulkUpload'
        || subtitle.langId !== 'configuration.programs.bulkUpload'
        || subtitle.langId !== 'configuration.nonUheUnits.bulkUpload')
    ) {
      switch (usersContext) {
        case UsersContext.users:
          setSubtitle('configuration.users.bulkUpload');
          break;
        case UsersContext.mobilePatients:
          setSubtitle('configuration.mobilePatients.bulkUpload');
          break;
        case UsersContext.programs:
          setSubtitle('configuration.programs.bulkUpload');
          break;
        case UsersContext.nonUheUnits:
          setSubtitle('configuration.nonUheUnits.bulkUpload');
          break;
        default:
          break;
      }
    }
  }, []);

  useEffect(() => {
    let timer;
    if (!showCounter && !loading && !uploadCompleted) {
      timer = () => {
        setTimeout(() => {
          date = Date.now();
          setShowCounter(true);
        }, 200);
      };
      timer();
    } if (uploadCompleted) {
      setShowCounter(false);
      clearTimeout(timer);
    }
  }, [showCounter, uploadCompleted, loading]);

  /**
   * Handles delete import file after upload is clicked
   * @returns {void}
  */
  const removeImportFile = async () => {
    fileFormRef.current.setFieldsValue({ importFile: null });
    setFileList([]);
    await fileFormRef.current?.validateFields();
  };

  /**
   * Handles upload file
   * @returns {void}
   */
  const onSubmitImportFile = async () => {
    await fileFormRef.current?.validateFields();
    const importFile = fileFormRef.current?.getFieldValue('importFile');
    const fd = new FormData();
    fd.append('file', importFile);

    switch (usersContext) {
      case UsersContext.users:
        fd.append('userPolicy', userPolicy);
        uploadUserDocument(fd);
        break;
      case UsersContext.mobilePatients:
        uploadMobilePatientsDocument(fd);
        break;
      case UsersContext.programs:
        fd.append('userPolicy', userPolicy);
        uploadProgramDocument(fd);
        break;
      case UsersContext.nonUheUnits:
        uploadSBAPSDocument(fd);
        break;
      default:
        break;
    }
    setHideError(true);
    removeImportFile();
    setTimeout(() => {
      setHideError(false);
    }, 200);
  };

  /**
   * Renders Detailed Report Button && Calls fetchDetailedReportRequest Action on click
   * @param {object} value Item from the logs response
   * @returns {JSX.Element|null} Clickable text showing more details of import
   */
  const renderDetailedReport = (value) => {
    if (!value.has_detailed_report) {
      return null;
    }

    return (
      <a onClick={() => fetchDetailedReportRequest({ id: value.id, name: value.file_name })}>
        <IntlMessages id="configuration.bulkUpload.detailedReport" />
      </a>
    );
  };

  /**
   * Adapts the Upload Log Table Data
   * @param {array} data data
   * @returns {array} array
   */
  const uploadLogsDataAdapter = (data = []) => {
    const adaptedData = [];

    data.forEach((value) => {
      adaptedData.push({
        key: value.id,
        fileName: value.file_name,
        uploadTime: moment(value.upload_time).format('DD MMM, hh:mma'),
        status: value.status,
        totalRows: value.total_rows,
        successCount: value.succsess_count,
        failCount: value.fail_count,
        renderDetailedReport,
        detailedReport: renderDetailedReport(value),
      });
    });

    return adaptedData;
  };

  /**
  * onSelectUserPolicy function
  * @param {string} e e.target.value
  * @returns {function} setUserPolicy state
  */
  const onSelectUserPolicy = (e) => {
    setUserPolicy(e.target.value);
  };

  /**
   * Renders file upload form
   * @param {boolean} disabled On users page there are policies for import and
   * the upload fields must be disabled until user choose option
   * @return {JSX.Element} File select and upload components
   */
  const renderFileForm = (disabled = false) => (
    <Form ref={fileFormRef}>
      <Form.Item
        colon={false}
        name="importFile"
        rules={[
          {
            required: true,
            message: hideError ? '' : formatMessage({ id: 'configuration.bulkUpload.fileRequired' }),
          },
        ]}
        getValueFromEvent={(e) => {
          const file = e?.file;
          setFileList(withoutEmptyEntries([file]));
          return file;
        }}
      >
        <Upload
          className="bulk-upload-csv-input"
          accept=".csv"
          beforeUpload={() => false}
          fileList={fileList}
          onRemove={removeImportFile}
        >
          <Button
            icon={<UploadOutlined />}
            className="select-file-btn"
            disabled={disabled}
          >
            <IntlMessages id="configuration.bulkUpload.selectFile" />
          </Button>
        </Upload>
      </Form.Item>
      <Form.Item
        colon={false}
      >
        <Button
          className="sendButton upload-btn"
          disabled={disabled}
          onClick={onSubmitImportFile}
        >
          <IntlMessages id="sidebar.dataEntry.upload" />
        </Button>
      </Form.Item>
    </Form>
  );

  /**
   * whichTemplateToDownload
   * @param {string} context context
   * @returns {void}
   */
  const whichTemplateToDownload = (context) => {
    let file;
    switch (context) {
      case UsersContext.users:
        file = BULK_UPLOAD_TEMPLATE_USER;
        break;
      case UsersContext.mobilePatients:
        file = BULK_UPLOAD_TEMPLATE_MOBILE_DEVICE;
        break;
      case UsersContext.programs:
        file = BULK_UPLOAD_TEMPLATE_PROGRAM;
        break;
      case UsersContext.nonUheUnits:
        file = BULK_UPLOAD_TEMPLATE_SBAPS;
        break;
      default:
        return file;
    }
    return file;
  };
  /**
   * Renders button to download template xlsx file for imports
   * @return {JSX.Element} Download button
   */
  const renderDownloadTemplateButton = () => (
    <Button className="downloadBtn">
      <a
        href={whichTemplateToDownload(usersContext)}
        download
      >
        <IntlMessages id="common.download" />
      </a>
    </Button>
  );

  /**
   * Renders steps for uploading
   * @return {JSX.Element} The list with the steps
   */
  const renderProgramUploadStepsList = () => (
    <List
      className="bulkUpload__instructions"
      dataSource={Array(6).fill(true)
        .map((_, index) => (
          <div
            className={index === 3 ? '' : 'bulkUpload__instructions--listItem'}
          >
            <span>
              {`${index + 1}. ${formatMessage({ id: `configuration.bulkUpload.programMessage${index + 1}` })}`}
            </span>
            {
              (() => {
                switch (index) {
                  case 0:
                    return renderDownloadTemplateButton();
                  case 3:
                    return (
                      <Radio.Group className="gx-mt-2 gx-ml-2" onChange={onSelectUserPolicy}>
                        <Row gutter={[10, 10]}>
                          <Col lg={24} md={24} sm={24} xs={24}>
                            <Radio
                              value={1}
                            >
                              <IntlMessages id="configuration.bulkUpload.radioOption1" />
                            </Radio>
                          </Col>
                          <Col lg={24} md={24} sm={24} xs={24}>
                            <Radio
                              value={2}
                            >
                              <IntlMessages id="configuration.bulkUpload.radioOption4" />
                            </Radio>
                          </Col>
                          <Col lg={24} md={24} sm={24} xs={24}>
                            <Radio
                              value={3}
                            >
                              <IntlMessages id="configuration.bulkUpload.radioOption5" />
                            </Radio>
                          </Col>
                        </Row>
                      </Radio.Group>
                    );
                  case 4:
                    return renderFileForm(userPolicy === 0);
                  default:
                    return <></>;
                }
              })()
            }
          </div>
        ))}
      renderItem={(item) => <List.Item>{item}</List.Item>}
    />
  );

  /**
   * Renders steps for uploading
   * @return {JSX.Element} The list with the steps
   */
  const renderUserUploadStepsList = () => (
    <List
      className="bulkUpload__instructions"
      dataSource={Array(6).fill(true)
        .map((_, index) => (
          <div
            className={index === 3 ? '' : 'bulkUpload__instructions--listItem'}
          >
            <span>
              {`${index + 1}. ${formatMessage({ id: `configuration.bulkUpload.message${index + 1}` })}`}
            </span>
            {
              (() => {
                switch (index) {
                  case 0:
                    return renderDownloadTemplateButton();
                  case 3:
                    return (
                      <Radio.Group className="gx-mt-2 gx-ml-2" onChange={onSelectUserPolicy}>
                        <Row gutter={[10, 10]}>
                          <Col lg={24} md={24} sm={24} xs={24}>
                            <Radio
                              value={1}
                            >
                              <IntlMessages id="configuration.bulkUpload.radioOption1" />
                            </Radio>
                          </Col>
                          <Col lg={24} md={24} sm={24} xs={24}>
                            <Radio
                              value={2}
                            >
                              <IntlMessages id="configuration.bulkUpload.radioOption2" />
                            </Radio>
                          </Col>
                          <Col lg={24} md={24} sm={24} xs={24}>
                            <Radio
                              value={3}
                            >
                              <IntlMessages id="configuration.bulkUpload.radioOption3" />
                            </Radio>
                          </Col>
                        </Row>
                      </Radio.Group>
                    );
                  case 4:
                    return renderFileForm(userPolicy === 0);
                  default:
                    return <></>;
                }
              })()
            }
          </div>
        ))}
      renderItem={(item) => <List.Item>{item}</List.Item>}
    />
  );

  /**
  * renderMobileDeviceUploadStepsList function
  * @returns {JSX} render JSX
  */
  const renderMobileDeviceUploadStepsList = () => (
    <List
      className="bulkUpload__instructions"
      dataSource={Array(5).fill(true)
        .map((_, index) => (
          <div
            className="bulkUpload__instructions--listItem"
          >
            <span>
              {`${index + 1}. ${formatMessage({ id: `configuration.bulkUpload.mobileMessage${index + 1}` })}`}
            </span>
            {
              (() => {
                switch (index) {
                  case 0:
                    return renderDownloadTemplateButton();
                  case 3:
                    return renderFileForm();
                  default:
                    return <></>;
                }
              })()
            }
          </div>
        ))}
      renderItem={(item) => <List.Item>{item}</List.Item>}
    />
  );

  /**
  * renderNonUheUnitsUploadStepsList function
  * @returns {JSX} render JSX
  */
  const renderNonUheUnitsUploadStepsList = () => (
    <List
      className="bulkUpload__instructions"
      dataSource={Array(5).fill(true)
        .map((_, index) => (
          <div
            className="bulkUpload__instructions--listItem"
          >
            <span>
              {`${index + 1}. ${formatMessage({ id: `configuration.bulkUpload.nonUheUnitsMessage${index + 1}` })}`}
            </span>
            {
                (() => {
                  switch (index) {
                    case 0:
                      return renderDownloadTemplateButton();
                    case 3:
                      return renderFileForm();
                    default:
                      return <></>;
                  }
                })()
              }
          </div>
        ))}
      renderItem={(item) => <List.Item>{item}</List.Item>}
    />
  );

  /**
   * whichUploadStepListToRender
   * @param {number} context context index
   * @returns {function} function to render
   */
  const whichUploadStepListToRender = (context) => {
    let functionToRun;
    switch (context) {
      case UsersContext.users:
        functionToRun = renderUserUploadStepsList();
        break;
      case UsersContext.mobilePatients:
        functionToRun = renderMobileDeviceUploadStepsList();
        break;
      case UsersContext.programs:
        functionToRun = renderProgramUploadStepsList();
        break;
      case UsersContext.nonUheUnits:
        functionToRun = renderNonUheUnitsUploadStepsList();
        break;
      default:
        return functionToRun;
    }
    return functionToRun;
  };

  /**
   * whichTemplateToRender
   * @param {number} context context index
   * @returns {array} template to render
   */
  const whichTemplateToRender = (context) => {
    let template;
    switch (context) {
      case UsersContext.users:
        template = templateUserTable;
        break;
      case UsersContext.mobilePatients:
        template = templateMobilePatientsTable;
        break;
      case UsersContext.programs:
        template = templateProgramUserTable;
        break;
      case UsersContext.nonUheUnits:
        template = templateNonUheUnitsTable;
        break;
      default:
        return template;
    }
    return template;
  };

  return (
    <div className="manage-customer-wrapper">
      <Card className="ant-card-upload">
        {whichUploadStepListToRender(usersContext)}
        <Row gutter={16}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <div className="upload-log-wrapper">
              <div className="upload-log-head">
                <h2 className="upload-log-title">
                  <IntlMessages id="configuration.bulkUpload.importLog" />
                </h2>
                {showCounter
                && (
                <span>
                  <IntlMessages id="uhe.table.autoRefresh" />
                  <Countdown
                    onComplete={onFinish}
                    date={date + 3000}
                    renderer={({ seconds }) => (
                      <span className="gx-ml-1">
                        {seconds}
                      </span>
                    )}
                  />
                </span>
                )}
              </div>
              <Table
                className="upload-log-table gx-table-responsive"
                bordered
                pagination={false}
                columns={uploadLogColumns}
                dataSource={uploadLogsDataAdapter(uploadLogs)}
                loading={loading}
              />
            </div>
            <div className="uhe-table bulk-upload-table">
              <Table
                className="gx-table-responsive"
                bordered
                pagination={false}
                dataSource={templateDescriptionDataAdapter(whichTemplateToRender(usersContext))}
                columns={bulkUploadColumns}
              />
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
});

BulkUpload.displayName = 'BulkUpload';

BulkUpload.propTypes = {
};

export default BulkUpload;
