import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import IntlMessages from 'util/IntlMessages';
import PropTypes from 'prop-types';
import {
  Table, Card, Form, Input, Row, Col, Button,
} from 'antd';

import OrganizationCell from '@components/tables/cells/OrganizationCell';
import CustomerCell from '@components/tables/cells/CustomerCell';
import ActionsCell from '@components/tables/cells/ActionsCell';
import ListingsTableInputFilter from '@filters/ListingsTableInputFilter';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import {
  getNotifactions,
  getGlobalNotifications,
  updateGlobalNotifications,
} from '@actions/uhe/system/NotificationsActions';
import {
  shouldShowNotficationsActions,
  shouldSaveGlobalNotifications,
  shouldEditGlobalNotificationsInpunts,
  shouldShowSystemWideNotificationEmailSection,
  shouldValidateGlobalNotifications,
} from '@util/UheRoleChecker';
import { getLoggedUser, setObjectEmptyStringPropertiesToNull } from '@util/UheHelper';
import { APP_PAGES_CONTEXT } from '@constants/UHESettings';
import { DownOutlined } from '@ant-design/icons';

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 },
};

/**
   * @description Render Cells on the Table
   * @param {Object} content
   * @param {string} key
   * @returns {Object}
   */
const cellRenderer = (content, key) => {
  let cell;

  switch (key) {
    case 'organization':
      cell = <OrganizationCell content={content} />;
      break;
    case 'customer':
      cell = <CustomerCell content={content} />;
      break;
    case 'actions':
      cell = (
        <ActionsCell
          content={content}
          loggedUser={getLoggedUser()}
          editLink={`/system/notifications/edit/${content.id}`}
          page={APP_PAGES_CONTEXT.notifications}
        />
      );
      break;
    default:
      cell = content;
  }

  return cell;
};

/**
 * Creates Object for the Table Columns
 * @param {string} columnName Column Name
 * @returns {object} Object Item for Table dataSource
 */
const columnsMapper = (columnName) => ({
  title: (cellData) => (
    <ListingsTableInputFilter
      cellData={cellData}
      title={`uhe.table.${columnName}`}
      dataKey={columnName}
      showFilter={false}
    />
  ),
  align: 'left',
  minWidth: 200,
  dataIndex: columnName,
  render: (content) => cellRenderer(content, columnName),
});

/**
 * Notifications Class Component
 */
class Notifications extends Component {
  /**
   * Adapt data to the Talbe
   * @param  {Array} data Notifications Table Data
   * @returns {Array} Notifications Table Cell Data
   */
  static dataAdapter(data = []) {
    const adaptedData = [];

    if (data) {
      data.forEach((value, index) => {
        adaptedData.push({
          key: index,
          organization: {
            name: value.customer.organization.name,
            id: value.customer.organization.id,
          },
          customer: {
            name: value.customer.name,
            id: value.customer.id,
          },
          notificationEmailOne: value.email1,
          notificationEmailTwo: value.email2,
          notificationEmailThree: value.email3,
          actions: {
            id: value.customer.id,
          },
        });
      });
    }

    return adaptedData;
  }

  constructor(props) {
    super(props);
    const { subtitle, setSubtitle } = this.props;
    this.formRef = React.createRef();
    this.columns = [];
    this.tableKeys = [
      'organization',
      'customer',
      'notificationEmailOne',
      'notificationEmailTwo',
      'notificationEmailThree',
    ];

    if (shouldShowNotficationsActions()) {
      this.tableKeys.push('actions');
    }

    this.state = {
      globalNotifications: {
        email1: null,
        email2: null,
        email3: null,
      },
      tableColumns: this.tableKeys.map(columnsMapper),
      showMore: 20,
    };

    if (subtitle && subtitle.langId !== 'notifications.title') {
      setSubtitle('notifications.title');
    }
  }

  /**
   * @description Invoke Actions with Data && Updates Local State
   * @returns {void}
   */
  componentDidMount() {
    const {
      notifactionsData,
      globalNotifications,
      globalNotificationsData,
      loggedUser,
    } = this.props;
    const { email1, email2, email3 } = globalNotifications;

    notifactionsData();
    globalNotificationsData();

    if (Object.keys(globalNotifications).length > 0) {
      this.setState({
        globalNotifications,
      });

      if (shouldValidateGlobalNotifications(loggedUser)) {
        this.formRef.current.setFieldsValue({
          email1,
          email2,
          email3,
        });
      }
    }
  }

  /**
   * @description Set Props to Local State
   * @param {Object} prevProps
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const { globalNotifications, loggedUser } = this.props;

    if (
      Object.keys(prevProps.globalNotifications).length === 0
      && Object.keys(globalNotifications).length > 0
    ) {
      this.setState({ globalNotifications }, () => {
        const { globalNotifications } = this.state;
        const { email1, email2, email3 } = globalNotifications;

        if (shouldValidateGlobalNotifications(loggedUser)) {
          this.formRef.current.setFieldsValue({
            email1,
            email2,
            email3,
          });
        }
      });
    }

    if (prevProps.loggedUser !== loggedUser) {
      if (shouldShowNotficationsActions()) {
        if (!this.tableKeys.some((column) => column === 'actions')) {
          this.tableKeys.push('actions');
          this.setState({ tableColumns: this.tableKeys.map(columnsMapper) });
        }
      } else {
        this.tableKeys = this.tableKeys.filter((column) => column !== 'actions');
        this.setState({ tableColumns: this.tableKeys.map(columnsMapper) });
      }
    }
  }

  /**
   * @description Handle Changes in the Input Fields
   * @param {Object} event
   * @param {string} key
   * @returns {void}
   */
  changeHandler = (event, key) => {
    const { globalNotifications } = this.state;

    this.setState({
      globalNotifications: {
        ...globalNotifications,
        [key]: event.target.value,
      },
    });
  };

  /**
   * @description Invokes Save Notifications Action
   * @returns {void}
   */
  saveNotifications = () => {
    const { globalNotifications } = this.state;
    const { saveGlobalNotifications } = this.props;

    saveGlobalNotifications({ body: setObjectEmptyStringPropertiesToNull(globalNotifications) });
  };

  /**
   * Handle show more records
   * @returns {void}
   */
  showMoreRecords = () => {
    const { showMore } = this.state;

    this.setState({ showMore: showMore + 20 });
  }

  /**
   * Renders Notifications Component
   * @returns {JSX.Element} Notifications Component
   */
  render() {
    const { data, loading } = this.props;
    const { globalNotifications, tableColumns, showMore } = this.state;
    const { email1, email2, email3 } = globalNotifications;
    const { customer } = data;

    const customerToShow = customer ? customer.slice() : undefined;
    const customerAdapted = customerToShow ? customerToShow.slice(0, showMore) : undefined;

    return (
      <div>
        {shouldShowSystemWideNotificationEmailSection() && (
          <Form
            {...layout}
            ref={this.formRef}
            onFinish={this.saveNotifications}
            autoComplete="off"
          >
            <Card
              className="gx-card"
              title={<IntlMessages id="notifications.SWNE" />}
              loading={loading}
            >
              <Row>
                <Col lg={7} md={8} sm={24} sx={24}>
                  <Form.Item
                    colon={false}
                    className="form-item-row"
                    name="email1"
                    label={<IntlMessages id="notifications.notificationEmail1" />}
                    rules={[
                      {
                        type: 'email',
                        message: <IntlMessages id="common.error_email" />,
                      },
                    ]}
                    initialValue={email1}
                  >
                    <Input
                      autoComplete="off"
                      onChange={(event) => this.changeHandler(event, 'email1')}
                      disabled={shouldEditGlobalNotificationsInpunts()}
                    />
                  </Form.Item>
                </Col>
                <Col lg={7} md={8} sm={24} sx={24}>
                  <Form.Item
                    colon={false}
                    className="form-item-row"
                    name="email2"
                    label={<IntlMessages id="notifications.notificationEmail2" />}
                    rules={[
                      {
                        type: 'email',
                        message: <IntlMessages id="common.error_email" />,
                      },
                    ]}
                    initialValue={email2}
                  >
                    <Input
                      autoComplete="off"
                      onChange={(event) => this.changeHandler(event, 'email2')}
                      disabled={shouldEditGlobalNotificationsInpunts()}
                    />
                  </Form.Item>
                </Col>
                <Col lg={7} md={8} sm={24} sx={24}>
                  <Form.Item
                    colon={false}
                    className="form-item-row"
                    name="email3"
                    label={<IntlMessages id="notifications.notificationEmail3" />}
                    rules={[
                      {
                        type: 'email',
                        message: <IntlMessages id="common.error_email" />,
                      },
                    ]}
                    initialValue={email3}
                  >
                    <Input
                      autoComplete="off"
                      onChange={(event) => this.changeHandler(event, 'email3')}
                      disabled={shouldEditGlobalNotificationsInpunts()}
                    />
                  </Form.Item>
                </Col>
                <Col
                  lg={3}
                  md={8}
                  sm={24}
                  sx={24}
                  className="gx-d-flex gx-align-items-center"
                >
                  <Button
                    className="notifications-save-btn"
                    htmlType="submit"
                    disabled={shouldSaveGlobalNotifications()}
                  >
                    <IntlMessages id="button.save" />
                  </Button>
                </Col>
              </Row>
            </Card>
          </Form>
        )}
        <div className="uhe-table">
          <Table
            className="notifications-table"
            dataSource={Notifications.dataAdapter(customerAdapted)}
            columns={tableColumns}
            pagination={false}
            loading={loading}
            footer={() => (
              <div className="table-footer">
                <Button type="text" onClick={() => this.showMoreRecords()}>
                  <IntlMessages id="common.showMore" />
                  <DownOutlined />
                </Button>
              </div>
            )}
          />
        </div>
      </div>
    );
  }
}

Notifications.defaultProps = {
  loggedUser: {},
};

Notifications.propTypes = {
  data: PropTypes.shape().isRequired,
  globalNotifications: PropTypes.shape().isRequired,
  notifactionsData: PropTypes.func.isRequired,
  globalNotificationsData: PropTypes.func.isRequired,
  subtitle: PropTypes.shape().isRequired,
  setSubtitle: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loggedUser: PropTypes.shape(),
  saveGlobalNotifications: PropTypes.func.isRequired,
};

const mapStateToProps = ({ Notifications, ConfigurationUsers, subtitle }) => {
  const { loading, notifications, globalNotifications } = Notifications;
  const { ownUser } = ConfigurationUsers;

  return {
    data: notifications,
    globalNotifications,
    loggedUser: ownUser,
    loading,
    subtitle,
  };
};

const mapDispatchToProps = (dispatch) => ({
  notifactionsData: () => dispatch(getNotifactions()),
  globalNotificationsData: () => dispatch(getGlobalNotifications()),
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  saveGlobalNotifications: (data) => dispatch(updateGlobalNotifications(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withRouter(Notifications)));
