import {
  NOTIFICATIONS_REQUEST,
  NOTIFICATIONS_REQUEST_SUCCESS,
  GLOBAL_NOTIFICATIONS_REQUEST,
  GLOBAL_NOTIFICATIONS_REQUEST_SUCCESS,
  UPDATE_GLOBAL_NOTIFICATIONS_REQUEST,
  UPDATE_GLOBAL_NOTIFICATIONS_SUCCESS,
  CUSTOMER_NOTIFICATIONS_REQUEST,
  CUSTOMER_NOTIFICATIONS_SUCCESS,
  UPDATE_CUSTOMER_NOTIFICATIONS_REQUEST,
  UPDATE_CUSTOMER_NOTIFICATIONS_SUCCESS,
} from '@constants/UHEActionTypes';

/**
 * @description Action Creator for Notifications
 * @returns {Object}
 */
export const getNotifactions = () => ({
  type: NOTIFICATIONS_REQUEST,
});

/**
 * @description Action Creator for Notifications on Success
 * @param {Object} payload
 * @returns {Object}
 */
export const getNotifactionsSuccess = (payload) => ({
  type: NOTIFICATIONS_REQUEST_SUCCESS,
  payload,
});

/**
 * @description Action Creator for Global Notifications
 * @returns {Object}
 */
export const getGlobalNotifications = () => ({
  type: GLOBAL_NOTIFICATIONS_REQUEST,
});

/**
 * @description Action Creator for Global Notifications on Success
 * @param {Object} payload
 * @returns {Object}
 */
export const getGlobalNotificationsSuccess = (payload) => ({
  type: GLOBAL_NOTIFICATIONS_REQUEST_SUCCESS,
  payload,
});

/**
 * @description Action Creator for Global Notifications Update
 * @param {Oject} payload
 * @returns {Object}
 */
export const updateGlobalNotifications = (payload) => ({
  type: UPDATE_GLOBAL_NOTIFICATIONS_REQUEST,
  payload,
});

/**
 * @description Action Creator for Global Notifications Update on Success
 * @param {Oject} payload
 * @returns {Object}
 */
export const updateGlobalNotificationsSuccess = (payload) => ({
  type: UPDATE_GLOBAL_NOTIFICATIONS_SUCCESS,
  payload,
});

/**
 * @description Action Creator for Specific Customer Notifications
 * @param {number} id
 * @returns {Object}
 */
export const getCustomerNotifications = (id) => ({
  type: CUSTOMER_NOTIFICATIONS_REQUEST,
  payload: id,
});

/**
 * @description Action Creator for Specific Customer Notifications on Success
 * @param {Object} payload
 * @returns {Object}
 */
export const getCustomerNotificationsSuccess = (payload) => ({
  type: CUSTOMER_NOTIFICATIONS_SUCCESS,
  payload: { ...payload },
});

/**
 * @description Action Creator for Updating Specific Customer Notifications
 * @param {Object} payload
 * @returns {Object}
 */
export const updateCustomerNotifications = (payload) => ({
  type: UPDATE_CUSTOMER_NOTIFICATIONS_REQUEST,
  payload,
});

/**
 * @description Action Creator for Updating Specific Customer Notifications on Success
 * @param {Object} payload
 * @returns {Object}
 */
export const updateCustomerNotificationsSuccess = (payload) => ({
  type: UPDATE_CUSTOMER_NOTIFICATIONS_SUCCESS,
  payload: { ...payload },
});
