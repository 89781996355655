import React, { Component } from 'react';
import { Button, Form, Input } from 'antd';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { activateUser } from '@uhe_actions/configuration/users/UsersActions';
import { INVITE_TOKEN } from '@constants/UHESettings';

const FormItem = Form.Item;

class ActivateUser extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    this.intl = this.props.intl;
    this.qParams = new URLSearchParams(this.props.history.location.search);
  }

  /**
   * @description Sends POST Request to /api/authentication/invitation/accept on 'Activate' button click
   * @param {Object} event
   * @returns {void}
   */
  handleSubmit = (event) => {
    event.preventDefault();
    this.formRef.current.validateFields()
    .then(values => {
      const { activateUser } = this.props;
      const invitationToken = this.qParams.get(INVITE_TOKEN);
      activateUser({
        token: invitationToken,
        password: values.password,
        redirectOnAuth: this.redirectOnAuth,
      });
    })
    .catch(info => {
      console.log('info:', info);
    });
  };

  /**
   * @description Checks if the New Password Matches the Password
   * @param {Object} rule
   * @param {string} value
   * @param {function} callback
   * @returns {void}
   */
  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback(this.props.intl.formatMessage({ id: 'common.comparePass' }));
    } else {
      callback();
    }
  };

  /**
   * @description Checks if the New Password Matches the Password
   * @param {Object} rule
   * @param {string} value
   * @param {function} callback
   * @returns {void}
   */
  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.props.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  /**
   * @description Redirects after invocation
   * @param {string} token
   * @returns {void}
   */
  redirectOnAuth = (token) => {
    token ? this.props.history.push('/') : this.props.history.push('/signin');
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const passwordValidation = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})';

    return (
      <div className="gx-login-container">
        <div className="gx-login-content">
          <div className="gx-login-header"></div>
          <div className="gx-mb-4">
            <h2>
              <IntlMessages id="common.activateUser" />
            </h2>
            <p>
              <IntlMessages id="appModule.enterPasswordReset" />
            </p>
          </div>

          <Form onSubmit={this.handleSubmit} className="gx-login-form gx-form-row" ref={this.formRef} autoComplete="off">
            <FormItem rules={[
                  {
                    required: true,
                    message: this.intl.formatMessage({
                      id: "common.inputPass",
                    }),
                  },
                  {
                    validator: this.validateToNextPassword,
                  },
                  {
                    message: this.intl.formatMessage({
                      id: "common.validPassword",
                    }),
                    pattern: new RegExp(passwordValidation),
                  },
                ]
              }>
                <Input
                  autoComplete="off"
                  longdesc={this.intl.formatMessage({
                    id: "configuration.users.descriptions.newPassword",
                  })}
                  type="password"
                  placeholder={this.intl.formatMessage({
                    id: "common.newPass",
                  })}
                />
              
            </FormItem>

            <FormItem>
              {getFieldDecorator("confirm", {
                rules: [
                  {
                    required: true,
                    message: this.intl.formatMessage({
                      id: "common.confirmPass",
                    }),
                  },
                  {
                    validator: this.compareToFirstPassword,
                  },
                  {
                    message: this.intl.formatMessage({
                      id: "common.validPassword",
                    }),
                    pattern: new RegExp(passwordValidation),
                  },
                ],
              })(
                <Input
                  autoComplete="off"
                  longdesc={this.intl.formatMessage({
                    id: "configuration.users.descriptions.reNewPassword",
                  })}
                  placeholder={this.intl.formatMessage({
                    id: "common.reNewPass",
                  })}
                  type="password"
                />
              )}
            </FormItem>

            <FormItem>
              <Button type="primary" htmlType="submit">
                <IntlMessages id="common.activate" />
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    activateUser: (activation) => dispatch(activateUser(activation)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(injectIntl(withRouter(ActivateUser)));
