// ListingsTopFilterSaga
import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';
import {
  organizationsFetchDataSuccess,
  fetchOrganizationSuccess,
  fetchBrandingSuccess,
  saveOrganizationSuccess,
  deleteOrganizationSuccess,
  organizationsOnFetchData,
  clearLoading,
  setRedirectUrl,
  organizationsFetchDataCustomersSuccess,
  organizationsFetchDataFacilitySuccess,
} from '@actions/uhe/configuration/organizations/OrganizationsActions';
import {
  CONFIGURATION_ORGANIZATIONS_FETCH_DATA,
  FETCH_ORGANIZATION_REQUEST,
  FETCH_ORGANIZATION_BRANDING_REQUEST,
  SAVE_ORGANIZATION_REQUEST,
  DELETE_ORGANIZATION_REQUEST,
  FETCH_DATA_CUSTOMERS,
  FETCH_DATA_FACILITY,
} from '@constants/UHEActionTypes';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { fetchError, showMessage } from '@actions/Common';
import RestManager from '@util/RestManager';

/**
 *
 * @param {number} page
 * @param {Array} sorting
 */
const doFetchData = async (page, sorting, filter) => {
  const filterQueryString = filter && filter.length ? `&${filter.join('&')}` : '';
  const sortingQueryString = sorting && sorting.length ? `&sort=${sorting.join('&sort=')}` : '';
  return await RestManager.request(
    `${ENDPOINTS.configuration.OrganizationsTable}?page=${page || 0
    }${sortingQueryString}${filterQueryString}`,
  );
};

function* fetchTableData({ page, sorting, filter }) {
  try {
    const fetchedData = yield call(doFetchData, page, sorting, filter);
    yield put(organizationsFetchDataSuccess(fetchedData));
  } catch (error) {
    yield put(fetchError(error));
    yield put(clearLoading());
  }
}

/**
 * @return {Array} - Array of objects
 */
const dofetchOrganizationCustomers = async () => await RestManager.request(`${ENDPOINTS.organization.fetchById}`);

/**
 * @description Fetching all customer with the base projection
 */
function* fetchOrganizationAdd() {
  try {
    const fetchOrganizationAdd = yield call(dofetchOrganizationCustomers);
    if (fetchOrganizationAdd) {
      yield put(organizationsFetchDataCustomersSuccess(fetchOrganizationAdd));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @return {Array} - Array of objects
 */
const dofetchOrganizationFacility = async () => await RestManager.request(`${ENDPOINTS.facility.fetchAll}`);

/**
 * @description Fetching all facility with the base projection
 */
function* fetchOrganizationFacilityAdd() {
  try {
    const fetchOrganizationFacilityAdd = yield call(dofetchOrganizationFacility);
    if (fetchOrganizationFacilityAdd) {
      yield put(organizationsFetchDataFacilitySuccess(fetchOrganizationFacilityAdd));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @param  {string} id
 * @return {Object}
 */
async function doFetchOrganizationById(id) {
  return await RestManager.request(`${ENDPOINTS.organization.fetchById}/${id}`);
}

/**
 * @param  {string} id
 * @return {Object}
 */
const doFetchBrandingById = async (id) => await RestManager.request(
  `${ENDPOINTS.organization.fetchById}/${id}/branding`,
);

/**
 * Build FormData object
 *
 * @param  {object} bodyData
 * @return {FormData}
 */
const prepareSaveEditFormData = (bodyData) => {
  const data = new FormData();
  data.append('icon', bodyData.branding.icon);
  const jsonToSend = { ...bodyData };
  delete jsonToSend.branding.icon;
  delete jsonToSend.branding.attachment;
  delete jsonToSend.id;
  data.append('data', JSON.stringify(jsonToSend));
  return data;
};

/**
 * @param  {string} bodyData
 * @return {Object}
 */
const save = async (bodyData) => {
  const { branding = {} } = bodyData;
  let data;

  if (branding.icon && branding.icon instanceof File) {
    data = prepareSaveEditFormData(bodyData);
    return await RestManager.formDataRequest(
      `${ENDPOINTS.organization.saveOrganization}/${bodyData.id}`,
      data,
    );
  }

  data = { ...bodyData };
  delete data.id;
  delete data.branding.attachment;
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.organization.saveOrganization}/${bodyData.id}`,
    'POST',
    data,
  );
};

/**
 * @param  {Object} bodyData
 * @return {Object}
 */
const addNew = async (bodyData) => {
  const { branding = {} } = bodyData;
  let data;

  if (branding.icon && branding.icon instanceof File) {
    data = prepareSaveEditFormData(bodyData);
    return await RestManager.formDataRequest(
      `${ENDPOINTS.organization.saveOrganization}`,
      data,
    );
  }

  data = { ...bodyData };
  delete data.id;
  delete data.branding.attachment;
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.organization.saveOrganization}`,
    'POST',
    data,
  );
};

/**
 * @param  {Object} bodyData
 * @return {Object}
 */
const del = async (id) => await RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.organization.saveOrganization}/${id}`,
  'DELETE',
);

/**
 * Save Organization in case of update or create
 * @param {string} data - Data for saving
 * @returns {void}
 */
function* saveOrganization(data) {
  try {
    let organization = null;
    if (data.payload.id) {
      organization = yield call(save, data.payload);
      if (organization) {
        yield put(saveOrganizationSuccess({ ...organization, id: data.payload.id }));
        if (organization.id) {
          yield put(setRedirectUrl({ url: `/configuration/organizations/edit/${organization.id}` }));
        }
        yield put(showMessage('organizationUpdated'));
      }
    } else {
      const body = { ...data.payload };
      delete body.id;
      delete body.status;
      organization = yield call(addNew, body);
      if (organization) {
        yield put(saveOrganizationSuccess({ ...organization, new: true }));
        yield put(showMessage('organizationCreated'));
      }
    }
  } catch (error) {
    yield put(fetchError(error));
    yield put(clearLoading());
  }
}

/**
 * @param {object} - includes id
 */
function* fetchOrganizationById({ id }) {
  try {
    if (id) {
      const fetchedOrganization = yield call(doFetchOrganizationById, id);
      if (fetchedOrganization) {
        yield put(fetchOrganizationSuccess({ ...fetchedOrganization, id }));
      }
    } else {
      yield put(fetchOrganizationSuccess({}));
    }
  } catch (error) {
    yield put(fetchError(error));
    yield put(clearLoading());
  }
}

/**
 * @param {object} - includes id
 */
function* fetchBrandingById({ id }) {
  try {
    const fetchedBranding = yield call(doFetchBrandingById, id);
    if (fetchedBranding) {
      yield put(fetchBrandingSuccess(fetchedBranding));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @param {object} - includes id
 */
function* deleteOrganization({
  id, page, sorting, filter, size,
}) {
  try {
    const response = yield call(del, id);
    if (response) {
      console.log(response);
      yield put(deleteOrganizationSuccess(id));
      yield put(organizationsOnFetchData(page, sorting, filter));
      yield put(showMessage('organizationDeleted'));
    }
  } catch (error) {
    console.log(error);
    yield put(fetchError(error));
    yield put(clearLoading());
  }
}

/**
 * @return {Array} - Array of objects
 */
function* fetchData() {
  yield takeEvery(CONFIGURATION_ORGANIZATIONS_FETCH_DATA, fetchTableData);
}

/**
 * @description Generator function which handles FETCH_ORGANIZATION_REQUEST action
 */
function* fetchOrganization() {
  yield takeEvery(FETCH_ORGANIZATION_REQUEST, fetchOrganizationById);
}

/**
 * @description Generator function which handles FETCH_ORGANIZATION_BRANDING_REQUEST action
 */
function* fetchBranding() {
  yield takeEvery(FETCH_ORGANIZATION_BRANDING_REQUEST, fetchBrandingById);
}

/**
 * @description Generator function which handles FETCH_ORGANIZATION_BRANDING_REQUEST action
 */
function* saveOrganizationGenerator() {
  yield takeEvery(SAVE_ORGANIZATION_REQUEST, saveOrganization);
}

/**
 * @description Generator function which handles FETCH_ORGANIZATION_BRANDING_REQUEST action
 */
function* deleteOrganizationGenerator() {
  yield takeEvery(DELETE_ORGANIZATION_REQUEST, deleteOrganization);
}

/**
 * @description Generator function which handles FETCH_DATA_CUSTOMERS action
 */
export function* fetchOrgCustomersSaga() {
  yield takeEvery(FETCH_DATA_CUSTOMERS, fetchOrganizationAdd);
}

/**
 * @description Generator function which handles FETCH_DATA_FACILITY action
 */
export function* fetchOrgFacilitySaga() {
  yield takeEvery(FETCH_DATA_FACILITY, fetchOrganizationFacilityAdd);
}

export default function* rootSaga() {
  yield all([
    fork(fetchData),
    fork(fetchOrganization),
    fork(deleteOrganizationGenerator),
    fork(fetchBranding),
    fork(saveOrganizationGenerator),
    fork(fetchOrgCustomersSaga),
    fork(fetchOrgFacilitySaga),
  ]);
}
