import {
  DELETE_UHE_BED_CART_REQUEST,
  DELETE_UHE_BED_CART_SUCCESS,
  DELETE_NON_UHE_BED_CART_REQUEST,
  DELETE_NON_UHE_BED_CART_SUCCESS,
  FETCH_BED_CART_REQUEST,
  FETCH_BED_CART_SUCCESS,
  FETCH_DEVICE_TYPES_REQUEST,
  FETCH_DEVICE_TYPES_SUCCESS,
  CLEAR_BED_CART,
  SAVE_UHE_BEDCART_REQUEST,
  SAVE_UHE_BEDCART_SUCCESS,
  ADD_BEDCART_REQUEST,
  FETCH_DEVICE_ACTION,
  FETCH_DEVICE_QR_CODE_REQUEST,
  FETCH_DEVICE_QR_CODE_SUCCESS,
  FETCH_DEVICE_ADDITIONAL_QR_CODE_REQUEST,
  FETCH_DEVICE_ADDITIONAL_QR_CODE_SUCCESS,
  UPLOAD_BEDCART_PICTURE_REQUEST,
  UPLOAD_BEDCART_PICTURE_SUCCESS,
  FETCH_BEDCART_IMAGE_REQUEST,
  FETCH_BEDCART_IMAGE_SUCCESS,
  FETCH_CARE_DEVICE_MODES,
  FETCH_CARE_DEVICE_MODES_SUCCESS,
  FETCH_CALL_ACTION,
  FETCH_CALL_ACTION_SUCCESS,
  FETCH_ACKNOWLEDGED_EMAIL,
  DELETE_ROOM_PICTURE,
  DELETE_ROOM_PICTURE_SUCCESS,
  SAVE_BEDCART_FAIL,
  VERIFY_CONNECTIVITY,
  VERIFY_CONNECTIVITY_SUCCESS,
  FETCH_ACKNOWLEDGED_EMAIL_SUCCESS,
  FETCH_COMMANDS_STATUS,
  FETCH_COMMANDS_STATUS_SUCCESS,
} from '@constants/UHEActionTypes';

/**
 * Handle FETCH_BED_CART_REQUEST Action
 * @param {string} id device id
 * @returns {Object} object
 */
export const fetchCommandsStatus = (id) => ({
  type: FETCH_COMMANDS_STATUS,
  payload: id,
});

/**
 * Handle FETCH_BED_CART_REQUEST Action
 * @param {object} commands commands status
 * @returns {Object} object
 */
export const fetchCommandsStatusSuccess = (commands) => ({
  type: FETCH_COMMANDS_STATUS_SUCCESS,
  payload: commands,
});

/**
 * Handle FETCH_BED_CART_REQUEST Action
 * @param {string} id id
 * @returns {Object} object
 */
export const fetchBedCart = (id) => ({
  type: FETCH_BED_CART_REQUEST,
  payload: id,
});

/**
 * Handles FETCH_ACKNOWLEDGED_EMAIL Action
 * @returns {Object} The action type
 */
export const fetchAcknowledgedByEmail = () => ({
  type: FETCH_ACKNOWLEDGED_EMAIL,
});

/**
 * Handles FETCH_ACKNOWLEDGED_EMAIL_SUCCESS Action
 * @param {Object} payload The payload (acknowledged_email)
 * @returns {Object} The action type and the payload
 */
export const fetchAcknowledgedByEmailSuccess = (payload) => ({
  type: FETCH_ACKNOWLEDGED_EMAIL_SUCCESS,
  payload,
});

/**
 * Handle FETCH_BED_CART_SUCCESS Action
 * @param {Object} payload payload
 * @returns {Object} object
 */
export const fetchBedCartSuccess = (payload) => ({
  type: FETCH_BED_CART_SUCCESS,
  payload,
});

/**
 * Handle FETCH_DEVICE_ACTION Action
 * @param {Object} payload payload
 * @returns {string} string
 */
export const fetchDeviceAction = (payload) => ({
  type: FETCH_DEVICE_ACTION,
  payload,
});

/**
 * Handle FETCH_DEVICE_ACTION Action
 * @param {Object} payload payload
 * @returns {string} string
 */
export const fetchCallAction = (payload) => ({
  type: FETCH_CALL_ACTION,
  payload,
});

/**
 * Handle FETCH_CARE_DEVICE_MODES_SUCCESS Action
 * @param {Object} data data
 * @returns {Object} object
 */
export const fetchCallActionSuccess = (data) => ({
  type: FETCH_CALL_ACTION_SUCCESS,
  data,
});

/**
 * Handle DELETE_UHE_BED_CART_REQUEST Action
 * @param {Object} data data
 * @returns {Object} object
 */
export const deleteUheBedCart = (data) => ({
  type: DELETE_UHE_BED_CART_REQUEST,
  payload: data,
});

/**
 * Handle DELETE_UHE_BED_CART_SUCCESS Action
 * @returns {Object} object
 */
export const deleteUheBedCartSuccess = () => ({
  type: DELETE_UHE_BED_CART_SUCCESS,
});

/**
 * Handle DELETE_NON_UHE_BED_CART_REQUEST Action
 * @param {Object} data data
 * @returns {Object} object
 */
export const deleteNonUheBedCart = (data) => ({
  type: DELETE_NON_UHE_BED_CART_REQUEST,
  payload: data,
});

/**
 * Handle DELETE_NON_UHE_BED_CART_SUCCESS Action
 * @returns {Object} object
 */
export const deleteNonUheBedCartSuccess = () => ({
  type: DELETE_NON_UHE_BED_CART_SUCCESS,
});

/**
 *  Handle FETCH_DEVICE_TYPES_REQUEST Action
 * @returns {Object} object
 */
export const fetchDeviceTypes = () => ({
  type: FETCH_DEVICE_TYPES_REQUEST,
});

/**
 *  Handle FETCH_DEVICE_TYPES_SUCCESS Action
 * @param {Object} data data
 * @returns {Object} object
 */
export const fetchDeviceTypesSuccess = (data) => ({
  type: FETCH_DEVICE_TYPES_SUCCESS,
  data,
});

/**
 *  Handle FETCH_DEVICE_QR_CODE_REQUEST Action
 * @param {string} id device id
 * @returns {Object} object
 */
export const fetchDeviceQrCode = (id) => ({
  type: FETCH_DEVICE_QR_CODE_REQUEST,
  payload: id,
});

/**
 *  Handle FETCH_DEVICE_ADDITIONAL_QR_CODE_REQUEST Action
 * @param {string} id device id
 * @returns {Object} object
 */
export const fetchDeviceAdditionalQrCode = (id) => ({
  type: FETCH_DEVICE_ADDITIONAL_QR_CODE_REQUEST,
  payload: id,
});

/**
 *  Handle FETCH_DEVICE_QR_CODE_SUCCESS Action
 * @param {Object} payload data
 * @returns {Object} object
 */
export const fetchDeviceQrCodeSuccess = (payload) => ({
  type: FETCH_DEVICE_QR_CODE_SUCCESS,
  payload,
});

/**
 *  Handle FETCH_DEVICE_ADDITIONAL_QR_CODE_SUCCESS Action
 * @param {Object} payload data
 * @returns {Object} object
 */
export const fetchDeviceAdditionalQrCodeSuccess = (payload) => ({
  type: FETCH_DEVICE_ADDITIONAL_QR_CODE_SUCCESS,
  payload,
});

/**
 *  Handle FETCH_BEDCART_IMAGE_REQUEST Action
 * @param {object} payload  data
 * @returns {Object} object
 */
export const fetchBedCartImage = (payload) => ({
  type: FETCH_BEDCART_IMAGE_REQUEST,
  payload,
});

/**
 *  Handle FETCH_BEDCART_IMAGE_SUCCESS Action
 * @param {Object} payload data
 * @returns {Object} object
 */
export const fetchBedCartImageSuccess = (payload) => ({
  type: FETCH_BEDCART_IMAGE_SUCCESS,
  payload,
});

/**
 *  Handle CLEAR_BED_CART Action
 * @returns {Object} object
 */
export const clearBedCart = () => ({
  type: CLEAR_BED_CART,
});

/**
 *  Handle SAVE_UHE_BEDCART_REQUEST Action
 * @param {Object} data data
 * @returns {Object} object
 */
export const saveUheBedCart = (data) => ({
  type: SAVE_UHE_BEDCART_REQUEST,
  payload: data,
});

/**
 *  Handle SAVE_UHE_BEDCART_SUCCESS Action
 * @param {Object} payload data
 * @returns {Object} object
 */
export const saveUheBedCartSuccess = (payload) => ({
  type: SAVE_UHE_BEDCART_SUCCESS,
  payload,
});

/**
 *  Handles UPLOAD_BEDCART_PICTURE_REQUEST action
 * @param {Object} file file
 * @param {string} id picture id
 * @returns {Object} object
 */
export const uploadBedCartPicture = (file, id) => ({
  type: UPLOAD_BEDCART_PICTURE_REQUEST,
  payload: {
    file,
    id,
  },
});

/**
 *  Handles UPLOAD_USER_DOCUMENT_SUCCESS action
 * @returns {Object} object
 */
export const uploadBedCartPictureSuccess = () => ({
  type: UPLOAD_BEDCART_PICTURE_SUCCESS,
});

/**
 *  Handle ADD_BEDCART_REQUEST Action
 * @param {Object} data bed cart data
 * @returns {Object} object
 */
export const addBedCart = (data) => ({
  type: ADD_BEDCART_REQUEST,
  payload: data,
});

/**
 *  Handle FETCH_CARE_DEVICE_MODES Action
 * @param {Object} data care device modes data
 * @returns {Object} object
 */
export const fetchCareDeviceModes = () => ({
  type: FETCH_CARE_DEVICE_MODES,
});

/**
 *  Handle FETCH_CARE_DEVICE_MODES_SUCCESS Action
 * @param {Object} data care device mode data
 * @returns {Object} object
 */
export const fetchCareDeviceModesSuccess = (data) => ({
  type: FETCH_CARE_DEVICE_MODES_SUCCESS,
  data,
});

/**
 * Handle DELETE_ROOM_PICTURE Action
 * @param {Object} data for delete
 * @return {Object} bedCart data
 */
export const deleteRoomPicture = (data) => ({
  type: DELETE_ROOM_PICTURE,
  payload: data,
});

/**
 * Handle DELETE_ROOM_PICTURE_SUCCESS Action
 * @returns {Object} bedCart data
 */
export const deleteRoomPictureSuccess = () => ({
  type: DELETE_ROOM_PICTURE_SUCCESS,
});

/**
 * Fetch action when save request fail
 * @returns {boolean} after catch error
 */
export const saveBedCartFail = () => ({
  type: SAVE_BEDCART_FAIL,
});

/**
* Handle VERIFY_CONNECTIVITY Action
 * @param {Object} data payload
 * @returns {Object} data
 */
export const verifyConnectivityRequest = (data) => ({
  type: VERIFY_CONNECTIVITY,
  payload: { ...data },
});

/**
 * Handle VERIFY_CONNECTIVITY_SUCCESS Action
 * @param {Object} payload data
 * @returns {Object} data
 */
export const verifyConnectivitySuccess = (payload) => ({
  type: VERIFY_CONNECTIVITY_SUCCESS,
  payload: { ...payload },
});
