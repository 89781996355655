import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import RestManager from '@util/RestManager';
import { fetchError, showMessage } from '@actions/Common';
import { fetchVmrReportSuccess } from '@actions/uhe/reports/VmrReportActions';
import { FETCH_VMR_REPORT } from 'constants/UHEActionTypes';

/**
 * @description Sends GET Request to /vmr_report
 * @returns {Object}
 */
const fetchVmrReport = async () => {
  return await RestManager.request(`${ENDPOINTS.reports.vmr}`);
};

/**
 * @description Fetches VMRs
 * @param {Object} data
 * @returns {void}
 */
function* doFetchVmrReports({ data }) {
  try {
    const fetchedVmr = yield call(fetchVmrReport, data);
    if (fetchedVmr) {
      yield put(fetchVmrReportSuccess(fetchedVmr));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Handles Actions
 * @returns {void}
 */
export function* actionsWatcher() {
  yield takeEvery(FETCH_VMR_REPORT, doFetchVmrReports);
}

export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
