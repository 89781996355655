import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, InputNumber } from 'antd';
import { EditableContext } from '@components/uhe/configuration/customer/InterpreterSettings';

/**
 * Renders Editable Cell
 * @param {object} props EditableCell Props
 * @returns {JSX.Element} Editable Cell
 */
const EditableCell = (props) => {
  /**
   * Renders Input
   * @param {string} data Input Data
   * @returns {JSX.Element} Input
   */
  const getInput = (data) => {
    const { inputType } = props;

    return (inputType === 'number'
      ? <InputNumber autoComplete="off" value={data} />
      : <Input autoComplete="off" value={data} />);
  };

  /**
   * Renders Editable Table Cell
   * @returns {JSX.Element} Editable Table Cell
   */
  const renderCell = () => {
    const {
      editing, dataIndex, title, inputType, record, index, children, ...restProps
    } = props;

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            colon={false}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
            initialValue={record[dataIndex] || ''}
            name={dataIndex || ''}
          >
            {getInput(record[dataIndex])}
          </Form.Item>
        ) : (children)}
      </td>
    );
  };

  return (
    <EditableContext.Consumer>{renderCell}</EditableContext.Consumer>
  );
};

EditableCell.defaultProps = {
  index: PropTypes.string,
};

EditableCell.propTypes = {
  editing: PropTypes.bool.isRequired,
  dataIndex: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  children: PropTypes.shape().isRequired,
  record: PropTypes.shape().isRequired,
  index: PropTypes.string,
};

export default EditableCell;
