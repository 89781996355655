export default class ReactivePermission {
  constructor(obj = null) {
    /**
     * @type {boolean}
     */
    this.granted = undefined;
    /**
     * @type {boolean}
     */
    this.allowed = undefined;
    /**
     * @type {boolean}
     */
    this.artificial = undefined;
    /**
     * @type {boolean}
     */
    this.inherited = undefined;
    /**
     * @type {string}
     */
    this.name = undefined;
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
