import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, Select, Col, Row, Input, Form, Popover,
} from 'antd';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';

const { Option } = Select;

/**
 * Renders DeviceAttrCard Component
 * @param {object} bedCart Device Data
 * @param {function} onChangeDeviceAttr Device Attribute Input Change Handler
 * @param {object} intl Internalization Object
 * @param {boolean} loading Loading State
 * @return {JSX.Element} DeviceAttrCard
 */
const DeviceAttrCard = ({
  bedCart, onChangeDeviceAttr, intl, loading,
}) => {
  /**
   * Sorts Each Attribute Field based on it's Availability and Type Definition
   * @param {array} acc Accumulator
   * @param {object} cur Current Value
   * @param {1|0|null} cur.endpointAttributeType.send_to_device Current Value
   * @param {1|0} cur.endpointAttributeType.is_boolean Attribute Type Definition
   * @param {number} index Array Index
   * @param {array} arr Array of Attributes
   * @return {array} Sorted Attributes
   */
  const customAttributeSort = (acc, cur, index, arr) => {
    if (cur.endpointAttributeType.send_to_device === null) {
      acc.unshift(cur);
    } else if (cur.endpointAttributeType.is_boolean) {
      acc.push(cur);
    } else {
      acc
        .splice(arr
          .findIndex((attribute) => attribute.endpointAttributeType.is_boolean) - 1, 0, cur);
    }

    return acc;
  };

  /**
   * Renders Each Device Attribute Form Item
   * @param {object} attr Device's Attribute
   * @returns {JSX.Element} Device's Attribute Label/Input
   */
  const toDeviceAttr = (attr) => {
    const { value, endpointAttributeType } = attr;
    const {
      description, is_boolean, send_to_device, name_tag,
    } = endpointAttributeType;
    const deviceNameAttribute = description.split('_').join(' ');

    if (is_boolean) {
      return (
        <Col className="gx-align-items-center" lg={12} md={24} sm={24} xs={24} key={attr.endpointAttributeType.id}>
          <Form.Item
            colon={false}
            className="gx-align-items-center"
            name={intl.formatMessage({ id: `configuration.bedsCarts.descriptions.${name_tag || description}` })}
            label={(
              <Popover content={<IntlMessages id={`configuration.bedsCarts.descriptions.${name_tag || description}`} />}>
                <span className="device-attr-title">
                  <IntlMessages id={`configuration.bedsCarts.descriptions.${name_tag || description}`} />
                </span>
              </Popover>
           )}
            initialValue={value}
          >
            <Select
              longdesc={name_tag}
              onChange={(attrValue) => onChangeDeviceAttr(attrValue, attr)}
            >
              <Option value="1">{intl.formatMessage({ id: 'common.yes' })}</Option>
              <Option value="0">{intl.formatMessage({ id: 'common.no' })}</Option>
            </Select>
          </Form.Item>
        </Col>
      );
    }

    return (
      <Col className="gx-align-items-center" lg={12} md={24} sm={24} xs={24} key={attr.endpointAttributeType.id}>
        <Form.Item
          colon={false}
          className="gx-align-items-center device-attr"
          name={intl.formatMessage({ id: `configuration.bedsCarts.descriptions.${deviceNameAttribute}` })}
          label={(
            <Popover content={deviceNameAttribute}>
              <span className="device-attr-title">
                {deviceNameAttribute}
              </span>
            </Popover>
            )}
          initialValue={value}
        >
          <Input
            autoComplete="off"
            disabled={!!(send_to_device === null || 0)}
            onChange={(event) => onChangeDeviceAttr(event.target.value, attr)}
          />
        </Form.Item>
      </Col>
    );
  };

  return (
    <div>
      <Card
        className="gx-card"
        title={<IntlMessages id="configuration.bedsCarts.deviceAttr.title" />}
        loading={loading}
      >
        <Row gutter={16} className="device-attr-row gx-align-items-center">
          {bedCart?.endpointAttributes
            ?.reduce(customAttributeSort, [])
            .map(toDeviceAttr)}
        </Row>
      </Card>
    </div>
  );
};

DeviceAttrCard.propTypes = {
  bedCart: PropTypes.shape().isRequired,
  onChangeDeviceAttr: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  intl: PropTypes.shape().isRequired,
};

export default injectIntl(DeviceAttrCard);
