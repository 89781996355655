import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'antd';
import IntlMessages from 'util/IntlMessages';

import battery_1 from '@assets/images/battery_1.svg';
import battery_2 from '@assets/images/battery_2.svg';
import battery_3 from '@assets/images/battery_3.svg';
import battery_4 from '@assets/images/battery_4.svg';
import wifi_1 from '@assets/images/wifi_1.svg';
import wifi_2 from '@assets/images/wifi_2.svg';
import wifi_3 from '@assets/images/wifi_3.svg';
import wifi_4 from '@assets/images/wifi_4.svg';
import devices_in_alarm from '@assets/images/show_devices_in_alarm.svg';
import devices_not_configured from '@assets/images/show_devices_not_configured.svg';
import devices_online from '@assets/images/show_devices_online.svg';

/**
 * GridViewCard Component
 */
class GridViewCard extends Component {
  /**
   * GridViewCard Component Constructor
   * @param {object} props GridViewCard Component Props
   */
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * Renders Wifi or Battery Icon Based on Their Level Statuses
   * @param {number} statusLevel Status Level of Wifi or Battery
   * @param {boolean} isWifi Check if it's Wifi Status or not
   * @returns {JSX.Element|null} Wifi or Battery Level Status
   */
  renderStatusIcon = (statusLevel, isWifi) => {
    switch (true) {
      case (statusLevel >= 1 && statusLevel <= 24):
        return (<img alt="battery_status" src={isWifi ? wifi_1 : battery_1} />);

      case (statusLevel >= 25 && statusLevel <= 49):
        return (<img alt="battery_status" src={isWifi ? wifi_2 : battery_2} />);

      case (statusLevel >= 50 && statusLevel <= 74):
        return (<img alt="battery_status" src={isWifi ? wifi_3 : battery_3} />);

      case (statusLevel >= 75 && statusLevel <= 100):
        return (<img alt="battery_status" src={isWifi ? wifi_4 : battery_4} />);

      default:
        return null;
    }
  }

    /**
   * Renders Version or Status Icons
   * @param {number} status Status
   * @param {number} version Version
   * @returns {JSX.Element} Status or Version Icons
   */
    renderVersionStatusIcon = (status, version) => {
      const { latestVersion } = this.props;

      if (!version) {
        let statusIcon;
        switch (status) {
          case 'healthy':
            statusIcon = devices_online;
            break;
          case 'unhealthy':
            statusIcon = devices_in_alarm;
            break;
          default:
            statusIcon = devices_not_configured;
        }
        return <img alt="icon" className="status-icon" src={statusIcon} />;
      }
      if (version) {
        let icon = devices_online;
        if (status && status < latestVersion) {
          icon = devices_in_alarm;
        } else if (status && status > latestVersion) {
          icon = devices_not_configured;
        }
        return <img alt="icon" className="status-icon" src={icon} />;
      }
    }

  /**
   * Renders GridViewCard Component
   * @returns {JSX.Element} GridViewCard Component
   */
  render() {
    const {
      room,
      deviceType,
      deviceId,
      customerId,
      version,
      status,
      loading,
      wifiLevel,
      batteryLevel,
      toggleActiveClass,
      selectedId,
    } = this.props;

    return (
      <Card
        className={`grid-view-card ${selectedId === deviceId ? 'grid-view-card--active' : ''}`}
        loading={loading}
        onClick={() => toggleActiveClass(deviceId)}
      >
        <div className="grid-view-card__header">
          <p className="grid-view-card__room">
            <Link to={`/configuration/beds-carts/uhe-units/edit/${deviceId}/${customerId}`} title={room}>
              <span>{room}</span>
            </Link>
          </p>
          <p className="grid-view-card__type">
            <span className="grid-view-card--blue label-type">
              <IntlMessages id="gridViewCard.type" />
            </span>
            <span>{deviceType}</span>
          </p>
          <p className="grid-view-card__id">
            <span className="grid-view-card--blue label-id">
              <IntlMessages id="gridViewCard.id" />
            </span>
            <span>{deviceId}</span>
          </p>
        </div>
        <div className="grid-view-card__body">
          <Row className="grid-view-card__upper-row">
            <Col sm={16} xs={12}>
              <span className="grid-view-card--blue">
                <IntlMessages id="gridViewCard.version" />
              </span>
              <div className="version-content">
                <span>{version}</span>
                <span>{version && this.renderVersionStatusIcon(version, true)}</span>
              </div>
            </Col>
            <Col sm={8} xs={12}>
              <span className="grid-view-card--blue">
                <IntlMessages id="gridViewCard.mos" />
              </span>
              <span />
            </Col>
          </Row>
          <Row>
            <Col sm={8} xs={12}>
              <span className="grid-view-card--blue">
                <IntlMessages id="gridViewCard.status" />
              </span>
              <div className="grid-view-card__status">
                {this.renderVersionStatusIcon(status, false)}
              </div>
            </Col>
            <Col sm={8} xs={12}>
              <span className="grid-view-card--blue">
                <IntlMessages id="gridViewCard.wifi" />
              </span>
              <span>
                {this.renderStatusIcon(wifiLevel, true)}
              </span>
            </Col>
            <Col sm={8} xs={12}>
              <span className="grid-view-card--blue">
                <IntlMessages id="gridViewCard.battery" />
              </span>
              <span>
                {this.renderStatusIcon(batteryLevel, false)}
              </span>
            </Col>
          </Row>
        </div>
      </Card>
    );
  }
}

GridViewCard.defaultProps = {
  version: '',
  wifiLevel: 0,
  batteryLevel: 0,
  selectedId: 0,
};

GridViewCard.propTypes = {
  room: PropTypes.string.isRequired,
  deviceType: PropTypes.string.isRequired,
  deviceId: PropTypes.number.isRequired,
  customerId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  version: PropTypes.string,
  latestVersion: PropTypes.number.isRequired,
  wifiLevel: PropTypes.number,
  batteryLevel: PropTypes.number,
  toggleActiveClass: PropTypes.func.isRequired,
  selectedId: PropTypes.number,
};

export default GridViewCard;
