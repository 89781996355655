/**
 * Select all documents from the global state SupportSettings space
 * @param {object} state Global state
 * @returns {*} global state.SupportSettings.documentData.list
 */
const getDocumentsList = (state) => state.SupportSettings?.documentData?.list || [];

/**
 * Select all sections from the global state SupportSettings space
 * @param {object} state Global state
 * @returns {*} global state.SupportSettings.sectionData.list
 */
const getSectionsList = (state) => state.SupportSettings?.sectionData?.list || [];

/**
 * Select loading from the global state SupportSettings space
 * @param {object} state Global state
 * @returns {*} global state.SupportSettings.loading
 */
const getLoading = (state) => state.SupportSettings.loading;

/**
 * Select all documents based on search query from the global state SupportSettings space
 * @param {object} state Global state
 * @returns {*} global state.SupportSettings.searchDocumentData.list
 */
const getSearchDocumentsList = (state) => state.SupportSettings?.searchDocumentData?.list || [];

/**
 * Calculate documents and sections
 * @param {object} state Global state
 * @returns {object} updated state
 */
const getSectionsWithMappedDocuments = (state) => {
  const sections = getSectionsList(state);
  const documents = getDocumentsList(state);
  const searchDocuments = getSearchDocumentsList(state);
  return sections.map((section) => {
    const subsections = sections.filter((subsec) => subsec.parent_section.id === section.id);
    const updatedSubsections = subsections.map((subsection) => (
      {
        ...subsection,
        documents: documents.filter((doc) => doc.section.id === subsection.id),
        searchDocuments: searchDocuments.filter((doc) => doc.section.id === subsection.id),
      }));
    return {
      ...section,
      documents: documents.filter((doc) => doc.section.id === section.id),
      subsections: updatedSubsections,
      searchDocuments: searchDocuments.filter((doc) => doc.section.id === section.id),
    };
  });
};

export {
  getLoading,
  getSectionsWithMappedDocuments,
  getDocumentsList,
};
