import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

/**
 * Render Email address column
 * @param {Object} content
 * @returns
 */

const AddressCell = ({ content, editLink }) => {
  const intl = useIntl();
  if (content) {
    const { id, name } = content;
    return (
      <div className="uhe-table-td">
        <Link to={editLink} title={intl.formatMessage({ id: 'sidebar.view' })}>
          <span>{name}</span>
        </Link>
      </div>
    );
  }
  return null;
};

AddressCell.propTypes = {
  content: PropTypes.object,
};

export default AddressCell;
