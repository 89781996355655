import React from 'react';
import PropTypes from 'prop-types';

/**
 * Encapsulate styling for listings top buttons container section
 * @type {React.NamedExoticComponent<{readonly children?: *}>}
 */
const ButtonsContainer = React.memo(({ children }) => (
  <div className="buttons-container">
    {children}
  </div>
));

ButtonsContainer.displayName = 'ButtonsContainer';
ButtonsContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ButtonsContainer;
