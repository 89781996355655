import React, { PureComponent } from 'react';
import {
  Button, List, Popconfirm, Typography, Comment, Input, Form, DatePicker,
} from 'antd';
import { connect } from 'react-redux';
import {
  getAnnouncements, deleteAnnouncementRequest, addAnnouncementRequest, updateAnnouncementRequest,
} from '@uhe_actions/system/AnnouncementsActions';
import PropTypes from 'prop-types';
import moment from 'moment';
import IntlMessages from 'util/IntlMessages';

const { Title } = Typography;
const { TextArea } = Input;

/**
 * Announcements Component
 */
class Announcements extends PureComponent {
  /**
   * Constructor
   * @param {object} props Constructor Props
   */
  constructor(props) {
    super(props);

    this.state = {
      isEditClicked: {},
      textAreaValue: '',
      isAddClicked: false,
      isAddDisabled: false,
      isEditDisabled: false,
    };
  }

  /**
   * componentDidMount
   * @returns {function} call action
   */
  componentDidMount() {
    const { getAnnouncementsAction } = this.props;
    getAnnouncementsAction();
  }

  /**
   * handleEditAction
   * @param {string} id announcement id
   * @returns {func} change current state
   */
  handleEditAction = (id) => {
    const { isEditClicked } = this.state;
    this.setState({ isEditClicked: { ...isEditClicked, [id]: true } });
    this.setState({ isAddDisabled: true });
    this.setState({ isEditDisabled: true });
  }

  /**
   * handleDeleteAction
   * @param {string} id announcement id
   * @returns {func} send action to saga
   */
  handleDeleteAction = (id) => {
    const { deleteAnnouncementAction } = this.props;
    deleteAnnouncementAction(id);
  }

  /**
   * handleSubmitAction
   * @param {string} type of submit - add/edit
   * @param {string} id announcement id
   * @param {string} values form values
   * @returns {func} send action to saga and change current state
   */
  handleSubmitAction = (type, id, values) => {
    const { date } = values;
    const defaultDate = moment().add(29, 'days').format('LLL');
    const formatDate = moment(date).format('LLL');
    const expireDate = date ? formatDate : defaultDate;
    const { addAnnouncementAction, updateAnnouncementAction } = this.props;
    const { textAreaValue, isEditClicked } = this.state;
    if (type === 'add') {
      addAnnouncementAction(textAreaValue, expireDate);
      this.setState({ isAddClicked: false });
    }
    if (type === 'edit') {
      updateAnnouncementAction(id, textAreaValue, expireDate);
      this.setState({ isEditDisabled: false });
      this.setState({ isEditClicked: { ...isEditClicked, [id]: false } });
      this.setState({ textAreaValue: '' });
      this.setState({ isAddDisabled: false });
    }
  }

  /**
   * Render edit field
   * @param {string} defaultMessage initial message
   * @param {string} type type of edit field - add/edit
   * @param {string} id announcement id
   * @returns {JSX} edit field
   */
  renderEditField = (defaultMessage, type, id) => (
    <>
      <Comment content={(
        <>
          <Form onFinish={(values) => this.handleSubmitAction(type, id, values)}>
            <Form.Item name="date" label={<IntlMessages id="announcements.expireDate" />} className="date-field">
              <DatePicker
                showToday={false}
                disabledDate={(d) => !d || d.isAfter(moment().add(31, 'days').format('YYYY MM DD')) || d.isSameOrBefore(moment().add(1, 'day').format('YYYY MM DD')) }
              />
            </Form.Item>
            <Form.Item
              name="textfield"
            >
              <TextArea
                rows={4}
                defaultValue={defaultMessage}
                maxLength={500}
                showCount
                onChange={(e) => this.setState({ textAreaValue: e.target.value })}
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                type="primary"
              >
                {type === 'add' ? <IntlMessages id="announcements.add" /> : <IntlMessages id="announcements.edit" />}
              </Button>
              <Button onClick={() => this.setState({
                isAddClicked: false, isEditClicked: false, isEditDisabled: false, isAddDisabled: false,
              })}
              >
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </>
)}
      />
    </>
  );

  /**
   * Render Announcements
   * @returns {JSX} announcements
   */
  renderAnnouncementsList = () => {
    const { announcements } = this.props;
    const {
      isEditClicked, isAddClicked, isAddDisabled, isEditDisabled,
    } = this.state;
    return (
      <List
        loading={!(announcements.length > 0)}
        className="announcements"
        itemLayout="vertical"
        header={(
          <>
            <Title>Announcements</Title>
            <Button
              disabled={isAddDisabled}
              onClick={() => this.setState({ isAddClicked: true })}
            >
              <IntlMessages id="announcements.addBtn" />
            </Button>
            {isAddClicked && this.renderEditField('', 'add', '')}
          </>
)}
        bordered
        dataSource={announcements}
        renderItem={(announcement) => (
          <List.Item
            key={announcement.id}
            id={announcement.id}
            className="announcement-list-item"
            actions={[
              <Button
                disabled={isEditClicked[announcement.id] || isAddClicked || isEditDisabled}
                onClick={() => this.handleEditAction(announcement.id)}
              >
                <IntlMessages id="announcements.editBtn" />
              </Button>,
              <Popconfirm
                title="Are you sure you want to delete this announcement?"
                onConfirm={() => this.handleDeleteAction(announcement.id)}
                disabled={isEditClicked[announcement.id] || isAddClicked || isEditDisabled}
              >
                <Button
                  disabled={isEditClicked[announcement.id] || isAddClicked || isEditDisabled}
                >
                  <IntlMessages id="announcements.deleteBtn" />
                </Button>
              </Popconfirm>,
              <div>
                <IntlMessages id="announcements.expireMessage" />
                {' '}
                {moment(announcement.expire_at).tz(this.props.loggedUser.isSuperAdmin ? 'UTC' : moment.tz.guess()).format('MMMM Do YYYY, HH:mm:ss z')}
              </div>,
            ]}
          >
            {(isEditClicked[announcement.id] && isEditDisabled) ? this.renderEditField(announcement.message, 'edit', announcement.id) : announcement.message}
          </List.Item>
        )}
      />
    );
  }

  /**
  * render Announcements Component
  * @returns {JSX} Announcements Component
  */
  render() {
    return (
      <>
        {this.renderAnnouncementsList()}
      </>
    );
  }
}

Announcements.propTypes = {
  getAnnouncementsAction: PropTypes.func.isRequired,
  announcements: PropTypes.shape().isRequired,
  deleteAnnouncementAction: PropTypes.func.isRequired,
  addAnnouncementAction: PropTypes.func.isRequired,
  updateAnnouncementAction: PropTypes.func.isRequired,
};
/**
 * Maps Global State to Component's Props
 * @returns {Object} data
 */
const mapStateToProps = ({ announcements }) => ({
  announcements: announcements.announcements.list,
});
/**
 * Returns Object Which Dispatch Actions to the Store
 * @param {function} dispatch data
 * @returns {Object} data
 */
const mapDispatchToProps = (dispatch) => ({
  getAnnouncementsAction: () => dispatch(getAnnouncements()),
  deleteAnnouncementAction: (id) => dispatch(deleteAnnouncementRequest(id)),
  addAnnouncementAction: (message, date) => dispatch(addAnnouncementRequest(message, date)),
  updateAnnouncementAction: (id, message, date) => dispatch(updateAnnouncementRequest(id, message, date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Announcements);
