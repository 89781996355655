import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';
import {
  fetchDataSuccess
} from '@actions/uhe/ResetPasswordActions';
import {
  FETCH_PASSWORD_DATA,
} from '@constants/UHEActionTypes';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { fetchError, showMessage } from '@actions/Common';
import RestManager from '@util/RestManager';
import { notification } from 'antd';

/**
 * @description Request change user's own password
 * @param  {Object} bodyData
 * @return {Object}
 */
const addNew = async (bodyData) => {
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.resetPassword}`,
    'POST',
    bodyData
  );
};

/**
 * @description Change password
 * @param {Object} data
 * @returns {void}
 */
function* changePassword(data) {
  try {
    const password = yield call(addNew, data.data.password);
    if (password) {
      yield put(fetchDataSuccess(password));
      yield put(showMessage('change_success'));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* actionsWatcher() {
  yield takeEvery(FETCH_PASSWORD_DATA, changePassword);
}

export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
