import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

const NonUheUnitCell = ({ content }) => {
  const intl = useIntl();
  const { id, name } = content;

  return (
    <div className="uhe-table-td">
      <Link to={`/configuration/beds-carts/non-uhe-units/edit/${id}`} title={intl.formatMessage({ id: 'sidebar.view' })}>
        <span>{name}</span>
      </Link>
    </div>
  );
};

export default NonUheUnitCell;
