// Customizer const
export const TOP_FILTER_PREFIX = 'topf_';
export const TABLE_FILTER_PREFIX = 'tf_';
export const MAP_FILTER_PREFIX = 'mf_';

export const ACCESS_TOKEN_PARAM = 'access_token';
export const ACCESS_TOKEN_KEY = 'accessToken';
export const CURRENT_USER_KEY = 'ownUserProfile';
export const IMPERSONATE_TOKEN = 'impersonate_token';
export const INVITE_TOKEN = 'invite_token';

export const TOP_FILTER_PAGE_SIZE = 100000;
export const LISTING_TABLES_PAGE_SIZE = 20;
export const MAP_DEFAULT_CENTER = { lat: 35.6709749, lng: -92.8776557 };

export const SIZE = {
  tablePageSize: {
    pageSize: 20,
  },
};

// Edit Facility
export const ADDRESS_POSTAL_CODE = 'postal_code';
export const ADDRESS_COUNTRY = 'country';
export const ADDRESS_CITY = 'locality';
export const ADDRESS_STATE = 'administrative_area_level_1';

// Form Layout
export const FORM_LAYOUT = {
  labelCol: {
    span: 14,
  },
  wrapperCol: {
    span: 10,
  },
};

// Bed/Cart UHE Unit - IP Config Dropdowns
export const UHE_BEDCART_AUTH = {
  leap: 'LEAP',
  md5: 'MD5',
  tls: 'TLS',
  peap: 'PEAP',
  ttls: 'TTLS',
  sim: 'SIM',
  fast: 'FAST',
  pwd: 'PWD',
};

export const UHE_BEDCART_INNER_AUTH = [
  'PAP',
  'CHAP',
  'MSCHAP',
  'MSCHAPv2',
  'GTC',
  'OTP',
  'MD5',
  'TSL',
];

// Bed/Cart Call Formats
export const BEDCART_CALL_FORMATS = ['SIP', 'H323'];

export const ACCESS_LEVEL_OPTIONS = [
  'SUPER_ADMIN',
  'ORGANIZATION_ADMIN',
  'CUSTOMER_ADMIN',
];

export const ADMIN_LEVEL_OPTIONS = [
  'User Admin',
  'Organization Admin',
  'Customer Admin',
  'Facility Admin',
  'Unit Admin',
];

export const HTTP_ERROR_MESSAGES = {
  400: 'bad_request',
  403: 'forbidden',
  404: 'not_found',
  409: 'already_exists',
  500: 'server_error',
  502: 'bad_gateway',
  503: 'service_not_available',
  504: 'gateway_timeout',
};

export const MEDICAL_SPECIALITY_OPTIONS = [
  'InPatient',
  'OutPatient',
];

export const MEDICAL_ROLE_OPTIONS = [
  'Patient',
];

export const YOUTUBE_URL = 'http://www.youtube.com/watch?v=';

export const MFA_OPTIONS = [
  'NONE',
  'TWILIO',
];

export const STATES_OPTIONS = [
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA',
  'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR',
  'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY',
];

export const CANADIAN_PROVINCES = [
  'AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'NT', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT',
];

export const FICAL_YEAR = [
  'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December',
];

export const TELEHEALTH = [
  'ATA', 'CHQI', 'CENTER OF EXCELLENCE', 'URAC',
];

export const MONITORING_DEFAULT_SORTING_QUERY_STRING = '&sort=is_live,desc&sort=status,asc&sort=last_event,desc&sort=organization_name,asc&sort=customer_name,asc&sort=facility_name,asc&sort=unit_name,asc&sort=cart_name,asc';

export const PROVIDER = [
  'audiologist',
  'cardiologist',
  'cns',
  'dermotologist',
  'emergencyMedicine',
  'endocrinologist',
  'ent',
  'familyMedicine',
  'gastroenterologist',
  'generalSurgeon',
  'hospitalist',
  'infectiousDisease',
  'intensivist',
  'internist',
  'nephrologist',
  'neuroSurgeon',
  'obGynecologist',
  'oncologist',
  'orthopedicSurgeon',
  'pediatrician',
  'perinatologist',
  'psychiatrist',
  'radiologist',
  'traumaSurgeon',
  'urologist',
  'other',
];

export const NURSING = [
  'rn',
  'lpn',
  'nonLicensed',
  'telepresenter',
  'careCaseManager',
  'other',
];

export const ANCILLARY = [
  'chaplain',
  'hospice',
  'occupationalTherapist',
  'palliativeCare',
  'pharmacist',
  'psychotherapist',
  'respiratoryTherapist',
  'socialWorker',
  'speechTherapist',
  'other',
];

export const USER_ROLES = [
  { caregility_admin: 'CAREGILITY_ADMIN' },
  { super_admin: 'SUPER_ADMIN' },
  { portal_customer_admin: 'CUSTOMER_ADMIN' },
  { portal_facility_admin: 'FACILITY_ADMIN' },
  { iconsult_desktop: 'I_CONSULT_DESKTOP' },
  { iobserver: 'I_OBSERVER' },
  { notification_portal: 'NOTIFICATION_PORTAL' },
  { portal_org_admin: 'ORGANIZATION_ADMIN' },
  { reporting: 'REPORTING' },
  { technician: 'TECHNICIAN' },
  { technician_admin: 'TECHNICIAN_ADMIN' },
  { portal_unit_admin: 'UNIT_ADMIN' },
  { user_management_admin: 'USER_MANAGEMENT' },
];

export const CLINICAL_ROLES = [
  'provider',
  'nursing',
  'ancillary',
];

export const CONNECTION_TYPES = [null, 'DHCP', 'Static IP', 'Wifi', 'Enterprise Wifi'];

export const PROTOCOL_TYPES = Object.freeze({
  dhcp: '1',
  staticIp: '2',
  wifi: '3',
  enterpriseWifi: '4',
});

export const NUMBERS_PATTERN = /^[+]*[0-9+() -]*$/g;

export const URL_PATTERN = /^((?:https?:\/\/(?:www\.)?)?[a-z0-9]+(?:[-.][a-z0-9]+)*\.[a-z]{2,5}(?::[0-9]{1,5})?(?:\/.*)?)$/;

export const COUNTRIES = Object.freeze({
  usa: 'United States',
  usaIslands: 'United States Minor Outlying Islands',
});

export const APP_PAGES_CONTEXT = Object.freeze({
  users: 1,
  mobilePatients: 2,
  uheUnits: 3,
  nonUheUnits: 4,
  units: 5,
  customers: 6,
  facilities: 7,
  organizations: 8,
  bulkActions: 9,
  notifications: 10,
  programs: 11,
  twilioNumbers: 12,
});

export const PASSWORD_REGEX = /^((?=.*[a-zA-Z])(?=.*\d)(?=.*\W).{6,})|(((?=.*[a-z])(?=.*[A-Z])(?=.*[\W\d])).{6,})$/;
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const HOSTNAME_REGEX = /^(?:(?:https?:\/\/)?(?:(?:25[0-5]|(?:2[0-4]|1\d|[1-9]|)\d)(?:\.(?!$)|$)){4}|(?:https?:\/\/(?:www\.)?)?[a-z0-9]+(?:[-.][a-z0-9]+)*\.[a-z]{2,5}(?::[0-9]{1,5})?(?:\/.*)?)$/;
export const PARAMS_REGEX = /(\w+):\s*([^,]*)/gi;
export const JSON_OBJECT_REGEX = /[^|]+::[^|]+/g;

export const CLOUDBREAK = 'Cloudbreak';
export const INDEMAND = 'InDemand';
export const STRATUS = 'Stratus';
export const LANGUAGELINE = 'LanguageLine';
export const LANGUAGELINEPSTN = 'Language Line PSTN';

export const ACKNOWLEDGED_NOTES = 'acknowledged_notes';
export const ACKNOWLEDGED_BY = 'acknowledgedBy';
export const UPDATE_CERTIFICATE = 'update_wifi_cert';
export const NOTIFICATION_ROLE = 'Notification Portal';
export const ALL_ORGANIZATIONS = 'All';
export const ORGANIZATION = 'organization';
export const CUSTOMER = 'customer';
export const FACILITY = 'facility';
export const UNIT = 'unit';
export const PROCESSING = 'PROCESSING';
export const SUCCESS = 'SUCCESS';
export const FAIL = 'FAIL';
export const HARD_REBOOT = 'hard_reboot';
export const REBOOT_OS = 'reboot_os';
export const SOFT_REBOOT = 'soft_reboot';
export const REBOOT = 'reboot';

export const DOCUMENT_TYPE = [
  'video',
  'documents',
  'any',
];
export const PREFIX = [
  'Mr',
  'Mrs',
  'Ms',
  'BSN',
  'DR',
  'NP',
  'PA',
  'PHD',
  'RN',
];

export const IS_EDIT_PAGE_REGEX = /(organizations|customers|facilities|units|uhe-units|non-uhe-units|mobile-patients|users|programs)\/edit/i;

export const PROFILE_DROPDOWN = [
  {
    label: 'Profile',
    value: 'profile',
  },
  {
    label: 'About',
    value: 'about',
  },
];

export const NAV_STYLE_FIXED = 'NAV_STYLE_FIXED';

export const WINDOW_DIMENSIONS = 'width=960 height=640';
